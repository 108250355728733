import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {Add, SwapHoriz,Close} from '@material-ui/icons';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import { AlertCompo } from '../../../js/components';
import { Autocomplete } from '@material-ui/lab';
import { useMediaQuery } from 'react-responsive'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import {  mdiTransfer } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


//add new bon
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Mon_cai_trt(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const api=require('../../../js/Apis')
    const [mtn,setMtn]=useState('');
    const [desc,setDesc]=useState('');
    const [source,setSource]=useState(localStorage.getItem('name'));
    const [dest,setDest]=useState('');
    const [open, setOpen] =useState(false);
    const [openA, setOpenA] =useState(false);
    const [message, setMessage] =useState('');
    const [severity, setSeverity] =useState('info');
    const [usr, setUsr] = useState([]);
    const service="_cai.php"
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 520px)' })

    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    const handleClickOpen = () => { 
        setOpen(true);setMtn('');setSource(localStorage.getItem('name'));setDest('');setDesc('')
        handleOpenUsr();
      };
  
    const handleClose = () => { setOpen(false); };


    //fetch fournisseurs
    const fetchUsr= useCallback(async(serv="_usr.php", act = "gets") => {
        const response = await Gfunc.axiosGetAction(serv,act);
        Gfunc.axiosResponse(response,()=>{
            setUsr(response.data.jData);    
        })
      },[Gfunc])

    //fetch fournisseur handler
    const handleOpenUsr= useCallback(async () => {
      if(usr.length===0) fetchUsr()
      },[usr,fetchUsr])
  
      // ajouter un nouveau transfert du caisse
    const handleSubmit= async(e) =>{
      if(source!==''&&dest!==''&&mtn!==''){
        const src=usr[Gfunc.getIndex(usr,source,"name")].id
        const destin=usr[Gfunc.getIndex(usr,dest,"name")].id
        const response=await axiosPostAction(service,"transfer",{srcAgent:src,destAgent:destin,mtn:parseFloat(mtn),note:desc})
        if(response.data.err && response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err);
        }else
        axiosResponse(response,()=>{
                if (response.data.jData) {
                props.handleAlert(true,'success',"Le transfert est effectué avec succès");
                props.callback();
                setOpen(false);
                api.Caisse();
              }
        })
     }
    }

  return (
    <div>
      <Button className={(isTabletOrMobile)?classes.topTabButton:classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<SwapHoriz/>}>Effectuer un Transfert</Button>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
            <div className='DialogTitle'>
              <Icon path={mdiTransfer} size={1} /> 
              <label>Transfert caisse</label>
            </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
            <DialogContent >
            <ValidatorForm ref={useRef(null)} id="charge"  onSubmit={handleSubmit} onError={errors => console.log(errors)} > 
             <div id='chg-com'>
                <div id='row-1'>
                <Autocomplete
                    disableClearable
                    className='smallAuto'
                    onChange={(e,newValue) =>{setSource(newValue)} } 
                    id='iNtr' 
                    onFocus={handleOpenUsr}
                    value={source}
                    options={usr.map((option) => option.name)}
                    renderInput={(params) => (
                        <TextField  {...params} label='Depuis' size="small" margin='dense' variant="outlined" required />)} 
                   />
                <Autocomplete
                    disableClearable
                    id='iNtr' 
                    className='smallAuto'
                    onChange={(e,newValue) =>{setDest(newValue)} } 
                    onFocus={handleOpenUsr}
                    value={dest}
                    options={usr.map((option) => option.name)}
                    renderInput={(params) => (
                        <TextField {...params} label='Vers' size="small" margin='dense' variant="outlined" required />)} 
                />
                 
                </div>
                <TextField margin="dense"  label='Montant' size="small" type='number' value={mtn} variant="outlined"className='smallText' 
                           InputProps={{ inputProps: { min: 0 ,step:"0.01"} }} onChange={e =>setMtn(e.target.value)} required />
                <TextField size="small" label='Description' className='smallNote' margin="dense" id="idesc" name="idesc"  value={desc}
                           onChange={e =>setDesc(e.target.value)}  placeholder="Insérer une note" multiline variant="outlined" rows={4}/>
             </div>
            </ValidatorForm>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type='submit' color="primary" form="charge" startIcon={<Add/>}>AJOUTER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
