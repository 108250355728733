import React,{useState,useCallback,useEffect} from "react";
import {Card,CardContent,Typography} from '@mui/material';
import {Box, Tooltip} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import '../../css/irh.css'
import { useStyles } from '../../css/muiStyle';
import DialogImpr from "../../components/content/modals/irh_dig_imprimer";
import Iconn from "@mdi/react"
import { mdiAlphaCBox,mdiAlphaSBox,mdiContentCopy,mdiPhone} from '@mdi/js';
import {NoDataComponent,NotAllowedComponent,BackdropCompo} from "../../js/components"
import {CalendarToday,Search} from '@material-ui/icons';
import {  ShareRounded } from "@mui/icons-material";
import { EmailIcon, EmailShareButton, ViberIcon, ViberShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import RichTooltip from "../../compo/RichTootip";

export default function Irh_dig (props){
 const classes=useStyles()
 const Gfunc=require('../../Gfunc')
 const librery=require('../../js/data_table_librery')
 const param=props.param;
 const [data, setData] = useState([]);
 const [tous, setTous] = useState([]);
 const [loading, setLoading] = useState(false);
 const[noData,setNoData]=useState(false);
 const[notAllowed,setNotAllowed]=useState(false);
 const[focus,setFocus]=useState(false);
 const[errMessage,setErrMessage]=useState('');
 const[records,setRecords]=useState('');
 const[search,setSearch]=useState('');
 const[partage,setPartage]=useState('');

 const service='_irh.php'

 const fetch= useCallback(async() => {
        setFocus(true)
        setLoading(true);
        const response = await Gfunc.axiosGetAction(service,"getdigits")  
        if(response.data.err&&response.data.err!=="-1"){
            setErrMessage(response.data.err)
            setNotAllowed(true)
          }else{
            Gfunc.axiosResponse(response,()=>{
                setRecords(response.data.recordsFiltered)
                if(response.data.jData){
                  setData(response.data.jData);
                  setTous(response.data.jData);
                  setNoData(true)
                }else {setData([])}
            })
          } 
          setLoading(false)
  },[Gfunc,librery]);
  
  useEffect(() => {
    if(!focus){fetch(1);}
  }, [fetch,Gfunc]);
  
  

  /*function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
       e.preventDefault();
       handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }
  function liveSearch2() {
    handleSearch();
  }*/
 const HandleSearch=(e)=>{
    if(search!==''&&search!==undefined){
      var found=data.filter((el) =>  JSON.stringify(el).toLowerCase().indexOf(search.toLowerCase()) !== -1)
      setData(found)
    }else{
      setData(tous)
    }
 }
 
  return (
    <div className="content">
       <BackdropCompo className={classes.backdrop} open={loading}/>
       <div className='table-header1'>
          <div className='search'>
            {
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search}  variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={e => HandleSearch(e)}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:'pointer' }} />),}}
                               size="small" margin='none' onKeyDown={e => HandleSearch(e)} onChange={e => setSearch(e.target.value)} />
              </Box>
            }
          </div>
        </div>
       <div id='digit-cards'>
        { 
        (data.length!==0)?
            data.map((el,index)=>(
                <Card sx={{background:'#f5f5f5'}} key={index} >
                 <div id='dig-ex' > 
                 <Tooltip title="Imprimer"><div id='print-tick'><DialogImpr param={param} row={el}/></div></Tooltip>
                 <Tooltip title="Partager">
                 <div id='share-tick'>
                 <RichTooltip
                   key={index}
                   color="#fcfcfc"
                   content={
                    <div className='shareFlex centerContent'>
                        < EmailShareButton subject={"Demander de code"+el.digit} url={"Vous avez demander le code de "+el.digit+((localStorage.getItem("mag"))?" de la part de "+localStorage.getItem("mag"):"")+": "+el.code}>
                            <EmailIcon round size={30}/>
                        </EmailShareButton>
                        < ViberShareButton  url={"Vous avez demander le code de "+el.digit+((localStorage.getItem("mag"))?" de la part de "+localStorage.getItem("mag"):"")+": "+el.code}>
                            <ViberIcon round size={30}/>
                        </ViberShareButton>
                        < WhatsappShareButton url={"Vous avez demander le code de "+el.digit+((localStorage.getItem("mag"))?" de la part de "+localStorage.getItem("mag"):"")+": "+el.code}>
                            <WhatsappIcon round size={30}/>
                        </WhatsappShareButton>
                        <span>
                        <Tooltip title="Copier">
                          <div className='shareIcons'>
                            <Iconn path={mdiContentCopy} color="white" size="20px" onClick={()=>{navigator.clipboard.writeText(el.code);}}/>
                          </div>
                        </Tooltip>
                        </span>
                    </div>
                  }
                  open={(partage===el.digitId)?true:false}
                  placement={"top"}
                  arrow={(partage===el.digitId?true:false)}
                  onClose={()=>partage!==el.digitId}
                ><ShareRounded className={classes.blackIcon} onClick={()=>{setPartage(el.digitId)}}/></RichTooltip></div></Tooltip>
                 </div>
                  <div id='img-dig'><img src ={(param.imgDigital+'prod_'+el.liberoId+'.png')} alt="digit" style={{width: "120px", height:"auto"}}/></div> 
                  <CardContent>
                      <Typography gutterBottom variant="h5" component="div" className="digit-name">{el.digit} </Typography>
                      <div id='card-dig' title={"code"} >
                        <Iconn path={mdiAlphaCBox} color="red" size="18px"/>
                        <Typography variant="body2" color="main" id='code-dig'>{el.code}</Typography>
                      </div>
                      <div id='det-dig' title='Serial'>
                        <Iconn path={mdiAlphaSBox} color="green" size="18px" />
                         <p id='serial-dig'>{(el.serial)?el.serial:'---'}</p> 
                      </div>
                      <div id='det-dig' title="Numéro d'envoi">
                        <Iconn path={mdiPhone} color="black" size="18px"/>
                        {(el.sendNumber)?el.sendNumber:'---'}
                      </div>
                      <div id='det-dig' title="Date de recharge">
                        <CalendarToday className={classes.blackIconn}/>
                        {(el.date_charged)?el.date_charged:'---'}
                      </div>
                      <div id='det-dig' title="Date de consommation">
                        <CalendarToday className={classes.blackIconn}/>
                        {(el.date_used)?el.date_used:'---'}
                      </div>
                  </CardContent>
                </Card> 
            ))
            
                  :(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null
        }
    </div>
    </div>    

  );
}