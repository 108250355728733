
import React from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export default function Area_chart(props){
    function CustomTooltip({ payload, label, active,...props }) {
        if (active && payload[0]!==undefined) {
          return (
            <div className="custom-tooltip" style={{ border:"2px solid"+props.color}}>
              <p style={{color:'black'}}>{`${label}`+`  ${payload[0].payload.Format}`}</p>
              <p className="intro" style={{color:props.color}}>
                {props.name}:{(payload.length!==0)?new Intl.NumberFormat().format(`${payload[0].value}`):0 }
              </p>
            </div>
          );
        } 
        return null;
      }
    return (
      <div>
        <ResponsiveContainer width="100%" height={props.height}>
          <AreaChart width={500} height={150} data={props.data} margin={props.margin}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={props.dataKeyX} tick={{ fill: 'black',fontSize:'13px',dy:3,angle: -20}} />
            <YAxis tick={{ fill: 'black',fontSize:'12px'}} />
            <Tooltip content={<CustomTooltip name={props.name} color={props.fill}/>}/>
            <Area type="monotone" dataKey={props.dataKeyY} stroke={props.fill} fill={props.fill} />
          </AreaChart>
        </ResponsiveContainer>
     </div>
    );
}