import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import TextField from '@material-ui/core/TextField';
import {CircularProgress,TextareaAutosize,Tooltip,ClickAwayListener} from '@material-ui/core';
import {Divider} from '@mui/material';
import {Search,Note} from '@material-ui/icons';
import {customStyles, numberColumn}from '../../css/datatable-custom'
import {useStyles,textAreaEdit} from '../../css/muiStyle';
import Iconn from "@mdi/react"
import {mdiWallet,mdiVectorDifference,mdiScaleUnbalanced} from '@mdi/js';
import {AlertCompo,NoDataComponent,NotAllowedComponent,AutocompleteS,InputNbrEditable} from "../../js/components"
import { Box } from "@mui/system";
import './../../css/monitoring.css'
import './../../css/referentiel.css'
import axios from "axios"
import DraggableDialog from './modals/ref_cap_actionnaire'
import DraggableDialogRedAug from './modals/ref_cap_reduce_aug'
import { Paper } from "material-ui";
import {ReactComponent as Capital} from '../../img/icons/capital.svg'


export default function Ref_cap(props){
  const lodashClonedeep = require('lodash.clonedeep');
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [mouv, setMouv] = useState([]);
  const [repC, setRepC] = useState([]);
  const [catL, setCatL] = useState([]);
  const [tous, setTous] = useState([]);
  const [capital,setCapital]=useState('')
  const [stock,setStock]=useState('')
  const [ecart,setEcart]=useState('')
  const [,setSelecto]=useState('')
  const [,setItem]=useState('')
  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false);
  const [, setTest] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [search1,setSearch1]=useState('');
  const [records, setRecords] =useState('');
  const [,setIcon]=useState('');
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const[noData,setNoData]=useState(false); 
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[doSearch,setDoSearch]=useState(false);
  const service='_cai.php'
  const srv='produit'
  const srv1='prod_det'
  const classes=useStyles()
  
  const conditionalRowStyles=[ 
    { when: row => row.mtn>0,
       style: {
             color: "green",
       }
     },
    { when: row => row.mtn<=0,
       style: {
             color: 'red',
       }
     }
 ]

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

    //fetch the mouv  
    const fetchMvt= useCallback(async(page,servic = service, size = perPage,value) => {
        setCalled(true)
        const response=await librery.axiosGet2(page+'&search='+((value!==undefined)?value:''),service,'getcapmvt', size)
        if(response.data.err&&response.data.err!=='-1'){
          setErrMessage(response.data.err)
          setNotAllowed(true)
        }else
           if(response.data.data!==undefined)
             setRecords(response.data.recordsFiltered)
             Gfunc.axiosResponse(response,()=>{
               if(response.data.data){
                setMouv(response.data.data);
                setTotalRows(response.data.recordsFiltered);
                setDoSearch(true)
                setNoData(true)
               }
            })
      },[])

    //fetch the repartition 
    const fetchRep= useCallback(async() => {
        var cap=0;var stk=0;var ecr=0;
        setCalled(true)
        const response=await Gfunc.axiosGetAction(service,"getcapcat")
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,"error",response.data.err)
        }else
           if(response.data.jData!==undefined)
           Gfunc.axiosResponse(response,()=>{
            response.data.jData.map((el)=>{
              cap=cap+el.captial
              stk=stk+el.stock
              ecr=ecr+((el.captial)-(el.stock))
            })
            setCapital(cap)
            setStock(stk)
            setEcart(ecr)
            setRepC(response.data.jData);
            setTous(response.data.jData)
            setDoSearch(true)
            })
      },[Gfunc])

  useEffect(() => {
    if(!called){fetchMvt(1);}
    if(!called){fetchRep();}
  }, [fetchRep,fetchMvt,called]);


  //fetch categorie
 const fetchCat= useCallback(async(serv="_cat.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
     if(response.data.err&&response.data.err!=="-1"){
       handleAlert(true,'error',response.data.err)
     }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          setCatL(response.data.jData)
        }
      })
     }
 },[Gfunc,lodashClonedeep])
 

  //fetch categorie handler
  const handleOpenCat = useCallback(async () => {
      if(catL.length===0)
      fetchCat()
      },[catL,fetchCat])

  const handleOnEdit=useCallback(async(e,row,att)=>{
    e.preventDefault();
    const edt=e.target.innerHTML
    const edt1=Gfunc.DeleteSpace(edt)
    if(att==='produit' ){
      if(row[att]!==edt1){
        const attrib=row[att];
        const response=await librery.axiosEdit(service,row,edt1,att) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{e.target.innerHTML=edt1;})
        if(response.data.err) {setLoading(true); row[att]=attrib; setLoading(false)}
      }else{e.target.innerHTML=edt1}
    }
  },[librery,Gfunc]);


/*const handleSelect=useCallback((row,att)=>{
    setSelecto(row[att])
    setItem(row.DT_RowId)
    setItemN(att)
 },[])*/

const handleVider=(val)=>{
  setIcon(val)
}

const flagMan = useCallback(async(e,row,att,vall)=>{
    const response= await Gfunc.axiosPostAction('flagman.php','',({ srv:(vall==='principale')?srv:srv1, id:(vall==='principale')?row.DT_RowId.substr(1):row.id,att:att, val:'ll' }))
    Gfunc.axiosResponse(response,()=>{
          setIcon(row.DT_RowId) 
          handleVider('')
            })
  },[Gfunc]);
  const handleTest=(val)=>{
    setTest(val)
  }

const DelPrdCatalogue=useCallback(async(row)=>{
    const response=await Gfunc.axiosPostAction(service,"delcat",{produitId:parseInt(row.DT_RowId.substr(1))})
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,"error",response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData===true){
        row.catalog=false
        setTest(true)
        handleTest(false)
      }
   })
  },[Gfunc])

  const AddPrdCatalogue=useCallback(async(row)=>{
    const response=await Gfunc.axiosPostAction(service,"addcat",{produitId:parseInt(row.DT_RowId.substr(1))})
        if(response.data.err&&response.data.err!=="-1"){
           handleAlert(true,"error",response.data.err)
       }else
       Gfunc.axiosResponse(response,()=>{
          if(response.data.jData===true){
            row.catalog=true
            setTest(true)
            handleTest(false)
          }
       })
  },[Gfunc])


  const columnsCap =useMemo(
    () => [ 
      {
        name: "Date",
        selector: row=>row.date,
        sortable: true,
        minWidth:"130px !important",
        sortField:"date",

      },  
      {
        name: "Actionnaire",
        selector: row=>row.actionnaire,
        sortable: true,
        minWidth:"150px !important",
        sortField:"actionnaire",
      },  
      {
        name: "Categorie",
        selector:row=>row.categorie,
        minWidth:"140px !important",
        sortField:"categorie",
        sortable: true,
        cell: (row) => { 
            return (
            <div >  
            {
            <AutocompleteS
                  service={service} action={'updcap'} 
                  defaultValue={row.categorie} row={row} list={catL} 
                  id={'categorieId'} name='categorie' attr='nom' 
                  onOpen={handleOpenCat} setLoading={setLoading} setItem={setItem} setSelecto={setSelecto}  handleAlert={handleAlert}/>
            }          
            </div> 
            );
        },
      },
      {
        name: "Montant",
        selector:row=>row.mtn,
        minWidth:"140px !important",
        sortField:"mtn",
        sortable: true,
        cell: (row) => {
          return (
            <InputNbrEditable
              service={service} action='updcap' min=''
              row={row} att="mtn" value={row.mtn} id='DT_RowId' idi={row.DT_RowId}
              handleAlert={handleAlert}
            />
           );
         },
      },
       
  ],[classes.redIcon,AddPrdCatalogue,DelPrdCatalogue,catL,classes.checkBox,classes.greenIcon,fetch,handleOnEdit,handleOpenCat,flagMan]);
  
  const columnsRep =useMemo(
    () => [  
      {
        name: "Categorie",
        selector:row=>row.categorie,
        minWidth:"135px !important",
        sortable: true,
      },
      {
        name: "Capital Prévu",
        selector:row=>row.captial,
        minWidth:"165px !important",
        sortable: true,
        style:numberColumn,
        cell: (row) => { 
          return (
          <div >{new Intl.NumberFormat().format( row.captial)} </div> 
          );
      }
      },
      {
        name: "Stock Réel",
        selector:row=>row.stock,
        minWidth:"140px !important",
        style:numberColumn,
        sortable: true,
        cell: (row) => { 
          return (
          <div >{new Intl.NumberFormat().format(row.stock)} </div> 
          );
      }
      },
      {
        name: "Ecart",
        minWidth:"110px !important",
        sortable: true,
        selector:row=>row.capital-row.stock,
        style:numberColumn,
        cell: (row) => { 
          return (
          <div >{new Intl.NumberFormat().format( row.captial-row.stock)} </div> 
          );
      }
      },
       
  ],[]);
  

  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,'getcapmvt')
      if(response.data.err&&response.data.err!=='-1'){
         handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setMouv(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      }) 
    } 
  }

  const handleSearch=async(size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,'getcapmvt')
    if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setMouv(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setMouv([])
    })  
    setLoading(false); 
  }
    return recherche
  }

  function handlePageChange (page) {
    fetchMvt(page,service,perPage,search);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchMvt(page,service,newPerPage,search);
    setPerPage(newPerPage);
  };
  
  //Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
  function liveSearch(e,val){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch(val);
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch(val);
            }
    }
  }

  function liveSearch2(e,val) {
    handleSearch(val);
  }

  const filtrer=(val)=>{
    var test=tous.filter((el) =>  JSON.stringify(el).toLowerCase().indexOf(val.toLowerCase()) !== -1)
    setRepC(test)
  }

  const  HamdleOnChange=(e)=>{
    setSearch1(e.target.value)
    filtrer(e.target.value)
  }
  
  const ExpanableComponent =({data}) => {
    const [selecto,setSelecto]=useState('')
    const [Item,setItem]=useState('')
    const [ItemN,setItemN]=useState('')
    const [note,setNote]=useState('');


       //handle update note
    const handleOnEditNote=useCallback(async(note,row,att,action)=>{
      const edt1=note
      var edt=Gfunc.DeleteSpace(edt1);
      if(row[att]!==edt){
        edt=edt.replaceAll("\n", "\r")
        const attrib=row[att];
        const response=await librery.axiosEdit2(service,action,row,edt,att) 
        if(response.data.err&&response.data.err!=='-1'){
          setSelecto('')
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{setSelecto('');row[att]=edt})
        if(response.data.err) {row[att]=attrib}
      }else {row[att]=edt}
      setNote('')
      },[]);


      const handleSelectE=useCallback((row,att)=>{
        setSelecto(row[att])
        setItem(row.DT_RowId)
        setItemN(att)
        setNote(row.note)
      },[])

   return( 
    <div className="row-expenderr">
     <div id='comments'>
        <Note style={{height:'20px',width:'20px'}}/>     
        <b>Commentaire :</b>
        <div className="note-container">
            { (data.note===selecto && ItemN==='note' && data.DT_RowId===Item)?
            <ClickAwayListener onClickAway={()=>{handleOnEditNote(note,data,"note",'updcap')}}>
             <TextareaAutosize style={textAreaEdit} value={note}  onChange={(e)=>{setNote(e.target.value);}}
             /></ClickAwayListener>:
             ((data.note&&data.note.match(/\r/g) || []).length>=1)?
             <Tooltip title={data.note.replaceAll("\r", "/ ")}>
                 <div className="content-editable" onClick={()=>{handleSelectE(data,'note')}}>{data.note.substring(0, data.note.indexOf("\r"))+"..."}</div>
             </Tooltip>:
               <Tooltip title={data.note}>
             <div className="content-editable-exp" onClick={()=>{handleSelectE(data,'note')}} >{data.note}</div></Tooltip>}
        </div>    
     </div>
   </div>
   )
  }


  return (
    <div className="content" id='div-tabs'>
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
       <div id='table-d'>
        <div id='tab-head'><p>Mouvements des capitaux</p></div>
        <div className='table-header'>
        <div className='button-flex-wrapper' >
         <DraggableDialogRedAug handleAlert={handleAlert} callback={()=>fetchMvt(1)} callback1={()=>fetchRep()} open='augm'/>
         <DraggableDialogRedAug handleAlert={handleAlert} callback={()=>fetchMvt(1)} callback1={()=>fetchRep()} open='reduce'/>
         <DraggableDialog param={param} handleAlert={handleAlert} callback={()=>fetch(1)} />

         </div>
          {(doSearch)?
            <div className='search' >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined" label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:'pointer' }} />),}}
                               size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
              </Box>
            </div>:null}  
        </div>
        {(mouv.length!==0||doSearch)?
        <div id='table-scroll'>
          <DataTable 
              key={Math.floor(Math.random() * 100)}
              persistTableHead
              columns={columnsCap}
              data={mouv}
              noHeader={true}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[25,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              fixedHeader={true}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationPerPage={perPage}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              expandOnRowClicked
              expandableRows 
              expandableRowsComponent={ExpanableComponent}
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
        /></div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
     </div>
     <div id='table-g'>
        <div id='tab-head'><p>Répartition du capital</p></div>
        <div className='table-header'>
            <div className='search' >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined" id="input-with-sx" label="Recherche" value={search1} 
                               InputProps={{startAdornment: (<Search style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px' }} />),}}
                               onChange={e =>HamdleOnChange(e)} size="small" margin='none'  />
              </Box>
            </div>  
        </div>
        <div className="globalHead-cap">
             <div id='globalHeadChild-cap'>
             <Capital fill='black' style={{height:'28px',width:'28px',padding:"1px"}}/>
                <div className="ChildHead-cap">
                    <h2>{Intl.NumberFormat().format(parseFloat(capital))}</h2>
                    <p>Total capital prévu</p>
                </div>
              </div>
            <Divider orientation="vertical" flexItem />
            <div id='globalHeadChild-cap'>
               <Iconn path={mdiWallet} color="black" size="26px"/>
               <div className="ChildHead-cap">
                  <h2>{Intl.NumberFormat().format(parseFloat(stock))}</h2>
                  <p>Total stock réel</p>
               </div>
            </div>
            <Divider orientation="vertical" flexItem />
            <div id='globalHeadChild-cap'>
               <Iconn path={mdiScaleUnbalanced} color="black" size="26px"/>
               <div className="ChildHead-cap">
                  <h2>{Intl.NumberFormat().format(parseFloat(ecart))}</h2>
                  <p>Total écart</p>
               </div>
            </div>
           </div>
        {(repC.length!==0||doSearch)?
        <div id='table-scrollC'>
           <DataTable 
              persistTableHead
              columns={columnsRep}
              data={repC}
              noHeader={true}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              pagination
              fixedHeader={true}
              highlightOnHover
              paginationPerPage={25}
              paginationRowsPerPageOptions={[25, 30, 50]}
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
           />
        </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
    </div>    
    </div>    
  );
}
