import React ,{useRef,useCallback,useState,useMemo} from "react";
import DataTable from "react-data-table-component";
import {Button,MenuItem,CircularProgress,Backdrop } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { GetApp, DoneAll} from '@material-ui/icons';
import { customStyles, customStyles1} from '../../css/datatable-custom'
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { useStyles } from '../../css/muiStyle';
import ReactExport from "react-export-excel";
import {NoDataComponent,AlertCompo} from '../../js/components'
import { Autocomplete } from "@material-ui/lab";
import  '../../css/reporting.css'

export default function Rep_top (props){
    const Gfunc=require('../../Gfunc')
    const param=props.param;
    const classes = useStyles();
    const [loading,setLoading]=useState(false)
    const [comm,SetComm]=useState([])
    const [selectComm,SetSelectComm]=useState('')
    const [Commune,SetCommune]=useState([])
    const [selectcommune,SetSelectCommune]=useState('')
    const [selectDateD,SetSelectDateD]=useState(Gfunc.DateDeb(new Date()))
    const [selectDateF,SetSelectDateF]=useState(new Date())
    const [topClient,SetTopClient]=useState([])
    const [topCommune,SetTopCommune]=useState([])
    const [topCommercial,SetTopCommerciel]=useState([])
    const [test,Settest]=useState(false)
    const [err,SetErr]=useState(false)
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const message='Aucune donnée trouvée'

    const handleAlert=(open,severity,message)=>{
        setOpenA(true)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
      }
//recuperer les utilisateurs
const fetchUsers= useCallback(async() => {
    const response = await Gfunc.axiosGetAction('_usr.php',"gets")
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      response.data.jData.unshift({id:'empty',name:'Tous'})
      SetComm(response.data.jData);   
    })
    }
  },[Gfunc,param.urlService]);

//recuperer les communes
const fetchCommun= useCallback(async() => {
    const response = await Gfunc.axiosGetAction('_cli.php',"getcom")
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({id:'empty',name:'Toutes'})
        SetCommune(response.data.jData);   
      })
    }
  },[Gfunc,param.urlService]);

//valider le formulaire
 const handleSubmit= async() =>{
    var commercial='';var commune='';
    SetErr(false)
    setLoading(true)
    
    if(selectComm!==''){
      commercial=comm[Gfunc.getIndex(comm,selectComm,"name")].id
    }
    if(selectcommune!==''){
      commune=Commune[Gfunc.getIndex(Commune,selectcommune,"name")].id
    }

    const response=await Gfunc.axiosPostAction("_rep.php","gettopc",{  date1:Gfunc.formatDate(new Date(selectDateD)), date2:Gfunc.formatDate(new Date(selectDateF)),
                                                commercial:(commercial!=='empty')?commercial:'',commune:(commune!=='empty')?commune:''})
    setLoading(false)
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
     if(response.data.jData!==undefined){   
      if(response.data.jData.topClient.length!==0||response.data.jData.topCommercial.length!==0||response.data.jData.topCommune.length!==0)
      { Settest(true)}
      if(response.data.jData.topClient.length===0&&response.data.jData.topCommercial.length===0&&response.data.jData.topCommune.length===0)
      { SetErr(true)}
      var tes1=response.data.jData.topClient.sort(function (a, b) {
        return b.mben - a.mben;
      })
      tes1.map((el,index)=>{
       el["ind"]=index+1
       return true
      }) 
      var tes2=response.data.jData.topCommercial.sort(function (a, b) {
        return b.mben - a.mben;
      })
      tes2.map((el,index)=>{
       el["ind"]=index+1
       return true

      }) 
      var tes3=response.data.jData.topCommune.sort(function (a, b) {
        return b.mben - a.mben;
      })
      tes3.map((el,index)=>{
       el["ind"]=index+1
       return true

      }) 

      SetTopClient(response.data.jData.topClient)
      SetTopCommerciel(response.data.jData.topCommercial)
      SetTopCommune(response.data.jData.topCommune)
     } 
    })
  }
}

//valider formulaire avec touche d'entrer
const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="addBtn")) {
        if(selectComm===''||selectcommune===''){
          alert("Vous devez remplir tous les champs!")
        }
      }
}

 //remplir Select commercial
const handleOpenComm= useCallback(async () => {
   if(comm.length===0) fetchUsers()
},[comm,fetchUsers])

//remplir Select Commune
const handleOpenCommune= useCallback(async () => {
   if(Commune.length===0) fetchCommun()
},[Commune,fetchCommun])



//colomne tableau chiffre d'affaire client
const columnsC =useMemo(
            () => [ 
              { 
                width:"40px",
                name: "",
                selector:row=> row.ind,
                sortable: true,
             }, 
              {
                  name: "Client",
                  selector:row=> row.client,
                  sortable: true,
              },
              {
                name: "Commune",
                selector:row=> row.commune,
                sortable: true,
              },
             {
                name: "Commercial",
                selector:row=> row.commercial,
                sortable: true,
            },
            {
                name: "C.A.",
                selector:row=> row.ca,
                sortable: true,
                cell: (row) => {
                  return (
                    <div >
                      {new Intl.NumberFormat().format(row.ca)} 
                     </div>
                  )
                }
            }
            ],[]);
 

//colomne tableau chiffre d'affaire de commerciel
const columnsCC =useMemo(
() => [ 
  {
    name: "",
    width:"40px",
    selector:row=> row.ind,
    sortable: true,
 },  
  {
      name: "Commercial",
      selector:row=>row.commercial,
      sortable: true,
  },
  {
    name: "C.A.",
    selector: row=>row.ca,
    sortable: true,
    cell: (row) => {
      return (
        <div >
          {new Intl.NumberFormat().format(row.ca)} 
         </div>
      )
    }
}
],[]);  

//colomne tableau chiffre d'affaire de la commune

 const columnsCUC =useMemo(
        () => [   
          {
            name: "",
            width:"40px",
            selector:row=> row.ind,
            sortable: true,
          }, 
          {
              name: "Commune",
              selector:row=>row.commune,
              sortable: true,
          },
          {
            name: "C.A.",
            selector:row=>row.ca,
            sortable: true,
            cell: (row) => {
              return (
                <div >
                  {new Intl.NumberFormat().format(row.ca)} 
                 </div>
              )
            }
        }
        ],[]);

return (
  <div className='content'>
  <fieldset className="fieldsetTop" id="topC">
        <ValidatorForm noValidate id="formAdd" ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)}  >
            <div className="rowGrid" id="row-1">
             <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDateD} variant="inline" placeholder="01/01/2020" onChange={date => SetSelectDateD(date)}
                                        minDate='10/10/2018' format="dd/MM/yyyy" className='smallText' margin='dense' inputVariant="outlined"
                                        invalidDateMessage="Le format de la date est incorrect" 

                                />
             </MuiPickersUtilsProvider>
             <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDateF} variant="inline" placeholder="10/10/2018" onChange={date => SetSelectDateF(date)}
                                            minDate="01/01/2018" format="dd/MM/yyyy" className='smallText' margin='dense' inputVariant="outlined"
                                            invalidDateMessage="Le format de la date est incorrect" 
                                />
             </MuiPickersUtilsProvider>
             <Autocomplete
                   disableClearable
                   onChange={(e,newValue) =>{SetSelectComm(newValue)}}
                   value={selectComm}
                   options={comm.map((option) => option.name)}
                   onFocus={handleOpenComm}
                   className='smallAuto' 
                   renderInput={(params) =>  
                        <TextField margin='dense' 
                              inputlabelprops={{className:(comm)?undefined:classes.dataLabel}}
                              // error={errorP}
                              size='small'{...params} variant="outlined"  label="Commercial..." 
                              SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                          />}
              />
              <Autocomplete
                disableClearable
                onChange={(e,newValue) =>{SetSelectCommune(newValue)}}
                value={selectcommune}
                options={Commune.map((option) => option.name)}
                onFocus={handleOpenCommune}
                className='smallAuto' 
                renderInput={(params) =>  
                    <TextField margin='dense' inputlabelprops={{className:(Commune)?undefined:classes.dataLabel}}
                               size='small'{...params} variant="outlined"  label="Commune..." 
                               SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                      />}
              />
              <Button  className={classes.PrintBtn} variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" 
                       color="primary" startIcon={<DoneAll/>}>VALIDER</Button>
              {
              (test===true)?
              <ExcelFile element={ <Button  id='Row-Button1' className={classes.PrintBtn} variant="contained" type='submit' name="addBtn1" color="primary" title='Exporter les Données' ><GetApp fontSize="small"/></Button> }>
              <ExcelSheet data={topClient} name="Top clients"  >
                  <ExcelColumn label="Client" value="client" />
                  <ExcelColumn label="Commune" value="commune"/>
                  <ExcelColumn label="commercial" value="commercial"/>
                  <ExcelColumn label="C.A." value="ca"/>
              </ExcelSheet>
              <ExcelSheet data={topCommune} name="Top des communes" >
                  <ExcelColumn label="Commune" value="commune"/>
                  <ExcelColumn label="C.A." value="ca"/>
              </ExcelSheet>
              <ExcelSheet data={topCommercial} name="Top des commerciaux" >
                  <ExcelColumn label="Commercial" value="commercial"/>
                  <ExcelColumn label="C.A." value="ca"/>
              </ExcelSheet>
              </ExcelFile>
              :<></>
              }
            </div >
              
    </ValidatorForm>
 </fieldset>
 <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
 <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
 </Backdrop>
   {
      (topClient.length!==0)?
      <div className="row-tables">
        <div id='tablee-1'>
        <DataTable persistTableHead
              title="Top clients par  encaissement"
              columns={columnsC}
              data={topClient}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              highlightOnHover
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   

              />
        </div>
        </div>:<></>
    }
        
    {
      (topCommercial.length!==0)?
      <div className="row-tables" >
        <div id='tablee-1'>
        <DataTable persistTableHead 
              title="Top commerciaux par  encaissement"
              columns={columnsCC}
              data={topCommercial}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              highlightOnHover
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
                              />
        </div>
     </div>:<></>
  }

  {
    (topCommune.length!==0)?
    <div className="row-tables">
        <div id='tablee-1'>
            <DataTable persistTableHead 
                title="Top communes par encaissement"
                columns={columnsCUC}
                data={topCommune}
                defaultSortAsc={true}
                noDataComponent={param.ErreurData}
                paginationRowsPerPageOptions={[10,30,50,100]}
                pagination
                highlightOnHover
                paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
                customStyles={customStyles}   
            />
        </div>
    </div>:<></>
  }
   {(err===true)?<div><NoDataComponent message={message}/></div>:<></>}
    </div>   
  );
}