import React,{ useState ,useCallback} from "react";
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField, Backdrop, CircularProgress, Tooltip} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import {  RotateLeft} from '@material-ui/icons';
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import { AlertCompo } from "../../../js/components";
import '../../../css/histoBinars.css'
import {mdiAccountQuestion, mdiDiamondStone } from "@mdi/js";
import Iconn from "@mdi/react";
import { Divider } from "@mui/material";

//historique binars 
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

//add binars
export default function Vnt_cpt_topUp(props) {

  const classes=useStyles();
  const Gfunc=require('../../../Gfunc')
  const [prodL, setProdL] = useState([]);
  const [player, setPlayer] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] =useState(false);
  const [topped, setTopped] =useState(false);
  const [trans, setTrans] =useState(null);
  const [uid, setUid] =useState("");
  const [zone, setZone] =useState("");
  const [openA, setOpenA] =useState(false);
  const [message, setMessage] =useState('');
  const [severity, setSeverity] =useState('info');
  const stk=localStorage.getItem('stockId');
  const service="_irh.php"
  const labelText1="ID"
  const labelText2="Zone"



    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }


  //fetch products
  const fetchProd= useCallback(async(serv="_prd.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        setProdL(response.data.jData);    
    })
    }
  },[Gfunc])


  const handleClickOpen = () => { 
    fetchProd()
    setPlayer({}) 
    setOpen(true);
    setUid("")
    setTopped(false)
    setTrans(null)
  };

  const Reset = () => { 
    setZone("")
    setPlayer({}) 
    setUid("")
    setTopped(false)
    setTrans(null)
  };

  const handleClose = () => { setOpen(false); }; 

  const handleSubmit= async(e) =>{
    setLoading(true)
    
    if(uid){
      const data={
                defStock:stk,
                tiketId:props.ticketId,
                liberoId:props.el.liberoId,
                accountId:uid,
                topupParam1:player.topupParam1,
                topupParam2:player.topupParam2}

    if(props.el.marqueId===37) data["zone"]=zone
      const respon=await axiosPostAction(service,"topup",data)
      if(respon.data.err && respon.data.err!=='-1'){
        handleAlert(true,'error',respon.data.err);
      }else
        axiosResponse(respon,()=>{
                if (respon.data.data) {
                  //setOpen(false)
                  setTopped(true)
                  setTrans(respon.data.data.transactionId)
                  props.GetAtSoldes()
                  props.handleAddVnt2(respon.data.data.vente,props.stk,props.stkN,respon.data.data.vente.mtn,(props.retour)?-respon.data.data.vente.qte:respon.data.data.vente.qte,"",handleAlert,()=>{},()=>{})  
                  handleAlert(true,"success","Le TopUp est effectué avec succès")
                }
        })
    }
      setLoading(false)
  }
  const handleValidate= async(e) =>{
    setLoading(true)
    const data={
      liberoId:props.el.liberoId,
      accountId:uid,
      tiketId:props.ticketId}
      const respon=await axiosPostAction(service,"validate",data)
      setPlayer(respon.data.data)
     /* if(respon.data.err && respon.data.err!=='-1'){
        handleAlert(true,'error',respon.data.err);
      }else*/
      axiosResponse(respon,()=>{
              if (respon.data.jData) {
                setPlayer(respon.data.jData)
                const el=document.getElementById("binarsVal")
                if(el){
                    el.innerHTML= new Intl.NumberFormat().format(respon.data.jData)
                }
            }
      })
      setLoading(false)
  }
  return (
    <>
  <Tooltip title="TopUp"><Button className={classes.iconBtnBig} variant="contained" style={{background:"gold"}} onClick={handleClickOpen} ><Iconn path={mdiDiamondStone} color="red" size="18px" /></Button></Tooltip> 
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >TopUp {(props.el.digit)?props.el.digit:""}</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
            <DialogContent>
                <Backdrop className={classes.backdropDiag} open={loading}>
                  <CircularProgress color="inherit" />
                </Backdrop>   
                
                {(Gfunc.isEmpty(player))?<>
                  <Divider textAlign='left' >Vérification du code</Divider> 
                  <ValidatorForm id="verfifyCode" type="number"  onSubmit={handleValidate} onError={errors => console.log(errors)} > 
                    <TextField size="small" label={labelText1} className='smallText' margin="dense" id="iUid" name="iUid"  value={uid}
                          onChange={e =>setUid(e.target.value)} variant="outlined" required autoFocus/>

                    {(props.el.marqueId===37)?<TextField size="small" label={labelText2} className='smallText' margin="dense" id="iZone" name="iZone"  value={zone}
                          onChange={e =>setZone(e.target.value)} variant="outlined" required />:null}
                    <Tooltip title="Vérifier"><Button className={classes.iconBtn} variant="contained" type='submit' color="primary" form="verfifyCode" ><Iconn path={mdiAccountQuestion} size="22px"/></Button></Tooltip>
                  </ValidatorForm>
                </>
                 :
                <>
                <Divider textAlign='left' >Top Up</Divider> 
                <div id="verfifyCode">
                  <fieldset className="fieldInf" >
                        <legend> ID:</legend>
                        <p>{uid}</p>
                  </fieldset>
                  {(props.el.marqueId===37)?
                    <fieldset className="fieldInf" >
                          <legend> Zone:</legend>
                          <p>{zone}</p>
                    </fieldset>:null}
                  {(!Gfunc.isEmpty(player))?
                    <fieldset className="fieldInf" >
                          <legend> Nom:</legend>
                          <p>{(player.name)?player.name:null}</p>
                    </fieldset>:null}
                <Tooltip title="Réinitialiser"><Button className={classes.iconBtn} variant="contained" onClick={()=>Reset()} color="primary" form="verfifyCode" ><RotateLeft/></Button></Tooltip>
                </div>
                <ValidatorForm id="topUp" type="number" onSubmit={handleSubmit} onError={errors => console.log(errors)} >
                 {(!topped)?<Button className={classes.smallBtn} variant="contained" type='submit' color="primary" form="topUp" style={{background:"gold",color:"red",border:"none"}} startIcon={<Iconn path={mdiDiamondStone} size="18px" color="red"/>}>TopUp</Button>
                           :<fieldset className="fieldInf" style={{maxWidth:"180px"}} >
                           <legend> Id de transaction:</legend>
                             <p>{(trans)?trans:""}</p>
                           </fieldset>
                 }
                </ValidatorForm>
                </>}
            </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}