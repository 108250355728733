import React,{useState,useMemo,createRef,useRef,useCallback, useEffect} from 'react';
import {DialogContent,Paper,Dialog,IconButton,Button,Collapse,MenuItem, Tooltip,TextField, CircularProgress, Backdrop, Box,Tabs,AppBar, DialogActions,Typography} from '@material-ui/core';
import DialogTitle1 from '@material-ui/core/DialogTitle';
import { ArrowRight, Print, Receipt, Today,Close} from '@material-ui/icons';
import Draggable from 'react-draggable';
import DataTable from "react-data-table-component";
import { paperProps2, useStyles, paperPropsSmallTall,paperPropsTicket,paperPropsTicketSmall} from '../css/muiStyle';
import ReactToPrint from 'react-to-print';
import  '../css/retour.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import NoData from '../img/nodata-found.svg'
import NoAjust from '../img/noajust.png'
import NotAllowed from '../img/sorryicon.png'
import '../css/global.css'
import Alert from '@material-ui/lab/Alert';
import '../css/ticket-bon.css'
import '../css/components.css'
//import QRCode from "react-qr-code";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {fr} from "date-fns/locale";
import {ReactComponent as BigPrinter} from '../img/icons/bigPrinter.svg'
import Iconn, { Icon } from "@mdi/react"
import {mdiSim, mdiTableAlert,mdiCashMultiple} from '@mdi/js';
import {numberColumnTicket,customStylesTicket,customStylesTicket2} from '../css/datatable-custom'
import TabScrollButton from '@material-ui/core/TabScrollButton';
import LogoBq from '../img/logos/logo_bq.png';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import PrintDig from './printDig';
import { useContext } from 'react';
import ParamContext from '../Contexte';
import {ViberShareButton, ViberIcon, WhatsappShareButton, WhatsappIcon, EmailShareButton, EmailIcon} from "react-share";
import LazyLoad from 'react-lazyload';


const stylesExp = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitleExp = withStyles(stylesExp)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: "15px",
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff"
  }
}))(LinearProgress);

const useStylesBar = makeStyles({
  root: {
    display:"flex",
    width:"auto",
    gap:"20px",
    paddingLeft:"20px"
  },
  
});

export function CustomizedProgressBars(props) {
  const classes = useStylesBar();

  return (
    <div id='borderLinearProgress'>
      <div className={classes.root} >
       <div id="progress"> 
          <p className="flex-wrapper" id="percent">{(props.value>0)?parseFloat(props.value).toFixed(2):0}%</p>
          <BorderLinearProgress  variant="determinate" value={props.value}/>
          <p className="flex-wrapper" id="max">Seuil <ArrowRight/> {Intl.NumberFormat().format(props.max)}</p>
       </div>
       <p id="current" >{Intl.NumberFormat().format(props.current)}</p>
      </div>
    </div>
  );
}


//var zlib = require('zlib');
//const param=require('../param.json')
const Gfunc=require('../Gfunc')
const librery=require('./data_table_librery')
const mdi=require('@mdi/js');

const paperProps={
    style:{
      width:"700px",
      maxWidth:"2000px",
      maxHeight:"95%",
     // height:"1000px",
      overflow:"auto"
    }
 }

const useStyles2 = makeStyles((theme) => ({
  root: {
   /* width: '98%',
    margin:"0px 1%",
    '& > * + *': {
      marginTop: theme.spacing(2),
    },*/
    position:'fixed',
    bottom:"30px",
    right:'10px',
    zIndex: theme.zIndex.drawer + 1,
    width: '300px',
    opacity:1
  },
  root2: {
   /* width: '98%',
    margin:"0px 1%",
    '& > * + *': {
      marginTop: theme.spacing(2),
    },*/
    position:'fixed',
    top:"35px",
    zIndex: theme.zIndex.drawer + 1,
    width: '95%',
    marginLeft:'2.5%',
    marginRight:'2.5%',
    opacity:0.7
  },
}));

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle  className={classes.root} {...other}>
        <Typography >{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
export const NoDataComponent =((props) => {
    return (
    <div className='nodata'>
      <div id='cont'>
      <img src={(props.ajust)?NoAjust:NoData} alt="NoData" className='notFound' />   
      <p className='message'>{props.message}</p>  
     </div>  
    </div>      
    );
  }); 

  export const NoTableDataComponent =((props) => {
    return (
    <div className='nodata'>
      {(props.ajust)?<img src={NoAjust} alt="NoData" className='notFound' />   
      :<Iconn path={mdiTableAlert} size={'150px'} color={'gray'} />}
      <p className='message'>{props.message}</p>  
    </div>        
    );
  });


export function Detail_tiket(props) {
    const [detail,setDetail]=useState([]);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [load, setLoad] = useState(false);
    const componentRef = useRef();
    const classes=useStyles();
    const param= useContext(ParamContext)


    const handleError=()=>{
      setError(true)
    }
  
    const handleLaoad=()=>{
      setLoad(true)
    }
  
    // fetch detail de tiket 
    const fetchDetail= async() => { 
      const response = await Gfunc.axiosPostAction("_vnt.php","gettiket",{idt:(props.val!=='prod'||props.from==='vnt')?props.idt:props.row.tiketId})
      Gfunc.axiosResponse(response,()=>{
         setDetail(response.data.jData);
         
         //var deflated = zlib.deflateSync(Buffer.from(JSON.stringify(detail))).toString('base64');
         //var inflated = zlib.inflateSync(Buffer.from(deflated, 'base64')).toString();
/*
         var compressed = LZString.compress(JSON.stringify(detail));
         console.log(compressed)
         var decompressed = LZString.decompress(compressed);
         console.log(decompressed)
*/
      })
    };

    const handleClickOpen = () => {  
      fetchDetail();
      setOpen(true); 
    };
    const handleClose = () => { 
      setDetail([])
      setOpen(false); };

    const columns = useMemo(()=>[   
          {
              name: "Produit",
              selector: row=>row.produit,
              sortable: false,
              width:'132px !important'
          },
          {
              name: "QTE.",
              selector: row=>row.qte,
              sortable: false,
              width:'40px !important',
              style:numberColumnTicket,
              cell:(row)=>{
                return (
                <>
                  {new Intl.NumberFormat().format(row.qte)}
                </>
                )
              }
          },
          {
              name: "Prix",
              sortable: false,
              style:numberColumnTicket,
              width:'56px !important',
              cell:(row)=>{
                return (
                <>
                  {new Intl.NumberFormat().format(row.prix)}
                </>
                )
              }
          },
          {
              name: "Total",
              selector: row=>row.mtn,
              style:numberColumnTicket,
              sortable: false,
              cell:(row)=>{
                return (
                <>
                  {new Intl.NumberFormat().format(row.mtn)}
                </>
                )
              }
          },
        ],[])



    const columns2 = useMemo(()=>[   
          {
              name: "Produit",
              selector: row=>row.produit,
              sortable: false,
              width:'78px !important',
              cell:(row)=>{
                return (
                <div id='prodName'>
                  {row.produit}
                </div>
                )
              }
          },
          {
              name: "QTE.",
              selector: row=>row.qte,
              sortable: false,
              width:'34px !important',
              style:numberColumnTicket,
              cell:(row)=>{
                return (
                <>
                  {new Intl.NumberFormat().format(row.qte)}
                </>
                )
              }
          },
          {
              name: "Prix",
              sortable: false,
              style:numberColumnTicket,
              width:'45px !important',
              cell:(row)=>{
                return (
                <>
                  {new Intl.NumberFormat().format(row.prix)}
                </>
                )
              }
          },
          {   
              name: "Total",
              selector: row=>row.mtn,
              //minWidth:'80px !important',
              style:numberColumnTicket,
              sortable: false,
              cell:(row)=>{
                return (
                <>
                  {new Intl.NumberFormat().format(row.mtn)}
                </>
                )
              }
          },
        ],[])

  return (
    <div>
    {(props.from==="vnt")?
               <RaccBtn
                      id="impTik"
                      tip={'Imprimer ticket'}
                      className={classes.racBtnRed}
                      onClick={handleClickOpen}
                      text={'Impr.'}
                      path={mdi["mdiPrinter"]}
                      iconColor={"white"}
                      racc={"ctrl+F3"}
                      disabled={props.disabled}
                    />:(props.from==="jrn")?<Tooltip title="Imprimer"><Button className={classes.iconBtn} onClick={handleClickOpen} variant="contained" color="primary"  minWidth='250px' > <Print fontSize='small'/></Button></Tooltip>:<div  className="clickable" onClick={handleClickOpen}>{(props.val!=='prod')?props.num:props.row.tiketNum}</div> }
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={(localStorage.getItem('tiketWidth')==="1")?paperPropsTicketSmall:paperPropsTicket}
      >
<DialogTitle id="simple-dialog-title" onClose={handleClose}>Dètail du ticket <ReactToPrint trigger={() => <Button color="primary" style={{width:"120px"}}startIcon={<Print/>}> Imprimer</Button>} content={() => componentRef.current} />
</DialogTitle>
<DialogContent ref={componentRef} className={(localStorage.getItem('tiketWidth')==="1")?" ticketPaperContent2":" ticketPaperContent"}>
    <div id={(localStorage.getItem('tiketWidth')==="1")?"ticket2":"ticket"} >
            <div id='ticketHead'>
            <img src={(error===false&&load===true)?param.logo+localStorage.getItem('logo'):LogoBq} id='logo_ticket' alt='logo'  onLoad={handleLaoad}  onError={handleError} />
              <h3 style={{color:'black',paddingBottom:'5px'}}>{(localStorage.getItem('entet_tiket_1')&&localStorage.getItem('entet_tiket_1')!=="null")?localStorage.getItem('entet_tiket_1'):null}</h3>
              <p>{(localStorage.getItem('adresse')&&localStorage.getItem('adresse')!=="null")?localStorage.getItem('adresse'):null}</p>
              <p>{(localStorage.getItem('entet_tiket_2')&&localStorage.getItem('entet_tiket_2')!=="null")?localStorage.getItem('entet_tiket_2'):null}</p>
              <p>{(localStorage.getItem('fecebook')&&localStorage.getItem('fecebook')!=="null")?localStorage.getItem('fecebook'):null}</p>
              </div>
            <hr />
              <div className="rowGrid" id="row-2">
                <p>Ticket n: <b>{detail.numero}</b></p>
                <p>Date: <b>{detail.date_tiket}</b></p>
              </div>
              <div className="rowGrid" id="row-2">
                <p>Client: <b>{detail.client}</b></p>
                <p>Vendeur: <b>{detail.vendeur}</b></p>
              </div>
              <div className="rowGrid" id="row-2">
                {(detail.tel!=null)?<p>Tel client: <b>{detail.tel}</b></p>:<p></p>}
                <p>Ancien solde: <b>{new Intl.NumberFormat().format(detail.ex_solde)}</b></p>
              </div>
                <DataTable 
                  persistTableHead
                  columns={(localStorage.getItem('tiketWidth')==="1")?columns2:columns}
                  data={detail.ventes}
                  noHeader={true}
                  customStyles={(localStorage.getItem('tiketWidth')==="1")?customStylesTicket2:customStylesTicket}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
                />
               <div className="rowGrid" id="row-3">
                <p>Nombre d'article: <b>{(detail.ventes)?detail.ventes.length:0}</b></p>
                <p >Total: <b>{new Intl.NumberFormat().format(detail.total)}</b></p>
              </div>
              <br/>
               <div className="rowGrid" id="row-4">
                <p>Versement: <b>{new Intl.NumberFormat().format(detail.versement)}</b></p>
                <p>Solde restant: <b>{new Intl.NumberFormat().format(detail.reste)}</b></p>
              </div>
              <hr />
              <p style={{textAlign:"center"}}>Merci pour votre fidélité {/*- <b>www.irahaserv.com</b>*/}</p>
            </div>{/*
            <hr className={(localStorage.getItem('tiketWidth')==="1")?"dash2":"dash"}/>
             <div className={(localStorage.getItem('tiketWidth')==="1")?"codeQr2":"codeQr"}>
              <QRCode size={200} level ="L" value={zlib.deflateSync(JSON.stringify(detail)).toString('base64')} /></div>*/}
        </DialogContent>
      </Dialog>
      </div>
  );
}

export class OutsideClickHandler extends React.Component {
  wrapperRef = createRef();

  static defaultProps = {
    onOutsideClick: () => {}
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.props.onOutsideClick();
    }
  };

  render() {
    const { children } = this.props;

    return <div ref={this.wrapperRef}>{children}</div>;
  }
}

export const AlertCompo=(props)=>{
  const classes=useStyles2();
  return (
    <div className={(props.dialg)?classes.root2:classes.root}>
      <Collapse in={props.open}>
        <Alert
        variant="filled"
        severity={props.severity}
        style={{whiteSpace: "pre-wrap",maxHeight:'100%',overflow:"auto"}}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                props.setOpen();
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          {(props.message.includes('PDOException')===true)?"Une erreur s'est produite":props.message}
        </Alert>
      </Collapse>
    </div>
  )
}
export const NotAllowedComponent =((props) => {
  return (
  <div className='nodata'>
    <div style={{width:"100%",padding:'0px auto'}}>
    <img src={NotAllowed} alt="NoData" className='notAllowed' /> 
    </div>  
    <p className='message'>{props.message}</p>  
  </div>        
  );
});


export function Detail_Bon(props) {
  const classes = useStyles();
  const componentRef = useRef();
  const componentRef1 = useRef();
  const param= useContext(ParamContext)
  const customStyles=require('../css/datatable-custom')
  const [open, setOpen] = useState(false);
  const [bon, setBon] = useState([]);
  const [boncat,setBoncat] = useState([]);
  const [choix,SetSelectCoix] = useState('');
  const [affich,SetAffich] = useState(false);
  

  const handleClickOpen = () => {  
      setOpen(true); 
      fetchBon();
      SetAffich(true)
      SetSelectCoix('')
  };
  const handleClose = () => { setOpen(false); };

  const fetchBon=async()=>{
    const response = await Gfunc.axiosPostAction("_ach.php","getbon",{idb:(props.row.ida)?props.row.ida:(props.val==='prod')?props.row.bonId:props.row.DT_RowId.substr(1)})
    Gfunc.axiosResponse(response,()=>{
        setBon(response.data.jData);
    })
  }
  const fetchCat=async()=>{
    const response = await Gfunc.axiosPostAction("_ach.php","getboncat",{idb:(props.row.ida)?props.row.ida:(props.val==='prod')?props.row.bonId:props.row.DT_RowId.substr(1)})
    Gfunc.axiosResponse(response,()=>{
       setBoncat(response.data.jData);
    })
  }
  useEffect(() => {
    if(choix==='cat'){ fetchCat();}
  },[choix,fetchCat]);
  
  const columns = useMemo(()=>[   
        {
            name: "Description",
            selector: row=>row.produit,
        },
        {
            name: "QTE.",
            width:'110px',
            selector: row=>row.qte,
            cell:(row)=>{
              return (
              <div>
                {new Intl.NumberFormat().format(row.qte)}
              </div>
              )
            }
        },
        {
            name: "Prix",
            width:'110px',
            cell:(row)=>{
              return (
              <div>
                {new Intl.NumberFormat().format(row.achat)}
              </div>
              )
            }
        },
        {
            name: "Total",
            selector: row=>row.mtn,
            width:'160px',
            cell:(row)=>{
              return (
              <div>
                {new Intl.NumberFormat().format(row.mtn)}
              </div>
              )
            }
        },
      ],[])
  const columnsc = useMemo(()=>[   
        {
            name: "Boutiqa",
            selector: row=>row.nom_bq,
        },
        {
            name: "Categorie",
            width:'140px',
            selector: row=>row.categorie,
        },
        {
          name: "Irahati",
          width:'240px',
          selector: row=>row.nom_ir,
      },
        {
            name: "Prix",
            width:'90px',
            cell:(row)=>{
              return (
              <div>
                {new Intl.NumberFormat().format(row.prix)}
              </div>
              )
            }
        },
       
      ],[])
return (
  <div>
    {(props.icon)?<Tooltip title='Imprimer'><Print className={classes.blackIcon} fontSize="small" onClick={handleClickOpen}/></Tooltip>
    :<div  className="clickable" onClick={handleClickOpen}>{(props.val==='prod')?props.row.bonNum:props.num}</div>}
      <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" PaperProps={paperProps2}>
        <DialogTitleExp className={classes.topDivTitle} onClose={handleClose}>
           <div className='DialogTitle'>
              <Print style={{width:'20px',height:'20px'}} />
              <label>Détail du Bon</label>
            </div>
        </DialogTitleExp>
        <DialogContent>
          <div style={{width:'30%',float:'right',marginBottom:'10px'}}>
            <TextField select  color="primary" label="Choisir Bon..." size="small"  inputProps={{ tabIndex: "4"}}
                       onClick={(e) => e.stopPropagation()} value={choix}  onChange={e =>SetSelectCoix(e.target.value)}
                       className='smallText' margin="dense" variant="outlined">
                              <MenuItem  value='bon' >Imprimer bon d'achat</MenuItem>
                              <MenuItem  value='cat'>Imprimer bon du catalogue</MenuItem>
            </TextField>
          </div>
          {
            (choix==='bon' || (affich===true && choix===''))?
            <div className='bon-ach' ref={componentRef}>
            <div className='bon-head'> 
              <div id='infoG'>
                <p id='infos'><b>iRaha services</b></p> 
                <p id='infos'>Magasin: <b>{(localStorage.getItem('mag'))?localStorage.getItem('mag'):'---'}</b></p>
                <p id='infos'>Achteur: <b> {(bon.achteur)?bon.achteur:'---'}</b></p>
              </div>
              <img src={param.logo+localStorage.getItem('logo')} id='logo' alt='logo'/>
              <div id='infoD'>
                <p id='infos'><b>{(bon.frns)?bon.frns:'---'}</b></p> 
                <p id='infos'><b>{(bon.frns_adr)?bon.frns_adr:'---'}</b></p>
                <p id='infos'><b>{(bon.frns_tel)?bon.frns_tel:'---'}</b></p>
                <p id='infos'>Le <b>{(bon.date)?bon.date:'---'}</b></p>
              </div>
            </div>
           <div>
           <DataTable 
                  persistTableHead
                  columns={columns}
                  data={bon.achats}
                  noHeader={false}
                  title={"Bon d'achat N°  "+bon.numero}
                  customStyles={customStyles.customStyles} 
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
                />
            
            <div id='solde'>
              {(bon.length>0)?<div id='a'>
                <p id='mtn'>Total:<b> {new Intl.NumberFormat().format(bon.total)}</b></p>
                <p id='mtnM'>Ancien solde:<b> {new Intl.NumberFormat().format(bon.pre_sold)}</b></p>
                <p id='mtn'>Nouveau solde:<b> {new Intl.NumberFormat().format(bon.post_sold)}</b></p>
              </div>:null }
            </div> 
          </div> 
          </div>:null
        }
        {
            (choix==='cat')? 
            <div className='boncat' ref={componentRef1}>
            <div className='bon-head'> 
              <div id='infoG'>
                <p id='infos'><b>iRaha services</b></p> 
                <p id='infos'>Magasin: <b>{(localStorage.getItem('mag'))?localStorage.getItem('mag'):"---"}</b></p>
                <p id='infos'>Achteur: <b>{(bon.achteur)?bon.achteur:'---'}</b></p>
              </div>
              <img src={param.logo+localStorage.getItem('logo')} id='logo' alt='logo'/>
            </div>
            <div>
              <DataTable 
                  persistTableHead
                  columns={columnsc}
                  data={boncat}
                  noHeader={false}
                  title={"Bon de catalogue relatif au bon d'achat N°  "+bon.numero}
                  customStyles={customStyles.customStyles}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
               />
            </div>
            </div>:null
        }
        </DialogContent>
        <DialogActions>
           {(choix==="bon" || (affich===true && choix===''))?
             <ReactToPrint trigger={() => <Button  variant="contained" color="primary"  startIcon={<Print/>}> Imprimer </Button>} 
                           content={() => componentRef.current} />
            : <ReactToPrint trigger={() => <Button variant="contained" color="primary"  startIcon={<Print/>}> Imprimer </Button>}
                           content={() => componentRef1.current} />}
        </DialogActions>
     </Dialog>
    </div>
);
}

export function Detail_swap(props) {
  const componentRef = useRef();
  const classes=useStyles();
  const [open, setOpen] = useState(false);
  const [tick, settick] = useState([]);
  const param= useContext(ParamContext)

  const fetchtick=async()=>{
    const response = await Gfunc.axiosPostAction("_ret.php","gettik",{retour:props.row.DT_RowId.substr(1)})
    Gfunc.axiosResponse(response,()=>{
        settick(response.data.jData);
    })
  }
  const handleClickOpen = () => {  
    setOpen(true); 
    props.handleFetchMag();
    fetchtick();
  };
  const handleClose = () => { setOpen(false); };

  return (
  <div>
    <Tooltip title={props.title}>
       <Receipt className={classes.blueIcon} onClick={handleClickOpen} fontSize="small"/>
    </Tooltip>
    <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={paperProps}
    >
      <DialogTitleExp className={classes.topDivTitle}  onClose={handleClose}>
          <div style={{display:"flex",gridGap:"10px",alignItems:"center"}}>
            <Print style={{width:'20px',height:'20px'}}/>
            <label>Détail du ticket</label> 
          </div> 
      </DialogTitleExp>
      <DialogContent ref={componentRef} >
          <div id="ticket" >
              <div id='ticketHead'>
                          <img src={param.logo+props.mag.logo} id='logo_ticket' alt='logo'/>
                          <h3  id='titre' style={{color:'grey',paddingBottom:'5px'}}>{props.mag.entet_tiket_1}</h3>
                          <p>{props.mag.adresse}</p>
                          <p>{props.mag.tel}</p>
                          <p>{props.mag.facebook}</p>
                          </div>
                        <hr />
                    <div id='divinfo'>
                    <div  id="row1"> 
                        <div  style={{textAlign:"start"}}> 
                          <p><b>Client:</b> {props.row.client}</p> 
                        </div>
                        <div  style={{textAlign:"start"}}> 
                          <p><b>Agent:</b> {localStorage.getItem('name')}</p> 
                        </div>
                        {
                        (props.row.reponseId===1)? 
                          <div style={{textAlign:"end"}}>
                          <p><b>Date de swap:</b> {tick.date_swap}</p>
                        </div>:null
                        }
                        {
                        (props.row.reponseId===2)? 
                          <div style={{textAlign:"end"}}>
                          <p><b>Date:</b> {tick.date_remboursement}</p>
                        </div>:null
                        }
                    </div>
                  {
                  (props.row.reponseId===1)? 
                  <div id="prod">
                      <div >
                          <h3>{props.row.produit}</h3>
                      </div>
                      <div >
                          <h3>{props.row.serie}</h3>
                      </div>
                  </div>:null
                  }

                {
                  (props.row.reponseId===2)? 
                  <div id='row2'>
                      <div >
                          <h3>{props.row.produit}</h3>
                      </div>
                      <div style={{textAlign:"right"}}>
                        <h3>{new Intl.NumberFormat().format(tick.mtn)} </h3>
                      </div>
                  </div>:null
                }
                </div>
          </div>
    </DialogContent>
    <DialogActions>
       <ReactToPrint trigger={() => <Button variant="contained" color="primary"  startIcon={<Print />}>Imprimer</Button>}
                     content={() => componentRef.current}/>    
    </DialogActions>
    </Dialog>
    </div>
);
}

export function AutocompleteS({service,action,row,list,className,defaultValue,id,name,onOpen,attr,handleAlert,from,rowId,dateupd,agentupd,
  datein,agentin,handleResponse,handleResponsee,props}) {
  const classes = useStyles();
  const [selecto,setSelecto]=useState('')
  const [Item,setItem]=useState('')
  const [ItemN,setItemN]=useState('')
  const [value,setValue]=useState('')

  const handleSelect=(row,att)=>{
    setSelecto(row[att])
    setItem(row[rowId])
    setItemN(att)
  }
  const handleOnEditSelect=async(val,attList,f=()=>{})=>{
    const rowid=(rowId!==undefined)?rowId:"DT_RowId"
    if(row[id]!==val){
      const edt=val
      let response=null
      if(from==="monJrn")
      response=await Gfunc.axiosPostAction(service,action,{tiketId:row.tiketId,clientId:edt}) 
      else
      response=await librery.axiosEdit2(service,action,row,(edt!=='empty')?edt:'',id,rowid) 
      if(response.data.err&&response.data.err!=='1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        const objIndex =list.findIndex((obj => obj.id === edt));
        //handleRowChange(row,name,list[objIndex][attList])
        row[name]=(edt!=='empty')?list[objIndex][attList]:""
        setValue(row[name])
        row[id]=(edt!=='empty')?edt:''
        if(handleResponse){
          handleResponse(response.data)
        }
        if(handleResponsee){
          handleResponsee(edt)
        }
        setSelecto('')
        setValue('')
        setItem('')
    })
  }
  }
  return(
    <Tooltip title={(datein!==undefined||dateupd!==undefined)?((!dateupd)?"Entré le: "+datein +' par: ' +agentin:"Édité le: "+dateupd +' par: ' +agentupd):''}>
    <div className={(props==='produit')?'smallSelectProd':(from==='inf')?"smallSelectDT2":"smallSelectDT"}>
  {
    ((row[rowId]===Item || from==='inf') && row[name]===selecto && ItemN===name)?
    <ClickAwayListener onClickAway={()=>{setSelecto('');setValue('')}}>
    <div>
      <Autocomplete 
        disableClearable
        id={id}
        key={row[id]}
        onBlur={()=>{setSelecto('');setValue('')}}
        loading={list.length===0}
        loadingText="Chargement..."
        defaultValue={row[name]}
        //getOptionSelected={(option, value) => option[attr] === value}
        onChange={(e,newValue)=>{e.stopPropagation();handleOnEditSelect(list[Gfunc.getIndex(list,newValue,attr)].id,attr)}}
        onOpen={onOpen}
        options={list.map((option) => option[attr])}
        inputprops={{className:classes.diagFormInput}}
        renderInput={(params) => (
        <TextField autoFocus {...params} color="primary" variant="outlined" size="small"/>)} /></div></ClickAwayListener>
      :(row[name]!==null)? <div className={(className!==undefined)?className:"content-editable"} id={id} onClick={()=>{handleSelect(row,name)}}>{(value==='')?row[name]:value}</div>
      :<div className={(className!==undefined)?className:"div-vide"} id={id} onClick={()=>{handleSelect(row,name)}}></div>
  }
  </div></Tooltip>
  )
}
export function DatePickerUpd({service,action,name,row,selecto,ItemN,Item,setItem,setSelecto,handleSelect,minDate,maxDate,extra,
                               handleAlert,rowId,dateupd,agentupd,datein,agentin,className}) {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date());


  const handleOnEditDate=useCallback(async(date,row,att)=>{
    const rowid=(rowId!==undefined)?rowId:"DT_RowId"
    if(Object.prototype.toString.call(date) === "[object Date]"&&!isNaN(date)){
      const edt=Gfunc.formatDate(date);
    if(row[att]!==edt){
      const attrib=row[att];
      const response=await librery.axiosEdit2(service,action,row,edt,name,rowid,"text") 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
        setSelecto('')
      }else
      Gfunc.axiosResponse(response,()=>{row[att]=edt})
      if(response.data.err) {row[att]=attrib}
    }else {row[att]=edt}
    setSelectedDate(new Date())
   }
  },[action,handleAlert,name,service,setSelecto]);

  

  return(
    <Tooltip title={(datein!==undefined||dateupd!==undefined)?((!dateupd)?"Entré le: "+datein +' par: ' +agentin:"Édité le: "+dateupd +' par: ' +agentupd):''}>
    <div className={className}>
  { (row[name]===selecto && row[rowId]===Item && ItemN===name)?
             <ClickAwayListener onClickAway={()=>{setItem('');setSelecto('')}}>
               <div>
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  size="small"
                  TextFieldComponent={(params) => (
                      <TextField {...params} color="primary" variant="outlined" size="small"/>)}
                        className={classes.selectEdit2}
                        inputProps={{className:classes.inputEdit2}}
                        KeyboardButtonProps={{style:{padding:"0px"}}}
                        maxDate={(maxDate===undefined)?new Date():maxDate}
                        minDate={minDate}
                        invalidDateMessage="Le format de la date est incorrect"   
                        maxDateMessage="La date ne doit pas être supérieure à la date maximale" 
                        inputVariant="outlined"
                        variant="inline"
                        format="dd-MM-yyyy"
                        name="iDate"
                        value={selectedDate}
                        keyboardIcon={<Today className={classes.whiteSmallIcon}/>}
                        onChange={(date,e)=>{
                            setSelectedDate(date);
                            handleSelect(row,name);
                            handleOnEditDate(date,row,name)
                        }}
                        required
                />
              </MuiPickersUtilsProvider></div></ClickAwayListener>:
            <div className='content-editable' onClick={()=>{setSelectedDate(new Date(row[name]));
                 if(row[name]){ var dateParts = row[name].split("-");
                  var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                  setSelectedDate(new Date(dateObject));}
                  else
                  setSelectedDate(new Date());
                  handleSelect(row,name);
            }}>{(extra!=='')?(row[name].substring(0, 5)+extra):(row[name])} </div>}
  </div></Tooltip>
  )
}

export function SituationFournisseur(props) {
  const customStyles=require('../css/datatable-custom')
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [data, setData] = useState([]);
  const componentRef = useRef();
  const [Ndate, setNDate] = useState(new Date());
  const param= useContext(ParamContext)

  const handleClickOpen = () => {  
      setOpen(true); 
      setData(props.data);
      setNDate(new Date);
  };
  const handleClose = () => { setOpen(false); };


  const columns = useMemo(()=>[   
        {
            name: "Date",
            selector: row=>row.date,
            cell:(row)=>{
              return (
              <div>
                {row.date +' '+ row.heure}
              </div>
              )
            }
        },
        {
            name: "montant",
            minWidth:'110px',
            selector: row=>row.mtn,
            style:customStyles.numberColumn,
            cell:(row)=>{
              return (
              <div>
                {new Intl.NumberFormat().format(row.mtn)}
              </div>
              )
            }
        },
        {
            name: "Solde",
            minWidth:'110px',
            style:customStyles.numberColumn,
            cell:(row)=>{
              return (
              <div>
                {new Intl.NumberFormat().format(row.solde)}
              </div>
              )
            }
        },
      ],[]);
  
return (
  <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} 
              startIcon={<Print fontSize='small' />}>Imprimer</Button>
    
      <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" PaperProps={paperProps2}>
      <DialogTitleExp className={classes.topDivTitle} id="simple-dialog-title" onClose={handleClose}>
           <div className='DialogTitle'>
              <Print style={{width:'20px',height:'20px'}} />
              <label>Situation fournisseur</label>
            </div>
      </DialogTitleExp>
      <DialogContent>
         { (data.length>0)?<>
            <div className='bon-ach' ref={componentRef}>
            <div className='bon-head'> 
              <div id='infoG'>
                <p id='infos'><b>{localStorage.getItem('etiquette')}</b></p> 
                <p id='infos'>Magasin: <b>{localStorage.getItem('mag')}</b></p>
                <p id='infos'>Date: <b>{(Ndate)?(Gfunc.formatDate(Ndate)+' à '+("0" + Ndate.getHours()).slice(-2)+ ":" + ("0" + Ndate.getMinutes()).slice(-2)):null}</b></p>
              </div>
              <img src={param.logo+localStorage.getItem('logo')} id='logo' alt='logo'/>
              <div id='infoD'>
                {console.log(data)}
                <p id='infos'>Fournisseur: <b> {props.frns}</b></p>
                <p id='infos'>du: <b> {Gfunc.minDate(data,'date','heure').toString()}</b></p>
                <p id='infos'>Jusqu'à: <b> {Gfunc.maxDate(data,'date','heure').toString()}</b></p>
              </div>
            </div>
           <div>
           <DataTable 
                  persistTableHead
                  columns={columns}
                  data={data}
                  noHeader={false}
                  title={"Situation fournisseur"}
                  customStyles={customStyles.customStyles}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
                />
          </div> 
          </div>
          </>:null}
        </DialogContent>
        <DialogActions>
            <ReactToPrint
                 trigger={() => <Button variant="contained" color="primary"  startIcon={<Print />}>Imprimer</Button>}
                 content={() => componentRef.current} 
            />
        </DialogActions>
     </Dialog>
    </div>
);
}

// editable input of type number 
export function InputNbrEditable({service,handleAlert,row,att,action,id,className,value,idi,min,max,traetResp,dateupd,agentupd,datein,agentin,positif,doBefore,charge,supp,suppAtt,doAfter,checkForUpd}) {
  //const customStyles=require('../css/datatable-custom')
  const api=require('../js/Apis')
  const [open, setOpen] = useState('');
  const [val, setVal] = useState(value);
  const [changed, setChanged] = useState(false);
 // const classes = useStyles();
  const [load, setLoad] =useState(false);
  
 //update field for formatted numbers
 const handleOnEdit2=useCallback(async(val)=>{
  if(val!==''){
  var mn = false;
  var mx = false;
    if(doBefore) doBefore()
    setLoad(true)
  const edt1=val.toString()
  var edt=JSON.parse(edt1.replace(/\s/g, ''));
  if (min) if(min>edt) mn=true;
  if (max) if(max<edt) mx=true;
      if(row[att]!==edt&&!isNaN(edt)){
        if(!mx && !mn){const attrib=row[att]; 
        //if(positif && edt<0) edt=-edt
        const response=await librery.axiosEdit2(service,action,row,(positif && edt<0)?-edt:edt,att,id,"number",supp,suppAtt) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
          setVal(edt);
          row[att]=edt
         // e.target.value=new Intl.NumberFormat().format(edt)
          if(traetResp!==undefined)
            traetResp(response.data)
            if(charge==='charge'){api.Caisse()}
        })
        if(response.data.err) {setVal(attrib)}
      }else setVal(row[att])
      }
      setLoad(false)
      
    }else {setVal(value)}
    if(doAfter) doAfter()
    setOpen('')
},[librery,Gfunc,row,att,action,id,traetResp,doBefore,value]);


useEffect(async() => {
  if(open===row[id]){
    const elem=document.getElementById(idi)
    if (elem) {elem.focus(); elem.select()}
  }
}, [open]);
  
return (
  <Tooltip title={(datein!==undefined||dateupd!==undefined)?((!dateupd)?"Entré le: "+datein +' par: ' +agentin:"Édité le: "+dateupd +' par: ' +agentupd):''}>
  <div className='InputNbrEditable'>
  {(open!==row[id])?<div id={idi} className={(className!==undefined)?className:"content-editable" }style={{textAlign:'end',justifyContent:"end"}} onClick={()=>setOpen(row[id])}>{(val!==null)?new Intl.NumberFormat().format(val):''}</div>
  :<ClickAwayListener onClickAway={e => {if(changed){handleOnEdit2(val);setOpen('')}else {setOpen('');if(doAfter) doAfter()}}}>
    {(!load)?
  <input
      id={idi}
      type='number'
      value={(val===null)?'':val}
      data-column="item"
      className={(className!==undefined)?className:"content-editable"}
      onChange={(e)=>{
        if(value!==parseInt(e.target.value)) { if(checkForUpd) checkForUpd();
           setChanged(true)} else setChanged(false)
        if(min!==undefined&&min!=null&&min!==''){
         if(min>=0&&e.target.value.includes('-'))
          {handleAlert(true,'error',"Veuillez saisir un montant positif!");setVal(row[att]);setOpen('')}
          else{
          setVal(e.target.value)}
        }else 
        if(max!==undefined&&max!=null&&max!==''){
         if(!e.target.value.includes('-'))
          {handleAlert(true,'error',"Veuillez saisir un montant negatif !");setVal(row[att]);setOpen('')}
          else{
          setVal(e.target.value) }
        }else setVal(e.target.value)
      }
    }
      autoFocus
      min={min}
      max={max}
      onKeyPress={(e)=>{
        if(e.key==="Enter"){
        handleOnEdit2(val);}
        }
    }
      //onBlur={e => {handleOnEdit2(e);setOpen('')}}
  />:<CircularProgress style={{width:"12px",height:"12px"}} color="inherit" />}
  </ClickAwayListener>
  }
  </div>
  </Tooltip>

);
}
//imprimer une etiquette 
export function ImprimerEtiq(props) {
  const classes=useStyles();
  const componentRef = useRef();
  var Barcode = require('react-barcode');
  
return (
  <div>
    <Tooltip title="Imprimer l'étiquette" >
      <ReactToPrint trigger={() => <Print fontSize="small" className={classes.blackIcon}/> } content={() => componentRef.current} />
    </Tooltip>
      <div hidden>
        <div ref={componentRef}>
          <div className="barCodeImpSmall">
              <div className="barCodeN">
                <p>{props.produit}</p>
                <Barcode height={25} width={2} fontSize={8} margin={5} textAlign={'center'} value={('00000000' + props.pdetId).substring(Math.min(("" + props.pdetId).length, 8))} />
              </div>
              {JSON.parse(localStorage.getItem('etiqDisplayPrice'))?<div className="gridEtiq"><p style={{justifyItems:'flex-start'}}>{localStorage.getItem('etiquette')} </p><p id="priceEtiq">Prix:<b>{new Intl.NumberFormat().format(props.prix)}</b>DA</p></div>:<p>{localStorage.getItem('etiquette')}</p>}
          </div>
        </div>
      </div>
    </div>
);
} 
//imprimer des etiquettes 
export function ImprimerEtiqPlus(props) {
  const classes=useStyles();
  var Barcode = require('react-barcode');
  const componentRef = useRef();
  const [open, setOpen] = useState(false);
  const [qte, setQte] = useState('');
  const etq = [];


  const handleClickOpen = () => {  
    setOpen(true); 
    setQte(props.qte)
  };

  const handleClose=()=>{
    setOpen(false); 
  }
  

  for (let i = 0; i <parseInt(qte); i++) {
    etq.push(
      <div key={i} className="barCodeImpSmall">
          <div className="barCodeN">
            <p>{props.produit}</p>
            <Barcode height={25} width={2} fontSize={8} margin={5} textAlign={'center'} value={('00000000' + props.pdetId).substring(Math.min(("" + props.pdetId).length, 8))} />
          </div>
          {JSON.parse(localStorage.getItem('etiqDisplayPrice'))?<div className="gridEtiq"><p style={{justifyItems:'flex-start'}}>{localStorage.getItem('etiquette')} </p><p is="priceEtiq">Prix: <b>{new Intl.NumberFormat().format(props.prix)}</b>DA</p></div>:<p>{localStorage.getItem('etiquette')}</p>}
    </div>);
  }
  
return (
  <div>
    <Tooltip title="Imprimer plusieurs étiquette">
      <BigPrinter fill='black' style={{height:'20px',width:'19px',padding:"1px"}} onClick={handleClickOpen}/>
    </Tooltip>
    <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" 
            PaperProps={paperPropsSmallTall}>
      <DialogTitleExp className={classes.topDivTitle} onClose={handleClose}>
          <div className='DialogTitle'>
              <Print style={{width:'20px',height:'20px'}}/>
              <label>Imprimer plusieurs étiquettes</label>
          </div>
      </DialogTitleExp>
      <DialogContent>
          <div>
            <TextField margin="dense" type='number' label="Quantité" id="iQte" size="small"  value={qte} variant="outlined"
                      className='smallText' inputProps={{className:classes.diagFormInput}} InputProps={{ inputProps: { min: 0 } }}
                      onChange={e =>setQte(e.target.value)} required/>
          <div hidden> 
            <div ref={componentRef}>
              {etq}
            </div>
          </div>
          </div>
      </DialogContent>
      <DialogActions>
           <ReactToPrint trigger={() => <Button  variant="contained" color="primary"  startIcon={<Print/>}> Imprimer</Button> } 
                         content={() => componentRef.current} />
      </DialogActions>
    </Dialog>
    </div>
);
}

//imprimer une etiquette 
export function ImprimerEtiqAll(props) {
  const componentRef = useRef();
  const classes=useStyles();
  var Barcode = require('react-barcode');

  const [etq,setEtq]=useState([])
  const [control,setControl]=useState(false)


  const handleImpEtiq=async()=>{
    let etqs=[]
      for (let i = 0; i <props.data.length; i++) {
        for (let j = 0; j <parseInt(props.data[i].qte); j++) {
          etqs.push(
            <div key={i+'-'+j} className="barCodeImpSmall">
                <div className="barCodeN">
                  <p>{props.data[i].produit}</p>
                  <Barcode height={25} width={2} fontSize={8} margin={5} textAlign={'center'} value={('00000000' + props.data[i].produitId).substring(Math.min(("" + props.data[i].produitId).length, 8))} />
                </div>
                  {JSON.parse(localStorage.getItem('etiqDisplayPrice'))?<div className="gridEtiq"><p style={{justifyItems:'flex-start'}}>{localStorage.getItem('etiquette')} </p><p id="priceEtiq" >Prix:<b>{new Intl.NumberFormat().format(props.data[i].vente)}</b>DA</p></div>:<p>{localStorage.getItem('etiquette')}</p>}
            </div>);
        }
      }
      setEtq(etqs)
      setControl(!control)
  }
  
return (
  <div>
      <ReactToPrint onBeforeGetContent={handleImpEtiq} trigger={() => <Button className={classes.topTabBtn}  variant="contained" color="secondary" 
                    startIcon={<BigPrinter fill='black' style={{height:'20px',width:'19px',padding:"1px"}}/>}> Imprimer toutes les étiquettes
                               </Button>} content={() => componentRef.current} />
      <div hidden>
        <div ref={componentRef}>
        {etq}
        </div>
      </div>
    </div>
);
}

//Custum shortCut button
export function RaccBtnFixed(props) {
  const classes=useStyles();
  const [clickedRed,setClickedRed]=useState(false);
  const [clickedGrey,setClickedGrey]=useState(false);

   // handle what happens on key press
   const handleKeyPress = useCallback((event) => {
      if(props.racc&&!props.disabled){
        const ctrl=props.racc.split("+").includes("ctrl")
        const alt=props.racc.split("+").includes("alt")
        const shift=props.racc.split("+").includes("shift")
        if(event.ctrlKey===ctrl&&event.altKey===alt&&event.shiftKey===shift&&(event.key===props.racc.split("+").pop()||(event.key==="Escape"&&props.racc.split("+").pop()==="Echap"))&&event.target.id!=="iLook"&&event.target.id!=="iCode"&&!(event.target.classList.contains("content-editable"))){
          event.preventDefault();
          //props.onClick()
          const elem= document.getElementById(props.id)
          if(elem){
            elem.focus();
            elem.click();            
          }
        }
      }
     // event.preventDefault();
   },[props]); 

   // handle what happens on key press
   const handleClick =(event) => {
     const clName=props.className.toString()
     if(clName.includes("Grey") || clName.includes("Prod")){
       setClickedGrey(true);
     }
     if(clName.includes("Red")){
       setClickedRed(true);
     }
   } 

   useEffect(() => {
    // attach the event listener
      document.addEventListener('keydown', handleKeyPress);
    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

return (
  
    <ClickAwayListener onClickAway={()=>{setClickedRed(false);setClickedGrey(false);}}>
      <Tooltip title={props.tip}>
   <span>
     {(!props.digital)?
  <Button id={props.id} variant="contained" /*onBlur={()=>setClicked(false)}*/ className={(clickedRed)?props.className+' '+classes.racClickedRed:(clickedGrey)?props.className+' '+classes.racClickedGrey:props.className} onClick={()=>{props.onClick();handleClick()}} disabled={props.disabled}>
  <div className='racIcon centerContent'>
  {(props.path)?
      <Iconn path={mdi[props.path]}
      size={0.7}
      color={props.iconColor}
      />
      :<Iconn path={mdi["mdiDevices"]}
      size={0.7}
      color={props.iconColor}
      />
      }
     <p className="racBtnLabel">{props.text.substring(0,10)}</p>
     </div>
     <p className="racBtnLabel">{props.racc}</p>
     </Button>:  
  <Button id={props.id} variant="contained" /*onBlur={()=>setClicked(false)}*/ className={(clickedRed)?classes.racProdtouch+' '+classes.racClickedRed:(clickedGrey)?classes.racProdtouch+' '+classes.racClickedGrey:classes.racProdtouch} onClick={()=>{props.onClick();handleClick()}} disabled={props.disabled}>
        <div>
          <div className='racIconF'>
          {(props.path)?
          <Iconn path={mdi[props.path]}
          size={0.7}
          color={props.iconColor}
          />
          :<Iconn path={mdi["mdiDevices"]}
          size={0.7}
          color={props.iconColor}
          />}
          </div>
          <p className="racBtnLabelTouch">{props.text.substring(0,10)}</p> 
        </div>
     </Button>}
     </span>
     </Tooltip>
     </ClickAwayListener>

);
}
//Custum shortCut button
export function RaccBtnStatic(props) {
  const classes=useStyles();
  const [clickedRed,setClickedRed]=useState(false);
  const [clickedGrey,setClickedGrey]=useState(false);

   // handle what happens on key press
   const handleKeyPress = useCallback((event) => {
      if(props.racc&&!props.disabled &&!(event.target.classList.contains("content-editable"))){
        const ctrl=props.racc.split("+").includes("ctrl")
        const alt=props.racc.split("+").includes("alt")
        const shift=props.racc.split("+").includes("shift")
        if(event.ctrlKey===ctrl&&event.altKey===alt&&event.shiftKey===shift&&(event.key===props.racc.split("+").pop()||(event.key==="Escape"&&props.racc.split("+").pop()==="Echap"))){
          event.preventDefault();
          //props.onClick()
          const elem= document.getElementById(props.id)
          if(elem){
            elem.focus();
            elem.click();            
          }
        }
      }
     // event.preventDefault();
   },[props]); 

   // handle what happens on key press
   const handleClick =(event) => {
     const clName=props.className.toString()
     if(clName.includes("Grey") || clName.includes("Prod")){
       setClickedGrey(true);
     }
     if(clName.includes("Red")){
       setClickedRed(true);
     }
   } 

   useEffect(() => {
    // attach the event listener
      document.addEventListener('keydown', handleKeyPress);
    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

return (
  
    <ClickAwayListener onClickAway={()=>{setClickedRed(false);setClickedGrey(false);}}>
      <Tooltip title={props.tip}>
   <span>
     {(!props.digital)?
  <Button id={props.id} variant="contained" /*onBlur={()=>setClicked(false)}*/ className={(clickedRed)?props.className+' '+classes.racClickedRed:(clickedGrey)?props.className+' '+classes.racClickedGrey:props.className} onClick={()=>{props.onClick();handleClick()}} disabled={props.disabled}>
  <div className='racIcon centerContent'>
  {(props.path==="none")?null:(props.path)?
      <Iconn path={mdi[props.path]}
      size={0.7}
      color={props.iconColor}
      />
      :<Iconn path={mdiSim}
      size={0.7}
      color={props.iconColor}
      />
      }
     <p className="racBtnLabel">{props.text.substring(0,10)}</p>
     </div>
     <p className="racBtnLabel">{props.racc}</p>
     </Button>:  
  <Button id={props.id} variant="contained" /*onBlur={()=>setClicked(false)}*/ className={(clickedRed)?classes.racAirtouch+' '+classes.racClickedRed:(clickedGrey)?classes.racProdtouch+' '+classes.racClickedGrey:classes.racAirtouch} onClick={()=>{props.onClick();handleClick()}} disabled={props.disabled}>
 <div>
      <div className='racIconF'>
      {(props.path)?
      <Iconn path={mdi[props.path]}
      size={0.7}
      color={props.iconColor}
      />
      :<Iconn path={mdiSim}
      size={0.7}
      color={props.iconColor}
      />
      }
      </div>
         <p className="racBtnLabelTouch">{props.text.substring(0,10)}</p> 
      </div>
     </Button>}
     </span>
     </Tooltip>
     </ClickAwayListener>

);
}

//Custum shortCut button
export function RaccBtnStaticStand(props) {
  const classes=useStyles();
  const [clickedRed,setClickedRed]=useState(false);
  const [clickedGrey,setClickedGrey]=useState(false);

   // handle what happens on key press
   const handleKeyPress = useCallback((event) => {
      if(props.racc&&!props.disabled &&!(event.target.classList.contains("content-editable"))){
        const ctrl=props.racc.split("+").includes("ctrl")
        const alt=props.racc.split("+").includes("alt")
        const shift=props.racc.split("+").includes("shift")
        if(event.ctrlKey===ctrl&&event.altKey===alt&&event.shiftKey===shift&&(event.key===props.racc.split("+").pop()||(event.key==="Escape"&&props.racc.split("+").pop()==="Echap"))){
          event.preventDefault();
          //props.onClick()
          const elem= document.getElementById(props.id)
          if(elem){
            elem.focus();
            elem.click();            
          }
        }
      }
     // event.preventDefault();
   },[props]); 

   // handle what happens on key press
   const handleClick =(event) => {
     const clName=props.className.toString()
     if(clName.includes("Grey") || clName.includes("Prod")){
       setClickedGrey(true);
     }
     if(clName.includes("Red")){
       setClickedRed(true);
     }
   } 

   useEffect(() => {
    // attach the event listener
      document.addEventListener('keydown', handleKeyPress);
    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

return (
  
    <ClickAwayListener onClickAway={()=>{setClickedRed(false);setClickedGrey(false);}}>
      <Tooltip title={props.tip}>
   <span>
     {(!props.digital)?
  <Button id={props.id} variant="contained" /*onBlur={()=>setClicked(false)}*/ className={(clickedRed)?props.className+' '+classes.racClickedRed:(clickedGrey)?props.className+' '+classes.racClickedGrey:props.className} onClick={()=>{props.onClick();handleClick()}} disabled={props.disabled}>
  <div className='racIcon2 centerContent'>
  {(props.path==="none")?null:(props.path)?
      <Iconn path={mdi[props.path]}
      size={0.7}
      color={props.iconColor}
      />
      :<Iconn path={mdiSim}
      size={0.7}
      color={props.iconColor}
      />
      }
     <p className="racBtnLabel">{props.text}</p>
     </div>
     {(props.racc)?<p className="racBtnLabel">{props.racc}</p>:null}
     </Button>:  
  <Button id={props.id} variant="contained" /*onBlur={()=>setClicked(false)}*/ className={(clickedRed)?classes.racAirtouch+' '+classes.racClickedRed:(clickedGrey)?classes.racProdtouch+' '+classes.racClickedGrey:classes.racAirtouch} onClick={()=>{props.onClick();handleClick()}} disabled={props.disabled}>
 <div>
      <div className='racIconF'>
      {(props.path)?
      <Iconn path={mdi[props.path]}
      size={0.7}
      color={props.iconColor}
      />
      :<Iconn path={mdiSim}
      size={0.7}
      color={props.iconColor}
      />
      }
      </div>
         <p className="racBtnLabelTouch">{props.text.substring(0,10)}</p> 
      </div>
     </Button>}
     </span>
     </Tooltip>
     </ClickAwayListener>

);
}
//Custum shortCut button
export function RaccBtn(props) {
  const classes=useStyles();
  const [clickedRed,setClickedRed]=useState(false);
  const [clickedGrey,setClickedGrey]=useState(false);

   // handle what happens on key press
   const handleKeyPress = useCallback((event) => {
      if(!props.disabled){        
        const ctrl=props.racc.split("+").includes("ctrl")
        const alt=props.racc.split("+").includes("alt")
        const shift=props.racc.split("+").includes("shift")
        if(event.ctrlKey===ctrl&&event.altKey===alt&&event.shiftKey===shift&&(event.key===props.racc.split("+").pop()||(event.key==="Escape"&&props.racc.split("+").pop()==="Echap"))){
          event.target.blur()
          event.preventDefault();
          //props.onClick()
          const elem= document.getElementById(props.id)
          if(elem){
            elem.focus();
            elem.click();            
          }
        }
      }
     // event.preventDefault();
   },[props]); 

   // handle what happens on key press
   const handleClick =(event) => {
     const clName=props.className.toString()
     if(clName.includes("Grey") || clName.includes("Prod")){
       setClickedGrey(true);
     }
     if(clName.includes("Red")){
       setClickedRed(true);
     }
   } 

   useEffect(() => {
    // attach the event listener
      document.addEventListener('keydown', handleKeyPress);
    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

return (
  
    <ClickAwayListener onClickAway={()=>{setClickedRed(false);setClickedGrey(false);}}>
      <Tooltip title={props.tip}>
   <span>
  <Button id={props.id} variant="contained" /*onBlur={()=>setClicked(false)}*/ className={(clickedRed)?props.className+' '+classes.racClickedRed:(clickedGrey)?props.className+' '+classes.racClickedGrey:props.className} onClick={()=>{props.onClick();handleClick()}}  onKeyPress={props.onKeyPress} disabled={props.disabled}>
  
  <div className='racIcon centerContent'>
  <Iconn path={props.path}
     size={0.7}
     color={props.iconColor}
     />
     {props.text.substring(0,10)}
     </div>
     <p className="racBtnLabel">{props.racc}</p>
     </Button>  
     </span>
     </Tooltip>
     </ClickAwayListener>

);
}

//tags
export function Tags(props) {
  const classes=useStyles();

  return (
    <div className="tag"><button className="tag-name" >{props.name} </button><div className="tag-elem"><p>{props.value}</p></div></div>
  );
}

//back drop for loading actions
export function BackdropCompo(props) {
  const classes=useStyles();

return (
  <Backdrop
        className={props.className}
        open={props.open}
      >
        <CircularProgress color="inherit" />
  </Backdrop>
);
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function DetailProduit(props) {
  const [open, setOpen] = useState(false);
  const [scrtch, setScrtch] = useState(false);
  const componentRef = useRef();
  const handleClickOpen = () => { if(!props.disabled){props.setDig({}); setOpen(true);} };
  const handleClose = () => { setOpen(false);setScrtch(false)};
  const [openA, setOpenA] =useState(false);
  const [message, setMessage] =useState('');
  const [severity, setSeverity] =useState('info');
  const param= useContext(ParamContext)

  const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
return (
  <div>
    <Tooltip title={(!props.disabled)?"Détails du produit":""}>
      <span >
      <div id="prodImg" onClick={handleClickOpen}><img src={param.imgDigital+"prod_"+props.el.liberoId+".png"+"?auto=compress"} style={{width: "85px", height:"auto",minHeight:"121px",opacity:(props.el.digitExist)?1:0.4}} /></div>
      <div id="digPrice" style={{opacity:(props.el.digitExist)?1:0.4}}>
           <Typography variant="body2" component="p">{Intl.NumberFormat().format(props.el.prx)}</Typography>
       </div>
       </span>
    </Tooltip>
    <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={paperPropsTicketSmall}>
      <DialogTitle id="simple-dialog-title" onClose={handleClose}></DialogTitle>
      <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
      <DialogContent ref={componentRef}>
        <div className={(!scrtch)?"gridDetProd":"gridDetProd2"}>
          <Typography variant="h4" component="h2">{props.el.digit}</Typography>
          {/*<Typography className="information" component="p">Une fois le code du produit digtal affiché, son montant vous sera facturé (aucun remboursement ne sera possible). </Typography>*/}
          <LazyLoad height={200}><img src={props.picUrl+"?auto=compress"} style={{width: "auto", height:"220px"}} /></LazyLoad>
          
          <div className='infoPrice'><Iconn path={mdiCashMultiple} color="green" size="16px"/> <Typography variant="h5" component="h2">{Intl.NumberFormat().format(props.el.prx)}</Typography></div>
          <Paper className="infoPaper"> <Typography variant="body2" component="p"> {props.el.desc} </Typography></Paper>
          {(props.dig.code)?<div id='card-dig' title={"code"} >
              <Iconn path={mdi.mdiAlphaCBox} color="red" size="18px" />
              <Typography variant="body2" color="main" id='code-dig'>Code</Typography>
              <ArrowRight id='code-dig' fontSize='small'/>
              <Typography variant="body2" color="main" id='code-dig'>{props.dig.code}</Typography>
         </div>:null}
         {(props.dig.code)?<div id='det-dig' title='Serial'>
              <Iconn path={mdi.mdiAlphaSBox} color="green" size="18px" />
              <p id='serial-dig'>Serial</p><ArrowRight id='serial-dig' fontSize='small'/><p id='serial-dig'>{(props.dig.serial)?props.dig.serial:'---'}</p> 
         </div>:null}
          {(!scrtch)?<Button variant="contained" color="primary" onClick={async()=>{const resp= await props.ScratchDig(props.tid,props.el.liberoId);if(resp===true) setScrtch(true); else handleAlert(true,"error",resp)}} startIcon={<Iconn path={mdi.mdiCursorPointer} color="white" size="16px"/>}>Gratter</Button>
          :<PrintDig param={props.param} row={props.dig} type="button"/>}
        </div>
        {(props.dig.code)?<div className='shareFlex centerContent'>
          < EmailShareButton subject={"Demander de code"+props.el.digit} url={"Vous avez demander le code de "+props.el.digit+((localStorage.getItem("mag"))?" de la part de "+localStorage.getItem("mag"):"")+": "+props.dig.code}>
              <EmailIcon round size={30}/>
          </EmailShareButton>
          < ViberShareButton  url={"Vous avez demander le code de "+props.el.digit+((localStorage.getItem("mag"))?" de la part de "+localStorage.getItem("mag"):"")+": "+props.dig.code}>
              <ViberIcon round size={30}/>
          </ViberShareButton>
          < WhatsappShareButton  url={"Vous avez demander le code de "+props.el.digit+((localStorage.getItem("mag"))?" de la part de "+localStorage.getItem("mag"):"")+": "+props.dig.code}>
              <WhatsappIcon round size={30}/>
          </WhatsappShareButton>
           <div className='shareIcons'>
            <Iconn path={mdi.mdiContentCopy} color="white" size="20px" onClick={()=>{navigator.clipboard.writeText(props.dig.code);}}/>
          </div>
        </div>:null}
      </DialogContent>
    </Dialog>
    </div>
);
}

export const ScrollableTabs=(props)=>{
  const [value, setValue] = React.useState(0);
  const Inner=props.compo;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const MyTabScrollButton = withStyles(theme => ({
    root: {
      width: 28,
      overflow: 'hidden',
      transition: 'width 0.5s',
      '&.Mui-disabled': {
        width: 0,
      },
    },
  }))(TabScrollButton);
  return(
  <AppBar position="static" color="transparent" elevation={0}>
    <Tabs
                ScrollButtonComponent={MyTabScrollButton}
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                style={{minHeight:"30px"}}
                TabIndicatorProps={{   
                  style: {
                      display: "none",
                  },
                }}
              >
                <Inner/>
    </Tabs>
  </AppBar>)
}

//loading circle
export function LoadingCircle(props) {

return (
        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
           {(props.loading)?<CircularProgress color="inherit" size={(props.size)?props.size:"20px"} style={{color:(props.color)?props.color:"red"}}/>:null}
        </div>
);
}

// editable input of type number 
export function TelUpd({service,handleAlert,row,att,action,id,value,idi,min,max,traetResp,dateupd,agentupd,datein,agentin,positif,loading,setLoading,charge,supp,suppAtt}) {
  const api=require('../js/Apis')
  const [open, setOpen] = useState('');
  const [val, setVal] = useState(value);
  const [changed, setChanged] = useState(false);
  const [load, setLoad] =useState(false);
  
 //update field for formatted numbers
 //update field for formatted numbers
 const handleOnEdit2=useCallback(async(val)=>{
  if(val!==''){
    const check=Gfunc.phonenumber(val)
  if(check.response &&val!==null){
  setLoad(true)
  const edt1=val
  var edt=edt1.replace(/\s/g, '');
      if(row[att]!==edt&&edt!==""){
        const attrib=row[att]; 
        const response=await librery.axiosEdit2(service,action,row,edt,att,id,"number",supp,suppAtt) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
          setVal(edt);
          row[att]=edt
         // e.target.value=new Intl.NumberFormat().format(edt)
          if(traetResp!==undefined)
            traetResp(response.data)
            if(charge==='charge'){api.Caisse()}
        })
        if(response.data.err) {setVal(attrib)}
      }
      setLoad(false)
    }
    else {
        handleAlert(true,'error',check.message);setVal(value)}
    setOpen('')
    }
},[librery,Gfunc,row,att,action,id,traetResp]);

useEffect(async() => {
  if(open===row[id]){
    const elem=document.getElementById(idi)
    if (elem) {elem.focus(); elem.select()}
  }
}, [open]);
  
return (
  <Tooltip title={(datein!==undefined||dateupd!==undefined)?((!dateupd)?"Entré le: "+datein +' par: ' +agentin:"Édité le: "+dateupd +' par: ' +agentupd):''}>
  <div className='InputNbrEditable'>
  {(open!==row[id])?<div id={idi} className="content-editable" style={{textAlign:'center',justifyContent:"center"}} onClick={()=>setOpen(row[id])}>{(val!==null)?val:''}</div>
  :<ClickAwayListener onClickAway={e => {if(changed){handleOnEdit2(val);}else setOpen('')}}>
    {(!load)?
    <input 
    id={idi}
    key={row[id]}
    //onBlur={()=>{setSelecto('');setValue('')}}
    defaultValue={value}
    value={(val===null)?'':val}
    type="number"  
    style={{color:"black !important"}}
    name="iTel" className='content-editable removeArrow' margin='dense' variant='outlined'
    autoFocus
    onChange={(e)=>{
      if(value!==e.target.value) setChanged(true); else setChanged(false)
      if(e.target.value && e.target.value.length >=10){setVal(e.target.value.substr(0,10))}else{setVal(e.target.value)}
    }}
    onKeyPress={(e)=>{
      if(e.key==="Enter"){
      handleOnEdit2(val);}
      }}
    required
    /> 
:<CircularProgress style={{width:"12px",height:"12px"}} color="inherit" />}
  </ClickAwayListener>
  }
  </div>
  </Tooltip>

);
}