import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {ReactComponent as BarCode} from '../../../img/icons/barcodeSearch.svg'
import {ReactComponent as Produit} from '../../../img/icons/Produit.svg'
import {CalendarToday, Category, Check,Delete, Person,Close} from '@material-ui/icons';
import { useStyles, paperPropsSmallF} from '../../../css/muiStyle';
import "../../../css/referentiel.css"
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiCheckDecagram, mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_prd_codeBar(props) {
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] =useState(false);
    const [cb,setCb]=useState('');
    const classes=useStyles();
    const [vide, setVide] =useState(false);
    const [data, setData] =useState([]);
    const service='_prd.php'
   
    const handleClickOpen = () => { 
      setOpen(true); 
      setCb('');setData([]);setVide(false)
    };

    const handleClose = () => { setOpen(false); };

    const handleSubmit= async(e) =>{
      if(cb!==''){
        const response = await Gfunc.axiosPostAction(service,"getpbycb",{code:cb})
        if(response.data.err){
          props.handleAlert(true,'error',response.data.err)
          setOpen(false)
        }else{
          Gfunc.axiosResponse(response,()=>{
            if(response.data.jData){
              if(response.data.jData.agent!==null||response.data.jData.produit!==null||response.data.jData.date!==null||response.data.jData.categorie!==null){
                setData(response.data.jData)
              }else{
               setData([]) 
               setVide(true)
              }
            }
       })
      } 
      }    
      }

  const handleDelete=async()=>{
    const response = await Gfunc.axiosPostAction(service,"delcb",{cb:cb})
    if(response.data.err){
      props.handleAlert(true,'error',response.data.err)
      setOpen(false)
    }else{
      Gfunc.axiosResponse(response,()=>{
        props.handleAlert(true,"success","Le code à barres a été supprimé avec succès ")
        setOpen(false)
         
     })
    } 
  }

  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} 
              startIcon={<BarCode  style={{height:'18px',width:'18px',padding:"1px"}}/>}>Code à barres</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallF}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
           <div className='DialogTitle'>
              <Icon path={mdiCheckDecagram} size={1} />
              <label> Vérification des code à barres</label>
            </div>
        </DialogTitle>
          <DialogContent className={classes.dialogueContent}>
            <div id='prd-valid-code'>
              <ValidatorForm id="validate"  ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
                <div className="rowGrid" id="row-1">
                 <TextField className='smallText' margin='dense' variant='outlined'  label="Code à barres" size="small" 
                            value={cb}  onChange={e =>{setCb(e.target.value)}} autoFocus/>
                 <Button className={classes.PrintBtn} form="validate" type="submit" variant="contained" color="primary" startIcon={<Check/>}>Vérifier</Button>                
                </div>
              </ValidatorForm>
            </div>
            <div>
              {
                (data.length!==0)? 
                  <div id='code-prod-info'>
                    <div id="item"> 
                      <Produit style={{width:'20px',height:'20px'}}/>
                      <b>Nom : </b>
                      <p>{(data.produit)?data.produit:'---'}</p>
                    </div>
                    <div id="item"> 
                      <Category style={{width:'20px',height:'20px'}}/>
                      <b>Catégorie :</b>
                      <p>{(data.categorie)?data.categorie:'---'}</p>
                    </div>
                    <div id="item"> 
                      <CalendarToday style={{width:'20px',height:'20px'}}/>
                      <b> Ajouté le :</b>
                      <p>{(data.date)?data.date:'---'}</p>
                    </div>
                    <div id="item"> 
                      <Person style={{width:'20px',height:'20px'}}/>
                      <b>Par :</b>
                      <p>{(data.agent)?data.agent:'---'}</p>
                    </div>
                  </div>
              :(vide===true)?<div id='prod-err'>Ce code à barres n'existe pas</div>:<></>
              }
            </div>
            </DialogContent>
            <DialogActions>
                <Button className={classes.diagTabBtn} disabled={(data.deletable)?false:true}  variant="contained" color="primary"
                        onClick={handleDelete}  startIcon={<Delete/>}>Supprimer le code</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}