import React,{useState,useCallback,useMemo} from 'react';
import {DialogContent,Paper,Button,Dialog,CircularProgress,TextField,Typography,IconButton} from '@material-ui/core';
import DataTable from "react-data-table-component";
import Draggable from 'react-draggable';
import { LocalGroceryStore,Close} from '@material-ui/icons';
import { useStyles,paperProps } from '../../../css/muiStyle';
import {customStyles} from '../../../css/datatable-custom'
import DraggableC from '../modals/mon_lst_prd_add'
import Autocomplete from '@mui/material/Autocomplete';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import { useMediaQuery } from 'react-responsive'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import Icon from '@mdi/react';
import { mdiClipboardClockOutline } from '@mdi/js';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Mon_lst_abs(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const [cat, setCat] = useState('');
    const [catL, setCatL] = useState([]);
    const [open, setOpen] = useState(false);
    const [prod, setProd] = useState([]);
    const [tous, setTous] = useState([]);
    const service="_lst.php"
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 520px)' })


    const handleClickOpen = () => { 
      setOpen(true); setProd([]); fetch()
    };

    const handleClose = () => { setOpen(false); };

    //fetch comments of news
    const fetch= useCallback(async() => {
        const response=await Gfunc.axiosGetAction(service,"getmiss")
        Gfunc.axiosResponse(response,()=>{
        setProd(response.data.jData);
        setTous(response.data.jData);
        })
      },[Gfunc])

 
    const columns =useMemo(
        () => [ 
          {
            name: "Produit",
            selector: row=>row.produit,
            minWidth:"450px !important",
            sortable: true, 
          },
          {
            name: "Catégorie",
            selector: row=>row.categorie,
            minWidth:"200px !important",
            sortable: true,
          },
          {
            right:true,
            cell: (row) => {
              return (
                 <DraggableC row={row} callback={()=>props.callback()} setOpen={setOpen} handleAlert={props.handleAlert} dialog='abs'/>
               );
            }, 
          },
              
        ],[props]);

     
      //fetch categorie
    const fetchCat= useCallback(async(serv="_cat.php", act = "gets") => {
      const response = await Gfunc.axiosGetAction(serv,act);
      Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({id:'empty',nom:'Tous'})
        setCatL(response.data.jData);    
     })
    },[Gfunc])

    //fetch fournisseur handler
    const handleOpenCat = useCallback(async () => {
        if(catL.length===0)
        fetchCat()
    },[catL,fetchCat])
    
   const filtrer=(att,val)=>{
     if(val!=='empty'){
      var objIndex='';
      var test=[];
      objIndex =  catL.findIndex((obj => obj.id ===val )); 
      test=tous.filter(word => word.categorie ===att);
      setProd(test)
     }else{
       setProd(tous)
     }
       } 

    const handleChange=(val)=>{
      if(val!=='Tous'){
       const value=catL[Gfunc.getIndex(catL,val,"nom")].nom;
       const objIndex =  catL.findIndex((obj => obj.nom===value)); 
       setCat(objIndex)
       filtrer(val,objIndex)
      }else{setProd(tous)}
    }

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<LocalGroceryStore/>} 
              className={(isTabletOrMobile)?classes.topTabButton:classes.topTabBtn}>Produits absents</Button>
      <Dialog
            open={open}
            id='lst-prd-abs'
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}
       >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
          <div className='DialogTitle'>
              <Icon path={mdiClipboardClockOutline} size={1} />
              <label>Liste des produits absents</label>
          </div>
        </DialogTitle>
            <DialogContent>
            <div className='table-header'>
            <div className=' smallAutoFiltre' id='selectMonit'>
                 <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{handleChange(newValue);}}
                    onFocus={handleOpenCat}
                    options={catL.map((option) => option.nom)}
                    className={classes.headFilter}
                    inputlabelprops={{className:classes.dataLabel}} 
                    renderInput={(params) =>  
                            <TextField {...params} margin='dense' size='small' variant="outlined"  label="CATÉGORIE" 
                                       SelectProps={{ MenuProps: {closeAfterTransition:true } }}/>
                  }
                />
             </div>
            </div>
            <DataTable
                key={Math.floor(Math.random() * 100)}
                columns={columns}
                data={prod}
                noDataComponent={param.ErreurData}
                defaultSortAsc={true}
                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                pagination
                highlightOnHover
                customStyles={customStyles}
                selectableRowsComponentProps={{ className: classes.checkStelect }}
                paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
                contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
                progressComponent={<CircularProgress className="circularProgress" />}

          />
        </DialogContent>    
      </Dialog>
      </div>
  );
}
