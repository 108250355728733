import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import {CircularProgress, ClickAwayListener,TextareaAutosize,Tooltip} from '@material-ui/core';
import {Search,Lock,LockOpen} from '@material-ui/icons';
import DraggableDialog from './modals/adm_rol_add'
import RolDialog from './modals/adm_rol_usr'
import PrevDialog from './modals/adm_rol_privilege'
import {conditionalRowStyles,customStyles, numberColumn}from '../../css/datatable-custom'
import { textAreaEdit, useStyles } from '../../css/muiStyle';
import {AlertCompo,NoDataComponent,NotAllowedComponent} from "../../js/components"
import { Box } from "@mui/system";


export default function Adm_rol(props){
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [Icon,setIcon]=useState('');
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const[noData,setNoData]=useState(false); 
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[doSearch,setDoSearch]=useState(false);
  //const service='_rol.php'
  // const srv="role"
  const service='_rol.php'
  const srv='role'
  const classes=useStyles()
  const[called,setCalled]=useState(false);

  const [Item,setItem]=useState('')
  const [selecto,setSelecto]=useState('')
  const [note,setNote]=useState('')
  
   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  const fetch= useCallback(async(page,servic = service, size = perPage) => {
    setCalled(true)
    setLoading(true);
    const response=await librery.axiosGet(page+'&search='+search,servic,size)
      if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setDoSearch(true)
        setNoData(true)
        }else {setDoSearch(false);setData([])}
      })
    setLoading(false)
  },[librery,Gfunc,search,perPage])

  useEffect(() => {
    if(!called){fetch(1);}
    Gfunc.loseFocus('.content-editable')
  }, [fetch,called,Gfunc]);
  
  const flagMan = useCallback(async( row)=>{
    const objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId)); 
    const response= await Gfunc.axiosPostAction('flagman.php','',({ srv:srv, id: row.DT_RowId.substr(1),att:'activ', val: row.activ }))
    Gfunc.axiosResponse(response,()=>{
        setIcon(row.DT_RowId) 
            })
    if(row.activ){
      data[objIndex].activ=0
    }else data[objIndex].activ=1
  },[Gfunc,data]);

  //update field
  const handleOnEdit=useCallback(async(e,row,att)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
    if(att==='nom' ){
      if(row.role!==edt){
        const attrib=row.role;
        const response=await librery.axiosEdit(service,row,edt,'nom') 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{ row.role=edt ;e.target.innerHTML=edt})
        if(response.data.err) {e.target.innerHTML=attrib}
      }else{e.target.innerHTML=edt}
    }
    else
        if(row[att]!==edt){
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt,att) 
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt}
  },[librery,Gfunc]);

  const handleSelect=useCallback((row,att)=>{
    setSelecto(row[att])
    setItem(row.DT_RowId)
 },[])

  //handle update note
  const handleOnEditNote=useCallback(async(note,row,att,action)=>{
    const edt1=note
    var edt=Gfunc.DeleteSpace(edt1);
    edt=edt.replaceAll("\n", "\r")
    if(row[att]!==edt){
      const attrib=row[att];
      const response=await librery.axiosEdit2(service,action,row,edt,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{row[att]=edt})
      if(response.data.err) {row[att]=attrib}
    }else {row[att]=edt}
    setNote('')
    setSelecto('')
  },[librery,Gfunc]);

  const handleLockIcon=useCallback((val)=>{
      setIcon(val);
  },[]);
  
  useEffect((val) => {
    handleLockIcon(val);
  }, [handleLockIcon]);

  
  const columns =useMemo(
    () => [   
      {
          name: "NOM",
          selector: row=>row.role,
          sortable: true,
          sortField:"role",
          minWidth:"200px !important",
          cell: (row) => {
            return (
             <Tooltip title={row.role}>
              <ContentEditable
                html={row.role}
                name="rol"
                data-column="item"
                className="content-editable"
                disabled={false} 
                onBlur={e => handleOnEdit(e,row,"nom")}
              />
             </Tooltip>
             );
           },
      },
      {
        name: "DATE",
        selector: row=>row.datin,
        sortable: true,
        width:"100px !important",
        sortField:"datin",
      },
      {
        name: "UTILISATEUR",
        selector:row=>row.user,
        sortable: true,
        width:"180px !important",
        sortField:"user",
      },
      {
        name: "Note",
        selector:row=>row.note,
        sortable: true,
        width:"380px !important",
        sortField:"note",
        cell: (row) => {
          return (
            <div className="note-container">
            { (row.note===selecto && row.DT_RowId===Item)?
            <ClickAwayListener onClickAway={()=>{(note)?handleOnEditNote(note,row,"note",'upd'):setSelecto('')}}>
             <TextareaAutosize style={textAreaEdit} autoFocus onFocus={(e)=>{ e.target.value=note}}  onChange={(e)=>{setNote(e.target.value);}}
             /></ClickAwayListener>:
             (((row.note&&row.note.match(/\r/g)) || []).length>=1)?
             <Tooltip title={row.note.replaceAll("\r", "/ ")}>
                 <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}}>{row.note.substring(0, row.note.indexOf("\r"))+"..."}</div>
             </Tooltip>:
               <Tooltip title={(row.note)?row.note:''}>
             <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}} >{row.note}</div></Tooltip>}
             </div>
           );
         },
       },
       {
        name: "privilèges",
        selector:row=>row.nbPrivs,
        sortable: true,
        width:'135px !important',
        style:numberColumn, 
        sortField:"nbPrivs",      
      },
      {
        width:'105px !important',
        cell: (row) => {
          if(Icon===row.DT_RowId){
            if(row.activ===1)
            row.activ=0
            else
            row.activ=1   
            setTimeout(() => { handleLockIcon('')},100)         
          }
          return (
            <div id="icons"> 
              <div className='rowGrid' id="row-11">
                <div><PrevDialog param={param} handleAlert={handleAlert} row={row} callback={()=>fetch(1)} /></div> 
                <div><RolDialog param={param} handleAlert={handleAlert} row={row}/></div> 
                <Tooltip title="Activer / Désactiver">
                  <div onClick={()=>flagMan(row)}>{(row.activ)?
                    <LockOpen className={classes.redIcon} fontSize="small"/>:<Lock className={classes.greenIcon} fontSize="small"/> }
                  </div>  
                </Tooltip>      
              </div>
            </div>
            
          );
         },
      }  
  ],[flagMan,handleOnEdit,handleLockIcon,Icon,classes,handleOnEditNote,handleSelect,selecto,Item,note,fetch,param]);
  
 

  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,'get')
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      })     
  }

  const handleSearch=async( size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,'get')
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
    return recherche
  }

  function handlePageChange (page) {
    fetch(page,service,perPage);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,service, newPerPage);
    setPerPage(newPerPage);
  };
  
  //Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }

  function liveSearch2() {
    handleSearch();
  }

  return (
    <div className="content">
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <div className='table-header'>
          <div className='button-flex-wrapper' label="Ajouter un role" >
            <DraggableDialog handleAlert={handleAlert} callback={()=>fetch(1)} />
          </div>
          {(doSearch)?
            <div className='search' >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer"}} />),}}
                               size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
              </Box>
            </div>:null}
        </div>
        {(data.length!==0||doSearch)?<div>
        <DataTable className="table"
              persistTableHead
              columns={columns}
              data={data}
              noHeader={true}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
        /></div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
    </div>    

  );
}
