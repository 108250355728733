import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import DraggableDialog from "./modals/adm_usr_add";
import MagDialog from "./modals/adm_usr_mags";
import HistoDialog from "./modals/adm_usr_histo";
import {Search,Add,VpnKey} from '@material-ui/icons';
import {CircularProgress,Tooltip, Box,TextareaAutosize} from '@material-ui/core';
import {AlertCompo,NotAllowedComponent,NoDataComponent,AutocompleteS, TelUpd} from "../../js/components"
import {customStyles}from '../../css/datatable-custom'
import { useStyles,textAreaEdit} from '../../css/muiStyle';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import '../../css/admin.css';
import RolDialog from './modals/adm_usr_rol'

export default function Adm_usr (props){
  const classes=useStyles()
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [note,setNote]=useState('')
  const [mags, setMags] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [resetDP, setResetDP] = useState(false);
  const [search,setSearch]=useState('');
  const [,setIcon]=useState('');
  const [selecto, setSelecto] = useState('');
  const [ItemN,setItemN]=useState('');
  const [item,setItem]=useState('')
  const [noData,setNoData]=useState(false);
  const [doSearch,setDoSearch]=useState(false);
  const [notAllowed,setNotAllowed]=useState(false);
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [errMessage,setErrMessage]=useState('');
  const [records,setRecords]=useState('');

  const service='_usr.php'

  const conditionalRowStyles=[
    { 
      when: row => row.inMag===false,
       style: {
             color: 'gray',
             backgroundColor: "lightgray",
       }
    }
  ]
 
   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  const fetch= async(page,servic = service, size = perPage) => {
    setCalled(true);
    setLoading(true);
    const response=await librery.axiosGet(page,servic,size+"&search="+search)
    if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
    }else{
    Gfunc.axiosResponse(response,()=>{
      setRecords(response.data.recordsFiltered)
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
        setNoData(true)
        setDoSearch(true)
      }else{
        setDoSearch(false)
        setData([])
      }
    })
  }
  }

  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);
  
  
  //update field
  const handleOnEdit=useCallback(async(e,row,att)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
    if(att==='nom' ){
      if(row.user!==edt){
        const attrib=row.user;
        const response=await librery.axiosEdit(service,row,edt,'nom') 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{ row.user=edt ;e.target.innerHTML=edt})
        if(response.data.err) {e.target.innerHTML=attrib}
      }else{e.target.innerHTML=edt}
    }
    else
        if(row[att]!==edt){
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt,att) 
          if(response.data.err&&response.data.err!=="-1"){
            handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt}
  },[librery,Gfunc]);


const handleLockIcon=(val)=>{
  setIcon(val);
}
useEffect((val) => {
handleLockIcon(val);
}, []);
 


const handleSelectNote=useCallback((row,att)=>{
    setSelecto(row[att])
    setItem(row.DT_RowId)
    setItemN(att)
  },[])

  //handle update note
 const handleOnEditNote=useCallback(async(note,row,att,action)=>{
    const edt1=note
    var edt=Gfunc.DeleteSpace(edt1);
    if(row[att]!==edt){
      edt=edt.replaceAll("\n", "\r")
      const attrib=row[att];
      const response=await librery.axiosEdit2(service,action,row,edt,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{row[att]=edt})
      if(response.data.err) {row[att]=attrib}
    }else {row[att]=edt}
    setNote('')
    setSelecto('')
  },[librery,Gfunc]);

const ResetPass=async(val)=>{
    const response=await Gfunc.axiosPostAction(service,'resetpw',{userId:val})
      if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData===true){
         handleAlert(true,'success',"Le mot de passe à été  réinitialisé avec succès ")
        }
      })
}

const DefaultMag=async(row)=>{
    const response=await Gfunc.axiosPostAction(service,'setumag',{idu:row.DT_RowId.substr(1),
                              idr:JSON.parse(localStorage.getItem('magid'))})
      if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData===true){
          row.inMag=true;
          handleAlert(true,'success',"Le magasin a été affecté avec succès")
        }
      })
}
const handleOpenMag=async(row)=>{
  setMags([])
  const data={idu:row.DT_RowId.substr(1),unsets:false}
  const response=await Gfunc.axiosPostAction(service,"getumags",data)
        if(response.data.err&&response.data.err!=='-1'){
          props.handleAlert(true,"error",response.data.err)
        }else
          if(response.data.jData!==undefined){
           Gfunc.axiosResponse(response,()=>{
            setMags(response.data.jData);
            })
          }
}
const columns =useMemo(
    () => [  
      {
         width:"28px !important",
         cell: (row) => {
            return (
              <HistoDialog row={row} handleAlert={handleAlert}/>
             );
           }
      },
      {
         name: "PSEUDO",
         selector:row=>row.user,
         minWidth:"180px !important",
         sortField: "user",
         sortable: true,
         cell: (row) => {
            return (
            <div data-tooltip={(row.date!==undefined||row.dateUpd!==undefined)?((!row.dateUpd)?"Entré le: "+row.date +' par: ' +row.agent:"Édité le: "+row.dateUpd +' par: ' +row.agentUpd):''}
                className="tooltipU">
            <ContentEditable
                html={row.user}
                name="user"
                data-column="item"
                className="content-editable"
                disabled={false} 
                onBlur={e => handleOnEdit(e,row,'nom')}
            />
            </div>
             );
           }
      },
      {
         name:<p title="Magasin par défaut">Magasin</p>,
         selector:row=>row.defMag,
         minWidth:"180px !important",
         sortField: "defMag",
         sortable: true,
         cell: (row) => {
            return (
              <div >  
              {
                <AutocompleteS
                    datein={row.date} agentin={row.agent}  dateupd={row.dateUpd} agentupd={row.agentUpd} 
                    service={service} action={'upd'} 
                    defaultValue={row.defMag} row={row} list={mags} 
                    id={'defMagId'} name='defMag' attr='name' onOpen={()=>handleOpenMag(row)}
                    setLoading={setLoading} setItem={setItem} setSelecto={setSelecto}  handleAlert={handleAlert}
                    handleResponsee={(response)=>{
                      if(response!==undefined&&row.DT_RowId.substr(1)===localStorage.getItem('uid')){
                        const ind=mags.findIndex((obj => obj.id ===response ));
                        localStorage.setItem('magid',response)
                        localStorage.setItem('mag',mags[ind].name)
                      }
                      }
                    }
                    />
              }          
              </div> 
             );
           }
      },
      {
        name: "Tel.",
        selector:row=>row.tel,
        minWidth:"180px !important",
        sortField: "tel",
        sortable: true,
        cell: (row) => {
            return (
              <div key={Math.random()}>
                {
                  <TelUpd
                    datein={row.date} agentin={row.agent} dateupd={row.dateUpd} agentupd={row.agentUpd}
                    service={service} action='upd'
                    row={row} att="tel" value={row.tel} id='DT_RowId'
                    handleAlert={handleAlert}
                 />
                }
              </div>
             );
           }
      },
      {
        name: "Note",
        selector:row=>row.note,
        minWidth:"280px !important",
        sortField: "note",
        sortable: true,
        cell: (row) => {
            return (
              <div className="note-container">
              { (row.note===selecto && ItemN==='note' && row.DT_RowId===item)?
              <ClickAwayListener onClickAway={()=>{(note)?handleOnEditNote(note,row,"note",'upd'):setSelecto('')}}>
               <TextareaAutosize style={textAreaEdit} value={note}  onChange={(e)=>{setNote(e.target.value);}}
               /></ClickAwayListener>:
               ((row.note&&row.note.match(/\r/g) || []).length>=1)?
               <Tooltip title={row.note.replaceAll("\r", "/ ")}>
                   <div className="content-editable" onClick={()=>{setNote(row.note);handleSelectNote(row,'note')}}>{row.note.substring(0, row.note.indexOf("\r"))+"..."}</div>
               </Tooltip>:
                 <Tooltip title={(row.note)?row.note:''}>
               <div className="content-editable" onClick={()=>{setNote(row.note);handleSelectNote(row,'note')}} >{row.note}</div></Tooltip>}
               </div>
             );
           },
      },
      {
        name: "Agent",
        selector:row=>row.agent,
        minWidth:"100px !important",
        sortField: "agent",
        sortable: true,
      }, 
      {
        right:true,
        cell: (row) => { 
          return (
            <div  className="rowGrid fourIcons"> 
              {(!row.inMag)?
                    <Tooltip title="Affecter le magasin actuel">
                      <Add className={classes.blackIcon} fontSize="small"  onClick={()=>DefaultMag(row)}/>
                    </Tooltip>  
                    :<div>
                </div>}
                <div><RolDialog row={row} param={param}/></div>
                <MagDialog row={row} handleAlert={handleAlert} callback={()=>{if(currentPage===1) fetch(1);else setResetDP(!resetDP);}}/>
                <Tooltip title="Réinitialiser le mot de passe">
                  <div onClick={()=>ResetPass(row.DT_RowId.substr(1))}><VpnKey className={classes.redIcon} fontSize="small"/></div> 
                </Tooltip>
           </div>
           );
         },
      } , 
    ],[classes,item,ItemN,selecto,mags,note,handleOnEdit,handleOnEditNote,handleSelectNote,handleOpenMag,resetDP]);

  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage+"&search="+search)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,'get')
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      }) 
    }    
  }

  //recherche serverside
const handleSearch=async(size = perPage)=>{
  setLoading(true);
  const recherche=search;
  const response=await librery.axiosSearch(service,recherche,1,size,"get")
  setLoading(false); 
  if(response.data.err&&response.data.err!=="-1"){
    handleAlert(true,'error',response.data.err)
  }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
  }
  return recherche
}

 function handlePageChange (page) {

  fetch(page);
  setCurrentPage(page);
};

 const handlePerRowsChange = async (newPerPage, page) => {
  fetch(page,service, newPerPage);
  setPerPage(newPerPage);
};

//Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
} 
function liveSearch2() {
  handleSearch();
}

  return (
    <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
            <div className='table-header'>
                <div className='button-flex-wrapper'><DraggableDialog callback={()=>{ fetch(1)}} ResetPass={ResetPass}
                   handleAlert={handleAlert}/></div>
                <div className='search'>
                  {(doSearch)?
                  <div className='search' >
                      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                                       InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                                       style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:'pointer' }} />),}}
                                       size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
                      </Box>
                  </div>:null}
                </div>
            </div>
           {
                (data.length!==0 ||doSearch)?
                <div>
                <DataTable
                  className="table"
              persistTableHead
                  columns={columns}
                  data={data}
                  noHeader={true}
                  noDataComponent={param.ErreurData}
                  paginationRowsPerPageOptions={[10,30,50,100]}
                  progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationResetDefaultPage={resetDP}
                  paginationDefaultPage={1}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  sortServer={true}
                  onSort={handleOnSort}
                  progressComponent={<CircularProgress className={classes.circularProgress} />}
                  highlightOnHover
                  customStyles={customStyles}
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
                  conditionalRowStyles = { conditionalRowStyles }
                />
            </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
    </div>    
  );
}
