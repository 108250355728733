
import React from 'react'
import { BarChart,XAxis,YAxis,Tooltip ,CartesianGrid,Bar,ResponsiveContainer} from 'recharts';

export default function Bar_chart(props){
    function CustomTooltip({ payload, label, active,...props }) {
        if (active&&payload[0]!==undefined) {
          return (
            <div className="custom-tooltip" style={{ border:"2px solid"+props.color}}>
              <p style={{color:'black'}}>{`${label}`}</p>
              <p className="intro" style={{color:props.color}}>{props.name}: {(payload.length!==0)?new Intl.NumberFormat().format(`${payload[0].value}`):0 }</p>
            </div>
          );
        }
        return null;
      }
    return (
      <div>
       <ResponsiveContainer width='98%' height={props.height}>       
         <BarChart data={props.data} margin={props.margin} barSize={props.barSize}>
                <XAxis dataKey={props.dataKeyX} scale="point" padding={props.padding} tick={{ fill: 'black',fontSize:'13px',dy:3,angle: -20}}/>
                <YAxis tick={{ fill: 'black',fontSize:'12px'}}/>
                <Tooltip content={<CustomTooltip name={props.name} color={props.fill} />}/>
                <CartesianGrid strokeDasharray="3 3" />
                <Bar dataKey={props.dataKeyY} fill={props.fill} background={{ fill: '#eee' }} />
         </BarChart>
       </ResponsiveContainer >
      </div>
    );
}