import React,{useState,useRef,useCallback,useMemo} from 'react';
import {DialogContent,Paper,Button,Dialog,TextField,CircularProgress,TextareaAutosize,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import DataTable from "react-data-table-component";
import { ValidatorForm} from 'react-material-ui-form-validator';
import {Add,RotateLeft,Today,Search,IndeterminateCheckBox,AccessTimeOutlined,Comment,Keyboard,Close} from '@material-ui/icons';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider,KeyboardDatePicker, KeyboardTimePicker} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import {ReactComponent as Payment} from '../../../img/icons/payment.svg'
import { AlertCompo, AutocompleteS, DatePickerUpd,InputNbrEditable,SituationFournisseur} from '../../../js/components';
import { Box } from '@mui/system'; 
import { Autocomplete } from '@material-ui/lab';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import {customStyles, mtnControlFourni, numberColumn}from '../../../css/datatable-custom'
import { useStyles,paperPropsBigFixed, textAreaEdit2 } from '../../../css/muiStyle';
import "../../../css/achat.css"
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';


const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ach_ent_vers(props) {
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const librery=require('../../../js/data_table_librery')
    const [open, setOpen] =useState(false);
    const [frns,setFrns]=useState('');
    const [frnsN,setFrnsN]=useState('');
    const [note,setNote]=useState('');
    const [caisse,setCaisse]=useState('');
    const [caisseN,setCaisseN]=useState('');
    const [type,setType]=useState('');
    const [typeN,setTypeN]=useState('');
    const [bon,setBon]=useState('');
    const [reset,setReset]=useState(true);
    const [bonD,setBonD]=useState('');
    const [bonN,setBonN]=useState('');
    const [mtn,setMtn]=useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState(new Date());
    const [bonL, setBonL] = useState([]);
    const [data, setData] = useState([]);
    const [typeL, setTypeL] = useState([{id:-1,name:'Versement'},{id:1,name:'Remboursement'}]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [search,setSearch]=useState('');
    const [focused,setFocused]=useState('');
    const [Item, setItem] = useState('');
    const [ItemN, setItemN] = useState('');
    const [selecto, setSelecto] = useState('');
    const [doSearch,setDoSearch]=useState(false);
    const [, setDel] = useState('');
    const Apis =require("../../../js/Apis.js");

    const classes=useStyles();
    

    const addTitle="Ajouter un versement fournisseur"
    const labelText1="Date"
    const labelText2="Fournisseur"
    const labelText3="Montant"
    const labelText4="Caisse"
    const labelText5="Note"
    const labelText6="Type"
    const labelText7="Bon"
    const labelText8="Heur"
    const service="_ach.php"
    
    const [openA, setOpenA] =useState(false);
    const [message, setMessage] =useState('');
    const [severity, setSeverity] =useState('info');
    
    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    const fetch= async(page,servic = service,size = perPage,id) => {
      setLoading(true);
      const response=await librery.axiosGet2(page,servic,'getver&f='+id+'&search='+search,size)
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
          setData(response.data.data);
          setTotalRows(response.data.recordsFiltered);
          setDoSearch(true)
          //setNoData(true)
        }
      })
      setLoading(false);
    }


     //fetch Bon
     const fetchBon= useCallback(async(serv="_ach.php", act = "getsbon") => {
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==undefined){
          setBonL(response.data.jData);  
        }  
      })
      return(response.data.jData);  
    }
    },[Gfunc])
   
    //time handler
    const handleTimeChange = (date) => {
      setSelectedTime(date);
    }; 

    //date handler
    const handleDateChange = (date) => {
      setSelectedDate(date);
    }; 
  
     //fetch bon handler
     const handleOpenBon = useCallback(async () => {
      //if(bonL.length===0)
      fetchBon()
      },[fetchBon])
    
    
    //handle le changement de la page
    function handlePageChange (page) {
      fetch(page,service,perPage,frns);
      setCurrentPage(page);
    };
    //handle le nombre de lignes par page
     const handlePerRowsChange = async (newPerPage, page) => {
      fetch(page,service, newPerPage,frns);
      setPerPage(newPerPage);
    };
     //Sort serverside
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
    setLoading(true);
    const response=await librery.axiosSort(column,sortdirection,page,size,service,"getver&f="+frns)
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }   
    })  
    setLoading(false);   
}

    //recherche serverside
  const handleSearch=async(size = perPage)=>{
    setLoading(true);
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche+'&f='+frns,1,size,"getver")
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
    return recherche
  }

    //delete versement
    const handleSetData=useCallback((row,att)=>{
      Gfunc.removeItemsByAtt(data,row.DT_RowId,att)
      setDel('')
    },[data,Gfunc])

    //handle delete versement 
      const handleDeleteVers=useCallback(async(row)=>{
        const x=window.confirm("Êtes-vous sure de supprimer cet élément?");
        if(x){
          const response = await Gfunc.axiosPostAction(service,"delver",{id:JSON.parse(row.DT_RowId.substr(1))})
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{
                  handleAlert(true,'success',"L'élément est supprimé avec succès.")
                 // handleSetData(row,"DT_RowId")
                 // setDel(row.DT_RowId)
                 fetch(currentPage,service,perPage,frns);
                 Apis.Caisse()
          })  
        }
      },[handleSetData,Gfunc,currentPage,service,perPage,frns,fetch])

    //handle Inputs automaticcaly (auto fill form) 
      const handleAutoInput=useCallback(async(row)=>{
        setFrns(row.frnsId)
        setFrnsN(row.frns)
        setMtn(row.mtn);
          if(row.bonId!=null){
            var bl=bonL;
            bl=await fetchBon();
            if(bl.length&&bl.length>0){setBon(row.bonId)
                const x=bl[Gfunc.getIndex(bl,row.bonId,'id')] 
                if(x){    
                setBon(row.bonId)      
                setBonN(x.numero)
                setBonD(x.date)
              }
                else{
                setBon('')
                setBonD('')
                setBonN('')}
              }
              }
                
          else{
            setBon('')
            setBonN('')
            setBonD('')
          }
          document.getElementById('iNote').focus();
      },[Gfunc,bonL,data,fetchBon])

      //modifier un champs avec un select
    const handleSelect=useCallback((row,att,f=()=>{})=>{
      f()
      setSelecto(row[att])
      setItem(row.DT_RowId)
      setItemN(att)
    },[])

    const conditionalRowStyles=[
       { when: row => row.type==='achat',
          style: {
                color: "red",
          }
        },
       { when: row => row.type!=='achat',
          style: {
                color: 'green',
          }
        }
    ]

      //handle live search
    function liveSearch(e,search){
      if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
      }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
             }
            }
    }

    //open dialogue
    const handleClickOpen = () => { 
      props.handleOpenFrns()
      props.handleOpenUsers()
      setDoSearch(false)
      setPerPage(10);
      setCurrentPage(1);
      setOpen(true); 
      setType(-1)
      setTypeN('Versement')
      setFrns('');
      setMtn('');
      setCaisse((localStorage.getItem('uid'))?localStorage.getItem('uid'):'')
      setCaisseN((localStorage.getItem('name'))?localStorage.getItem('name'):'')
      setNote('')
      setBonD('')
      setBonN('')
      setBon('')
      setFrnsN('')
      setData([])
    };

    //close dialogue
    const handleClose = () => { setOpen(false); };

    const handleChangeFrns= async(idf) =>{
      fetch(1,service,perPage,idf)
    }
    const handleReset= async(e) =>{
      setDoSearch(false)
      setPerPage(10);
      setCurrentPage(1);
      setType(-1)
      setTypeN('Versement')
      setCaisse((localStorage.getItem('uid'))?localStorage.getItem('uid'):'')
      setCaisseN((localStorage.getItem('name'))?localStorage.getItem('name'):'')
      setMtn('');
      setNote('')
      setBon(null)
      setBonN('')
      setBonD('')
      setData([])
      setFrns('')
      setFrnsN('')
      setReset(!reset);
    }

//handle update note
const handleOnEditNote=useCallback(async(note,row,att,action)=>{
  const edt1=note
  var edt=Gfunc.DeleteSpace(edt1);
  if(row[att]!==edt){
    const attrib=row[att];
    const response=await librery.axiosEdit2(service,action,row,edt,att) 
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{row[att]=edt})
    if(response.data.err) {row[att]=attrib}
  }else {row[att]=edt}
  setSelecto('')
},[librery,Gfunc]);

    const handleSubmit= async(e) =>{
     if(parseInt(mtn)>0) {const data={
        date:Gfunc.formatDate(new Date(selectedDate)),
        time:Gfunc.getClock(selectedTime),
        typ:(type===-1)?true:false,
        mtn:parseFloat(mtn),
        idfrns:parseInt(frns),
        user:parseInt(caisse),
        note:note,
        idb:parseInt(bon),
      }
      const respon=await Gfunc.axiosPostAction(service,"addver",data)
      if(respon.data.err&&respon.data.err!=='-1'){
        handleAlert(true,'error',respon.data.err)
      }else
      Gfunc.axiosResponse(respon,()=>{
         fetch(1,service,perPage,frns)
         setType(-1)
         setTypeN('Versement')
         setCaisse((localStorage.getItem('uid'))?localStorage.getItem('uid'):'')
         setCaisseN((localStorage.getItem('name'))?localStorage.getItem('name'):'')
         setSelectedDate(new Date())
         setSelectedTime(new Date())
         setMtn('');
         setNote('')
         setBonN('')
         Apis.Caisse();
         const typee=(type===1)?"remboursement":"versement"
         handleAlert(true,'success','Le '+typee+' est ajouté.')
         document.getElementById('iMtn').focus();
      })}
      else{
        handleAlert(true,'error','Veuillez saisir un montant positif!')
      }
    }
    const columns =useMemo(
      () => [
        {
            name: "DATE",
            minWidth:'100px !important',
            selector: row=>row.date,
            sortable: true,
            sortField:'date',
            cell:(row)=>{
              return(
                  <div>
                    <DatePickerUpd selecto={selecto} Item={Item} ItemN={ItemN} row={row}
                  setSelecto={setSelecto}
                  name="date"
                  service={service} action={(row.type==='achat')?'updbon':'updver'} extra=''
                  handleSelect={handleSelect} setItem={setItem} handleAlert={handleAlert}/>
                
              </div>
              )
          }
        },
        {
          name: "FOURNISSEUR",
          minWidth:'160px !important',
          selector: row=>row.frns,
          sortable: true,
          sortField:'frns',
          cell: (row) => { 
            return (
                <AutocompleteS
                  datein={row.date} agentin={row.agent}
                  service={service} action={(row.type!=="achat")?'updver':'updbon'} 
                  defaultValue={row.frns} row={row} list={props.frnsL} 
                  id={(row.type!=="achat")?'frnsId':'idfrns'} name='frns' attr='name'
                  onOpen={props.handleOpenFrns} handleAlert={handleAlert}/>
                );
           },
      },
      {
          name: "MONTANT",
          style:numberColumn,
          conditionalCellStyles:mtnControlFourni,
          selector: row=>row.mtn,
          minWidth:'150px !important',
          sortable: true,
          sortField:'mtn',
          cell: (row) => {
            return (
              <div>
                {(row.type==='achat')?
                <div style={{padding:"0 5px"}}>{new Intl.NumberFormat().format(row.mtn)}</div>
                : <InputNbrEditable
                    min={(row.mtn>0)?0:undefined} max={(row.mtn<0)?-1:undefined} positif={true}
                    service={service} action='updver'
                    row={row} att="mtn" value={row.mtn} id='DT_RowId'
                    traetResp={()=>{Apis.Caisse();fetch(1,service,perPage,frns)}}
                    handleAlert={handleAlert}
                />
              }
              </div>
             );
           },
      },
      {
          name: "par",
          minWidth:'150px !important',
          selector: row=>row.agent,
          sortable: true,
          sortField:'agent',
          cell: (row) => { 
            return (
              <div >
              {(row.type==="achat")? <div >{row.agent}</div>: 
                 <AutocompleteS
                  datein={row.date} agentin={row.agent}
                  service={service} action={'updver'} 
                  defaultValue={row.agent} row={row} list={props.userL} 
                  id={'agentId'} name='agent' attr='name'
                  onOpen={()=>props.handleOpenUsers()} handleAlert={handleAlert}/>}
            </div> 
                );
           },
      },
      {
          name: "solde",
          style:numberColumn,
          minWidth:'150px !important',
          selector: row=>row.solde,
          sortable: true,
          sortField:'solde',
          cell: (row) => {
            return (
                <div>{new Intl.NumberFormat().format(row.solde)}</div>
             );
           },
      },
      {
        width:"45px",
        cell: (row) => {
          return (
            <div id="icons" >
              {(row.type==="achat")
                ?<div onClick={()=>{handleAutoInput(row)}} title="Saisir automatiquement" className='flex-wrapper'><Keyboard fontSize="small" className={classes.blackIcon}/></div> 
                :<div onClick={()=>{handleDeleteVers(row)}} title="Supprimmer" className='flex-wrapper'><IndeterminateCheckBox  fontSize="small" className={classes.redIcon}/></div> }
            </div>
           );
         },
      } 
      ],[classes,handleDeleteVers,handleAutoInput,handleSelect,selecto,Item,ItemN,props,handleAlert]);


      const ExpanableComponent = ({ data }) => {
        const [noteB,setNoteB] = useState('');
        return (
            <div className='flex-wrapper' style={{padding:'5px',justifyContent:'center',width:'100%',background:'#C7C7C7'}}>
              <Comment/>
              <TextareaAutosize  style={textAreaEdit2} onFocus={(e)=>{setNoteB(e.target.value)}} onChange={(e)=>{setNoteB(e.target.value);}} minRows={3} value={(noteB?noteB:(data.note)?data.note:'')} onBlur={e=>handleOnEditNote(noteB,data,"note",(data.type==='achat')?'updbon':'updver')}/>
            </div>
        )
    }
  
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Payment fill='black' style={{height:'20px',width:'20px',padding:"1px"}}/>}>Versement</Button>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsBigFixed}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
            <div className='DialogTitle'>
              <Icon path={mdiPlusBox} size={1} />
              <label> {addTitle}</label>
            </div>
        </DialogTitle>
        <DialogContent id="versDiagCont" className={classes.dialogueContent}>
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
            <fieldset className="fieldsetTop" id="vers">
               <ValidatorForm id="validate"  ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
                 <div className="rowGrid" id="row-1">
                  <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                              disableToolbar
                              margin='dense'
                              size="small"
                              className='smallText'
                              inputProps={{className:classes.diagFormInput}}
                              KeyboardButtonProps={{style:{padding:"0px"}}}
                              inputVariant="outlined"
                              variant="inline"
                              maxDate={new Date()}  
                              invalidDateMessage="Le format de la date est incorrect"   
                              maxDateMessage="La date ne doit pas être supérieure à la date maximale"
                              format="dd/MM/yyyy"
                              name="iDate"
                              id="date-picker-inline"
                              label={labelText1}
                              keyboardIcon={<Today className={classes.blackSmallIcon}/>}
                              value={selectedDate}
                              onChange={handleDateChange}
                              required 
                      />
                  </MuiPickersUtilsProvider>  
                  <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                          <KeyboardTimePicker
                                    showTodayButton={true}
                                    className='smallText'
                                    inputProps={{tabIndex: "2",className:classes.FormInput}}
                                    KeyboardButtonProps={{style:{padding:"0px",height:'30px'}}}
                                    okLabel="Confirmer"
                                    cancelLabel='Annuler'
                                    todayLabel="Maintenant"
                                    invalidDateMessage="Format est incorrect"   
                                    margin='dense'
                                    size="small"
                                    autoOk={true}
                                    orientation='landscape'
                                    inputVariant="outlined"
                                    ampm={false}
                                    maxDate={new Date()} 
                                    format="HH:mm"
                                    id="iTime"
                                    label={labelText8}
                                    value={selectedTime}
                                    keyboardIcon={<AccessTimeOutlined className={classes.blackSmallIcon}/>}
                                    onChange={handleTimeChange} />            
                    </MuiPickersUtilsProvider> 
                    <Autocomplete
                                key={reset}
                                disableClearable
                                className='smallAuto'
                                id="iFrns"
                                onChange={(e,newValue) =>{
                                    setFrns(newValue.id)
                                    setFrnsN(newValue.name)
                                    handleChangeFrns(newValue.id)}
                                  } 
                                options={props.frnsL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText2}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    autoFocus 
                                    required/>
                                )}/>
                    <TextField margin="dense"  label={labelText3} size="small" type='number' value={mtn} variant="outlined"
                        className='smallText'
                        id="iMtn"
                        inputProps={{className:classes.diagFormInput}}
                        InputProps={{ inputProps: { min: 0 } }}
                        onChange={e =>setMtn(e.target.value)} required/>
                     <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iCaisse"
                                onChange={(e,newValue) =>{
                                  //const idf=props.userL[Gfunc.getIndex(props.userL,newValue,"name")].id
                                    setCaisse(newValue.id)
                                    setCaisseN(newValue.name)
                                  }}
                                value={{id:parseInt(caisse),name:caisseN}}
                                options={props.userL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => (option.id=== value.id)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText4}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    required/>
                                )} />
                    </div>
                    <div className="rowGrid" id="row-2">
                    <TextField size="small" variant="outlined" label={labelText5} id="iNote" name="iNote"  value={note}
                        margin="dense"
                        className='smallNote'
                        //inputProps={{className:classes.diagFormNoteInput}}
                        onChange={e =>setNote(e.target.value)} 
                        multiline
                        rows={2} />
                        <div className="grid2 buttOnfit">
                        <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iType"
                                onChange={(e,newValue) =>{
                                    setType(newValue.id)
                                    setTypeN(newValue.name)
                                  } }
                                value={{id:type,name:typeN}}
                                options={typeL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => (option.id=== value.id)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText6}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    required/>
                                )} />
                    <Autocomplete
                                key={reset}
                                disableClearable
                                className='smallAuto'
                                id="iBon"
                                onChange={(e,newValue) =>{setBon(newValue.id);setBonD(newValue.date);setBonN(newValue.numero)}} 
                                onOpen={(e)=>{handleOpenBon();}}
                                options={bonL}
                                value={{id:bon,date:bonD,numero:bonN}}
                                getOptionLabel={(option) => option.date+' ['+option.numero+']'}
                                getOptionSelected={(option, value) => option.id === value.id || value.id===''}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText7}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    />
                                )} />
                    <Button className={classes.PrintBtn} variant="contained" color="primary" type='submit' startIcon={<Add/>}>Ajouter</Button>
                    <Button className={classes.PrintBtn} variant="contained" color="primary" onClick={handleReset} startIcon={<RotateLeft/>}>Réinitialiser</Button>
                    </div>
                    </div>
                </ValidatorForm>
                
            </fieldset>
            { (doSearch)? 
         <div>
        <div className='table-header'>
            <div className="button-flex-wrapper"> 
              <SituationFournisseur data={data} frns={frnsN}/>
            </div>  
            <div className='search'>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="Recherche" value={search} size="small" margin='none' variant="outlined"
                     className={classes.search}
                     InputProps={{startAdornment: (<Search style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px' }} />),}}
                     onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} 
                     onFocus={(e)=>{setFocused(e.target.name)}}
                     onBlur={(e)=>{setFocused('');}}
                   />
            </Box>  
           </div>
            </div>   
              <div id={(data.length<=10)?"versDT":"versDT2"}>
                <DataTable 
                  persistTableHead
                  columns={columns}
                  data={data}
                  title="Situation fournisseur"
                  noDataComponent={param.ErreurData}
                  paginationRowsPerPageOptions={[5,10,15,20,25,30,40,50,60,70,80,90,100]}
                  progressPending={loading}
                  progressComponent={<CircularProgress className={classes.circularProgress} />}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  expandOnRowClicked 
                  expandableRows 
                  expandableRowsComponent={ExpanableComponent} 
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  sortServer={true}
                  onSort={handleOnSort}
                  highlightOnHover
                  customStyles={customStyles}
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}            
                  conditionalRowStyles = { conditionalRowStyles }
                />
              </div>
            </div>:null}
            </DialogContent>
      </Dialog>
      </div>
  );
}