import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import {Paper,TextField, Box, Backdrop, CircularProgress, Divider} from '@material-ui/core';
import DataTable  from "react-data-table-component";
import {Search} from '@material-ui/icons';
import { useStyles } from '../../css/muiStyle';
import LogoBinars from '../../img/logos/Logo-Binar-orange.png';
import { AlertCompo ,CustomizedProgressBars,NoDataComponent,NotAllowedComponent} from "../../js/components";
import {customStyles, numberColumn}from '../../css/datatable-custom'
import '../../css/histoBinars.css'
import axios from "axios"
import AddBinars from "./modals/addBinars";
 


export default function Irh_bnr(props) {
    const param=props.param;
    const Gfunc=require('../../Gfunc')
    const librery=require('../../js/data_table_librery')
    const [data, setData] = useState([]);
    const [tous, setTous] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search,setSearch]=useState('');
    const [,setIcon]=useState('');
    const service="_irh.php"
    const srv="categorie"
    const classes=useStyles();
    const [severity, setSeverity] =useState("info");
    const [open, setOpen] =useState(false);
    const [binars, setBinars] =useState(0);
    const [messageA, setMessageA] =useState('');
    const [noData,setNoData]=useState(false);
    const [doSearch,setDoSearch]=useState(false);
    const [notAllowed,setNotAllowed]=useState(false);
    const [errMessage,setErrMessage]=useState('');
    const [records,setRecords]=useState('');
    const [sold, setSold] = useState('');
    const [seuil, setSeuil] = useState('');
  


    const fetch= useCallback(async() => {
      setLoading(true);
      const response=await Gfunc.axiosGetAction(service,'getbinhist')
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==undefined){
          setData(response.data.jData);
          setTous(response.data.jData)
          setDoSearch(true)
          //setNoData(true)
          //const elem=parseFloat(document.getElementById("binars").innerHTML.replace(/\s/g, ''))
          if(response.data.jData.length>0){
            setBinars(response.data.jData[0].solde)
          }
        }
      })
      setLoading(false);
    },[])

    const fetchSold= useCallback(async() => {
      const response= await axios.get( param.urlService+service+'?do=getatsold',{ headers: Gfunc.getHeader()});
      if(response.data.err&&response.data.err!=="-1"){
          setErrMessage(response.data.err)
          setNotAllowed(true)
        }else{
          Gfunc.axiosResponse(response,()=>{
              if(response.data.jData){
                setSold(response.data.jData);
              }
          })
        } 
  },[Gfunc,librery]);
  
    const fetchSeuil= useCallback(async() => {
      const response= await axios.get( param.urlService+service+'?do=getatseuil',{ headers: Gfunc.getHeader()});
      if(response.data.err&&response.data.err!=="-1"){
          setErrMessage(response.data.err)
          setNotAllowed(true)
        }else{
          Gfunc.axiosResponse(response,()=>{
              if(response.data.jData){
                setSeuil(response.data.jData);
              }
          })
        } 
  },[Gfunc,librery]);

  const fetchAll=useCallback(()=>{
    fetch()
    fetchSold()
    fetchSeuil()
  },[fetch,fetchSold,fetchSeuil])
   
    const conditionalRowStyles=[
      { when: row => row.isConso===false,
         style: {
               color: "green",
         }
       },
      { when: row => row.isConso===true,
         style: {
               color: 'red',
         }
       }
    ]
  
     //handle alerts properties
     const handleAlert=(open,severity,message)=>{
      setOpen(open)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpen(false)
      }, 5000);
    }

      useEffect(() => {
        fetchAll()
      }, [fetchAll]);
  
    const handleSearch=()=>{
      const filters=[]
      var keys=[] 
      keys=Object.keys(tous[0])
        keys.map( key =>{ 
          const f={}  
          f["att"]=key
          filters.push(f)
          return true
        }) 
      setData(tous.filter((d)=>filters.some(filterEl =>{if(d[filterEl.att]!=null) return((d[filterEl.att].toString().toLowerCase()).includes(search.toLowerCase()))})))
    }
  
  const handleLockIcon=(val)=>{
    setIcon(val);
  }

  useEffect((val) => {
    handleLockIcon(val);
  }, []);

  const columns =useMemo(
      () => [ 
          {
              name: "DATE",
              selector:row=>row.date,
              width:"145px !important",
              sortable: true
            },  
         {
              name: "Agent",
              selector:row=>row.agent,
              minWidth:"150px !important",
              sortable: true
        }, 
         {
              name: "Produit",
              selector:row=>row.produit,
              minWidth:"240px !important",
              sortable: true
        }, 
        {
          name: "Montant",
          minWidth:'110px !important',
          style:numberColumn,
          selector: row=>row.mtn,
          sortField:'mtn',
          sortable: true,
          cell: (row) => {
            return (
              <div>{new Intl.NumberFormat().format(row.mtn)}</div>
            )
          }, 
        },
        {
          name: "QTE.",
          minWidth:'90px !important',
          style:numberColumn,
          selector: row=>row.qte,
          sortField:'qte',
          sortable: true,
          cell: (row) => {
            return (
              <div>{new Intl.NumberFormat().format(row.qte)}</div>
            )
          }, 
        },
        {
          name: "Solde",
          minWidth:'120px !important',
          style:numberColumn,
          selector: row=>row.solde,
          sortField:'solde',
          sortable: true,
          cell: (row) => {
            return (
              <div>{new Intl.NumberFormat().format(row.solde)}</div>
            )
          }, 
        },
      ],[]);
   
    //Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
    function liveSearch(e){
      if(search.length>-1){
        e.preventDefault();
        handleSearch();
      }
    }
    function liveSearch2() {
      handleSearch();
    }

  return (
    <div className="content">
                <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
                <Backdrop className={classes.backdrop} open={loading}>
                  <CircularProgress color="inherit" />
                </Backdrop>               
                <div >
                <div className='table-header'>
                <div className="button-flex-wrapper">
                  <AddBinars from="hist"/>
                </div>
                {
                (data.length!==0||doSearch)? 
                  <div className='search'>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                                       InputProps={{startAdornment: (<Search style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px' }} />),}}
                                       size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
                        </Box>
                  </div>:null}
                  <Paper id="brnInfos" style={{width:"99%",margin:" 10px auto 10px auto", background:"#e6e6e6"}}>
                    <CustomizedProgressBars value={(sold/seuil)*100} max={seuil} current={sold}/>
                    <Divider orientation="vertical" flexItem />
                    {(data.length!==0)? 
                    <div className="binarsHead">
                      <div className="staticsHead">
                      <h2>{Intl.NumberFormat().format(parseFloat(binars))}</h2>
                      <p>Solde</p></div><img src={LogoBinars} style={{width:"40px",height:"auto"}}/></div>
                    :<div>
                    </div>}
                  </Paper>
                </div>
                {
                (data.length!==0||doSearch)?
                <div>
                    <DataTable 
                    persistTableHead
                    columns={columns}
                    data={data}
                    paginationPerPage={20}
                    noDataComponent={param.ErreurData}
                    paginationRowsPerPageOptions={[20,30,50,100]}
                    progressPending={loading}
                    progressComponent={<CircularProgress className={classes.circularProgress} />}
                    pagination
                    noHeader={true}
                    highlightOnHover
                    customStyles={customStyles}
                    paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
                    conditionalRowStyles = { conditionalRowStyles }
                    />
                </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
                </div>
      
      </div>
  );
}
