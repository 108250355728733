import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField,FormControlLabel,Checkbox,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {Add,Close} from '@material-ui/icons';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import { AlertCompo } from '../../../js/components';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_pck_add(props) {
    const [name,setName]=useState('');
    const [note,setNote]=useState('');
    const [checked,setChecked]=useState(0);
    const [open, setOpen] = React.useState(false);
    const service="_pck.php"
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const [openA, setOpenA] =useState(false);
    const [message, setMessage] =useState('');
    const [severity, setSeverity] =useState('info');
    
    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
 
 
    const handleClickOpen = () => { 
      setName('');
      setNote('')
      setChecked(0)
      setOpen(true); 
    };

    const handleClose = () => { setOpen(false); };
    
    const handleSubmit= async(e) =>{
      const data={
        pack:name,
        note:Gfunc.stringToNull(note),
        favorite:checked
      }
        e.preventDefault();
      const respon=await axiosPostAction(service,"add",data)
      if(respon.data.err&&respon.data.err!=='-1'){
          handleAlert(true,'error',respon.data.err)
      }else
        axiosResponse(respon,()=>{
                setOpen(false);
                if (respon.data.jData) props.handleAlert(true,"success","Un nouveau pack a été ajouté")
                props.callback();
        })
    }

    const handleChange=(e)=>{
        setChecked((!checked)?1:0)
      }

  return (
    <div>
      <Button  className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} 
               startIcon={<Add/>}>Nouveau pack</Button>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
            <div className='DialogTitle'>
              <Icon path={mdiPlusBox} size={1} />
              <label>Ajouter un nouveau pack</label>
            </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
        <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
            <DialogContent>
                <div id='pck-head'>
                    <TextField  className='smallText' margin="dense" variant="outlined" size="small" label='Nom du pack'   value={name} 
                                onChange={e =>setName(e.target.value)} title='Insérer le nom de la marque' fullWidth  autoFocus required />
                    <FormControlLabel control={ <Checkbox checked={checked} onChange={e=>handleChange(e)} name="checked" color="primary"/> } 
                                                      label="Favori"/>
                </div>
                <TextField size="small" label="Note" id="iNote" name="iNote" className='smallNote' value={note}
                           onChange={e =>setNote(e.target.value)} margin="dense" variant='outlined' multiline rows={2}/>                                  
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type='submit' color="primary" startIcon={<Add/>}>Ajouter</Button>
            </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}