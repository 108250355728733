import React,{useState,useMemo,useCallback,useEffect} from 'react';
import {DialogContent,Paper,Button,Dialog,CircularProgress,TextField,ClickAwayListener,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import {IndeterminateCheckBox,Close} from '@material-ui/icons';
import {customStylesBigHeight} from '../../../css/datatable-custom'
import { useStyles,paperPropsPrint} from '../../../css/muiStyle';
import "../../../css/referentiel.css"
import DataTable from "react-data-table-component";
import $ from 'jquery'
import Iconn from '@mdi/react';
import { mdiKeyboardSettings,mdiKeyboardSettingsOutline } from '@mdi/js';
import {AlertCompo,InputNbrEditable,AutocompleteS} from "../../../js/components"
import ContentEditable from 'react-contenteditable';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_prd_AllRac(props) {
    const Gfunc=require('../../../Gfunc')
    const librery=require('../../../js/data_table_librery')
    const param=useContext(ParamContext);
    const [open, setOpen] =useState(false);
    const [data, setData] =useState([]);
    const [edit, setEdit] =useState(false);
    const [editt, setEditt] =useState(false);
    const [id, setId] =useState('');
    const [openA, setOpenA] =useState(false);
    const [stks, setStks] =useState([]);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const classes=useStyles();
    const service='_prd.php'
    const NO=['Control','Alt','Shift']
    const Ye=["-","*","F11",'F12']


      //handle alerts properties
    const handleAlert=(open,severity,message)=>{
        setOpenA(open)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
      }

    $(function() {
      $("div").removeAttr("data-tag")
    })  

    const handleClickOpen = () => { 
      setOpen(true); 
      setData([]);
      fetch();
    };

    const fetch=async()=>{
      const response=await Gfunc.axiosPostAction('_mag.php','getmag',{id:localStorage.getItem('magid')})
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          setData(response.data.jData.shortcuts) 
        }
      })
    }

      //fetch all users that doesn't have the selected role
      const fetchStock=useCallback(async() => {
        const response=await Gfunc.axiosGetAction("_stk.php","gets")
              if(response.data.err&&response.data.err!=='-1'){
                handleAlert(true,"error",response.data.err)
                setOpen(false)
                return false
              }else
                if(response.data.jData!==undefined){
                  Gfunc.axiosResponse(response,()=>{
                  setStks(response.data.jData);
                  return true
                  })
                }
      },[Gfunc,props])  

   //handle the call for fetch commune
   const handleOpenStock=(event)=>{
    if(stks.length===0)
    {
       fetchStock();
    }else{
      //  setError(true)
    }
   }

    const handleClose = () => { setOpen(false); };

    const HandleTest=(row)=>{
      setId(row.shortcutId)
      setEdit(true)
      setEditt(true)
    } 
  //update shortcuts
  const EditRac=useCallback(async(event)=>{
    event.preventDefault()
    var value='';var test=0
    if(Ye.includes(event.key)){
      value=event.key
    }
    if((event.ctrlKey&&!NO.includes(event.key))||(event.altKey&&!NO.includes(event.key))||(event.shiftKey&&!NO.includes(event.key))){ 
      test=1
      const even=(event.ctrlKey)?'ctrl+':(event.altKey)?'alt+':'shift+'
      value=even+event.key
    }
    if((event.ctrlKey&&event.altKey&&!NO.includes(event.key))||(event.altKey&&event.shiftKey&&!NO.includes(event.key))||
       (event.shiftKey&&event.ctrlKey&&!NO.includes(event.key))){
       const even=(event.ctrlKey&&event.altKey)?'ctrl+alt+':(event.altKey&&event.shiftKey)?'alt+shift+':'shift+'
       value=even+event.key
     }
    if(Ye.includes(event.key)||(event.ctrlKey&&!NO.includes(event.key))||(event.altKey&&!NO.includes(event.key))||(event.shiftKey&&!NO.includes(event.key))|| 
       (event.ctrlKey&&event.altKey&&!NO.includes(event.key))||(event.altKey&&event.shiftKey&&!NO.includes(event.key))|| (event.shiftKey&&event.ctrlKey&&!NO.includes(event.key))){
         const response = await Gfunc.axiosPostAction(service,"updshort",{att:'shortcut',id:'c'+id,val:value})
         if(response.data.err){
           handleAlert(true,'error',response.data.err)
           setEdit(false)
         }else{
           Gfunc.axiosResponse(response,()=>{
             if(response.data.jData===true){
               const objIndex =  data.findIndex((obj => obj.shortcutId ===id ));
               data[objIndex].shortcut=value
               setEdit(false)
               setEditt(false)
             // setId('')
             }
         })
       }
    } 
   },[data,id])

   useEffect(() => {
    if(open===true){
        // attach the event listener   
         if(editt===true) {
          document.addEventListener('keydown', EditRac);
         }
         // remove the event listener
         return () => {
         if(editt===true) {          
             document.removeEventListener('keydown', EditRac);
          }
         };
    } 
   }, [editt,EditRac]); 
  
     //update field
  const handleOnEdit=useCallback(async(e,row,att,idd)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
        if(row[att]!==edt &&edt!==''){
          const attrib=row[att];
          const response=await librery.axiosEdit2(service,'updshort',row,edt,att,idd,'text') 
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{
            if(response.data.jData){
              row[att]=edt;e.target.innerHTML=edt
             /* const objIndex =  data.findIndex((obj => obj.shortcutId ===row.shortcutId ));
              data[objIndex].shortcutName=edt1*/
            }
          })
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt}
  },[librery,Gfunc]);
  
  
  const handleDelete=useCallback(async(row)=>{
    const response = await Gfunc.axiosPostAction(service,"delshort",{shortcutId:"c"+row.shortcutId})
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
      setOpen(true)
    }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData===true){
          const objIndex =  data.findIndex((obj => obj.shortcutId ===row.shortcutId ));
          data.splice(objIndex,1)
          handleAlert(true,"success","La suppression du raccourci a été supprimeé avec succès")
        }
    })
  }
  },[data])

    const columns =useMemo(
        () => [ 
          {
            name: "Raccourci",
            selector: row=>row.shortcut,
            width:'180px !important',
            sortable: true,
            cell: (row) => {
              return (
                <div  id={'rac'+row.shortcutId} className='shortcut'>
                    {
                    (row.shortcutId===id&&edit)?
                    <ClickAwayListener onClickAway={()=>{setEdit(false);setEditt(false)}}>
                     <TextField  id='rac' value={row.shortcut} color="primary" variant="outlined" />
                    </ClickAwayListener> :<div  className="content-editable" onClick={()=>HandleTest(row)}>{row.shortcut}</div>
                    }
                </div>
               );
             }
          },
          {
            name: "Nom",
            selector: row=>row.shortcutName,
            maxWidth:'150px !important',
            sortable: true, 
            cell: (row) => {
              return (
              <ContentEditable
                  html={(row.shortcutName)?row.shortcutName:''}
                  name="nom"
                  data-column="item"
                  className="content-editable"
                  disabled={false} 
                  onBlur={e => handleOnEdit(e,row,"shortcutName",'shortcutId')}
               />
               );
             },
          },
          {
            name: "Produit",
            selector: row=>row.produit,
            sortable: true,
            minWidth:'200px !important',
            cell: (row) => {
              return (
              <div id='prd-name'>{row.produit}</div>
               );
             },
          },
          {
            name: "Qte.",
            selector: row=>row.qte,
            sortable: true,
            width:'90px !important',
            cell: (row) => {
              return (
                <InputNbrEditable service={service} action='updshort' min={1} row={row} att="qte" value={row.qte} id='shortcutId' 
                                  idi={row.shortId} handleAlert={handleAlert}/>
               );
             }
          },
          {
            name: "stock",
            selector: row=>row.stock,
            sortable: true,
            minWidth:'100px !importante',
            cell:(row)=>{
              return(
                    <AutocompleteS service={service} action='updshort' defaultValue={row.stock} row={row} list={stks} rowId='shortcutId'
                                   id='stockId' name='stock' attr='nom' onOpen={handleOpenStock} handleAlert={handleAlert} />  
              );
          }
          }, 
          {
            width:"20px",
            cell: (row) => {
              return (
                <div onClick={()=>handleDelete(row)} title="Supprimer raccourci"><IndeterminateCheckBox className={classes.redIcon} fontSize="small"/></div>   
                );
             },
          } 
        ],[edit,editt,id,HandleTest]);

  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} 
       startIcon={<Iconn path={ mdiKeyboardSettings } size={0.70}  color='black' />}>Raccourcis Produits </Button>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsPrint}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
           <div className='DialogTitle'>
              <Iconn path={mdiKeyboardSettingsOutline} size={1} />
              <label>Les raccourcis des produits </label>
            </div>
        </DialogTitle>
            <DialogContent >
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
              <DataTable
                  key={Math.floor(Math.random() * 100)}
                  columns={columns}
                  data={data}
                  noDataComponent={param.ErreurData}
                  defaultSortAsc={true}
                  pagination
                  paginationRowsPerPageOptions={[5, 10, 20, 30]}
                  highlightOnHover
                  customStyles={customStylesBigHeight}
                  selectableRowsComponentProps={{ className: classes.checkStelect }}
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
                  contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
                  progressComponent={<CircularProgress className="circularProgress" />}
             />
      
            </DialogContent>
      </Dialog>
      </div>
  );
}