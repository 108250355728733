import React, {useMemo} from "react";
import DataTable from "react-data-table-component";
import ReactToPrint from "react-to-print";
import LogoBq from '../img/logos/logo_bq.png';
import {useStyles} from '../css/muiStyle';
import {numberColumnTicket,customStylesTicket,customStylesTicket2} from '../css/datatable-custom'
import {Button,Tooltip} from '@material-ui/core';
import {Print} from '@material-ui/icons';
import  '../css/retour.css';
import '../css/global.css'
import '../css/ticket-bon.css'
//import QRCode from "react-qr-code";
import { BackdropCompo, RaccBtn } from "./components";
import { useContext } from "react";
import ParamContext from "../Contexte";

export default function  PrintTicket (props) {
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const [detail, setDetail] = React.useState({});
  const Gfunc=require('../Gfunc')
  const mdi=require('@mdi/js');
  const classes=useStyles();
  const [error, setError] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const param=useContext(ParamContext);



  const handleError=()=>{
    setError(true)
  }

  const handleLaoad=()=>{
    setLoad(true)
  }

  const handleAfterPrint = React.useCallback(() => {
    //console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    setLoading(false);
    //console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(async() => {
    let det=null;
    //console.log("`onBeforeGetContent` called");
    setLoading(true);
    setText("Loading new text...");
    const response = await Gfunc.axiosPostAction("_vnt.php","gettiket",{idt:(props.val!=='prod'||props.from==='vnt')?props.idt:props.row.tiketId})
    Gfunc.axiosResponse(response,()=>{
        det=response.data.jData
      })
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setDetail(det)
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText, setDetail,props]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    return (
        <button>Click</button>
    );
  }, []);


  const columns = useMemo(()=>[   
    {
        name: "Produit",
        selector: row=>row.produit,
        sortable: false,
        width:'132px !important'
    },
    {
        name: "QTE.",
        selector: row=>row.qte,
        sortable: false,
        width:'40px !important',
        style:numberColumnTicket,
        cell:(row)=>{
          return (
          <>
            {new Intl.NumberFormat().format(row.qte)}
          </>
          )
        }
    },
    {
        name: "Prix",
        sortable: false,
        style:numberColumnTicket,
        width:'56px !important',
        cell:(row)=>{
          return (
          <>
            {new Intl.NumberFormat().format(row.prix)}
          </>
          )
        }
    },
    {
        name: "Total",
        selector: row=>row.mtn,
        style:numberColumnTicket,
        sortable: false,
        cell:(row)=>{
          return (
          <>
            {new Intl.NumberFormat().format(row.mtn)}
          </>
          )
        }
    },
  ],[])



const columns2 = React.useMemo(()=>[   
    {
        name: "Produit",
        selector: row=>row.produit,
        sortable: false,
        width:'78px !important',
        cell:(row)=>{
          return (
          <div id='prodName'>
            {row.produit}
          </div>
          )
        }
    },
    {
        name: "QTE.",
        selector: row=>row.qte,
        sortable: false,
        width:'34px !important',
        style:numberColumnTicket,
        cell:(row)=>{
          return (
          <>
            {new Intl.NumberFormat().format(row.qte)}
          </>
          )
        }
    },
    {
        name: "Prix",
        sortable: false,
        style:numberColumnTicket,
        width:'45px !important',
        cell:(row)=>{
          return (
          <>
            {new Intl.NumberFormat().format(row.prix)}
          </>
          )
        }
    },
    {   
        name: "Total",
        selector: row=>row.mtn,
        //minWidth:'80px !important',
        style:numberColumnTicket,
        sortable: false,
        cell:(row)=>{
          return (
          <>
            {new Intl.NumberFormat().format(Math.round(row.mtn))}
          </>
          )
        }
    },
  ],[])

  return (
    <div>
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Bon de vente"
        onAfterPrint={handleAfterPrint}
        onBeforeGetContent={handleOnBeforeGetContent}
        onBeforePrint={handleBeforePrint}
        removeAfterPrint= {false}
        trigger={()=>
            (props.from==="vnt")?<RaccBtn
            id="impTik"
            tip={'Imprimer ticket'}
            className={classes.racBtnRed}
            //onClick={handleClickOpen}
            text={'Impr.'}
            path={mdi["mdiPrinter"]}
            iconColor={"white"}
            racc={"ctrl+F3"} 
            disabled={props.disabled}
          />:(props.from==="jrn")?<span><Tooltip title="Imprimer"><Button className={classes.iconBtn} /*onClick={handleClickOpen}*/ variant="contained" color="primary"  minWidth='250px' > <Print fontSize='small'/></Button></Tooltip></span>:<div  className="clickable" /*onClick={handleClickOpen}*/>{(props.val!=='prod')?props.num:props.row.tiketNum}</div> 
        }
      />
      <BackdropCompo className={classes.backdrop} open={loading}/>
      <div hidden>
<div>
<div ref={componentRef} className={(localStorage.getItem('tiketWidth')==="1")?" ticketPaperContent2":" ticketPaperContent"}>
    <div  id={(localStorage.getItem('tiketWidth')==="1")?"ticket2":"ticket"} >
            <div id='ticketHead'>
            <img src={(error===false&&load===true)?param.logo+localStorage.getItem('logo'):LogoBq} id='logo_ticket' alt='logo'  onLoad={handleLaoad}  onError={handleError} />
            <h3 style={{color:'black',paddingBottom:'5px'}}>{(localStorage.getItem('entet_tiket_1')&&localStorage.getItem('entet_tiket_1')!=="null")?localStorage.getItem('entet_tiket_1'):null}</h3>
              <p>{(localStorage.getItem('adresse')&&localStorage.getItem('adresse')!=="null")?localStorage.getItem('adresse'):null}</p>
              <p>{(localStorage.getItem('entet_tiket_2')&&localStorage.getItem('entet_tiket_2')!=="null")?localStorage.getItem('entet_tiket_2'):null}</p>
              <p>{(localStorage.getItem('fecebook')&&localStorage.getItem('fecebook')!=="null")?localStorage.getItem('fecebook'):null}</p>
              </div>
            <hr />
              <div className="rowGrid" id="row-2">
                <p>Ticket n: <b>{detail.numero}</b></p>
                <p>Date: <b>{detail.date_tiket}</b></p>
              </div>
              <div className="rowGrid" id="row-2">
                <p>Client: <b>{detail.client}</b></p>
                <p>Vendeur: <b>{detail.vendeur}</b></p>
              </div>
              <div className="rowGrid" id="row-2">
                {(detail.tel!=null)?<p>Tel client: <b>{new Intl.NumberFormat().format(detail.tel)}</b></p>:<p></p>}
                <p>Ancien solde: <b>{new Intl.NumberFormat().format(Math.round(detail.ex_solde))}</b></p>
              </div>
             { ((!detail.ventes&&detail.versement>0)||(detail.ventes&&detail.ventes.length===1&&detail.ventes[0].id==null&&detail.versement>0))?<div id='empty-ventes'>Ticket de versement.</div>
                :<div className="rowGrid" id="row-4"><DataTable 
                  persistTableHead
                  columns={(localStorage.getItem('tiketWidth')==="1")?columns2:columns}
                  data={detail.ventes}
                  noHeader={true}
                  customStyles={(localStorage.getItem('tiketWidth')==="1")?customStylesTicket2:customStylesTicket}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                 // paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
                />
                <div className="rowGrid" id="row-3">
                <p>Nombre d'article: <b>{(detail.ventes)?detail.ventes.length:0}</b></p>
                <p >Total: <b>{new Intl.NumberFormat().format(Math.round(detail.total))}</b></p>
              </div>
              </div>
                }
               
              <br/>
               <div className="rowGrid" id="row-4">
                <p>Versement: <b>{new Intl.NumberFormat().format(Math.round(detail.versement))}</b></p>
                <p>Nouveau solde: <b>{new Intl.NumberFormat().format(Math.round(detail.new_solde))}</b></p>
              </div>
              <hr />
              <p style={{textAlign:"center"}}>Merci pour votre fidélité {/*- <b>www.irahaserv.com</b>*/}</p>
            </div>{/*
            <hr className={(localStorage.getItem('tiketWidth')==="1")?"dash2":"dash"}/>
             <div className={(localStorage.getItem('tiketWidth')==="1")?"codeQr2":"codeQr"}>
              <QRCode size={200} level ="L" value={zlib.deflateSync(JSON.stringify(detail)).toString('base64')} /></div>*/}
        </div>
      </div>
      </div>
      </div>
  );
};
