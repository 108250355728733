import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {Add,Close} from '@material-ui/icons';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_chg_add(props) {
    const [name,setName]=useState('');
    const [open, setOpen] =useState(false);
    const classes=useStyles();
    const service="_chg.php"
    
    const handleClickOpen = () => { 
      setName('');
      setOpen(true);
    }
    const handleClose = () => { setOpen(false); };
    
    const handleSubmit= async(e) =>{
        e.preventDefault();
        const response=await axiosPostAction(service,"add",{nom:name})
        if(response.data.err&&response.data.err!=='-1'){
         props.handleAlert(true,"error",response.data.err)
        }else
        axiosResponse(response,()=>{
                if (response.data.jData){
                  props.handleAlert(true,"success","Une nouvelle charge a été ajouté avec succès!.")
                    props.callback();
                    setOpen(false);
                } 
        })
    }
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Add/>}>NOUVEAU TYPE CHARGE</Button>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
       >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
           <div className='DialogTitle'>
              <Icon path={mdiPlusBox} size={1} />
              <label> Ajouter un type de charge</label>
            </div>
        </DialogTitle>
        <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
            <DialogContent>
                    <TextField  className='smallText' margin="dense" variant="outlined" size="small" label='Nom'
                                value={name} onChange={e =>setName(e.target.value)} fullWidth autoFocus required />
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type='submit' color="primary" startIcon={<Add />}>Ajouter</Button>
            </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
