import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable  from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import {CircularProgress, Box,FormControlLabel,Checkbox,TextareaAutosize,TextField,Tooltip} from '@material-ui/core';
import {NoDataComponent,NotAllowedComponent,AlertCompo} from "../../js/components"
import {Search,Lock,LockOpen} from '@material-ui/icons';
import DraggableDialog from './modals/ref_cat_add'
import DraggableDialogList from './modals/ref_cat_listprds'
import { useStyles ,textAreaEdit} from '../../css/muiStyle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {conditionalRowStyles,customStyles}from '../../css/datatable-custom'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Iconn from '@mdi/react';

export default function Ref_cat (props){
  const classes=useStyles();
  const mdi=require("@mdi/js")
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [note,setNote]=useState('')
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [ItemN,setItemN]=useState('');
  const [selecto,setSelecto]=useState('')
  const [item,setItem]=useState('')
  const [iconL, setIconL] = useState([]);
  const [,setIcon]=useState('');
  const[noData,setNoData]=useState(false);
  const[doSearch,setDoSearch]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const[focus,setFocus]=useState(false);
  const[,setTest]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[records,setRecords]=useState('');
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const service='_cat.php'
  const srv="categorie"

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  const fetch= useCallback(async(page,servic = service, size = perPage) => {
    setFocus(true)
    setLoading(true);
    const response=await librery.axiosGet(page,servic,size)
      if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else{
        Gfunc.axiosResponse(response,()=>{
            setRecords(response.data.recordsFiltered)
            if(response.data.data!==undefined && response.data.data.length!==0){
              setData(response.data.data);
              setTotalRows(response.data.recordsFiltered);
              setDoSearch(true)
              setNoData(true)
            }else {setDoSearch(false);setData([])}
        })
      } 
      setLoading(false)
  },[Gfunc,perPage,librery]);

   //fetch categorie
   const fetchIcon= useCallback(async(serv="_cat.php", act = "geticons") => {
     const dat=[];var datt=[]
    const response = await Gfunc.axiosGetAction(serv,act);
     if(response.data.err){
       handleAlert(true,'error',response.data.err)
     }else{
      Gfunc.axiosResponse(response,()=>{
        Object.keys(response.data.jData).map((key, index)=> {
          if(response.data.jData!==null){
            var nom=(response.data.jData[key]==='mdiSignal4G')?'mdiSignal4g':(response.data.jData[key]==='mdiTabletIpad')?"mdiTablet":response.data.jData[key]
            dat.push({id:key,nom:nom})
            datt.push(response.data.jData)
          }
          return true
        })  
        dat.unshift({id:'empty',nom:'Aucun'})
        setIconL(dat);    
     })
     } 
  },[Gfunc])

useEffect(() => {
  if(!focus){fetch(1);}
  Gfunc.loseFocus('.content-editable')
}, [fetch,focus,Gfunc]);

  //fetch icon handler
const handleOpenIcon = useCallback(async () => {
      if(iconL.length===0)
      fetchIcon()
},[iconL,fetchIcon]) 

const handleTest=(val)=>{
  setTest(val)
}

const flagMan = useCallback(async(e,row,att,attr)=>{
    const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: row.DT_RowId.substr(1),att:att, val:(row[att] ===true)?1:0 }))
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        if(row[att]===true) {
          row[att]=false
        }else{
          row[att]=true
        }
          setTest(true)
          handleTest(false)
  
              })
    }  
 },[Gfunc])

//update field
const handleOnEdit=useCallback(async(e,row,att)=>{
  const edt1=e.target.innerHTML
  const edt=Gfunc.DeleteSpace(edt1);
  if(row[att]!==edt){
        const attrib=row[att];
        const response=await librery.axiosEdit(service,row,edt,att) 
        if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
        }else{
            Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
        }
        if(response.data.err) {e.target.innerHTML=attrib}
  }else {e.target.innerHTML=edt}
},[librery,Gfunc]);

const handleOnEditIcon=useCallback(async(val,row,att)=>{
  const response=await librery.axiosEdit(service,row,(val.id!=='empty')?val.nom:'',att) 
  if(response.data.err&&response.data.err!=='-1'){
    handleAlert(true,'error',response.data.err)
  }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData){
        row[att]=val.nom
        setIcon(row.DT_RowId)
        handleLockIcon('')
      }
      })
  }
},[librery,Gfunc]);

const handleLockIcon=(val)=>{
  setIcon(val);
}
useEffect((val) => {
handleLockIcon(val);
}, []);

const handleSelect=(row)=>{
  setSelecto(row.icon)
  setItem(row.DT_RowId)
  setItemN('icon')
}

const Icon=(props)=>{
  const val=(props.auto==='auto')?props.row.toString():props.row.icon.toString()
  const mdiP=mdi[val]
  return(
   <Iconn path={mdiP} size="17px" color="black"/>
  );
}
 
const handleSelectNote=useCallback((row,att)=>{
      setSelecto(row[att])
      setItem(row.DT_RowId)
      setItemN(att)
},[])

  //handle update note
  const handleOnEditNote=useCallback(async(note,row,att,action)=>{
    const edt1=note
    var edt=Gfunc.DeleteSpace(edt1);
    if(row[att]!==edt){
      edt=edt.replaceAll("\n", "\r")
      const attrib=row[att];
      const response=await librery.axiosEdit2(service,action,row,edt,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{row[att]=edt})
      if(response.data.err) {row[att]=attrib}
    }else {row[att]=edt}
    setNote('')
    setSelecto('')
  },[librery,Gfunc]);

const columns =useMemo(
    () => [ 
      {
          width:'25px',
          cell:(row)=>{
            return(
              <DraggableDialogList param={param} row={row}/>
            );
          }
      } , 
      {
          name: "NOM",
          selector:row=>row.categorie,
          sortable: true,
          sortField:'categorie',
          minWidth:'110px !important ',
          cell: (row) => {
            return (
                <ContentEditable
                    html={row.categorie}
                    name="cat"
                    data-column="item"
                    id="content-editable"
                    className="content-editable"
                    disabled={false} 
                    onBlur={e => handleOnEdit(e,row,"categorie")}
                />
             );
           },
      },
      {
        name: "Stock",
        selector:row=> row.stock,
        sortable: true,
        sortField:'stock',
        width:'100px !important',
        cell: (row) => {
          return (
            <div className="checkbox">
              <FormControlLabel control={<Checkbox checked={row.stock} className={classes.checkBox}
                                         onChange={e=>flagMan(e,row,'stock','stock')} inputProps={{ 'aria-label': 'controlled' }}  />}
                                          /> 
            </div>
           );
         },
      },
      {
        name: "Depuis",
        selector:row=> row.dateIn,
        sortable: true,
        sortField:'dateIn',
        width:'100px !important'
      },
      {
        name: "Par",
        selector:row=> row.agentIn,
        sortable: true,
        sortField:'agentIn',
        width:'100px !important'
      },
      {
        name: "Icone",
        selector:row=> row.icon,
        sortable: true,
        width:'120px !important',
        sortField:'icon',
        cell: (row) => {
          return (
           <div className="smallSelectDTIcon">  
            {
              (item===row.DT_RowId && ItemN==='icon' && selecto===row.icon)?
              <ClickAwayListener onClickAway={()=>{setSelecto('')}}>
                <Autocomplete 
                  disableClearable
                  onFocus={handleOpenIcon}
                  loading={iconL.length===0}
                  loadingText="Chargement..."
                  defaultValue={row.icon}
                  onBlur={()=>{setSelecto('');}}
                  onChange={(e,newValue)=>handleOnEditIcon(newValue,row,'icon')}
                  options={iconL}
                  getOptionLabel={(option) =>(option.nom)?option.nom:''}
                  renderOption={(props, option) => (
                   <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} style={{ margin:'auto' }}>
                     {
                      (props.nom!=='Aucun')?
                       <Icon row={props.nom} auto='auto'/>
                      :<b>{props.nom}</b>
                     }
                  </Box>
                    )}
                  renderInput={(params) => (
                  <TextField {...params} color="primary" variant="outlined" size="small"/>
                  )} 
                />
               </ClickAwayListener>
                :(row.icon!==null)?
                <div className="content-editable" onClick={()=>{handleSelect(row)}}>
                  <Icon row={row}/>
                </div>
                :<div className="div-vide"  onClick={()=>{handleSelect(row)}}></div>
            }          
           </div> 
           );
         },
      },
      {
        name: "Comptoir",
        selector:row=> row.onTop,
        sortable: true,
        sortField:'onTop',
        width:'120px !important',
        cell: (row) => {
          return (
            <div className="checkbox">
              <FormControlLabel control={<Checkbox checked={row.onTop} className={classes.checkBox}
                                         onChange={e=>flagMan(e,row,'onTop','onTop')} inputProps={{ 'aria-label': 'controlled' }}  />}
                                          /> 
            </div>
           );
         },
      },
      {
        name: "Note",
        selector:row=> row.note,
        sortable: true,
        sortField:'note',
        minWidth:'150px !important',
        cell: (row) => {
          return (
            <div className="note-container">
            { (row.note===selecto && ItemN==='note' && row.DT_RowId===item)?
            <ClickAwayListener onClickAway={()=>{(note)?handleOnEditNote(note,row,"note",'upd'):setSelecto('')}}>
             <TextareaAutosize style={textAreaEdit} value={note}  onChange={(e)=>{setNote(e.target.value);}}
             /></ClickAwayListener>:
             ((row.note&&row.note.match(/\r/g) || []).length>=1)?
             <Tooltip title={row.note.replaceAll("\r", "/ ")}>
                 <div className="content-editable" onClick={()=>{setNote(row.note);handleSelectNote(row,'note')}}>{row.note.substring(0, row.note.indexOf("\r"))+"..."}</div>
             </Tooltip>:
               <Tooltip title={(row.note)?row.note:''}>
             <div className="content-editable" onClick={()=>{setNote(row.note);handleSelectNote(row,'note')}} >{row.note}</div></Tooltip>}
             </div>
           );
         },
      },
      {
        width:"35px",
        cell: (row) => {
          return (
            <Tooltip title="Activer / Désactiver">
                <div onClick={e=>flagMan(e,row,'activ','activ')} >
                  {(row.activ)?<LockOpen className={classes.redIcon} fontSize="small"/>
                  :<Lock className={classes.greenIcon} fontSize="small"/> }
                </div>        
            </Tooltip>
            );
         },
      }  
    ],[flagMan,handleOnEdit,classes,item,ItemN,selecto,handleSelect,handleSelectNote,handleOnEditNote]);
 
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,'get')
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      })     
  }

   //recherche serverside
   const handleSearch=async(size = perPage)=>{
    setCurrentPage(1)
    setLoading(true);
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,"get")
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })   
    setLoading(false);
    return recherche
  }

function handlePageChange (page) {
  fetch(page,service,perPage);
  setCurrentPage(page);
};
 const handlePerRowsChange = async (newPerPage, page) => {
  fetch(page,service, newPerPage);
  setPerPage(newPerPage);
};

//Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
}
function liveSearch2() {
  handleSearch();
}
  
  return (
    <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <div className='table-header'>
          <div className='button-flex-wrapper'><DraggableDialog callback={()=>fetch(1)} handleAlert={handleAlert}/></div>
          <div className='search'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined" label="Recherche" value={search}
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:'pointer' }} />),}}
                               size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
               </Box>
            </div>
        </div>
        {(data.length!==0||doSearch)?
        <div>
        <DataTable className="table"
            persistTableHead
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              noHeader={true}
              highlightOnHover
              paginationDefaultPage={1}
              //paginationResetDefaultPage={resetDP}
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }

            />
        </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
            
    </div>    

  );
}