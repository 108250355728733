import React,{useState,useMemo,useCallback,useEffect} from 'react';
import {DialogContent,Paper,Button,Dialog,IconButton,Typography, Tooltip} from '@material-ui/core';
import DataTable from "react-data-table-component";
import Draggable from 'react-draggable';
import {IndeterminateCheckBox,Add, AssignmentInd,Close} from '@material-ui/icons';
import {axiosResponse,axiosPostAction,axiosGetAction} from'../../../Gfunc'
import { useStyles,paperPropss} from '../../../css/muiStyle';
import '../../../css/admin.css'
import {customStyles}from '../../../css/datatable-custom'
import {AlertCompo} from "../../../js/components"
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiFormatListGroup } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});



//the dialog that adds previlege to a role**********
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Adm_rol_previlege(props) {
    const param=useContext(ParamContext);
    const [open, setOpen] =useState(false);
    const [option, setOption] = useState([]);
    const [selectOption, setSelectOption] = useState([]);
    const [privsAll, setPrivsAll] = useState([]);
    const [privs, setPrivs] = useState([]);
    const classes=useStyles();
    const [,setDel]=useState(false);
    const [openA, setOpenA] =useState(false);
    const [test, setTest] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service="_rol.php"

    //dialog functions************************
    const handleClickOpen = () => { 
      setOpen(true)
      fetchPrivs();
      setSelectOption('');
    };

    const handleClose = () => {
       setOpen(false); 
       if(test) {props.callback(1)}
        };

     //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
  
    //fetch role prives
    const fetchRolePrivs=useCallback(async() => {
        const datY=[]
        const datN=[]
        const response=await axiosPostAction(service,"getrprvs",{idr:props.row.DT_RowId.substr(1)})
        axiosResponse(response,()=>{
              privsAll.map(el=>{
                const objIndex = response.data.jData.findIndex((obj => obj.id ===el.id));
                if(objIndex!==-1){
                  datY.push(el)
                }else{
                 datN.push(el)
                }
                return true
              })  
              setPrivs(datY)
              setOption(datN)  
               })
     },[privsAll,props.row])

       //fetch all prives
    const fetchPrivs= useCallback(async() => {
        const response=await axiosGetAction(service,"getprivs")
        if(response.data.err){
          handleAlert(true,'error',response.data.err)
        }else
        axiosResponse(response,()=>{
          setPrivsAll(response.data.jData)
        }); 
       },[service]);
    
    useEffect(() => {
      if(privsAll.length!==0) {
          fetchRolePrivs();}
      }, [fetchRolePrivs,privsAll]);

    
  const handleDeleteUser=useCallback(async(row)=>{
    const response=await axiosPostAction(service,"delrpriv",{idp:row.id,idr:props.row.DT_RowId.substr(1)})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    axiosResponse(response,()=>{
      if(response.data.jData===true){
        const objIndex = privs.findIndex((obj => obj.id ===row.id));
        privs.splice(objIndex,1)   
         setDel(true)  
         handleTest(false) 
         setTest(true)
      }  
    })   
  
  },[privs,props.row])

  const columns =useMemo(
    () => [   
      {
          name: "Privilège",
          selector:row=> row.function,
          sortable: true,
          minWidth:'350px !important',
          cell: (row) => {
            return (
              <Tooltip title={row.function}><div>{row.function}</div></Tooltip>
            );
           },
      },
      {
          name: "Module",
          selector:row=> row.module,
          sortable: true,
          minWidth:'160px !important',
      },
      {
        right:true,
        width:'32px',
        cell: (row) => {
          return (
            <Tooltip title="Supprimer le privilège">
               <div onClick={()=>handleDeleteUser(row)} ><IndeterminateCheckBox className={classes.redIcon} fontSize="small"/></div>   
            </Tooltip>
          );
         },
      }  
    ],[handleDeleteUser,classes]);
  
  const handleTest=(val)=>{
     setDel(val)
  }   

  //Add users to role
  const handleAddUsr=useCallback(async() => {
    var id=-1;
    const objIndex = privsAll.findIndex((obj => obj.function ===selectOption));
    if(objIndex!==-1){
       id=privsAll[objIndex].id
    }
    const response=await axiosPostAction(service,"setrpriv",{idp:id,idr:props.row.DT_RowId.substr(1)})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    axiosResponse(response,()=>{
      if(response.data.jData===true){
        privs.unshift({id: id, function:selectOption, module:privsAll[objIndex].module} )     
         setDel(true)  
         handleTest(false) 
         setSelectOption('')
         setTest(true)
      }  
    })   
  },[privsAll,privs,selectOption,props.row])

  return (
    <div>
            <Tooltip title="Ajouter / Supprimer un privilège">
              <AssignmentInd  fontSize='small' className={classes.blueIcon} onClick={handleClickOpen} label="Voir les utilisateurs"/>
            </Tooltip>
            <Dialog
                    open={open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={paperPropss}
            >
                <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
                  <div className='DialogTitle'>
                    <Icon path={mdiFormatListGroup} size={1} />
                    <label>Les privilèges de <b>{props.row.role}</b></label>
                  </div>
                </DialogTitle>
                <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
                <DialogContent>
                <div  id="fieldSet">
                   <div className='flex-row'>
                     <Autocomplete
                          disableClearable
                          value={selectOption}
                          onChange={(e,newValue) =>{setSelectOption(newValue)}}
                          id='iPriv'
                          options={option.map((option) => option.function)}
                          className='smallAuto'
                          renderInput={(params) =>
                              <TextField  margin='dense'  size='small'{...params} variant="outlined"  label="Privilège..." autoFocus
                                          SelectProps={{ MenuProps: {closeAfterTransition:true } }}/>}
                        />
                      <Tooltip title="Ajout d'un / des privilèges">
                        <Button  className={classes.PrintBtn} onClick={()=>handleAddUsr()} variant="contained" type='submit' 
                                 id="bouton" name="addBtn" color="primary" startIcon={<Add/>}>Ajouter</Button>
                      </Tooltip>
                    </div>
                </div>
              <DataTable
                  key={Math.floor(Math.random() * 100)}
                  className="table"
                  persistTableHead
                  columns={columns}
                  data={privs}
                  noHeader={true}
                  customStyles={customStyles}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  pagination
                  paginationRowsPerPageOptions={[5,10,50,100]}
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
             />
          </DialogContent>
        </Dialog>
      </div>
  );
}
