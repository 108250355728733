import React,{useState} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Add,Close} from '@material-ui/icons';
import { useStyles,paperPropsBig} from '../../../css/muiStyle';
import { AlertCompo, BackdropCompo, RaccBtnStatic, RaccBtnStaticStand } from '../../../js/components';
import '../../../css/vente.css';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import Icon from '@mdi/react';
import {mdiFormatListGroup} from '@mdi/js';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//add new vente
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Vnt_cpt_addSearch(props) {

    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const [qte,setQte]=useState(1);
    const [check,setCheck]=useState('');
    const service='_vnt.php'
    const classes=useStyles();
    const [action, setAction] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [open, setOpen] =useState(false);
    const [packs, setPacks] =useState([]);
  
   
    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

   //reste all fiels
   const reset=()=>{
    setPacks([])
    //setFirst(props.data.detail[0].pdetId)
   }

   //reste all fiels
   const fetchPacks=async()=>{
    const response= await Gfunc.axiosGetAction("_pck.php","gets")
    if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,"error",response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          setPacks(response.data.jData)
        }
    })
    //setFirst(props.data.detail[0].pdetId)
   }


    // ajouter un nouveau bon
    const handleSubmit= async(id) =>{
      if(!action){
        setAction(true)
        const dat={
            tiketId:props.ticketId,
            packId:id,
            qte:parseInt(qte),
            retour:props.retour,
            defStock:parseInt(localStorage.getItem('stockId'))
          }
          const response=await Gfunc.axiosPostAction(service,'addvpack',dat)
          if(response.data.err&&response.data.err!=='-1'){
             handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{
            if(response.data.jData!==undefined&&response.data.jData.length>0){
              response.data.jData.map((el)=>{ props.handleAddVnt2(el,parseInt(localStorage.getItem('stockId')),localStorage.getItem('stock'),el.mtn,el.qte,el.pdetId,props.handleAlert,()=>{},()=>{})})
              props.handleAlert(true,'success',"La vente est ajoutée");
              setOpen(false)
            }     
          })
        setAction(false)
      }
      }


    const handleClickOpen = () => { 
      fetchPacks()
      setOpen(true)
      //document.getElementById(props.data.detail[0].pdetId).focus()
    };
  
    const handleClose = () => { 
      reset()
      setOpen(false); //reset();
     };

  return (
    <div>
      <RaccBtnStatic
                            id={'packsBtn'}
                            tip={"Ajouter des packs"}
                            className={classes.racAir}
                            onClick={()=>handleClickOpen()}
                            text={"Packs"}
                            path={"mdiPackageVariantClosed"}
                            iconColor={"white"}
                            digital={props.digital}
                            racc={"F9"}
                            disabled={props.caisseId}
                          />
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsBig}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
            <div className='DialogTitle'>
              <Icon path={mdiFormatListGroup} size={1} />
              <label>La liste des packs</label>
            </div>
        </DialogTitle>
        <BackdropCompo className={classes.backdropDiag} open={action}/>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent >
            <div style={{maxWidth:"300px",margin:"auto"}}>
                <TextField margin="dense"  label={"Qte"} size="small" type='number' value={qte} variant="outlined"
                            className='smallText'
                            id="iQte"
                            inputProps={{className:classes.diagFormInput}}
                            InputProps={{ inputProps: { min: 1 } }}
                            onChange={e =>setQte(e.target.value)} required autoFocus/>
            </div>
            <div className='packGrid'>{packs.map((elem,index)=>{
                return(
                  <RaccBtnStaticStand
                            id={'pack'+elem.id}
                            key={index}
                            tip={elem.name}
                            className={classes.racPack}
                            onClick={()=>handleSubmit(elem.id)}
                            text={elem.name}
                            path={"none"}
                            iconColor={"black"}
                            digital={false}
                            racc={null}
                            disabled={props.caisseId}
                          />
                )
              })}
              </div>
              
            </DialogContent>
            <DialogActions>
               <Button variant="contained" type='submit' color="primary" form="addVAuto" startIcon={<Add />}>Ajouter</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
