import React,{ useState, useRef, useCallback} from "react";
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField, Backdrop, CircularProgress, Tooltip,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import { AlertCompo, CustomizedProgressBars } from "../../../js/components";
import '../../../css/histoBinars.css'
import { Add, Close } from "@material-ui/icons";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from "@mdi/js";
import Icon from "@mdi/react";

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//historique binars 
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

//add binars
export default function AddBinars(props) {

  const classes=useStyles();
  const Gfunc=require('../../../Gfunc')
  const librery=require('../../../js/data_table_librery')
  const [loading, setLoading] = useState(false);
  const [open, setOpen] =useState(false);
  const [qte, setQte] =useState("");
  const [openA, setOpenA] =useState(false);
  const [message, setMessage] =useState('');
  const [severity, setSeverity] =useState('info');
  const [sold, setSold] = useState('');
  const [seuil, setSeuil] = useState('');
  const stk=localStorage.getItem('stockId');
  const service="_irh.php"



    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
    const fetchSold= useCallback(async() => {
      const response= await Gfunc.axiosGetAction(service,"getatsold");
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else{
          Gfunc.axiosResponse(response,()=>{
              if(response.data.jData){
                setSold(response.data.jData);
              }
          })
        } 
  },[Gfunc,librery]);
  
    const fetchSeuil= useCallback(async() => {
      const response= await await Gfunc.axiosGetAction(service,"getatseuil");
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else{
          Gfunc.axiosResponse(response,()=>{
              if(response.data.jData){
                setSeuil(response.data.jData);
              }
          })
        } 
  },[Gfunc,librery]);

  const fetchAll=useCallback(()=>{
    fetchSold()
    fetchSeuil()
  },[fetchSold,fetchSeuil])

  const handleClickOpen = () => { 
    setSold("")
    setSeuil('')
    setQte("")
    setOpen(true); 
    if(props.from!=="hist"){ fetchAll()}
  };
  const handleClose = () => { setOpen(false);}; 

  const handleSubmit= async(e) =>{
    setLoading(true)
    const data={
      qte:qte,
      defStock:parseInt(stk)
    }
      const respon=await axiosPostAction(service,"addbinar",data)
      if(respon.data.err && respon.data.err!=='-1'){
        handleAlert(true,'error',respon.data.err);
      }else
      axiosResponse(respon,()=>{
              if (respon.data.jData) {
                const el=document.getElementById("binarsVal")
                if(el){
                    el.innerHTML= new Intl.NumberFormat().format(respon.data.jData)
                }
                props.handleAlert(true,"success",qte+" Binars sont ajouté a votre solde")
            }
            if(props.from==="hist") props.fetchAll()
      })
      setLoading(false)
  }
  return (
    <>
    {(props.from==="hist")?<Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} 
                   startIcon={<AddIcon/>}>Ajouter Binars</Button>
    :<Tooltip title="Ajout binars"><AddIcon className={classes.grayIcon} onClick={handleClickOpen} /></Tooltip>}
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
           <div className='DialogTitle'>
              <Icon path={mdiPlusBox} size={1} />
              <label>Ajouter des Binars</label>
            </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
            <DialogContent>
                <Backdrop className={classes.backdropDiag} open={loading}>
                <CircularProgress color="inherit" />
                </Backdrop>    
                {(props.from!=="hist")?<CustomizedProgressBars  value={(sold/seuil)*100} max={seuil} current={sold}/>
                :null}
                <ValidatorForm id="addBinars" type="number"  ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} > 
                  <TextField size="small" label={"Quantité"} className='smallText' type="number" margin="dense" id="iQte" name="iQte"  value={qte}
                        onChange={e =>setQte(e.target.value)} variant="outlined" required autoFocus />
                </ValidatorForm>
            </DialogContent>
        <DialogActions>
            <Button variant="contained" type='submit' color="primary" form="addBinars" startIcon={<Add/>}>AJOUTER</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}