import React from 'react'
import { PieChart,Pie,Cell,Tooltip,ResponsiveContainer } from 'recharts';

export default function Pie_chart(props){
    const Gfunc=require('../Gfunc')
    function CustomTooltip({ payload, label, active,...props }) {
        if (active) {  
           var clr=(Gfunc.rgbToHex(payload[0].payload.fill)!==undefined)?Gfunc.rgbToHex(payload[0].payload.fill):payload[0].payload.fill
             return (
                     <div className="custom-tooltip" style={{ border:"2px solid"+clr}}>
                         <p className="intro"><b>{`${payload[0].name}`}</b>:{(payload.length!==0)?new Intl.NumberFormat().format(`${payload[0].value}`):0 }</p>
                         <p className="intro">{((`${payload[0].value}`*100)/props.percent).toFixed(2)+'%'}</p>
                     </div>
         );}
       return null;
    }
    return (
      <div style={{ width:props.dimension, height: 300 }}>
       <ResponsiveContainer>
          <PieChart width={'100%'}  height={'100%'}>
          <Pie dataKey="value" data={props.data} fill="#8884d8" >
          {props.data.map((index,i) =>
              <Cell key={`cell-${i}`} fill={props.color[i]}/>
           )
           }        
          </Pie>
          <Tooltip content={<CustomTooltip percent={props.percent}/>} /> 
          </PieChart>
       </ResponsiveContainer>
     </div>
    );
}