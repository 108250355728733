import {Paper} from 'material-ui'
import React ,{useCallback,useState,useEffect} from 'react'
import {Button,Typography,TextField,Select,MenuItem,FormControl,InputLabel} from '@material-ui/core';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import {ValidatorForm} from 'react-material-ui-form-validator';
import { useStyles} from '../../css/muiStyle';
import {AlertCompo} from "../../js/components"
import {Lock} from '@material-ui/icons';
import Iconn from "@mdi/react";
import { mdiCashPlus } from "@mdi/js";
import BarCharts from '../../compo/BarChart'
import AreaCharts from '../../compo/AreaChart'
import { makeStyles } from '@material-ui/core/styles';
import '../../css/monitoring.css'

const useStyless = makeStyles((theme) => ({
  formControl: {
    margin: 0,
  },
  selectEmpty: {
    marginTop:0,
  },
}));

export default function Mon_msl(props){
  const classess = useStyless();
  const classes=useStyles()
  const lodashClonedeep = require('lodash.clonedeep');
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const Api=require('../../js/Apis')
  const [area, setArea] = useState([]);
  const [UsrCom, setUsrCom] = useState([]);
  const [cat, setCat] = useState([]);
  const [usr, setUsr] =useState([])
  const [months, setMonths] = useState([]);
  const [categories, setCategories] = useState([]);
  const [years, setYears] = useState([]);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [info, setInfo] = useState([]);
  const [days, setDays] = useState([]);
  const [chrg, setChrg] = useState([]);
  const [coms, setComs] = useState([]);
  const [moy, setMoy] = useState([]);
  const [moy1, setMoy1] = useState([]);
  const [act, setAct] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalCom, setTotalCom] = useState(0);
  const [getYr, setGetYr] =useState(false);  
  const [disab, setDisab] =useState(false);  
  const [resp, setResp] =useState(false);  
  const [stat, setState] =useState(false);  
  const [open, setOpen] =useState(false);  
  const [called, setCalled] =useState(false);
  const [called1, setCalled1] =useState(false);
  const [called2, setCalled2] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const [selectUsr, setSelectUsr] =useState('');
  const [selectAgent, setSelectAgent] =useState('');
  const [commis, setCommis] =useState(0);
  const [result, setResult] =useState(0);
  const [totalM, setTotalM] =useState(0);
  const [totalV, setTotalV] =useState(0);
  const [SomTotM, setSomToM] =useState(0);
  const [MoyTotM, setMoyToM] =useState(0);
  const [SomTotV, setSomToV] =useState(0);
  const [MoyTotV, setMoyToV] =useState(0);
  const [prc, setPrc] =useState('20');
  const service='_clo.php'

  //handle alerts properties
  const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  //handle get informations
  const getInfo=useCallback(async(month,year)=>{
    var mn=''
    var index=months.findIndex((obj => obj.monthName ===month));
    if(index!==-1){
       mn=months[index].month
    }
    const response= await Gfunc.axiosPostAction(service,"getinfo",{year:year,month:mn});
    if(response.data.err&&response.data.err!=='-1'){
     handleAlert(true,"error",response.data.err);
    }else
    Gfunc.axiosResponse(response,()=>{
       if(response.data.jData){
          if(response.data.jData.date===null){
             setDisab(true)
          }
           setInfo(response.data.jData)
       }
    })
  },[Gfunc,months])

  //handle get informations
const getCharge=useCallback(async(month,year)=>{
    setChrg([]); 
    var somm=0;var somm1=0;var tab=[];var tab1=[];var r=0;
    var mn=''
    var index=months.findIndex((obj => obj.monthName ===month));
    if(index!==-1){
       mn=months[index].month
    }
    const response= await Gfunc.axiosPostAction(service,"getchgs",{year:year,month:mn});
    if(response.data.err&&response.data.err!=='-1'){
     handleAlert(true,"error",response.data.err);
    }else
    Gfunc.axiosResponse(response,()=>{
       setCalled2(true)
       if(response.data.jData.length!=0){
          response.data.jData.map((el)=>{
            if(el.mtn<0){
              tab.push(el)
              somm=somm+el.mtn
            }else{
              tab1.push(el)
              somm1=somm1+el.mtn
            }
          })
           setTotal(somm)
           setTotalCom(somm1)
           setChrg(tab)
           setComs(tab1)
       }
    })
},[months,Gfunc])

  //handle get days informations
  const getDays=useCallback(async(m,y)=>{
    setCalled(true)
    setDays([]);setResp(false);setArea([])
    var arr=[];var ar=[];var ar1=[];var final=[];var i=1;const pas = 7;var elms=[];var moys=[];var moys1=[]
    var somC=0;var somM=0;var sm=[]
    var mn=Gfunc.MonthName1(m)
    const response= await Gfunc.axiosPostAction(service,"getdays",{year:y,month:mn});
    if(response.data.err&&response.data.err!=='-1'){
     handleAlert(true,"error",response.data.err);
     setCalled(false)
    }else
    Gfunc.axiosResponse(response,()=>{
       setCalled1(true)
       if(response.data.jData){
        if(response.data.jData.length!==0){
         //const daysInCurrentMonth = Gfunc.getDaysInMonth(year,parseInt(mn));
          const alldays=Gfunc.getAllDaysInMonth(y,mn)
          alldays.map(el=>{
            const day=Gfunc.DissectDay(new Date(el))
            arr.push(day)
          })
          setArea(arr)
          arr.map((el)=>{
            const objIndex =  response.data.jData.findIndex((obj => obj.dayNum ===el.num )); 
            if(objIndex!==-1){
              el['marge']=response.data.jData[objIndex].marge
              el['mtn']=response.data.jData[objIndex].mtn
            }else{
              el['marge']=0
              el['mtn']=0
            }
          })
          const comp=arr[0].day
          const comp1=arr[arr.length-1].day
          const week=['Dim.','Lun.','Mar.','Mer.','Jeu.','Ven.','Sem.']
          var ind=week.indexOf(comp)
          var ind1=week.indexOf(comp1)
          week.map((el,index)=>{
            if(lodashClonedeep(comp)!==el&&index<ind){
               ar.push({num:'00',day:el,marge:0,mtn:0,p:'n'})
            }
            if(comp1!==el&&(index>ind1)){
               ar1.push({num:'00',day:el,marge:0,mtn:0,p:'n'})
            }
          })
          var resulat1= ar.concat(arr)
          var resulat2=resulat1.concat(ar1)
          for (let j = 0; j <= resulat2.length; j += pas) {
              if(i<=6){
                final['Semaine'+i]= resulat2.slice(j,j+pas);
                i=i+1
              }
          }
          if(final.hasOwnProperty('Semaine6')){
             while(final['Semaine6'].length<7){
               final['Semaine6'].push({num:'00',day:'',marge:0,mtn:0})
             }
          };
          Object.keys(final).map((key,index)=> {
            var somm=0;var somm1=0;  var count=0;var count1=0
            final[key].map(el=>{
              somm=somm+el.mtn
              somm1=somm1+el.marge
              if(!el.hasOwnProperty('p')){count=count+1}
              if(!el.hasOwnProperty('p')){count1=count1+1}
            })
            final[key]['moyenne']=(count!==0)?Math.ceil(somm/count):0
            final[key]['marge']=(count1!==0)?Math.ceil(somm1/count1):0
          })

          week.map((el)=>{
            const val= Gfunc.AllOccurrences(resulat2,el,'day')
            elms.push(val)
          })
        
          elms.map((el)=>{
            var mo=0;var mo1=0;var cnt=0;var cnt1=0;
            el.map((item)=>{
             mo=mo+resulat2[item].mtn
             mo1=mo1+resulat2[item].marge
             if(!resulat2[item].hasOwnProperty('p')){cnt=cnt+1}
             if(!resulat2[item].hasOwnProperty('p')){cnt1=cnt1+1}
            })
            const m=(cnt!==0)?Math.ceil(mo/cnt):0
            const m1=(cnt1!==0)?Math.ceil(mo1/cnt1):0
            moys.push(m)
            moys1.push(m1)
          })
          if(response.data.jData.length!==0){setResp(true)}
         
          response.data.jData.map((el)=>{
           somC=somC+el.mtn
           somM=somM+el.marge
          })
          setDays(final)
          setMoy(moys)
          setMoy1(moys1)
          setSomToM(somM)
          setSomToV(somC)
          var mt=(response.data.jData.length!==0)?somM/alldays.length:0
          var mt1=(response.data.jData.length!==0)?somC/alldays.length:0
          setMoyToM(mt)
          setMoyToV(mt1)
       }
      }
    })
},[])


  //handle get Year
  const getYears=useCallback(async()=>{
    setGetYr(true)
    const response= await Gfunc.axiosGetAction('_jrn.php',"getsyear");
    if(response.data.err&&response.data.err!=='-1'){
     handleAlert(true,"error",response.data.err);
    }else
    Gfunc.axiosResponse(response,()=>{
       if(response.data.jData){
           setYears(response.data.jData)
           setYear(response.data.jData[0]['year'])
           handleOpenMonth(response.data.jData[0]['year'],'')
       }
    })
},[])

//handle get months
const getMonths=useCallback(async(y,str)=>{
  if(y!==''&&y!==undefined){
      const response= await Gfunc.axiosPostAction('_jrn.php',"getsmonth",{year:y});
      if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err);
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
            setMonths(response.data.jData)
            if(str!=='focus'){
              setMonth(response.data.jData[0]['monthName'])
            }
        }
      })
  }else{
      handleAlert(true,'error','Vous devez choisir une année en amont')
  }
},[])

//fetch year handler
const handleOpenYear= useCallback(async () => {
    if(years.length===0) getYears()
    },[years,getYears])

//fetch utilisateurs handler
const handleOpenMonth = useCallback(async (year,str) => {
    if(months.length===0) getMonths(year,str)
},[months,getMonths])

const getComission=useCallback(async(year,month,val)=>{
   var tm=0;var tv=0;var coms=0;var mn='';
   var index=months.findIndex((obj => obj.monthName ===month));
    if(index!==-1){
      mn=months[index].month
    }
    const objIndex =  usr.findIndex((obj => obj.name ===val )); 
    var agent=  usr[objIndex] .id 
    const response = await Gfunc.axiosPostAction(service,"getasal",{year:year,month:mn,agent:agent});
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData){
        response.data.jData.map((el)=>{
         tm=tm+el.marge
         tv=tv+el.mtn
        })
        coms=(tm*prc)/100;
        setCommis(coms)
        setTotalM(tm)
        setTotalV(tv)
        setUsrCom(response.data.jData);    
      }
  })  
},[usr,prc,months])

const getCat=useCallback(async(month,year)=>{
    setCat([]);var mn=''
    var index=months.findIndex((obj => obj.monthName ===month));
    if(index!==-1){
       mn=months[index].month
    }
    const response = await Gfunc.axiosPostAction(service,"getcats",{year:year,month:mn});
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData){
        setCat(response.data.jData);    
      }
  })
},[usr,months])

 //fetch actionnaire
 const fetchActionnaire= useCallback(async(serv="_usr.php", act = "getact") => {
  const response = await Gfunc.axiosGetAction(serv,act);
  Gfunc.axiosResponse(response,()=>{
      setAct(response.data.jData);    
  })
},[Gfunc])


 //fetch cat
const fetchCat= useCallback(async(serv="_cat.php", act = "gets") => {
  const response = await Gfunc.axiosGetAction(serv,act);
  Gfunc.axiosResponse(response,()=>{
      setCategories(response.data.jData);    
  })
},[Gfunc])

const CalculeRslt=()=>{
    var t=SomTotM+total+totalCom
    setResult(t)
}


useEffect(() => {
  if(!getYr){handleOpenYear();} 
  if(!called&&month!==''&&year!==''){
    getInfo(month,year)
    getDays(month,year);
    getCharge(month,year)
    getCat(month,year)
    fetchActionnaire()
    setState(true)
    fetchCat();
    fetchUsr();
  }
}, [getYr,month,year,getInfo,getCharge,getCat,getDays,fetchActionnaire]);

useEffect(() => {
  if(called1&&called2&&month!==''&&year!==''){CalculeRslt();}
}, [CalculeRslt,called1,called2,month,year]);


const onChangeDate=(mon,year)=>{
  setDays([]);setResp(false);setChrg([]);setSelectUsr('');setUsrCom([])
  setComs([]);setCat([]);setArea([]);setSelectAgent('');setSomToM(0);setSomToV(0);setTotal(0);setCommis('');setPrc('20')
  setTotalCom(0);setResult(0);setDisab(false);setInfo([]);
  setInfo([])
  getInfo(mon,year)
  getDays(mon,year)
  getCharge(mon,year)
  getCat(mon,year) 
  fetchActionnaire()
}

 //fetch users
 const fetchUsr= useCallback(async(serv="_usr.php", act = "gets") => {
  const response = await Gfunc.axiosGetAction(serv,act);
  Gfunc.axiosResponse(response,()=>{
      setUsr(response.data.jData); 
  })
},[Gfunc])

const PayerCom=async()=>{
  var usrr=''
  if(selectUsr!==''){
    if(selectAgent!==''){ usrr=usr[Gfunc.getIndex(usr,selectAgent,"name")].id}
    const note="Prime "+selectUsr+' le '+Gfunc.dernierJourDuMois(year,Gfunc.MonthName1(month))
    const respon=await Gfunc.axiosPostAction('_chg.php',"addcc",{date:Gfunc.dernierJourDuMois(year,Gfunc.MonthName1(month)),typeChargeId:null,
                                        mtn:parseFloat(commis),caissier:usrr,actionId:8,note:note})
    if(respon.data.err && respon.data.err!=='-1'){
        handleAlert(true,'error',respon.data.err);
    }else
        Gfunc.axiosResponse(respon,()=>{
              if (respon.data.jData) {
                //setSelectAgent('')
                getCharge(month,year);
              }
    })
  }
 }

const Cloturer=async()=>{
  if (act && act.length > 0) {
    var capital = 0; var retrait = ''; var dat = [];
   act.map((el)=>{
    var categorie='';var agent='=';var resu='';var cat='';var ag='';
     capital=document.getElementById('act'+el.id).value
     categorie=document.getElementById('cat'+el.id).value
     agent=document.getElementById('ag'+el.id).value
     resu=document.getElementById('resultat'+el.id).value
     retrait=document.getElementById('ret'+el.id).value4
     if(retrait!==0&&categorie!==''){
      const objIndex1 =categories.findIndex((obj => obj.nom ===categorie)); 
      cat=categories[objIndex1].id;
     }else{categorie=''}
     if(agent!==''){
      const obj =usr.findIndex((obj => obj.name ===agent)); 
      ag=usr[obj].id;
     }else{agent=''}
     dat.push({agent:el.id,resultat:parseInt(resu),capital:parseInt(capital),categorieId:cat,caissier:ag})
   })
    var mn=''
    var index=months.findIndex((obj => obj.monthName ===month));
    if(index!==-1){
        mn=months[index].month
    }
    const respon=await Gfunc.axiosPostAction(service,"cloture",{year:year,month:mn,clotures:dat})
    if(respon.data.err && respon.data.err!=='-1'){
        handleAlert(true,'error',respon.data.err);
    }else
        Gfunc.axiosResponse(respon,()=>{
          if(respon.data.jData){
            Api.Caisse();
            setDisab(false)
            getInfo(month,year)
            handleAlert(true,'success',"Le mois est cloturé");
          }
        })
  } else handleAlert(true, "error", "Il n'y a aucun actionnaire désigné pour ce magasin")
}

const Calculate=(val)=>{
   var value=( totalM*val)/100;
   setCommis(value)
}

const CompCap=(props)=>{
  const t=(props.el.actions===0)?0:props.el.actions*props.result
  const [val, setVal] =useState(t);
  const [val1, setVal1] =useState(0);
  const [capitall, setCapitall] =useState(t);
  const [ca, setCa] =useState((props.catL.length!==0)?props.catL[0].nom:'');
  const [ag, setAg] =useState(localStorage.getItem('name'));
  const [usrs, setUsrs] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const handleChange=(val)=>{
    setCapitall(val)
    setVal1(t-val)
  }

  useEffect(() => {
    if(capitall!==0 ){setDisabled(false)}
    if(capitall===0 || capitall==='0'){setDisabled(true)}
  }, [capitall]);  

   //fetch fournisseurs
 const fetchUsrC= useCallback(async(serv="_usr.php", act = "gets") => {
  const response = await Gfunc.axiosGetAction(serv,act);
  Gfunc.axiosResponse(response,()=>{
      setUsrs(response.data.jData);    
  })
},[Gfunc])

//fetch fournisseur handler
const handleOpenUsrC= useCallback(async () => {
  if(usrs.length===0) fetchUsrC()
  },[usrs,fetchUsrC])

  return(
    <div id='ligne1'>
                 <TextField margin="dense"  label={'Résultat '+props.el.name} id={'resultat'+props.el.id} size="small" type='number' 
                      variant="outlined" className='smallText'  
                      value={val} 
                      onChange={e=>setVal(e.target.value)}
                      disabled={true}
                        />
                 <TextField margin="dense"  label={'Capital '+props.el.name} id={'act'+props.el.id} size="small" type='number' 
                      variant="outlined" className='smallText' InputProps={{ inputProps: { min: 0,step:"1",max:val} }} 
                      value={capitall} 
                      onChange={e=>{handleChange(e.target.value)}}
                        />
                  <TextField margin="dense"  label='Retrait ' size="small" type='number' value={val1} variant="outlined"
                             id={'ret'+props.el.id}
                             className='smallText' 
                             //onChange={e =>setVal1(e.target.value)} 
                              />
                  <Autocomplete
                    disableClearable
                    disabled={disabled}
                    className='smallAuto'
                    onChange={(e,newValue) =>{setCa(newValue)}} 
                    id={'cat'+props.el.id}
                    //onFocus={handleOpenCat}
                    value={ca}
                    options={props.catL.map((option) => option.nom)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label='Catégorie' size="small" margin='dense' variant="outlined" required/>)} />

                  <Autocomplete
                    disableClearable
                    //disabled={disabled}
                    className='smallAuto'
                    onChange={(e,newValue) =>{setAg(newValue)}} 
                    id={'ag'+props.el.id}
                    onFocus={handleOpenUsrC}
                    value={ag}
                    options={usrs.map((option) => option.name)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label='Agent' size="small" margin='dense' variant="outlined" required/>)} />
              </div>
  )
}

    return (
      <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <div className='flex-msl' >
            <div>
                <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{setYear(newValue); setMonths([]);setMonth('');setDays([]);setResp(false);setChrg([]);
                                             setComs([]);setCat([]);setArea([]);setSelectAgent('');setSelectUsr('');setCommis('');setPrc('20')                                            ;setSomToM(0);setSomToV(0);setTotal(0);
                                             setTotalCom(0);setResult(0);setDisab(true);setInfo([]);setUsrCom([]);setAct([]);
                                             }}
                    value={year}
                    className='smallAuto'
                    //onFocus={handleOpenYear}
                    options={years.map((option) => option.year)}
                    inputlabelprops={{className:classes.dataLabel}} 
                    renderInput={(params) =>  
                            <TextField margin='dense' size='small'{...params} variant="outlined"  label="ANNÉE" 
                                       SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                                />
                    }
                 />     
            </div>
            <div>
                <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{onChangeDate(newValue,year);setMonth(newValue)}}
                    value={month}
                    onFocus={()=>handleOpenMonth(year,'focus')}
                    options={months.map((option) => option.monthName)}
                    className='smallAuto'
                    inputlabelprops={{className:classes.dataLabel}} 
                    renderInput={(params) =>  
                            <TextField margin='dense' 
                                    // error={errorP}
                                    size='small'{...params} variant="outlined"  label="MOIS" 
                                    SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                                />
                    }
                 />     
            </div>  
          { 
           (info.length!==0&&((info.date!==null&&info.cloture!==0)||(info.date!==null)))? <div id="p-cloture">Mois clôturé à <p id='cloture-mtn'>{new Intl.NumberFormat().format(info.cloture)} 
           </p>le <p id='cloture-mtn'>{info.date}</p></div>:<></>
          }
      </div>
        <div id='msl-div'>
        <div id='right-div'>
        <div id='com-usr'>
       <div class="heading" id='head-color'>Clôture du mois</div>
       <div id='All-row'>
       <div id='ligne'>
           <TextField margin="dense"  label='Marge brute' size="small" type='number' value={SomTotM} variant="outlined"
                      className='smallText' InputProps={{ inputProps: { min: 0 ,step:"0.01"} }} onChange={e =>setSomToM(e.target.value)} 
                      disabled={true}
                    />
           <TextField margin="dense"  label="Chiffre d'affaires" size="small" type='number' value={SomTotV} variant="outlined"
                      className='smallText' InputProps={{ inputProps: { min: 0 ,step:"0.01"} }} onChange={e =>setSomToV(e.target.value)} 
                      disabled={true}
                    />
       </div>  
       <div id='ligne'>
           <TextField margin="dense"  label='Balance des charges' size="small" type='number' value={total} variant="outlined"
                      className='smallText' InputProps={{ inputProps: { min: 0 ,step:"0.01"} }} onChange={e =>setTotal(e.target.value)} 
                      disabled={true}
                    />
           <TextField margin="dense"  label='Balance des commissions' size="small" type='number' value={totalCom} variant="outlined"
                      className='smallText' InputProps={{ inputProps: { min: 0 ,step:"0.01"} }} onChange={e =>setTotalCom(e.target.value)} 
                      disabled={true}
                    />
       </div> 
       <div id='ligneR'>
           <TextField margin="dense"  label='Résultat' id='resultat' size="small" type='number' value={result} variant="outlined"
                      className='smallText' InputProps={{ inputProps: { min: 0 ,step:"0.01"} }} onChange={e =>setResult(e.target.value)} 
                      disabled={true}
                   />
       </div> 
       </div>           
       {
        (act.length!==0&&disab===true)?
        <div id='clo-inf'>
         <Divider>Clôture</Divider>
         <ValidatorForm id="form" onSubmit={Cloturer} >
          {
            act.map((el,index)=>{
              return (
                <CompCap el={el} result={result} catL={categories}/>
              )
            })
          }
          </ValidatorForm  >
         <Divider/>
       </div>:null}
       {(disab===true)?
        <div id='btn-com'>
         <Button variant="contained" id='payem'  form="form" type='submit'  className={classes.BtnPay} color="primary" 
                 startIcon={<Lock fontSize="small"  style={{height:"15px",width:'15px'}} />}>Clôture</Button>
       </div>:null}
      </div>
          
    {
      (stat===true)?
      <Paper id='stat-msl'>
      {
      (resp===true)?
      <div>
      <div className='heading' id='head-color'>Répartition mensuelles de la marge nette </div>
      <table id='table-msl'>
                <tr>
                  <td rowSpan="2"></td>
                </tr>
                <tr id='head-msl'>
                  <th scope="col">Dim.</th>
                  <th scope="col">Lun.</th>
                  <th scope="col">Mar.</th>
                  <th scope="col">Mer.</th>
                  <th scope="col">Jeu.</th>
                  <th scope="col">Ven.</th>
                  <th scope="col">Sam.</th>
                  <th scope="col">Moy.</th>
                </tr>
                {
                  Object.keys(days).map((key, index)=> {
                    return(
                      <tr> 
                        <th id='head-msl'>{key}</th>
                        {
                          days[key].map((el)=>{
                            return(
                              <td id='cell-msl' title={(el.num!=='00')?el.num:null} className={(el.num!=='00')?'tooltip1':''} style={{backgroundColor:(el.marge===0)?'rgb(223 219 219)':''}}>
                                {new Intl.NumberFormat().format(el.marge)} 
                              </td>
                              )
                          })
                        }
                        <td id='moy-msl'>{new Intl.NumberFormat().format(Math.ceil(days[key].marge))} </td>
                      </tr>
                      )
                        })  
                      }
                      <tr>
                        <th scope="row" id='head-msl'>Moyennes</th>
                        {
                          moy1.map((el)=>{
                            return(
                              <td id='moy-msl'>{new Intl.NumberFormat().format(Math.ceil(el))}</td>
                            )
                          })

                        }
                        <td id='moy1-msl' >{new Intl.NumberFormat().format(Math.ceil(MoyTotM))}</td>
                    </tr>
          </table>
          <div className='heading' id='head-color'>Répartition mensuelles de chiffre d'affaire </div>
          <table id='table-msl'>
                <tr>
                  <td rowSpan="2"></td>
                </tr>
                <tr id='head-msl'>
                  <th scope="col">Dim.</th>
                  <th scope="col">Lun.</th>
                  <th scope="col">Mar.</th>
                  <th scope="col">Mer.</th>
                  <th scope="col">Jeu.</th>
                  <th scope="col">Ven.</th>
                  <th scope="col">Sam.</th>
                  <th scope="col">Moy.</th>
                </tr>
                {
                  Object.keys(days).map((key, index)=> {
                    return(
                      <tr> 
                        <th id='head-msl'>{key}</th>
                        {
                          days[key].map((el)=>{
                            return(
                              <td id='cell-msl' title={(el.num!=='00')?el.num:''} className={(el.num!=='00')?'tooltip1':''} style={{backgroundColor:(el.mtn===0)?'rgb(223 219 219)':''}}>
                                {new Intl.NumberFormat().format(el.mtn)} 
                              </td>
                              )
                          })
                        }
                        <td id='moy-msl'>{new Intl.NumberFormat().format(Math.ceil(days[key].moyenne))} </td>
                      </tr>
                      )
                        })  
                      }
                      <tr>
                        <th scope="row" id='head-msl'>Moyennes</th>
                        {
                          moy.map((el)=>{
                            return(
                              <td id='moy-msl'>{new Intl.NumberFormat().format(Math.ceil(el))}</td>
                            )
                          })
                        }
                        <td id='moy1-msl'>{new Intl.NumberFormat().format(Math.ceil(MoyTotV))}</td>
                      </tr>
        </table>
        </div>:<p id='empty-data'>Aucune donnée.</p>
        }
    <div id='tab-chrg'>
      { 
        (chrg.length!==0 &&stat===true)?
        <div>
         <div className='heading' id='head-color'>Charges mensuelles </div>
         <table id='table-msl'>
          <thead id='head-msl1'>
                <th scope="col" style={{width:'120px'}}>DATE</th>
                <th scope="col" style={{width:'100px'}}>Agent</th>
                <th scope="col" style={{width:'120px'}}>TYPE</th>
                <th scope="col" style={{width:'150px'}}>CHARGE</th>
                <th scope="col" style={{width:'100px',marginRight:'5px'}}>MONTANT</th>
          </thead>
          <tbody>
            {
              chrg.map((el)=>{
                return(
                <tr>
                    <td style={{width:'120px'}} id='tab-cell'>{el.date}</td>
                    <td style={{width:'100px'}} id='tab-cell'>{el.agent}</td>
                    <td style={{width:'120px'}} id='tab-cell'>{el.type}</td>
                    <td style={{width:'150px'}} id='tab-cell'>{el.charge}</td>
                    <td style={{width:'100px',textAlign:'right',color:"#990000",fontWeight:"bold"}} id='tab-cell'>{new Intl.NumberFormat().format(el.mtn)}</td>
                </tr>
                )
              })
            }
          </tbody>
        </table>
        <div id='total-div'><p>Total:</p><p id='total-chrg'>{new Intl.NumberFormat().format(total)}</p></div>
        <div className='heading' id='head-color'>Commissions mensuelles  </div>
         <table id='table-msl'>
          <thead id='head-msl1'>
                <th scope="col" style={{width:'120px'}}>DATE</th>
                <th scope="col" style={{width:'100px'}}>Agent</th>
                <th scope="col" style={{width:'120px'}}>TYPE</th>
                <th scope="col" style={{width:'170px'}}>CHARGE</th>
                <th scope="col" style={{width:'100px',marginRight:'5px'}}>MONTANT</th>
          </thead>
          <tbody>
            {
              coms.map((el)=>{
                return(
                <tr>
                    <td style={{width:'120px'}} id='tab-cell'>{el.date}</td>
                    <td style={{width:'100px'}} id='tab-cell'>{el.agent}</td>
                    <td style={{width:'120px'}} id='tab-cell'>{el.type}</td>
                    <td style={{width:'170px'}} id='tab-cell'>{el.charge}</td>
                    <td style={{width:'100px',textAlign:'right',color:"#990000",fontWeight:"bold"}} id='tab-cell'>{new Intl.NumberFormat().format(el.mtn)}</td>
                </tr>
                )
              })
            }
          </tbody>
        </table>
        <div id='total-div'><p>Total:</p><p id='total-chrg'>{new Intl.NumberFormat().format(totalCom)}</p></div>
        <div id='total-gen'><p>Total général :</p><p id='total-chrg'>{new Intl.NumberFormat().format(totalCom+total)}</p></div>
        </div>
        :<p id='empty-data1'>Aucune donnée.</p>}
      </div>
      </Paper>:<></>}
      {
        (param.gros==='true')?
        <Paper id='com-usr'>
      <div className='heading' id='head-color'>Commissions des commerciaux</div>
      <div id='row-1'>
           <Autocomplete
              disableClearable
              className='smallAuto'
              onChange={(e,newValue) =>{setSelectUsr(newValue);getComission(year,month,newValue)}} 
              id='iCom' 
              value={selectUsr}
              options={usr.map((option) => option.name)}
              renderInput={(params) => (
                <TextField {...params}  label='Commercial' size="small" margin='dense' variant="outlined" required />)} 
            />
            <Autocomplete
              disableClearable
              className='smallAuto'
              onChange={(e,newValue) =>{setSelectAgent(newValue)}} 
              id='iCom' 
              //onFocus={handleOpenUsr}
              value={selectAgent}
              options={usr.map((option) => option.name)}
              renderInput={(params) => (
                <TextField {...params}  label='Agent' size="small" margin='dense' variant="outlined" required />)} 
            />
            <TextField margin="dense"  label='%' size="small" type='number' value={prc} variant="outlined"
                        className='smallText' InputProps={{ inputProps: { min: 0 ,step:"1"} }} 
                        onChange={e =>{setPrc(e.target.value);Calculate(e.target.value)}} 
                        />
            <TextField margin="dense"  label='Commission' size="small" type='number' value={commis} variant="outlined"
                        className='smallText' InputProps={{ inputProps: { min: 0 ,step:"0.01"} }} onChange={e =>setCommis(e.target.value)} 
                        />
            <Button disabled={!disab} variant="contained" id='payem' className={classes.BtnPay} color="primary" onClick={()=>PayerCom()} 
                    startIcon={<Iconn path={mdiCashPlus} size="20px" color="white"/>}>Payer</Button>
         </div>
          <div id='table-com'>
          {
            (UsrCom.length!==0)?
            <table id='tab-com'>
            <thead id='head-com'>
              <th>Client</th>
              <th>Ventes</th>
              <th>Marges</th>
            </thead>
            <tbody>
            {
              UsrCom.map((el)=>{
                return (
                <tr>
                  <td id='tab-com-cell'>{el.client}</td>
                  <td id='tab-com-cell'>{new Intl.NumberFormat().format(el.mtn)}</td>
                  <td id='tab-com-cell'>{new Intl.NumberFormat().format(el.marge)}</td>
                </tr>
                )
              })
              }
              <tr>
                  <td id='tab-com-cell'>Total</td>
                  <td id='tab-com-cell'>{new Intl.NumberFormat().format(totalV)}</td>
                  <td id='tab-com-cell'>{new Intl.NumberFormat().format(totalM)}</td>
                </tr>

            </tbody>
            </table>:<div id='empty-result'>Aucune donnée.</div>
          }
          </div>
      </Paper>
        :<></>
      }
      </div>
   <div id='div-left'>
   <Paper id='com-usr' className='mar'>
    {
    (cat.length!==0)?<>
    <BarCharts width={600} height={150} data={cat}
             margin={{ top: 15, right: 30, left:50, bottom: 5 }} barSize={20}
             dataKeyX="categorie" padding={{ left: 10, right: 10 }} dataKeyY="marge" fill="#8884d8" name="Marge "/>
       <Typography className='titleCharts' variant='h6'> Marge nette par catégorie de produit</Typography>
       <br/>
       <BarCharts width={600} height={150} data={cat}
             margin={{ top: 15, right: 30, left:50, bottom: 5 }} barSize={20}
             dataKeyX="categorie" padding={{ left: 10, right: 10 }} dataKeyY="mtn" fill="#008000" name="chiffre d'affaires "/>
       <Typography className='titleCharts' variant='h6'> Chiffre d'affaires par catégorie de produit</Typography>
       </>:<div id='empty-result'>Aucune donnée.</div>
    }
       
   </Paper>
   <Paper id='com-usr' className='mar'>
    {
      (area.length!==0)?
      <> 
        <AreaCharts width={600} height={150} data={area}
             margin={{ top: 15, right: 30, left:50, bottom: 5 }} barSize={20}
             dataKeyX="day" padding={{ left: 10, right: 10 }} dataKeyY="marge" fill="#ffa000" name="Marge "/>
        <Typography className='titleCharts' variant='h6'> Répartition mensuelle de la marge nette</Typography>
        <br/>
        <AreaCharts width={600} height={150} data={area}
             margin={{ top: 15, right: 30, left:50, bottom: 5 }} barSize={20}
             dataKeyX="day" padding={{ left: 10, right: 10 }} dataKeyY="mtn" fill="#964B00" name="chiffre d'affaires"/>
        <Typography className='titleCharts' variant='h6'> Répartition mensuelle de chiffre d'affaires</Typography>
      </>
      :<div id='empty-result'>Aucune donnée.</div>
    }
   </Paper>
   </div>
 </div> 
      </div>    
  
    );
  }
