import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField ,FormControl,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import {Add,Today,Close} from '@material-ui/icons';
import { AlertCompo } from '../../../js/components';
import { Autocomplete } from '@material-ui/lab';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//add new bon
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Mon_chg_add(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const api=require('../../../js/Apis')
    const [selectNature,setSelectNature]=useState('Charge');
    const [selectType,setSelectType]=useState('');
    const [mtn,setMtn]=useState('');
    const [desc,setDesc]=useState('');
    const [type, setType] = useState([]);
    const [open, setOpen] =useState(false);
    const [openA, setOpenA] =useState(false);
    const [message, setMessage] =useState('');
    const [severity, setSeverity] =useState('info');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectCaiss, setSelectCaiss] = useState(localStorage.getItem('name'));
    const [usr, setUsr] = useState([]);
    const nature=[{id:8,name:'Charge'},{id:11,name:'Commission'}]
    const service="_chg.php"


    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    const handleClickOpen = () => { 
        setOpen(true);setDesc('');setSelectNature('');setMtn('');setSelectCaiss(localStorage.getItem('name'));setSelectNature('Charge');
        setSelectType('');setSelectedDate(new Date());handleOpenUsr();
    };
  
    const handleClose = () => { setOpen(false); };

    //date handler
    const handleDateChange = (date) => {
      setSelectedDate(date);
    }; 

    //fetch utilisateurs
    const fetchUsr= useCallback(async(serv="_usr.php", act = "gets") => {
        const response = await Gfunc.axiosGetAction(serv,act);
        Gfunc.axiosResponse(response,()=>{
            setUsr(response.data.jData);    
        })
      },[Gfunc])

    //fetch fournisseurs
    const fetchType= useCallback(async(serv="_chg.php", act = "gets") => {
        const response = await Gfunc.axiosGetAction(serv,act);
        Gfunc.axiosResponse(response,()=>{
            setType(response.data.jData);    
        })
      },[Gfunc])

    //fetch fournisseur handler
    const handleOpenUsr= useCallback(async () => {
      if(usr.length===0) fetchUsr()
      },[usr,fetchUsr])
  
    //fetch utilisateurs handler
    const handleOpenType = useCallback(async () => {
    if(type.length===0) fetchType()
    },[type,fetchType])


      // ajouter un nouveau bon
    const handleSubmit= async(e) =>{
      if(selectType!==''&&selectCaiss!==''&&selectNature!==''){
        const usrr=usr[Gfunc.getIndex(usr,selectCaiss,"name")].id
        const typ=type[Gfunc.getIndex(type,selectType,"nom")].id
        const nat=nature[Gfunc.getIndex(nature,selectNature,"name")].id
        const respon=await axiosPostAction(service,"addcc",{date:Gfunc.formatDate(selectedDate),typeChargeId:typ,mtn:parseFloat(mtn),caissier:usrr,actionId:nat,note:desc})
        if(respon.data.err && respon.data.err!=='-1'){
          handleAlert(true,'error',respon.data.err);
        }else
        axiosResponse(respon,()=>{
                if (respon.data.jData) {
                props.handleAlert(true,'success',"la "+selectNature+" est ajoutée");
                props.callback();
                setOpen(false);
                api.Caisse();
              }
        })
     }
    }

  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<AddIcon/>}>Nouvelle charge/commission</Button>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}>
            <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
              <div className='DialogTitle'>
                <Icon path={mdiPlusBox} size={1} />
                <label>Ajouter une nouvelle charge/commission</label>
              </div>
            </DialogTitle>
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
            <DialogContent >
            <ValidatorForm ref={useRef(null)} id="charge"  onSubmit={handleSubmit} onError={errors => console.log(errors)} > 
             <div id='chg-com'>
                <div id='row-1'>
                <FormControl className='smallText' margin="dense">
                  <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                          <KeyboardDatePicker
                              disableToolbar
                              size="small"
                              //inputProps={{className:classes.diagFormInput}}
                              KeyboardButtonProps={{style:{padding:"0px"}}}
                              variant="inline"
                              inputVariant="outlined"
                              maxDate={new Date()}  
                              invalidDateMessage="Le format de la date est incorrect"   
                              maxDateMessage="La date ne doit pas être supérieure à la date maximale"
                              format="dd/MM/yyyy"
                              name="iDate"
                              id="date-picker-inline"
                              label='Date'
                              keyboardIcon={<Today className={classes.blackSmallIcon}/>}
                              value={selectedDate}
                              onChange={handleDateChange}
                              required />
                  </MuiPickersUtilsProvider>  
                </FormControl>
                <Autocomplete
                    disableClearable
                    id='ityp'
                    className='smallAuto'
                    onChange={(e,newValue) =>{setSelectType(newValue) } } 
                    value={selectType}
                    onFocus={handleOpenType}
                    options={type.map((option) => option.nom)}
                    renderInput={(params) => (
                        <TextField {...params} label='Type' size="small" margin='dense' variant="outlined" required />
                    )} 
                />
                </div>
                <div id='row-1'>
                    <TextField margin="dense"  label='Montant' size="small" type='number' value={mtn} variant="outlined"
                        className='smallText' InputProps={{ inputProps: { min: 0 ,step:"0.01"} }} onChange={e =>setMtn(e.target.value)} 
                        required
                        />
                    <Autocomplete
                                disableClearable
                                className='smallAuto'
                                onChange={(e,newValue) =>{setSelectNature(newValue) } } 
                                id='iNtr'
                                value={selectNature}
                                options={nature.map((option) => option.name)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Nature' size="small" margin='dense' variant="outlined" required />)} 
                   />
                </div>
                <Autocomplete
                    disableClearable
                    id='iNtr' 
                    className='smallAuto'
                    onChange={(e,newValue) =>{setSelectCaiss(newValue)} } 
                    onFocus={handleOpenUsr}
                    value={selectCaiss}
                    options={usr.map((option) => option.name)}
                    renderInput={(params) => (
                        <TextField  {...params} label='Caissier' size="small" margin='dense' variant="outlined" required />
                    )} 
                />
                <TextField size="small" variant="outlined" label='Description' className='smallNote' margin="dense" id="idesc" name="idesc" 
                           value={desc} onChange={e =>setDesc(e.target.value)} placeholder="Insérer une description" multiline  rows={4} required
               />
             </div>
            </ValidatorForm>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type='submit' color="primary" form="charge" startIcon={<Add />}>AJOUTER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
