import React,{useState,useMemo,useCallback} from 'react';
import {DialogContent,Paper,Dialog,Box,TextField,CircularProgress,Fab,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import { useStyles,paperProps } from '../../../css/muiStyle';
import DataTable from "react-data-table-component";
import { customStyles, numberColumn } from '../../../css/datatable-custom';
import { Search,Close } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { AlertCompo } from '../../../js/components';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import {ReactComponent as Stock} from '../../../img/icons/stockW.svg'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';


const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//add new bon
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Vnt_cpt_stks(props) {
    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const [loading, setLoading] =useState(false);
    const [search,setSearch] =useState('');
    const [cat,setCat] =useState(null);
    const [open, setOpen] =useState(false);
    const [data, setData] =useState([]);
    const [tous, setTous] = useState([]);
    const [catL, setCatL] = useState([]);
    const addTitle="Stocks"
    const classes=useStyles();
    const service="_prd.php"
    const [openA, setOpenA] =useState(false);
    const [message, setMessage] =useState('');
    const [severity, setSeverity] =useState('info');
    
    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
    
    

    const fetch = async() => { 
      setLoading(true)
        const response = await Gfunc.axiosGetAction(service,"getstkprp")
        if(response.data.err){
          props.handleAlert(true,'error',response.data.err)
          setOpen(false)
        }else
        Gfunc.axiosResponse(response,()=>{
            setTous(response.data.jData)
            setData(response.data.jData)
            
        })
      setLoading(false)
    };

    const handleClickOpen = () => { 
      setOpen(true); 
      fetch();
      handleOpenCat()
     };

    const handleClose = () => { setOpen(false); setData([]) };

    //fetch Cat
    const fetchCat= useCallback(async() => {
      const response = await Gfunc.axiosGetAction("_cat.php","gets")
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData)
        response.data.jData.unshift({id:null,nom:"Toutes"})
          setCatL(response.data.jData);   
      })
    },[Gfunc]);

    //handle the call for fetch client
    const handleOpenCat=(event)=>{
      if(catL.length===0)
      fetchCat();
    }

    const handleSearchAll=(from,val)=>{
      var d=tous
       if(search!==''){
        const filters=[]
        var keys=[] 
        keys=Object.keys(tous[0])
          keys.map( key =>{ 
            const f={}  
            f["att"]=key
            filters.push(f)
            return true
          }) 
        d=tous.filter((d)=>filters.some(filterEl =>{if(d[filterEl.att]!=null) return((d[filterEl.att].toString().toLowerCase()).includes(search.toLowerCase()))}))
       }
       if(from==='fCat'){ if(val &&val!=="Toutes" && d.length>0){d=d.filter(elem=>{return(elem.categorie===val)})}}
       else
       if(cat && d.length>0){d=d.filter(elem=>{return(elem.categorie===cat)})}
       setData(d)
  }

    function liveSearch(e){
        if(search.length>-1){
          e.preventDefault();
          handleSearchAll();
        }
    }

    const columns =useMemo(
        () => [
          {
              name: "nom",
              selector: row=>row.nom,
              sortable: true,
              minWidth:'150px !important',
          },
          {
              name: "catégorie",
              selector: row=>row.categorie,
              sortable: true,
              minWidth:'160px !important',
          },
          {
              name: "Stock",
              selector: row=>row.stock,
              sortable: true,
              style:numberColumn,
              width:'150px !important',
              cell:(row)=>{
                  return(
                      <div>
                          {new Intl.NumberFormat().format(row.stock)} 
                      </div>
                  )
              }
          },
        ],[Gfunc]);
    
 
  return (
    <>
    <Fab className={classes.sideBtn} variant="extended" size="small" color="secondary" onClick={handleClickOpen} > Stocks</Fab>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
           <div className='DialogTitle'>
              <Stock style={{height:'20px',width:'20px'}}/>
              <label>{addTitle}</label>
            </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
            <DialogContent >
        <div className='table-header'>
            <div className="button-flex-wrapper" id='exp'> 
            <Autocomplete
                                disableClearable
                                id="iCat"
                                className="smallAutoFiltre"
                                onChange={(e,newValue) =>{
                                    setCat(newValue.id)
                                    handleSearchAll("fCat",newValue.nom)
                                  }}
                                options={catL}
                                getOptionLabel={(option) => option.nom}
                                getOptionSelected={(option, value) => (option.id=== value.id)}
                                renderInput={(params) => (
                                <TextField  {...params} label={"CATEGORIE"} variant='outlined' margin='dense' size="small"/>)}/>
            </div>  
            <div className='search'>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField variant="outlined" id="input-with-sx" label="Recherche" value={search} size="small" margin='none'
                             onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} 
                             InputProps={{startAdornment: (<Search style={{ marginRight: '5px',zIndex:'1000' }} />),}}/>

              </Box>  
            </div>
            </div>   
            <div id="vntDT">
                <DataTable 
                  persistTableHead
                  columns={columns}
                  data={data}
                  noDataComponent={param.ErreurData}
                  paginationRowsPerPageOptions={[5,10,20,30,40,50,100]}
                  pagination
                  progressPending={loading}
                  progressComponent={<CircularProgress className={classes.circularProgress} />}
                  noHeader={true}
                  highlightOnHover
                  customStyles={customStyles}
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}            
                /></div>
            </DialogContent>
      </Dialog>
      </>
  );
}
