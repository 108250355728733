import React,{useState,useRef,useCallback} from 'react';
import {DialogContent,Typography,Paper,Dialog,IconButton,Button, DialogActions} from '@material-ui/core';
import Draggable from 'react-draggable';
import {paperPropsTicketDig,paperPropsTicketDig2,paperPropsTicketDigTall } from '../../../css/muiStyle';
import ReactToPrint from 'react-to-print';
import {Close,Print} from '@material-ui/icons';
import { withStyles} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useStyles } from '../../../css/muiStyle';
import LogoBq from '../../../img/logos/bq-b.jpg'
import ParamContext from '../../../Contexte';
import { useContext } from 'react';


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: 0,
      color: 'white',
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle  className={classes.root} {...other}>
        <Typography >{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Irh_dig_imprimer(props) {
    const classes = useStyles();
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const [open,setOpen]=useState(false)
    const [date,setDate]=useState('')
    const [error,setError]=useState(false)
    const [load,setLoad]=useState(false)
    //const service='_irh.php'
    const componentRef = useRef();


    const handleClickOpen = () => { 
      setOpen(true); setDate('');
      fetchNow()
  };
    const handleClose = () => { setOpen(false); };
    
  /*  const handleError=()=>{setError(true)}
    
    const handleLaoad=()=>{setLoad(true) }*/
    const fetchNow= useCallback(async() => {
      const response=await Gfunc.axiosGetAction('getNow.php',"")
      if(response.data.err&&response.data.err!=="-1"){
        //handleAlert(true,"error",response.data.err)
      }else{
          Gfunc.axiosResponse(response,()=>{
              if(response.data.jData){
                setDate(response.data.jData);
              }
          })
        } 
  },[Gfunc]);

  const handleError=()=>{
    setError(true)
  }

  const handleLaoad=()=>{
    setLoad(true)
  }

  function TicketIdoomAT(props){

    return(
      <div ref={componentRef} style={{fontFamily:"Arial"}} >
                    <div className="bon_digit" style={{display:'flex',gridGap:"5px",width:"5.2cm"}}>
                      <img src={(props.row.topLogo)?param.imgApp+props.row.topLogo:""} style={{height:"50px"}} onError={errors => {/*console.log(errors)*/}}/>
                     <div style={{justifyContent: "end", display:"flex", width:"90px"}}> <img id='logo_ticket' src={(error===false&&load===true)?param.logo+localStorage.getItem('logo'):LogoBq} onLoad={handleLaoad}  onError={handleError}/></div>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'55% 40%',fontSize:"12px",width:'5.2cm'}}>
                        <div style={{textAlign:'left'}}>
                            {date.day}
                        </div>
                        <div style={{textAlign:'right'}}>
                            {date.time}
                        </div>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'55% 40%',fontSize:"12px",width:"5.2cm"}}>
                        <div style={{textAlign:'left'}}>
                          {localStorage.getItem("mag")}
                        </div>
                        <div style={{textAlign:'right'}}>
                        </div>
                    </div>
                    <div id='p1'>
                      {props.row.digit}
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'40% 60%',fontSize:"11px",width:'5cm',marginBottom:"3px"}}>
                        <div style={{textAlign:'left'}}>
                           TTC:{props.row.prix} DA
                        </div>
                        <div style={{textAlign:'right'}}>
                           Expire: {props.row.expire}
                        </div>
                    </div>
                    <div style={{fontSize:'12px',marginBottom:'5px'}}>N° Série :{props.row.serial}</div>
                    <div style={{textAlign:'center',width:'5cm',marginBottom:"3px" ,height:"40px"}}>
                      {(props.row.digit&&(props.row.digit.toLowerCase().includes("4g") || props.row.digit.toLowerCase().includes("idoom")))?
                    <img src={param.imgApp+props.row.centerLogo} style={{height:props.row.digit.toLowerCase().includes("4g")?"40px":"30px"}}/>:null}</div>
                    <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}>CODE</div>
                    <div style={{textAlign:'center',fontSize:'18px',width:'5cm',fontWeight:'bold'}}>{props.row.code}</div>
                    <div id='p1'></div>
                    <div style={{fontSize:"12px"}}>لتعبئة حسابكم للانترنيت ،اتصل بالرقم : 1500</div>
                    <div style={{textAlign:'center',fontSize:'13px',width:'5.2cm'}}>اتصالات الجزائر غير مسؤولة عن التذكرة</div>
                    <div style={{textAlign:'center',fontSize:'13px',width:'5.2cm',marginBottom:"30px"}}>الغير مطبوعة امامكم</div>
      </div>
    )

  }
  

  //ticketg de idoom et idoom 4g 
  function TicketStandard(props){
    return (
      <div ref={componentRef}  id={(localStorage.getItem('tiketWidth')==="1")?"digCont1":"digCont"}>
        <div id={(localStorage.getItem('tiketWidth')==="1")?'ticket-dig1':'ticket-dig'}>
          <div>
              <p id='entet-tick-dig'>{localStorage.getItem('entet_tiket_1')}</p>
              <p id='entet-tick-dig'>{localStorage.getItem('entet_tiket_2')}</p>
          </div>
          <img src={(error===false&&load===true)?param.logo+localStorage.getItem('logo'):LogoBq} id='logo_ticket' alt='logo' onLoad={handleLaoad}  onError={handleError}/>
        </div>
        <hr size="1" color='black'/>
         <div id={(localStorage.getItem('tiketWidth')==="1")?'digit-inf1':"digit-inf"}>
          <div id='row1'>{'le '+date.day+' à '+date.time}</div>
           <Typography variant="h5" component="div" id='digit-name'>{props.row.digit} </Typography>
           <div id='code'>{props.row.code}</div>
           <div id='row'><b>N° serie : </b>{props.row.serial}</div>
           <div style={{textAlign:'left'}}>
                           TTC:{props.prx} DA
                        </div>
  
         </div>
        <hr size="1" color='black'/>
        <p id='footer-digit'><b>Merci pour votre fidélité</b></p>
        <p id={(localStorage.getItem('tiketWidth')==="1")?"footer-digit2":"footer-digit1"}>{localStorage.getItem('entet_tiket_2')}</p>
        </div>
    )
  }

  //Ticket Ooredoo
  function TicketOoredoo(props){
    return (
      <div ref={componentRef} style={{fontFamily:"Arial"}} >
          <div style={{width:'5.2cm',textAlign:'center',marginBlock:'6px'}}>{<img src={(error===false&&load===true)?param.logo+localStorage.getItem('logo'):LogoBq} style={{height:"40px"}} onLoad={handleLaoad}  onError={handleError}/>}</div>
          <div style={{display:'grid',gridTemplateColumns:'55% 40%',fontSize:"12px",width:'5.2cm'}}>
              <div style={{textAlign:'left'}}>{date.day}</div>
              <div style={{textAlign:'right'}}>{date.time} </div>
          </div>
          <div style={{display:'grid',gridTemplateColumns:'55% 40%',fontSize:"12px",width:"5.2cm"}}>
              <div style={{textAlign:'start'}}>{localStorage.getItem("etiquette")} </div>
          </div>
          <div id='p1'>{props.row.digit}</div>
          <div style={{display:'grid',gridTemplateColumns:'48% 48%',gridGap:'5px',fontSize:"11px",width:'5cm'}}>
              <div style={{textAlign:"start"}}>
                  <p>N° Série :</p>
                  <p>Expire: </p>
                  <p>Montant TTC:</p>
              </div>
              <div style={{textAlign:"end"}}>
                  <p>{(props.row.serial)?props.row.serial:"--"}</p>
                  <p> {props.row.expire}</p>
                  <p>{props.row.prix} DA </p>
              </div>
          </div>
          <div style={{textAlign:'center',width:'5cm',marginBottom:"3px" ,height:"40px"}}>
              <img src={param.imgApp+"logo_ooredoo.png"} style={{height:'100%'}} /></div>
          <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}>CODE</div>
          <div style={{textAlign:'center',fontSize:'18px',width:'5cm',fontWeight:'bold'}}>{props.row.code}</div>
          <div id='p1'></div>
          <div style={{textAlign:'center',fontSize:'11px',width:'5.2cm'}}>Composer *222* Code ci-dessus # OK</div>
          <div style={{fontSize:"11px",textAlign:"center",fontWeight:'normal',marginTop:'5px',width:"5.2cm"}}>Ooredoo n'est pas responsable des  </div>
          <div style={{fontSize:"11px",textAlign:"center",marginBottom:"50px",width:"5.2cm"}}>tickets non imprimes devant vous</div>
     </div>
  )
  }

function TicketDjezzy(props){
  return (
    <div ref={componentRef} style={{width:'5.2cm',fontFamily:'Arial'}} >
    <div style={{width:'100%',marginTop:'10px',display:'grid',gridTemplateColumns:'50% 50%'}}>
      <div style={{textAlign:'start'}}><img src={param.imgApp+"logo_djezzy.png"} style={{height:"40px"}}/></div>
      <div style={{textAlign:'end'}}>{<img src={(error===false&&load===true)?param.logo+localStorage.getItem('logo'):LogoBq} style={{height:"40px"}} onLoad={handleLaoad}  onError={handleError}/>}</div>    </div>
        <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}></div>
        <div style={{display:'block',textAlign:'start',fontSize:'11px'}}>
            <p style={{marginBottom:'3px'}}>Magasin : <b>{localStorage.getItem("etiquette")}</b></p>
            <p style={{marginBottom:'3px'}}>Date : <b>{date.day+' '+date.time}</b></p>
        </div>
        <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}></div>
        <div style={{display:'grid',gridTemplateColumns:'48% 48%',fontSize:'11px'}}>
            <div >
                <p>N° Série :</p>
                <p>Expire le : </p>
                <p>Montant TTC :</p>
            </div>
            <div >
                <p><b>{(props.row.serial)?props.row.serial:'--'}</b></p>
                <p><b>{(props.row.expire)?props.row.expire:'--'}</b> </p>
                <p><b>{(props.row.prix)?props.row.prix:0} DA</b></p>
            </div>
        </div> 
        <p style={{fontSize:'13px',margin:'5px 0',textAlign:'center'}}><b>Recharge {props.row.digit} DA</b></p>
        <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}>
          <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}>CODE RECHARGE</div>
              <div style={{textAlign:'center',fontSize:'18px',width:'5cm',fontWeight:'bold'}}>{props.row.code}</div>
        <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}></div>
        <div style={{fontSize:"12px",textAlign:"center",fontWeight:'bold'}}>Composez</div>
        <div style={{fontSize:"12px",textAlign:"center",fontWeight:'bold'}}>*700* les 14 chiffres #</div>
        <div style={{fontSize:"11px",textAlign:"center",marginTop:'5px'}}>Djezzy n'est pas responsable des  </div>
        <div style={{fontSize:"11px",textAlign:"center",marginBottom:"50px"}}>tickets non imprimes devant vous</div>
   </div>
   </div>
  );
}

function TicketMobilis(props){
  return (
    <div ref={componentRef} style={{fontFamily:"Arial"}} >
        <div style={{width:'5.2cm',textAlign:'center',marginBottom:'6px'}}>
            <img src={(error===false&&load===true)?param.logo+localStorage.getItem('logo'):LogoBq} style={{height:"40px"}} onLoad={handleLaoad}  onError={handleError}/></div>
        <div style={{display:'grid',gridTemplateColumns:'55% 40%',fontSize:"12px",width:'5.2cm'}}>
            <div style={{textAlign:'left'}}>{date.day}</div>
            <div style={{textAlign:'right'}}>{date.time} </div>
        </div>
        <div style={{display:'grid',gridTemplateColumns:'55% 40%',fontSize:"12px",width:"5.2cm"}}>
            <div style={{textAlign:'start'}}>{localStorage.getItem("etiquette")}</div>
        </div>
        <div id='p1'>{props.row.digit}</div>
        <div style={{display:'grid',gridTemplateColumns:'48% 48%',gridGap:'5px',fontSize:"11px",width:'5cm',marginBottom:"3px"}}>
            <div style={{textAlign:"start"}}>
                <p>N° Série :</p>
                <p>Expire: </p>
                <p>Montant TTC:</p>
            </div>
            <div style={{textAlign:"end"}}>
                <p>{(props.row.serial)?props.row.serial:"--"}</p>
                <p> {props.row.expire}</p>
                <p>{props.row.prix} DA </p>
            </div>
        </div>
        <div style={{textAlign:'center',width:'5cm',marginBottom:"3px"}}>
            <img src={param.imgApp+"logo_mobilis.png"} style={{height:'40px'}}/></div>
        <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}>CODE</div>
        <div style={{textAlign:'center',fontSize:'18px',width:'5cm',fontWeight:'bold'}}>{props.row.code}</div>
        <div id='p1'></div>
        <div style={{textAlign:'center',fontSize:'11px',width:'5.2cm',marginBottom:"50px"}}>Composer *111* Code ci-dessus # OK</div>
   </div>
);
}


  return (
    <div>
      <div id="imprDig" onClick={handleClickOpen}><Print className={classes.blueIcon} /></div>
      <Dialog 
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={ ((props.row.digit&&(!props.row.digit.toLowerCase().includes("4g") 
            && !props.row.digit.toLowerCase().includes("idoom")))
            ||!props.row.digit)?(localStorage.getItem('tiketWidth')==="1")?paperPropsTicketDig:paperPropsTicketDigTall:paperPropsTicketDig2}
       >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
          <div style={{display:"flex",gridGap:"10px",alignItems:"center"}}>
            <Print style={{width:'20px',height:'20px'}}/>
            <label>Ticket</label> 
          </div>
        </DialogTitle>  
        <DialogContent  > 
        {((props.row.digit&&(props.row.digit.toLowerCase().includes("4g") ||props.row.digit.toLowerCase().includes("idoom"))))?
          <TicketIdoomAT row={props.row} prx={props.prx}/>
        :(props.row.digit&&props.row.digit.toLowerCase().includes("mobilis"))?
          <TicketMobilis row={props.row} prx={props.prx}/>
        :(props.row.digit&&props.row.digit.toLowerCase().includes("ooredoo"))?
            <TicketOoredoo  row={props.row} prx={props.prx}/>
        :(props.row.digit&&props.row.digit.toLowerCase().includes("djezzy"))?
            <TicketDjezzy  row={props.row} prx={props.prx}/>
        : <TicketStandard logo={props.logo} row={props.row} prx={props.prx}/>        
        }
        </DialogContent>
        <DialogActions>
            <ReactToPrint
                 trigger={() => <Button variant="contained" color="primary"  startIcon={<Print />}>Imprimer</Button>}
                 content={() => componentRef.current} 
              />
        </DialogActions>
      </Dialog>
      </div>
  );
}
