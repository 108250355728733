import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,MenuItem,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import {SwapHoriz,SwapHorizontalCircle, Settings, AttachMoney, Reply, DoneAll,Close} from '@material-ui/icons';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import  {useStyles,paperPropsSmallTall} from '../../../css/muiStyle';
import  '../../../css/retour.css';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import {AlertCompo} from "../../../js/components"
import Icon from '@mdi/react';
import { mdiCashRefund,mdiSwapVerticalCircle } from '@mdi/js';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { Construction } from '@mui/icons-material';

 
const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ret_man_act(props) {
    const Gfunc=require('../../../Gfunc')
    const [agent,setAgent]=useState('');
    const [frns,setFrns]=useState('');
    const [stk,setStk]=useState('');
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [ids,setIds]=useState([]);
    const [id,setId]=useState('');
    const classes = useStyles();
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    
    const handleClickOpen = async() => { 
      //setOpen(true);
      if(props.rows.length>0||props.type==='rest'||props.type==='swap'){ 
      const ro=handleRows();
      if(!ro) return false
      var opf=true
      var ops=true
      var opu=true
      if(props.type==="swaps"||props.type==="remb"||props.type==="repas"){
          const resp=await props.handleOpenFrns();
          if((resp.data!==undefined&&resp.data.err!==undefined))
          opf=false
      }
      if(props.type==="swaps"||props.type==="swap"){
        const resps=await props.handleOpenStock();
        if(resps.data!==undefined&&resps.data.err!==undefined) ops=false
        if(props.stockL.length>0) setStk(props.stockL[0].id); else setStk('') 
      }
      const respu=await props.handleOpenUser();
      if(respu.data!==undefined&&respu.data.err!==undefined) opu=false
      if(!(opf&&ops&&opu)) {props.handleClearRows(); return false}
      setOpen(opf&&ops&&opu)
      
      setAgent(localStorage.getItem("uid"))
      setFrns('');
      
      setOpenA(false);
      setMessageA('');
      setSeverity('info');
      setSelectedDate(new Date());
    }
    else props.handleAlert(true,"warning","Vous devez sélectionner au moins un élément!")
    };
    const handleClose = () => { setOpen(false); };
  
    const handleDateChange = (date) => {
        setSelectedDate(date);
      };    

    const handleRows=()=>{
        var dat=[]
        if(props.rows.length){
          props.rows.map(row=>{
              if((props.type==='swaps'||props.type==='remb') ){
                if(row.statusId===1||row.statusId===3) {
                    dat.push(parseInt(row.DT_RowId.substr(1)))
                  }
              }
              if(props.type==='repas'){
                if(row.statusId===1) 
                    dat.push(parseInt(row.DT_RowId.substr(1)))
              }         
          })
          if(dat.length>0)
          setIds(dat)
          else{
            setOpen(false)
            props.handleAlert(true,"error","Vous ne pouvez pas effectuer cette action sur les retours sélectionnés.")
            return false
          }  
          //setIds(props.rows)
        }else{
            setId(props.rows.DT_RowId)
        }
        return true
    }

     //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
    //handle subit form
    const handleSubmit= async(e) =>{
        var dat={}
        if(props.type==="swaps")
        dat={ids:ids,date:Gfunc.formatDate(new Date(selectedDate)),frns:frns,agent:agent,stock:stk}
        if(props.type==="repas"||props.type==="remb")
        dat={ids:ids,date:Gfunc.formatDate(new Date(selectedDate)),frns:frns,agent:agent}
        if(props.type==="swap")
        dat={retour:parseInt(id.substr(1)),date:Gfunc.formatDate(new Date(selectedDate)),agent:agent,stock:stk}
        if(props.type==="rest")
        dat={retour:parseInt(id.substr(1)),date:Gfunc.formatDate(new Date(selectedDate)),agent:agent}
        const respon=await Gfunc.axiosPostAction(props.service,props.action,dat)
        if(respon.data.err){
          props.handleAlert(true,"error",respon.data.err)
          setOpen(false);
        }else Gfunc.axiosResponse(respon,()=>{
                if(respon.data.jData){
                  var mess=''
                  if(props.type==="swaps"||props.type==="repas"||props.type==="remb")
                    mess="Les produits sélectionnés ont été "+((props.type==="swaps")?'swapé':(props.type==="repas")?'envoyé':(props.type==="remb")?'rembourssé':null)+" avec succés"
                  else mess="Le produit sélectionné a été "+((props.type==="swap")?'swapé':(props.type==="rest")?'restitué':null)+" avec succés"
                    props.callback();
                    props.handleAlert(true,"success",mess)
                    props.handleVider([])
                    setOpen(false);
                }
                })
      props.handleClearRows()
    }
  return (
    <div>
      {
       (props.type==="swaps"||props.type==="repas"||props.type==="remb")?
       <Button variant="contained" color="secondary" onClick={handleClickOpen} startIcon={(props.type==="swaps")?<SwapHoriz/>:(props.type==="repas")?<Settings/>:(props.type==="remb")?<AttachMoney/>:null} className={classes.topTabBtn}>{props.message}</Button>
       :(props.type==="swap")?<SwapHorizontalCircle onClick={handleClickOpen} className={classes.blueIcon} fontSize="small"/>:<Reply className={classes.greenIcon} onClick={handleClickOpen} fontSize="small"/>
      }
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
      >
        <DialogTitle  className={classes.topDivTitle} onClose={handleClose}>
          <div style={{display:"flex",gridGap:"10px",alignItems:"center"}}>
            {(props.title==='Swap fournisseur')?
              <Icon path={mdiSwapVerticalCircle} size={1} />
              :(props.title==='Réparation fournisseur')?
               <Construction style={{height:'20px',width:'20px'}} />
              : <Icon path={mdiCashRefund} size={1} />
            }
            <label> {props.title}</label> 
          </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
        <DialogContent >
            <ValidatorForm className='manForm'id="form" ref={useRef(null)} onSubmit={handleSubmit} >
                         <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                size="small"
                                className='smallText' margin="dense" inputVariant='outlined'
                                maxDate={new Date()}  
                                invalidDateMessage="Le format de la date est incorrect"   
                                maxDateMessage="La date ne doit pas être supérieure à la date maximale"                              
                                variant="inline"
                                format="dd/MM/yyyy"
                                name="iDate"
                                id="date-picker-inline"
                                label={(props.type==="swaps")?"Date de réception":(props.type==="repas")?'Date de remise':(props.type==="remb")?'Date de remboursement':(props.type==='swap')?'Date de swap':'Date'}
                                value={selectedDate}
                                onChange={handleDateChange}
                                required
                              />
                         </MuiPickersUtilsProvider>  
                        { (props.type==="swaps"||props.type==="repas"||props.type==="remb")?
                        <TextValidator size="small"  select label='Fournisseur' value={frns}  className='smallText' margin="dense" variant='outlined'
                            onChange={e =>setFrns(e.target.value)} 
                            validators={['required']} 
                            errorMessages={["Veuillez sélectionner un fournisseur"]} 
                            autoFocus
                            >
                                {props.frnsL.map((user,index)=>{return (<MenuItem key={index} value={user.id}>{user.name}</MenuItem>)})}
                         </TextValidator>:null}
                        <TextValidator size="small" id='iAgent'  onClick={(e) => e.stopPropagation()} select  value={agent}  className='smallText' margin="dense" variant='outlined'
                            label={(props.type==="swap"||props.type==="repas")?'Remis par':(props.type==="swaps")?'Réceptionné par':'Agent'} 
                            onChange={e =>setAgent(e.target.value)} 
                            validators={['required']} 
                            errorMessages={["Veuillez sélectionner un agent"]} 
                            >
                                <MenuItem value={agent}>{localStorage.getItem('name')}</MenuItem>
                                {props.userL.map((user,index)=>{return (<MenuItem key={index} value={user.id}>{user.name}</MenuItem>)})}
                         </TextValidator>
                         { (props.type==="swaps"||props.type==="swap")?
                        <TextValidator size="small"  select value={stk}  className='smallText' margin="dense" variant='outlined'
                            label={(props.type==="swaps")?"Vers le stock":(props.type==="swap")?"Depuis le stock":'Stock'}
                            onClick={(e) => e.stopPropagation()} 
                            onChange={e =>setStk(e.target.value)} 
                            validators={['required']} 
                            errorMessages={["Veuillez sélectionner un stock"]}
                            autoFocus={props.type==="swap"} >
                            {props.stockL.map((stock,index)=>{return (<MenuItem key={index} value={stock.id}>{stock.nom}</MenuItem>)})}
                         </TextValidator>:null}
                </ValidatorForm>
           </DialogContent>
           <DialogActions>
              <Button variant="contained" form="form" type='submit' color="primary" startIcon={<DoneAll />}>VALIDER</Button>
           </DialogActions>
      </Dialog>
      </div>
  );
}
