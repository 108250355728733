import React,{useState,useCallback, useMemo} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField, CircularProgress,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {Add,Close} from '@material-ui/icons';
import { useStyles,paperPropsBig} from '../../../css/muiStyle';
import { AlertCompo, BackdropCompo } from '../../../js/components';
import DataTable from "react-data-table-component";
import { customStyles, numberColumn } from '../../../css/datatable-custom';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import '../../../css/vente.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiLocationExit } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//add new vente
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Vnt_cpt_addSearch(props) {

    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const [qte,setQte]=useState(1);
    const [check,setCheck]=useState('');
    const service='_vnt.php'
    const classes=useStyles();
    const [action, setAction] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [open, setOpen] =useState(false);
    const [able, setAble] =useState('');
    const [first, setFirst] =useState('');
  
   
    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

   //reste all fiels
   const reset=()=>{
    setQte(1)
    //setFirst(props.data.detail[0].pdetId)
   }

   const handleMtn=async(pdetId,qte)=>{
    if(qte>0){
    var mtn=0;
     if(pdetId){ const data={
           pdetId:pdetId,
           qte:parseInt(qte)
         }
         const response = await Gfunc.axiosPostAction("_vnt.php","getpdinfvt",data)
         if(response.data.err&&response.data.err!=="-1"){
           handleAlert(true,'error',response.data.err)
         }else
         Gfunc.axiosResponse(response,()=>{
          if(response.data.jData)
             mtn=response.data.jData.montant
         })}
         return mtn}
         const elem=document.getElementById("p"+able.pdetId)
         if(elem) elem.select();
         handleAlert(true,"error","La quantité doit être supérieur à zéro")
   }

    // ajouter un nouveau bon
    const handleSubmit= async(e) =>{
      if(!action){
        if(qte!==''&& qte>0){
          setAction(true)
          var m= await handleMtn(able.pdetId,parseInt(qte))
          const dat={
            pdetId:able.pdetId,
            tiketId:props.ticketId,
            qte:parseInt(qte),
            stockId:(able.stockId!=null)?parseInt(able.stockId):parseInt(localStorage.getItem("stockId")),
            mtn:m,
            retour:props.retour,
          }
            const respon=await Gfunc.axiosPostAction(service,"addvman",dat)   
            if(respon.data.err && respon.data.err!=='-1'){
              handleAlert(true,'error',respon.data.err);
            }else
            Gfunc.axiosResponse(respon,async()=>{
              if(respon.data.jData){
                let t=parseFloat(m)
                let q=parseInt(qte)
                if(props.retour){ t=-t
                  q=-q}
                props.handleAddVnt(respon,able.stockId,able.stock,t,q,able.pdetId,props.handleAlert,()=>{},()=>{})  
                props.handleAlert(true,'success',"La vente est ajouté");
                //const index=Gfunc.getIndex(props.data,able.pdetId,'pdetId')
                //props.data[index]=props.data[index].qte-parseInt(q)
                setOpen(false)
                props.Focus("iLook")
                reset()
              }
            })
            setAction(false)
        }else{
          const elem=document.getElementById("p"+able.pdetId)
          if(elem) elem.select();
          handleAlert(true,'error',"Veuillez saisir la quantité!")
        }
      }
      }


    const handleClickOpen = () => { 
      setOpen(true)
      if(props.data.length>0) setFirst(props.data[0].pdetId)
      setAble('')
      //document.getElementById(props.data.detail[0].pdetId).focus()
    };
  
    const handleClose = () => { 
      setOpen(false); //reset();
      props.Focus("iLook")
     };

    const HandleUpdateDefault=useCallback(async(row)=>{
      setCheck(row)      
    },[])

    const columns =useMemo(
        () => [    
          {
              name: "Produit",
              minWidth:'280px !important',
              selector: row=>row.prod,
              sortable: true,
          },
          {
              name: "Vente",
              minWidth:'100px !important',
              style:numberColumn,
              selector: row=>row.prixVente,
              sortable: true,
          },
          {
              name: "stock",
              minWidth:'100px !important',
              selector: row=>row.stock,
              sortable: true,
          },
          {
              name: "Qte.",
              minWidth:'100px !important',
              style:numberColumn,
              selector: row=>row.qte,
              sortable: true,
          },
          {
            name: "Choisir un stock",
            minWidth:'180px !important',
            cell: (row) => {
              return (
                <div className="smallElem"> 
                   <TextField id={"p"+row.pdetId} autoFocus={(open&&able!=='')?able.pdetId===row.pdetId:first===row.pdetId} className='qteT' margin='dense' variant='outlined' size="small" value={(able.pdetId===row.pdetId)?qte:''} onFocus={()=>{setAble(row)}} onChange={(e)=>{setQte(e.target.value)}} type='number' /> 
                </div>
               );
             },
          },
        ],[classes,HandleUpdateDefault,check,qte,able,first]);
   
  return (
    <div>
      <div hidden id="addSearchBtn" className={classes.smallBtn} variant="contained" color="secondary" onClick={handleClickOpen} 
           disabled={props.disabled}></div>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsBig}
      >
        <DialogTitle className={classes.topDivTitle}  onClose={handleClose} >
           <div className='DialogTitle'>
              <Icon path={mdiLocationExit} size={1} />
              <label>Sortie de stock</label>
            </div> 
        </DialogTitle>
        <BackdropCompo className={classes.backdropDiag} open={action}/>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent >
            <ValidatorForm id="addSAuto"  onSubmit={handleSubmit} onError={errors => console.log(errors)} > 
            <div id="addS">  
              <DataTable 
                  persistTableHead
                  columns={columns}
                  title="Veuillez choisir le stock de sortie."
                  data={props.data}
                  noDataComponent={param.ErreurData}
                  progressComponent={<CircularProgress className={classes.circularProgress} />}
                  fixedHeaderScrollHeight
                  highlightOnHover
                  customStyles={customStyles}
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}            
              />
            </div>
              </ValidatorForm>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type='submit' color="primary" form="addSAuto" startIcon={<Add/>}>AJOUTER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
