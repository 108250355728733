import React ,{useRef,useState,useMemo} from "react";
import {Button,CircularProgress,Backdrop,FormControlLabel,Checkbox} from '@material-ui/core';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { DoneAll} from '@material-ui/icons';
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { useStyles } from '../../css/muiStyle';
import  '../../css/reporting.css'
import {customStyles}from '../../css/datatable-custom'
import {NoDataComponent,AlertCompo} from '../../js/components'
import DataTable  from "react-data-table-component";


export default function Rep_trn (props){
    const Gfunc=require('../../Gfunc')
    const param=props.param;
    const classes = useStyles();
    const [loading,setLoading]=useState(false)
    const [data,setData]=useState([])
    const [qte,setQte]=useState(false)
    const [selectDateD,SetSelectDateD]=useState(Gfunc.DateDeb(new Date()))
    const [selectDateF,SetSelectDateF]=useState(new Date())
    const [err,SetErr]=useState(false)
    const [open, setOpen] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const message='Aucune donnée trouvée'
    const service='_rep.php' 

     //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

    const columns =useMemo(
        () => [ 
          {
              name: "PRODUIT",
              selector:row=>row.produit,
              sortable: true,
              sortField:'produit',
          },
          {
              name: "QTE.",
              selector:row=>row.qte,
              sortable: true,
              sortField:'qte',
          },
          {
            name: "NOMBRE TRANSACTIONS",
            selector:row=> row.count,
            sortable: true,
            sortField:'count',
          },
           
        ],[classes]);
     


//valider le formulaire
const handleSubmit= async(val,att) =>{
  const va=(att!=='change')?qte:val
  setLoading(true)
  SetErr(false)
  setData([])
  const response=await Gfunc.axiosPostAction(service,"gettoptr",{date1:Gfunc.formatDate(new Date(selectDateD)),
                                              date2:Gfunc.formatDate(new Date(selectDateF)),withQte:va})
  setLoading(false)
  if(response.data.err&&response.data.err!=='-1'){
     handleAlert(1,'error',response.data.err)
  }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
       if(response.data.jData.length!==0){
           setData(response.data.jData)
       }else{
           SetErr(true)
       }
       }
     })
    
  }
}

//validation de formulaire le choix du quantite
const handleChange=(val)=>{
  handleSubmit(val,'change')
}

//valider formulaire avec touche d'entrer
const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="addBtn")) {
          alert("Vous devez remplir tous les champs!")
      }
} 

return (
  <div className='content'>
  <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
  <fieldset className="fieldsetTop" id="trans">
        <ValidatorForm noValidate id="formAdd" ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)}  >
            <div className="rowGrid" id="row-1">
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                   <KeyboardDatePicker disableToolbar value={selectDateD} variant="inline" onChange={date => SetSelectDateD(date)}
                               className='smallText' margin="dense" inputVariant="outlined"
                               minDate='10/10/2018' format="dd/MM/yyyy" 
                               invalidDateMessage="Le format de la date est incorrect" 
                               />
                               
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDateF} variant="inline" placeholder="10/10/2018" onChange={date => SetSelectDateF(date)}
                                minDate='10/10/2018' format="dd/MM/yyyy"  className='smallText' margin="dense" inputVariant="outlined"
                                invalidDateMessage="Le format de la date est incorrect" 
                               />
             </MuiPickersUtilsProvider>
             <FormControlLabel control={<Checkbox  checked={qte} color="primary"
                               onChange={e=>{setQte(e.target.checked);handleChange(e.target.checked)}} inputProps={{ 'aria-label': 'controlled' }}  />}
                               label="Avec quantité" /> 
              <Button className={classes.PrintBtn} margin='dense' variant="contained" tabIndex="3" type='submit' id="bouton" 
                      name="addBtn" color="primary" startIcon={<DoneAll/>}>VALIDER</Button>
            </div>        
    </ValidatorForm>
 </fieldset>
 <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
 </Backdrop>
 <br/>
   { (data.length>0)?
        <DataTable 
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              pagination
              paginationRowsPerPageOptions={[10,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              noHeader={true}
              highlightOnHover
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
        />:<></>}
        {(err===true)?<div><NoDataComponent message={message}/></div>:<></>}
</div>  
  );
}