import React,{ useState,useRef,Fragment} from "react";
import { Edit} from "@material-ui/icons";
import { Button,Paper,Dialog,DialogActions,DialogContent,Typography,IconButton } from "@material-ui/core";
import Draggable from 'react-draggable';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { paperPropsSmallF, useStyles } from '../css/muiStyle';
import  {AlertCompo} from '../js/components';
import { Password,Close } from "@mui/icons-material";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiAccountEdit } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function ChangePass(props) {
    const Gfunc=require('../Gfunc')
    const classes=useStyles();
    const [open, setOpen] =useState(false);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const [prvPass, setPrvPass] =useState("");
    const [newPass, setNewPass] =useState("");
    const [confPass, setConfPass] =useState("");
    const label1="Ancien mot de passe"
    const label2="Nouveau mot de passe"
    const label3="Confirmer le nouveau mot de passe"
  
    
    const handleClickOpen = () => { 
        setOpen(true); 
        setPrvPass('');
        setNewPass('');
        setConfPass('');
      };
      const handleClose = () => { setOpen(false); };

     //handle alerts properties
     const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    const handleSubmit= async(e) =>{
      if (newPass===confPass){
        const response = await Gfunc.axiosPostAction('pass.php','',{opass:prvPass,npass:newPass});
        if(response.data.err&&response.data.err!=="-1"){
          handleAlert(true,'error',response.data.err)
        }else
          Gfunc.axiosResponse(response,()=>{
            if(response.data.jData){
              handleAlert(true,'success',"Votre mot de passe a été modifié avec succès.")
              setTimeout(() => {
                setOpen(false)
              }, "1000")
            }
          })
      }
      else {
          handleAlert(true,'warn',"Veuillez ressaisir votre mot de passe correctement !")
           setConfPass('')
      }
    
    }
    
  return (
    <Fragment>
      <span id="userName" onClick={()=>handleClickOpen()}>{props.getInfo().name}</span>
      <Dialog
            open={open}
            id='changePass'
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallF}
       >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
           <div className='DialogTitle'>
              <Icon path={mdiAccountEdit} size={1} />
              <label> Modifier le mot de passe</label>
            </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} />
        <DialogContent>
           <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} /*onError={errors => console.log(errors)} */>
              <div id='div-pwd'>
                <TextValidator className='smallText' variant="outlined" size="small"  label={label1} value={prvPass} 
                                placeholder={label1}
                                onChange={e =>setPrvPass(e.target.value)} 
                                validators={['required']} 
                                errorMessages={["Veuillez saisir votre mot de passe !"]} 
                                InputProps={{
                                  startAdornment: (
                                    <Password style={{width:"18px",height:'18px'}} /> 
                                  ),
                                }}                                
                                type="password"
                                autoFocus
                                        />
                                        <br/>
                <TextValidator  className='smallText' variant="outlined" size="small"  label={label2} value={newPass}  
                                onChange={e =>setNewPass(e.target.value)} 
                                placeholder={label2}
                                validators={['required']} 
                                errorMessages={["Veuillez saisir un nouveau mot de passe !"]} 
                                InputProps={{
                                  startAdornment: (
                                    <Password style={{width:"18px",height:'18px'}} /> 
                                  ),
                                }}  
                                type="password"
                                        />
                                        <br/>
                <TextValidator   className='smallText' variant="outlined" size="small"  label={label3} value={confPass}  
                                onChange={e =>setConfPass(e.target.value)} 
                                placeholder={label3}
                                validators={['required']} 
                                errorMessages={["Veuillez ressaisir le nouveau mot de passe !"]} 
                                InputProps={{
                                  startAdornment: (
                                    <Password style={{width:"18px",height:'18px'}} /> 
                                  ),
                                }}  
                                type="password"
                                        />
            </div>
            </ValidatorForm>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" type='submit' color="primary" startIcon={<Edit/>}>Modifier</Button>
        </DialogActions>
      </Dialog>
      </Fragment>
  );
}
