import React,{useState} from 'react';
import {DialogContent,Paper,Dialog,Fab,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import { useStyles,paperProps } from '../../../css/muiStyle';
import { Tags } from '../../../js/components';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import {ReactComponent as Stock} from '../../../img/icons/stockW.svg'


const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//add new bon
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Vnt_cpt_stkCmpt(props) {
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] =useState(false);
    const [data, setData] =useState([]);
    const addTitle="Stock comptoir"
    const classes=useStyles();
    const service="_prd.php"

    const fetch = async() => { 
        const response = await Gfunc.axiosGetAction(service,"getstkcmp")
        if(response.data.err){
          props.handleAlert(true,'error',response.data.err)
          setOpen(false)
        }else
        Gfunc.axiosResponse(response,()=>{
            setData(response.data.jData)
        })
    };

    const handleClickOpen = () => { 
      fetch();
      setOpen(true); 
    };


    const handleClose = () => { setOpen(false); setData([])};
 
  return (
    <>
    <Fab className={classes.sideBtn} variant="extended" size="small" color="secondary" onClick={handleClickOpen} > Stocks compt.</Fab>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
           <div className='DialogTitle'>
              <Stock style={{height:'20px',width:'20px'}}/>
              <label>{addTitle}</label>
            </div>
        </DialogTitle>
        <DialogContent >
                <div className='flex-wrapper'>
                    {data.map((elem,index)=>{
                        return(
                            <Tags key={index} name={elem.nom} value={Intl.NumberFormat().format(elem.stock)}/>
                        )
                    })}
               </div>
        </DialogContent>

      </Dialog>
      </>
  );
}
