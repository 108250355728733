import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import TextField from '@material-ui/core/TextField';
import {Checkbox, CircularProgress, FormControlLabel} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import DraggableDialog from './modals/mon_cai_trt'
import {customStyles}from '../../css/datatable-custom'
import { useStyles} from '../../css/muiStyle';
import {AlertCompo,NoDataComponent,NotAllowedComponent} from "../../js/components"
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from "@mui/system";
import './../../css/monitoring.css'
import { useContext } from "react";
import ParamContext from "../../Contexte";


export default function Mon_chg(props){
  const classes=useStyles()
  const param=useContext(ParamContext)
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [type, setType] = useState([]);
  const [usr, setUsr] = useState([]);
  const [selectUsr, setSelectUsr] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [,setDel] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [open, setOpen] =useState(false);
  const [called, setCalled] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [records, setRecords] =useState('');
  const [severity, setSeverity] =useState("info");
  const[noData,setNoData]=useState(false); 
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[checked,setChecked]=useState(1);
  const[doSearch,setDoSearch]=useState(false);
  const service='_cai.php'

  const conditionalRowStyles=[
    { 
      when: row => row.mtn<0,
       style: {
             color: "red",
       }
     },
    { 
      when: row => row.mtn>=0,
       style: {
             color: 'green',
       }
     }
  ]

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  const fetch= useCallback(async(page,usr,servic = service, size = perPage,s,chckd = checked) => {
    setData([])
    setCalled(true)
    setLoading(true);
    var user=(usr!==undefined&&usr!=="")?'&usr='+usr:""
    const response=await librery.axiosGet2(page+user+"&cpt="+chckd+'&search='+((s!==undefined)?s:''),service,'gethisto', size)
    setLoading(false)
    if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
    }else
       Gfunc.axiosResponse(response,()=>{
          setRecords(response.data.recordsFiltered)
          if(response.data.data!==undefined){
                setData(response.data.data);
                setTotalRows(response.data.recordsFiltered);
                setDoSearch(true)
                setNoData(true)
          }else {setDoSearch(false);setData([])}
    })
  },[Gfunc,param.urlService,perPage])

  useEffect(() => {
    if(!called){
      fetch(1);
    }
  }, [fetch,called]);
  

 //fetch fournisseurs
 const fetchType= useCallback(async(serv="_chg.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    Gfunc.axiosResponse(response,()=>{
        setType(response.data.jData);    
    })
  },[Gfunc])

   //fetch utilisateurs handler
 const handleOpenType = useCallback(async () => {
    if(type.length===0) fetchType()
    },[type,fetchType])

  //delete itam
const handleSetData=useCallback((val)=>{
    setDel(val)
  },[])


 
  const columns =useMemo(
    () => [ 
      {
        name: "Date",
        selector: row=>row.date,
        sortable: true,
        width:'150px !important',
        sortField:'date',
      },  
      {
        name: "Action",
        selector: row=>row.action,
        sortable: true,
        minWidth:'150px !important',
        sortField:'action',
      },  
      {
        name: "Donneur",
        selector:row=>row.donneur,
        minWidth:'180px !important',
        sortable: true,
        sortField:"donneur",
      },
      {
        name: "Montant",
        selector:row=>row.mtn,
        sortable: true,
        sortField:"mtn", 
        minWidth:'100px !important',
        cell: (row) => {
            return (
              <div >
                {new Intl.NumberFormat().format(row.mtn)} 
              </div>
            )
          }
      },
      {
        name: "Receveur",
        selector:row=>row.receveur,
        sortable: true,
        sortField:"receveur",
        width:'130px !important'
      },
      {
        name: "Note",
        selector:row=>row.note,
        sortable: true,
        sortField:"note",
        minWidth:'250px !important'
      },   
  ],[handleOpenType,classes.redIcon]);
  
 
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,'gethisto'+"&cpt="+checked)
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      })  
    }
  }

  const handleSearch=async( size = perPage)=>{
    var value=''
    setData([])
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    if(selectUsr!==''){
     var  objIndex =  usr.findIndex((obj => obj.name ===selectUsr )); 
     if(usr[objIndex].id!=='empty'){
        value='&usr='+usr[objIndex].id
     }
    }
    const response=await librery.axiosSearch(service,recherche,1,size,'gethisto'+value+"&cpt="+checked)
    setLoading(false);
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
   }
    return recherche
  }

  function handlePageChange (page) {
    var value=''
    if(selectUsr!==''){
     const objIndex =  usr.findIndex((obj => obj.name ===selectUsr )); 
     if(usr[objIndex].id!=='empty'){
      value=usr[objIndex].id
     }
    }
    fetch(page,value,service,perPage,search,checked);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    var value=''
    if(selectUsr!==''){
     const objIndex =  usr.findIndex((obj => obj.name ===selectUsr )); 
     if(usr[objIndex].id!=='empty'){
      value=usr[objIndex].id
     }
    }
    fetch(page,value,service,newPerPage,search,checked);
    setPerPage(newPerPage);
  };

  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }

  function liveSearch2() {
    handleSearch();
  }

  //handle get Year
  const getUsr=useCallback(async()=>{
        const response= await Gfunc.axiosGetAction('_usr.php',"gets");
        if(response.data.err&&response.data.err!=='-1'){
         handleAlert(true,"error",response.data.err);
        }else
        Gfunc.axiosResponse(response,()=>{
           if(response.data.jData){
               response.data.jData.unshift({id:'empty',name:"Tous"})
               setUsr(response.data.jData)
           }
        })
 },[])
  
   //fetch usr handler
  const handleOpenUsr= useCallback(async () => {
    if(usr.length===0) getUsr()
    },[usr,getUsr])

   //check Inclure
  const handleChangeInclure= useCallback(async () => {
    var value=''
    if(selectUsr!==''){
     const objIndex =  usr.findIndex((obj => obj.name ===selectUsr )); 
     if(usr[objIndex].id!=='empty'){
      value=usr[objIndex].id
     }
    }
    setChecked((!checked)?1:0)
    fetch(currentPage,value,service,perPage,search,(!checked)?1:0);
    },[checked,fetch,search,selectUsr])

 

  const onChangeUsr=(val)=>{
       const objIndex =  usr.findIndex((obj => obj.name ===val )); 
    fetch(1,(usr[objIndex].id!=='empty')?usr[objIndex].id:"",service,perPage,search,checked)
  }
 

  
  return (
    <div className="content">
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <div className='table-header'>
           <div className='button-flex-wrapper-chg' >
           <DraggableDialog handleAlert={handleAlert} callback={()=>fetch(1)} />
            
            <div className="smallAutoFiltre" id='selectUsr'>
                <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{onChangeUsr(newValue);setSelectUsr(newValue)}}
                    value={selectUsr}
                    onFocus={handleOpenUsr}
                    options={usr.map((option) => option.name)}
                    className={classes.dataInput}
                    inputlabelprops={{className:classes.dataLabel}} 
                    renderInput={(params) =>  
                            <TextField margin='dense' 
                                    // error={errorP}
                                    size='small'{...params} variant="outlined"  label="FILTRER LES UTILISATEURS" 
                                    SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                                />
                    }
                 />     
            </div>
            <FormControlLabel labelPlacement="start" style={{justifyContent:"left",margin:0}} control={
              <Checkbox checked={checked} onChange={handleChangeInclure} name="checked" color="primary"/> } 
                label={<b>Inclure les encaissemets du comptoir</b>}/>
         </div>
          {(doSearch)?
            <div className='search' >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined" label="Recherche"  size="small" margin='none'
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer" }} />),}}
                               value={search}  onKeyUp={e => {liveSearch(e)}} onChange={e => {setSearch(e.target.value);}} />
              </Box>
            </div>:null}
        </div>
        {(data.length!==0||doSearch)?
          <DataTable 
              //key={Math.floor(Math.random() * (1000 - 1 + 1)) + 1}
              persistTableHead
              columns={columns}
              data={data}
              noHeader={true}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationPerPage={15}
              paginationRowsPerPageOptions={[15,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
           />
          :(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
        
    </div>    
  );
}
