import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import TextField from '@material-ui/core/TextField';
import {CircularProgress, Tooltip} from '@material-ui/core';
import {Search,Delete,FindReplace} from '@material-ui/icons';
import DraggableDialog from './modals/mon_lst_abs'
import DraggableDialogCat from './modals/mon_lst_vid_cat'
import DraggableDialogPrint from './modals/mon_lst_imprimer'
import {conditionalRowStyles,customStyles,numberColumn}from '../../css/datatable-custom'
import { useStyles } from '../../css/muiStyle';
import {AlertCompo,InputNbrEditable,NoDataComponent,NotAllowedComponent} from "../../js/components"
import { Box } from "@mui/system";
import './../../css/monitoring.css'
import Autocomplete from '@mui/material/Autocomplete';

export default function Mon_lst(props){
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [cat, setCat] = useState('');
  const [catL, setCatL] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [,setDel] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [open, setOpen] =useState(false);
  const [called, setCalled] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [records, setRecords] =useState('');
  const [severity, setSeverity] =useState("info");
  const[noData,setNoData]=useState(false); 
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[doSearch,setDoSearch]=useState(false);
  const[noupd,setNoupd]=useState(false);

  const service='_lst.php'
  const classes=useStyles()
  
   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  const fetch= useCallback(async(page,filtre,servic = service, size = perPage,value) => {
    setCalled(true)
    setLoading(true);
    const response=await librery.axiosGet(page+'&search='+((value!==undefined)?value:'')+'&cat='+((filtre!=='empty'&&filtre!==undefined)?filtre:''),servic,size)
      if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else
       Gfunc.axiosResponse(response,()=>{
          setRecords(response.data.recordsFiltered)
          if(response.data.data!==undefined){
          setData(response.data.data);
          setTotalRows(response.data.recordsFiltered);
          setDoSearch(true)
          setNoData(true)
        }else {setDoSearch(false);setData([])}
      })
    setLoading(false)
  },[Gfunc,param.urlService,perPage])

  useEffect(() => {
    if(!called){
      fetch(1);
    }
  }, [fetch,called]);
  
  //fetch categorie
  const fetchCat= useCallback(async(serv="_cat.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    Gfunc.axiosResponse(response,()=>{
      response.data.jData.unshift({id:'empty',nom:'Tous'})
      setCatL(response.data.jData);    
   })
  },[Gfunc])

  //fetch categorie handler
  const handleOpenCat = useCallback(async () => {
      if(catL.length===0)
      fetchCat()
      },[catL,fetchCat])

  //delete itam
const handleSetData=useCallback((val)=>{
    setDel(val)
  },[])

const handleDeleteItem=useCallback(async(row)=>{
          const response=await Gfunc.axiosPostAction(service ,"del",{listId:row.DT_RowId.substr(1)})
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,"error",response.data.err)
          }else
           Gfunc.axiosResponse(response,()=>{
            if(response.data.jData===true){
                const objIndex =  data.findIndex((obj => obj.DT_RowId ===row.DT_RowId )); 
                data.splice(objIndex,1)
                setDel(row.DT_RowId)
                handleSetData('')
             }
            })
},[Gfunc,handleSetData,service,data])
  

  const columns =useMemo(
    () => [ 
      {
        name: "Produit",
        selector: row=>row.produit,
        sortable: true,
        width:'480px !important',
        sortField:'produit',
      },  
      {
        name: "Catégorie",
        selector: row=>row.categorie,
        sortable: true,
        width:'120px !important',
        sortField:'categorie',
      },  
      {
        name: "Qte.",
        selector:row=>row.qte,
        width:'100px !important',
        sortable: true,
        style:numberColumn,
        sortField:"qte",
        cell: (row) => {
            return (
                <InputNbrEditable
                    service={service} action='upd' min={1}
                    row={row} att="qte" value={row.qte} id='DT_RowId'
                    handleAlert={handleAlert}
                />
             );
           },
      },
      {
        name: "Tarif",
        selector:row=>row.tarif,
        width:'100px !important',
        sortable: true,
        style:numberColumn,
        sortField:"tarif",
        cell: (row) => {
            return (
            <InputNbrEditable
                    service={service} action='upd' min={1}
                    row={row} att="tarif" value={row.tarif} id='DT_RowId' idi={row.DT_RowId}
                    handleAlert={handleAlert}
           />
             );
           },
      },
      {
        name: "Entree",
        selector:row=>row.date,
        sortable: true,
        sortField:"date",
        width:'100px !important'
      },
      {
        name: "Agent",
        selector:row=>row.agent,
        sortable: true,
        sortField:"agent",
        minWidth:"100px !important"
      },
      {
        name: "Source",
        selector:row=>row.source,
        sortable: true,
        sortField:"source",
        minWidth:"100px !important"
      },
      {
        right:true,
        cell: (row) => {
          return (
            <Tooltip title="Supprimer">
              <Delete fontSize="small"  className={classes.redIcon} onClick={()=>{handleDeleteItem(row)}} />
            </Tooltip>
           );
        },   
      }   
  ],[handleDeleteItem,classes.redIcon]);
  
 
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,'get')
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      })  
    }
  }

  const handleSearch=async( size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch1(service,cat,recherche,1,size,'get')
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
   }
    return recherche
  }

  function handlePageChange (page) {
    fetch(page,cat,service,perPage,search);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,cat,service,newPerPage,search);
    setPerPage(newPerPage);
  };
  
  //Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }

  function liveSearch2() {
    handleSearch();
  }
  
 /* const HandleVide=async(val)=>{
     const response=await Gfunc.axiosPostAction(service ,"emptycat",{catId:val})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else
     Gfunc.axiosResponse(response,()=>{
      if(response.data.jData===true){
        handleAlert(true,"success","La catégorie a été vidée avec succès ")  
        fetch(1)
        setCat("")
          }
      })
  }*/

  const handleChange=async(val)=>{
    const value=catL[Gfunc.getIndex(catL,val,"nom")].nom;
    const objIndex =  catL.findIndex((obj => obj.nom===value)); 
    setCat(catL[objIndex].id)
    fetch(1,catL[objIndex].id,service,perPage,search)
  }

  const ExpanableComponent =({data}) => {
    const [prod,setProd]=useState([]);
    const [loadingExp,setLoadingExp]=useState(false) 
    
    //fetch products
     const fetchP= useCallback(async() => {
      setLoadingExp(true)
      const response=await Gfunc.axiosPostAction("_ach.php","getprodach",{idp:data.produitId})
      setLoadingExp(false)
      Gfunc.axiosResponse(response,()=>{
        setProd(response.data.jData);
      })
    },[data])

    useEffect(() => {
     if(prod.length===0){ fetchP();}
    }, [fetchP,prod.length]);
   
    const handleReplace=useCallback(async(row)=>{
      const response=await Gfunc.axiosPostAction(service ,"setpu",{listId:parseInt(data.DT_RowId.substr(1)),achatId:row.achatId})
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,"error",response.data.err)
          }else
           Gfunc.axiosResponse(response,()=>{
            if(response.data.jData===true){
              Gfunc.updateElem(data.DT_RowId,row.tarif)
            }
            })
    },[data])

  return (
       <div className="row-expenderr">
          {
            (loadingExp)?<div id="empty-expender"><CircularProgress /></div>
            :
            (prod.length!==0)?
            <div class="table-scroll">
            <table>
            <thead>
            <tr>
              <th>Bon</th>
              <th>Fournisseur</th>
              <th>Date</th>
              <th>Par</th>
              <th>Tarif</th>
              <th></th>
            </tr>
            </thead>
             <tbody>
              {
                prod.map((el,index)=>{
                  return(
                    <tr key={index}>
                      <td>{el.bon}</td>
                      <td>{el.frns}</td>
                      <td>{el.date}</td>
                      <td>{el.agent}</td>
                      <td>{el.tarif}</td>
                      <td title="Appliquer le prix" id='icon-p'>  <FindReplace fontSize="small"  className={classes.blackIcon} onClick={()=>{handleReplace(el)}} /></td>
                    </tr>
                    
                  )
                })
              }
             </tbody>
           </table></div>:<div id='empty-expender'>Aucune donnée .</div>}

       </div>
     )
    }

  return (
    <div className="content">
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <div className='table-header'>
        <div className='button-flex-wrapper' id='mtn-lst-exp'>
          <div className="smallAutoFiltre" id='selectCat'>
           <Autocomplete
               disableClearable
               onChange={(e,newValue) =>{handleChange(newValue);}}
              // value={cat}
               onFocus={handleOpenCat}
               options={catL.map((option) => option.nom)}
               className={classes.dataInput}
               inputlabelprops={{className:classes.dataLabel}} 
               renderInput={(params) =>  
                       <TextField margin='dense' 
                            // error={errorP}
                            size='small'{...params} variant="outlined"  label="FILTRER PAR CATÉGORIE" 
                            SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />
            }
            />
          </div> 
          <DraggableDialog param={param} handleAlert={handleAlert} callback={()=>fetch(1)} />
          <DraggableDialogCat handleAlert={handleAlert} callback={()=>fetch(1)} setOpen={setOpen} />
          <DraggableDialogPrint handleAlert={handleAlert} data={data} setOpen={setOpen} />
         </div>
          {(doSearch)?
            <div className='search' >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined" label="Recherche" value={search} size="small" 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:'pointer' }} />),}}
                               margin='none' onKeyUp={e => liveSearch(e)} onChange={e => {setSearch(e.target.value);setNoupd(true)}} />
              </Box>
            </div>:null}
        </div>
        {(data.length!==0||doSearch)?
        <div id='exp-DT'>
          <DataTable 
              //key={Math.floor(Math.random() * (1000 - 1 + 1)) + 1}
              persistTableHead
              columns={columns}
              data={data}
              noHeader={true}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationPerPage={25}
              paginationRowsPerPageOptions={[25,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles}
              expandOnRowClicked
              expandableRows 
              expandableRowsComponent={ExpanableComponent}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
        />
        </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
        
    </div>    
  );
}
