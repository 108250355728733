import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import TextField from '@material-ui/core/TextField';
import {CircularProgress,ClickAwayListener,TextareaAutosize,Tooltip} from '@material-ui/core';
import {Search,Delete} from '@material-ui/icons';
import DraggableDialog from './modals/mon_chg_add'
import {customStyles,numberColumn}from '../../css/datatable-custom'
import { useStyles,textAreaEdit} from '../../css/muiStyle';
import {AlertCompo,InputNbrEditable,NoDataComponent,NotAllowedComponent,AutocompleteS} from "../../js/components"
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from "@mui/system";
import './../../css/monitoring.css'
import axios from "axios"


export default function Mon_chg(props){
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const Api=require('../../js/Apis')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [type, setType] = useState([]);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [,setDel] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [open, setOpen] =useState(false);
  const [,setUpd] =useState(false);
  const [called, setCalled] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [records, setRecords] =useState('');
  const [severity, setSeverity] =useState("info");
  const[noData,setNoData]=useState(false); 
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[doSearch,setDoSearch]=useState(false);
  const[note,setNote]=useState('');
  const [selecto,setSelecto]=useState('')
  const [Item,setItem]=useState('')
  const [ItemN,setItemN]=useState('')
  const [evl,setEvl]=useState('')
  const service='_chg.php'
  const classes=useStyles()
  const nature=[{id:8,name:'Charge'},{id:11,name:'Commission'}]

  const conditionalRowStyles=[
    { when: row => row.action==='Charge',
       style: {
             color: "red",
       }
     },
    { when: row => row.action==='Commission',
       style: {
             color: 'green',
       }
     }
  ]
   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  const fetch= useCallback(async(page,y,m,s) => {
    setData([])
    setCalled(true)
    setLoading(true);
    var ye=(y!=='Aucun')?y:''
    var me=(m!=='empty')?m:''
    var se=(s!==undefined)?s:search
    const response= (ye!==undefined&&m!==undefined&&ye!==''&&me!=='')?
          await librery.axiosGet2(page+'&a='+ye+'&m='+me+'&search='+((s!==undefined)?s:''),service,'getcc', perPage)
         :(y!==undefined&&y!==''&&y!=='Aucun')?
          await librery.axiosGet2(page+'&a='+ye+'&search='+((s!==undefined)?s:''),service,'getcc', perPage)
         :(y==='Aucun')?await axios.get( param.urlService+service+'?do=getcc&page='+page+"&per_page="+perPage+'&search='+se,{ headers: Gfunc.getHeader()}):
         await librery.axiosGet2(page+'&search='+se,service,'getcc', perPage)
         setLoading(false)
    if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
    }else
       Gfunc.axiosResponse(response,()=>{
          setRecords(response.data.recordsFiltered)
          if(response.data.data!==undefined){
                setDoSearch(true)
                if(y==="Aucun"){setMonths([]);setMonth('')}
                setData(response.data.data);
                setTotalRows(response.data.recordsFiltered);
                setNoData(true)
          }else {setData([])}
    })
  },[Gfunc,param.urlService,perPage])

  useEffect(() => {
    if(!called){
      fetch(1);
    }
  }, [fetch,called]);
  

 //fetch fournisseurs
 const fetchType= useCallback(async(serv="_chg.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    Gfunc.axiosResponse(response,()=>{
        setType(response.data.jData);    
    })
  },[Gfunc])

   //fetch utilisateurs handler
 const handleOpenType = useCallback(async () => {
    if(type.length===0) fetchType()
    },[type,fetchType])

  //delete itam
const handleSetData=useCallback((val)=>{
    setDel(val)
  },[])

const handleDeleteItem=useCallback(async(row)=>{
    const response=await Gfunc.axiosPostAction(service ,"delcc",{id:row.DT_RowId})
    if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,"error",response.data.err)
    }else{
       Gfunc.axiosResponse(response,()=>{
         if(response.data.jData===true){
                handleAlert(true,'success','La '+row.action+' a été supprimé avec succès')
                fetch(currentPage,year,(month!=='')?months[Gfunc.getIndex(months,month,"monthName")].month:month,search);
                Api.Caisse();
         }
       })
    }
},[Gfunc,handleSetData,service,data])
 
const handleSelectE=useCallback((row,att)=>{
    setSelecto(row[att])
    setItem(row.DT_RowId) 
    setItemN(att)
  },[])
  
//handle update note
const handleOnEditNote=useCallback(async(note,row,att,action)=>{
    const edt1=note
    var edt=Gfunc.DeleteSpace(edt1);
    if(row[att]!==edt){
      edt=edt.replaceAll("\n", "\r")
      const attrib=row[att];
      const response=await librery.axiosEdit2(service,action,row,edt,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{row[att]=edt})
      if(response.data.err) {row[att]=attrib}
    }else {row[att]=edt}
    setNote('')
},[]);

  const columns =useMemo(
    () => [ 
      {
        name: "Date opération",
        selector: row=>row.dateOp,
        sortable: true,
        width:'150px !important',
        sortField:'dateOp',
      },  
      {
        name: "Nature",
        selector: row=>row.action,
        sortable: true,
        width:'150px !important',
        sortField:'action',
        cell: (row) => { 
            return (
            <div >  
             {
              <AutocompleteS  service={service} action={'updcc'}  defaultValue={row.action} row={row} list={nature} id={'actionId'}  name='action' 
                              attr='name' setLoading={setLoading} setItem={setItem} setSelecto={setSelecto} handleAlert={handleAlert} 
                              handleResponse={()=>{
                                setUpd(true);setUpd(false)
                                Api.Caisse();
                              }}
              />
             }          
            </div> 
            );
        }
      },  
      {
        name: "Type",
        selector:row=>row.typeCharge,
        maxWidth:'180px !important',
        sortable: true,
        sortField:"typeCharge",
        cell: (row) => { 
            return (
            <div >  
             {
               <AutocompleteS service={service} action={'updcc'}  defaultValue={row.typeCharge} row={row} list={type} id={'typeChargeId'} 
                              name='typeCharge' attr='nom' onOpen={handleOpenType} setLoading={setLoading} setItem={setItem} 
                              setSelecto={setSelecto}  handleAlert={handleAlert}
               />
             }          
            </div> 
            );
        }
      },
      {
        name: "Description",
        selector:row=>row.charge,
        sortable: true,
        sortField:"charge", 
        minWidth:'250px !important',
        cell: (row)=>{
            return(
              <div className="note-container">
                  { (row.charge===selecto && row.DT_RowId===Item)?
                  <ClickAwayListener onClickAway={()=>{(note)?handleOnEditNote(note,row,"charge",'updcc'):setSelecto('')}}>
                  <TextareaAutosize style={textAreaEdit} autoFocus onFocus={(e)=>{ e.target.value=note}} 
                                    onChange={(e)=>{setNote(e.target.value);}}
                  /></ClickAwayListener>:
                  (((row.charge&&row.charge.match(/\n/g)) || []).length>=1)?
                  <Tooltip title={row.charge.replaceAll("\n", "/ ")}>
                      <div className="content-editable" onClick={()=>{setNote(row.charge);handleSelectE(row,'charge')}}>{row.charge}</div>
                  </Tooltip>:
                  <Tooltip title={(row.charge)?row.charge:''}>
                  <div className="content-editable" onClick={()=>{setNote(row.charge);handleSelectE(row,'charge')}} >{row.charge}</div></Tooltip>}
              </div>
            )
           }
      },
      {
        name: "Caissier",
        selector:row=>row.caissier,
        sortable: true,
        sortField:"caissier",
        width:'130px !important'
      },
      {
        name: "Date saisie",
        selector:row=>row.dateAdd,
        sortable: true,
        sortField:"dateAdd",
        width:'130px !important'
      },
      {
        name: "Par",
        selector:row=>row.agentAdd,
        sortable: true,
        sortField:"agentAdd",
        width:'130px !important'
      },
      {
        name: "Montant",
        selector:row=>row.mtn,
        width:'150px !important',
        sortable: true,
        style:numberColumn,
        sortField:"mtn",
        cell: (row) => {
            return (
              <InputNbrEditable service={service} action='updcc' min={1} row={row} att="mtn" value={row.mtn} id='DT_RowId' idi={row.DT_RowId}
                                handleAlert={handleAlert} charge='charge'
             />
             );
           },
      },
      {
        width:'35px',
        cell: (row) => {
          return (
            <Tooltip title="Supprimer" >
              <div onClick={()=>{handleDeleteItem(row)}}><Delete fontSize="small"  className={classes.redIcon}/></div>
            </Tooltip>
          );
        },   
       }   
  ],[handleDeleteItem,handleOpenType,selecto,ItemN,Item,note,classes.redIcon]);
  
 
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,'getcc')
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      })  
    }
  }

  const handleSearch=async( size = perPage)=>{
    setData([])
    setLoading(true);
    setCurrentPage(1)
    var mm='';
    const recherche=search;
    var yy=(year!=='Aucun')?year:""
    if(month!==''){
      mm=(month!=='Aucun')?months[Gfunc.getIndex(months,month,"monthName")].month:""
    }
    const response=((year===''||year==='Aucun')&&mm==='')?await librery.axiosSearch(service,recherche,1,size,'getcc'):
              (mm===''&&year!=='')?await librery.axiosSearch(service,recherche,1,size,'getcc&a='+yy)
              :await librery.axiosSearch(service,recherche,1,size,'getcc&a='+yy+'&m='+mm)
    setLoading(false);
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
   }
    return recherche
  }

  function handlePageChange (page) {
    fetch(page,year,(month!=='')?months[Gfunc.getIndex(months,month,"monthName")].month:month,search);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,year,(month!=='')?months[Gfunc.getIndex(months,month,"monthName")].month:month,search);
    setPerPage(newPerPage);
  };
  
  //Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }

  function liveSearch2() {
    handleSearch();
  }

  //handle get Year
  const getYears=useCallback(async()=>{
        const response= await Gfunc.axiosGetAction('_jrn.php',"getsyear");
        if(response.data.err&&response.data.err!=='-1'){
         handleAlert(true,"error",response.data.err);
        }else
        Gfunc.axiosResponse(response,()=>{
           if(response.data.jData){
               response.data.jData.unshift({year:'Aucun'})
               setYears(response.data.jData)
           }
        })
 },[])

  //handle get months
  const getMonths=useCallback(async(y)=>{
    //var yy=(year!=='Aucun')?y:""
    if(y!==''&&y!==undefined){
      if(y!=="Aucun"){
        const response= await Gfunc.axiosPostAction('_jrn.php',"getsmonth",{year:y});
        if(response.data.err&&response.data.err!=='-1'){
         handleAlert(true,"error",response.data.err);
        }else
        Gfunc.axiosResponse(response,()=>{
           if(response.data.jData){
               response.data.jData.unshift({month:'empty',monthName:'Aucun'})
               setMonths(response.data.jData)
           }
        })
      }else{handleAlert(true,'error',"Aucun mois ne correspond à votre année choisie")}
    }else{
        handleAlert(true,'error','Vous devez choisir une année en amont')
    }
   },[])
  
   //fetch year handler
  const handleOpenYear= useCallback(async () => {
    if(years.length===0) getYears()
    },[years,getYears])

  //fetch utilisateurs handler
  const handleOpenMonth = useCallback(async (year) => {
    if(months.length===0) getMonths(year)
  },[months,getMonths])

  const onChangeYear=(val)=>{
    fetch(currentPage,val,(month!=='')?months[Gfunc.getIndex(months,month,"monthName")].month:month,search)
  }
  const onChangeMonth=(val)=>{
    fetch(currentPage,year,months[Gfunc.getIndex(months,val,"monthName")].month,search)
  } 

  
  return (
    <div className="content">
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <div className='table-header'>
           <div className='button-flex-wrapper-chg' >
           <DraggableDialog handleAlert={handleAlert} callback={()=>fetch(1)} />
            
            <div className="smallAutoFiltre" id='selectCat'>
                <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{onChangeYear(newValue);setYear(newValue)}}
                    value={year}
                    onFocus={handleOpenYear}
                    options={years.map((option) => option.year)}
                    className={classes.dataInput}
                    inputlabelprops={{className:classes.dataLabel}} 
                    renderInput={(params) =>  
                            <TextField {...params} margin='dense' size='small' variant="outlined"  label="FILTRER PAR ANNÉE" 
                                       SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                           />
                    }
                 />     
            </div>
            <div className="smallAutoFiltre" id='selectCat'>
                <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{onChangeMonth(newValue);setMonth(newValue)}}
                    value={month}
                    onFocus={()=>handleOpenMonth(year)}
                    options={months.map((option) => option.monthName)}
                    className={classes.dataInput}
                    inputlabelprops={{className:classes.dataLabel}} 
                    renderInput={(params) =>  
                            <TextField  {...params} margin='dense' size='small'variant="outlined"  label="FILTRER PAR MOIS" 
                                        SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                            />
                    }
                 />     
            </div>
         </div>
          {(doSearch)?
            <div className='search' >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search}  size="small" 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:'pointer'}} />),}}
                               margin='none' onKeyUp={e => {liveSearch(e)}} onChange={e => {setSearch(e.target.value);}} />
              </Box>
            </div>:null}
        </div>
        {(data.length!==0||doSearch)?<div>
          <DataTable 
              //key={Math.floor(Math.random() * (1000 - 1 + 1)) + 1}
              persistTableHead
              columns={columns}
              data={data}
              noHeader={true}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationPerPage={15}
              paginationRowsPerPageOptions={[15,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
        /></div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
        
    </div>    
  );
}
