import React,{useState,useCallback,useMemo} from 'react';
import {DialogContent,Paper,Dialog,IconButton,Typography, Tooltip} from '@material-ui/core';
import Draggable from 'react-draggable';
import {paperProps } from '../../../css/muiStyle';
import {SettingsInputAntenna,ExitToApp,Close} from '@material-ui/icons';
import { useStyles } from '../../../css/muiStyle';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import {AlertCompo} from "../../../js/components"
import DataTable from "react-data-table-component";
import {customStyles}from '../../../css/datatable-custom'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import Icon from '@mdi/react';
import { mdiClipboardClockOutline } from '@mdi/js';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//historique utilisateur
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Adm_usr_histo(props) {
    const classes = useStyles();
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const [dis,setDis]=useState('')
    const [open,setOpen]=useState(false)
    const [data,setData]=useState('');
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const service='_usr.php'

    const conditionalRowStyles=[
      { when: row => row.actv===true,
         style: {
               color: "green",
         }
       },
      { when: row => row.actv===false || row.id===dis,
         style: {
               color: 'gray',
         }
       }
    ]

    const handleAlert=(open,severity,message)=>{
        setOpenA(true)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
      }

    const handleClickOpen = () => { 
      setOpen(true); 
      setDis(false);
      fetch()
    };
    const handleClose = () => { setOpen(false); };

    const Disconnect=async(row)=>{
      const response=await Gfunc.axiosPostAction(service,'disconnect',{sessionId:row.id})
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData===true){
          row.actv=false
          setDis(row.id)
          handleAlert(true,'success',"La session est déconnectée")
        }
      })
    }
    
    const columns =useMemo(
        () => [   
          {
              name: "OUVERTURE",
              selector:row=> row.open,
              sortable: true,
              minWidth:"130px !important"
          },
          {
              name: "IP",
              selector:row=> row.ip,
              sortable: true,
              minWidth:"130px !important"
          },
          {
              name: "MACHINE",
              selector:row=> row.host,
              sortable: true,
              minWidth:"130px !important"
          },
          {
              name: "FERMETURE",
              selector:row=> row.close,
              sortable: true,
              minWidth:"130px !important"
          },
          {
            width:"30px !important",
            cell: (row) => {
              return (
                <div>
                  {
                    (row.actv)?
                    <div onClick={()=>Disconnect(row)} title="Déconnexion">
                      <ExitToApp className={classes.blackIcon} fontSize="small" style={{heigth:'20px',width:'20px'}}/>
                    </div>:<></>
                  }
                </div>
              );
             },
          }  
    ],[classes]);
 
    const fetch= useCallback(async() => {
      const response=await Gfunc.axiosPostAction(service,"getsessions",{userId:props.row.DT_RowId.substr(1)})
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,"error",response.data.err)
      }else{
          Gfunc.axiosResponse(response,()=>{
              if(response.data.jData){
                setData(response.data.jData);
              }
          })
        } 
  },[Gfunc]);


  return (
    <div>
      <Tooltip  title="Historique des connexions">
        <div id="imprDig" onClick={handleClickOpen} ><SettingsInputAntenna   className={classes.greenIcon} style={{height:'18px',width:'18px'}}/></div>
      </Tooltip>
      <Dialog 
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}
       >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
          <div className='DialogTitle'>
              <Icon path={mdiClipboardClockOutline} size={1} />
              <label> Historique des connexions de <b>{props.row.user}</b></label>
          </div>
        </DialogTitle> 
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
        <DialogContent>
        <div id='div-usr-hist'>
            <DataTable 
                    key={Math.floor(Math.random() * (1000 - 1 + 1)) + 1}
                    className="table"
                    persistTableHead
                    columns={columns}
                    data={data}
                    noHeader={true}
                    customStyles={customStyles}
                    defaultSortAsc={true}
                    noDataComponent={param.ErreurData}    
                    highlightOnHover
                    pagination
                    paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}     
                    conditionalRowStyles = { conditionalRowStyles }          
             />
        </div>
        </DialogContent>
      </Dialog>
      </div>
  );
}
