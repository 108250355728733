import React, { useState,useCallback,useEffect } from 'react'
import {NoDataComponent,NotAllowedComponent,AlertCompo} from '../../../js/components'
import { useHistory } from 'react-router-dom';
import {ReactComponent as Inv} from '../../../img/icons/inventaire.svg'
import { CalendarToday, KeyboardBackspace, Person } from '@material-ui/icons';
import '../../../css/global.css';
import '../../../css/datatable-custom'
import '../../../css/inventaire.css';


export default function Digitalisation(props) {
    var row1=''
    if(props.location.row1){
      row1=props.location.row1;
      localStorage.setItem('row1',JSON.stringify(row1))    
    } else {
      row1=JSON.parse(localStorage.getItem('row1'))
    }
    const[data,setData]=useState([]);
    const[dataD,setDataD]=useState([]);
    const[noAjust,setNoAjust]=useState(false);
    const [openA, setOpenA] =useState(false);
    //  const [open, setOpen] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
  //  const[noData,setNoData]=useState(false); 
    const[notAllowed,setNotAllowed]=useState(false);
    const[errMessage,setErrMessage]=useState('');
    const Gfunc=require('../../../Gfunc')
    const service='_inv.php'
    const message="Aucun ajustement n'a été effectué"
    const history = useHistory();

    const routeChange = (newPath) =>{ 
      let path = newPath; 
      history.push(path);
    }

//fetch data
const fetch= useCallback(async(servic = service) => {
        const response = await Gfunc.axiosPostAction(service ,'getlocked',{idi:row1.DT_RowId.substr(1)})
        if(response.data.err&&response.data.err!=="-1"){
          handleAlert(true,'error',response.data.err)
          setErrMessage(response.data.err)
          setNotAllowed(true)
        }else
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData!==undefined){
          setNoAjust(true);
          if(response.data.jData.length!==0){
          setData(response.data.jData.detail)
          }else  setData(response.data.jData)
        }
        })
 },[Gfunc,row1]);

//fetch detail
const fetchD= useCallback(async(servic = service) => {
        const response = await Gfunc.axiosPostAction(service ,'getdet',{idi:row1.DT_RowId.substr(1)})
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData!==undefined)
          {
           setDataD(response.data.jData)
          }
        })
},[Gfunc,row1]);

useEffect(() => {
 fetch(1);
 fetchD();
}, [fetch,fetchD]); 

 //handle alerts properties
 const handleAlert=(open,severity,message)=>{
  setOpenA(true)
  setSeverity(severity)
  setMessageA(message)
  setTimeout(function(){
    setOpenA(false)
  }, 5000);
}

function backHome(){ routeChange('/r/ri') }  

    var rows = [];
    return (
        <div className='content'>
            <div id='div-clo-fin'>
              <div title="Retour à inventaire du stock">
                 <KeyboardBackspace style={{height:'20px',width:'24px',cursor:'pointer'}} onClick={() => { backHome() }}/>
              </div>
              <div id='inv-clo-infos'>
                  <div id='item'>
                      <Inv style={{height:'20px',width:'20px'}}/>
                      <b>Nom:</b>
                      <p>{row1.inventaire}</p>
                  </div>
                  <div id='item'>
                      <CalendarToday style={{height:'20px',width:'20px'}}/>
                      <b>Date:</b>
                      <p>{row1.datin}</p>
                  </div>
                  <div id='item'>
                      <Person style={{height:'20px',width:'20px'}}/>
                      <b>Par:</b>
                      <p>{row1.user}</p>
                  </div>
              </div>
            </div>
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} />
            <div id="cloDiv">
            { (data.length!==0)?
              <table className="data">
                <thead><tr><th>ID</th><th>PRODUIT</th><th>QTE. AJUSTEMENT</th><th>MONTANT</th></tr></thead>
                <tbody>
                  {
                  data.forEach((e,index) => {
                    rows.push(
                                             <tr key={index} className='hoverstyle'>          
                                             <td> {('000000'+e.idp).substring(Math.min((""+e.idp).length,6))}</td>
                                             <td>{e.produit}</td>
                                             <td title="Quantité d'ajustement">{e.qte_ajust}</td>
                                             {
                                             (e.mtn_ajust<0)?<td title="Montant d'ajustement" className="Mtn_inf">{new Intl.NumberFormat().format(e.mtn_ajust)}</td>
                                             :<td title="Montant d'ajustement" className="Mtn_sup">{new Intl.NumberFormat().format(e.mtn_ajust)}</td>
                                             }
                                             </tr>);
                })
                }
                {rows}
                </tbody>
              </table>
              :(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noAjust)?<div><NoDataComponent ajust={true} message={message}/></div>:null}
            </div>

        </div>
    )
}
