import React,{useState,useRef,useMemo} from 'react';
import {DialogContent,DialogActions,Paper,Dialog,Button,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import {customStyles}from '../../../css/datatable-custom'
import {paperProps} from '../../../css/muiStyle';
import ReactToPrint from 'react-to-print';
import {Print,Close} from '@material-ui/icons';
import DataTable from "react-data-table-component";
import { useStyles } from '../../../css/muiStyle';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';


const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


//add new marque
function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    )
  }

export default function Irh_dig_imprimer(props) {
    const classes = useStyles();
    const param=useContext(ParamContext);
    const [open,setOpen]=useState(false)
    const componentRef = useRef();


    const handleClickOpen = () => { 
      setOpen(true);
    };

    const handleClose = () => { setOpen(false); };
    
    const columns = useMemo(()=>[   
        {
            name: "Produit",
            selector: row=>row.produit,
            minWidth:'300px !important',
            cell: (row) => {
              return (
                <div data-tooltip={row.produit} className='tooltipU'>
                  {row.produit}
                </div>
              );
             },    
        },
        {
            name: "Catégorie",
            minWidth:'120px !important',
            selector: row=>row.categorie,
        },
        {
            name: "Qte.",
            selector: row=>row.qte,
            minWidth:'100px !important',
        },
        {
            name: "Prix",
            selector: row=>row.tarif,
            minWidth:'170px !important',
            cell: (row) => {
                return (
                  <div>
                    {new Intl.NumberFormat().format(row.tarif)}
                  </div>
                );
               },    
        },
    ],[])

    return (
     <div>
      <Button variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Print/>} className={classes.topTabBtn}>Imprimer liste d'achat</Button>
      <Dialog 
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={paperProps}
       >
       <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
            <div className='DialogTitle'>
              <Print style={{height:"20px",width:'20px'}} />
              <label> Imprimer la liste d'achat</label>
            </div>
       </DialogTitle>
       <DialogContent className={classes.dialogueContent}>
          <div className='bon-ach' ref={componentRef} >
          <DataTable 
                  persistTableHead
                  columns={columns}
                  data={props.data}
                  noHeader={false}
                  customStyles={customStyles}
                  title={"La liste d'achat "}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
                />
          </div>
        </DialogContent>
        <DialogActions>
            <ReactToPrint
                 trigger={() => <Button variant="contained" color="primary"  startIcon={<Print />}>Imprimer</Button>}
                 content={() => componentRef.current} 
            />
        </DialogActions>
      </Dialog>
      </div>
  );
}
