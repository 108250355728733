import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,Tooltip,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Add, AddCircle,Close} from '@material-ui/icons';
import {TextField} from '@material-ui/core';
import { ValidatorForm} from 'react-material-ui-form-validator';
import  {useStyles,paperPropsSmallF} from '../../../css/muiStyle';
import  '../../../css/retour.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { mdiPlusBox } from '@mdi/js';
import { withStyles} from '@material-ui/core/styles';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Mon_lst_prd_add(props) {
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] = useState(false);
    const [errorQ, setErrorQ] = useState(false);
    const [errorP, setErrorP] = useState(false);
    const [qte, setQte] = useState('');
    const [prx, setPrx] = useState('');
    const classes = useStyles();
    const service="_lst.php"

     //get calculation methods
     const getPrdPrix= useCallback(async() => {
      var prx=''
      const response = await Gfunc.axiosPostAction('_prd.php',"getprxsach",
                {idp:(props.dialog!=='abs')?parseInt(props.row.DT_RowId.substr(1)):parseInt(props.row.produitId)})
      if(response.data.err){
        props.handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          if(response.data.jData.achat.length!==0){
            prx=response.data.jData.achat[0].prxAchat
          }
        }
      })
      return prx;
    },[]);

    const handleClickOpen =async () => { 
        var prix=await getPrdPrix();
        setPrx((prix))
        setQte('');setErrorP(false);setErrorQ(false)
        setOpen(true); 
    };
    const handleClose = () => { setOpen(false); };
    
   
    
    const handleAdd=async()=>{
        if(qte===''){setErrorQ(true)}
        if(prx===''){setErrorP(true)}
        if(qte!==''&&prx!==''){
          const response=await Gfunc.axiosPostAction(service,"add",{prodId:(props.val!=='prod')?props.row.produitId:props.row.DT_RowId.substr(1),qte:parseInt(qte),prx:parseFloat(prx)})
          if(response.data.err&&response.data.err!=="-1"){
            props.handleAlert(true,"error",response.data.err)
         }else
         Gfunc.axiosResponse(response,()=>{
            if(response.data.jData===true){
              props.callback()
              setOpen(false)
              props.setOpen(false) 
            }
         })
        }
      }
   
    //valider formulaire avec touche d'entrer
const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="addBtn")) {
          alert("Vous devez remplir tous les champs!")
      }
    }

  return (
    <div>
      <Tooltip title="Ajouter à la liste d'achat "><AddCircle fontSize="small"  className={classes.redIcon} onClick={handleClickOpen}/></Tooltip>
        <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallF}
        >
            <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
              <div className='DialogTitle'>
                <Icon path={mdiPlusBox} size={1} />
                <label>Ajouter {props.row.produit} à la liste d'achat</label>
              </div>  
            </DialogTitle>
            <DialogContent >
            <ValidatorForm  noValidate  id="form" ref={useRef(null)} onSubmit={handleAdd} onKeyDown={e=>detectTouche(e)} >

              <TextField type='number' error={errorQ} className='smallText' margin='dense' variant='outlined' size="small" label='Quantité'
                       value={qte} onChange={e =>{setQte(e.target.value);setErrorQ(false)}} title='Insérer la quantité ' 
                       fullWidth autoFocus required 
                       helperText={(errorQ)?"Veuillez sélectionner une Quantité":null}/>
           
              <br/>
              <TextField type='number' error={errorP} className='smallText' margin='dense' variant='outlined' size="small" label='Prix' value={prx} 
                        onChange={e =>{setPrx(e.target.value);setErrorP(false)}} title='Insérer le prix' placeholder="Prix" fullWidth  required
                        helperText={(errorP)?"Veuillez sélectionner un prix":null} />

            </ValidatorForm>
           </DialogContent>
            <DialogActions>
              <Button variant="contained" name="iValide" form="form" type='submit' color="primary" startIcon={<Add />}>AJOUTER</Button>
            </DialogActions>

      </Dialog>
      </div>
  );
}
