import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import {CircularProgress,Checkbox,FormControlLabel, ClickAwayListener, TextareaAutosize, Tooltip} from '@material-ui/core';
import {Search,Lock,LockOpen} from '@material-ui/icons';
import DraggableDialog from './modals/ach_sto_add'
import RolDialog from './modals/ach_sto_usr'
import {conditionalRowStyles,customStyles}from '../../css/datatable-custom'
import { textAreaEdit, useStyles } from '../../css/muiStyle';
import {AlertCompo,NoDataComponent,NotAllowedComponent} from "../../js/components"
import { Box } from "@mui/system";
import './../../css/stock.css'

export default function Ach_sto(props){
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [,setCheck]=useState('');
  const [,setDefaut]=useState(false);
  const [check1,setCheck1]=useState('');
  const [vendable,setvendable]=useState(false);
  const [Icon,setIcon]=useState('');
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const [noData,setNoData]=useState(false); 
  const [notAllowed,setNotAllowed]=useState(false);
  const [errMessage,setErrMessage]=useState('');
  const [doSearch,setDoSearch]=useState(false);
  const [,setRefresh]=useState(false);
  const [Item,setItem]=useState('')
  const [selecto,setSelecto]=useState('')
  const [note,setNote]=useState('')

  const service='_stk.php'
  const srv='stock'
  const classes=useStyles()
  const[called,setCalled]=useState(false);
  
   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  const fetch= useCallback(async(page,servic = service, size = perPage) => {
    setCalled(true)
    setLoading(true);
    const response=await librery.axiosGet(page+'&search='+search,servic,size)
      if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setDoSearch(true)
        setNoData(true)
        }else {setDoSearch(false);setData([])}
      })
    setLoading(false)
  },[Gfunc,librery,perPage,search])

  useEffect(() => {
    if(!called) {fetch(1);}
    Gfunc.loseFocus(".content-editable")
  }, [fetch,called,Gfunc]);
  
  const flagMan = useCallback(async( e,row,att)=>{
    var val=''
    if(att==='vendable'){
         val=(row[att]===true)?1:0
    }
          const objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId)); 
          const response= await Gfunc.axiosPostAction('flagman.php','',({ srv:srv, id: row.DT_RowId.substr(1),att:att, val:(val==='')? row[att]:val }))
          if(att==='vendable')
          {
          if(response.data.err){
            setCheck1(row.DT_RowId)
            setvendable(row[att])
          }
          else{
           Gfunc.axiosResponse(response,()=>{
            if(response.data.jData===true)
            {
            setCheck1(row.DT_RowId)
            setvendable(!row[att])
            }
        //setIcon(row.DT_RowId) 
            })
          }
    }
    else{
      Gfunc.axiosResponse(response,()=>{
        setIcon(row.DT_RowId) 
            })
      if(row.activ){
        data[objIndex].activ=0
      }else data[objIndex].activ=1
    }
   
  },[Gfunc,data,srv]);

  //update field
  const handleOnEdit=useCallback(async(e,row,att)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
    if(att==='nom' ){
      if(row.stock!==edt){
        const attrib=row.role;
        const response=await librery.axiosEdit(service,row,edt,att) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{ row.role=edt ;e.target.innerHTML=edt})
        if(response.data.err) {e.target.innerHTML=attrib}
      }else{e.target.innerHTML=edt}
    }
    else
        if(row[att]!==edt){
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt,att) 
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt}
  },[librery,Gfunc]);

  const handleLockIcon=useCallback((val)=>{
      setIcon(val);
  },[]);
  
  useEffect((val) => {
    handleLockIcon(val);
  }, [handleLockIcon]);
  const handleRefresh=(val,dat)=>{
    setRefresh(val)
    setData(dat)
  }

  const handleSelect=useCallback((row,att)=>{
    setSelecto(row[att])
    setItem(row.DT_RowId)
 },[])

  //handle update note
  const handleOnEditNote=useCallback(async(note,row,att,action)=>{
    const edt1=note
    var edt=Gfunc.DeleteSpace(edt1);
    if(row[att]!==edt){
      edt=edt.replaceAll("\n", "\r")
      const attrib=row[att];
      const response=await librery.axiosEdit2(service,action,row,edt,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{row[att]=edt})
      if(response.data.err) {row[att]=attrib}
    }else {row[att]=edt}
    setNote('')
    setSelecto('')
  },[librery,Gfunc]);
 
  const HandleUpdateDefault=useCallback(async(e,row,att)=>{
     var objIndex='';var index='';
     const response= await Gfunc.axiosPostAction(service,'setdef',({ id:row.DT_RowId.substr(1) }))
     setCheck(row.DT_RowId)
     if(response.data.err){
        setDefaut(row[att])
     }
     else{
       setDefaut(!row[att])
       Gfunc.axiosResponse(response,()=>{
         objIndex = data.findIndex((obj => obj.defaut === true)); 
         index = data.findIndex((obj => obj.DT_RowId === row.DT_RowId));
         if(objIndex!==-1) {
          data[objIndex].defaut=false
         }
         data[index].defaut=true
         localStorage.setItem('stock',row.stock); 
         localStorage.setItem('stockId',row.DT_RowId.substr(1)); 
         localStorage.setItem('Activ',row.activ); 
         setRefresh(true)
         handleRefresh(false,data)
             })
     }
  },[service,Gfunc,data])

  const columns =useMemo(
    () => [   
      {
          name: "STOCK",
          selector: row=>row.stock,
          sortable: true,
          minWidth:'200px !important',
          sortField:"stock",
          cell: (row) => {

            return (
            <ContentEditable
                html={(row.stock)?row.stock:''}
                name="stock"
                data-column="item"
                className="content-editable"
                disabled={false} 
                onBlur={e => handleOnEdit(e,row,"nom")}
          />
             );
           },
      },
      {
        name: "CREE",
        minWidth:'130px !important',
        selector: row=>row.datin,
        sortable: true,
        sortField:"datin",
      },
      {
        name: "Par",
        minWidth:'180px !important',
        selector:row=>row.user,
        sortable: true,
        sortField:"user",
      },
      {
        name: "Vendable",
        selector:row=>row.vendable,
        sortable: true,
        minWidth:'180px !important',
        sortField:"vendable",
        cell: (row) => {
          if(check1===row.DT_RowId){
            row.vendable=vendable
          }
          return (
            <div className="checkbox">
              <FormControlLabel control={<Checkbox checked={row.vendable} className={classes.checkBox}
                                         onChange={e=>flagMan(e,row,'vendable')} inputProps={{ 'aria-label': 'controlled' }}  />}
                                          /> 
            </div>
           );
         },
      },
      {
        name: "Principal",
        selector:row=>row.defaut,
        sortable: true,
        minWidth:'180px !important',
        sortField:"defaut",
        cell: (row) => {
          return (
            <div className="checkbox">
               <FormControlLabel control={<Checkbox checked={row.defaut} className={classes.checkBox}
                  onChange={e=>HandleUpdateDefault(e,row,'defaut')} inputProps={{ 'aria-label': 'controlled' }}/>}
               /> 
            </div>
           );
         },
      },
      {
        name: "Note",
        selector:row=>row.note,
        sortable: true,
        minWidth:'280px !important',
        sortField:"note",
        cell: (row) => {
          return (
            <div className="note-container">
            { (row.note===selecto && row.DT_RowId===Item)?
            <ClickAwayListener onClickAway={()=>{(note)?handleOnEditNote(note,row,"note",'upd'):setSelecto('')}}>
             <TextareaAutosize style={textAreaEdit} value={note}  onChange={(e)=>{setNote(e.target.value);}}
             /></ClickAwayListener>:
             ((row.note&&row.note.match(/\r/g) || []).length>=1)?
             <Tooltip title={row.note.replaceAll("\r", "/ ")}>
                 <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}}>{row.note.substring(0, row.note.indexOf("\r"))+"..."}</div>
             </Tooltip>:
               <Tooltip title={row.note}>
             <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}} >{row.note}</div></Tooltip>}
             </div>
           );
         },
      },
      {
        right:true,
        cell: (row) => {
          if(Icon===row.DT_RowId){
            if(row.activ===1)
            row.activ=0
            else
            row.activ=1   
            setTimeout(() => { handleLockIcon('')},100)         
          }
          return (
            <div id="iconsAch" >
            <div className='rowGrid' id="row-1" >
              <div><RolDialog param={param} handleAlert={handleAlert} row={row}/></div>
              <Tooltip title="Activer / Désactiver"> 
                <div onClick={e=>flagMan(e,row,'activ')}>
                  {(row.activ)?<LockOpen className={classes.redIcon} fontSize="small"/>:<Lock className={classes.greenIcon} fontSize="small"/> }
                </div>        
              </Tooltip>
            </div>
            </div>
          );
         },
      }  ,
      
  ],[flagMan,handleOnEdit,handleLockIcon,HandleUpdateDefault,Icon,classes,vendable,check1,handleOnEditNote,handleSelect,selecto,Item,note]);
  
 

  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,'get')
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      })     
  }

  const handleSearch=async( size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,'get')
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
    return recherche
  }

  function handlePageChange (page) {
    fetch(page,service,perPage);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,service, newPerPage);
    setPerPage(newPerPage);
  };
  
  //Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }

  function liveSearch2() {
    handleSearch();
  }

  return (
    <div className="content">
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <div className='table-header'>
          <div className='button-flex-wrapper' ><DraggableDialog handleAlert={handleAlert} callback={()=>fetch(1)} /></div>
          {(doSearch)?
            <div className='search' >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} id="input-with-sx" label="Recherche" value={search} variant="outlined"
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer" }} />),}}
                               size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
              </Box>
            </div>:null}
        </div>
        {(data.length!==0||doSearch)?<div>
        <DataTable className="table"
              persistTableHead
              columns={columns}
              data={data}
              noHeader={true}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
        /></div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
    </div>    

  );
}
