import React ,{useCallback,useRef,useState,useMemo}from "react";
import TextField from '@material-ui/core/TextField';
import DataTable from "react-data-table-component";
import {Button,Paper,Typography,CircularProgress,Backdrop } from '@material-ui/core';
import { GetApp,CancelOutlined, DoneAll} from '@material-ui/icons';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { customStyles} from '../../css/datatable-custom'
import  '../../css/reporting.css'
import ReactExport from "react-export-excel";
import axios from "axios"
import { useStyles } from '../../css/muiStyle';
import {theme} from "../../css/theme"
import {NoDataComponent,AlertCompo} from '../../js/components'
import { useMediaQuery } from 'react-responsive'
import BarCharts from '../../compo/BarChart'
import { Autocomplete } from '@material-ui/lab';


export default function Rep_noc(props){
  const classes = useStyles();
  const Gfunc=require('../../Gfunc')
  const [loading,SetLoading]=useState(false)
  const [comm,SetComm]=useState([])
  const [selectComm,SetSelectComm]=useState('')
  const [selectNumber,SetSelectNumber]=useState('15')
  const [icon,setIcon]=useState('')
  const [Commerciel,SetCommerciel]=useState([])
  const [err,SetErr]=useState(false)
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const param=props.param;
  const message='Aucune donnée trouvée'
  const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 850px)'})

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

//recuperer les utilisateurs
const fetchUsers= useCallback(async() => {
    const response = await axios.get( param.urlService+'_usr.php?do=gets',{ headers: Gfunc.getHeader()});
    if(response.data.err&&response.data.err!=='-1'){

    }else{
        Gfunc.axiosResponse(response,()=>{
           response.data.jData.unshift({id:'empty',name:'Tous'})
            SetComm(response.data.jData);   
        })
    }
},[Gfunc,param.urlService]);

//supprimer un element
const flagMan=useCallback(async(row)=>{
  if (window.confirm("Êtes-vous sûrs d'exclure cet élément?")) {   
    const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:"client", id: row.id_client,att:'no_report_warn', val: 0}))
    if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{ 
        if(response.data.jData===true)
         { 
          setIcon(row.id_client)
         }
     })
    }
  }
},[Gfunc])

const columns =useMemo(
    () => [   
      {
        name: "Client",
        selector:row=> row.client,
        sortable: true,
        minWidth:'130px !important'
      },
      {
        name: "Commune",
        selector:row=> row.commune,
        sortable: true,
        minWidth:'130px !important'
      },
      {
        name: "Commercial",
        selector:row=> row.commercial,
        sortable: true,
        minWidth:'130px !important'
    },
    {
        name: "Dernier Versement",
        selector:row=> row.old,
        sortable: true,
        minWidth:'190px !important',
        cell: (row) => {
          return (
            <div >
              {new Intl.NumberFormat().format(row.old)} 
             </div>
          )
        }
    },
    {
        name: " Montant dernier Versement",
        selector:row=> row.last_vers,
        sortable: true,
        minWidth:'260px !important',
        cell: (row) => {
          return (
            <div >
              {new Intl.NumberFormat().format(row.last_vers)} 
             </div>
          )
        }
    },
    {
        name: "Solde",
        selector:row=> row.solde,
        sortable: true,
        minWidth:'100px !important',
        cell: (row) => {
          return (
            <div >
              {new Intl.NumberFormat().format(row.solde)} 
             </div>
          )
        }
      
   },
   {
    minWidth:"50px !important",
    cell: (row) => {
       if(row.id_client===icon){
         row.exclude=0
         setTimeout(() => { setIcon('')},1)
       }
      return (
        <div>
         { (row.exclude)?<div title="Exclusion"><CancelOutlined style={{color:theme.palette.error.main}} onClick={()=>{flagMan(row)}} className={classes.tdIcon}/></div>:null}
       </div>
      )
    }
  }
    ],[classes.tdIcon,flagMan,icon]);
    
//Valider le formulaire
const handleSubmit= async() =>{ 
  var commercial=''
  SetLoading(true)
  SetErr(false)
  SetCommerciel([])
  if(selectComm!==''){
     commercial=comm[Gfunc.getIndex(comm,selectComm,"name")].id
  }
  const response=await Gfunc.axiosPostAction("_rep.php","getnonenc",{jours:selectNumber, commercial:(commercial!=='empty')?commercial:''})
  SetLoading(false)
  if(response.data.err&&response.data.err!=='-1'){
    handleAlert(true,'error',response.data.err)
  }else{
      Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
      if(response.data.jData.length!==0){
        var dat=response.data.jData
        dat.map((item)=>{
          item["exclude"]=1
          return null
        })
          SetCommerciel(dat)
      }else{
        SetErr(true)
      }
      }
      })
  }
}
//remplir Select commercial
const handleOpenComm= useCallback(async () => {
    if(comm.length===0)
       fetchUsers()
},[comm,fetchUsers])

//valider formulaire avec touche d'entrer
const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="addBtn")) {
        if(selectComm===''||selectNumber===''){
          alert("Vous devez remplir tous les champs!")
        }
    }
}


return (
  <div className="content">
   <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
   <fieldset className="fieldsetTop" id="Noc">
        <ValidatorForm noValidate id="formAdd" ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)}  >
            <div className="rowGrid" id="row-1">
            <Autocomplete
                   disableClearable
                   onChange={(e,newValue) =>{SetSelectComm(newValue)}}
                   value={selectComm}
                   options={comm.map((option) => option.name)}
                   onFocus={handleOpenComm}
                   className='smallAuto' 
                   renderInput={(params) =>  
                        <TextField margin='dense' inputlabelprops={{className:(comm)?undefined:classes.dataLabel}}
                                   size='small'{...params} variant="outlined"  label="Commercial..." 
                                   SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                          />}
              />
              <TextField required  color="primary" label="Nombre de jours" size="small" inputProps={{ tabIndex: "4"}}
                     className='smallText' variant='outlined' margin="dense"
                     value={selectNumber}  onChange={e =>SetSelectNumber(e.target.value)} placeholder="Dernier jours.">
              </TextField>
              <Button  className={classes.PrintBtn} variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" color="primary" 
                       startIcon={<DoneAll/>}>VALIDER</Button>
              {
             (Commerciel.length>0)?
             <div>
              <ExcelFile element={ <Button  id='Row-Button1' className={classes.PrintBtn} variant="contained" type='submit' name="addBtn1" color="primary" title='Exporter les Données'><GetApp fontSize="small"/></Button> }>
                  <ExcelSheet data={Commerciel} name="Clients sans encaissement" >
                      <ExcelColumn label="Client" value="client"/>
                      <ExcelColumn label="Commune" value="commune"/>
                      <ExcelColumn label="Commercial" value="commercial"/>
                      <ExcelColumn label="Dernier versement" value="old"/>
                      <ExcelColumn label="Montant dernier versement" value="last_vers"/>
                      <ExcelColumn label="Solde" value="solde"/>
                  </ExcelSheet>
              </ExcelFile>
             </div>:<></>
              }
            </div>
        </ValidatorForm>
        </fieldset>
        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
        {
          (Commerciel.length>0)?
          <div id='charts' >
            <Paper className={classes.paperBody} id='charts-1' elevation={5}>
              <BarCharts width={(isDesktopOrLaptop===false)?600:380} height={300} data={Commerciel}
              margin={{ top: 5, right: 30, left: (isDesktopOrLaptop===false)?20:-10, bottom: 5 }} barSize={20}
              dataKeyX="client" padding={{ left: 10, right: 10 }} dataKeyY="old" fill="#8884d8" name="Age (jours)"/> 
              <Typography className='titleCharts'>Nombre de jours depuis le dernier encaissement</Typography>
            </Paper>
            <Paper className={classes.paperBody} id='charts-2' elevation={5}>
            <BarCharts width={(isDesktopOrLaptop===false)?600:380} height={300} data={Commerciel}
              margin={{ top: 5, right: 30, left: (isDesktopOrLaptop===false)?20:-10, bottom: 5 }} barSize={20}
              dataKeyX="client" padding={{ left: 10, right: 10 }} dataKeyY="solde"  fill="#01579b" name="Solde (DA)"/>
              <Typography className='titleCharts'> Solde </Typography>
            </Paper>
          </div>:<></>
        }
       {
        (Commerciel.length!==0)?
          <DataTable 
              persistTableHead 
              title="Les chiffres d'affaires des clients"
              progressPending={loading}
              columns={columns}
              data={Commerciel}
              defaultSortAsc={true}
              noHeader={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              highlightOnHover
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
          />
       :<></>
    }
    {(err===true)?<div><NoDataComponent message={message}/></div>:<></>}
  </div>    
  );
}