import React,{useState,useRef,useCallback} from 'react';
import {DialogContent,Paper,Button,Dialog,Tooltip,TextField,FormControlLabel,Checkbox,Typography,IconButton, DialogActions} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { useStyles, paperPropsSmallF} from '../../../css/muiStyle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import $ from 'jquery'
import Iconn from "@mdi/react"
import{ mdiTrendingUp,mdiTrendingDown} from "@mdi/js"
import { DoneAll,Close } from '@material-ui/icons';
import "../../../css/referentiel.css"
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_cap_reduce_aug(props) {
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] =useState(false);
    const [errorA, setErrorA] =useState(false);
    const [errorC, setErrorC] =useState(false);
    const [errorCai, setErrorCai] =useState(false);
    const [errorM, setErrorM] =useState(false);
    const [up, setUp] =useState((props.open==='reduce')?false:true);
    const classes=useStyles();
    const [usr, setUsr] =useState([]);
    const [cais, setCais] =useState("");
    const [act, setAct] =useState("");
    const [categ, setCateg] =useState("");
    const [mtn, setMtn] =useState("");
    const [cat, setCat] =useState([]);
    const [note, setNote] =useState('');
    const service='_cai.php'
    const Apis =require("../../../js/Apis.js");

    $(function() {
      $("div").removeAttr("data-tag")
    })  

    const handleClickOpen = () => { 
      setOpen(true); 
      setCateg('');setMtn('');setAct('');setCais('')
    };

    const handleClose = () => { setOpen(false); };

    
    const fetchUser=async()=>{
      const response=await Gfunc.axiosGetAction('_usr.php',"gets")
      if(response.data.err&&response.data.err!=="-1"){
          props.handleAlert(true,"error",response.data.err)
      }else
          Gfunc.axiosResponse(response,()=>{
            if(response.data.jData){
              setUsr(response.data.jData)
            }
       })
    }
    const fetchCat=async()=>{
      const response=await Gfunc.axiosGetAction('_cat.php',"gets")
      if(response.data.err&&response.data.err!=="-1"){
          props.handleAlert(true,"error",response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            setCat(response.data.jData)
          }
     })
      }
    }
  //fetch categorie handler
  const handleOpenCat = useCallback(async () => {
    if(cat.length===0)
    fetchCat()
    },[cat,fetchCat])

  //fetch categorie handler
  const handleOpenUser = useCallback(async () => {
    if(usr.length===0)
    fetchUser()
    },[usr,fetchUser])

    //valider formulaire
    const handleSubmit= async() =>{
      if(mtn===''){setErrorM(true)}
      if(categ===''){setErrorC(true)}
      if(cais===''){setErrorCai(true)}
      if(act===''){setErrorA(true)}
      if(mtn!==""&&categ!==''&&cais!==''&&act!==''){
      const objIndex = usr.findIndex((obj => obj.name ===act )); 
      const objIndex1 = usr.findIndex((obj => obj.name ===cais )); 
      const objIndex2 = cat.findIndex((obj => obj.nom ===categ )); 
      const actionnaire=usr[objIndex].id
      const caissier=usr[objIndex1].id
      const categorie=cat[objIndex2].id
      const response = await Gfunc.axiosPostAction(service,"movcap",{up:up,mtn:parseFloat(mtn),caissier:caissier,actionnaire:actionnaire,
        categorieId:categorie,note:note})
      if(response.data.err){
            props.handleAlert(true,'error',response.data.err)
     }else{
        Gfunc.axiosResponse(response,()=>{
         if(response.data.jData){
           Apis.Caisse()
           props.callback()
           props.callback1()
           props.handleAlert(true,"success",(props.open==='reduce')?"Le capital a été réduit avec succès":"Le capital a été augmenté au avec succès")
           setOpen(false)
          }
        })
      }
      }
      }

    //valider formulaire avec la touche clavier 
const detectTouche= (e)=>{
    if (e.keyCode === 13) {
        if(categ===''|| act==='' || cais===''){
         props.handleAlert(true,"warning","Vous devez renseigner tous les champs")
        }else{handleSubmit()}
    }
  }
  return (
    <div>
       <Tooltip title={(props.open==='reduce')?"Réduire le capital":'Augmenter le capital'}> 
         <Button variant="contained" color="secondary" onClick={handleClickOpen} className={classes.topTabBtn} 
                 startIcon={<Iconn path={(props.open==='reduce')?mdiTrendingDown:mdiTrendingUp} color="black" size="20px"/>} >
                  {(props.open==='reduce')?'Réduction':'Augmentation'}
         </Button>
       </Tooltip>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallF}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
            <div className='DialogTitle'>
              {
                (props.open==='reduce')?
                  <Icon path={mdiTrendingDown} size={1} />
                :<Icon path={mdiTrendingUp} size={1} />
              }
              <label>{(props.open==='reduce')?'Réduire le capital':'Augmenter le capital'}</label>
            </div>
        </DialogTitle>
            <DialogContent >
            <ValidatorForm  id="form" ref={useRef(null)} onSubmit={handleSubmit} onKeyDown={e=>detectTouche(e)} >
            <div className="form-two-grid-container"> 
                        <Autocomplete
                            disableClearable
                            className='smallAuto' 
                            id="iAct"
                            onChange={(e,newValue) =>{setAct(newValue);setErrorA(false)}} 
                            onFocus={handleOpenUser}
                            value={(act!=='')?(act):null}
                            options={usr.map((option) => option.name)}
                            renderInput={(params) => (
                                <TextField {...params} error={errorA} label="Actionnaire" size="small"  required
                                           margin='dense' variant='outlined' helperText={(errorA)?"Veuillez sélectionner un actionnaire":null}
                                   />
                        )} />   
                       <Autocomplete
                          disableClearable
                          className='smallAuto'
                          id="iCaiss"
                          onChange={(e,newValue) =>{setCais(newValue);setErrorCai(false)}} 
                          onFocus={handleOpenUser}
                          value={(cais!=='')?(cais):null}
                          options={usr.map((option) => option.name)}
                          renderInput={(params) => (
                                   <TextField {...params} error={errorCai} label="Caissier" size="small" margin='dense' variant='outlined'
                                           helperText={(errorCai)?"Veuillez sélectionner un caissier":null} required/>
                                )}
                       />  
                       <Autocomplete
                          disableClearable
                          className='smallAuto'
                          id="iCat"
                          onChange={(e,newValue) =>{setCateg(newValue);setErrorC(false)}} 
                          onFocus={handleOpenCat}
                          value={(categ!=='')?(categ):null}
                          options={cat.map((option) => option.nom)}
                          renderInput={(params) => (
                              <TextField {...params} error={errorC} label="Catégorie" size="small" margin='dense' variant='outlined'
                                         helperText={(errorC)?"Veuillez sélectionner une catégorie":null}  />
                                )}
                       />
                       <TextField  type='number'  error={errorM} helperText={(errorM)?"Veuillez sélectionner un montant":null} 
                                   label='Montant' id="iMtn" name="iMtn" value={mtn}  className='smallText' margin='dense' variant='outlined'
                                   onChange={(e)=>{setMtn(e.target.value);setErrorM(false)}}  required                                  
                        />  
                  </div>
                  <div className='note-add'>
                        <TextField  label='Note' id="iNote" name="iNote" value={note}  className='smallNote' margin='dense' variant='outlined'
                                    onChange={(e)=>{setNote(e.target.value);}} 
                                    multiline
                                    minRows={3}
                        /> 
                  </div>
                  <div id='up-check'>
                        <FormControlLabel disabled control={<Checkbox  checked={up} color="primary" label="UP"
                                          onChange={e=>{setUp(e.target.checked)}} inputProps={{ 'aria-label': 'controlled' }}  />}/> 
                  </div>
              </ValidatorForm>
            </DialogContent>
            <DialogActions>
               <Button variant="contained" name="iValide" form='form' type='submit' color="primary" startIcon={<DoneAll/>}>VALIDER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}