import React,{useState,useMemo,useCallback} from 'react';
import DataTable from "react-data-table-component";
import {CircularProgress,DialogContent,Paper,Dialog,Tooltip,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import {customStyles}from '../../../css/datatable-custom'
import axios from "axios"
import {AlertCompo} from "../../../js/components"
import { useStyles, paperPropsBig } from '../../../css/muiStyle';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import { Close } from '@material-ui/icons';
import Icon from '@mdi/react';
import { mdiClipboardText,mdiFormatListGroup} from '@mdi/js';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


//add new categorie
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
} 

export default function Ref_cat_listprds(props) {
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const librery=require('../../../js/data_table_librery')
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const service="_prd.php"
    const classes=useStyles();

      //handle alerts properties
    const handleAlert=(open,severity,message)=>{
        setOpenA(open)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
    }

    const handleClickOpen = () => { 
      setOpen(true); 
      fetch(1);
    };
    const handleClose = () => { setOpen(false); };

    const fetch= useCallback(async(page,servic = service, size = perPage) => {
        setLoading(true);
        const response= await axios.get( param.urlService+servic+'?do=get&page='+page+"&per_page="+size+'&cat='+props.row.DT_RowId.substr(1),{ headers: Gfunc.getHeader()});
          if(response.data.err&&response.data.err!=="-1"){
              handleAlert(true,"error",response.data.err)
          }else
           Gfunc.axiosResponse(response,()=>{
               setData(response.data.data)
               setTotalRows(response.data.recordsFiltered);

          })
        setLoading(false)
      },[Gfunc,param.urlService,perPage])

      const columns =useMemo(
        () => [ 
          {
            name: "Produit",
            selector:row=> row.produit,
            sortable: true,
            width:'300px !important',
            sortField:'produit'
          },
         
          {
            name: "Date",
            selector:row=> row.dateIn,
            sortable: true,
            width:'140px !important',
            sortField:'dateIn'
          },
         
          {
            name: "Marque",
            selector:row=> row.marque,
            sortable: true,
            width:'220px !important',
            sortField:'marque'
          },
         
          {
            name: "Limite",
            selector:row=> row.limite,
            sortable: true, 
            width:'160px !important',
            sortField:'limite',
            cell: (row) => {
              return (
                <div >
                  {(row.limite!==null)?new Intl.NumberFormat().format(row.limite):null} 
                 </div>
              )
            }
          },
         
        ],[]);
    const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
        setLoading(true);
        const response=await librery.axiosSort(column,sortdirection,page,size,service,'get')
        Gfunc.axiosResponse(response,()=>{
            setData(response.data.data);
            setTotalRows(response.data.recordsFiltered);
            setLoading(false);
        })  
        }
    function handlePageChange (page) {
        fetch(page,service,perPage);
        setCurrentPage(page);
      };
    
    const handlePerRowsChange = async (newPerPage, page) => {
        fetch(page,service,newPerPage);
        setPerPage(newPerPage);
      };
      
  return (
    <div>
      <Tooltip title="Liste des produits">
        <Icon path={mdiClipboardText} size="20px" onClick={handleClickOpen}/>
      </Tooltip>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsBig}
      >
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
            <div className='DialogTitle'>
              <Icon path={mdiFormatListGroup} size={1} />
              <label>La liste des produits de {props.row.categorie}</label>
            </div>
        </DialogTitle>
        <DialogContent >
            <br/>
                  <DataTable 
                    //key={Math.floor(Math.random() * (1000 - 1 + 1)) + 1}
                    persistTableHead
                    columns={columns}
                    data={data}
                    noHeader={true}
                    defaultSortAsc={true}
                    noDataComponent={param.ErreurData}
                    paginationRowsPerPageOptions={[10,30,50,100]}
                    progressPending={loading}
                    progressComponent={<CircularProgress className={classes.circularProgress} />}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    sortServer={true}
                    onSort={handleOnSort}
                    perPage={perPage}
                    highlightOnHover
                    customStyles={customStyles}
                    paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
                 />
            </DialogContent>
      </Dialog>
      </div>
  );
}
