import React ,{useRef,useCallback,useState,useMemo} from "react";
import DataTable from "react-data-table-component";
import {Button,MenuItem,CircularProgress,Backdrop } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import { GetApp, DoneAll} from '@material-ui/icons';
import { customStyles} from '../../css/datatable-custom'
import { useStyles } from '../../css/muiStyle';
import  '../../css/reporting.css'
import ReactExport from "react-export-excel";
import {AlertCompo, NoDataComponent} from '../../js/components'
import { Autocomplete } from '@material-ui/lab';


export default function Rep_top (props){
    const Gfunc=require('../../Gfunc')
    const param=props.param;
    const classes = useStyles();
    const [loading,setLoading]=useState(false)
    const [comm,SetComm]=useState([])
    const [cat,SetCat]=useState([])
    const [selectComm,SetSelectComm]=useState('')
    const [Commune,SetCommune]=useState([])
    const [selectcommune,SetSelectCommune]=useState('')
    const [selectcat,SetSelectCat]=useState('')
    const [selectDateD,SetSelectDateD]=useState(Gfunc.DateDeb(new Date()))
    const [selectDateF,SetSelectDateF]=useState(new Date())
    const [topClient,SetTopClient]=useState([])
    const [topClient1,SetTopClient1]=useState([])
    const [topCommune,SetTopCommune]=useState([])
    const [topCommune1,SetTopCommune1]=useState([])
    const [topCommercial,SetTopCommerciel]=useState([])
    const [topCommercial1,SetTopCommerciel1]=useState([])
    const [topCategorie,SettopCategorie]=useState([])
    const [topProduit,SettopProduit]=useState([])
    const [topProduit1,SettopProduit1]=useState([])
    const [topCategorie1,SettopCategorie1]=useState([])
    const [test,Settest]=useState(false)
    const [err,SetErr]=useState(false)
    const [idrow,setIdRow]=useState('')
    const [idrow1,setIdRow1]=useState('')
    const [idrow2,setIdRow2]=useState('')
    const [idrow3,setIdRow3]=useState('')
    const [idrow4,setIdRow4]=useState('')
    var [cl,setcl]=useState(0)
    var [comr,setcomr]=useState(0)
    var [comn,setcomn]=useState(0)
    var [caat,setcaat]=useState(0)
    var [pr,setpr]=useState(0)
    const [severity, setSeverity] =useState("info");
    const [open, setOpen] =useState(false);
    const [messageA, setMessageA] =useState('');
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const message='Aucune donnée trouvée'

      //handle alerts properties
  const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }
    
//recuperer les utilisateurs
const fetchUsers= useCallback(async() => {
    const response = await Gfunc.axiosGetAction( '_usr.php','gets');
    if(response.data.err&&response.data.err!==-1){
      handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({id:'empty',name:'Tous'})
        SetComm(response.data.jData);   
      })
    }
},[Gfunc]);

//recuperer les communes
const fetchCommun= useCallback(async() => {
    const response = await Gfunc.axiosGetAction('_cli.php','getcom');
    if(response.data.err&&response.data.err!==-1){
      handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({id:'empty',name:'Toutes'})
        SetCommune(response.data.jData);   
      })
    }
},[Gfunc]);

//recuper les categories 
const fetchCat= useCallback(async() => {
    const response = await Gfunc.axiosGetAction('_cat.php','gets');
    if(response.data.err&&response.data.err!==-1){
      handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({id:'empty',nom:'Toutes'})
        SetCat(response.data.jData);   
      })
    }
  },[Gfunc]);

//valider le formulaire
 const handleSubmit= async() =>{
    var commercial='';var commune='';var categorie=''
    SetErr(false)
    var cl=0; var comr=0; var comn=0;var caat=0;var pr=0
    setcl(0);setcomr(0);setcomr(0);setcomn(0);setcaat(0);  setpr(0)
    SetTopClient([]); SetTopClient1([]);SetTopCommune([]);SetTopCommune1([]);SetTopCommerciel([]);SetTopCommerciel1([]); SettopCategorie([])
    SettopProduit([]);SettopProduit1([]);SettopCategorie1([])
    setLoading(true)

    var cl1=[];var cl11=[];var cat1=[];var cat11=[];var cm1=[]; var cm11=[];var cum1=[];var cum11=[];var prod=[];var prod1=[];
    if(selectComm!==''){
      commercial=comm[Gfunc.getIndex(comm,selectComm,"name")].id
    }
    if(selectcommune!==''){
      commune=Commune[Gfunc.getIndex(Commune,selectcommune,"name")].id
    }
    if(selectcat!==''){
      categorie=cat[Gfunc.getIndex(cat,selectcat,"nom")].id
    }
    
    const response=await Gfunc.axiosPostAction("_rep.php","gettop",{date1:Gfunc.formatDate(new Date(selectDateD)), date2:Gfunc.formatDate(new Date(selectDateF)),
                                                commercial:(commercial!=='empty')?commercial:'',commune:(commune!=='empty')?commune:'',
                                                categorie:(categorie!=='empty')?categorie:''})
    setLoading(false)
    if(response.data.err&&response.data.err!==-1){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
     if(response.data.jData!==undefined){ 
      //setData(response.data.jData)  
      if(response.data.jData.topClient.length!==0||response.data.jData.topProduit.length!==0||response.data.jData.topCommercial.length!==0||response.data.jData.topCommune.length!==0||response.data.jData.topCategorie.length!==0)
      { Settest(true)}
      if(response.data.jData.topClient.length===0&&response.data.jData.topProduit.length===0&&response.data.jData.topCommercial.length===0&&response.data.jData.topCommune.length===0&&response.data.jData.topCategorie.length===0)
      { SetErr(true)}
      //client
      if(response.data.jData.topClient!==undefined){
        response.data.jData.topClient.map((elem,index)=>{
            cl1.push({ind:index+1,client:elem.client,commune:elem.commune,commercial:elem.commercial,ca:elem.ca,mben:elem.mben})

            if(elem.hasOwnProperty('mben')){
            cl++
            }
            setcl(cl)
            return true
          })
        SetTopClient(cl1)
          var tess=response.data.jData.topClient.sort(function (a, b) {
            return b.mben - a.mben;
          }) 
          tess.map((elem,index)=>{
            cl11.push({ind:index+1,client:elem.client,commune:elem.commune,commercial:elem.commercial,ca:elem.ca,mben:elem.mben})
            return true
          })
          SetTopClient1(cl11)
      }
      //produit
      if(response.data.jData.topProduit!==undefined){
        response.data.jData.topProduit.map((elem,index)=>{
          prod.push({ind:index+1,produit:elem.produit,categorie:elem.categorie,ca:elem.ca,mben:elem.mben})
          if(elem.hasOwnProperty('mben')){
          pr++
          }
          setpr(pr)
          return true
        })
        SettopProduit(prod)
        var tep=response.data.jData.topProduit.sort(function (a, b) {
          return b.mben - a.mben;
        }) 
        tep.map((elem,index)=>{
          prod1.push({ind:index+1,produit:elem.produit,categorie:elem.categorie,ca:elem.ca,mben:elem.mben})
          return true
        })
        SettopProduit1(prod1)
      }
      //commerciel
      if(response.data.jData.topCommercial!==undefined){
        response.data.jData.topCommercial.map((elem,index)=>{
          cm1.push({ind:index+1,commercial:elem.commercial,ca:elem.ca,mben:elem.mben})
          if(elem.hasOwnProperty('mben')){
          comr++
          }
          setcomr(comr)   
          return true
        })
        SetTopCommerciel(cm1)
        var tes=response.data.jData.topCommercial.sort(function (a, b) {
          return b.mben - a.mben;
        }) 
        tes.map((elem,index)=>{
          cm11.push({ind:index+1,commercial:elem.commercial,ca:elem.ca,mben:elem.mben})
          return true
        })
        SetTopCommerciel1(cm11)
      }
       //commune
       if(response.data.jData.topCommune!==undefined){
          response.data.jData.topCommune.map((elem,index)=>{
            cum1.push({ind:index+1,commune:elem.commune,ca:elem.ca,mben:elem.mben})
            if(elem.hasOwnProperty('mben')){
            comn++
            }
            setcomn(comn)
            return true
          })
          SetTopCommune(cum1)
          var tes1=response.data.jData.topCommune.sort(function (a, b) {
            return b.mben - a.mben;
          }) 
          tes1.map((elem,index)=>{
            cum11.push({ind:index+1,commune:elem.commune,ca:elem.ca,mben:elem.mben})
            return true
          })
          SetTopCommune1(cum11)
      }
        //categorie
        if(response.data.jData.topCategorie!==undefined){
          response.data.jData.topCategorie.map((elem,i)=>{
            cat1.push({ind:i+1,categorie:elem.categorie,ca:elem.ca,mben:elem.mben})
            if(elem.hasOwnProperty('mben')){
            caat++
            }
            setcaat(caat)
            return true
          })
          SettopCategorie(cat1)
          var tesss=response.data.jData.topCategorie.sort(function (a, b) { return b.mben - a.mben;})
          tesss.map((elem,i)=>{
            cat11.push({ind:i+1,categorie:elem.categorie,ca:elem.ca,mben:elem.mben})
            return true
          })
          SettopCategorie1(cat11)
        }
      }
    })
   }
  }

//valider formulaire avec touche d'entrer
const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="addBtn")) {
        if(selectComm===''||selectcommune===''||selectcat===''){
          alert("Vous devez remplir tous les champs!")
        }
      }
    }

//remplir Select commercial
const handleOpenComm= useCallback(async () => {
   if(comm.length===0) fetchUsers()
},[comm,fetchUsers])

//remplir Select Commune
const handleOpenCommune= useCallback(async () => {
    if(Commune.length===0) fetchCommun()
},[Commune,fetchCommun])

//remplir Select Categorie 
const handleOpenCat= useCallback(async () => {
    if(cat.length===0) fetchCat()
},[cat,fetchCat])

//colomne tableau chiffre d'affaire client
const columnsC =useMemo(
            () => [ 
              { 
                width:"30px",
                selector:row=> row.ind,
                sortable: true,
              }, 
              {
                name: "Client",
                selector:row=> row.client,
                sortable: true,
                minWidth:'180px'
              },
              {
                name: "Commune",
                selector:row=> row.commune,
                sortable: true,
                minWidth:'220px'
              },
              {
                name: "Commercial",
                selector:row=> row.commercial,
                sortable: true,
                minWidth:'220px'
              },
              {
                name: "C.A.",
                selector:row=> row.ca,
                sortable: true,
                minWidth:'160px',
                cell: (row) => {
                  return (
                    <div >
                      {new Intl.NumberFormat().format(row.ca)} 
                     </div>
                  )
                }
              }
            ],[]);
//colomne tableau chiffre d'affaire du client
const columnsB =useMemo(
    () => [  
      {
        width:"30px",
        selector:row=> row.ind,
        sortable: true,
      },  
      {
        name: "Client",
        selector:row=>row.client,
        sortable: true,
        minWidth:"130px",
      },
      {
        name: "Commune",
        selector: row=>row.commune,
        sortable: true,
        minWidth:"130px",
    },
    {
        name: "Commercial",
        selector: row=>row.commercial,
        sortable: true,
        minWidth:'150px'
      },
    {
        name: "Marge",
        selector: row=>row.mben,
        sortable: true,
        minWidth:"150px",
        cell: (row) => {
          return (
            <div >
              {new Intl.NumberFormat().format(row.mben)} 
             </div>
          )
        }
    }
    ],[]);       

//colomne tableau chiffre d'affaire de commerciel
const columnsCC =useMemo(
() => [ 
  {
    name: "",
    width:"40px",
    selector:row=> row.ind,
    sortable: true,
 },  
  {
      name: "Commercial",
      selector:row=>row.commercial,
      sortable: true,
  },
  {
    name: "C.A.",
    minWidth:'160px',
    selector: row=>row.ca,
    sortable: true,
    cell: (row) => {
      return (
        <div >
          {new Intl.NumberFormat().format(row.ca)} 
         </div>
      )
    }
}
],[]);  
//colomne tableau benifice de commerciel
const columnsCB =useMemo(
    () => [   
      {
        name: "",
        width:"40px",
        selector:row=> row.ind,
        sortable: true,
     },
      {
          name: "Commercial",
          selector:row=>row.commercial,
          sortable: true,
      },
      {
          name: "Marge",
          selector:row=>row.mben,
          sortable: true,
          cell: (row) => {
            return (
              <div >
                {new Intl.NumberFormat().format(row.mben)} 
              </div>
            )
          }
    }
    ],[]);
//colomne tableau chiffre d'affaire de la commune

 const columnsCUC =useMemo(
        () => [   
          {
            width:"30px",
            selector:row=> row.ind,
            sortable: true,
          }, 
          {
              name: "Commune",
              selector:row=>row.commune,
              sortable: true,
          },
          {
            name: "C.A.",
            minWidth:'120px',
            selector:row=>row.ca,
            sortable: true,
            cell: (row) => {
              return (
                <div >
                  {new Intl.NumberFormat().format(row.ca)} 
                 </div>
              )
            }
        }
        ],[]);
//colomne tableau benefice de la commune
const columnsCUB =useMemo(
    () => [   
      {
        width:"30px",
        selector:row=> row.ind,
        sortable: true,
      }, 
      {
          name: "Commune",
          selector:row=>row.commune,
          sortable: true,
      },
      {
          name: "Marge",
          selector:row=>row.mben,
          sortable: true,
          cell: (row) => {
            return (
              <div >
                {new Intl.NumberFormat().format(row.mben)} 
               </div>
            )
          }
    }
    ],[]);

//colomne tableau benifice d'un produit 
const columnsCUP =useMemo(
  () => [   
    {
      width:"30px",
      selector:row=> row.ind,
      sortable: true,
    }, 
    {   
        minWidth:"300px",
        name: "Produit",
        selector:row=>row.produit,
        sortable: true,
        cell:(row)=>{
          return(
            <div title={row.produit}>
              {row.produit}
            </div>
          )
        }
    },
    {
      name: "Catégorie",
      selector:row=>row.categorie,
      sortable: true,
      minWidth:"130px",

  },
    {
        name: "Marge",
        selector:row=>row.mben,
        sortable: true,
        minWidth:"130px",
        cell: (row) => {
          return (
            <div >
              {new Intl.NumberFormat().format(row.mben)} 
             </div>
          )
        }
  }
  ],[]);
//colomne tableau chiffre d'affaire d'une produit
const columnsCAP =useMemo(
      () => [  
        {
          width:"30px",
          selector:row=> row.ind,
          sortable: true,
        },  
        {   
            name: "Produit",
            selector:row=>row.produit,
            sortable: true,
            minWidth:"300px",
            cell:(row)=>{
              return(
                <div title={row.produit}>
                  {row.produit}
                </div>
              )
            }
         },
        {
          name: "Catégorie",
          selector:row=>row.categorie,
          sortable: true,
          minWidth:"130px",
        },
        {
          name: "C.A.",
          minWidth:'170px',
          selector: row=>row.ca,
          sortable: true,
          cell: (row) => {
            return (
              <div >
                {new Intl.NumberFormat().format(row.ca)} 
               </div>
            )
          }
      }
      ],[]);
//colomne tableau benifice d'une categorie
const columnsCAB =useMemo(
    () => [ 
      {
        name: "",
        width:"40px",
        selector:row=> row.ind,
        sortable: true,
      },   
      {
          name: "Catégorie",
          selector:row=>row.categorie,
          sortable: true,
      },
      {
          name: "Marge",
          selector:row=>row.mben,
          sortable: true,
           cell: (row) => {
          return (
            <div >
              {new Intl.NumberFormat().format(row.mben)} 
             </div>
          )
        }
    }
    ],[]);
    //colomne tableau choffre d'affaire d'une categorie
const columnsCAC =useMemo(
  () => [  
    {
      width:'30px',
      selector:row=> row.ind,
      sortable: true,
    },  
    {
      name: "Catégorie",
      selector:row=>row.categorie,
      sortable: true,
      minWidth:"130px "
    },
    {
      name: "C.A.",
      selector: row=>row.ca,
      sortable: true,
      minWidth:'130px !important',
      cell: (row) => {
        return (
          <div >
            {row.ca} 
           </div>
        )
      }
  }
  ],[]);

const conditionalRowStyles = [
        {
          when: row => row.client===idrow,
          style: {
            backgroundColor: '#e6e6e6',
            color: '#990000',
          },
        },
      ];
const conditionalRowStyles1 = [
        {
          when: row => row.commercial===idrow1,
          style: {
            backgroundColor: '#e6e6e6',
            color: '#990000',
          },
        },
      ];
const conditionalRowStyles2 = [
        {
          when: row => row.commune===idrow2,
          style: {
            backgroundColor: '#e6e6e6',
            color: '#990000',
          },
        },
      ];

const conditionalRowStyles3= [
        {
          when: row => row.categorie===idrow3,
          style: {
            backgroundColor: '#e6e6e6',
            color: '#990000',
          },
        },
      ];
const conditionalRowStyles4= [
        {
          when: row => row.produit===idrow4,
          style: {
            backgroundColor: '#e6e6e6',
            color: '#990000',
          },
        },
      ];

return (
  <div className='content'>
  <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
  <fieldset className="fieldsetTop" id="topV">
        <ValidatorForm noValidate id="formAdd" ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)}  >
            <div className="rowGrid" id="row-1">
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                   <KeyboardDatePicker disableToolbar value={selectDateD} variant="inline" placeholder="01/01/2020" onChange={date => SetSelectDateD(date)}
                                       minDate='10/10/2018' format="dd/MM/yyyy" className='smallText' margin="dense" inputVariant="outlined"
                                       invalidDateMessage="Le format de la date est incorrect" 
                               />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                   <KeyboardDatePicker disableToolbar value={selectDateF} variant="inline" placeholder="10/10/2018" onChange={date => SetSelectDateF(date)}
                                        minDate="01/01/2018" format="dd/MM/yyyy" className='smallText' margin="dense" inputVariant="outlined"
                                        invalidDateMessage="Le format de la date est incorrect" 

                               />
             </MuiPickersUtilsProvider>
              <Autocomplete
                   disableClearable
                   onChange={(e,newValue) =>{SetSelectComm(newValue)}}
                   value={selectComm}
                   options={comm.map((option) => option.name)}
                   onFocus={handleOpenComm}
                   className='smallAuto' 
                   renderInput={(params) =>  
                        <TextField margin='dense' 
                              inputlabelprops={{className:(comm)?undefined:classes.dataLabel}}
                              // error={errorP}
                              size='small'{...params} variant="outlined"  label="Commercial..." 
                              SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                          />}
              />
              <Autocomplete
                disableClearable
                onChange={(e,newValue) =>{SetSelectCommune(newValue)}}
                value={selectcommune}
                options={Commune.map((option) => option.name)}
                onFocus={handleOpenCommune}
                className='smallAuto' 
                renderInput={(params) =>  
                    <TextField margin='dense' inputlabelprops={{className:(Commune)?undefined:classes.dataLabel}}
                               size='small'{...params} variant="outlined"  label="Commune..." 
                               SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                      />}
              />
              <Autocomplete
                disableClearable
                onChange={(e,newValue) =>{SetSelectCat(newValue)}}
                value={selectcat}
                options={cat.map((option) => option.nom)}
                onFocus={handleOpenCat}
                className='smallAuto' 
                renderInput={(params) =>  
                    <TextField margin='dense' inputlabelprops={{className:(cat)?undefined:classes.dataLabel}}
                               size='small'{...params} variant="outlined"  label="Catégorie..." 
                               SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                      />}
              />
              <Button  className={classes.PrintBtn} variant="contained" tabIndex="3" type='submit'  id="bouton" name="addBtn" color="primary" 
                       startIcon={<DoneAll/>}>VALIDER</Button>
              {
              (test===true)?
              <ExcelFile element={ <Button  className={classes.PrintBtn} variant="contained"  name="addBtn1" 
                         color="primary" title='Exporter les Données' ><GetApp fontSize="small"/></Button> }>
              <ExcelSheet data={topClient} name="Top clients"  >
                  <ExcelColumn label="Client" value="client" />
                  <ExcelColumn label="Commune" value="commune"/>
                  <ExcelColumn label="commercial" value="commercial"/>
                  <ExcelColumn label="C.A." value="ca"/>
                  <ExcelColumn label='' value=''/><ExcelColumn label='' value=''/><ExcelColumn label=''value=''/><ExcelColumn label='' value=''/>
                  <ExcelColumn label="Client" value="client"/>
                  <ExcelColumn label="Commune" value="commune"/>
                  <ExcelColumn label="commercial" value="commercial"/>
                  <ExcelColumn label=" Marge" value="mben"/>
              </ExcelSheet>
              <ExcelSheet data={topCommune} name="Top des communes" >
                  <ExcelColumn label="Commune" value="commune"/>
                  <ExcelColumn label="C.A." value="ca"/>
                  <ExcelColumn label='' value=''/><ExcelColumn label='' value=''/><ExcelColumn label=''value=''/><ExcelColumn label='' value=''/>
                  <ExcelColumn label="Commune" value="commune"/>
                  <ExcelColumn label=" Marge" value="mben"/>
              </ExcelSheet>
              <ExcelSheet data={topCommercial} name="Top des commerciaux" >
                  <ExcelColumn label="Commercial" value="commercial"/>
                  <ExcelColumn label="C.A." value="ca"/>
                  <ExcelColumn label='' value=''/><ExcelColumn label='' value=''/><ExcelColumn label=''value=''/><ExcelColumn label='' value=''/>
                  <ExcelColumn label="Commercial" value="commercial"/>
                  <ExcelColumn label=" Marge" value="mben"/>
              </ExcelSheet>
              <ExcelSheet data={topCategorie} name="Top des catégories" >
                  <ExcelColumn label="Catégorie" value="categorie"/>
                  <ExcelColumn label="C.A." value="ca"/>
                  <ExcelColumn label='' value=''/><ExcelColumn label='' value=''/><ExcelColumn label=''value=''/><ExcelColumn label='' value=''/>
                  <ExcelColumn label="Catégorie" value="categorie"/>
                  <ExcelColumn label=" Marge" value="mben"/>
              </ExcelSheet>
              <ExcelSheet data={topProduit} name="Top des produits" >
                  <ExcelColumn label="Produit" value="produit"/>
                  <ExcelColumn label="C.A." value="ca"/>
                  <ExcelColumn label='' value=''/><ExcelColumn label='' value=''/><ExcelColumn label=''value=''/><ExcelColumn label='' value=''/>
                  <ExcelColumn label="Produit" value="produit"/>
                  <ExcelColumn label=" Marge" value="mben"/>
              </ExcelSheet>
              </ExcelFile>
              :<></>
              }
            </div >       
    </ValidatorForm>
 </fieldset>
 <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
 </Backdrop>
    {
      (topClient.length!==0)?
      <div className="row-tables">
        <div id={(cl>0)?'table-1':'tablee-1'}>
        <DataTable 
              persistTableHead
              title="Top clients par chiffre d'affaire"
              columns={columnsC}
              data={topClient}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              highlightOnHover
              onRowClicked={(row)=>setIdRow(row.client)}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
              conditionalRowStyles={conditionalRowStyles}

              />
        </div>
        <div id={(cl>0)?'table-2':'tablee-2'}>
        {
        (cl>0)?
        <DataTable 
              persistTableHead 
              title="Top clients par marge"
              columns={columnsB}
              data={topClient1}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              onRowClicked={(row)=>setIdRow(row.client)}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
              conditionalRowStyles={conditionalRowStyles}
              defaultSortField="row=>row.mben"
              defaultSortAsc={false}
              />:<></>
        }
        </div>
        </div>:<></>
    }
        
    {
      (topCommercial.length!==0)?
      <div className="row-tables" >
        <div id={(comr>0)?'table-1':'tablee-1'}>
        <DataTable 
              persistTableHead 
              title="Top commerciaux par chiffre d'affaire"
              columns={columnsCC}
              data={topCommercial}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              highlightOnHover
              onRowClicked={(row)=>setIdRow1(row.commercial)}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
              conditionalRowStyles={conditionalRowStyles1}

                              />
        </div>
        <div id={(comr>0)?'table-2':'tablee-2'}>
        {(comr>0)?
        <DataTable 
              persistTableHead 
              title="Top commerciaux par marge"
              columns={columnsCB}
              data={topCommercial1}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              onRowClicked={(row)=>setIdRow1(row.commercial)}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
              conditionalRowStyles={conditionalRowStyles1}
       />:<></>
        }
        </div>
     </div>:<></>
  }
  {   
    (topProduit.length!==0)?
    <div className="row-tables">
      <div id={(pr>0)?'table-1':'tablee-1'}>
        <DataTable
              persistTableHead 
              title="Top produits par chiffre d'affaire"
              columns={columnsCAP}
              data={topProduit}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              highlightOnHover
              onRowClicked={(row)=>setIdRow4(row.produit)}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
              conditionalRowStyles={conditionalRowStyles4}
         />
      </div>
      <div id={(pr>0)?'table-2':'tablee-2'}>
        { 
          (pr>0)?
          <DataTable 
              persistTableHead 
              title="Top produits par marge"
              columns={columnsCUP}
              data={topProduit1}
              defaultSortAsc
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              onRowClicked={(row)=>setIdRow4(row.produit)}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
              conditionalRowStyles={conditionalRowStyles4}
              

          />:<></>
         }
        </div>
    </div>:<></>
   }
  {   
    (topCategorie.length!==0)?
    <div className="row-tables">
      <div id={(caat>0)?'table-1':'tablee-1'}>
        <DataTable 
              persistTableHead 
              title="Top catégories par chiffre d'affaire"
              columns={columnsCAC}
              data={topCategorie}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              highlightOnHover
              onRowClicked={(row)=>setIdRow3(row.categorie)}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
              conditionalRowStyles={conditionalRowStyles3}
                              />
      </div>
      <div id={(caat>0)?'table-2':'tablee-2'}>
        { 
          (caat>0)?
          <DataTable 
              persistTableHead 
              title="Top catégories par marge"
              columns={columnsCAB}
              data={topCategorie1}
              defaultSortAsc
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              onRowClicked={(row)=>setIdRow3(row.categorie)}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
              conditionalRowStyles={conditionalRowStyles3}
              

          />:<></>
         }
        </div>
    </div>:<></>
   }   
  {
    (topCommune.length!==0)?
    <div className="row-tables">
        <div id={(comn>0)?'table-1':'tablee-1'}>
        <DataTable 
              persistTableHead 
              title="Top communes par chiffre d'affaire"
              columns={columnsCUC}
              data={topCommune}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              highlightOnHover
              onRowClicked={(row)=>setIdRow2(row.commune)}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
              conditionalRowStyles={conditionalRowStyles2}

                              />
        </div>
        <div id={(comn>0)?'table-2':'tablee-2'}>
       { 
         (comn>0)?
           <DataTable 
              persistTableHead 
              title="Top communes par marge"
              columns={columnsCUB}
              data={topCommune1}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              onRowClicked={(row)=>setIdRow2(row.commune)}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
              conditionalRowStyles={conditionalRowStyles2}
           />:<></>
        }
        </div>
    </div>:<></>
  }
  
   {(err===true)?<div><NoDataComponent message={message}/></div>:<></>}
    </div>   
  );
}