import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import TextField from '@material-ui/core/TextField';
import {CircularProgress} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import DraggableDialog from './modals/ach_trt_add'
import DraggableDialogImp from './modals/ach_trt_imprimer'
import {conditionalRowStyles,customStyles}from '../../css/datatable-custom'
import { useStyles } from '../../css/muiStyle';
import {AlertCompo,NoDataComponent,NotAllowedComponent} from "../../js/components"
import { Box } from "@mui/system";
import Autocomplete from '@mui/material/Autocomplete';
import './../../css/stock.css'
import './../../css/achat.css'

export default function Ach_trt(props){
  const param=props.param;
  const classes=useStyles()
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [usrs, setUsrs] = useState([]);
  const [stks, setStks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [records, setRecords] =useState('');
  const [usr, setUsr] =useState('');
  const [stk, setStk] =useState('');
  const [severity, setSeverity] =useState("info");
  const[noData,setNoData]=useState(false); 
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[doSearch,setDoSearch]=useState(false);
  const [called, setCalled] = useState(false);
  const service='_prd.php'
  
   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  const fetch= useCallback(async(page,val,stk,servic = service, size = perPage,value) => {
    setLoading(true);
    setCalled(true);
    const va=(val==='empty')?'':val
    const va1=(stk==='empty')?'':stk  
    const response=await librery.axiosGet2(page+'&search='+((value!==undefined)?value:'')+"&usr="+va+"&stk="+va1,service,'gettrans', size)
      if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else
      Gfunc.axiosResponse(response,()=>{
        setRecords(response.data.recordsFiltered)
        if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setDoSearch(true)
        setNoData(true)
        }else {setDoSearch(false);setData([])}
      })
    setLoading(false)
  },[Gfunc,param.urlService,perPage])

  //recuperer les utilisateurs
const fetchUsers= useCallback(async() => {
    const response = await Gfunc.axiosGetAction('_usr.php',"gets")
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData){
        response.data.jData.unshift({id:'empty',name:'Tous'})
        setUsrs(response.data.jData); 
      }
    })
},[Gfunc]);

const fetchStk= useCallback(async() => {
    const response =  await Gfunc.axiosGetAction('_stk.php',"gets")
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData){
        response.data.jData.unshift({id:'empty',nom:'Tous'})
        setStks(response.data.jData); 
      }
    })
},[Gfunc]);

  useEffect(() => {
    if(!called){  fetch(1,usr,stk);}
  }, [fetch,usr,stk]);
  
//remplir Select commercial
const handleOpenUser= useCallback(async () => {
    if(usrs.length===0)
       fetchUsers()
     },[usrs,fetchUsers])

//remplir Select stock
const handleOpenStk= useCallback(async () => {
    if(stks.length===0)
        fetchStk()
     },[stks,fetchStk])


  const columns =useMemo(
    () => [ 
      {
        name: "Date",
        selector: row=>row.date,
        sortable: true,
        width:'770px',
        sortField:"date",
      },  
      {
        name: "Bon",
        selector:row=>row.numero,
        sortable: true,
        width:'780px',
        sortField:"numero",
      },  
  ],[]);
  
 

  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort3(column,usr,sortdirection,page,size,service,'gettrans')
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      })  
  }

  const handleSearch=async( size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,'gettrans&stk='+stk+'&usr='+usr)
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
    return recherche
  }

  function handlePageChange (page) {
    fetch(page,usr,stk,service,perPage,search);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,usr,stk,service, newPerPage,search);
    setPerPage(newPerPage);
  };
  
  //Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }

  function liveSearch2() {
    handleSearch();
  }

  const handleChangeStk=(val)=>{
    fetch(1,usr,val,service,perPage,search)
  }
  const handleChangeUsr=(val)=>{
    fetch(1,val,stk,service,perPage,search)
  }


  const ExpanableComponent =({data}) => {
    const [det,setDet]=useState([]);
    const [loadingExp,setLoadingExp]=useState(false)

     //fetch comments of news
    const fetchDet= useCallback(async() => {
      setLoadingExp(true)
      const response=await Gfunc.axiosPostAction("_prd.php","gettransdet",{bonId:data.DT_RowId.substr(1)})
      setLoadingExp(false)
      Gfunc.axiosResponse(response,()=>{
        setDet(response.data.jData);
      })
    },[data])

   useEffect(() => {
    if(det.length===0){ fetchDet();}
   }, [fetchDet,det.length]);

  return (
       <div className="row-expenderr">
      {
            (loadingExp)?<div id="empty-expender"><CircularProgress /></div>
            :
            (det.length!==0)?
            <div  className="table-scroll">
            <table >
                <thead>
                <tr>
                  <th>Produit</th>
                  <th>Donneur</th>
                  <th>Qte.</th>
                  <th>Receveur</th>
                  <th>Agent</th>
                </tr>
                </thead>
                <tbody>
                  {
                    det.map((el,index)=>{
                      return(
                        <tr key={index}>
                          <td id="prod" style={{width:'650px'}}>{el.produit}</td>
                          <td style={{width:'200px'}}>{el.donneur}</td>
                          <td className="numbers" style={{width:'100px'}}>{el.qte}</td>
                          <td style={{width:'200px'}}>{el.receveur}</td>
                          <td style={{width:'160px'}}>{el.agent}</td>
                        </tr>
                        
                      )
                    })
                  }
             </tbody>
            </table>
        </div>:<div id='empty-expender'>Aucune donnée .</div> }
        <div id='print'> <DraggableDialogImp param={param} row={det} bon={data}/></div>
        <br/><br/>
       </div>
     )
    }
  return (

    <div className="content">
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <div className='table-header'>
        <div className='button-flex-wrapper' id='mtn-lst-exp'>
        <DraggableDialog param={param} handleAlert={handleAlert} callback={()=>fetch(1,usr,stk)} />
        <Autocomplete
            disableClearable
            onChange={(e,newValue) => {setStk(stks[Gfunc.getIndex(stks,newValue,"nom")].id);
                                       handleChangeStk(stks[Gfunc.getIndex(stks,newValue,"nom")].id)}}
            onFocus={handleOpenStk}
            options={stks.map((option) => option.nom)}
            className='smallAutoFiltre' 
            renderInput={(params) =>  
                <TextField {...params} margin='dense' size='small' variant="outlined" label="STOCK..."  />
              }
          />
        <Autocomplete
            disableClearable
            onChange={(e,newValue) =>{setUsr(usrs[Gfunc.getIndex(usrs,newValue,"name")].id);
                                      handleChangeUsr(usrs[Gfunc.getIndex(usrs,newValue,"name")].id);}}
            onFocus={handleOpenUser}
            options={usrs.map((option) =>option.name)}
            className='smallAutoFiltre' 
            renderInput={(params) =>  
                <TextField margin='dense' size='small'{...params} variant="outlined"  label="AGENT..." />
              }
          />
        </div>
          {(doSearch)?
            <div className='search' >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} id="input-with-sx" label="Recherche" value={search} variant="outlined"
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer" }} />),}}
                               size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
              </Box>
            </div>:null}
        </div>
        {(data.length!==0||doSearch)?
        <div id='exp-DT'>
          <DataTable 
              persistTableHead
              columns={columns}
              data={data}
              noHeader={true}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              paginationPerPage={perPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles}
              expandOnRowClicked
              expandableRows 
              expandableRowsComponent={ExpanableComponent}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
        /></div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
        
    </div>    
  );
}
