import './App.css';
import './css/global.css';
import React, {Component} from 'react';
import { Helmet } from 'react-helmet'
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import ErrorPage from './components/error';
import EmptyPage from './components/empty';
import Login from './components/login';
import Pages from './components/pages';
import "react-data-table-component";
import { ThemeProvider } from "@material-ui/core";
import {theme} from "./css/theme.js"
const Gfunc=require('./Gfunc')
const version=require('./version.json')

class App extends Component {
  state ={
    menu : {},
    mounted: false,
    loaded: false,
    isLogged:localStorage.getItem('isLoged'),
    param:null
  }
 
  //will be executed once the app is mounted 
  async componentDidMount(){
    var para=await Gfunc.getParams();
    if(localStorage.getItem('build')&&localStorage.getItem('build') !==version[0].build){
      localStorage.clear();
      window.location=("/");
      localStorage.setItem('build',version[0].build);
    }
    localStorage.setItem("param",JSON.stringify({urlService:para.urlService,appID:para.appID}))
    this.setState({
      mounted:true,
      param:para
    })
  }  
  static getDerivedStateFromProps(props, state) {
  //  if(state.isLogged)
   // localStorage.setItem('title',param.webApp+" "+version[0].build )
   return {pcscope: state.cscope};
 }

  //recuperer le fichier json de menu 
  getMenu=async()=> {
    try{  
      const Gfunc=require('./Gfunc')
      const data=[]
      const response=await Gfunc.axiosGetAction('getMenu.php',"")
      if(response.response&&response.response.status>400){
        localStorage.clear();
      }else
      Gfunc.axiosResponse(response,()=>{
        Object.keys(response.data).map((key, index)=> {
          if(response.data[key]!==null){
            data[key]=response.data[key]
            data.length++
          }
          return true
        })  
        this.setState({loaded:true, menu:data })
      }) 
    }
    catch(error){
        localStorage.clear();
        window.location=("/");
     } 
  }

 
  render(){
     // verify if the page is mounted then if it was loaded to getthe menu
    if(!this.state.loaded && this.state.mounted && this.state.isLogged==="true"){this.getMenu();Gfunc.getMag();}     
    if (this.state.mounted) {     
      return (
        <ThemeProvider theme={theme}>
          <Helmet>
          <title>{(this.state.isLogged!=='true')?this.state.param.webApp+" "+version[0].build:localStorage.getItem('title')}</title>
        </Helmet>
        <BrowserRouter > 
          <Switch>
              <Route path="/error"  component={props => <EmptyPage {...props} />}/>
              {this.state.isLogged!=='true'?<Route exact path="/"  component={props => <Login param={this.state.param} />}/>:<Pages menu={this.state.menu} param={this.state.param} />} 
              {(this.state.loaded&&this.state.isLogged==="true")?<Pages menu={this.state.menu} />:<Route component={props => <ErrorPage {...props} />}/>}
              
         </Switch>
        </BrowserRouter>
        </ThemeProvider>
      );     
    } else {
      return <></>  
    }
  }
}
export default App;