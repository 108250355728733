import React,{useState,useMemo,useCallback} from 'react';
import {DialogContent,Paper,Button,Dialog,IconButton,Typography, Tooltip} from '@material-ui/core';
import DataTable from "react-data-table-component";
import Draggable from 'react-draggable';
import {IndeterminateCheckBox,SupervisorAccount,Add,Close} from '@material-ui/icons';
import {axiosResponse,axiosPostAction,removeItemsByAtt} from'../../../Gfunc'
import Multiselect from 'multiselect-react-dropdown';
import { useStyles,multiSelect, paperPropsBigH } from '../../../css/muiStyle';
import {customStyles}from '../../../css/datatable-custom'
import {AlertCompo} from "../../../js/components"
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import { mdiFormatListGroup } from '@mdi/js';
import Icon from '@mdi/react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


//the dialog that adds users to a role**********
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Adm_rol_usr(props) {
    const classes=useStyles();
    const param=useContext(ParamContext);
    const [open, setOpen] = useState(false);
    const [option, setOption] = useState([]);
    const [data, setData] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [,setDel]=useState(-1);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    var users =[]
    const title="Les utilisateurs de "
    const serviceUsrs="_usr.php"
    const service="_rol.php"

    //fetch the users that have the selected role
    const fetch= useCallback(async() => {
      const dat={idr:props.row.DT_RowId.substr(1),unsets:false}
      const response=await axiosPostAction(service,"getrusrs",dat)
      if(response.data.err&&response.data.err!=='-1'){
        props.handleAlert(true,"error",response.data.err)
        setOpen(false)
        return false
      }else
         if(response.data.jData!==undefined)
          axiosResponse(response,()=>{
          setData(response.data.jData);
          return true
          })
    },[props])

    //fetch all users that doesn't have the selected role
    const fetchUsers=useCallback(async() => {
      const dat={idr:props.row.DT_RowId.substr(1),unsets:true}
      const response=await axiosPostAction(service,"getrusrs",dat)
            if(response.data.err&&response.data.err!=='-1'){
              props.handleAlert(true,"error",response.data.err)
              setOpen(false)
              return false
            }else
              if(response.data.jData!==undefined){
                axiosResponse(response,()=>{
                setOption(response.data.jData);
                return true
                })
              }
    },[props])

    //dialog functions************************
    const handleClickOpen = () => { 
      var op1=true
      var op2=true
      if(option.length===0)
      op1=fetchUsers();
      if(data.length===0)
      fetch(); 
      op2=setOpen(op1&&op2)
    };

    const handleClose = () => { setOpen(false); };

     //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

    //Multiselect functions********************
    function onSelect(selectedList, selectedItem) {
      users=selectedList;
      setSelectedValues(users)
    }
     //suppression des options
      function onRemove(selectedList, removedItem) {
       setSelectedValues(selectedList)
    }
  
  //Add users to role
  const handleAddUsr=useCallback(async() => {
     if(selectedValues.length>0){
      var idrss=[]
      selectedValues.map((opt)=>{
        idrss.push(opt.id) 
        return true 
      })
      const response= await axiosPostAction(service ,"setrusers",{ idr:props.row.DT_RowId.substr(1), idus:idrss})
        axiosResponse(response,()=>{
            handleAlert(true,"success",'Le role a été attribués avec succès aux utilisateurs sélectionnés.')
            selectedValues.map((item)=>{
              removeItemsByAtt(option,item.id,"id")
              data.push(item)
              return true;
            })
        }) 
        setSelectedValues([])}
      else handleAlert(true,"warning","Vous devez sélectionner au moins un élement!")
   },[data,option,selectedValues,service,props])
   
  
  //Delete users from role
  const handleDeleteUser=useCallback(async(row)=>{
    const response =await axiosPostAction(serviceUsrs,"delurole",{idu:row.id, idr:props.row.DT_RowId.substr(1)});
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
        axiosResponse(response,()=>{
          const objIndex = data.findIndex((obj => obj.id ===row.id )); 
          data.splice(objIndex,1)
          handleAlert(true,"success","L'utilisateur a été supprimé avec succès.")
        })
  },[serviceUsrs,props,data,axiosPostAction,axiosResponse]) 


  const columns =useMemo(
    () => [   
      {
          name: "UTILISATEURS",
          selector:row=> row.name,
          sortable: true,
          minWidth:'350px !important'
      },
      {
        right:true,
        cell: (row) => {
          return (
          <Tooltip title="Supprimer l'utilisateur">
            <div onClick={()=>handleDeleteUser(row)}>
              <IndeterminateCheckBox className={classes.redIcon} fontSize="small"/>
            </div> 
          </Tooltip>  
          );
         },
      }  
    ],[handleDeleteUser,classes]);
 
  return (
    <div>
        <Tooltip title="Ajouter / Supprimer utilisateur">
           <SupervisorAccount className={classes.blackIcon} onClick={handleClickOpen} label="Voir les utilisateurs"/>
        </Tooltip>
        <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsBigH}
        >
            <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
               <div className='DialogTitle'>
                    <Icon path={mdiFormatListGroup} size={1} />
                    <label>{title} <b>{props.row.role}</b></label>
                </div>
            </DialogTitle>
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent>
                <div id="fieldSet">
                {
                 (option.length!==0)?
                   <div className='flex-row'>
                        <Multiselect
                            style={multiSelect}
                            options={option} 
                            selectedValues={selectedValues}
                            onSelect={onSelect} 
                            onRemove={onRemove} 
                            emptyRecordMsg="Il n'y a plus d'options"
                            displayValue="name" 
                            placeholder="Selectioner les utilisateurs"
                            closeOnSelect={false}
                            hidePlaceholder={true}
                        />
                    <Button className={classes.PrintBtn} variant="contained" onClick={handleAddUsr} color="primary" startIcon={<Add/>}>
                            AJOUTER</Button>
                    </div>:<></>
              }
              </div>
              <div >   
                <DataTable 
                  key={Math.floor(Math.random() * (1000 - 1 + 1)) + 1}
                  className="table"
                  persistTableHead
                  columns={columns}
                  data={data}
                  noHeader={true}
                  customStyles={customStyles}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  pagination
                  paginationRowsPerPageOptions={[5,10,50,100]}
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
               />
              </div> 
            </DialogContent>
            </Dialog>
      </div>
  );
}
