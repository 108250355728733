import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {Add,Close} from '@material-ui/icons';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_sim_add(props) {
    const [tel,setTel]=useState('');
    const [note,setNote]=useState('');
    const [op,setOp]=useState('');
    const [prod,setprod]=useState('');
    const [errorP, setErrorP] =useState(false);
    const [errorO, setErrorO] =useState(false);
    const [errorN, setErrorN] =useState(false);
    const [open, setOpen] =useState(false);
    const classes=useStyles();
    const service="_sim.php"
    
    const handleClickOpen = () => { 
      setOpen(true);setTel('');setOp('');setprod('');setNote('')
    }
    const handleClose = () => { setOpen(false); };
    
    const handleSubmit= async(e) =>{
      if(prod!==''){
        const objIndex =  props.prod.findIndex((obj => obj.nom ===prod ));
        var produit=props.prod[objIndex].id
      }
        const objIndex1 =  props.op.findIndex((obj => obj.nom ===op ));
        var operateur=props.op[objIndex1].id
        const response=await axiosPostAction(service,"add",{numero:tel,operatorId:operateur,produiId:produit,note:note})
        if(response.data.err&&response.data.err!=='-1'){
         props.handleAlert(true,"error",response.data.err)
        }else
        axiosResponse(response,()=>{
                if (response.data.jData){
                  props.handleAlert(true,"success","Une nouvelle sim a été ajouté avec succès  ")
                    props.callback();
                    setOpen(false);
                } 
        })
    }
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Add/>}>NOUVELLE SIM</Button>
      <Dialog 
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
       >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
           <div className='DialogTitle'>
              <Icon path={mdiPlusBox} size={1} />
              <label> Ajouter une nouvelle sim</label>
            </div>
        </DialogTitle>
        <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
            <DialogContent>
              <div id='row-sim'> 
                <TextField  error={errorN} helperText={(errorN)?"Veuillez sélectionner un numéro":null} 
                            label='Numéro' id="inum" name="inum" value={tel}  className='smallText' margin='dense' variant='outlined'
                            onChange={(e)=>{setTel(e.target.value);setErrorN(false)}} required
                            inputProps={{ pattern: "[0][1-7][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]",maxLength: 10 }}/>   
                <Autocomplete
                     disableClearable
                     className='smallAuto' 
                     id="iSim"
                     onChange={(e,newValue) =>{setOp(newValue);setErrorO(false)}} 
                     onFocus={props.handleOpenOp}
                     options={props.op.map((option) => option.nom)}
                     renderInput={(params) => (
                        <TextField  error={errorO} {...params} label="Opérateur" size="small" 
                                    margin='dense' variant='outlined' helperText={(errorO)?"Veuillez sélectionner une classe":null}  required/>
                                )} />
              </div>                  
                <Autocomplete
                    disableClearable
                    className='smallAuto' 
                    id="iProd"
                    onChange={(e,newValue) =>{setprod(newValue);setErrorP(false)}} 
                    onFocus={props.handleOpenProd}
                    options={props.prod.map((option) => option.nom)}
                    renderInput={(params) => (
                        <TextField error={errorP} {...params} label="Produit"   size="small"
                                   margin='dense' variant='outlined' helperText={(errorP)?"Veuillez sélectionner une classe":null} />
                                )} />                  
                <TextField label='Note' id="iNote" name="iNote" value={note}  className='smallNote' margin='dense' variant='outlined'
                           onChange={(e)=>{setNote(e.target.value);}} multiline
                           minRows={3}
                                    /> 
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type='submit' color="primary" startIcon={<Add />}>Ajouter</Button>
            </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
