import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable  from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import {CircularProgress, Box, Tooltip} from '@material-ui/core';
import {NoDataComponent,NotAllowedComponent,AlertCompo} from "../../js/components"
import {Search,Lock,LockOpen} from '@material-ui/icons';
import DraggableDialog from './modals/ref_chg_add'
import { useStyles } from '../../css/muiStyle';
import {conditionalRowStyles,customStyles, iconCell}from '../../css/datatable-custom'
import '../../css/referentiel.css'


export default function Ref_tch (props){
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [,setIcon]=useState('');
  const[noData,setNoData]=useState(false);
  const[doSearch,setDoSearch]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const[,setTest]=useState(false);
  const[focus,setFocus]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[records,setRecords]=useState('');
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const service='_chg.php'
  const srv="charge"
  const classes=useStyles();

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  const fetch= useCallback(async(page,servic = service, size = perPage) => {
    setFocus(true)
    setLoading(true);
    const response=await librery.axiosGet(page,servic,size)
      if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else{
        Gfunc.axiosResponse(response,()=>{
            setRecords(response.data.recordsFiltered)
            if(response.data.data!==undefined && response.data.data.length!==0){
            setData(response.data.data);
            setTotalRows(response.data.recordsFiltered);
            setDoSearch(true)
            setNoData(true)
        }else {setDoSearch(false);setData([])}
        })
      } 
      setLoading(false)
  },[Gfunc,perPage,librery]);

  useEffect(() => {
   if(!focus){ fetch(1);}
    Gfunc.loseFocus(".content-editable")

  }, [fetch,focus,Gfunc]);
  
const handleTest=(val)=>{
  setTest(val)
}

 const flagMan = useCallback(async(e,row,att,attr)=>{
    const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: row.DT_RowId.substr(1),att:att, val:(row[att] ===true)?1:0}))
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        if(row[att]===true) {
          row[att]=false
        }else{
          row[att]=true
        }
          setTest(true)
          handleTest(false)
  
              })
    }  
 },[Gfunc])

//update field
const handleOnEdit=useCallback(async(e,row,att)=>{
  const edt1=e.target.innerHTML
  const edt=Gfunc.DeleteSpace(edt1);
      if(row[att]!==edt){
        const attrib=row[att];
        const response=await librery.axiosEdit(service,row,edt,att) 
        if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
        }
        if(response.data.err) {e.target.innerHTML=attrib}
      }else {e.target.innerHTML=edt}
},[librery,Gfunc]);

const handleLockIcon=(val)=>{
  setIcon(val);
}
useEffect((val) => {
handleLockIcon(val);
}, []);
const columns =useMemo(
    () => [ 
      {
          name: "Charge",
          selector:row=>row.nom,
          sortable: true,
          sortField:'nom',
          cell: (row) => {
            return (
                <ContentEditable
                html={row.nom}
                name="charge"
                data-column="item"
                className="content-editable"
                disabled={false} 
                onBlur={e => handleOnEdit(e,row,"nom")}
                />
             );
           },
      },
      {
        name: "Date",
        selector:row=> row.date,
        sortable: true,
        sortField:'date',
      },
      {
        name: "Par",
        selector:row=> row.agent,
        sortable: true,
        sortField:'agent',
      },
      {
        width:"35px",
        style:iconCell,
        cell: (row) => {
          return (
            <Tooltip title="Activer / Désactiver">
              <div onClick={e=>flagMan(e,row,'activ','activ')} title="Activer/Desactiver">
                {(row.activ)?<LockOpen className={classes.redIcon} fontSize="small"/>:<Lock className={classes.greenIcon} fontSize="small"/> }
              </div> 
            </Tooltip>       
            );
         },
      }  
    ],[flagMan,handleOnEdit,classes]);
 
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,'get')
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          setData(response.data.data);
          setTotalRows(response.data.recordsFiltered);
          setLoading(false);
        })   
    }  
  }

   //recherche serverside
   const handleSearch=async(size = perPage)=>{
    setLoading(true);
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,"get")
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        }else setData([])
      })   
    }
    setLoading(false);
    return recherche
  }

function handlePageChange (page) {
  fetch(page,service,perPage);
  setCurrentPage(page);
};
 const handlePerRowsChange = async (newPerPage, page) => {
  fetch(page,service, newPerPage);
  setPerPage(newPerPage);
};

//Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
}
function liveSearch2() {
  handleSearch();
}
  
  return (
    <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <div className='table-header'>
          <div className='button-flex-wrapper'><DraggableDialog callback={()=>fetch(1)} handleAlert={handleAlert}/></div>
          {(doSearch)?
             <div className='search'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined" label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer" }} />),}}
                               size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
              </Box>
            </div>:<></>
          }
        </div>
        {(data.length!==0||doSearch)?
        <div>
        <DataTable className="table"
            persistTableHead
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              noHeader={true}
              highlightOnHover
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }

            />
        </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
            
    </div>    

  );
}