import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField,FormControlLabel,Checkbox,Box,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {Add,Close} from '@material-ui/icons';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles, paperPropsSmallF } from '../../../css/muiStyle';
import { Autocomplete } from '@material-ui/lab';
import {AlertCompo} from '../../../js/components'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Iconn from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//add new categorie
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_cat_add(props) {
    const classes=useStyles();
    const mdi=require("@mdi/js")
    const Gfunc=require('../../../Gfunc')
    const [name,setName]=useState('');
    const [open, setOpen] = useState(false);
    const [stkL, setStkL] = useState([]);
    const [stk, setStk] = useState('');
    const [note, setNote] = useState('');
    const [icon, setIcon] = useState('');
    const [checked,setchecked]=useState(false);
    const [iconLl, setIconLl] = useState([]);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const service="_cat.php"


    //handle alerts properties
    const handleAlert=(open,severity,message)=>{
      setOpenA(open)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
     
   const handleClickOpen = () => { 
      setName('');setNote('');setStk(localStorage.getItem('stock'))
      setOpen(true); 
      handleOpenStk();setIcon('');setIconLl([]);setchecked(false)
    };
   const handleClose = () => { setOpen(false); };

    //fetch stocks
   const fetchStk= useCallback(async() => {
      const response = await Gfunc.axiosGetAction('_stk.php',"gets")
      if(response.data.err){
         handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
          setStkL(response.data.jData);   
      })
    },[Gfunc]);
    
     //fetch marque handler
  const handleOpenStk = useCallback(async () => {
    if(stkL.length===0)
    fetchStk()
    },[stkL,fetchStk])

    const handleSubmit= async(e) =>{
        e.preventDefault();
        const stockS=stkL[Gfunc.getIndex(stkL,stk,"nom")].id
        const response=await axiosPostAction(service,"add",{name:name,note:note,onTop:checked,stock:stockS,icon:icon})
        if(response.data.err&&response.data.err!=="-1"){
            handleAlert(true,'error',response.data.err)
            setOpen(false)
        }else{
        axiosResponse(response,()=>{
            setOpen(false);
            props.handleAlert(true,'success',"La catégorie est ajoutée avec succès")
            props.callback();
        })
      }
    }
  
  const handleChange=(e)=>{
      setchecked(!checked)
    }

  //fetch categorie
  const fetchIcon= useCallback(async(serv="_cat.php", act = "geticons") => {
    const dat=[];var datt=[]
   const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
    }else{
     Gfunc.axiosResponse(response,()=>{
       Object.keys(response.data.jData).map((key, index)=> {
         if(response.data.jData!==null){
           var nom=(response.data.jData[key]==='mdiSignal4G')?'mdiSignal4g':(response.data.jData[key]==='mdiTabletIpad')?"mdiTablet":response.data.jData[key]
           dat.push({id:key,nom:nom})
           datt.push(response.data.jData)
         }
         return true
       })  
       setIconLl(dat)
    })
    } 
 },[Gfunc])
      //fetch icon handler
const handleOpenIcon = useCallback(async () => {
  if(iconLl.length===0)
    fetchIcon()
  },[iconLl,fetchIcon]) 

  const Icon=(props)=>{
      const mdiP=mdi[props.row]
      return(
       <Iconn path={mdiP} size="17px" color="black"/>
      );
}

  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} 
              startIcon={<Add/>}>Nouvelle CATÉGORIE</Button>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallF}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
            <div className='DialogTitle'>
              <Iconn path={mdiPlusBox} size={1} />
              <label>Ajouter une nouvelle catégorie</label>
            </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true} />
        <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
            <DialogContent id='cat-dialog'>
              <TextField className='smallText' variant='outlined' size="small" label='Nom'  value={name} margin='dense'
                         onChange={e =>setName(e.target.value)} fullWidth autoFocus required />
              <Autocomplete
                   disableClearable
                   onChange={(e,newValue) =>{setStk(newValue)}}
                   value={stk}
                   options={stkL.map((option) => option.nom)}
                   className='smallAuto' 
                   renderInput={(params) =>  
                        <TextField margin='dense' 
                              inputlabelprops={{className:(stkL)?undefined:classes.dataLabel}}
                              // error={errorP}
                              size='small'{...params} variant="outlined"  label="Stock" 
                              SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                          />}
              />
             <div id='cat-div-icons'>
              <FormControlLabel control={<Checkbox checked={checked} onChange={e=>handleChange(e)} name="checked" color="primary"/>   }
                    label="Afficher sur le comptoire"
                 />
              <Autocomplete
                   disableClearable
                   onFocus={handleOpenIcon}
                   onChange={(e,newValue) =>{setIcon(newValue.nom)}}
                   //value={icon}
                   options={iconLl}
                   getOptionLabel={(option) => option.nom}
                   renderOption={(props, option) => (
                   <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} style={{ margin:'auto' }}>
                      <Icon row={props.nom} />
                   </Box>
                    )}
                   className='smallAuto' 
                   renderInput={(params) =>  
                        <TextField margin='dense' 
                              inputlabelprops={{className:(stkL)?undefined:classes.dataLabel}}
                              // error={errorP}
                              size='small'{...params} variant="outlined"  label="Icon" 
                              SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                          />}
                  />
              </div>
              <TextField size="small" label='Note' className='smallNote' margin="dense" id="iNote" name="iNote"  value={note}
                        onChange={e =>setNote(e.target.value)} 
                        placeholder="Insérer une note"
                        multiline
                        variant="outlined"
                        rows={2}
                         />             
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type='submit' color="primary" startIcon={<Add/>}>Ajouter</Button>
            </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
