import React,{useState,useCallback,useMemo} from 'react';
import {DialogContent,Paper,Dialog,FormControl,TextField,MenuItem,ClickAwayListener,IconButton,Typography, Tooltip} from '@material-ui/core';
import Draggable from 'react-draggable';
import DataTable from "react-data-table-component";
import {CalendarToday, Category, Person, YoutubeSearchedFor,Close} from '@material-ui/icons';
import  {useStyles,paperProps2} from '../../../css/muiStyle';
import  {customStyles} from '../../../css/datatable-custom';
import  {Detail_tiket,Detail_Bon} from '../../../js/components';
import  '../../../css/retour.css';
import $ from 'jquery'
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import {ReactComponent as Produit} from '../../../img/icons/Produit.svg'
import Icon from '@mdi/react';
import { mdiAbugidaThai,mdiArrowDownLeftBold,mdiClipboardAccount } from '@mdi/js';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';


const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ret_rec_suiv(props) {
    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const librery=require('../../../js/data_table_librery.js')
    const [detail,setDetail]=useState([]);
    const [data,setData]=useState([]);
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [Item, setItem] = useState('');
    const [ItemN, setItemN] = useState('');
    const [selecto, setSelecto] = useState('');
    const service="_ret.php"
  
    $(function() {
      $("div").removeAttr("data-tag")
    }) 

    const handleClickOpen = () => { 
      fetchDetail()
      setOpen(true); 
      setSelecto('')
    };
    const handleClose = () => { setOpen(false); };
  
    const fetchDetail= useCallback(async() => {
      const response = await Gfunc.axiosPostAction(service,"getdet",{idr:props.idr})
      Gfunc.axiosResponse(response,()=>{
          setDetail(response.data.jData); 
          setData(response.data.jData.actions)
         // if(response.data.jData.actions[0].tiket_num) setTicket(true)
      })
    },[Gfunc,props.idr]);

    //handle select items update providing event, row, Attribute's id, attribute's value ,the choices list(a state), it's set function 
  const handleOnEditSelect=useCallback(async(e,row,attId,attV,list,attList,f=()=>{},idLine,action)=>{
    if(row[attId]!==e.target.value){
      const edt=e.target.value
      const attrib=row[attId];
      const response=await librery.axiosEditId(service,action,"c"+row[idLine],edt,attId) 
      Gfunc.axiosResponse(response,()=>{
        const objIndex =list.findIndex((obj => obj.id === edt));
        row[attV]=list[objIndex][attList]
        row[attId]=edt
        setItem("");
        setItemN("");
        f();
        setSelecto('')
        if(props.from==='frs'){
        fetchDetail()
        }
      })
      
      if(response.data.err) {setItem("");setItemN(""); row[attId]=attrib;}
   }
},[Gfunc,librery])

//modifier un champs avec un select
const handleSelect=useCallback((row,att,f=()=>{})=>{
  f()
  setSelecto(row[att])
  setItem(row.ida)
  setItemN(att)
},[])

    const columns = useMemo(()=>[   
          {
              name: "Date",
              selector: row=>row.date,
              sortable: true,
          },
          {
              name: "Action",
              selector: row=>row.action,
              sortable: true,
          },
          {
              name: "Agent",
              selector: row=>row.agent,
              sortable: true,
              cell: (row) => { 
                return (
                  <div>
                      {(row.actionId===2||row.actionId===7)?
                      <div>
                  { (row.agentId===selecto && row.ida===Item && ItemN==="agentId")?
                   <ClickAwayListener onClickAway={()=>setItem('')}>
                      <FormControl  onClick={(e) => e.stopPropagation()}>
                        <TextField
                          className={classes.selectEdit}
                          inputProps={{className:classes.inputEdit,onBlur:()=>setSelecto('')}}
                          select
                          variant="outlined"
                          size="small"
                          value={selecto}
                          //onFocus={()=>props.handleOpenUser()}
                          //onChange={(e)=>{setSelecto(e.target.value);setChange(true)}}>
                          onChange={(e)=>{handleOnEditSelect(e,row,"agentId","agent",props.userL,"name",setSelecto(e.target.value),"ida",'updact')}}>
                              <MenuItem value={row.agentId}><em>{row.agent}</em></MenuItem>
                                {props.userL.map((usr,index)=>{
                                return <MenuItem key={index} value={usr.id}>{usr.name}</MenuItem>})}
                        </TextField>
                    </FormControl>
                    </ClickAwayListener>:
                    <div className="content-editable" onClick={()=>{handleSelect(row,'agentId',props.handleOpenUser())}} >{row.agent}</div>}
                    </div>:<div>{row.agent}</div>}
                </div>
                 );
            },
          },
          {
              name: "Fournisseur",
              selector: row=>row.frns,
              sortable: true,
              cell: (row) => { 
                  return (
                    <div>
                { (row.frnsId===selecto && row.ida===Item && ItemN==="frnsId"&&props.from==='frs'&&row.actionId===4)?
                    <FormControl  onClick={(e) => e.stopPropagation()}>
                      <TextField
                        className={classes.selectEdit}
                        inputProps={{className:classes.inputEdit,onBlur:()=>setSelecto('')}}
                        select
                        variant="outlined"
                        size="small"
                        value={selecto}
                        onMouseOver={()=>props.handleOpenFrns()}
                        onChange={(e)=>{handleOnEditSelect(e,row,"frnsId","frns",props.frnsL,"name",setSelecto(e.target.value),"ida","updact")}}>
                            <MenuItem value={row.frnsId}><em>{row.frns}</em></MenuItem>
                              {props.frnsL.map((usr,index)=>{
                              return <MenuItem key={index} value={usr.id}>{usr.name}</MenuItem>})}
                      </TextField>
                  </FormControl>:
                  <div className="content-editable" onClick={()=>{handleSelect(row,'frnsId')}} >{row.frns}</div>}
                  </div>
                  );
            },
          },
          {
              name: "Référence",
              selector: row=>row.tiket_num,
              sortable: true,
              cell:(row)=>{
                   
                  return(
                  <div>  
                      {(row.tiket_num)?<Detail_tiket idt={row.tiket_id} num={row.tiket_num} />:null}  
                      {(row.bon_num /*&& row.bon_num.indexOf("/TR")===-1*/)?<Detail_Bon mag={props.mag} handleFetchMag={props.handleFetchMag} row={row}/>:null}
                  </div>
                  )
              }
          },
        ],[handleSelect,handleOnEditSelect,detail,props,selecto,Item,ItemN])

  return (
    <div>
      <Tooltip title="Suivre">
        <YoutubeSearchedFor className={classes.blackIcon} fontSize="small" onClick={handleClickOpen}/>
      </Tooltip>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps2}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
          <div style={{display:"flex",gridGap:"10px",alignItems:"center"}}>
            <Icon path={mdiArrowDownLeftBold} size={1} />
            <label>Suivi d'un retour</label> 
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogueContent}>
                <div id="fieldSet">
                  <div id='ret-det-info'>
                    <div id='item'>
                        <Produit style={{height:'20px',width:'20px'}}/>
                        <b>Nom du produit :</b>
                        <p> {(detail.produit)?detail.produit:'---'}</p>
                    </div>
                    <div id='item'>
                       <Icon path={mdiClipboardAccount} size={0.8} />
                        <b>Client :</b>
                        <p> {(detail.client)?detail.client:'---'}</p>
                    </div>
                    <div id='item'>
                        <Category style={{height:'20px',width:'20px'}}/>
                        <b>Type :</b>
                        <p> {(detail.type)?detail.type:'---'}</p>
                    </div>
                  </div>
                  <div id='ret-det-info'>
                    <div id='item'>
                        <Icon path={mdiAbugidaThai} size={0.8} />
                        <b>№ série :</b>
                        <p> {(detail.serie)?detail.serie:'---'}</p>
                    </div>
                    <div id='item'>
                        <CalendarToday style={{height:'20px',width:'20px'}}/>
                        <b>Date :</b>
                        <p> {(detail.date_retour)?detail.date_retour:'---'}</p>
                    </div>
                    <div id='item'>
                        <Person style={{height:'20px',width:'20px'}}/>
                        <b>Agent :</b>
                        <p> {(detail.agent)?detail.agent:'---'}</p>
                    </div>
                  </div>
                </div>
                <DataTable 
                  id="suiver"
                  columns={columns}
                  data={data}
                  noHeader={true}
                  customStyles={customStyles}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
               />
           </DialogContent>
      </Dialog>
      </div>
  );
}
