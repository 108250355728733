import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,FormControl,MenuItem,TextField,FormControlLabel,Checkbox,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {Add,Close} from '@material-ui/icons';
import { useStyles,paperPropsBig } from '../../../css/muiStyle';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import "../../../css/referentiel.css"
import { AlertCompo } from '../../../js/components';
import { Autocomplete } from '@material-ui/lab';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_prd_add(props) {
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] =useState(false);
    const [prod,setProd]=useState('');
    const [cat,setCat]=useState('');
    const [catN,setCatN]=useState('');
    const [mrq,setMrq]=useState('');
    const [mrqN,setMrqN]=useState('');
    const [qte,setQte]=useState('');
    const [qteD,setQteD]=useState('');
    const [note,setNote]=useState('');
    const [achat,setAchat]=useState(0);
    const [vente1,setVente1]=useState(0);
    const [catL,setCatL]=useState([]);
    const [mrqL,setMrqL]=useState([]);
    const [msr,setMsr]=useState('');
    const [msrL,setMsrL]=useState([]);
    const [mth,setMth]=useState('null');
    const [cb,setCb]=useState('');
    const [limit,setLimit]=useState('');
    const [calML,setCalML]=useState([]);
    const [checkedA,setcheckedA]=useState(false);
    const [checkedB,setcheckedB]=useState(true);
    const [selectedDate, setSelectedDate] = useState(null);
    const sexeL=[{value:0,name:'Femme'},{value:1,name:'Homme'}]
    const [sexe, setSexe] = useState('null');
    const [stk,setStk]=useState('');
    const [stkN,setStkN]=useState('');

    const classes=useStyles();
    const [openA, setOpenA] =useState(false);
    const [message, setMessage] =useState('');
    const [severity, setSeverity] =useState('info');

    const addTitle="Ajouter un nouveau produit"
    const labelText1="Nom"
    const labelText2="Categorie"
    const labelText3="Sexe"
    const labelText4="Mesure"
    const labelText5="Marque"
    const labelText6="Qte. par defaut"
    const labelText7="Note"

    const labelText8="Prix d'achat"
    const labelText9="Prix de vente"
    const labelText10="Stock"
    const labelText11="Qte."
    const labelText12="Péremption"
    const labelText13="Calcul"
    const labelText14="Code à barres"
    const labelText15="Limite"

    const checkLabel1="Afficher sur le comptoire"
    const checkLabel2="Liste d'achat automatique"
    const service=(props.open!=='prod')?'_ach.php':"_prd.php" 
    const action=(props.open!=='prod')?"addprod":'add'
     //handle alerts properties
   const handleAlert=(openA,severity,message)=>{
    setOpenA(openA)
    setSeverity(severity)
    setMessage(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

    //open dialogue
    const handleClickOpen = () => { 
      props.fetchProd()
      if(props.from==='prd'){props.handleOpenCat()}else{handleOpenCat()}
      if(props.from==='prd')props.handleOpenMrq(); else handleOpenMrq();
      if(props.open!=='prod') props.handleOpenStk();
      getCalMethods();
      setOpen(true); 
      getMesure();
      setProd('');
      setCat('');
      setCatN('');
      setMrq('');
      setMrqN('');
      setQte(1);
      setSexe("null");
      setLimit('')
      setMsr("1");
      setcheckedA(false)
      setcheckedB(true)
      setNote('')
      setAchat('');
      setVente1('');
      setMth('null')
      setSelectedDate(null)
      setQteD('')
      setCb('')
      if(JSON.parse(localStorage.getItem('stockactiv'))){
        setStk(localStorage.getItem('stockId'))
        setStkN(localStorage.getItem('stock'))
      }else{
        setStk('')
        setStkN('')
      }  
      setProd(props.prodL)
    };

    //close dialogue
    const handleClose = () => { setOpen(false); };

    //handle date change 
   const handleDateChange = (date) => {
    setSelectedDate(date);}

  /*const handleReset= async(e) =>{
        setProd('');
        setCat('');
        setMrq(null);
        setAchat(0);
        setVente1(0);
    }*/
    
  const handleSubmit= async(e) =>{
       const kh=Gfunc.formatDate(new Date(selectedDate))
       if(parseFloat(achat.replace(/,/g, '.'))<=parseFloat(vente1.replace(/,/g, '.'))){ const data=(props.open!=='prod')?{
          idb:JSON.parse(props.idb),
          stockId:stk,
          qte:(qte==='')?null:JSON.parse(qte),
          name:prod,
          marqueId:(mrq==='')?null:mrq,
          categorieId:cat,
          mesureId:JSON.parse(msr),
          sex:JSON.parse(sexe),
          methodeId:JSON.parse(mth),
          limite:(limit==='')?null:JSON.parse(limit),
          defQte:(qteD==='')?null:JSON.parse(qteD),
          onTop:(checkedA===false)?null:checkedA,
          autoList:(checkedB==='')?null:checkedB,
          achat:parseFloat(achat.replace(/,/g, '.')),
          vente:parseFloat(vente1.replace(/,/g, '.')),
          cb:(cb==='')?null:cb,
          note:(note==='')?null:note,
          dat_per:(selectedDate!==null)?Gfunc.formatDate(new Date(selectedDate)):null,
        }:{
          name:prod,
          marqueId:(mrq==='')?null:mrq,
          categorieId:cat,
          mesureId:JSON.parse(msr),
          sex:JSON.parse(sexe),
          methodeId:JSON.parse(mth),
          limite:(limit==='')?null:JSON.parse(limit),
          defQte:(qteD==='')?null:JSON.parse(qteD),
          onTop:(checkedA===false)?null:checkedA,
          autoList:(checkedB==='')?null:checkedB,
          achat:parseFloat(achat),
          vente:parseFloat(vente1),
          cb:(cb==='')?null:cb,
          note:(note==='')?null:note,
          dat_per:(selectedDate!==null)?Gfunc.formatDate(new Date(selectedDate)):null,
        }
        const respon=await Gfunc.axiosPostAction(service,action,data)
        if(respon.data.err&&respon.data.err!=='-1'){
          handleAlert(true,'error',respon.data.err)
        }else
        Gfunc.axiosResponse(respon,()=>{
            props.callback()
            props.handleAlert(true,'success',"Le produit est ajouté.")
            if(props.open!=='prod'){
              Gfunc.updateElem("c"+props.idb,respon.data.jData['mtnBon'])
            }
            setOpen(false)
        })}else{
          handleAlert(true,'error',"Le prix de vente doit être supérieur à celui d'achat!")
        }
      }

  //fetch categorie
const fetchCat= useCallback(async(serv="_cat.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    Gfunc.axiosResponse(response,()=>{
        setCatL(response.data.jData);    
    })
  },[Gfunc])
    //fetch fournisseur handler
const handleOpenCat = useCallback(async () => {
    if(catL.length===0)
    fetchCat()
    },[catL,fetchCat])

  //fetch marques
const fetchMrq= useCallback(async(serv="_mrq.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    Gfunc.axiosResponse(response,()=>{
        setMrqL(response.data.jData);    
    })
  },[Gfunc])

    //fetch marques handler
const handleOpenMrq = useCallback(async () => {
    if(mrqL.length===0)
    fetchMrq()
    },[mrqL,fetchMrq])

    //get Mesures
  const getMesure= useCallback(async() => {
    if(Gfunc.isEmpty(msrL)){
    const response = await Gfunc.axiosGetAction('_prd.php',"getmsr")
    if(response.data.err){
      props.handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!=null)
        setMsrL(response.data.jData);   
    })}
  },[Gfunc,props,msrL]);
  
    //get calculation methods
  const getCalMethods= useCallback(async() => {
    if(Gfunc.isEmpty(calML)){
    const response = await Gfunc.axiosGetAction('_prd.php',"getmth")
    if(response.data.err){
      props.handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!=null)
        setCalML(response.data.jData);   
    })}
  },[Gfunc,calML,props]);
  // handle affichage checkbox
  const handleChangeA=(e)=>{
    setcheckedA(!checkedA)
  }
  // handle listAchat checkbox
  const handleChangeB=(e)=>{
    setcheckedB(!checkedB)
  }

  const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="iCb")) {
          handleAlert(true,'error',"Veuillez remplir tous les champs!")
      }
  }

  return (
    <div style={(props.open!=='prod')?{textAlign:'center'}:null}>
      <Button className={(props.open!=='prod')?classes.smallBtn:classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Add/>}>Nouveau produit </Button>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsBig}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
          <div className='DialogTitle'>
              <Icon path={mdiPlusBox} size={1} />
              <label>{addTitle}</label>
            </div>
        </DialogTitle>
        <DialogContent className={classes.dialogueContent}>
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
               <ValidatorForm id="validate" noValidate ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)} onError={errors => console.log(errors)} >
               <fieldset className="fieldsetTop2" id="prod">
               <legend>Produit:</legend>
                 <div className="rowGrid" id="row-1">   
                       <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iProd"
                                freeSolo
                                onChange={(e,newValue) =>{setProd(newValue)}} 
                                //value={prod}
                                options={props.prodL.map((option) => option.nom)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText1}
                                    variant="outlined"
                                    margin="dense"
                                    //className={classes.diagFormText}
                                    inputProps={{ ...params.inputProps, minLength:3}}
                                    size="small"
                                    value={prod} 
                                    onChange={e=>{setProd(e.target.value)}}
                                    autoFocus 
                                    required/>
                                )} />
                        <Autocomplete
                                disableClearable
                                className='smallAuto' 
                                id="iCat"
                                onChange={(e,newValue) =>{
                                    setCat(catL[Gfunc.getIndex(catL,newValue,"nom")].id)
                                    setCatN(newValue)}
                                  } 
                                value={catN}
                                options={catL.map((option) => option.nom)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText2}
                                    variant='outlined'
                                    margin='dense'
                                    size="small" 
                                    required/>
                                )} />
                    <FormControl margin="dense"  className='smallText' onClick={(e) => e.stopPropagation()}>
                            <TextField
                            select
                            id="iSexe"
                            size="small"
                            variant="outlined"
                            name="iSexe"
                            value={sexe}
                            label={labelText3}
                            onChange={(e)=>setSexe(e.target.value)}
                           >
                            <MenuItem  value={"null"}>Unisexe</MenuItem>
                            {
                            sexeL.map((item,index)=>{
                                return <MenuItem key={index}  value={item.value}>{item.name}</MenuItem>
                            })}
                            </TextField>
                    </FormControl>
                  </div>
                  <div className="rowGrid" id="row-2"> 
                    <FormControl margin="dense"  className='smallText' onClick={(e) => e.stopPropagation()}>
                            <TextField
                            select
                            id="iMsr"
                            size="small"
                            variant="outlined"
                            name="iMsr"
                            value={msr}
                            label={labelText4}
                            onChange={(e)=>setMsr(e.target.value)}
                            >
                            {
                            Object.keys(msrL).map((key, index)=>{
                                return <MenuItem key={index}  value={key}>{msrL[key]}</MenuItem>
                            })}
                            </TextField>
                    </FormControl>
                    <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iMrq"
                                onChange={(e,newValue) =>{
                                    setMrq(mrqL[Gfunc.getIndex(mrqL,newValue,"name")].id);
                                    setMrqN(newValue)}
                                  } 
                                value={mrqN}
                                options={mrqL.map((option) => option.name)}
                                renderInput={(params) => (
                                  <TextField {...params} label={labelText5} variant='outlined' margin='dense' size="small"  required/>
                                )}/>
                    <TextField margin="dense"  label={labelText15} size="small" type='number' value={limit} variant="outlined"
                        className='smallText'
                        id="iLimit"
                        InputProps={{ inputProps: { min: 0 } }}
                        onChange={e =>setLimit(e.target.value)} 
                        />
                    <TextField margin="dense"  label={labelText6} size="small" type='number' value={qteD} variant="outlined"
                        className='smallText'
                        id="iQted"
                        InputProps={{ inputProps: { min: 0 } }}
                        onChange={e =>setQteD(e.target.value)} 
                        />
                   </div>
                   <div className="rowGrid" id="row-3"> 
                          <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedA}
                          onChange={e=>handleChangeA(e)}
                          name="checked"
                          color="primary"
                       />
                             }
                    label={checkLabel1}
                 />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedB}
                          onChange={e=>handleChangeB(e)}
                          name="checked"
                          color="primary"
                       />
                             }
                    label={checkLabel2}/>
                 <TextField size="small" variant="outlined" label={labelText7} id="iNote" name="iNote"  value={note}
                            margin="dense" className='smallNote' onChange={e =>setNote(e.target.value)}  multiline rows={2} />
                 </div>
                    </fieldset>
                    <fieldset className="fieldsetTop2" id="prod">
                       <legend>Stock:</legend>
                    <div className="rowGrid" id={(props.open!=='prod')?"row-3":'row-3-1'}>
                    <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    margin="dense"
                                    size="small"
                                    className='smallText'
                                    minDate={new Date()} 
                                    invalidDateMessage="Le format de la date est incorrect"   
                                    minDateMessage="La date ne doit pas être inferieur à la date minimale"                           
                                    variant="inline"
                                    maxDateMessage="La date ne doit pas être supérieure à la date maximale" 
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    name="iDate"
                                    id="date-picker-inline"
                                    label={labelText12}
                                    value={selectedDate}
                                    onChange={handleDateChange}/>
                         </MuiPickersUtilsProvider>    
                         <FormControl margin="dense"  className='smallText' onClick={(e) => e.stopPropagation()}>
                            <TextField
                            select
                            id="iMth"
                            size="small"
                            variant="outlined"
                            name="iMth"
                            value={mth}
                            label={labelText13}
                            onChange={(e)=>setMth(e.target.value)}
                            >
                                <MenuItem value={'null'}>Aucune méthode de calcul</MenuItem>
                            {
                            Object.keys(calML).map((key, index)=>{
                                return <MenuItem key={index}  value={key}>{calML[key]}</MenuItem>
                            })}
                            </TextField>
                    </FormControl>
                    {
                      (props.open!=='prod')?
                      <Autocomplete
                      disableClearable
                      className='smallAuto'
                      id="iStk"
                      onChange={(e,newValue) =>{
                          setStk(props.stkL[Gfunc.getIndex(props.stkL,newValue,"nom")].id)
                          setStkN(newValue)}
                        } 
                      value={stkN}
                      options={props.stkL.map((option) => option.nom)}
                      renderInput={(params) => (
                      <TextField
                          {...params}
                          label={labelText10}
                          variant='outlined'
                          margin='dense'
                          size="small"
                          required/>
                      )} />
                      :null
                    }
                    
                    </div>
                    <div className={(props.open!=='prod')?'grid4':'grid4-1'}>
                    <TextField margin="dense"  label={labelText8} size="small" type='number' value={achat} variant="outlined"
                        className='smallText'
                        id="iAchat"
                        InputProps={{ inputProps: { min: 0 ,step:"0.01"} }}
                        onChange={e =>setAchat(e.target.value)} 
                        required
                        />
                    <TextField margin="dense"  label={labelText9} size="small" type='number' value={vente1} variant="outlined"
                        className='smallText'
                        id="iVente1"
                        InputProps={{ inputProps: { min: 0 ,step:"0.01"} }}
                        onChange={e =>setVente1(e.target.value)} 
                        required
                        />
                        {
                          (props.open!=='prod')?
                          <TextField margin="dense"  label={labelText11} size="small" type='number' value={qte} variant="outlined"
                              className='smallText'
                              id="iQte"
                              InputProps={{ inputProps: { min: 1 } }}
                              onChange={e =>setQte(e.target.value)} 
                              required
                          />:null
                        }
                    <TextField margin="dense"  label={labelText14} size="small" type='number' value={cb} variant="outlined"
                        className='smallText'
                        id="iCb"
                        onChange={e =>setCb(e.target.value)} 
                        />
                    </div>
                    </fieldset>
                </ValidatorForm>
            </DialogContent>
            <DialogActions>
               <Button className={classes.diagTabBtn} id={"addBtn"} form="validate" type="submit" variant="contained" color="primary" 
                       startIcon={<Add/>}>Ajouter</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}