import React,{ useState, useCallback, useEffect} from "react";
import { axiosGetAction,axiosPostAction, axiosResponse } from "../../Gfunc";
import {TextField, Accordion,AccordionDetails, AccordionSummary,Typography,Switch} from '@material-ui/core';
import DataTable from "react-data-table-component";
import {ExpandMore, Note} from '@material-ui/icons';
import { useStyles } from '../../css/muiStyle';
import  { merge,groupBy,getDate } from '../../Gfunc'
import {customStyles}from '../../css/datatable-custom'
import {AlertCompo} from "../../js/components"
import { CircularProgress } from "material-ui";
import { Autocomplete } from "@material-ui/lab";
import '../../css/admin.css';
import { Groups } from "@mui/icons-material";


export default function Adm_prv(props){
  const param=props.param;
  const [data, setData] = useState([]);
  const [privs, setPrivs] = useState([]);
  const [rPrivs, setRprivs] = useState([]);
  const [det, setDet] = useState([]);
  const [resp, setResp] = useState(false);
  const service='_rol.php'
  const [role, setRole] = useState('');
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [openAccord, setOpenAccord] = useState(false);
  const [switches, setSwitches] = useState('');
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const Gfunc=require('../../Gfunc')
    //handle alerts properties
    const handleAlert=(open,severity,message)=>{
      setOpen(open)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpen(false)
      }, 5000);
    }
    //fetch roles
    const fetch= useCallback(async() => {
      const response=await axiosGetAction(service,"gets")
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      axiosResponse(response,()=>{
        if(response.data.jData!==undefined){
          setData(response.data.jData);
        }
      }) 
    },[service]);
    
    
     //fetch all prives
     const fetchPrivs= useCallback(async() => {
      const response=await axiosGetAction(service,"getprivs")
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      axiosResponse(response,()=>{
        if(response.data.jData!==undefined){
          setPrivs(response.data.jData);
        }
      }); 
     },[service]);
  
     //fetch role prives
     const fetchRolePrivs= async(id) => {
        const response=await axiosPostAction(service,"getrprvs",{idr:id})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        axiosResponse(response,()=>{
          const rolePrivs=response.data.jData
          const conc=merge(privs,rolePrivs,'id')
          const priv = groupBy(conc, 'module')
          Object.keys(priv).map(i=>{ priv[i].map(item=>{
            if(item.hasOwnProperty("name")){
              item['switch']=true
            }else item['switch']=false
            return true
          }) 
          return true
        })
          setRprivs(priv);
          handleSwitches("")  
        })
      }
     //fetch role prives
     const fetchRoleDet= async(id) => {
      const response=await axiosPostAction(service,"getroledet",{idr:id})
      if(response.data.err&&response.data.err!==-1){
        handleAlert(true,'error',response.data.err)
      }else{
        axiosResponse(response,()=>{
          setDet(response.data.jData)
      
      })
    }
    }
  //handle select change
  const handleChange =(val) => {
    fetchRoleDet(val)
    setResp(true)
    setRole(val);
    setOpenAccord(true)
    fetchRolePrivs(val)
  };

  const handleOpenSelect=useCallback(()=>{
    if(data.length===0)fetch();
    if(privs.length===0) fetchPrivs();
  },[data,privs,fetch,fetchPrivs])
  
  //handle accordionchange
  const handleChangeAccordion = (panel)=>(event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
 
  //handle switch
  const handleSwitches=async(val)=>{  
     setSwitches(val);
  }
  //set or delete privs to a role
  const handlePrivs= async (row,key)=> {
    var response=null
    if(row.switch){
      response=await axiosPostAction(service,"delrpriv",{idp:row.id,idr:role})
    }
    else 
      response=await axiosPostAction(service,"setrpriv",{idp:row.id,idr:role})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        axiosResponse(response,()=>{
          handleSwitches(row.id)
          const objIndex = rPrivs[key].findIndex((obj => obj.id === row.id)); 
          if(row.switch===true){
            delete rPrivs[key][objIndex].name
            delete rPrivs[key][objIndex].date
            rPrivs[key][objIndex].switch=false
          }else{
            rPrivs[key][objIndex].name=localStorage.getItem('name')
            rPrivs[key][objIndex].date=getDate()
            rPrivs[key][objIndex].switch=true
          }  
        })          
   };

   useEffect(()=>{
    handleOpenSelect()
  },[handleOpenSelect])

  return (
    <div className='content'>  
    <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
              <div className='head'>
              <fieldset className="fieldsetTop" id="role">
               <div className="rowGrid" id="row-1">
                           <Autocomplete
                                disableClearable
                                //className={classes.textInput2}
                                id="iRole"
                                onChange={(e,newValue) =>{handleChange(data[Gfunc.getIndex(data,newValue,"name")].id)}}
                                className='smallAuto'
                                options={data.map((option) => option.name)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label={'Role'}
                                    size="small" 
                                    required/>
                            )}/>
                            {
                              (resp)?
                              <div id='fieldSet'>
                                  <div id='prv-item'>
                                      <Groups style={{height:'22px',width:'22px'}}/>
                                      <b>Utilisateurs:</b>
                                      <p> 
                                        {
                                          (det.length!==0)?det.users.map((elem,index)=>{
                                              return ((index===det.users.length-1)?elem+ ' . ':elem+ ' - ') 
                                          } ) :'---'
                                        }
                                      </p>
                                  </div>
                                  <div id='prv-item'>
                                      <Note style={{height:'20px',width:'20px'}}/>
                                      <b>Note :</b>
                                      <p> {(det.note)?det.note:'---'}</p>
                                  </div>
                                  
                              </div>:null
                            }
               </div>
              </fieldset>
              </div>
        <div className={classes.root}>
            {   
                 Object.keys(rPrivs).map((key, index) =>{ 
                    const columns =[   
                          {
                              selector:row=>row.function,
                              width:'450px !important',
                          },
                          { selector:row=>row.switch,
                            width:'50px !important',
                            cell: (row) => {
                              if(switches===row.id){
                                row.switch=!row.switch
                                setTimeout(() => {
                                  setSwitches('')
                                }, 1);
                              }
                              return (
                                <div>  
                                <Switch size="small"
                                        checked={row.switch}
                                        id={"s"+row.id}
                                        classes={{
                                          track: classes.switch_track,
                                          switchBase: classes.switch_base,
                                          colorPrimary: classes.switch_primary,
                                        }}
                                        onChange={(e)=>{handlePrivs(row,key)}}
                                        name={row.module}
                                        color='primary'
                                    />   
                                </div>  
                              );
                             }
                          },
                          {
                            selector: "name",
                            minWidth:'150px !important',
                            cell: (row) => {
                            return (
                              <div>  
                               <p className={classes.greenText}>{row.name}</p>
                              </div>  
                            );
                           }        
                          },
                          {
                            selector: "date",
                            minWidth:'100px !important',
                            cell: (row) => {
                              return (
                                <div>  
                                 <p className={classes.greenText}>{row.date}</p>
                                </div>  
                              );
                             }
                          }
                        ]
                        if(openAccord){
                           return (
                            <Accordion className={classes.accordionRoot} key={index} expanded={expanded === key} onChange={handleChangeAccordion(key)}>
                                <AccordionSummary
                                    className={classes.accordionSummary}
                                    expandIcon={<ExpandMore />}
                                    aria-controls={key+"-content"}
                                    id={key+"-header"}
                                >
                                <Typography className={classes.heading}>{key}</Typography>
                                </AccordionSummary>    
                                <AccordionDetails key={index} className={classes.accordionDetails}>
                                      <div className="accord-table">
                                            <DataTable 
                                                role={role}
                                                customStyles={customStyles}
                                                progressComponent={<CircularProgress className={classes.circularProgress} />}
                                                columns={columns}
                                                data={rPrivs[key]}
                                                noHeader={true}
                                                noTableHead={true}
                                                noDataComponent={param.ErreurData} 
                                                highlightOnHover
                                                paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous'}}
                                                />
                                      </div>
                                </AccordionDetails>
                            </Accordion>) 
                            }else return <></>     
                    }
                )
            }
        </div> 
    </div>
  );
}