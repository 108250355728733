import React, { useState, useRef, useCallback } from 'react';
import { DialogActions, DialogContent, Paper, Button, Dialog, TextField, IconButton, Typography, Checkbox } from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { axiosPostAction, axiosResponse } from '../../../Gfunc'
import { useStyles, paperPropsBigFixed } from '../../../css/muiStyle';
import { AlertCompo } from "../../../js/components"
import { Add, Close } from '@material-ui/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';
import { Autocomplete } from '@material-ui/lab';


// Define styles for the table
const useTableStyles = makeStyles((theme) => ({
    tableContainer: {
        // maxHeight: '200px',  // Adjust this value as needed
        overflowY: 'auto',
        height: "100%",
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: 300,
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: 0,
        color: 'white',
    },
});


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle className={classes.root} {...other}>
            <Typography>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

// Add a new Zakat
function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    )
}

export default function Ref_zkt_add(props) {
    const classes = useStyles();
    const tableClasses = useTableStyles(); // Use table styles
    const [catL, setCatL] = useState([]);
    const [open, setOpen] = useState(false);
    const [openA, setOpenA] = useState(false);
    const [messageA, setMessageA] = useState('');
    const [severity, setSeverity] = useState("info");
    const [yearL, setYearL] = useState([]);
    const nom = "Zakat"
    const addMessage = "Ajouter " + nom
    const alertError = nom + " a été ajouté"
    const service = "_zkt.php"
    const Gfunc = require('../../../Gfunc')
    const currentYear = Gfunc.getCurrentHijriYear();
    const [selectedHijriDate, setSelectedHijriDate] = useState(currentYear);

    //fetch categorie
    const fetchYear = useCallback(async (serv = "_zkt.php", act = "gety2") => {
        var dat = []
        const response = await Gfunc.axiosGetAction(serv, act);
        if (response.data.err && response.data.err !== "-1") {
            handleAlert(true, 'error', response.data.err)
        } else {
            Gfunc.axiosResponse(response, () => {
                dat = response.data.jData
                setYearL(response.data.jData);
            })
        }
        return dat

    }, [Gfunc])

    //fetch categorie handler
    const handleOpenYear = useCallback(async () => {
        if (yearL.length === 0) fetchYear()
    }, [yearL, fetchYear])

    // Handle alerts properties
    const handleAlert = (open, severity, message) => {
        setOpenA(open)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function () {
            setOpenA(false)
        }, 5000);
    }

    const handleClickOpen = () => {
        const newArray = props.cats.map(item => ({
            ...item,
            isAchat: false,
            idc: item.id
        }));
        setCatL(newArray)
        handleOpenYear()
        setOpen(true);
    };
    const handleClose = () => { setOpen(false); };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = { year: selectedHijriDate, cats: catL }
        const respon = await axiosPostAction(service, "add", data)
        if (respon.data.err && respon.data.err !== '-1') {
            handleAlert(true, "error", respon.data.err)
        } else
            axiosResponse(respon, () => {
                setOpen(false);
                if (respon.data.jData) props.handleAlert(true, 'success', alertError);
                props.callback(selectedHijriDate);
            })
    }

    const handleDateChange = (year) => {
        setSelectedHijriDate(year);
    };

    const handleCheck = (isAchat, index) => {
        var dat = [...catL]
        dat[index].isAchat = isAchat
        setCatL(dat)
    };

    return (
        <div>
            <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Add />}>Zakat</Button>

            <Dialog
                open={open}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                PaperProps={paperPropsBigFixed}
            >
                <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
                    <div className='DialogTitle'>
                        <Icon path={mdiPlusBox} size={"18"} />
                        <label>{addMessage}</label>
                    </div>
                </DialogTitle>
                <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true} />
                <DialogContent style={{ overflow: "hidden !important" }}>
                    <ValidatorForm id="zakatAddForm" ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
                        <div style={{ width: "120px" }}>
                            <Autocomplete
                                fullWidth
                                disableClearable
                                className='smallAuto'
                                options={yearL}
                                value={selectedHijriDate}
                                onChange={(e, newValue) => handleDateChange(newValue)}
                                getOptionLabel={(option) => option.toString()}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={"Année Hijri"}
                                        size="small"
                                        margin="dense"
                                        variant='outlined'
                                        autoFocus />
                                )}
                            />
                        </div>
                        <div className={tableClasses.tableContainer}>
                            <table className={tableClasses.table + " zakatCat"}>
                                <thead>
                                    <tr>
                                        <th>CATEGORIE</th>
                                        <th>ACHAT</th>
                                        <th>VENTE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {catL.map((cat, index) => (
                                        <tr key={cat.id}>
                                            <td>{cat.nom}</td>
                                            <td> <Checkbox
                                                checked={cat.isAchat}
                                                onChange={() => handleCheck(true, index)}
                                                color="primary"
                                                size="small"
                                                style={{ padding: 0 }}
                                            />
                                            </td>
                                            <td><Checkbox
                                                checked={!cat.isAchat}
                                                onChange={() => handleCheck(false, index)}
                                                color="primary"
                                                size="small"
                                                style={{ padding: 0 }}
                                            /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </ValidatorForm>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" form="zakatAddForm" type='submit' color="primary" startIcon={<Add />}>AJOUTER</Button>
                </DialogActions>

            </Dialog>
        </div>
    );
}
