import React,{useState,useMemo,useRef} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Print,Close} from '@material-ui/icons';
import { useStyles, paperPropsBig} from '../../../css/muiStyle';
import {customStyles2, numberColumn}from '../../../css/datatable-custom'
import DataTable from "react-data-table-component";
import ReactToPrint from 'react-to-print';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import "../../../css/stock.css"
import "../../../css/ticket-bon.css"
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ach_trt_imprimer(props) {
    const param=useContext(ParamContext);
    const [open, setOpen] =useState(false);
    const classes=useStyles();
    const componentRef = useRef();
   
    const handleClickOpen = () => { 
      setOpen(true); 
     };

    const handleClose = () => { setOpen(false); };



    const columns = useMemo(()=>[   
        {
            name: "Produit",
            selector: row=>row.produit,
            maxWidth:'230px !important',
            cell: (row) => {
              return (
                <div data-tooltip={row.produit} className='tooltipU'>
                  {row.produit}
                </div>
              );
             },    
        },
        {
            name: "Stock Source",
            selector: row=>row.donneur,
        },
        {
            name: "Qte.",
            selector: row=>row.qte,
            style:numberColumn,
            width:'90px !important',
        },
        {
            name: "Stock Destination",
            selector: row=>row.receveur,
            minWidth:'200px !important'
        },
        {
            name: "Agent",
            selector: row=>row.agent,
            width:'90px !important'
        },
     
       
      ],[])

  return (
    <div>
      <Button variant="contained" className={classes.PrintBtn} onClick={handleClickOpen} color="primary" startIcon={<Print />}>Imprimer</Button>
      <Dialog
            open={open}
            id='print'
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsBig}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
            <div className='DialogTitle'>
              <Print style={{width:'20px',height:'20px'}}/>
              <label>Imprimer un transfert</label>
            </div>
        </DialogTitle>
        <DialogContent className={classes.dialogueContent}>
            <div className='bon-ach' ref={componentRef} >
            <div className='bon-head'> 
              <div id='infoG'>
                <p id='infos'><b>iRaha services</b></p> 
                <p id='infos'>Magasin: <b>{localStorage.getItem('mag')}</b></p>
              </div>
              <img src={param.logo+localStorage.getItem('logo')} id='logo' alt='logo'/>
              <div id='infoD'>
                <p id='infos'>Agent :<b>{(props.row.length!==0)?props.row[0].agent:''}</b></p>
                <p id='infos'>Le <b>{(props.row.length!==0)?props.row[0].date.replace(" ", " à "):''}</b></p>
              </div>
            </div>
             {/* <div id='impr'>
              </div>*/}
               <DataTable 
                  persistTableHead
                  columns={columns}
                  data={props.row}
                  noHeader={false}
                  customStyles={customStyles2}
                  title={"Bon de transfert N° : "+props.bon.numero}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
                />
          </div>
          
           </DialogContent>
            <DialogActions>
              <ReactToPrint
                 trigger={() => <Button variant="contained" color="primary"  startIcon={<Print />}>Imprimer</Button>}
                 content={() => componentRef.current} 
              />
            </DialogActions>
      </Dialog>
      </div>
  );
}