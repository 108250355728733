import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField, Box, Backdrop, CircularProgress, Tooltip} from '@material-ui/core';
import DataTable  from "react-data-table-component";
import Draggable from 'react-draggable';
import {Add, Check, Search} from '@material-ui/icons';
import { useStyles,paperPropsBig } from '../../../css/muiStyle';
import { AlertCompo, CustomizedProgressBars, NoDataComponent,NotAllowedComponent} from "../../../js/components";
import {customStyles}from '../../../css/datatable-custom'
import '../../../css/histoBinars.css'
import axios from "axios"
import ParamContext from "../../../Contexte";
import { useContext } from "react";
import Mobilis from '../../../img/icons/Mobilis.png'
import Ooredoo from '../../../img/icons/Ooredoo.png'
import Djezzy from '../../../img/icons/Djezzy.png'
import Binar from '../../../img/logos/Logo-Binar-orange.png'
import IconButton from '@mui/material/IconButton';
import { ValidatorForm } from "react-material-ui-form-validator";
import $ from "jquery"
import { Divider } from "@mui/material";
import RichTooltip from "../../../compo/RichTootip";
import { mdiAlertCircle, mdiCheck, mdiCheckAll, mdiCheckBold, mdiProgressQuestion, mdiSend } from "@mdi/js";
import Iconn from "@mdi/react";
import { isEmpty } from "lodash";

//historique Airtime 
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Histo_cli_binars(props) {
    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const librery=require('../../../js/data_table_librery')
    const [data, setData] = useState([]);
    const [tous, setTous] = useState([]);
    const [atVals, setAtVals] = useState({Erselli:"",Flexy:"",Storm:"",Binar:""});
    const [loading, setLoading] = useState(false);
    const [search,setSearch]=useState('');
    const [,setIcon]=useState('');
    const service="_irh.php"
    const classes=useStyles();
    const [open, setOpen] =useState(false);
    const [noData,setNoData]=useState(false);
    const [doSearch,setDoSearch]=useState(false);
    const [notAllowed,setNotAllowed]=useState(false);
    const [errMessage,setErrMessage]=useState('');
    const [records,setRecords]=useState('');
    const [sold, setSold] = useState('');
    const [seuil, setSeuil] = useState('');
    const [at, setAt] = useState('');
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const statStyle={1:{color:"",icon:mdiCheck},3:{color:"#E60000",icon:mdiAlertCircle},4:{color:"#0096ff",icon:mdiCheckAll },2:{color:"#707070",icon:mdiSend}}
    const modStyle={0:{color:"#ff5733",icon:mdiProgressQuestion},1:{color:"green",icon:mdiCheck}}
    const stat= {1:"en attente",3:"Echec de transfert",4:"Transfert terminé",2:"Demande en cours"}
    const mode= {0:"Validation en cours",1:"Transfert en cours"}
    const airIds={Binar:0,Storm:props.sid,Flexy:props.fid,Erselli:props.eid}

    const conditionalRowStyles=[
      { when: row => !row.isVente&&row.mtn>0,
         style: {
               color: "green",
         }
       },
      { when: row => !row.isVente&&row.mtn<=0,
         style: {
               color: 'red',
         }
      },
      { when: row => row.isVente&&row.mtn<0,
         style: {
               color: "green",
         }
       },
      { when: row => row.isVente&&row.mtn>=0,
         style: {
               color: 'red',
         }
      }
    ]

    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    const fetch= useCallback(async() => {
      //setFocus(true)
      setLoading(true);
      const response= await axios.get( param.urlService+service+'?do=getathist',{ headers: Gfunc.getHeader()});
      if(response.data.err&&response.data.err!=="-1"){
          setErrMessage(response.data.err)
          setNotAllowed(true)
          handleAlert(true,"error",response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
              setRecords(response.data.recordsFiltered)
              if(response.data.jData!==undefined && response.data.jData.length!==0){
                setData(response.data.jData);
                setNoData(true)
              }else {setData([])}
          })
        } 
        setLoading(false)
  },[Gfunc,librery]);


    const fetchSold= useCallback(async() => {
      const response= await axios.get( param.urlService+service+'?do=getatsold',{ headers: Gfunc.getHeader()});
      if(response.data.err&&response.data.err!=="-1"){
          setErrMessage(response.data.err)
          setNotAllowed(true)
          handleAlert(true,"error",response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
              if(response.data.jData){
                setSold(response.data.jData);
              }
          })
        } 
  },[Gfunc,librery]);
  
    const fetchSeuil= useCallback(async() => {
      const response= await axios.get( param.urlService+service+'?do=getatseuil',{ headers: Gfunc.getHeader()});
      if(response.data.err&&response.data.err!=="-1"){
          setErrMessage(response.data.err)
          setNotAllowed(true)
        }else{
          Gfunc.axiosResponse(response,()=>{
              if(response.data.jData){
                setSeuil(response.data.jData);
              }
          })
        } 
  },[Gfunc,librery]);

  const fetchAll=useCallback(()=>{
    fetch()
    fetchSold()
    fetchSeuil()
  },[fetch,fetchSold,fetchSeuil])

  const handleAjustBinar=useCallback((val)=>{
     if(val>0){
       
     }
  },[])
   
  useEffect(()=>{
    const at={};
        at["Erselli"]=props.at.Erselli
        at["Flexy"]=props.at.Flexy
        at["Storm"]=props.at.Storm
        at["Binar"]=props.at.realBinar
        setAt(at)
  },[props])
    const handleClickOpen = () => { 
        fetchAll();
        setOpen(true); 
        setAtVals({Erselli:"",Flexy:"",Storm:"",Binar:""})
      };
    const handleClose = () => { setOpen(false); };
  
    const handleSearch=()=>{
      const filters=[]
      var keys=[] 
      keys=Object.keys(tous[0])
        keys.map( key =>{ 
          const f={}  
          f["att"]=key
          filters.push(f)
          return true
        }) 
      setData(tous.filter((d)=>filters.some(filterEl =>{if(d[filterEl.att]!=null) return((d[filterEl.att].toString().toLowerCase()).includes(search.toLowerCase()))})))
    }
  
  const handleLockIcon=(val)=>{
    setIcon(val);
  }

  useEffect((val) => {
     handleLockIcon(val);
  }, []);

  const columns =useMemo(
    () => [ 
      {
        width:'28px !important',
         cell: (row) => {
             return (
                <div>
                 {
                  (row.produit==='Flexy'||row.produit==='Storm'||row.produit==='Arselli'||row.produit==='Binar')?
                   <img src ={(row.produit==='Flexy')?
                               Djezzy:
                               (row.produit==="Storm")?
                               Ooredoo :
                               (row.produit==="Arselli")?
                               Mobilis:
                               Binar
                             }
                      alt="digit" style={{width: "18px", height:"auto"}}/>
                  :null 
                 }
                </div>   
             );
            },   
      },
      {
        name: "DATE",
        selector:row=>row.date,
        sortable: true,
        sortField:"date",
        minWidth:'135px !important'
      },
      {
        name: "Numéro",
        selector: row=>row.numero,
        sortable: true,
        sortField:"numero",
        minWidth:'130px !important'
      },  
      {
        name: "Produit",
        selector:row=>row.produit,
        sortable: true,
        sortField:"produit",
        minWidth:'130px !important'
      },  
      {
        name: "Qte.",
        selector:row=>row.qte,
        sortable: true,
        sortField:"qte",
        width:'90px !important',
        cell:(row)=>{
          return(
            <div>{(row.qte!==null)?Intl.NumberFormat().format(parseFloat(row.qte)):null}</div>
          )
        }
      },  
      {
        name: "Montant",
        selector:row=>row.mtn,
        sortable: true,
        sortField:"mtn",
        minWidth:'130px !important',
        cell:(row)=>{
          return(
            <div>{(row.mtn)?Intl.NumberFormat().format(parseFloat(row.mtn)):null}</div>
          )
        }
      },  
      {
        name: "Solde",
        selector:row=>row.solde,
        sortable: true,
        sortField:"solde",
        minWidth:'130px !important',
        cell:(row)=>{
          return(
            <div>{(row.solde)?Intl.NumberFormat().format(parseFloat(row.solde)):null}</div>
          )
        }
      },  
  ],[]);
   
  
    function liveSearch(e){
      if(search.length>-1){
        e.preventDefault();
        handleSearch();
      }
    }
    
    function liveSearch2() {
      handleSearch();
    }

    function handleAtVals(e,key) {
      let ats={...atVals};
      ats[key]=(e.target.value!==""&&!isNaN(parseFloat(e.target.value)))?parseFloat(e.target.value):0
      setAtVals(ats)
    }

    function handleCheckInterval(val,min,max) {
      return (val>=min&&val<=max)
    }
    function handleCheckAll(vals) {
      const min=5000
      const minS=10000
      const max=100000
      var dat={condition:true,message:""}
      Object.keys(vals).map(function(key, index) {
         if((vals[key]!==0&&vals[key]!=="")&&!handleCheckInterval(vals[key],(key!=="Storm")?min:minS,max)){
           dat.condition=false;
           if(dat.message!=="") dat.message=dat.message+"\r\n"
           if(key!=="Storm")
           dat.message=dat.message+key+" doit être entre "+Intl.NumberFormat().format(min) +" et "+Intl.NumberFormat().format(max)
           else
           dat.message=dat.message+key+" doit être entre "+Intl.NumberFormat().format(minS) +" et "+Intl.NumberFormat().format(max)
         }
      })
      return(dat)
    }

    async function handleAddAirTime() {
      props.setOrd(true)
      if(!Gfunc.isNullUndfEmpStr(atVals)){const con=handleCheckAll(atVals)
      if(con.condition){
        
        const data={
       // magId:parseInt(localStorage.getItem("magid")),
        erselli:Gfunc.stringToZero(atVals["Erselli"]),
        storm:Gfunc.stringToZero(atVals["Storm"]),
        flexy:Gfunc.stringToZero(atVals["Flexy"]),
        binar:Gfunc.stringToZero(atVals["Binar"]),}
      //make Order
      const response= await Gfunc.axiosPostAction(service,'order',data);
      if(response.data.err&&response.data.err!=="-1"){
          handleAlert(true,"error",response.data.err)
          
        }else
        Gfunc.axiosResponse(response,()=>{
           //set the list of orders Airtime
          var ord=[]
          Object.keys(atVals).map(function(key) {
            if( Gfunc.stringToZero(atVals[key])>0) ord.push(airIds[key])
          })
          props.setOrderss(ord)   
          var st={};var ex=[...props.excluded]
            response.data.data.map((elem)=>{
              if(ord.includes(elem.produitId)||elem.stat===1)
              st[elem.produitId]={stat:elem.stat,mode:elem.mode}
              else  ex.push(elem.produitId)
            }) 
            
            setTimeout(()=>props.setOrd(false),500)
            props.setStats(st)
            props.setExcluded(ex)
            
            
            props.getStats()
            setOpen(false);
            props.GetAtSoldes();
            
        })}else handleAlert(true,"error",con.message)
        
      }else handleAlert(true,"error","Veuillez renseigner au moins un champ")
    }
   
        //code jQuery pour boucler le focus des elements
    $('form').each(function(){
      var list  = $(this).find('input*[tabindex],#addAtBtn*[tabindex]').sort(function(a,b){ return a.tabIndex < b.tabIndex ? -1 : 1; }),
          first = list.first();
      list.last().on('keydown', function(e){
          if( e.keyCode === 9 ) {
              first.focus();
              return false;
          }
      });
    });


  return (
    <>
      <Paper id="binarsHistoBarPaper" >
        <div id="binarsHistoBar">
        {Object.keys(at).map(function(key, index) {
             return(
             
              <RichTooltip
                key={index}
                color={(props.ord&&props.orderss.includes(airIds[key]))?statStyle[2].color:(!isEmpty(props.stats)&&props.stats[airIds[key]])?(props.stats[airIds[key]].stat!==1)?statStyle[props.stats[airIds[key]].stat].color:modStyle[props.stats[airIds[key]].mode].color:null}
                content={(props.ord&&props.orderss.includes(airIds[key]))?<span className="flex-wrapper addGap5"><Iconn path={statStyle[2].icon} size="15px"/><p>{stat[2]}</p></span>:(!isEmpty(props.stats)&&props.stats[airIds[key]])?<span className="flex-wrapper addGap5"> <Iconn path={(props.stats[airIds[key]].stat!==1)?statStyle[props.stats[airIds[key]].stat].icon:modStyle[props.stats[airIds[key]].mode].icon} size="16px"/><p>{(props.stats[airIds[key]].stat!==1)?stat[props.stats[airIds[key]].stat]:mode[props.stats[airIds[key]].mode]}</p></span>:null}
                open={(props.stats[airIds[key]]/*&&props.displayStats*/)?true:false}
                placement={(index===0||index===1)?"top":"bottom"}
                arrow={props.stats[airIds[key]]/*&&props.displayStats*/}
                onClose={() => false}
              >
                
              <p id={key}  className="atVals" >
              {/*!isEmpty(props.stats)?console.log(props.stats[props.orders[airIds[key]]].stat):console.log("lol")*/}
              {/*!isEmpty(props.stats)?console.log(statStyle[props.stats[props.orders[airIds[key]]].stat]):console.log("lol")*/}
                  <img src ={(key==='Flexy')?
                                  Djezzy:
                                  (key==="Storm")?
                                  Ooredoo :
                                  (key==="Erselli")?
                                  Mobilis:(key==="Binar")?
                                  Binar:null
                                }
                          alt="digit" style={{width: "18px", height:"auto"}}/>
            <span id={key+"Val"}>{(!isNaN(at[key]&&at[key]!==""&&at[key]!==undefined))?Intl.NumberFormat().format(at[key]):0}<Tooltip title={"Décalage Binar ( Cliquer pour ajuster)"}><span id="realBinBin" onClick={(e)=>{e.preventDefault();props.handleAjustBinar()}}>{(key==="Binar"&&at[key]!==undefined)?" ("+(at[key]-props.at["binar"])+")":""}</span></Tooltip> </span> </p>
        </RichTooltip> 
             )
          })}
        </div> <div className="addBinarsButton">
        <Divider orientation="vertical" textAlign="left" style={{ padding:"26px 0 !important",width:"100%", height:"auto"}}></Divider>
       <Tooltip title="Commander Airtime"><Add fontSize="small" onClick={handleClickOpen}/></Tooltip></div>
      </Paper>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsBig}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Historique Airtime</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent>
                <Backdrop className={classes.backdropDiag} open={loading}>
                <CircularProgress color="inherit" />
                </Backdrop>  
                            
                <div>
                <Paper id="brnInfos" style={{width:"90%",margin:" 10px auto", background:"#f5f5f5"}}>
                  <CustomizedProgressBars value={(sold/seuil)*100} max={seuil} current={sold}/>
                    {/*(data.length!==0)? <div className="binarsHead"><div className="staticsHead"><h2>{Intl.NumberFormat().format(parseFloat(binars))}</h2><p>Solde</p></div><img src={LogoBinars} style={{width:"40px",height:"auto"}}/></div>:<div></div>*/}
                  <div id="binarsHistoBar" >
                  {Object.keys(at).map(function(key, index) {
             return(
              <RichTooltip
                key={index} paper={true}
                color={(props.ord)?statStyle[2].color:(!isEmpty(props.stats)&&props.stats[airIds[key]])?(props.stats[airIds[key]].stat!==1)?statStyle[props.stats[airIds[key]].stat].color:modStyle[props.stats[airIds[key]].mode].color:null}
                content={(props.ord)?<span className="flex-wrapper addGap5"><Iconn path={statStyle[2].icon} size="15px"/><p>{stat[2]}</p></span>:(!isEmpty(props.stats)&&props.stats[airIds[key]])?<span className="flex-wrapper addGap5"> <Iconn path={(props.stats[airIds[key]].stat!==1)?statStyle[props.stats[airIds[key]].stat].icon:modStyle[props.stats[airIds[key]].mode].icon} size="16px"/><p>{(props.stats[airIds[key]].stat!==1)?stat[props.stats[airIds[key]].stat]:mode[props.stats[airIds[key]].mode]}</p></span>:null}
                open={(props.stats[airIds[key]]/*&&props.displayStats*/)?true:false}
                placement={(index===0||index===1)?"top":"bottom"}
                arrow={props.stats[airIds[key]]/*&&props.displayStats*/}
                onClose={() => false}
              >
              <p id={key} className="atVals" >
                <img src ={(key==='Flexy')?
                                Djezzy:
                                (key==="Storm")?
                                Ooredoo :
                                (key==="Erselli")?
                                Mobilis:(key==="Binar")?
                                Binar:null
                              }
                        alt="digit" style={{width: "18px", height:"auto"}}/>
              <span id={key+"Val"}>{(!isNaN(at[key])&&(at[key]!==""&&at[key]!==undefined))?Intl.NumberFormat().format(at[key]):0} <span onClick={handleAjustBinar}>{(key==="Binar")?" ("+(at[key]-props.at["binar"])+")":""}</span></span> 
                </p>
            </RichTooltip>
             )
          })}
                  </div>
                </Paper> 
                <div className='table-header'>
                <div className="button-flex-wrapper" style={{gap:"10px"}}><ValidatorForm id="addAt" onSubmit={handleAddAirTime} onError={errors => console.log(errors)} > 
                   {Object.keys(at).map(function(key, index) {
                      return(<div className="child" key={index}><TextField disabled={!Gfunc.isEmpty(props.stats[airIds[key]])} variant='outlined' size="small" placeholder={key} id={"i"+key} name={"i"+key} autoComplete="off"  value={atVals[key]}
                                   onChange={e =>handleAtVals(e,key)} inputProps={{ tabIndex: index+1}} InputProps={{
                                      startAdornment:
                                      <IconButton style={{padding:'2px'}} ><img src ={(key==='Flexy')? Djezzy:(key==="Storm")? Ooredoo :(key==="Erselli")?Mobilis:(key==="Binar")?Binar:null}
                                         alt="digit" style={{width: "18px", height:"auto"}}/></IconButton>,
                                         classes: {adornedStart: classes.adornedSatrt}
                                      }}
                                      className='smallText' autoFocus={index===0}/></div>)}
                                     )} 
                 </ValidatorForm>
                 <Button id="addAtBtn" tabIndex={5} className={classes.topTabBtn} variant="contained" type='submit' color="primary" form="addAt" startIcon={<Check/>} >Commander</Button> 
                 </div> 
                {
                (data.length!==0||doSearch)? <div className='search'>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>{liveSearch2(e)}}/>
                            <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
                        </Box>
                    </div>:null}
                </div>
                {
                (data.length!==0||doSearch)?
                <div>
                    <DataTable 
                    persistTableHead
                    columns={columns}
                    data={data}
                    noDataComponent={param.ErreurData}
                    paginationRowsPerPageOptions={[10,30,50,100]}
                    progressPending={loading}
                    progressComponent={<CircularProgress className={classes.circularProgress} />}
                    pagination
                    noHeader={true}
                    highlightOnHover
                    customStyles={customStyles}
                    paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
                    conditionalRowStyles = { conditionalRowStyles }
                    />
                </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
                </div>
            </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
        </DialogActions>
      </Dialog>
      </>
  );
}
