import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import {CircularProgress,Card,MenuItem,ListSubheader,FormControlLabel,Checkbox, Tooltip} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {Typography} from '@material-ui/core';
import Retmanfuncs from '../content/modals/ret_man_act'
import RetrecSuiv from '../content/modals/ret_rec_suiv'
import {Search,FiberManualRecord} from '@material-ui/icons';
import {ReactComponent as DA} from '../../img/icons/dzdY.svg'
import {AlertCompo,NoDataComponent,NotAllowedComponent,Detail_swap} from "../../js/components"
import { useMediaQuery } from 'react-responsive'
import { Box } from "@mui/system";
import Icon from '@mdi/react';
import { mdiFilterMenu } from '@mdi/js';
import '../../css/global.css'
import {customStyles} from '../../css/datatable-custom'
import '../../css/datatable.css';
import { useStyles} from '../../css/muiStyle';



export default function Ref_man (props){
  const statu=[{id:0,name:"Détruit"},{id:1,name:"Non traité"},{id:2,name:"Swapé"},{id:3,name:"Réparation"},{id:4,name:"Rembourssé"},{id:5,name:"Diagnostiqué"},{id:6,name:"Réstitué"}]
  const cli=[{id:7,name:"Non traité"},{id:8,name:"swapé"},{id:9,name:"Rembourssé"},{id:10,name:"Réstitué"}]
  const classes = useStyles();
  const librery=require('../../js/data_table_librery')
  const param=props.param;
  const Gfunc=require('../../Gfunc')
 // const [agent, setAgent] = useState('');
  const [filterE, setFilterE] = useState('');
  const [etat,] = useState(statu);
  const [client,] = useState(cli);
  const [data, setData] = useState([]);
  const [userL,setUserL]=useState([]);
  const [frnsL,setFrnsL]=useState([]);
  const [stockL,setStockL]=useState([]);
  const [mag,setMag]=useState([]);
  //const [,setDel] = useState('');
  const [,setDel] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const service='_ret.php'
  const[cpt, setCpt] = useState(0);
  const [pselected, setPselected] = useState([]);
  const [jselected, setJselected] = useState([]);
  const [clearRows, setClearRows] = useState(false);
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const[noData,setNoData]=useState(false); 
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[doSearch,setDoSearch]=useState(false);
  const[called,setCalled]=useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 840px)' })
  const [check,setCheck]=useState([])

   //handle alerts properties
   const handleAlert=useCallback((open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  },[])
  
  const fetch= async(page,servic = service, size = perPage) => {
    setCalled(true)
    setLoading(true)
        const response=await librery.axiosGet2(page+'&search='+search+'&filter='+filterE,service,'getwait', size)
        if(response.data.err&&response.data.err!=="-1"){
          setErrMessage(response.data.err)
          setNotAllowed(true)
        }else
        Gfunc.axiosResponse(response,()=>{
          if(response.data.data!==undefined){
            setData(response.data.data);
            setTotalRows(response.data.recordsFiltered);
            setDoSearch(true)
          }else {setDoSearch(false);setData([])}
          setNoData(true)
        })
        setLoading(false)
  }
  
  const fetchUser= useCallback(async() => {
    const response = await Gfunc.axiosGetAction("_usr.php","gets")
    if(response.data.err&&response.data.err!=='-1'){handleAlert(true,'error',response.data.err);}
    else
    Gfunc.axiosResponse(response,()=>{
        setUserL(response.data.jData);   
    })
    return response;
  },[Gfunc,handleAlert]);

  //fetch fournisseur 
  const fetchFrns=useCallback(async() => {
    const response = await Gfunc.axiosGetAction("_frs.php","gets")
    if(response.data.err&&response.data.err!=='-1'){handleAlert(true,'error',response.data.err) ;}
    else
    Gfunc.axiosResponse(response,()=>{
        setFrnsL(response.data.jData);   
        
    })
    return response
  },[Gfunc,handleAlert])

  //fetch stocks 
  const fetchStock= useCallback(async() => {
    const response = await Gfunc.axiosGetAction("_stk.php","gets")
    if(response.data.err&&response.data.err!=='-1'){handleAlert(true,'error',response.data.err); }
    else
    Gfunc.axiosResponse(response,()=>{
        setStockL(response.data.jData);  
    })
    return response
  },[Gfunc,handleAlert])

  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);
 
  const handleVider=(val)=>{
    setCheck(val)
  }
  
   // fetch detail de magasin
 const fetchDetMag= useCallback(async() => {  
  const response = await Gfunc.axiosPostAction("_mag.php","getmag",{id:localStorage.getItem('magid')})
  Gfunc.axiosResponse(response,()=>{
     setMag(response.data.jData);
  })
  },[Gfunc])
  
  //handle fetch magasin
  const handleFetchMag=useCallback(()=>{
    if(mag.length===0)
    fetchDetMag();
  },[fetchDetMag,mag])

    //handle the call for fetch user
  const handleOpenUser=useCallback(async()=>{
    if(userL.length===0)
    return await fetchUser();
    else return userL
},[fetchUser,userL])

  //handle the call for fetch user
  const handleOpenFrns=useCallback(async()=>{
    if(frnsL.length===0)
    return await fetchFrns();
    return frnsL
},[frnsL,fetchFrns])
 //handle the call for fetch user
const handleOpenStock=useCallback(async()=>{
    if(stockL.length===0)
    return await fetchStock();
    return stockL
},[stockL,fetchStock])
  
 

  //vider les elemets selectionnes
const handleClearRows =() => {
    setClearRows(!clearRows)
    setPselected([])
    setCpt(0)
  }

  //delete itam
  const handleSetData=useCallback((row,att)=>{
    Gfunc.removeItemsByAtt(data,row.DT_RowId,att)
    setDel('')
  },[data,Gfunc])

  const handleRemb=useCallback(async(row)=>{
    const response=await Gfunc.axiosPostAction(service,"mback",{retour:parseInt(row.DT_RowId.substr(1))})
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
    }else  Gfunc.axiosResponse(response,()=>{  
             handleAlert(true,'success',"Le remboursement est effectué avec succès")
             handleSetData(row,"DT_RowId")
             setDel(row.DT_RowId)
           })
  },[Gfunc,service,handleSetData,handleAlert])
  
  const HandleChange=useCallback((e,row)=>{
    if(e.target.checked===true){
      const ind=check.findIndex((obj => obj.DT_RowId ===row.DT_RowId));
      if(ind===-1){check.push(row)}
   }
   else{
       const ind=check.findIndex((obj => obj.DT_RowId ===row.DT_RowId))
       check.splice(ind,1)
   }
  },[check])

  const columns =useMemo(
    () => [ 
      {
        width:"55px",
        cell: (row) => {
          return (
            <div >
               <FormControlLabel control={<Checkbox defaultChecked={Gfunc.getIndex(check,row.DT_RowId,"DT_RowId")>-1} className={classes.checkStelectableRow}
                  onChange={e=>HandleChange(e,row)} inputProps={{ 'aria-label': 'controlled' }}/>}
               />
            </div>
           );
         },
      },
      {   name: "DATE",
          width:"100px !important",
          selector: row=>row.date_retour,
          sortable: true,
          sortField:"date_retour",
      },
      {
         name: "PRODUIT",
         minWidth:"300px !important",
         selector:row=>row.produit,
         sortable: true,  
         sortField:"produit"
      },
      { name: "SERIE",
        width:"160px !important",
        selector:row=>row.serie,
        sortable: true,
        sortField:"serie",
      },
      { name: "Client",
        minWidth:"150px !important",
        selector:row=>row.client,
        sortable: true,
        sortField:"client",
      },
      { name: "Type",
        minWidth:"140px !important",
        selector:row=>row.type,
        sortable: true,
        sortField:"type",
      },
      { name: "État",
        minWidth:"140px !important",
        selector:row=>row.statu,
        sortable: true,
        sortField:"statu",
      },
      { name: "Client",
        minWidth:"140px !important",
        selector:row=>row.reponse,
        sortable: true,
        sortField:"reponse",
      },
      { right:true,
        cell: (row) => {
          return (
            <div id="icons">
                <div className='rowGrid' id="row-1" >
                  <Tooltip title="Restituer">
                    <div >
                    {(row.reponseId===0&&row.statusId===5)?<Retmanfuncs type="rest" service="_ret.php" action="getback" title="Restitution" rows={row} callback={()=>fetch(currentPage)} frnsL={frnsL} userL={userL} stockL={stockL} handleClearRows={handleClearRows} handleAlert={handleAlert} handleOpenUser={handleOpenUser} handleOpenFrns={handleOpenFrns} handleOpenStock={handleOpenStock}/>:null }
                    </div>
                  </Tooltip>
                  <Tooltip title="Swapper au client">
                    <div  >
                    {(row.reponseId===0)?<Retmanfuncs type="swap" service="_ret.php" action="swap" title="Swap client" rows={row} callback={()=>fetch(currentPage)} frnsL={frnsL} userL={userL} stockL={stockL} handleClearRows={handleClearRows} handleAlert={handleAlert} handleOpenUser={handleOpenUser} handleOpenFrns={handleOpenFrns} handleOpenStock={handleOpenStock}/>:null }
                    </div>
                  </Tooltip>
                  <div >
                        {(row.reponseId===0)?<DA onClick={()=>handleRemb(row)} style={{width:"20px",height:'20px'}} 
                                                fontSize="small"/>:null }
                        {(row.reponseId===1|| row.reponseId===2)?<Detail_swap mag={mag} handleFetchMag={handleFetchMag} row={row} 
                           title={(row.reponseId===1)?"Bon de swap":(row.reponseId===2)?"Bon de remboursement":"Rembourser le client"}/>:null}
                  </div>
                  <Tooltip title="Suivre">
                     <div >
                      <RetrecSuiv mag={mag} handleFetchMag={handleFetchMag} from="man" idr={parseInt(row.DT_RowId.substr(1))} 
                                  handleOpenFrns={handleOpenFrns} frnsL={frnsL} handleOpenUser={handleOpenUser} userL={userL}/>
                     </div>
                  </Tooltip>
                </div>
            </div>
          )
        }
      },
    ],[classes,handleOpenUser,handleOpenFrns,handleOpenStock,userL,frnsL,stockL,handleRemb,handleClearRows,fetch,mag,handleFetchMag,currentPage,handleAlert]);

  const handleSearch=async( size = perPage)=>{
    //setFilterE('')
    setLoading(true);
    setCurrentPage(1)
    const recherche = search + "&filter=" + filterE;
    const response=await librery.axiosSearch(service,recherche,1,size,"getwait")
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })   
    setLoading(false);
    return recherche
  }
  //Handle filter 
  const handleFilter=async(filter)=>{
    //setSearch('')
    setLoading(true);
    const response = await librery.axiosFilter(service, filter + "&search=" + search, 1, perPage, "getwait")
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setCurrentPage(1)
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }else setData([])
      setLoading(false);
    })   
    return filter
  }

  //Sort serverside
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
    setLoading(true);
    const response=await librery.axiosSort(column,sortdirection,page,size,service,"getwait")
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }   
    })  
    setLoading(false);   
}

const handlePageChange =async(page)=> {
  fetch(page,service,perPage);
  setCurrentPage(page);
}

const handlePerRowsChange = async (newPerPage, page) => {
  fetch(page,service, newPerPage);
  setPerPage(newPerPage);
}
//Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     handleSearch();
  }else{ if (e.keyCode === 13) {
            handleSearch();
          }
  }
}
function liveSearch2() {
  handleSearch();
}

const ExpanableComponent = ({ data }) => {
    return (
       <div className="row-expenderr">
        <div className='grid-wrapper'>
         <Card className={classes.cards}>
          <p className="titleCard">Note de panne:</p>
          <div className="note">{data.note_panne.replaceAll("\r","\n")}</div>
         </Card>
         <Card className={classes.cards}>
           <p className="titleCard">Note de reparation:</p>
           <div className="note">{(data.note_reparation)?data.note_reparation.replaceAll("\r","\n"):"Aucune note"}</div>
         </Card>
         <Card className={classes.cards}>
          <p className="titleCard">Montant de réparation:</p>
          <div className="note">
           <TextField variant="outlined" size="small" color="secondary" className="smallText" disabled
                      value={(data.mtn_reparation==null)?0:new Intl.NumberFormat().format(data.mtn_reparation)}/>
            {(data.mtn_reparation)?
              <div className="flex-wrapper">
                <FiberManualRecord className={classes.greenIcon} fontSize='small'/>
                <Typography> Réparé</Typography>
              </div>:
              <div className="flex-wrapper">
                <FiberManualRecord className={classes.redIcon} fontSize='small'/>
                <Typography> Non réparé</Typography>
              </div>
            }
            </div>
         </Card>
        </div>
        </div>
    )
}
return (
  <div className="content">
   <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} autoFocus/>
   { (doSearch)?
  <div className='table-header' >
     {(data.length>0)? <div className='button-flex-wrapper'>
        <Retmanfuncs type="swaps" service="_ret.php" action="swaps" handleVider={handleVider} title="Swap fournisseur" message={(isMobile)?'Swap':'Swap fournisseur'} rows={check}  callback={()=>fetch(currentPage)} frnsL={frnsL} userL={userL} stockL={stockL} handleClearRows={handleClearRows} handleAlert={handleAlert} handleOpenUser={handleOpenUser} handleOpenFrns={handleOpenFrns} handleOpenStock={handleOpenStock}/>
        <Retmanfuncs type="repas" service="_ret.php" action="gorepas" handleVider={handleVider} title="Réparation fournisseur" message={(isMobile)?'Réparer':'Envoyer chez le fournisseur'} rows={check}  callback={()=>fetch(currentPage)}  frnsL={frnsL} userL={userL} stockL={stockL} handleClearRows={handleClearRows} handleAlert={handleAlert} handleOpenUser={handleOpenUser} handleOpenFrns={handleOpenFrns} handleOpenStock={handleOpenStock}/>
        <Retmanfuncs type="remb" service="_ret.php" action="mbacks" handleVider={handleVider}  title="Remboursement fournisseur" message={(isMobile)?'Remboursement ':'Remboursement fournisseur'} rows={check}  callback={()=>fetch(currentPage)} frnsL={frnsL} userL={userL} stockL={stockL} handleClearRows={handleClearRows} handleAlert={handleAlert} handleOpenUser={handleOpenUser} handleOpenFrns={handleOpenFrns} handleOpenStock={handleOpenStock}/>
      </div>:<div className='button-flex-wrapper'></div>}
      { (doSearch)?
      <div className='search' >
         <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer" }} />),}}
                               value={search} size="small" onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
                    <TextField
                        select
                        className={classes.headFilter}
                        label="Filtrer"
                        value={filterE}
                        size="small"
                        variant="outlined"
                        InputProps={{startAdornment: (<Icon path={mdiFilterMenu} size={0.8} style={{zIndex:"1000"}} />),}}
                        onChange={(e)=>{setFilterE(e.target.value);handleFilter(e.target.value)}}>
                <MenuItem value={""}>Aucun</MenuItem>
                              <ListSubheader>Etat</ListSubheader>
                              {etat.map((elem,index)=>{
                              return <MenuItem key={index} value={elem.id}>{elem.name}</MenuItem>
                              })}
                              <ListSubheader>Client</ListSubheader>
                              {client.map((elem,index)=>{
                              return <MenuItem key={index} value={elem.id}>{elem.name}</MenuItem>
                              })}    
                      </TextField>
         </Box>
    </div>:null}
     </div>:null}
   { (data.length!==0||doSearch)?
      <div id='exp-DT'>
          <DataTable 
              className="table"
              persistTableHead
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              defaultSortAsc={true}
              paginationRowsPerPageOptions={[10,20,30,100]}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              onSort={handleOnSort}
              expandOnRowClicked
              expandableRows 
              expandableRowsComponent={ExpanableComponent} 
              Clicked
              clearSelectedRows={clearRows}
              highlightOnHover
              customStyles={customStyles}   
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
              progressComponent={<CircularProgress className={classes.circularProgress} />}/>
        </div>
        :(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
    </div>    
  );
}