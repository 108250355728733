import React,{useState,useMemo, useCallback} from 'react';
import {DialogContent,Paper,Button,Dialog,Tooltip, Box,TextField,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {Close,Search} from '@material-ui/icons';
import DataTable from "react-data-table-component";
import  {useStyles, paperPropsBigH} from '../../../css/muiStyle';
import {customStyles, numberColumn}from '../../../css/datatable-custom'
import { CircularProgress } from "material-ui";
import {Detail_tiket,Detail_Bon,AlertCompo} from '../../../js/components'
import $ from 'jquery'
import { useContext } from 'react';
import ParamContext from '../../../Contexte';
import Icon from '@mdi/react';
import { mdiClipboardTextClock ,mdiClipboardClockOutline} from '@mdi/js';
import {ReactComponent as Produit} from '../../../img/icons/Produit.svg'
import {ReactComponent as CoeffM} from '../../../img/icons/coeffM.svg'
import {ReactComponent as CoeffS} from '../../../img/icons/coeffS.svg'
import {ReactComponent as Ref} from '../../../img/icons/ref.svg'
import  '../../../css/referentiel.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  }, 
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_prd_hst(props) {
    const classes = useStyles();
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const [open, setOpen] = useState(false);
    const [stat, setStat] = useState([]);
    const [ach, setAch] = useState([]);
    const [achTous, setTousAch] = useState([]);
    const [vnt, setVnt] = useState([]);
    const [vntTous, setTousVnt] = useState([]);
    const [search, setSearch] = useState('');
    const [search1, setSearch1] = useState('');
    const [tabIndex, setTabIndex] = useState(0);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info")
    const service="_prd.php"

    
    $(function() {
      $("div").removeAttr("data-tag")
    })  
    
    //handle alerts properties
    const handleAlert=(open,severity,message)=>{
        setOpenA(open)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
    }

    const handleClickOpen = () => { 
      fetchStat();
      setOpen(true); 
      if(tabIndex===0){
        fetchAch()
      }else{
        fetchVnt()
      }
    };

    const handleClose = () => { setOpen(false); };
   
    const fetchStat=async()=>{
         const response=await Gfunc.axiosPostAction(service,"gethstats",{idp:props.row.DT_RowId.substr(1)})
         if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,"error",response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
            setStat(response.data.jData)
          }) 
         }   
    }

    const fetchAch=async()=>{
        const response=await Gfunc.axiosPostAction(service,"gethachat",{idp:props.row.DT_RowId.substr(1)})
        if(response.data.err&&response.data.err!=="-1"){
          handleAlert(true,"error",response.data.err)
        }else
         Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
           setAch(response.data.jData)
           setTousAch(response.data.jData)
          }
         })
       
    }

    const fetchVnt=async()=>{
        const response=await Gfunc.axiosPostAction(service,"gethvente",{idp:props.row.DT_RowId.substr(1)})
        if(response.data.err&&response.data.err!=="-1"){
            handleAlert(true,"error",response.data.err)
        }else
         Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
           setVnt(response.data.jData)
           setTousVnt(response.data.jData)
          }
         })
    }


    const columnsA =useMemo(
      () => [ 
        {
          name: "Date",
          selector:row=>row.date,
          sortable: true,
          minWidth:'120px !important'
        },   
        {
          name: "Qte.",
          selector:row=>row.qte,
          sortable: true, 
          width:'90px !important'    
        },   
        {
          name: "tarif",
          selector:row=>row.prix,
          sortable: true, 
          width:'100px !important',    
          cell: (row) => {
            return (
              <div id={row.DT_RowId}>
                {new Intl.NumberFormat().format(row.prix)}
              </div>
             );
           }
        },   
        {
          name: "Fournisseur",
          selector:row=>row.frns,
          sortable: true,   
          width:'180px !important'    
        },   
        {
          name: "Achteur",
          selector:row=>row.agent,
          sortable: true,  
          width:'150px !important'     
        },   
        {
          name: "Stock",
          selector:row=>row.stock,
          sortable: true,  
          width:'120px !important'    
 
        },   
        {
          name: "Bon",
          selector:row=>row.bonNum,
          sortable: true,   
          minWidth:'120px !important',
          cell: (row) => {
            return (
              <div>
               <Detail_Bon row={row} val='prod'/>
              </div>
             );
           },
        },   
    ],[]);

    const columnsV =useMemo(
      () => [ 
        {
          name: "Date",
          selector:row=>row.date,
          sortable: true, 
          width:'130px !important'  
        },   
        {
          name: "Qte.",
          selector:row=>row.qte,
          sortable: true,  
          width:'90px !important'  ,
          Style:numberColumn
        },   
        {
          name: "tarif",
          selector:row=>row.prix,
          width:'98px !important'  ,
          sortable: true,   
          cell: (row) => {
            return (
              <div id={row.DT_RowId}>
                {new Intl.NumberFormat().format(row.prix)}
              </div>
             );
           },
        },   
        {
          name: "Client",
          selector:row=>row.client,
          sortable: true,   
          width:'170px !important'  ,
        },   
        {
          name: "Vendeur",
          selector:row=>row.agent,
          sortable: true,   
          minWidth:'140px !important'
        },   
        {
          name: "Stock",
          selector:row=>row.stock,
          sortable: true,
          width:'100px !important'   
        },   
        {
          name: "Ticket",
          sortable: true,
          minWidth:'150px !important',
          cell: (row) => {
            return (
              <div>
               <Detail_tiket row={row} val='prod'/>
              </div>
             );
           },
        },   
    ],[]);
    
    // get purchase data 
    const filtrerA=(e)=>{
      if(e.target.value!==''){
        var test=achTous.filter((el) =>  JSON.stringify(el).toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
        setAch(test)
      }else{setAch(achTous)}
    }
    
    //get dale data
    const filtrerV=useCallback((e)=>{
      if(e.target.value!==''){
        var test=vntTous.filter((el) => JSON.stringify(el).toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
        setVnt(test)
      }else{setVnt(vntTous)}
    },[vntTous])

    //change tab 
    const handleChangeTabs=(index)=>{
    if(index===0){
      fetchAch()
      }else{
        fetchVnt()
      }
    }

  return (
    <div>
      <Tooltip title="Historique d'achat / vente du produit ">
        <Icon path={mdiClipboardTextClock} size="20px" onClick={handleClickOpen}/>
      </Tooltip>
      <Dialog
          id="prdHisto"
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          PaperProps={paperPropsBigH}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
            <div className='DialogTitle'>
              <Icon path={mdiClipboardClockOutline} size={1} />
              <label>Historique du produit {props.row.produit}</label>
            </div> 
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
        <DialogContent >
        <div id='prdHisto-info'>
           <div id='item'>
              <Produit style={{height:'20px',width:'20px'}}/>
              <b>Nom du produit :</b>
              <p> {(props.row.produit)?props.row.produit:'---'}</p>
           </div>
           <div id='item'>
              <Ref style={{height:'20px',width:'20px'}}/>
              <b>Référence :</b>
              <p> {(stat.reference)?stat.reference+' jours':'---'}</p>
           </div>
           <div id='item'>
              <CoeffS style={{height:'18px',width:'18px'}}/>
              <b>Coefficient par semaine:</b>
              <p> {stat.weekAvg}</p>
           </div>
           <div id='item'>
              <CoeffM style={{height:'20px',width:'20px'}}/>
              <b>Coefficient par mois :</b>
              <p> {stat.monthAvg}</p>
           </div>
        </div>
        <br/>
        <Tabs selectedIndex={tabIndex} onSelect={index =>{ handleChangeTabs(index);setTabIndex(index)}}>
          <TabList>
            <Tab>Achat</Tab>
            <Tab>Vente</Tab>
          </TabList>
          <TabPanel>
             <div className='table-header' > 
              <div className='search' >
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField  className={classes.search} variant="outlined" id="input-with-sx" label="Recherche" size="small" margin='none' 
                                InputProps={{startAdornment: (<Search style={{ marginRight: '5px',zIndex:'1000' }} />),}}
                                onKeyPress={e => {filtrerA(e);setSearch1(e.target.value)}}/>
                </Box>
              </div>
              </div>
               <div className="accord-table">
                  <DataTable 
                      customStyles={customStyles}
                      progressComponent={<CircularProgress className={classes.circularProgress} />}
                      columns={columnsA}
                      data={ach}
                      noHeader={false}
                      noTableHead={false}
                      noDataComponent={param.ErreurData} 
                      highlightOnHover
                      pagination
                      paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous'}}
                  />
               </div>
          </TabPanel>
          <TabPanel>
              <div className='table-header' >  
                <div className='search'>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField  className={classes.search} variant="outlined" id="input-with-sx" label="Recherche"  size="small" margin='none' 
                                InputProps={{startAdornment: (<Search style={{ marginRight: '5px',zIndex:'1000' }} />),}}
                                onKeyPress={e=>{filtrerV(e);setSearch(e)}}/>
                  </Box>
                </div>  
              </div>
              <div className="accord-table">
                  <DataTable 
                     customStyles={customStyles}
                     progressComponent={<CircularProgress className={classes.circularProgress} />}
                     columns={columnsV}
                     data={vnt}
                     noHeader={false}
                     noTableHead={false}
                     noDataComponent={param.ErreurData} 
                     highlightOnHover
                     pagination
                     paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous'}}
                  />
              </div>
          </TabPanel>
        </Tabs>
        </DialogContent>
      </Dialog>
      </div>
  );
}
