import React, { useState, useCallback, useMemo } from 'react';
import DataTable from "react-data-table-component";
import {DialogActions,DialogContent,TextField,Paper,Button,Dialog,CircularProgress,FormControlLabel,Checkbox,IconButton,Typography } from '@material-ui/core';
import Draggable from 'react-draggable';
import {Add,PostAdd,Close,Search} from '@material-ui/icons';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { customStyles } from '../../../css/datatable-custom'
import {AlertCompo,NotAllowedComponent,NoDataComponent} from "../../../js/components"
import { Box } from '@mui/system';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import { useStyles,paperProps } from '../../../css/muiStyle';
import axios from "axios"
import { Autocomplete } from '@material-ui/lab';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_inv_details_add(props) {
  const lodashClonedeep = require('lodash.clonedeep');
  const param=useContext(ParamContext);
  const librery = require('../../../js/data_table_librery')
  const Gfunc = require('../../../Gfunc')
  const [data, setData] = useState([]);
  const [datach, setDatach] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [prod, setProd] = useState('');
  const [cat, setCat] = useState('');
  const [catL, setCatL] = useState([]); 
  const [open, setOpen] = useState(false);
  const [openA, setOpenA] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState('info');
  const[noData,setNoData]=useState(false); 
  const [ch, setCh] =useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[doSearch,setDoSearch]=useState(false);
  const [check,setCheck]=useState([])
  const classes = useStyles();
  const service = "_inv.php"

 
   //handle alerts properties
   const handleAlert=useCallback((open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
    setOpenA(false)
    }, 5000);
  },[])
 
//ouvrir le fentre de dialog
  const handleClickOpen = () => {
    fetchProd(cat,1)
    setCheck([]);
  };

//fermer le fenetre de dialog
  const handleClose = () => { 
    setOpen(false);
    setPerPage(10);
    setCurrentPage(1)  ;
    setCheck([]);setCh(false);
    setCat("");
  };

//recuperer la liste des produits
const fetchProd = async (val,page, size=perPage ) => {
  setLoading(true);
  const response = await axios.get(param.urlService + '_prd.php?do=getlite&page=' + page + "&per_page=" + size+'&produit='+
                                    prod+'&categorie='+val, { headers: Gfunc.getHeader() });
  setLoading(false)
  if(response.data.err&&response.data.err!=="-1"){
    setOpen(false)
    props.handleAlert(true,'error',response.data.err)
    setErrMessage(response.data.err)
    setNotAllowed(true)
  }else
  Gfunc.axiosResponse(response,()=>{
    if(response.data.data!==undefined){
      setOpen(true)
      setData(response.data.data);
      setDatach(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      setDoSearch(true)
      setNoData(true)
    }else {setDoSearch(false);setData([])}
  })
}
  
  //fetch categorie
  const fetchCat= useCallback(async(serv="_cat.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    Gfunc.axiosResponse(response,()=>{
        setCatL(response.data.jData);    
    })
  },[Gfunc])

  //fetch fournisseur handler
  const handleOpenCat = useCallback(async () => {
    if(catL.length===0) fetchCat()
    },[catL,fetchCat])

 //validation d'ajout d'inventaire
 const handleSubmit = async () => {
  const idss=[];const dats=[]
  if(props.data.length!==0){
    props.data.map((item)=>{
      dats.push(item.idp.toString())
    })
  }
  if(check.length!==0){
    check.map((item)=>{
      if(!dats.includes(item.DT_RowId.substr(1))){
        idss.push(item.DT_RowId.substr(1))
      }else{
        handleAlert(true,'error',"le produit existe déjà")
      }
    })
   if (idss.length !== 0) {
    const response = await Gfunc.axiosPostAction(service, "addprods", {idi:props.idi.substr(1),ids: idss })  
       Gfunc.axiosResponse(response, () => {
       if (response.data.jData) {
         props.handleAlert(true,'success',"Le produit est ajouté")
         props.fetch();
         setOpen(false)
         setCheck([])
         setCh(false)
       }
     })
   }
  }else handleAlert(true,'error',"Vous devez sélectionné au moins un produit")
}

const HandleChange=useCallback((e,row)=>{
  if(e.target.checked===true){
    const ind=check.findIndex((obj => obj.DT_RowId ===row.DT_RowId));
    if(ind===-1){check.push(row)}
 }
 else{
     const ind=check.findIndex((obj => obj.DT_RowId ===row.DT_RowId))
     check.splice(ind,1)
 }
},[check])

    
const test=useCallback((val)=>{
  setCh(val)
  if(val===true){
    setCheck(lodashClonedeep(datach))
  }else{
    setCheck([])
  } 
},[datach])

const Ch=useCallback(()=>{
  return(
    <div className='head-check'>
    <FormControlLabel control={<Checkbox  className={classes.checkStelectableRow} defaultChecked={ch}
                  onChange={e=>test(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }}/>}
               />
    </div>
  )
},[test,ch])

  const columns = useMemo(
    () => [
      {
        name:<Ch/>,
        width:"55px",
        cell: (row) => {
          return (
            <div  key={Math.floor(Math.random() * (1000 - 1 + 1)) + 1} className="table-inv">
               <FormControlLabel control={<Checkbox defaultChecked={Gfunc.getIndex(check,row.DT_RowId,"DT_RowId")>-1} className={classes.checkStelectableRow}
                  onChange={e=>HandleChange(e,row)} inputProps={{ 'aria-label': 'controlled' }}/>}
               />
            </div>
           );
         },
      },
      {
        name: "ID",
        selector: row=>row.DT_RowId,
        sortable: true,
        cell: (row) => {
          return (
            <div className="addedCell">
{              ('000000' + row.DT_RowId.substr(1)).substring(Math.min(("" + row.DT_RowId.substr(1)).length, 6))  
}             </div>
          )
        }
      },
      {
        name: "PRODUIT",
        width:'300px',
        selector:  row=>row.produit,
        sortable: true,
        sortField:'produit'
      },
      {
        name: "CATEGORIE",
        selector:  row=>row.categorie,
        sortable: true,
        sortField:'categorie'
      }

    ], [check,HandleChange,test,check,Ch,ch,datach,data]);

  //trier le tableau
  const handleOnSort = async (column, sortdirection, page = currentPage, size = perPage) => {
    setLoading(true);
    const response = await librery.axiosSort(column, sortdirection, page, size, "_prd.php",'getlite')
    Gfunc.axiosResponse(response, () => {
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      setLoading(false);
    })
  }

  function handlePageChange(currentPage) {
    fetchProd(cat,currentPage,perPage);
    setCurrentPage(currentPage);
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchProd(cat,page,newPerPage);
    setPerPage(newPerPage);
  }

  const handleSearch = async (size = perPage) => {
    setLoading(true);
    setCurrentPage(1)
    const response = await librery.axiosGet2(1+'&produit='+prod+'&categorie='+cat,"_prd.php",'getlite',  size,)
    Gfunc.axiosResponse(response, () => {
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })
    setLoading(false);
    return response
  }

  function liveSearch(e, search) {
    if (param.liveSearch && search.length > 3) {
      e.preventDefault();
      handleSearch();
    } else {
      if (e.keyCode === 13 ) {
       // e.preventDefault();
        handleSearch();
      }
    }
  }
  function liveSearch2(e, search) {
        handleSearch();
  }

//valider formulaire avec touche clavier 
const detectTouche= (e)=>{
  if (e.target.name!=="search"&&e.keyCode === 13) {
      e.preventDefault();
      if( check.lenght===0){
        handleAlert(true,"error","Vous devez sélectionné au moins un produit")
      }else  handleSubmit();     
    }
  }

  return (
    <div >
      <Button variant="contained"  color="secondery" onClick={handleClickOpen} startIcon={<PostAdd />} 
              className={classes.topTabBtn}>AJOUTER PRODUIT</Button>
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={paperProps}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}> 
            <div className='DialogTitle'>
              <Icon path={mdiPlusBox} size={1} />
              <label>Ajouter des produit(s) à {props.row.inventaire}</label>
            </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
        <DialogContent >
          <div className='table-header'>
          <div className='search'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined" id="input-with-sx" label="Recherche" value={prod} size="small" 
                               InputProps={{startAdornment: (<Search style={{ marginRight: '5px',zIndex:'1000' }} />),}} 
                               margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setProd(e.target.value)} />
                </Box>
                 <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{setCat(catL[Gfunc.getIndex(catL,newValue,"nom")].id);
                                              fetchProd(catL[Gfunc.getIndex(catL,newValue,"nom")].id,1)}}
                    onFocus={handleOpenCat}
                    options={catL.map((option) => option.nom)}
                    className="smallAutoFiltre"
                    renderInput={(params) =>  
                            <TextField {...params} margin='dense' size='small'  variant="outlined"  label="Catégorie" 
                                       SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                          />}
                 />
            </div>
          </div>
          <ValidatorForm noValidate onSubmit={handleSubmit} onKeyDown={e=>detectTouche(e)} id="form2" >
          {(data.length!==0)?
           <div>
            <DataTable 
              className="table"
              id='datatable'
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              defaultSortAsc={true}
              paginationRowsPerPageOptions={[10, 20, 30]}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles}
              paginationServerOptions={{persistSelectedRowsOnSortChange: true}}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
              progressComponent={<CircularProgress className="circularProgress" />}
            />
            </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
            <br />
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" form='form2' type='submit' color="primary" startIcon={<Add />}>AJOUTER</Button>
        </DialogActions>
      </Dialog>
    </div>

  );
}