import React, {useState,useCallback,useMemo,useEffect } from 'react'
import { ValidatorForm} from 'react-material-ui-form-validator';
import {Button,Input, Menu} from '@material-ui/core';
import {Delete,Block,CheckCircle,KeyboardBackspace,Print, Category, Person, CalendarToday, Build, Lock, ArrowDropDown, ArrowDropUp} from '@material-ui/icons';
import {customStyles} from '../../../css/datatable-custom'
import DataTable from "react-data-table-component";
import { useStyles } from '../../../css/muiStyle';
import {CircularProgress,FormControlLabel,Checkbox} from '@material-ui/core';
import {theme} from "../../../css/theme"
import Draggable from './ref_inv_details_add'; 
import {AlertCompo,NoDataComponent,NotAllowedComponent} from "../../../js/components"
import { useHistory } from 'react-router-dom';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import {ReactComponent as Inv} from '../../../img/icons/inventaire.svg'
import { MenuItem } from 'material-ui';
import '../../../css/global.css'
import '../../../css/inventaire.css'


export default function Ref_inv_details(props) {
 const Gfunc=require('../../../Gfunc')
 const param=useContext(ParamContext);
 const [data,setData]=useState([])
 const [idDecom, setidDecom] = useState('');
 const [dataUsr,setDataUser]=useState([]);
 const [id,SetId]=useState("");
 const [idLock,SetIdLock]=useState(false);
 const [quantite1,SetQantite1]=useState(0)
 const [choix,SetChoix]=useState("")
 const [openA, setOpenA] =useState(false);
 const [messageA, setMessageA] =useState('');
 const [severity, setSeverity] =useState('info');
 const [noData,setNoData]=useState(false); 
 const [notAllowed,setNotAllowed]=useState(false);
 const [check,setCheck]=useState([])
 const [errMessage,setErrMessage]=useState('');
 const [anchorEl, setAnchorEl] = React.useState(null);
 const history = useHistory();
 const classes = useStyles();
 const service='_inv.php'

 const routeChange = (newPath) =>{ 
  let path = newPath; 
  history.push(path);
}

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};

 var locked=JSON.parse(localStorage.getItem('locked'))
 var row1=''
 if(props.location.row){
   row1=props.location.row;
   localStorage.setItem('row1',JSON.stringify(row1))    
 }else {
  row1=JSON.parse(localStorage.getItem('row1'))
}

//recuperer detail d'inventaire
const fetch= useCallback(async(servic = service) => {
    const response = await Gfunc.axiosPostAction(service ,'getdet',{idi:row1.DT_RowId.substr(1)})
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
      setErrMessage(response.data.err)
      setNotAllowed(true)
    }else
    Gfunc.axiosResponse(response, () => {
      if(response.data.jData!==undefined) {
        setData(response.data.jData.detail)
        setNoData(true)
      }
    })
  },[Gfunc,row1.DT_RowId]);

//recuperer les utilisateurs
const fetchUser= useCallback(async() => {
    const response = await Gfunc.axiosGetAction('_usr.php' ,'gets')
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response, () => {
      setDataUser(response.data.jData)
    })
  },[Gfunc]);

  useEffect(() => {
    fetch();
    fetchUser();
  }, [fetch,fetchUser]);
  
//ajouter comptage 
const HandleValider=useCallback(async(row)=>{
  var quantite=parseInt(document.getElementById('I'+row.idp).value)
  SetQantite1(quantite)
  var usr= parseInt(document.getElementById('S'+row.idp).options[document.getElementById('S'+row.idp).selectedIndex].value)
  var idi=parseInt(row1.DT_RowId.substr(1)) 
  var objIndex =dataUsr.findIndex(obj => obj.id===usr)
  if(objIndex!==-1){
      var nom=dataUsr[objIndex].name
      SetChoix(nom)
   }
  const response = await Gfunc.axiosPostAction(service ,'comptage',{idi:idi,idd:row.idi,qte:quantite,idu:usr})
  if(response.data.err&&response.data.err!=="-1"){
    handleAlert(true,'error',response.data.err)
  }else
    Gfunc.axiosResponse(response, () => {
        if(response.data.jData===true){
          SetId(row.idp)   
        }
    })
},[Gfunc,row1,dataUsr])

 //handle alerts properties
 const handleAlert=(open,severity,message)=>{
  setOpenA(true)
  setSeverity(severity)
  setMessageA(message)
  setTimeout(function(){
    setOpenA(false)
  }, 5000);
}

//supprimer un item
  const handleDeleteItem=useCallback(async(row)=>{
    document.getElementById('I'+row.idp).value=''
    document.getElementById('S'+row.idp).value=''
    if(row1.clotured===false){
      const response = await Gfunc.axiosPostAction(service ,'deldet',{idd:row.idi})
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==0){
            fetch()      
        }
      })
    }
   else{
    handleAlert(true,'success',"L'inventaire est clôturé")
   }
  },[Gfunc,row1,fetch])


//verouillez l'inventaire
 const handleLock=useCallback(async()=>{
      var comp=0;
      data.map((item)=>{
      if(item.cpt!==null){
        comp=comp+1
        }
        return comp
      })
      if(comp===data.length){
          const response = await Gfunc.axiosPostAction(service ,'lock',{idi:row1.DT_RowId.substr(1)})
          if(response.data.err&&response.data.err!=="-1"){
            handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response, () => {
                if(response.data.jData===true){
                  handleAlert(true,'success',"L'inventaire est verrouillé")
                  SetIdLock(true)
                }
            })    
      }else{
        handleAlert(true,'warning',"Vous ne pouvez pas verouiller l'inventaire avant d'avoir fini les comptages!")
  }
  },[Gfunc,data,row1])


//decompter un detail
const handleDecount=useCallback(async(row)=>{
  const response = await Gfunc.axiosPostAction(service ,'decomptage',{idd:row.idi})
  if(response.data.err&&response.data.err!=="-1"){
    handleAlert(true,'error',response.data.err)
  }else
  Gfunc.axiosResponse(response, () => {
        if(response.data.jData===true){
          setidDecom(row.idp)
          SetChoix('')
          SetQantite1('')
        }
  })
},[Gfunc])

const HandleChange=useCallback((e,row)=>{
  if(e.target.checked===true){
    const ind=check.findIndex((obj => obj.idp ===row.idp));
    if(ind===-1){check.push(row)}
 }
 else{
     const ind=check.findIndex((obj => obj.idp ===row.idp))
     check.splice(ind,1)
 }
},[check])

//les colomns de tableau
 const columns =useMemo(
    () => [ 
      {
        width:"55px",
        cell: (row) => {
          return (
            <div key={Math.floor(Math.random() * 100)}>
               <FormControlLabel control={<Checkbox defaultChecked={Gfunc.getIndex(check,row.idp,"idp")>-1} className={classes.checkStelectableRow}
                  onChange={e=>HandleChange(e,row)} inputProps={{ 'aria-label': 'controlled' }}/>}
               />
            </div>
           );
         },
      },
      {
        name: "ID",
        selector: row=>row.idp,
        minWidth:"100px !important",
        sortable: true,
        cell: (row) => {
          var id1=row.idp
            return (
               <div>{('000000'+id1).substring(Math.min((""+id1).length,6))} </div>      
             );
        }, 
      },
      {
        name: "PRODUIT",
        selector: row=>row.produit,
        sortable: true,
        minWidth:"250px !important"
      },
     {
        name: "COMPTAGE",
        selector: row=>row.cpt,
        minWidth:"150px !important",
        sortable: true,
        cell: (row) => {
          if(row.idp===id){
            row.cpt=quantite1
          }
          if(row.idp===idDecom){
            row.cpt=null
          }
          setTimeout(() => { SetId('')},1) ;
          setTimeout(() => { setidDecom('')},1) ;
        return (
          <div className="comptageContent">
          {
           (row.cpt===null )?
                <ValidatorForm onSubmit={()=>HandleValider(row)}>
                  <Input type='number' id={'I' + row.idp} className="dtInput" /><br/>
                  <select id={'S' + row.idp} className="dtInput">
                    <option defaultValue></option>
                    {  
                    (dataUsr.lenght !== 0) ? dataUsr.map((usr, index) => { 
                    return (<option key={index} value={usr.id}>{usr.name}</option>
                    );
                    }) : <></> }
                  </select>
                  <Button variant="contained" type='submit' className={classes.tdIconButton1} title="Effectuer le comptage" ><CheckCircle fontSize='small' className={classes.greenIcon}/></Button><br /><br />
                </ValidatorForm>
           :<div>{row.cpt}</div>
          
          }
          </div>
         );
        }   
    },
    {
      name: "AGENT",
      sortable: true,
      minWidth:'100px !important',
      cell: (row) => {
        if(row.idp===id){
          row.user_cpt=choix
        }
        if(row.idp===idDecom){
          row.user_cpt=null
        }
        setTimeout(() => { SetId('')},1) ;
        setTimeout(() => { setidDecom('')},1) ;
        return (
          <div className="comptageContent">
          {(row.user_cpt!==null)?<div>{row.user_cpt}</div>:<></>}
         </div>
         );     
    }  
    },
     {
       width:"45px !important",
       right:true,
       cell: (row) => {
        if(row.idp===idDecom){
          row.cpt=null;
        }
         return (
           <div style={{display:'grid',gridTemplateColumns:'50% 50%'}}>
             {(row.cpt!==null && row1.clotured===false && (row1.locked===false|| locked===false))?<div title="Decompter"><Block style={{color:theme.palette.secondary.dark}} fontSize="small" className={classes.tdIcon} onClick={() => handleDecount(row) } /></div>:<></>}   
             {(row1.clotured === false && (row1.locked===false|| locked===false)) ? <div title="Supprimer"><Delete style={{color:theme.palette.error.main}} fontSize="small" className={classes.tdIcon} onClick={() => handleDeleteItem(row)} /></div> : <></>}
           </div>
         );
       },
     }
    ],[HandleValider,handleDecount,handleDeleteItem,id,quantite1,idDecom,dataUsr,row1,classes,choix,locked]);
//lien vers la page d'acceuil
function backHome(){
  routeChange('/r/ri')
}

//lien vers la page d'impression
function Imprimer(){
  routeChange('/r/ri/dr/im')
  localStorage.setItem('row1',JSON.stringify(row1))    
}

//lien vers la page de la cloture
function Cloturer(){
  routeChange('/r/ri/cr')
}


//supprimer un ensemble d'elements
const DeleteMultiple=useCallback(async()=>{
  if(check.length>0){
      var idi=[]
      check.map((item)=>{
          idi.push(item.idi)
          return true;
          })
      const response = await Gfunc.axiosPostAction(service ,'deldets',{ids:idi})
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response, () => {
        if(response.data.jData){
            fetch();
            handleAlert(true,"success","Les éléments sélectionnés ont été supprimé avec succès")
            setCheck([])
        }
    })
  }else handleAlert(true,'warning',"Vous devez sélectionner au moins un élément!")
},[Gfunc,check,fetch])

//supprimer les produits inactifs
const DeleteProdInactif=useCallback(async()=>{
  const response = await Gfunc.axiosPostAction(service ,'delempty',{idi:row1.DT_RowId.substr(1)})
  if(response.data.err&&response.data.err!=="-1"){
    handleAlert(true,'error',response.data.err)
  }else
  Gfunc.axiosResponse(response, () => {
      if(response.data.jData!==0){
      fetch()
      handleAlert(true,'success','Les produits inactifs sont supprimés')
    } else handleAlert(true,'warning','Aucun produit inactif')
  })
},[row1,Gfunc,fetch])
    
    return (
      <div className='content'>
       <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
        <br/>
        <div id='inv-btn-div'>
            <div title="Retour à inventaire du stock">
              <KeyboardBackspace style={{height:'20px',width:'24px',cursor:'pointer'}} onClick={() => { backHome() }}/>
            </div>
            <Draggable param={param} id="bt-1-3" idi={row1.DT_RowId} data={data} fetch={fetch} row={row1} handleAlert={handleAlert}/> 
            {(row1.locked === false && idLock===false) 
              ?<Button variant="contained" type='submit' onClick={() => { handleLock() }} className={classes.topTabBtn}  
                       color="secondary" startIcon={<Lock/>} >Verrouiller</Button> 
              :<Button variant="contained" type='submit' onClick={() => { Cloturer() }} className={classes.topTabBtn}  
                       color="secondary" startIcon={< Build/>}>Clôturer</Button>}
           <div>
            <Button aria-controls="dropdown-menu" aria-haspopup="true" onClick={handleClick} variant="contained" color="secondery" 
                    className={classes.topTabBtn} startIcon={(!Boolean(anchorEl))?<ArrowDropDown/>:<ArrowDropUp/>}> Supprimer </Button>
            <Menu id="dropdown-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} 
                  style={{marginTop:"50px"}} >
              <MenuItem onClick={DeleteMultiple}><Delete/>  SUPPRIMER LES SÉLÉCTIONS </MenuItem>
              <MenuItem onClick={DeleteProdInactif}><Delete/>  SUPPRIMER LES NON COMPTÉS</MenuItem>
            </Menu>
          </div>
          <div onClick={Imprimer} title="Imprimer la liste des produits d'un inventaire">
              <Print style={{height:"20px",width:"20px",cursor:'pointer'}}/>
          </div>
        </div>
        <div id='inv-det-infos'>
           <div id='item'>
              <Inv style={{height:'20px',width:'20px'}}/>
              <b>Nom:</b>
              <p>{row1.inventaire}</p>
           </div>
           <div id='item'>
              <Category style={{height:'20px',width:'20px'}}/>
              <b>Catégorie:</b>
              <p> {(row1.categorie)?row1.categorie:'---'}</p>
           </div>
           <div id='item'>
              <CalendarToday style={{height:'18px',width:'18px'}}/>
              <b>Date:</b>
              <p>{row1.datin}</p>
           </div>
           <div id='item'>
              <Person style={{height:'20px',width:'20px'}}/>
              <b>Par:</b>
              <p>{row1.user}</p>
           </div>
        </div>
        {(data.length!==0)?
        <div id='divName'>
          <DataTable
            columns={columns}
            data={data}
            noDataComponent={param.ErreurData}
            defaultSortAsc={true}
            paginationRowsPerPageOptions={[10, 20, 30, 100]}
            pagination
            highlightOnHover
            customStyles={customStyles}
            paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
            contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
            progressComponent={<CircularProgress className="circularProgress" />}
          />
          </div>
          :(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </div>
    )
}