import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,Tooltip,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Close, Link} from '@material-ui/icons';
import {ReactComponent as ScanBarcode} from '../../../img/icons/scanBarcode.svg'
import {TextField} from '@material-ui/core';
import { ValidatorForm} from 'react-material-ui-form-validator';
import  {useStyles,paperPropsSmallTall} from '../../../css/muiStyle';
import  '../../../css/retour.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox, mdiSetAll } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_prd_cd(props) {
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] = useState(false);
    const [errorC, setErrorC] = useState(false);
    const [cb, setCb] = useState('');
    const classes = useStyles();
    const service="_prd.php"

    
    const handleClickOpen = () => { 
      setCb('');;setErrorC(false)
      setOpen(true); 
    };
    const handleClose = () => { setOpen(false); };
   
    const handleAdd=async()=>{
        if(cb!==''){
          const response=await Gfunc.axiosPostAction(service,"addcb",{produitId:parseInt(props.row.DT_RowId.substr(1)),cb:cb})
          if(response.data.err&&response.data.err!=="-1"){
            props.handleAlert(true,"error",response.data.err)
         }else
         Gfunc.axiosResponse(response,()=>{
            if(response.data.jData===true){
              props.callback()
              setOpen(false)
              props.handleAlert(true,"success","Un code à barres est associé avec succès ")
            }
         })
        }else{
          setErrorC(true)
        }
      }
   
    //valider formulaire avec touche d'entrer
   const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="addBtn")) {
          alert("Vous devez remplir tous les champs!")
      }
    }

  return (
    <div>
      <Tooltip title="Associer un code à barres"><ScanBarcode style={{height:'22px',width:'22px',padding:"1px"}} onClick={handleClickOpen}/></Tooltip>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
            <div className='DialogTitle'>
              <Icon path={mdiSetAll} size={1} />
              <label> Associer un code à barres à {props.row.produit}</label>
            </div>
        </DialogTitle>
        <ValidatorForm  noValidate  id="form" ref={useRef(null)} onSubmit={handleAdd} onKeyDown={e=>detectTouche(e)} >
            <DialogContent >
              <TextField  error={errorC} className='smallText' margin='dense' variant='outlined' size="small" label='Code à barres' value={cb} 
                          onChange={e =>{setCb(e.target.value)}} title='Insérer le code à barres' fullWidth autoFocus required 
                          helperText={(errorC)?"Veuillez sélectionner le code":null}/>
            
            </DialogContent>
            <DialogActions>
               <Button variant="contained" name="iValide" form="form" type='submit' color="primary" startIcon={<Link/>}>Associer</Button>
            </DialogActions>
            </ValidatorForm>

      </Dialog>
      </div>
  );
}
