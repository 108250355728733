import React, { useState, useRef, useCallback, useMemo } from 'react';
import DataTable from "react-data-table-component";
import { DialogActions, DialogContent,TextField, Paper, Button, Dialog,FormControlLabel,Checkbox,CircularProgress,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { customStyles } from '../../../css/datatable-custom'
import { Search ,PostAdd, Add,Close} from '@material-ui/icons';
import {AlertCompo,NotAllowedComponent,NoDataComponent} from "../../../js/components"
import { Box } from '@mui/system';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { useStyles,paperProps } from '../../../css/muiStyle';
import  '../../../css/global.css'
import  '../../../css/inventaire.css'
import axios from "axios"
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_inv_add2(props) {
  const lodashClonedeep = require('lodash.clonedeep');
  const param=useContext(ParamContext);
  const librery = require('../../../js/data_table_librery')
  const Gfunc = require('../../../Gfunc')
  const [data, setData] = useState([]);
  const [datach, setDatach] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [prod, setProd] = useState('');
  const [cat, setCat] = useState('');
  const [catL, setCatL] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const service = "_inv.php"
  const classes = useStyles();
  const [nom, setNom] = useState('');
  const [openA, setOpenA] =useState(false);
  const [ch, setCh] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState('info');
  const [noData,setNoData]=useState(false); 
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[doSearch,setDoSearch]=useState(false);
  const [check,setCheck]=useState([])
  
  const handleClickOpen = () => {
    setNom('');setProd('');
    setOpen(true); setOpenA(false); setError(false);setCheck([]);setCh(false);setError(false)
    fetchProd(cat,currentPage,perPage)
    
  };
  const handleClose = () => {
      setOpen(false);setCheck([])
      setPerPage(10);setCh(false)  
      setCurrentPage(1);setCat('');setProd('')
  };

  //fetch categorie
  const fetchCat= useCallback(async(serv="_cat.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    Gfunc.axiosResponse(response,()=>{
        setCatL(response.data.jData);    
    })
  },[Gfunc])

  //fetch fournisseur handler
  const handleOpenCat = useCallback(async () => {
    if(catL.length===0)
    fetchCat()
    },[catL,fetchCat])

  function handlePageChange(currentPage) {
    setCh(false) 
    fetchProd(cat,currentPage, perPage);
    setCurrentPage(currentPage);
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchProd(cat,page, newPerPage);
    setPerPage(newPerPage);
  }

  const fetchProd = async (val,page, size=perPage ) => {
    setLoading(true);
    const response = await axios.get(param.urlService + '_prd.php?do=getlite&page=' + page + "&per_page=" + size+'&produit='+prod+'&categorie='+val, { headers: Gfunc.getHeader() });
    if(response.data.err&&response.data.err!=="-1"){
      setOpen(false)
      props.handleAlert(true,'error',response.data.err)
      setErrMessage(response.data.err)
      setNotAllowed(true)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setOpen(true)
      setData(response.data.data);
      setDatach(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      setDoSearch(true)
      setNoData(true)
      }else {setDoSearch(false);setData([])}
    })
  setLoading(false)
  }

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

//validation d'ajout d'inventaire
  const handleSubmit = async () => {
    const idss=[]
    setError(false)
    if(nom === '') { setError(true);}
    if(nom!==''){
      check.map((item)=>{
        idss.push(item.DT_RowId.substr(1))
      })
      const response = await Gfunc.axiosPostAction(service,'add',{ name: nom, ids: idss });
      if(response.data.err){
       handleAlert(true,'error',response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            props.handleAlert(true,"success","L'inventaire est ajouté")
            setOpen(false)
            props.callback()
            setCheck([])
            setCh(false)  
          }  
        })
      } 
    }
  }

  const HandleChange=useCallback((e,row)=>{
    if(e.target.checked===true){
      const ind=check.findIndex((obj => obj.DT_RowId ===row.DT_RowId));
      if(ind===-1){check.push(row)}
   }
   else{
       const ind=check.findIndex((obj => obj.DT_RowId ===row.DT_RowId))
       check.splice(ind,1)
   }
  },[check])
  
  const test=useCallback((val)=>{
    setCh(val)
    if(val===true){
      setCheck(lodashClonedeep(datach))
    }else{
      setCheck([])
    } 
  },[datach])

  const Ch=useCallback(()=>{
    return(
      <div className='head-check'>
        <FormControlLabel control={<Checkbox  className={classes.checkStelectableRow} defaultChecked={ch}
                          onChange={e=>test(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }}/>}/>
      </div>
    )
  },[test,ch])

  const columns = useMemo(
    () => [
      {
        name:<Ch/>,
        width:"55px",
        cell: (row) => {
          return (
            <div key={Math.floor(Math.random() * (1000 - 1 + 1)) + 1} className="table-inv">
               <FormControlLabel control={<Checkbox defaultChecked={Gfunc.getIndex(check,row.DT_RowId,"DT_RowId")>-1} 
                                 className={classes.checkStelectableRow} onChange={e=>HandleChange(e,row)} 
                                 inputProps={{ 'aria-label': 'controlled' }}/>}
               />
            </div>
           );
         },
      },
      {
        name: "ID",
        selector: row=>row.DT_RowId,
        sortable: true,
        minWidth:"80px !important",
        cell: (row) => {
          return (
            <div className="addedCell">
               {('000000' + row.DT_RowId.substr(1)).substring(Math.min(("" + row.DT_RowId.substr(1)).length, 6))}  
            </div>
          )
        }
      },
      {
        name: "PRODUIT",
        selector: row=>row.produit,
        sortable: true,
        sortField:'produit',
        minWidth:"280px !important",
        cell: (row) => {
          return (
             <div title={row.produit}>{row.produit}</div>
           );
         },
      },
      {
        name: "Catégorie",
        selector: row=>row.categorie,
        sortable: true,
        sortField:'categorie',
        minWidth:"150px !important",
      }
    ], [check,Gfunc,HandleChange,test,check,Ch,ch,datach,data]);

  const handleOnSort = async (column, sortdirection, page = currentPage, size = perPage) => {
    setLoading(true);
    const response = await librery.axiosSort(column, sortdirection, page, size, "_prd.php",'getlite')
    Gfunc.axiosResponse(response, () => {
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      setLoading(false);
    })
  }

  const handleSearch = async (size = perPage) => {
    setLoading(true);
    setCurrentPage(1)
    const response = await librery.axiosGet2(1+'&produit='+prod+'&categorie='+cat,"_prd.php",'getlite',  size,)
    Gfunc.axiosResponse(response, () => {
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })
    setLoading(false);
    return response
  }

  function liveSearch(e, search) {
    if (param.liveSearch && search.length > 3) {
      e.preventDefault();
      handleSearch();
    } else {
      if (e.keyCode === 13 ) {
       // e.preventDefault();
        handleSearch();
      }
    }
  }
  function liveSearch2(e, search) {
        handleSearch();
  }
 
//valider formulaire avec la touche clavier 
const detectTouche= (e)=>{
  setError(false)
  if (e.keyCode === 13) {
      if(nom===''){
        setError(true)
        handleAlert(true,"warning","Vous devez renseigner tous les champs")
      }else{handleSubmit()}
  }
}

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<PostAdd />} className={classes.topTabBtn}>Inventaire personnalisé</Button>
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={paperProps}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
           <div className='DialogTitle'>
              <Icon path={mdiPlusBox} size={1} />
              <label>Ajouter un inventaire personnalisé</label>
            </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
        <DialogContent >
          <ValidatorForm noValidate ref={useRef(null)} onSubmit={handleSubmit} onKeyDown={e=>detectTouche(e)} id="form2" >
           <div className='table-header'>
            <div className='search'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined" label="Recherche" value={prod} size="small" margin='none' 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()} style={{ marginRight: '5px',zIndex:'1000',
                                           cursor:'pointer' }} />),}} 
                               onKeyUp={e => liveSearch(e)} onChange={e => setProd(e.target.value)} />
                </Box>
                <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{setCat(catL[Gfunc.getIndex(catL,newValue,"nom")].id);
                                              fetchProd(catL[Gfunc.getIndex(catL,newValue,"nom")].id,1)}}
                    onFocus={handleOpenCat}
                    options={catL.map((option) => option.nom)}
                    className="smallAutoFiltre"
                    renderInput={(params) =>  
                            <TextField {...params} margin='dense' size='small'  variant="outlined"  label="Catégorie" 
                                       SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                          />}
                />
            </div>
          </div>
          <div style={{width:"98%",margin:'10px auto 10px auto'}}>
           {(doSearch)?
              <TextField  size='small' label="Nom de l'inventaire" id="iName-t" name="iName" value={nom} autoFocus  className='smallText' margin='dense' variant='outlined'
                          onChange={e => setNom(e.target.value)} required  error={error} 
            />:null}
          </div>
          {(data.length!==0||doSearch)?
          <div>
            <DataTable 
              className="table-inv"
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              defaultSortAsc={true}
              paginationRowsPerPageOptions={[10, 20, 30]}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles}
              paginationServerOptions={{persistSelectedRowsOnSortChange: true}}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
              progressComponent={<CircularProgress className="circularProgress" />}
            />
            </div>
            :(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
            </ValidatorForm>
            <br />
        </DialogContent>
        <DialogActions>
              <Button variant="contained" form="form2" name="addBtn"type='submit' color="primary" startIcon={<Add />}>AJOUTER</Button>
        </DialogActions>
      </Dialog>
    </div>

  );
}