import React,{useState,useCallback,useEffect,useMemo} from "react";
import DataTable from "react-data-table-component";
import {Backdrop, CircularProgress, Paper} from '@material-ui/core';
import '../../css/irh.css'
import { useStyles } from '../../css/muiStyle';
import {customStyles}from '../../css/datatable-custom'
import {NoDataComponent,NotAllowedComponent,BackdropCompo, CustomizedProgressBars} from "../../js/components"
import Mobilis from '../../img/icons/Mobilis.png'
import Ooredoo from '../../img/icons/Ooredoo.png'
import Djezzy from '../../img/icons/Djezzy.png'
import Binar from '../../img/logos/Logo-Binar-orange.png'


export default function Irh_art (props){
 const classes=useStyles()
 const Gfunc=require('../../Gfunc')
 const librery=require('../../js/data_table_librery')
 const param=props.param;
 const [data, setData] = useState([]);
 const [sold, setSold] = useState('');
 const [seuil, setSeuil] = useState('');
 const [loading, setLoading] = useState(false);
 const[noData,setNoData]=useState(false);
 const[notAllowed,setNotAllowed]=useState(false);
 const[focus,setFocus]=useState(false);
 const[errMessage,setErrMessage]=useState('');
 const[records,setRecords]=useState('');
 const service='_irh.php'

 const conditionalRowStyles=[
  { when: row => !row.isVente&&row.mtn>0,
     style: {
           color: "green",
     }
   },
  { when: row => !row.isVente&&row.mtn<=0,
     style: {
           color: 'red',
     }
  },
  { when: row => row.isVente&&row.mtn<0,
     style: {
           color: "green",
     }
   },
  { when: row => row.isVente&&row.mtn>=0,
     style: {
           color: 'red',
     }
  }
]

 const fetch= useCallback(async() => {
        setFocus(true)
        setLoading(true);
        const response = await Gfunc.axiosGetAction(service,"getathist")  
        if(response.data.err&&response.data.err!=="-1"){
            setErrMessage(response.data.err)
            setNotAllowed(true)
          }else{
            Gfunc.axiosResponse(response,()=>{
                setRecords(response.data.recordsFiltered)
                if(response.data.jData!==undefined && response.data.jData.length!==0){
                  setData(response.data.jData);
                  setNoData(true)
                }else {setData([])}
            })
          } 
          setLoading(false)
  },[Gfunc,librery]);
  
  const fetchSold= useCallback(async() => {
    const response = await Gfunc.axiosGetAction(service,"getatsold")  
    if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else{
        Gfunc.axiosResponse(response,()=>{
            if(response.data.jData){
              setSold(response.data.jData);
            }
        })
      } 
},[Gfunc,librery]);

  const fetchSeuil= useCallback(async() => {
    const response = await Gfunc.axiosGetAction(service,"getatseuil")  
    if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else{
        Gfunc.axiosResponse(response,()=>{
            if(response.data.jData){
              setSeuil(response.data.jData);
            }
        })
      } 
},[Gfunc,librery]);
  useEffect(() => {
    if(!focus){
      fetch(1);
      fetchSeuil();
      fetchSold();
    }
  }, [fetch,fetchSeuil,fetchSold,Gfunc]);
  
  const columns =useMemo(
    () => [ 
      {
        width:'28px !important',
         cell: (row) => {
             return (
                <div>
                 {
                  (row.produit==='Flexy'||row.produit==='Storm'||row.produit==='Arselli'||row.produit==='Binar')?
                   <img src ={(row.produit==='Flexy')?
                               Djezzy:
                               (row.produit==="Storm")?
                               Ooredoo :
                               (row.produit==="Arselli")?
                               Mobilis:
                               Binar
                             }
                      alt="digit" style={{width: "18px", height:"auto"}}/>
                  :null 
                 }
                </div>   
             );
            },   
      },
      {
        name: "DATE",
        selector:row=>row.date,
        sortable: true,
        sortField:"date",
        minWidth:'130px !important'
      },
      {
        name: "Numéro",
        selector: row=>row.numero,
        sortable: true,
        sortField:"numero",
        minWidth:'130px !important'
      },  
      {
        name: "Produit",
        selector:row=>row.produit,
        sortable: true,
        sortField:"produit",
        minWidth:'130px !important'
      },  
      {
        name: "Qte.",
        selector:row=>row.qte,
        sortable: true,
        sortField:"qte",
        width:'90px !important',
        cell:(row)=>{
          return(
            <div>{(row.qte!==null)?Intl.NumberFormat().format(parseFloat(row.qte)):null}</div>
          )
        }
      },  
      {
        name: "Montant",
        selector:row=>row.mtn,
        sortable: true,
        sortField:"mtn",
        minWidth:'130px !important',
        cell:(row)=>{
          return(
            <div>{(row.mtn)?Intl.NumberFormat().format(parseFloat(row.mtn)):null}</div>
          )
        }
      },  
      {
        name: "Solde",
        selector:row=>row.solde,
        sortable: true,
        sortField:"solde",
        minWidth:'130px !important',
        cell:(row)=>{
          return(
            <div>{(row.solde)?Intl.NumberFormat().format(parseFloat(row.solde)):null}</div>
          )
        }
      },  
  ],[]);

  return (
    <div className="content"> 
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop> 
       {
        (sold!==""&&seuil!=="")? 
          <Paper id="brnInfosAir" style={{width:"90%",margin:" 10px auto", background:"#f5f5f5"}}>
             <CustomizedProgressBars value={(sold/seuil)*100} max={seuil} current={sold}/>                  
          </Paper>
        :null
       }
        { 
        (data.length!==0)?
        <DataTable 
            persistTableHead
            columns={columns}
            data={data}
            noHeader={true}
            defaultSortAsc={true}
            noDataComponent={param.ErreurData}
            paginationRowsPerPageOptions={[20,50,100]}
            paginationPerPage={20}
            progressPending={loading}
            progressComponent={<CircularProgress className={classes.circularProgress} />}
            pagination
            highlightOnHover
            customStyles={customStyles}
            paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
            conditionalRowStyles = { conditionalRowStyles }
      />
        :(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null
        }
    </div>    

  );
}