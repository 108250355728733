import React,{useState,useCallback} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Delete,DoneAll,Close} from '@material-ui/icons';
import {AlertCompo} from "../../../js/components"
import { useStyles,paperPropsSmallF } from '../../../css/muiStyle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useMediaQuery } from 'react-responsive'
import Icon from '@mdi/react';
import { mdiDeleteEmptyOutline } from '@mdi/js';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Mon_lst_vid_cat(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const [cat, setCat] = useState('');
    const [catL, setCatL] = useState([]);
    const [severity, setSeverity] =useState("info");
    const [messageA, setMessageA] =useState('');
    const [openA, setOpenA] = useState(false);
    const [open, setOpen] = useState(false);
    const service="_lst.php"
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 520px)' })


      //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

    const handleClickOpen = () => { 
      setOpen(true); 
      fetchCat()
      setCat('')
    };

    const handleClose = () => { setOpen(false); };
     
      //fetch categorie
    const fetchCat= useCallback(async(serv="_cat.php", act = "gets") => {
      const response = await Gfunc.axiosGetAction(serv,act);
      Gfunc.axiosResponse(response,()=>{
        setCatL(response.data.jData);    
     })
    },[Gfunc])

  //fetch categorie handler
  const handleOpenCat = useCallback(async () => {
      if(catL.length===0){fetchCat()}
      },[catL,fetchCat])

    const handleVide=async()=>{
      if(cat!==''){
        const objIndex =  catL.findIndex((obj => obj.nom ===cat )); 
        const response=await Gfunc.axiosPostAction(service ,"emptycat",{catId:catL[objIndex].id})
        if(response.data.err&&response.data.err!=='-1'){
        props.handleAlert(true,"error",response.data.err)
        }else
         Gfunc.axiosResponse(response,()=>{
          if(response.data.jData===true){
           props.handleAlert(true,"success","La catégorie a été vidée avec succès ")  
            setOpen(false)
            props.setOpen(false)
            props.callback()
              }
          })
      }else{
        handleAlert(true,"error","Vous devez choisir une catégorie ")
      }
    }
 
  return (
    <div>
        <Button variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Delete/>}
                className={(isTabletOrMobile)?classes.topTabButton:classes.topTabBtn}>Vider une catégorie</Button>
        <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallF}
        >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
            <div className='DialogTitle'>
              <Icon path={mdiDeleteEmptyOutline} size={1} />
              <label>Vider une catégorie</label>
            </div>
        </DialogTitle>
        <DialogContent>
              <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} /> 
              <Autocomplete
                  disableClearable
                  id="iCommune"
                  className='smallAuto' 
                  onChange={(e,newValue) =>{setCat(newValue);}} 
                  onFocus={handleOpenCat}
                  value={(cat!=='')?(cat):null}
                  options={catL.map((option) => option.nom)}
                  renderInput={(params) => (
                      <TextField {...params} label="Catégorie" size="small" margin='dense' variant='outlined' required/>
                )} 
              />
        </DialogContent>
        <DialogActions id='btn-div'>
              <Button variant="contained" name="iValide" onClick={handleVide} type='submit' color="primary" 
                      startIcon={<DoneAll />}>VALIDER</Button>
        </DialogActions>
      </Dialog>
      </div>
  );
}
