import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,Tooltip,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import {CalendarToday,CropRotate,Close} from '@material-ui/icons';
import {TextField} from '@material-ui/core';
import { ValidatorForm} from 'react-material-ui-form-validator';
import  {useStyles,paperProps} from '../../../css/muiStyle';
import  '../../../css/referentiel.css';
import { Autocomplete } from '@material-ui/lab';
import {ReactComponent as DA} from '../../../img/icons/dzd.svg'
import {ReactComponent as Limit} from '../../../img/icons/seuil.svg'
import { mdiAdjust } from '@mdi/js';
import Icon from '@mdi/react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';


const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_prd_details_ajust(props) {
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] = useState(false);
    const [errorQ, setErrorQ] = useState(false);
    const [errorS, setErrorS] = useState(false);
    const [qte, setQte] = useState('');
    const [stock, setStock] = useState([]);
    const [stck, setStck] = useState(localStorage.getItem('stock'));
    const classes = useStyles();
    const service="_prd.php"

    
    const handleClickOpen = () => { 
      setQte('');setErrorS(false);setErrorQ(false)
      setOpen(true); 
      handleOpenStock();
    };
    const handleClose = () => { setOpen(false); };
   
    //recuperer les  stocks
    const fetchStock= useCallback(async() => {
      const response = await Gfunc.axiosGetAction('_stk.php','gets')
      Gfunc.axiosResponse(response,()=>{
        setStock(response.data.jData)
      })
    },[Gfunc]);
    
    //fetch categorie handler
    const handleOpenStock = useCallback(async () => {
    if(stock.length===0)
    fetchStock()
    },[stock,fetchStock])

    const handleAjust=async()=>{
        if(qte===''){setErrorQ(true)}
        if(stck===''){setErrorS(true)}
        if(qte!==''&&stck!==''){
          const sto=stock[Gfunc.getIndex(stock,stck,"nom")].id
          const response=await Gfunc.axiosPostAction(service,"ajust",{pdetId:parseInt(props.row.id),stockId:sto,qte:parseInt(qte)})
          if(response.data.err&&response.data.err!=="-1"){
            props.handleAlert(true,"error",response.data.err)
         }else
          Gfunc.axiosResponse(response,()=>{
              props.handleQte(response.data.jData.pdetStock,props.row.id,props.Frow.DT_RowId)
              setOpen(false) 
         })
        }
      }
   
    //valider formulaire avec touche d'entrer
const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="addBtn")) {
          alert("Vous devez remplir tous les champs!")
      }
    }

  return (
    <div>
       <Tooltip title="Ajuster la quantité du stock">
          <CropRotate  fontSize='small' className={classes.blackSmallIcon}  onClick={handleClickOpen}/>
       </Tooltip>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
            <div className='DialogTitle'>
              <Icon path={mdiAdjust} size={1} />
              <label>Ajuster la quantité de {props.Frow.produit}</label>
            </div> 
        </DialogTitle>
        <DialogContent >
          <ValidatorForm  noValidate  id="form" ref={useRef(null)} onSubmit={handleAjust} onKeyDown={e=>detectTouche(e)} >
            <div id='fieldSet'>
            <div id='ligne1'>
           <div id='item'>
              <DA style={{height:'18px',width:'18px'}}/>
              <b> Prix d'achat (DA) :</b>
              <p> {(props.row.prixAchat)?new Intl.NumberFormat().format(props.row.prixAchat):'---'}</p>
           </div>
           <div id='item'>
              <Limit style={{height:'20px',width:'20px'}}/>
              <b>Quantité :</b>
              <p>{(props.row.stock)?new Intl.NumberFormat().format(props.row.stock):'---'}</p>
           </div>
           <div id='item'>
              <CalendarToday style={{height:'20px',width:'20px'}}/>
              <b>Date de péremption :</b>
              <p> {(props.row.dateIn)?props.row.dateIn:'---'}</p>
           </div>
           </div>
        </div>
          <div id='form-ajust'>
            <Autocomplete
               disableClearable
               onChange={(e,newValue) =>{setStck(newValue);setErrorS(false)}}
               value={stck}
               className='smallAuto' 
               options={stock.map((option) => option.nom)}
               renderInput={(params) => 
                 <TextField  error={errorS} 
                    helperText={(errorS)?"Veuillez sélectionner un stock":null}
                    margin='dense' variant='outlined'size='small'{...params}  label="Stock" 
                    inputProps={{...params.inputProps,tabIndex:'1'}}
                    SelectProps={{ MenuProps: {closeAfterTransition:true } }}/>}
            />
            <TextField type='number' error={errorQ} className='smallText' margin='dense' variant='outlined' size="small" label='Quantité'  
                       value={qte} onChange={e =>{setQte(e.target.value);setErrorQ(false)}} title='Insérer la quantité ' 
                       helperText={(errorQ)?"Veuillez sélectionner une Quantité":null} fullWidth  required  autoFocus/>
          </div> 
          </ValidatorForm> 
           </DialogContent>
          <DialogActions>
            <Button variant="contained" name="iValide" form="form" type='submit' color="primary" startIcon={<CropRotate />}>Ajuster</Button>
          </DialogActions>
      </Dialog>
      </div>
  );
}
