import React,{useState} from 'react';
import {DialogContent,Typography,Paper,Dialog,IconButton,Button} from '@material-ui/core';
import Draggable from 'react-draggable';
import {paperPropsSmall } from '../../../css/muiStyle';
import {Close} from '@material-ui/icons';
import { withStyles} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import LogoBq from '../../../img/logos/bq-b.jpg'
import { useStyles } from '../../../css/muiStyle';
import Icon from '@mdi/react';
import { mdiImageEditOutline } from '@mdi/js';
import '../../../css/admin.css'

const styles = (theme) => ({
    root: {
      margin: 0,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top:0,
      color:'white',
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle  className={classes.root} {...other}>
        <Typography >{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Adm_prm_logo(props) {
    const classes=useStyles()
    const Gfunc=require('../../../Gfunc')
    const [open,setOpen]=useState(false)
    const [nom,setNom]=useState('')
    const [error,setError]=useState(false)
    const [load,setLoad]=useState(false)
    const service='_mag.php'

    const handleClickOpen = () => { 
      setOpen(true); setNom('');
  };
    const handleClose = () => { setOpen(false); };
    
  /*  const handleError=()=>{setError(true)}
    
    const handleLaoad=()=>{setLoad(true) }*/

    const UpdateImg=async(file,id)=>{
        var formData = new FormData();
        const mag=localStorage.getItem('mag')
        const mags=JSON.parse(localStorage.getItem('mags'))
        const objIndex = mags.findIndex((obj => obj.name ===mag ));
        const idd=mags[objIndex].id;
        formData.append("img", file);
        formData.append("id",idd);
       const response = await Gfunc.axiosPostAction1(service ,'updimg',formData)
        if(response.data.err&&response.data.err!=='-1'){
          setOpen(false)
          props.handleAlert(true,"error",response.data.err)  
        }else{
          Gfunc.axiosResponse(response,()=>{
              if(response.data.jData===true){
                document.getElementById('mag').src=props.link+props.logo+'?'+new Date().getTime();
                setOpen(false)
                props.handleAlert(true,"success"," L'image a été modifié avec succès")    
              }
           })
        }
      }
      const handleError=()=>{
        setError(true)
      }
    
      const handleLaoad=()=>{
        setLoad(true)
      }
  return (
    <div>
      <img id='mag' src={(error===false&&load===true)?props.link+props.logo:LogoBq} onLoad={handleLaoad}  onError={handleError} width={120}
       alt='logo' onClick={handleClickOpen} style={{cursor:'pointer'}}/>
      <Dialog 
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmall}
       >
           <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
             <div style={{display:"flex",gridGap:"10px",alignItems:"center"}}>
               <Icon path={mdiImageEditOutline} size={1} />
               <label id='ess'>Modifier Logo</label>
             </div>
            </DialogTitle>
            <DialogContent>
            <div style={{gridGap:'5px',display:'flex',alignItems:'center'}}>
            <p style={{fontSize:"15px",fontWeight:'bold'}}>Télécharger un logo</p>
            <div id='div-param-logo'>
              <Button
                variant="contained"
                color='primary'
                component="label"
                className={classes.fieldBtn}
              >
               Choisir un fichier
               <input
                  type="file"
                  hidden
                  //accept={'image/*'}
                  accept='.jpg'
                  onChange={e=>{UpdateImg(e.target.files[0],e.target.files[0].name);setNom(e.target.files[0].name)}}
                />
              </Button>
            {(nom)?<b>{nom}</b>:<b>Aucun logo choisi</b>}
            </div>
            </div>
            </DialogContent>
      </Dialog>
      </div>
  );
}
