import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import {CircularProgress,Tooltip} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import '../../css/global.css'
import {customStyles} from '../../css/datatable-custom'
import '../../css/datatable.css';
import { useStyles,textAreaEdit } from '../../css/muiStyle';
import RetrecAdd from '../content/modals/ret_rec_add'
import RetrecSuiv from '../content/modals/ret_rec_suiv'
import {Search,IndeterminateCheckBox} from '@material-ui/icons';
import {AlertCompo, OutsideClickHandler,NoDataComponent,NotAllowedComponent, DatePickerUpd, AutocompleteS} from "../../js/components"
import { TextareaAutosize } from "@material-ui/core";
import { Box } from "@mui/system";


export default function Ref_rec (props){
  const classes = useStyles();
  const librery=require('../../js/data_table_librery')
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const [Item, setItem] = useState('');
  const [ItemN, setItemN] = useState('');
  const [selecto, setSelecto] = useState('');
  const [data, setData] = useState([]);
  const [userL,setUserL]=useState([]);
  const [note,setNote]=useState('');
  const [,setDel] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [open, setOpen] =useState(false);
  const [mag,setMag]=useState([]);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const[noData,setNoData]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[doSearch,setDoSearch]=useState(false);
  const service='_ret.php'
  const[called,setCalled]=useState(false);
 

  const fetch= async(page,servic = service, size = perPage) => {
    setCalled(true)
    setLoading(true)
        const response=await librery.axiosGet2(page+"&search="+search,service,'getin', size)
        if(response.data.err&&response.data.err!=="-1"){
          setErrMessage(response.data.err)
          setNotAllowed(true)
        }else
        Gfunc.axiosResponse(response,()=>{
          if(response.data.data!==undefined){
          setData(response.data.data);
          setTotalRows(response.data.recordsFiltered);
          setDoSearch(true)
          setNoData(true)
          }else {setDoSearch(false);setData([])}
        })
    setLoading(false)
  }

  const fetchUser= useCallback(async() => {
    const response = await Gfunc.axiosGetAction("_usr.php","gets")
    if( response.data.err){setSelecto('')}
    Gfunc.axiosResponse(response,()=>{
        setUserL(response.data.jData);   
    })
  },[Gfunc]);

  useEffect(() => {
    if(!called) {fetch(1);}
    Gfunc.loseFocus('.content-editable')
  }, [fetch,called]);
  
    // fetch detail de magasin
 const fetchDetMag= useCallback(async() => {  
  const response = await Gfunc.axiosPostAction("_mag.php","getmag",{id:localStorage.getItem('magid')})
  Gfunc.axiosResponse(response,()=>{
     setMag(response.data.jData);
  })
},[Gfunc])
  
  //handle fetch magasin
  const handleFetchMag=useCallback(()=>{
    if(mag.length===0)
    fetchDetMag();
  },[fetchDetMag,mag])

  const handleOpenUser=useCallback(()=>{
    if(userL.length===0)
    fetchUser();
},[fetchUser,userL])

//modifier un champs avec un select
const handleSelect=useCallback((row,att,f=()=>{})=>{
      f()
      setSelecto(row[att])
      setItem(row.DT_RowId)
      setItemN(att)
  },[])

  //handle update text
  const handleOnEdit=useCallback(async(e,row,att)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
    if(row[att]!==edt){
      const attrib=row[att];
      const response=await librery.axiosEdit(service,row,edt,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
      if(response.data.err) {e.target.innerHTML=attrib}
    }else {e.target.innerHTML=edt}
  },[librery,Gfunc]);

  //handle update note
  const handleOnEditNote=useCallback(async(note,row,att)=>{
    const edt1=note
    var edt=Gfunc.DeleteSpace(edt1);
    edt=edt.replaceAll("\n", "\r")
    if(row[att]!==edt){
      const attrib=row[att];
      const response=await librery.axiosEdit(service,row,edt,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{row[att]=edt})
      if(response.data.err) {row[att]=attrib}
    }else {row[att]=edt}
    setNote('')
    setSelecto('')
  },[librery,Gfunc]);


  //handle select items update providing event, row, Attribute's id, attribute's value ,the choices list(a state), it's set function 
  const handleOnEditSelect=useCallback(async(e,row,attId,attV,list,attList,f=()=>{})=>{
      if(row[attId]!==e.target.value){
        const edt=e.target.value
        const attrib=row[attId];
        const response=await librery.axiosEdit(service,row,edt,attId) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
          const objIndex =list.findIndex((obj => obj.id === edt));
          row[attV]=list[objIndex][attList]
          row[attId]=edt
          setItem("");
          setItemN("");
          f();
          setSelecto('')
        })
        
        if(response.data.err) {setLoading(true);setItem("");setItemN(""); row[attId]=attrib; setLoading(false)}
     }
  },[Gfunc,librery])
  
  //handle alerts properties
  const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }
  //go up
  /*function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }*/
   
  //delete itam
  const handleSetData=useCallback((row,att)=>{
    Gfunc.removeItemsByAtt(data,row.DT_RowId,att)
    setDel('')
  },[data,Gfunc])
    
   const handleDeleteItem=useCallback(async(row)=>{
      
        const response=await Gfunc.axiosPostAction(service,"del",{idr:row.DT_RowId.substr(1)}) 
        if(response.data.err)
        handleAlert(true,'error',response.data.err)
        else Gfunc.axiosResponse(response,()=>{
              if(response.data.jData!==0){
                handleAlert(true,'success','Le retour est supprimé')
                handleSetData(row,"DT_RowId")
                setDel(row.DT_RowId)
              }
          })
    },[Gfunc,handleSetData,service])

  const columns =useMemo(
    () => [ 
      {
          name: "DATE",
          minWidth:"100px !important",
          selector: row=>row.date_retour,
          sortable: true,
          sortField:"date_retour",
          cell:(row)=>{
              return(
                  <div>
                    <DatePickerUpd selecto={selecto} Item={Item} ItemN={ItemN} row={row}
                  setSelecto={setSelecto}
                  name="date_retour" minDate={Gfunc.LastXDay(300)}
                  service={service} action='upd' extra=''
                  handleSelect={handleSelect} setItem={setItem} handleAlert={handleAlert}/>
              </div>
              )
          }
      },
      {
        name: "PRODUIT",
        width:"250px !important",
        selector:row=>row.produit,
        sortable: true, 
        sortField:"produit", 
      },
      {
        name: "SERIE",
        minWidth:"160px !important",
        selector:row=>row.serie,
        sortable: true,
        sortField:"serie",
        cell: (row) => {
            return (
                <ContentEditable
                html={(row.serie)?row.serie:''}
                name="serie"
                data-column="item"
                className="content-editable"
                disabled={false} 
                onBlur={e => handleOnEdit(e,row,"serie")}
                />
             );
           },
      },
      {
        name: "Client",
        minWidth:"150px !important",
        selector:row=>row.client,
        sortable: true,
        sortField:"client",
      },
      {
        name: "Type",
        minWidth:"150px !important",
        selector:row=>row.type,
        sortable: true,
        sortField:"type",
      },
      {
        name: "Agent",
        minWidth:"150px !important",
        selector:row=>row.agent,
        sortable: true,
        sortField:"agent",
        cell: (row) => { 
            return (
              <AutocompleteS
                  datein={row.date} agentin={row.agent} 
                  service={service} action={'upd'} 
                  defaultValue={row.frns} row={row} list={userL} 
                  id={'agent_id'} name='agent' attr='name'
                  onOpen={handleOpenUser} handleAlert={handleAlert}/>
             );
        },
      },
      {
        name: "Panne",
        width:"300px !important",
        selector:row=>row.note,
        sortField:"note",
        sortable: true,
        cell:(row)=>{
            return (
              <div>
                { (row.note===selecto && row.DT_RowId===Item )?
             <OutsideClickHandler onOutsideClick={e => {(note)?handleOnEditNote(note,row,"note"):setSelecto('');}}>
                <TextareaAutosize style={textAreaEdit} onChange={(e)=>setNote(e.target.value)}  autoFocus onFocus={(e)=>{ e.target.value=note}} />
             </OutsideClickHandler>:
             ((row.note.match(/\r/g) || []).length>1)?
             <Tooltip title={row.note.replaceAll("\r", "/ ")}>
                 <div onClick={()=>{setSelecto(row.note);setItem(row.DT_RowId);setNote(row.note.replace(/^\r|\r$/g, '\n'))}}>{row.note.substring(0, row.note.indexOf("\r"))+"..."}</div>
             </Tooltip>:
             <div onClick={()=>{setSelecto(row.note);setItem(row.DT_RowId);setNote(row.note.replace(/^\r|\r$/g, '\n'))}}>{row.note}</div>}
              </div> 
            )
        }
      },
      {
        width:'65px',
        cell: (row) => {
          return (
            <div id="icons">
              <div className='rowGrid' id="row-2" >
                <Tooltip title="Suivre" >
                  <div>
                   <RetrecSuiv param={param} mag={mag} handleFetchMag={()=>handleFetchMag()} from="rec" idr={parseInt(row.DT_RowId.substr(1))} 
                               userL={userL} handleOpenUser={handleOpenUser}/>
                  </div>
                </Tooltip>
                <Tooltip title="Supprimer">
                 <div >
                   <IndeterminateCheckBox fontSize='small' onClick={()=>handleDeleteItem(row)} className={classes.redIcon}/>
                 </div>
                </Tooltip>
              </div>
            </div>
          )
        }
      },
    ],[Gfunc,classes,handleOnEdit,handleOnEditSelect,handleSelect,Item,ItemN,handleOpenUser, selecto,userL,note,handleOnEditNote,handleDeleteItem,handleFetchMag,mag]);
   
    //Sort serverside
    const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,"getin")
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        }
      })     
      setLoading(false)
    }
  
  //recherche serverside
  const handleSearch=async(size = perPage)=>{
    setLoading(true);
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,"getin")
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }else setData([])
      setLoading(false);
    })   
    return recherche
  }

function handlePageChange (page) {
  fetch(page,service,perPage);
  setCurrentPage(page);
}

 const handlePerRowsChange = async (newPerPage, page) => {
  fetch(page,service, newPerPage);
  setPerPage(newPerPage);
}

//Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     handleSearch();
  }else{ if (e.keyCode === 13) {
            handleSearch();
          }
  }
}

function liveSearch2() {
  handleSearch();
}
return (
  <div className="content">
   <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} autoFocus/>
     <div className='table-header'>
        <div className='button-flex-wrapper'>
            <RetrecAdd type="supp" service="" action="" callback={()=>fetch(1)} handleAlert={handleAlert}/>
        </div>
        { (doSearch)?
         <div className='search' >
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField variant="outlined"  label="Recherche" value={search} 
                             InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                             style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:'pointer' }} />),}}
                             size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
            </Box>
         </div>:null}
     </div>
     {(data.length!==0||doSearch)?
      <div>
        <DataTable 
              className="table"
              persistTableHead
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              defaultSortAsc={true}
              paginationRowsPerPageOptions={[10,20,30,100]}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles}   
              selectableRowsComponentProps={{ className: classes.checkStelect }} 
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
                /></div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
    </div>    

  );
}