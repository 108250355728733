import React, { Component} from 'react';
import { NavLink} from 'react-router-dom';
import axios from "axios";
import '../css/index.css'
import $ from 'jquery'
import FaceIcon from '@material-ui/icons/Face';
import ExitToApp from '@material-ui/icons/ExitToApp';
import {axiosPostAction,axiosGetAction,isEmpty} from '../Gfunc'
import DialogPass from '../components/changepass'
import Dr from '../compo/DrawerMenu'
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
//import { Autocomplete } from '@mui/material';
const Gfunc =require("../Gfunc")

//is an coponent that returns the menu and the sub menu
class mainMenu extends Component {
  state={
    scope:'',
    cscope:'',
    pcscope:'',
    dscope:'',
    dcscope:'',
    obj: {}, 
    dp:{},
    caisse:'',
    isMobile:'',
    mags:[],
  }

  constructor(props){
    super(props);
    this.state = {isMobile: window.screen.width < 600};
    const dp={}
    var defaultMenu=false
    var last=Object.keys(props.men)[0]
    var cpt=0
    if(Gfunc.getInfo().mags){
      this.state.mags=JSON.parse(Gfunc.getInfo().mags)
    }
    Object.entries(props.men).forEach( ([clé, valeur]) =>{ 
        cpt=cpt+1
        if (valeur[2]===2&& valeur[5]===1&&!dp.hasOwnProperty(valeur[1])){
          dp[valeur[0]]=clé
        } 
        if (valeur[2]===1&&valeur[5]===1){this.state.dcscope=clé;this.state.cscope=clé;localStorage.setItem('cscope',clé);defaultMenu=true} 
        if (valeur[0]===localStorage.getItem('cscope')&&valeur[2]===2&&valeur[5]===1) {this.state.dscope=clé;localStorage.setItem('scope',clé)
        //if (valeur[3]===2&&valeur[1]===localStorage.getItem('cscope')) {this.state.dscope=clé;localStorage.setItem('scope',clé)
      }
      if(valeur[2]===1){ 
        if(last!==clé){
          if( !dp.hasOwnProperty(last)){
            const key=Object.keys(props.men)[Gfunc.navObj(props.men,last,1)]
            if(props.men[key][2]===2){
            dp[last]=key
            this.state.dscope=key;}
            else{
            dp[last]=''
            this.state.dscope=key;}
          }
        }
        last=clé}
        
     if(cpt===props.men.length-1){
      if( !dp.hasOwnProperty(last)){
        const key=Object.keys(props.men)[Gfunc.navObj(props.men,last,1)]
        dp[last]=key
        this.state.dscope=key;
      }
     }
    if(!defaultMenu){
        this.state.dcscope=Object.keys(dp)[0];this.state.cscope=Object.keys(dp)[0];localStorage.setItem('cscope',Object.keys(dp)[0]);
        this.state.dscope=dp[Object.keys(dp)[0]];localStorage.setItem('scope',dp[Object.keys(dp)[0]])
    }   
    this.state.scope=dp[this.state.cscope]
    localStorage.setItem('scope',dp[this.state.cscope])
    this.state.dp=dp  
    }) 
    //if(window.location.pathname.split("/")[1]==="") window.location=('/'+localStorage.getItem('cscope')+'/'+localStorage.getItem('scope'));
    this.state.obj=props.men 
    //this.routeChange = this.routeChange.bind(this);
    if(window.location.pathname.split("/")[1]!==""){
        this.state.cscope=window.location.pathname.split("/")[1]
        if(window.location.pathname.split("/")[2]!==""&&this.state.obj.hasOwnProperty(window.location.pathname.split("/")[2]))
           this.state.scope=window.location.pathname.split("/")[2] 
        else
          this.state.scope=dp[this.state.cscope]
    }
  }

  //changer le lien 
  routeChange(newPath) {
    let path = newPath;
    this.props.history.push(path);
  }

  //update le state de type de device
  updateIsMobile=()=> {
    this.setState({
        isMobile: window.innerWidth < 600
    });
  };

  componentDidMount(){
      this.Caisse()
      window.addEventListener('resize', this.updateIsMobile);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateIsMobile);
  }

  //to change cscope according to the links of the  menu
   hendleSub= (key)=>{
    this.setState({
      cscope: key
    })  
  }

   //to change cscope according to the links of the sub menu
  hendleSubsub= (key)=>{
    this.setState({
      scope: key
    })
  }

  //deconnexion
   logout(){
    const Gfunc = require('../Gfunc');
    const param = this.props.param
    axios.get(param.urlService+'disconnect.php',{headers: Gfunc.getHeader()})
    .then(response => {  
        if(response.data.jData===true){
                localStorage.removeItem("token");
                localStorage.setItem('isLoged', false);
                localStorage.clear();
                window.location=("/"); 
        }
    })
    .catch(error => {
      //console.log(error)
    })
  }

  //we have used this function to get tha previous page's cscope
  static getDerivedStateFromProps(props, state) {
    if(props.men[window.location.pathname.split("/")[1]]!==undefined&&(window.location.pathname.split("/")[2]===''||props.men[window.location.pathname.split("/")[2]]!==undefined||window.location.pathname.split("/")[2]===undefined)){
    const dp=state.dp;
    if(window.location.pathname.split("/")[2]!==''&&window.location.pathname.split("/")[2]!==undefined)
    localStorage.setItem('title',props.men[window.location.pathname.split("/")[2]][3])

      state.cscope=window.location.pathname.split("/")[1]
      state.scope=window.location.pathname.split("/")[2]

    if(state.cscope!==state.pcscope && state.pcscope!==''){
     $(function() {
         $('a[id='+state.pcscope+']').removeClass('selected');
       });
    }
    else{
    $(function() {
       $('a[class~=link][href="/' + window.location.pathname.split("/")[1] +'/'+dp[window.location.pathname.split("/")[1]]+ '"]').addClass('selected');
     });
    }
   return {pcscope: state.cscope};}
   return {pcscope: null}
 }

 //Changer le magasin
async basculerMagzin(val){
  const select=val
  const response=await axiosPostAction("_usr.php" ,"switchmag",{mag:select})
  if(response.data){
    localStorage.setItem('mag',response.data.name)
    localStorage.setItem('magid',response.data.id)
    document.location.reload();   
  }
 // this.getMag() 
}
async Caisse(){
  const response=await axiosGetAction('_cai.php' ,'getcaisse')
  this.setState({ caisse: response.data.jData })    
}

 
 render(){
  if(!isEmpty(this.state.obj)&&window.location.pathname.split("/")[2]&&(this.props.men[window.location.pathname.split("/")[1]]!==undefined&&(window.location.pathname.split("/")[2]===''||this.props.men[window.location.pathname.split("/")[2]]!==undefined||window.location.pathname.split("/")[2]===undefined))){
  document.title=this.state.obj[window.location.pathname.split("/")[2]][3];
  }
  if((this.props.men[window.location.pathname.split("/")[1]]!==undefined&&(window.location.pathname.split("/")[2]===''||this.props.men[window.location.pathname.split("/")[2]]!==undefined||window.location.pathname.split("/")[2]===undefined)))
    return ( 
     <div>
       <div className='all' id="header" hidden={this.state.isMobile}>
        <nav className="navbar" >
          <div id="logo"></div>
          <div className="mainbar" id="menu" >
             <ul className="listbar">
                {Object.keys(this.state.obj).map((key, index)=> {
                            var value=this.state.obj[key];
                            if(value[2]===1&&!(!JSON.parse(localStorage.getItem("intgIraha"))&&value[3].toLowerCase().includes("iraha"))){
                              return <li key={index} className="nav-item" style={{paddingBottom:'0px'}}><NavLink  style={{paddinBottom:'0px'}} activeClassName=" selected" className="link chosen" aria-current="page" to={'/'+key+'/'+this.state.dp[key]} id={key}  onClick={()=>this.hendleSub(key)}>{value[3]}</NavLink></li>
                            } else return null ;
                    })}
              </ul>
          </div>
          <div id="infosWrap">
          <div id="infos1">
            <FaceIcon color="primary" style={{ fontSize: 18 }} />
           <DialogPass getInfo={Gfunc.getInfo}/>
            <span id="vmag">
           {/* <select  onChange={e=>this.basculerMagzin(e)}  id="vmag" name="vmag" value={localStorage.getItem('magid')}>
              {
               JSON.parse(this.state.mags).map((item,index)=>{
                 return <option key={index}  value={item.id}>{item.name}</option>
               })
              }
            </select>*/}
           { <Autocomplete
                                disableClearable
                                className='smallAuto smallerAuto'
                                id="iMag"
                                onChange={(e,newValue) =>{
                                  this.basculerMagzin(newValue.id);
                                  } }
                                value={{id:localStorage.getItem('magid'),name:localStorage.getItem('mag')}}
                                options={this.state.mags}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => (option.id=== value.id)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    />
                                )} />}
            </span>
            <ExitToApp style={{ color: 'gray',fontSize: 19, cursor: 'pointer' }} onClick={this.logout.bind(this)} titleAccess="Déconnexion" />
            </div>
            <div id="infos2">
            <span id="myCaiss">Caisse: <label className="mtn" id='caisseMtn'>{Intl.NumberFormat().format(this.state.caisse)}</label> $</span>
            </div></div>
          <div className="subbar" id="sMenu">
              <ul className="listbar">
                { Object.keys(this.state.obj).map((key, index)=> {
                            const value=this.state.obj[key]; 
                            if(value[2]===2 &&value[0]===this.state.cscope){
                              return <li key={index} className="nav-item" ><NavLink id={key} activeClassName='selected2' aria-current="page" to={'/'+this.state.cscope+'/'+key} onClick={()=>this.hendleSubsub(key)}>{value[3] }</NavLink></li>
                            }else return null;
                    })}
              </ul >
           <div>
              </div>
          </div>
          <div id="contentTitle"><span>{(this.state.scope&&window.location.pathname.split("/")[2]!==undefined&&window.location.pathname.split("/")[2]!=='')?(this.state.obj[window.location.pathname.split("/")[2]][4]):("")}</span></div>
        </nav>
        </div>
        {(this.props.men&&this.props.men!==undefined&&this.state.isMobile)?<Dr menu={this.props.men} dp={this.state.dp} hendleSub={this.hendleSub} cscope={this.state.cscope} scope={this.state.scope} basculerMagzin={this.basculerMagzin} hendleSubsub={this.hendleSubsub} caisse={this.state.caisse} getInfo={this.getInfo} logout={this.logout.bind(this)} routeChange={this.routeChange} />:null}
    </div>
    );
    else return null}
}
export default mainMenu;