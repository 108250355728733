import React,{ useState,useRef,Fragment} from "react";
import { VpnKey,Close} from "@material-ui/icons";
import { Password } from "@mui/icons-material";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Button,Paper,Dialog,DialogActions,DialogContent,TextField,Typography,IconButton } from "@material-ui/core";
import Draggable from 'react-draggable';
import { paperPropsSmallForg, useStyles } from '../css/muiStyle';
import axios from 'axios'
import $ from 'jquery'
import '../css/login.css'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiLockReset } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function ForgotPwd(props) {
    const classes=useStyles();
    const [open, setOpen] =useState(false);
    const [nom, setNom] =useState(props.email);
    const [error, setError] =useState(false);
    const [msg, setMsg] =useState(false);
    const param= props.param
    
    $(document).ready(function(){
      $('#tel-edit').on('input', function(ev) {
        var $this = $(this);
        var value = $this.val();
        if (value && value.length >10) {
          $this.val(value.substr(0,10));
        }
      })
      });

    const handleClickOpen = () => { 
        setOpen(true);setMsg(false);setNom(props.email)
    };

    const handleClose = () => { setOpen(false); };
     
    const vidage=()=>{
     setOpen(false)
     setNom('')
    }
    const handleSubmit= async(e) =>{
        e.preventDefault();   
      if(nom!==""){
              await axios({
                method: 'POST',
                url:param.urlService+'forgot.php',
                headers: { 'content-type': 'application/json' ,'appID': param.appID},
                data:JSON.stringify({ login:nom})     
            })
        //recuperation des donnees du service pour l'autorisation d'acces ou non 
            .then(response => { 
              if(response.data.err){
              alert(response.data.err)
              }else{
              setMsg(response.data.jData)
              setTimeout(vidage, 5000);
            }
            })
            .catch(error => {
                alert(error);
            })
      }else{
        setError(true)
      }
        
}
    
const testPhone=(e)=>{
  const re = /^[0-9\b]+$/;
  if (e.target.value==='' || re.test(e.target.value)) {
    setNom(e.target.value)
  }
}

  return (
    <Fragment >
      <div onClick={()=>handleClickOpen()} id='login-pwd'>
        <VpnKey className={classes.WhiteIcon} /><span id="pwd-forgot" >oublié?</span>
      </div>
      <Dialog
            open={open}
            id='changePass'
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallForg}
       >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
          <div className='DialogTitle'>
              <Password style={{width:'20px',height:'20px'}} />
              <label>Réinitialiser le mot de passe </label>
          </div>
        </DialogTitle>
        <ValidatorForm ref={useRef(null)} id='forgot' onSubmit={handleSubmit} >
            <DialogContent>
            {(msg===true)?<span id='sms-forgot'>Votre mot de passe a été changé, vous allez recevoir un sms sur votre téléphone.</span>:null}
           <br/> 
            <div style={{marginBottom:'6px !important'}}>
              <TextField type="tel"  error={error} helperText={(error)?"Veuillez saisir votre nom d'utilisateur!":null} 
                         label='Login' id="tel-edit" name="iTel" value={nom}  className='smallText' margin='dense' variant='outlined'
                         onChange={(e)=>{testPhone(e);setError(false)}} required autoFocus
                         inputProps={{ pattern: "[0][1-7][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]"}}
                                    /> 
            </div>
            </DialogContent>
            <DialogActions>
               <Button variant="contained" type='submit' form='forgot' color="primary" startIcon={<Icon path={mdiLockReset} size={1} />}>Réinitialiser</Button>
            </DialogActions>
        </ValidatorForm>
      </Dialog>
      </Fragment>
  );
}
