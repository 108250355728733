const Gfunc =require("../Gfunc")


export const getBinars=async()=>{
    let res=null
    const response=await Gfunc.axiosGetAction('_irh.php' ,'getbinars')
    if(response.data.err&&response.data.err!=='-1') return response
    Gfunc.axiosResponse(response,()=>{
        res=response;    
    })  
    return res;
  }

  export const Caisse=async ()=>{
    const response=await Gfunc.axiosGetAction('_cai.php' ,'getcaisse')
    Gfunc.updateElem("caisseMtn",response.data.jData)   
    Gfunc.updateElem("caisseMtn2",response.data.jData)   
  }
  
//fetch fournisseurs
export const fetchFrns= async(serv="_frs.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    Gfunc.axiosResponse(response,()=>{
        return(response.data.jData);    
    })
  }

//fetch users
export const fetchUser= async(serv="_usr.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    Gfunc.axiosResponse(response,()=>{
        return(response.data.jData);    
    })
  }