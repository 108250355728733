import React,{ useState, useEffect ,useCallback, useRef} from "react";
import TextField from '@material-ui/core/TextField';
import {CircularProgress, Paper, Tooltip, TextareaAutosize, ClickAwayListener, Fab, Switch,Tab,Tabs,AppBar,Card,CardContent, Button} from '@material-ui/core';
import { IndeterminateCheckBox, Add, Search} from '@material-ui/icons';
import { textAreaEdit, useStyles } from '../../css/muiStyle';
import '../../css/vente.css';
import { useTheme } from '@material-ui/core/styles';
import {AutocompleteS, BackdropCompo,InputNbrEditable, NoTableDataComponent, RaccBtn, RaccBtnFixed, RaccBtnStatic,TabPanel,ScrollableTabs} from "../../js/components"
import {AlertCompo, DetailProduit} from "../../js/components"
import PrintTicket from "../../js/printTicket"
import ReturnTikPlus from './modals/vnt_cpt_return'
import PayTik from './modals/vnt_cpt_pay'
import AddVnt from './modals/vnt_cpt_add'
import AddAir from './modals/vnt_cpt_addAir'
import AddPack from './modals/vnt_cpt_addPack'
import AddVntAuto from './modals/vnt_cpt_addAuto'
import AddVntSearch from './modals/vnt_cpt_addSearch'
import StkCompt from './modals/vnt_cpt_stkCmpt'
import SldClt from './modals/vnt_cpt_sldClt'
import Stks from './modals/vnt_cpt_stks'
import HistCli from './modals/vnt_cpt_histCli'
import TopUp from './modals/vnt_cpt_topUp'
import { ValidatorForm } from "react-material-ui-form-validator";
import $ from 'jquery'
import { Divider } from "@mui/material";
import axios from 'axios'
import IconButton from '@mui/material/IconButton';
import {mdiBasketPlus, mdiBasketRemove, mdiCashFast, mdiReceiptTextArrowLeft, mdiReceiptTextPlus, mdiReceiptTextRemove} from '@mdi/js';
import Iconn from '@mdi/react';
import { useCookies } from 'react-cookie'
import HistoCliB from './modals/histo_cli_binars'
import '../../css/irh.css'
import PrintDig from "../../js/printDig";
import PropTypes from 'prop-types';
import { isEmpty } from "lodash";
import LazyLoad from "react-lazyload";


//Test Etat refresh 3
export default function Vnt_cpt (props){
  const [cookies, setCookie] = useCookies(['digital'])
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [dataAdd, setDataAdd] = useState([]);
  const [dataSAdd, setDataSAdd] = useState([]);
  const [shortcuts, setShortcuts] = useState([]);
  const [aircuts, setAircuts] = useState([]);
  const [detail, setDetail] = useState([]);
  const [clientL,setClientL]=useState([]);
  const [clientId,setClientId]=useState(null);
  const [clientN,setClientN]=useState(null);
  const [stkL,setStkL]=useState([]);
  const [cb,setCb]=useState('');
  const [search,setSearch]=useState('');
  const stk=localStorage.getItem('stockId');
  const stkN=localStorage.getItem('stock');
  const [caisse,setCaisse]=useState('');
  const [fElem,setFElem]=useState('');
  const [selected, setSelected] = useState(false);
  const [checkUpd, setCheckUpd] = useState(false);
  const [prodId, setProdId] = useState('');
  const [produit, setProduit] = useState('');
  const [retour, setRetour] = useState(false);
  const [lastMtn, setLastMtn] = useState('');
  const [mtn, setMtn] = useState('');
  const [shorts, setShorts] = useState(false);
  const [control, setControl] = useState(false);
  const [controlVnt, setControlVnt] = useState(false);
  const [controlTik, setControlTik] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [action, setAction] = useState(false);
  const [jourId,setJourId]=useState('');
  const [ticketId,setTicketId]=useState('');
  const [ticketNum,setTicketNum]=useState('');
  const [ticketIndex,setTicketIndex]=useState('');
  const [caisseId,setCaisseId]=useState(false);
  const [elem,setElem]=useState(false);
  const [venteId,setVenteId]=useState('');
  const [venteIndex,setVenteIndex]=useState('');
  const [digital,setDigital]=useState((cookies.digital)?JSON.parse(cookies.digital):false);
  const [note,setNote]=useState('');
  const [sugg,setSugg]=useState([]);
  const service='_vnt.php'
  const classes=useStyles();
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  let [cancelToken, setCancelToken] =useState(undefined);
  const [catL,setCatL]=useState([]);
  const labelText1='Code à barres'
  const labelText2='Recherche'
  const [digsLStoreAll,setDigsLStoreAll]=useState({0:[]});
  const [digsLStore,setDigsLStore]=useState({0:[]});
  const [digCatL,setDigCatL]=useState([]);
  const [digsL,setDigsL]=useState([]);
  const [ats,setAts]=useState([]);
  const [digCatSelected,setDigCatSelected]=useState(0);
  const [digCatSelectedN,setDigCatSelectedN]=useState('');
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const Apis =require("../../js/Apis.js")
  const [binarsCalled,setBinarsCalled]=useState(false);
  const [digImp,setDigImp]=useState({});
  const [catFilter,setCatFilter]=useState({});
  const [stats,setStats]=useState({});
  //{oid1:{stat,mod},oid2:{stat:mod}}
  const [orders,setOrders]=useState({});
  //{pid1:{oid},pid2:{oid}}
  const [stop,setStop]=useState(false);
  const [displayStats,setDisplayStats]=useState(false);
  const bid=0
  const eid=1000001;
  const sid=1000002;
  const fid=1000003;
  const [ord, setOrd] =useState(false);
  const excluded =useRef([]);
  const [orderss, setOrderss] =useState([]);
  const [stCalled, setStCalled] =useState(false)
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setDigsLStore(digsLStoreAll);
  };
  const setExcluded = (x) => {
    excluded.current=x
  };

  useEffect(()=>{
    if(stop){
      setStop(false)
      setTimeout(()=>{
        var st={...stats}
        setDisplayStats(false);
        Object.keys(st).map(function(key) {
          if (excluded.current.includes(parseInt(key))) delete st[key]; 
          return false
        })
        setStats(st)

      },500)
    }
  },[stop,stats,excluded])

  const getStats=async()=>{
    //setDisplayStats(true)
    var stop=false
    var st={...stats}; var refreshStat,refresh=false;var respon=null;var ex=[]
    setStop(false)
    var tmp=0
    ex=[...excluded.current]
    const interval = setInterval(async function() {
      respon=null
      tmp++;
      if(tmp>4) tmp=1
      if(!stop){
        respon=await Gfunc.axiosPostAction("_irh.php","reford",{/*st:tmp*/})
        if(respon.data.err && respon.data.err!=='-1'){
          handleAlert(true,'error',respon.data.err);
        }
        else
        Gfunc.axiosResponse(respon,()=>{
          setOrd(false)
          if(respon.data.data) {
            if(isEmpty(st)){

              respon.data.data.map(function(elem, index) {

              if(elem.stat!==3&&elem.stat!==4) if (ex.includes(elem.produitId)) ex=Gfunc.removeItemAll(ex,elem.produitId)
              if(!ex.includes(elem.produitId))
              st[elem.produitId]={stat:elem.stat,mode:elem.mode}

              if(elem.stat===3||elem.stat===4) ex.push(elem.produitId)
              
              return false })
              setStats(st)
              if(excluded.length===4) stop=true
            } 
            else{
              respon.data.data.map(function(elem) {
                const pst={...st}
                if(elem.stat!==3&&elem.stat!==4) if (ex.includes(elem.produitId)) ex=Gfunc.removeItemAll(ex,elem.produitId)
                refreshStat=true
                //if((stats[elem.produitId]&&st[elem.produitId]&&stats[elem.produitId].stat!==st[elem.produitId].stat)||!stats[elem.produitId]) refreshStat=true
                if(!ex.includes(elem.produitId))
                   st[elem.produitId]={stat:elem.stat,mode:elem.mode}
                else  delete st[elem.produitId]
                if((pst[elem.produitId]!==undefined&&st[elem.produitId]!==undefined&&pst[elem.produitId].stat!==st[elem.produitId].stat&&st[elem.produitId].stat===4)) refresh=true

                if(elem.stat===3||elem.stat===4) ex.push(elem.produitId) 
                   return false 
                  })
                  
              if(refresh){
                  GetAtSoldes()
                  refresh=false;
                }
              if(refreshStat){
                setStats(st)
                refreshStat=false;
                }
                setExcluded(ex)
              if(ex.length===4){stop=true;setStop(true)}
            }
          }
        })
         
      }
      if(stop) {
        setStats(st)
        setOrd(false)
        clearInterval(interval);stop=false;setDisplayStats(false);
      }
  }, parseInt(param.refreshSec));

  
    setTimeout(() => {  
      //console.log("clear")
      //setOrders({})
      //setDisplayStats(false)
      //setStop(false)
      //if(excluded.length===4) setStats({})
      setOrd(false)
      setStop(true)
      setStats(st)
      clearInterval(interval);
    }, parseInt(param.refreshMin));
   
  }
  const getStat=async()=>{
    setStop(false)
    var st={}; var ex=[]
        const respon=await Gfunc.axiosPostAction("_irh.php","reford",{/*st:1*/})
       if(respon.data.err && respon.data.err!=='-1'){
         // handleAlert(true,'error',respon.data.err);
        }
        else
        Gfunc.axiosResponse(respon,()=>{
          if(respon.data.data) {
              respon.data.data.map(function(elem) {
             // if(elem.stat===3||elem.stat===4) ex.push(elem.produitId)
             // else
             if(elem.stat===1)
              st[elem.produitId]={stat:elem.stat,mode:elem.mode}
              return false })
              if(!Gfunc.isEmpty(st)){
              setStats(st) 
              getStats() 
              }
            }
        })


  }

  const getDigCat=async(id,cat)=>{
    setLoading2(true)
    let digs=digsLStore;

    if(!digs.hasOwnProperty(id)){
      const respon=await Gfunc.axiosPostAction("_irh.php","getdigitprd",{catId:id})
    if(respon.data.err && respon.data.err!=='-1'){
      handleAlert(true,'error',respon.data.err);
    }else
    Gfunc.axiosResponse(respon,()=>{
      if(respon.data.jData){
        let l=respon.data.jData;
        l.sort((a, b) => { if(a.digitExist&&!b.digitExist) return -1; if(b.digitExist) return 1; return 0 });
        setDigsL(l) 
        digs[id]=l;
        setDigsLStoreAll(digs)
        setDigsLStore(digs)
      }
    })}
    setDigCatSelected(id)
    setDigCatSelectedN(cat)
    setLoading2(false)
  }


  const ScratchDig=async(tid,lid)=>{
   setDigImp({})
    if(!action){
    setAction(true)
    const respon=await Gfunc.axiosPostAction("_irh.php","scrtch",{tiketId:tid,liberoId:lid,defStock:localStorage.getItem('stockId')})
    if(respon.data.err && respon.data.err!=='-1'){
      handleAlert(true,'error',respon.data.err);
    }else
    Gfunc.axiosResponse(respon,()=>{
      if(respon.data.digit){
        setDigImp(respon.data.digit)
        const elem=document.getElementById("imprDig")
        if(elem){
          elem.click()
        }
     }
      if(respon.data.vente){
        handleAddVnt2(respon.data.vente,stk,stkN,respon.data.vente.mtn,(retour)?-respon.data.vente.qte:respon.data.vente.qte,"",handleAlert,()=>{},()=>{})  
     }
      if(respon.data.binars){
        const el=document.getElementById("BinarVal")
        if(el){
          el.innerHTML= new Intl.NumberFormat().format(respon.data.binars)
        }
     }
    })
    setAction(false)}
  }
  const ScratchDig2=async(tid,lid)=>{
   if(!action){
    setAction(true)
   setDigImp({})
    const respon=await Gfunc.axiosPostAction("_irh.php","scrtch",{tiketId:tid,liberoId:lid,defStock:localStorage.getItem('stockId')})
    if(respon.data.err && respon.data.err!=='-1'){
     // handleAlert(true,'error',respon.data.err);
      return respon.data.err
    }else
    Gfunc.axiosResponse(respon,()=>{
      if(respon.data.digit){
        setDigImp(respon.data.digit)
     }
      if(respon.data.vente){
        handleAddVnt2(respon.data.vente,stk,stkN,respon.data.vente.mtn,(retour)?-respon.data.vente.qte:respon.data.vente.qte,"",handleAlert,()=>{},()=>{})  
     }
     if(respon.data.binars){
      const el=document.getElementById("BinarVal")
      if(el){
        el.innerHTML= new Intl.NumberFormat().format(respon.data.binars)
      }
    }
    })
    setAction(false)}
    return true  
  }
   
  const getDigList=useCallback(async()=>{
    const respon=await Gfunc.axiosPostAction("_irh.php","getdigcats")
    if(respon.data.err && respon.data.err!=='-1'){
      //handleAlert(true,'error',respon.data.err);
    }else
    Gfunc.axiosResponse(respon,()=>{
      let filters={}
      if(respon.data.jData){
      setDigCatL(respon.data.jData) 

      respon.data.jData.map((el)=>{
        if(el.filters){
           filters[el.nom_fr]=el.filters
        }
        return true
      })
      setCatFilter(filters)

    }})
  },[])

  const FilterByCat=useCallback(async(pattern)=>{
    const search={...digsLStoreAll}
    let filtered=[]
    search[digCatSelected].map((elem)=>{
      if(elem.hasOwnProperty("digit")){
        if(elem.digit&&elem.digit.toLowerCase().includes(pattern.toLowerCase())){
          filtered.push(elem);
        }
      }
    })
    setDigsLStore({...search, [digCatSelected]: filtered});
  },[digsLStore,digCatSelected,digsLStoreAll])

  const handleChangeIndex = (index,id) => {
    setValue(index);
  };
//const inputRef = useRef();

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }
    // Supprimer une vente apres selection
 const handleDeleteVenteFocused= async() =>{
  if(!action){
    setAction(true)
  if(venteId!==''&&venteIndex!==''){
     const dat={
      venteId:venteId}
    const respon=await Gfunc.axiosPostAction(service,"delv",dat)
    if(respon.data.err && respon.data.err!=='-1'){
      handleAlert(true,'error',respon.data.err);
    }else
    Gfunc.axiosResponse(respon,()=>{
      if(respon.data.jData){
          handleChangeStk(detail.ventes[venteIndex].pdetId,"La suppression de "+detail.ventes[venteIndex].produit+" est annulée.",handleAlert,()=>{},()=>{})
          data[ticketIndex].mtn=data[ticketIndex].mtn-detail.ventes[venteIndex].mtn
          detail.total=data[ticketIndex].mtn
          detail.reste=data[ticketIndex].mtn
          setMtn(data[ticketIndex].mtn)
          detail.ventes.splice(venteIndex, 1);
          //  setDetail(detail)
          setControlVnt(!controlVnt)
        if(detail.ventes.length>0) {
          if(venteIndex<detail.ventes.length){
            document.getElementById("v"+detail.ventes[venteIndex].id).focus()
          }
          else{
            document.getElementById("v"+detail.ventes[venteIndex-1].id).focus()
          }
        }else{
          setVenteId('')
        }
      }
    })}else{
      handleAlert(true,'error',"Veuillez sélectionner une vente!")
    }
    setAction(false)}
}

const Caisse=async ()=>{
  const response=await Gfunc.axiosGetAction('_cai.php' ,'getcaisse')
  setCaisse( response.data.jData ) 
  Gfunc.updateElem("caisseMtn",response.data.jData)   
  Gfunc.updateElem("caisseMtn2",response.data.jData)   
}

const GetAtSoldes=async()=>{
  const response=await Gfunc.axiosGetAction("_irh.php","getatsolds")
  if(response.data.err && response.data.err!=='-1'){
   // handleAlert(true,'error',response.data.err);
  }else
  Gfunc.axiosResponse(response,()=>{
    if(response.data.jData){
       setAts(response.data.jData)
    }
  })
  
}

const handleFocusTik=useCallback((ind)=>{
  if(data.length>0) {
    if(ind<data.length){
      document.getElementById("t"+data[ind].tiketId).focus()
    }
    else{
      document.getElementById("t"+data[ind-1].tiketId).focus()
    }
  }else{
    setTicketId('')
    setDetail([])
  }
},[data])

//fetch Cat
const fetchCat= useCallback(async() => {
  const response = await Gfunc.axiosGetAction("_cat.php","gets")
  if(response.data.err&&response.data.err!=='-1'){
    handleAlert(true,'error',response.data.err)
  }else
  Gfunc.axiosResponse(response,()=>{
    if(response.data.jData)
      setCatL(response.data.jData);   
  })
},[Gfunc]);

 //handle the call for fetch client
 const handleOpenCat=(event)=>{
  if(catL.length===0)
  fetchCat();
 }

  //loop focus on tiket list
  $(function(){
    var list  = $(this).find('#table tbody tr*[tabindex]').sort(function(a,b){ return a.tabIndex < b.tabIndex ? -1 : 1; }),
        first = list.first();
        if(list.length>0){
          for(var i=0;i<list.length;i++){
              if(i<list.length-1){
                $('#'+list[i].id).on('keydown', function(e){
                  if( e.key=== 'ArrowDown'&&e.target === this  ) {
                      $(this).next().focus();
                      return false;
                  }
                });
              }
              if(i>0){
                $('#'+list[i].id).on('keydown', function(e){
                    if( e.key=== 'ArrowUp' ) {
                      $(this).prev().focus();
                        return false;
                    }
                });  
              }
        }}
    list.last().on('keydown', function(e){
        if( e.key=== 'ArrowDown' ) {
            first.focus();
            return false;
        }
    });
    first.on('keydown', function(e){
        if( e.key=== 'ArrowUp' ) {
            list.last().focus();
            return false;
        }
    });
  });
  //loop focus on tiketDet
  $(function(){
    var list  = $(this).find('#table1 tbody tr*[tabindex]').sort(function(a,b){ return a.tabIndex < b.tabIndex ? -1 : 1; }),
        first = list.first();
        if(list.length>0){
          for(var i=0;i<list.length;i++){
              if(i<list.length-1){
                $('#'+list[i].id).on('keydown', function(e){
                  if( e.key=== 'ArrowDown' && e.target===this) {
                      $(this).next().focus();
                      return false;
                  }
                });
              }
              if(i>0){
                $('#'+list[i].id).on('keydown', function(e){
                    if( e.key=== 'ArrowUp' && e.target===this) {
                      $(this).prev().focus();
                        return false;
                    }
                });  
              }
        }}
    list.last().on('keydown', function(e){
        if( e.key=== 'ArrowDown' && e.target===this) {
            first.focus();
            return false;
        }
    });
    first.on('keydown', function(e){
        if( e.key=== 'ArrowUp' && e.target===this) {
            list.last().focus();
            return false;
        }
    });
  });
//add ticket
const addTicket= async(retour) => {
  if(!action){
  setAction(true)
  var tik=null
  var date = new Date();
  var minutes = date.getMinutes();
  var hour = date.getHours();
  var hour = hour+':'+String(minutes).padStart(2, '0');
  const response=await Gfunc.axiosPostAction(service,'addtik',{jourId:jourId,retour:retour})
  if(response.data.err&&response.data.err!=='-1'){
     handleAlert(true,'error',response.data.err)
  }else
  Gfunc.axiosResponse(response,()=>{
    if(response.data.jData!==undefined){
      setRetour(retour)
      var dat=data;
      handleAlert(true,'success',"Le ticket est bien ajouté.")
      tik=response.data.jData.tiketId
      data.unshift({heure:hour,numero:response.data.jData.numero,tiketId:response.data.jData.tiketId,mtn:0,retour:retour})
      setControlTik(!controlTik)
      const elem=document.getElementById("t"+response.data.jData.tiketId)
      if(elem) elem.focus()
    }     
  })  
  setAction(false)
  return(tik)
}
}
//add ticket
const addTicket2= async(retour) => {
  if(!action){
  setAction(true)
  var tik=null
  var date = new Date();
  var minutes = date.getMinutes();
  var hour = date.getHours();
  var hour = hour+':'+String(minutes).padStart(2, '0');
  const response=await Gfunc.axiosPostAction(service,'addtik',{jourId:jourId,retour:retour})
  if(response.data.err&&response.data.err!=='-1'){
     handleAlert(true,'error',response.data.err)
  }else
  Gfunc.axiosResponse(response,()=>{
    if(response.data.jData!==undefined){
      setRetour(retour)
      var dat=data;
      handleAlert(true,'success',"Le ticket est bien ajouté.")
      tik=response.data.jData.tiketId
      data.unshift({heure:hour,numero:response.data.jData.numero,tiketId:response.data.jData.tiketId,mtn:0,retour:retour})
      setControlTik(!controlTik)
      const elem=document.getElementById("t"+response.data.jData.tiketId)
      if(elem) elem.focus()
    }     
  })  
  setAction(false)
  return(tik)
}
}

   //fetch client 
   const fetchClient= useCallback(async() => {
    const response = await Gfunc.axiosGetAction("_cli.php","gets")
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData){
      const dat=response.data.jData
      dat.unshift({id:null,name:"Anonyme"})
        setClientL(dat);
      }   
    })
  },[Gfunc]);

   //handle the call for fetch client
   const handleOpenClient=(event)=>{
    if(clientL.length===0)
    fetchClient();
   }

    // ajouter un nouveau bon
    const handleAddAjuste= async(respon,stk,stkN,total,qte,pdetId,handleAlert=()=>{},handleTrue=()=>{},handleFalse=()=>{},from) =>{
      var detail={}
      detail['ventes']=[]
      detail.ventes.push({id:respon.data.jData.venteId,mtn:total,qte:qte,stockId:stk,stock:stkN,heure:respon.data.jData.time,produit:respon.data.jData.produit,pdetId:pdetId,marge:respon.data.jData.marge})
      data[ticketIndex].mtn=JSON.parse(total)+data[ticketIndex].mtn
      detail.total=data[ticketIndex].mtn
      detail.reste=data[ticketIndex].mtn
      setDetail(detail)
      setMtn(data[ticketIndex].mtn)
      setControlVnt(!controlVnt)
      const elem=document.getElementById("v"+respon.data.jData.venteId)
      if (elem)elem.focus()
      handleChangeStk(pdetId,"L'ajout de "+respon.data.jData.produit+" est annulé!",handleAlert,
        ()=>{
           //open dialogue add prods
           handleFalse()
         },
         ()=>{
          handleTrue()
         }
         )
         if(from){
           /*
          document.getElementById(from).focus()
          document.getElementById(from).select()*/
         }
    }

    const AddVente= async(ticketId,qte,prod,mtn,retour,newTik) =>{
      setTicketId(ticketId)
      if(!action){
        setAction(true)
        const dat={
          pdetId:prod,
          tiketId:ticketId,
          qte:parseInt(qte),
          stockId:parseInt(stk),
          mtn:parseInt(mtn),
          retour:retour,
        }
          const respon=await Gfunc.axiosPostAction(service,"addvman",dat)
          if(respon.data.err && respon.data.err!=='-1'){
            handleAlert(true,'error',respon.data.err);
          }else
          Gfunc.axiosResponse(respon,async()=>{
            if(respon.data.jData){
              var d=[...data]
                  d[0].mtn=mtn
                  setData(d)
                  handleAlert(true,'success',"La Binar est ajusté");
             }
          })
          setAction(false)
        }
      }
      

   const handleAjustBinar=async()=>{
        const respon=await Gfunc.axiosGetAction("_vnt.php","ajust")
        if(respon.data.err && respon.data.err!=='-1'){
          handleAlert(true,'error',respon.data.err);
        }else
        Gfunc.axiosResponse(respon,()=>{
          if(respon.data.jData){
            var dat =[...data];
            data.unshift({heure:respon.data.jData.heure,numero:respon.data.jData.numero,tiketId:respon.data.jData.tiketId,mtn:respon.data.jData.mtn,retour:respon.data.jData.retour,caisseId:respon.data.jData.caisseId})
            setControlTik(!controlTik)
            const elem=document.getElementById("t"+ticketId);
            GetAtSoldes()
            if(elem) elem.focus()
          }
        })
   }


   //fetch Stock
   const fetchStk= useCallback(async(id) => {
    const response = await Gfunc.axiosGetAction("_stk.php","gets")
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData)
        setStkL(response.data.jData);   
    })
  },[Gfunc]);

   //handle the call for fetch client
   const handleOpenStk=(event)=>{
    if(stkL.length===0)
    fetchStk(event.target.id);
   }

 //fetch journee id
  const fetchj= useCallback(async() => {
    const response=await Gfunc.axiosGetAction('_jrn.php','initj')
    if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,'error',response.data.err)
       return (-1) 
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setJourId(response.data.jData.jourId);     
      }   
    })
    if(response.data.jData!==undefined){
      return(response.data.jData.jourId);
    }
    else{
      return (-1) 
    }     
  },[Gfunc]);

 //fetch tickets
  const fetchTicket= useCallback(async(jI) => {
    setLoading1(true)
    const response=await Gfunc.axiosPostAction(service,'getsjt',{jourId:jI})
    if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setData(response.data.jData);
        setFElem(response.data.jData[0])
      }     
    })  
    setLoading1(false)
  },[Gfunc]);

 //add avec short cut
  const handleAddShort= async(prodId,qte,stockId,stock) => {
    if(!action){
    setAction(true)
    const dat={
      tiketId:ticketId,
      produitId:prodId,
      qte:qte,
      stockId:stockId,
      retour:retour,
      defStock:parseInt(localStorage.getItem('stockId'))
    }
    const response=await Gfunc.axiosPostAction(service,'addvshort',dat)
    if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        handleAddVnt(response,stockId,stock,response.data.jData.mtn,(retour)?-qte:qte,response.data.jData.pdetId,handleAlert,()=>{},()=>{})  
        handleAlert(true,'success',"La vente est ajoutée");
        
      }     
    })
    setAction(false) } 
  }

 
  const fetchDetTik=async(service ,action,data)=>{
    setLoading(true)
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Opération annulée en raison d'une nouvelle demande.")
    }
  
    //Save the cancel token for the current request
    
    let c=axios.CancelToken.source()
    setCancelToken(c)
     try {
        const response= await axios({
          method: 'POST',
          url:param.urlService+service+'?do='+action,
          headers: Gfunc.getHeader(cancelToken), 
          data:JSON.stringify(data),
          cancelToken: c.token  
        })
        return response
      } catch (error) {
        //console.log(error)
        return {data:{err:"Opération annulée en raison d'une nouvelle demande"}}
      }
  }

 //fetch tickets det
  const fetchTicketDet= useCallback(async(e,elem,ind) => {
   if(ticketId!==elem.tiketId){  if(venteId!==''){
      var prevElement = document.getElementById("v"+venteId);
    }
    setLoading(true)
    setTicketId(elem.tiketId);
    setTicketNum(elem.numero)
    setTicketIndex(ind)
    setRetour(elem.retour)
    setMtn(elem.mtn);
    setCaisseId(elem.caisseId!=null)
    setClientN(elem.client)
    setClientId(elem.clientId)
  
    if(elem.tiketId!==ticketId){
      var prevElement = document.getElementById("t"+ticketId );
      if(prevElement) prevElement.classList.remove("lastSelectedT");
      e.target.classList.add('lastSelectedT')

      //Check if there are any previous pending requests
      const response=await fetchDetTik(service,'gettiketlte',{jourId:jourId,idt:elem.tiketId})
      if(response){if(response.data.err&&response.data.err!=='-1'){
        //handleAlert(true,'error',response.data.err)
        return false
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==undefined){
          var dt=response.data.jData;
          dt['DT_RowId']='c'+elem.tiketId;  
          setNote(dt.noteTiket)  
          setDetail(dt);
        }     
      })}else setDetail([]);
      setLoading(false)
    }
  }
  },[Gfunc,jourId,service,ticketId,venteId,loading,fetchDetTik]);
  
  //handle update note
 const handleOnEditNote=useCallback(async(note,row,att,action)=>{
  const edt1=note
  var edt=Gfunc.DeleteSpace(edt1);
  detail.noteTiket=edt
  if(row[att]!==edt){
    const attrib=row[att];
    const response=await librery.axiosEdit2(service,action,row,edt,att) 
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{if(response.data.jData) row[att]=edt})
    if(response.data.err) {row[att]=attrib}
  }else {row[att]=edt}
  setSelected('');
},[librery,Gfunc,detail]);

 const handleVenteClicked=async(vId,e,ind)=>{
    if(vId!==venteId){setVenteId(vId)
    setVenteIndex(ind)
    e.preventDefault()
    e.target.classList.add('lastSelectedV')
    var prevElement = document.getElementById('v'+venteId);
    if(prevElement){
    prevElement.classList.remove("lastSelectedV");
  }}
 }

 // Encaisser un ticket
 const handleEncaisse= ( val/*,cId,cN*/,vers) =>{
   Caisse()
   setCaisseId(val);
   if(vers){
    // data.client=cN
    // data.clientId=cId
    // detail.client=cN
    // detail.clientId=cId
    //detail.noteTiket=note
     detail.versement=vers
     detail.reste=detail.total-vers
    }
   var elem = document.getElementById("t"+ticketId );
     if(val) {data[ticketIndex].caisseId="Id"
      elem.classList.add('payedTicket')
    }
    else {
      data[ticketIndex].caisseId=null;
      elem.classList.remove("payedTicket");
    }
}

 // delete Encaissement ticket
 const handleDeleteEncaisse= async() =>{
  if(!action){
    setAction(true)
  const dataa={
    tiketId:ticketId,
  }
    const respon=await Gfunc.axiosPostAction(service,"delpaytik",dataa)
    if(respon.data.err && respon.data.err!=='-1'){
      handleAlert(true,'error',respon.data.err);
    }else
    Gfunc.axiosResponse(respon,()=>{  
      handleAlert(true,'success',"L'encaissement est annulé!");
      if(respon.data.jData.success){
          if(detail.total===0){
            fetchTicket(jourId)
          }
         else {detail.versement=0
          detail.reste=detail.total
          handleEncaisse(false)
            if(respon.data.jData.resetClient){
              var dat=[...data]
              dat[ticketIndex].clientId=null
              dat[ticketIndex].client=''
              setData(dat)
            }}
      }
    })
    setAction(false)
  }
}
 // fetch shortcuts
 const getShortCuts= async() =>{

  const respon=await Gfunc.axiosPostAction('_mag.php','getmag',{id:localStorage.getItem('magid')})
    if(respon.data.err && respon.data.err!=='-1'){
      handleAlert(true,'error',respon.data.err);
    }else
    Gfunc.axiosResponse(respon,()=>{ 
      if(respon.data.jData){ 
        setShortcuts(respon.data.jData.shortcuts)
        setAircuts(respon.data.jData.airtimeSorts)
      }
    })
}


 // Supprimer une vente
 const handleDeleteTicket= useCallback(async() =>{
  const x=window.confirm("Êtes-vous sûrs de supprimer ce ticket?");
  if(x){
  if(!action){
    setAction(true)
    const dat={
      tiketId:ticketId,
    }
    const respon=await Gfunc.axiosPostAction(service,"deltik",dat)
    if(respon.data.err && respon.data.err!=='-1'){
      handleAlert(true,'error',respon.data.err);
    }else
    Gfunc.axiosResponse(respon,()=>{
      if(respon.data.jData){
        Caisse()
        handleAlert(true,'success',"Le ticket est supprimé.");
        if(data.length>1){
            data.splice(ticketIndex, 1);setData(data)
            if(Gfunc.arrayhasObjWithAtt(detail.ventes,"pdetId",[0,1000001,1000002,1000003])){
              GetAtSoldes()
            }
        setControlTik(!controlTik)
        handleFocusTik(ticketIndex)
      } else {setLoading(true);setData([]);setDetail([]);setLoading(false);}
      }
    })
  setAction(false)}
  }
},[control,data,ticketId,ticketIndex,service,mtn,Caisse,handleAlert,Gfunc,handleFocusTik])

 // Supprimer une vente
 const handleDeleteVente= async(row,index) =>{
   setAction(true)
  const dat={
    venteId:row.id,
  }
  const respon=await Gfunc.axiosPostAction(service,"delv",dat)
  if(respon.data.err && respon.data.err!=='-1'){
    handleAlert(true,'error',respon.data.err);
  }else
  Gfunc.axiosResponse(respon,()=>{
    if(respon.data.jData){
      handleChangeStk(row.pdetId,"La suppression de "+row.produit+" est annulée.",handleAlert,()=>{},()=>{})
      detail.ventes.splice(index, 1);
      data[ticketIndex].mtn=data[ticketIndex].mtn-row.mtn
      detail.total=data[ticketIndex].mtn
      detail.reste=data[ticketIndex].mtn
      setMtn(data[ticketIndex].mtn)
      //setDetail(detail)
      setControlTik(!controlVnt)
      if(detail.ventes.length>0) {
        if(index<detail.ventes.length){
          document.getElementById("v"+detail.ventes[index].id).focus()
          setVenteId(detail.ventes[index].id)
        }
        else{
          document.getElementById("v"+detail.ventes[index-1].id).focus()
          setVenteId(detail.ventes[index-1].id)
        }
        }else{
          setVenteId('')
        }
    }
  })
  setAction(false)
 }

  useEffect(async() => {
    if(!binarsCalled &&(!JSON.parse(param.gros)&&(JSON.parse(localStorage.getItem("intgIraha")))))
    {GetAtSoldes();
    setBinarsCalled(true)}
  }, [GetAtSoldes,binarsCalled,param]);

  useEffect(async() => {
    if(!stCalled)
    getStat()
    setStCalled(true)
  }, [stCalled,setStCalled]);

  useEffect(async() => {
    if (!shorts){
    getShortCuts();setShorts(true)}
  }, [getShortCuts,shorts]);

  useEffect(async() => {
    const jI= await fetchj()
    if(jI!==-1){
    fetchTicket(jI);}
    if(!JSON.parse(param.gros)&&(JSON.parse(localStorage.getItem("intgIraha")))) getDigList()
  }, [fetchTicket,fetchj,getDigList]);
  
  useEffect(async() => {
    if(fElem&&fElem.tiketId){const elemm =document.getElementById("t"+fElem.tiketId)
    if(elemm) elemm.focus(); else setElem(!elem)}
  }, [fElem,elem]);

  
     // ajouter une vente auto
 const handleAutoAdd= async() =>{
    const data={
        code:cb,
        tiketId:ticketId,
        retour:retour,
        defStock:parseInt(localStorage.getItem('stockId'))
    }
        const respon=await Gfunc.axiosPostAction(service,"addvauto",data)
        
        if(respon.data.err && respon.data.err!=='-1'){
          handleAlert(true,'error',respon.data.err);
        }else
        Gfunc.axiosResponse(respon,async()=>{
          if(!respon.data.jData.detail){
            //handleAlert(true,'success',"La vente est ajoutée");
            handleAddVnt(respon,stk,stkN,respon.data.jData.mtn,(retour)?-1:1,respon.data.jData.pdetId,handleAlert,()=>{},()=>{},"addAuto")  
            //detail.ventes.unshift({id:respon.data.jData.venteId,mtn:respon.data.jData.mtn,qte:1,stock:stkN,heure:respon.data.jData.time,produit:respon.data.jData.produit,pdetIdt:respon.data.jData.pdetId})
           // setControl(!control)
           /*handleChangeStk(respon.data.jData.pdetId,respon.data.jData.produit,handleAlert,
              ()=>{
              },
              ()=>{}
              )*/
          }
          else{
                //open dialogue add prods
                setDataAdd(respon.data.jData)
                document.getElementById('addAutoBtn').click()
          }
    })
    Focus("iCode")
}
  
     // ajouter une vente par recherche
 const handleSearchAdd= async() =>{
    const data={
        search:search,
        retour:retour,
        //defStock:parseInt(localStorage.getItem('stockId'))
    }
        const respon=await Gfunc.axiosPostAction("_prd.php","search",data)
        if(respon.data.err && respon.data.err!=='-1'){
          handleAlert(true,'error',respon.data.err);
        }else
        Gfunc.axiosResponse(respon,async()=>{
          if(respon.data.jData){
                //open dialogue add prods
                setDataSAdd(respon.data.jData)
                document.getElementById('addSearchBtn').click()
          }
    })
}
     // ajouter un nouveau bon
     const handleAddVnt= async(respon,stk,stkN,total,qte,pdetId,handleAlert=()=>{},handleTrue=()=>{},handleFalse=()=>{},from) =>{
      if(detail.ventes===undefined) detail['ventes']=[]
      detail.ventes.unshift({id:respon.data.jData.venteId,mtn:total,qte:qte,stockId:stk,stock:stkN,heure:respon.data.jData.time,produit:respon.data.jData.produit,pdetId:pdetId,marge:respon.data.jData.marge})
      data[ticketIndex].mtn=JSON.parse(total)+data[ticketIndex].mtn
      detail.total=data[ticketIndex].mtn
      detail.reste=data[ticketIndex].mtn
      setMtn(data[ticketIndex].mtn)
      setControlVnt(!controlVnt)
      if(from!=="addAuto"){
      const elem=document.getElementById("v"+respon.data.jData.venteId)
      if (elem)elem.focus()}
      else{
      const elem=document.getElementById("v"+respon.data.jData.venteId)
      if (elem)elem.focus()
      Focus("iCode")
    }
      handleChangeStk(pdetId,"L'ajout de "+respon.data.jData.produit+" est annulé!",handleAlert,
        ()=>{
           //open dialogue add prods
           handleFalse()
         },
         ()=>{
          handleTrue()
         }
         )
         if(from){
           /*
          document.getElementById(from).focus()
          document.getElementById(from).select()*/
         }
    }
     // ajouter un nouveau 
     const handleAddVnt2= async(respon,stk,stkN,total,qte,pdetId,handleAlert=()=>{},handleTrue=()=>{},handleFalse=()=>{},from) =>{
      if(detail.ventes===undefined) detail['ventes']=[]
      detail.ventes.unshift({id:respon.venteId,mtn:total,qte:qte,stockId:stk,stock:stkN,heure:respon.time,produit:respon.produit,pdetId:pdetId,marge:respon.marge})
      data[ticketIndex].mtn=JSON.parse(total)+data[ticketIndex].mtn
      detail.total=data[ticketIndex].mtn
      detail.reste=data[ticketIndex].mtn
      setMtn(data[ticketIndex].mtn)
      setControlVnt(!controlVnt)
      const elem=document.getElementById("v"+respon.venteId)
      if (elem)elem.focus()
      /*handleChangeStk(pdetId,"L'ajout de "+respon.produit+" est annulé!",handleAlert,
        ()=>{
           //open dialogue add prods
           handleFalse()
         },
         ()=>{
          handleTrue()
         }
         )*/
         if(from){
           /*
          document.getElementById(from).focus()
          document.getElementById(from).select()*/
         }
    }
    
     // handle Open Air time dialog
     const handleOpenAir= (x) =>{
        setProdId(x.produitId);
        setProduit(x.produit)
        const elem=document.getElementById("addAir")
        if (elem) elem.click()
    }

     const handleChangeStk= async(pdetId,message,handleAlert=()=>{},handleTrue=()=>{},handleFalse=()=>{}) =>{
         var respon2=await Gfunc.axiosPostAction(service,"stkeff",{pdetId:pdetId})
         var cpt=0;
        while(!respon2.data.jData&&(cpt<10)){
              respon2=await Gfunc.axiosPostAction(service,"stkeff",{pdetId:pdetId})
              cpt++;}
              if(!respon2.data.jData){
                 handleAlert(true,'error',message);
                 handleFalse()
              }else{
                handleTrue()
              }
    }
    /*
     const handleChangeStkTkt= async(ticketId,message,handleAlert=()=>{},handleTrue=()=>{},handleFalse=()=>{}) =>{
         var respon2=await Gfunc.axiosPostAction(service,"stkeff",{tiketId:ticketId})
         var cpt=0;
        while(!respon2.data.jData&&(cpt<10)){
              respon2=await Gfunc.axiosPostAction(service,"stkeff",{tiketId:ticketId})
              cpt++;}
              if(!respon2.data.jData){
                 handleAlert(true,'error',message);
                 handleFalse()
              }else{
                handleTrue()
              }
    }*/
     // treat response after quantity update in vente
     const handleUpdateQte= async(resp,elem,index) =>{
      Caisse();
      //const bbb=detail
      data[ticketIndex].mtn=data[ticketIndex].mtn-elem.mtn+resp.jData.mtn
      detail.total=data[ticketIndex].mtn
      detail.reste=data[ticketIndex].mtn-detail.versement
      setMtn(data[ticketIndex].mtn)
      elem.mtn=resp.jData.mtn;
      detail.ventes[index].mtn=resp.jData.mtn;
      detail.ventes[index].marge=resp.jData.marge;
      //setDetail(bbb)
      setControlVnt(!controlVnt)
      var prevElement = document.getElementById('v'+venteId);
      if(prevElement) prevElement.classList.add('lastSelectedV')
      setAction(false)
    }

     // treat response after montant update in vente
     const handleUpdateMtn=async(resp,elem,index) =>{
      if(resp.jData){
        data[ticketIndex].mtn=data[ticketIndex].mtn-lastMtn+resp.jData.mtn 
        detail.total=data[ticketIndex].mtn 
        detail.reste=data[ticketIndex].mtn-detail.versement
        setMtn(data[ticketIndex].mtn)
        detail.ventes[index].marge=resp.jData.marge;    
        setControl(!control)
      }
    }
     // treat response after montant update in vente
     const handleEncaisseShort=async(resp,elem,index) =>{
      if(!action){
        setAction(true)
        const data={
          tiketId:ticketId,
          clientId:clientId,
          mtn:Math.abs(mtn),
          note:Gfunc.stringToNull(note),
          retour:retour,
        }
        const respon=await Gfunc.axiosPostAction(service,"paytik",data)
        if(respon.data.err && respon.data.err!=='-1'){
          handleAlert(true,'error',respon.data.err);
        }else
        Gfunc.axiosResponse(respon,()=>{
          if(respon.data.jData){
          handleAlert(true,'success',"Le ticket est encaissé.");
          handleEncaisse(true/*,clientId,clientN*/,mtn)
          }
        })
        }
    }

   // handle what happens on key press
   const handleKeyPress = useCallback((event) => {
     let elem=null
     let dat=data;
     if((event.ctrlKey||event.altKey||event.shitKey)&&event.target.type==="number"&&isNaN(parseInt(event.key))){
      event.target.blur()
     }
    //alert(`Key pressed: ${event.key}`);
    if(event.key==='F6'){
      event.preventDefault()
      elem=document.getElementById("iCode")
      if(elem) elem.focus()
    }
    if(event.key==='F7'){
      event.preventDefault()
      elem=document.getElementById("iLook")
      if(elem) elem.focus()
    }
    if(event.key==='F4'){
      event.preventDefault()
      elem=document.getElementById("iNote")
      if(elem){ elem.click();
        elem=document.getElementById("iNote")
        if(elem) { elem.focus();}
      }
    }
    if(event.target.id!=="iLook"&&event.target.id!=="iCode"&&!(event.target.classList.contains("content-editable")/*&&event.key!=="-"&&event.key!=='*'*/)){
      if(event.key==='ArrowLeft'){
      if(ticketId!==''){
        document.getElementById('t'+ticketId).focus()
      }else
      $(function() {
        $("#table tbody tr[tabindex=1]").focus(); 
        //code jQuery pour boucler le focus des elements   
      });
    }
    if(event.key==='ArrowRight'){
      $(function() {
        if(venteId!=='') elem=document.getElementById('v'+venteId)
        if(elem) {elem.focus()}
        else{
          var index=data.length+1
          $("#table1 tbody tr[tabindex="+index+"]").focus();
        }    
      });
    }
    if(event.key==='-'&&venteId!==''&venteId!==null){
      event.preventDefault()
      elem=document.getElementById("m"+venteId)
      if(elem){ elem.click(); 
        elem=document.getElementById("m"+venteId)
        if(elem) { elem.focus();elem.select();}
        }
    }
    if(event.key==='*'&&venteId!==''&venteId!==null){
      event.preventDefault()
      elem=document.getElementById("q"+venteId)
      if(elem){ elem.click();
        elem=document.getElementById("q"+venteId)
        if(elem) { elem.focus();elem.select();}
      }
    }
    }
   // event.preventDefault();
  },[data,ticketId,ticketIndex,venteId,caisseId,handleEncaisseShort]);

  const handleOnClickFilter =(e,item)=>{
    if(!retour){FilterByCat(item.pattern); 
      const boxes = document.querySelectorAll('.addOp');
      boxes.forEach(box => {
        // Remove class from each element
        box.classList.remove('addOp');
      
        // Add class to each element
        // box.classList.add('small');
      });
      e.target.classList.add('addOp');}
  }

  const Focus =useCallback((val)=>{
    var elem=null
    if(val==="iLook") setSearch('')
    if(val==="iCode") setCb('')
      elem=document.getElementById(val)
      if(elem) {elem.click();elem.focus()}
  },[])

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);
    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
   };
  }, [handleKeyPress]);

  const compo=()=>{
    return(
      <>
    <StkCompt param={param} handleAlert={handleAlert}/>
    <SldClt param={param} handleOpenClient={handleOpenClient} clientL={clientL} handleAlert={handleAlert}/>
    <HistCli param={param} clientId={clientId} handleOpenClient={handleOpenClient} clientL={clientL} handleAlert={handleAlert}/>
   {(JSON.parse(param.gros))?<Stks param={param} catL={catL} handleOpenCat={handleOpenCat} handleAlert={handleAlert}/>:null}
    <Fab className={classes.sideBtn} variant="extended" size="small" color="secondary"  disabled> Détail ventes</Fab>
    <Fab className={classes.sideBtn} variant="extended" size="small" color="secondary"  disabled> Stocks</Fab>
    <Fab className={classes.sideBtn} variant="extended" size="small" color="secondary"  disabled> Stocks</Fab>
    </>
  )
  }

  return (
    <div className={(!JSON.parse(param.gros)&&(JSON.parse(localStorage.getItem("intgIraha"))))?"content vntGrid":"content vntGrid2"}>
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA}/>
       <BackdropCompo className={classes.backdrop} open={action}/>
       <div>
       <div className="headButtons">
          <div id='ticketFunc'>
            <ScrollableTabs compo={compo}/>
          </div>
          <Tooltip title="Mode écran tactile">
            <Switch size="small" checked={digital} classes={{ track: classes.switch_track, switchBase: classes.switch_base, colorPrimary: classes.switch_primary }} onChange={(e)=>{setCookie('digital', !digital, { path: '/'});setDigital(!digital)}} color='primary'/>  
          </Tooltip>
        </div>
      <div id='comptoire'>
        <div id='ticketList'>
        <div id='ticketInfo'>
        <fieldset className="fieldsetTop3" id="vntInfo">
                   <legend>Ticket N&#186;: {ticketNum}</legend>
                  { (!Gfunc.isEmpty(detail))?
                   <>
                    <div  style={{display:'flex',gridGap:"10px",alignItems:'center',fontSize:'12px',padding:'4px 8px',
                          margin:'4px auto 4px auto'}}>
                        {/*<Icon path={mdiClipboardAccount} size={0.8} />*/}
                        <b> Client:</b>                   
                        <p className={(!caisseId)?"infSelect":"inf"}>
                          { (caisseId)?detail.client:
                            <AutocompleteS
                              from='inf' className="content-editable-exp"
                              service={service} action={'updtik'} 
                              defaultValue={detail.client} row={detail} list={clientL} 
                              id={'clientId'} name='client' attr='name'
                              onOpen={handleOpenClient} handleResponse={()=>{
                                setClientId(detail['clientId'])
                                setClientN(detail['client'])
                                data[ticketIndex].client=detail['client']
                                data[ticketIndex].clientId=detail['clientId']
                                Gfunc.updateElemS("c"+ticketId,detail['client'])
                              }} handleAlert={handleAlert}/> 
                          }               
                        </p>
                    </div>
                  <div className="grid3">
                      <div style={{display:'flex',gridGap:"5px",alignItems:'center',fontSize:'12px',padding:'4px 8px',
                           margin:'4px 0 4px 0'}} >
                        <b> Total:</b>
                        <p> {Intl.NumberFormat().format(Math.round(detail.total))}</p>
                      </div>
                      <div style={{display:'flex',gridGap:"5px",alignItems:'center',fontSize:'12px',padding:'4px 8px',
                           margin:'4px auto 4px auto'}}>
                        <b> Versé:</b>
                        <p> {Intl.NumberFormat().format(Math.round(detail.versement))}</p>
                      </div>
                      <div style={{display:'flex',gridGap:"5px",alignItems:'center',fontSize:'12px',padding:'4px 8px', 
                           margin:'4px auto 4px auto'}}>
                        <b> Reste:</b>
                        <p> {Intl.NumberFormat().format(Math.round(detail.reste))}</p>
                      </div>  
                   </div> 
                   <Tooltip title={(!caisseId)?"Modifier par: F4":''}>
                    <div style={{display:'grid',gridTemplateColumns:'15% auto',gridGap:"10px",alignItems:'center',fontSize:'12px',
                                 padding:'4px 8px',margin:'4px auto 4px auto'}}>
                      <b> Note: (F4)</b>
                      <p className={(!caisseId)?"infSelect":"inf"}>
                          {(caisseId)?detail.noteTiket:<div className="note-container">
                              { (!Gfunc.isEmpty(detail)&&selected)?
                              <ClickAwayListener onClickAway={()=>{(note)?handleOnEditNote(note,detail,"note",'updtik'):setSelected(false)}}>
                              <TextareaAutosize id="iNote" style={textAreaEdit} value={note}  onChange={(e)=>{setNote(e.target.value);}}
                              /></ClickAwayListener>:
                              (detail.noteTiket!==undefined&&(detail.noteTiket&&detail.noteTiket.match(/\n/g) || []).length>=1)?
                                <Tooltip title={detail.noteTiket.replaceAll("\n", "/ ")}>    
                                  <div className="content-editable-exp" id="iNote" onClick={()=>{setNote(detail.noteTiket);setSelected(true)}}>{detail.noteTiket.substring(0, detail.noteTiket.indexOf("\n"))+"..."}</div>
                                </Tooltip>:
                                <Tooltip title={(detail.noteTiket)?detail.noteTiket:''}>
                                  <div className="content-editable-exp" id="iNhandleOpenClientote" onClick={()=>{setNote(detail.noteTiket);setSelected(true)}} >{detail.noteTiket}</div>
                                </Tooltip>
                              }
                            </div>}
                      </p>
                        </div>
                      </Tooltip> 
                      <div className="flex-wrapper centerContent tickBtn" >
                          <PayTik checkUpd={checkUpd} action={action}  caisseId={caisseId} clientId={clientId} mtn={mtn} disabled={caisseId} retour={retour}
                            clientN={clientN} note={note} clientL={clientL} ticketId={ticketId} 
                            handleOpenClient={handleOpenClient}  setAction={setAction}  handleEncaisse={handleEncaisse} 
                            handleAlert={handleAlert} handleDeleteEncaisse={handleDeleteEncaisse}/>
                          <RaccBtn
                            id="supTik"
                            tip={'Supprimer ticket'}
                            className={classes.racBtnRed}
                            onClick={handleDeleteTicket} 
                            text={'Sup.'}
                            path={mdiReceiptTextRemove}
                            iconColor={"white"}
                            racc={'F3'}
                            digital={digital}
                            disabled={caisseId}
                          />
                        <PrintTicket param={param} idt={ticketId} from='vnt' disabled={(caisseId||(detail.ventes&&detail.ventes.length>0))?false:true}/>
                    </div> 
                   </>:null}
                </fieldset>
                </div>
            <Paper className="ticketListP">
              <div className="ticketbuttons"> 
                <RaccBtn
                  id="AddTikV"
                  tip={'Ajouter un ticket de vente'}
                  className={classes.racBtnGrey}
                  onClick={()=>{addTicket(false)}}
                  text={'Tick. vt.'}
                  path={mdiReceiptTextPlus}
                  iconColor={"black"}
                  digital={digital}
                  racc={'F1'}
                />
                <RaccBtn
                  id="AddTikR"
                  tip={'Ajouter un ticket de retour'}
                  className={classes.racBtnGrey}
                  onClick={()=>{addTicket(true)}}
                  text={'Tick. rt.'}
                  path={mdiReceiptTextArrowLeft}
                  iconColor={"black"}
                  digital={digital}
                  racc={'ctrl+F1'}
                />
                <ReturnTikPlus action={action} retour={retour} handleAlert={handleAlert} addTicket={addTicket} fetchTicket={()=>fetchTicket(jourId)} tiketId={ticketId} handleChangeStk={handleChangeStk}/>
              </div>
              <div className="ticketL" tabIndex={0}>
              {(loading1)?<div id="circularProgress1"><CircularProgress /></div>:(data.length!==0)?
              <table id='table' className={(digital)?"tableT":''} key={controlTik}>
              <tbody>
                 { data.map((elem,index)=>{
                    return(
                      <Tooltip  key={index} title={elem.numero} placement="right">
                      <tr className={"ticket "+((elem.retour)?"ticketRet":"")} id={'t'+elem.tiketId} style={(elem.caisseId)?(!elem.retour)?{background:"#d9ffb0",color:"black"}:{background:"#ffde75",color:"black"}:null} tabIndex={index+1} 
                          onFocus={(e)=>{fetchTicketDet(e,elem,index)}}>
                        <td id="timeTv">{elem.heure}</td>
                        <td id={"c"+elem.tiketId} className="nClient">{elem.client}</td>
                        <td className="numerot1">{Intl.NumberFormat().format(Math.round(elem.mtn))}</td>
                        <td className="venteIcons">{(elem.caisseId)?<Iconn path={mdiCashFast} color="green" size="18px"/>:null} </td>
                      </tr>
                    </Tooltip>
                    )
                  })}
                </tbody>
              </table>:<NoTableDataComponent message="La liste des tickets est vide."/>}
              </div>
            </Paper>
        </div>
        <div id='ticketDetail' >
            <div id='ticketInfo' className={(data.length>0&&ticketIndex!==''&&caisseId)?"disabledDiv":''}>
                <fieldset className="fieldsetTop4" id="vntForm">
                   <legend>Ajouter une vente:</legend>
                   { (!Gfunc.isEmpty(detail))?
                   <div id='raccField'>
                   <div id='inf2'>
                     <div className="rowGrid">
                   <ValidatorForm id="addVA" onSubmit={handleAutoAdd} onError={errors => console.log(errors)} > 
                     <Tooltip title="Accéder avec: F6" placement="right">
                              <TextField  variant='outlined' size="small" label={labelText1} id="iCode" name="iCode"  value={cb} 
                                   onChange={e =>setCb(e.target.value)} placeholder="Accéder avec: F6"  InputProps={{
                                      endAdornment:
                                      <IconButton type='submit' style={{padding:'2px'}} disabled={caisseId}><Add fontSize="small" className={classes.blackSmallIcon} /></IconButton>,
                                         classes: {adornedEnd: classes.adornedEnd}
                                      }}
                                     className='smallText' disabled={caisseId} required/>
                    </Tooltip>
                  </ValidatorForm>
                   <ValidatorForm id="addVS" onSubmit={handleSearchAdd} onError={errors => console.log(errors)} > 
                         <Tooltip title="Accéder avec: F7" placement="right">
                              <TextField  variant='outlined' size="small" label={labelText2} placeholder="Accéder avec: F7" id="iLook" name="iRecherche" autoComplete="off"  value={search} inputProps={{ minLength: 3}}
                                   onChange={e =>setSearch(e.target.value)}  InputProps={{
                                      endAdornment:
                                      <IconButton type='submit' style={{padding:'2px'}} disabled={caisseId}><Search fontSize="small" className={classes.blackSmallIcon} /></IconButton>,
                                      classes: {adornedEnd: classes.adornedEnd}
                                      }}
                                     className='smallText' disabled={caisseId} required/>
                          </Tooltip>
                  </ValidatorForm>
                  </div>
                  <div className='flex-wrapper'>
                          <AddVnt ticketId={ticketId} catL={catL} handleOpenCat={handleOpenCat} retour={retour} handleAddVnt={handleAddVnt} handleAlert={handleAlert} disabled={caisseId}/>
                          <RaccBtn
                              id="suppV"
                              tip={'Supprimer une vente'}
                              className={classes.racBtnRedBig}
                              onClick={handleDeleteVenteFocused} 
                              text={'Sup. vt.'}
                              path={mdiBasketRemove}
                              iconColor={"white"}
                              racc={'F8'}
                              digital={digital}
                              disabled={caisseId}
                          /> 
                       </div>
                       <Divider orientation="vertical" textAlign="left" style={{ marginBottom:'5px'}}>
                    </Divider>
                  <AddVntAuto Focus={Focus} param={param} ticketId={ticketId} retour={retour} data={dataAdd} handleAddVnt={handleAddVnt} handleAlert={handleAlert} disabled={caisseId}/>
                  <AddVntSearch Focus={Focus} param={param} ticketId={ticketId} retour={retour} data={dataSAdd} handleAddVnt={handleAddVnt} 
                                handleAlert={handleAlert} disabled={caisseId}/>
                  </div>
                  <AddAir prodId={prodId} produit={produit} ticketId={ticketId} retour={retour} sugg={sugg} setSugg={setSugg} handleAddVnt={handleAddVnt} handleAlert={handleAlert} 
                          callBack={()=>{ GetAtSoldes()}} />
                  <AddPack param={param} ticketId={ticketId} retour={retour} data={dataAdd} handleAddVnt2={handleAddVnt2} handleAlert={handleAlert} disabled={caisseId} digital={digital}/>
                  { (aircuts.length>0)?
                     aircuts.map((elem,index)=>{
                       return( <RaccBtnStatic
                            id={'air'+index}
                            key={index}
                            tip={elem.produit}
                            className={classes.racAir}
                            onClick={()=>handleOpenAir(elem)}
                            text={elem.produit}
                            path={(elem.icon)?elem.icon:null}
                            iconColor={"white"}
                            digital={digital}
                            racc={"ctrl+"+index}
                            disabled={caisseId}
                          />)}):null
                    }  
                   { (shortcuts.length>0)?
                     shortcuts.map((elem,index)=>{
                       return( <RaccBtnFixed
                            id={'prod'+index}
                            key={index}
                            tip={elem.produit}
                            className={classes.racProd}
                            onClick={()=>handleAddShort(elem.produitId,elem.qte,elem.stockId,elem.stock)}
                            text={elem.shortcutName}
                            path={(elem.icon)?elem.icon:null}
                            iconColor={"black"}
                            digital={digital}
                            racc={elem.shortcut}
                            disabled={caisseId}
                          />)}):null
                    }
               </div>
                  :null}         
                </fieldset>
            </div>
            <div id='ticketDet'>
                <Paper className='ticketDetP'>
                { (loading)?<div id="circularProgress"><CircularProgress /></div>:(ticketId==='')?null:
                (detail&&!Gfunc.isEmpty(detail)&&detail.ventes!==undefined)?
                <div className="ticketDetL">
                <table id='table1' className={(digital)?"table1T":''} key={controlVnt}>
              <tbody>
                {
                  detail.ventes.map((elem,index)=>{
                    return(
                      <Tooltip key={elem.id} title={"Marge: "+Math.round(elem.marge)}  placement="bottom-end">
                      <tr id={'v'+elem.id} tabIndex={index+1+data.length} onFocus={(e)=>{handleVenteClicked(elem.id,e,index)}} >
                      <td id="timeTv">{elem.heure}</td>
                      <td id="prodNameV">{elem.produit}</td>
                      <td id="stockNameV">
                       { (!caisseId)?
                          <AutocompleteS
                            service={service} action={'updv'} rowId="id"
                            defaultValue={elem.stock} row={elem} list={stkL} 
                            id={'stockId'} name='stock' attr='nom'
                            onOpen={handleOpenStk} handleResponse={()=>{handleChangeStk(elem.pdetId,"La modification du stock de "+elem.produit+" est annulée!")}} handleAlert={handleAlert}/>:elem.stock
                      }   
                      </td>
                      <td className="numeroQte">
                      { (!caisseId)?
                        <InputNbrEditable
                          service={service} action='updv' min={(!retour)?1:undefined} max={(retour)?-1:undefined} rowId="id"
                          row={elem} att="qte" value={elem.qte} id='id' idi={'q'+elem.id}
                          checkForUpd={()=>setCheckUpd(true)}
                          handleAlert={handleAlert} doBefore={()=>setAction(true)} doAfter={()=>{setCheckUpd(false);setAction(false)}}
                          traetResp={(resp)=>{
                            if(resp!==undefined&&resp['jData']){  
                              handleUpdateQte(resp,elem,index)
                              handleChangeStk(elem.pdetId,"La modification de la quantité de "+elem.produit+" est annulée!")
                            }
                          }}
                        />:new Intl.NumberFormat().format(elem.qte)}
                      </td>
                      <td className="numero" onClick={()=>setLastMtn(elem.mtn)}>
                      { (!caisseId)?
                        <InputNbrEditable
                          service={service} action='updv' min={(!retour)?0:undefined} max={(retour)?-1:undefined} rowId="id"
                          row={elem} att="mtn" value={Math.round(elem.mtn)} id='id' idi={'m'+elem.id}
                          handleAlert={handleAlert}
                          checkForUpd={()=>setCheckUpd(true)}
                          doBefore={()=>setAction(true)} doAfter={()=>{setCheckUpd(false);setAction(false)}}
                          traetResp={(resp)=>{
                            if(resp!==undefined&&resp['jData']){  
                              handleUpdateMtn(resp,elem,index);
                            }
                          }}
                        />:new Intl.NumberFormat().format(Math.round(elem.mtn))}
                      </td>
                      <td className="venteIcons">{(!caisseId)?<IndeterminateCheckBox className={(digital)?classes.redIcon:classes.redSmallIcon} fontSize={(digital)?"medium":'small'} onClick={()=>{handleDeleteVente(elem,index)}}/>:null}</td>
                    </tr>
                    </Tooltip>
                    )
                  })
                } 
                </tbody>
              </table>
              </div>:<NoTableDataComponent message="Le ticket est vide!"/>}
                </Paper>
            </div>
        </div>
      </div>
      </div>
    {(!JSON.parse(param.gros)&&(JSON.parse(localStorage.getItem("intgIraha"))))?
      <div id="irahaInt" className="digL">
      <div id="try">
      <p style={{fontSize:"10px",color:"red",fontWeight: 600,textAlign: "center"}}>Une fois le code du produit digtal affiché, il vous sera facturé (aucun remboursement possible) </p>
      <HistoCliB param={param} at={ats} GetAtSoldes={GetAtSoldes} handleAjustBinar={handleAjustBinar} getStats={getStats} stats={stats} orders={orders} setOrders={setOrders} displayStats={displayStats} bid={bid} sid={sid} fid={fid} eid={eid} setStats={setStats} ord={ord} setOrd={setOrd} orderss={orderss} setOrderss={setOrderss} excluded={excluded.current} setExcluded={setExcluded} />
      </div>
        { (ticketId)?<Paper className='digitList'   style={(retour)?{opacity:0.5,cursor:"none !important"}:{}}>
            <AppBar position="static" color="default" className={classes.tabAppBar}>
              <Tabs
                className={classes.tabs}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth">
                {digCatL.map((elem,index)=>{
                  return ( 
                    <Tab disabled={retour} key={index} label={elem.nom_fr} index={index} onFocus={()=>getDigCat(elem.cat,elem.nom_fr)} className={classes.tab} autoFocus={index===0}/>
                  )
                })}
              </Tabs>
            </AppBar>{/*
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}>*/}
              {digCatL.map((elem,index)=>{
                  return (
                    <TabPanel  id="vntTabPanel" className={classes.tabPanel} value={value} key={index} index={index} style={{padding:0}} dir={theme.direction}>
                      {(!loading2)?<><div id="catFilters">
                          {(catFilter.hasOwnProperty(digCatSelectedN)?
                            catFilter[digCatSelectedN].map((item,index)=>{
                                return(
                                    //{/*<Fab key={index} onClick={()=>FilterByCat(item.pattern)} style={{maxWidth:"40px !important"}} className={classes.sideBtn + " button-flex-wrapper"} variant="extended" size="small" color="secondary"  > {item.pattern}</Fab>*/}
                                    <LazyLoad  style={{width:"fit-content", height:"fit-content", cursor:"pointer"}} key={"img-"+index} height={100}> <img disabled={retour}  src={param.imgFltr+item.logo+"-on.png"+"?auto=compress"} onClick={(e)=>handleOnClickFilter(e,item)} style={{width: "auto", height:"18px", cursor:"pointer"}} /></LazyLoad>
                                )
                            })
                          :null)}
                      </div>
                      <div className="digsListGrid" style={{height:"100%"}}>
                      {(digsLStore[digCatSelected])?digsLStore[digCatSelected].map((el,index)=>{
                          return (<Card key={"c"+index}>
                          <CardContent className={classes.cardContent}>
                       {  /*<div id="digTitle">
                               <Typography variant="body2" className="digName" component="h2"> {el.digit.substring(0,16)}</Typography>
                            </div>*/
                            }
                            <div style={{display:"grid",alignContent:"end",height:"125px"}}>
                              {(el.topup&&el.marqueId)?<TopUp el={el} ticketId={ticketId} disabled={retour} handleAddVnt2={handleAddVnt2} retour={retour} handleAlert={handleAlert} stk={stk} stkN={stkN} GetAtSoldes={GetAtSoldes}/>:null}
                               <Button disabled={retour||!el.digitExist} onDoubleClick={()=>ScratchDig(ticketId,el.liberoId)} className={classes.iconBtnBig} style={{background:"green",opacity:(retour||!el.digitExist)?0.4:1}} variant="contained" color="secondary"><Iconn path={mdiBasketPlus} color="white" size="16px" /></Button>
                            </div>
                            <Divider orientation="vertical" textAlign="left" style={{ marginTop:'0 !important',marginBottom:'0 !important',width:"100%", height:"auto"}}></Divider>
                            <div><DetailProduit disabled={retour||!el.digitExist} param={param} tid={ticketId} setDig={setDigImp} el={el} ScratchDig={ScratchDig2} dig={digImp}  picUrl={param.imgDigital+"prod_"+el.liberoId+".png"}/> </div> 
                            <PrintDig param={param} row={digImp} prx={Intl.NumberFormat().format(el.prx)} hidden={true}/>
                          </CardContent>
                        </Card>)
                      }):null}
                      </div></>:(retour)?null:<div style={{display:"flex",justifyContent:"center",alignItems:"center", height:"200px"}}><CircularProgress /></div>}
                    </TabPanel>
                  )
                })}
           {/* </SwipeableViews>*/}
         </Paper>:null}
        </div>:null}
    </div> 
  );
}