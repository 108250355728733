import React,{useState,useRef, useEffect} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField ,FormControl} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import { AlertCompo, RaccBtn } from '../../../js/components';
import { mdiCashPlus,mdiCashRemove } from '@mdi/js';
import $ from "jquery"
import Icon from '@mdi/react';
import { mdiCashMultiple } from '@mdi/js';
import { DoneAll } from '@material-ui/icons';
import {ReactComponent as Pieces} from '../../../img/icons/pieces.svg'

//add new bon
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
} 

export default function Vnt_cpt_pay(props) {
    const Gfunc=require('../../../Gfunc')
    const [client,setClient]=useState(null);
    const [clientN,setClientN]=useState('Anonyme');
    const [mtn,setMtn]=useState('');
    const [vrs,setVrs]=useState(0);
    const [timeB,setTimeB]=useState(0);
    const [rnd,setRnd]=useState('');
    const [enc,setEnc]=useState('');
    const [note,setNote]=useState('');
    const [open, setOpen] =useState(false);
    const [called, setCalled] =useState(false);

    const addTitle="Encaissement"
    const labelText1="Client"
    const labelText2="Note"
    const labelText3="Montant"
    const labelText4="Versement"
    const labelText5="Monnaie rendue"
    const labelText6="Billets"
    const service="_vnt.php"

    const classes=useStyles();
    const [openA, setOpenA] =useState(false);
    const [message, setMessage] =useState('');
    const [severity, setSeverity] =useState('info');
    
    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
 
    useEffect(()=>{
        const elem=document.getElementById('iEnc')
      if(elem){
      elem.focus();
      elem.select();}
      setCalled(true)
    },[called])


    useEffect(()=>{
      var pressTimer;
      var timebetween = 0;
      $("#encaisseBtn").mouseup(function(){
        clearInterval(pressTimer);
        setTimeB(timebetween)
        $("#encaisseBtn").click();
      });
        
      $("#encaisseBtn").mousedown(function(){
        // Set timeout
        timebetween = 0;
        pressTimer = setInterval(function(){
            timebetween++;
        }, 1);
        return false; 
      });
    },[])

    const handleClick=()=>{
      setMtn((parseFloat(props.mtn)>=0)?parseFloat(props.mtn):parseFloat(props.mtn)*(-1))
      setVrs((parseFloat(props.mtn)>=0)?parseFloat(props.mtn):parseFloat(props.mtn)*(-1))
      if(timeB>150){
        handleClickOpen()
      }else{
        const mtnn=Math.round((parseFloat(props.mtn)>=0)?parseFloat(props.mtn):parseFloat(props.mtn)*(-1))
        setVrs(mtnn)
        const dat={
          tiketId:props.ticketId,
          clientId:props.clientId,
          mtn:Math.round(mtnn),
          note:Gfunc.stringToNull(props.note),
          retour:props.retour}
        handleSubmit(dat)
      }
    }

    const handleClickOpen = () => { 
      if(!props.action){(props.clientId!=null)?setClient(props.clientId):setClient(null);
      (props.clientId)?setClientN(props.clientN):setClientN('Anonyme');
      //(props.clientId)?setNote(props.note):setNote('');
      const mtn =Math.abs(props.mtn)
      const pow=Math.pow(10,mtn.toString().length-1)
      setMtn(mtn)
      setEnc(Math.ceil(mtn/pow)*pow)
      setVrs(mtn)
      setRnd(0)
      setCalled(false)
      setOpen(true); }
    };
    /*
    const reset=()=>{
      setClientN('');
      setClient('');
      setVrs(0);
      setNote('');
      setMtn('')
      setEnc('')
      setRnd('')
    }*/
    const handleEncaisse = (e) => { 
      const enc=parseFloat(e.target.value)
      setEnc(enc);
      if(enc>vrs){
        setRnd(enc-vrs)
      }else {
        setRnd(0)
      }
    };


    const handleClose = () => { setOpen(false); };
    
      // Encaisser un ticket
    const handleSubmit = async(dat) =>{
      if(!props.action && vrs!==0 &&!props.checkUpd){
        props.setAction(true)
        const data={
          tiketId:props.ticketId,
          clientId:client,
          mtn:vrs,
          note:Gfunc.stringToNull(props.note),
          retour:props.retour}
        const respon=await axiosPostAction(service,"paytik",(dat!==undefined)?dat:data)
        if(respon.data.err && respon.data.err!=='-1'){
          handleAlert(true,'error',respon.data.err);
          props.handleAlert(true,'error',respon.data.err);
        }else
        axiosResponse(respon,()=>{
          if(respon.data.jData===true){
            setOpen(false)
            props.handleAlert(true,'success',"Le ticket est encaissé.");
            //reset();
            props.handleEncaisse(true/*,client,clientN*/,(dat!==undefined)?props.mtn:(props.mtn>0)?vrs:-vrs)
          }
        })
        props.setAction(false)}
    }

  return (
    <>
      <RaccBtn
          id='encaisseBtn'
          tip={'Encaisser directement un ticket'}
          className={classes.racBtnRed}
          text={(props.disabled)?'Sup. Enc':'Enc. D.'}
          path={(props.disabled)?mdiCashRemove:mdiCashPlus}
          onClick={()=>{(props.disabled)?props.handleDeleteEncaisse():handleClick()}}
          iconColor={"white"}
          racc={'F2'}
          disabled={props.checkUpd||(!props.caisseId&&props.mtn===0)}
      /> 
      {(!props.disabled)?
      <RaccBtn
          id='encaisseBtn2'
          tip={'Encaisser un ticket'}
          className={classes.racBtnRed}
          text={(props.disabled)?'Sup. Enc':'Enc.'}
          path={(props.disabled)?mdiCashRemove:mdiCashPlus}
          onClick={()=>{handleClickOpen()}}
          iconColor={"white"}
          racc={'ctrl+F2'}
          disabled={props.checkUpd||props.caisseId||props.clientId==null}
      />:null }
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}>
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addTitle+' de '+props.clientN}</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
            <DialogContent >
            <ValidatorForm id="payT"  ref={useRef(null)} onSubmit={()=>handleSubmit()} onError={errors => console.log(errors)} > 
                    <div className='grid2'>
                        <FormControl className='smallText'>
                            <TextField disabled={props.clientId==null} size="small" label={labelText4} onChange={(e)=>setVrs(parseFloat(e.target.value))} id="iVrs" name="iVrs"  value={vrs} type='number'
                                margin="dense" variant='outlined' InputProps={{ inputProps: { min: 0 } }} required
                                />
                        </FormControl>
                        <div id="fieldSet" style={{display:'flex',gridGap:"10px",alignItems:'center',fontSize:'12px',padding:'4px 8px'}} >
                              <Icon path={mdiCashMultiple} size={0.8} />
                              <b>Montant :</b>
                              <p> {Intl.NumberFormat().format(mtn)}</p>
                        </div>
                    </div>
                    <div className='grid2'>
                        <FormControl className='smallText'>
                            <TextField size="small" label={labelText6} id="iEnc" name="iEnc"  value={enc} type='number'
                                onChange={e =>{handleEncaisse(e)}} margin="dense" variant='outlined' InputProps={{ inputProps: { min: 0 } }}
                                />
                        </FormControl>
                        <div id="fieldSet" style={{display:'flex',gridGap:"10px",alignItems:'center',fontSize:'12px',padding:'4px 8px'}}>
                            <Pieces style={{height:"20px",width:'20px'}} />
                            <b>Monnaie rendue:</b>
                            <p> {Intl.NumberFormat().format(rnd)}</p>
                        </div>
                    </div>
             </ValidatorForm>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" type='submit' color="primary" form="payT" startIcon={<DoneAll />}>VALIDER</Button>
                <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </>
  );
}
