import React,{useState,useMemo,useCallback} from 'react';
import Multiselect from 'multiselect-react-dropdown';
import DataTable from "react-data-table-component";
import {Dialog,DialogContent,Paper,Button,IconButton,Typography, Tooltip} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Add ,SupervisorAccount,IndeterminateCheckBox,Close} from '@material-ui/icons';
import { useStyles,multiSelect, paperPropsBigH } from '../../../css/muiStyle';
import {customStyles}from '../../../css/datatable-custom'
import { AlertCompo } from '../../../js/components';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import Icon from '@mdi/react';
import { mdiFormatListGroup } from '@mdi/js';
import '../../../css/admin.css'

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//ajouter les  roles aux users
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Adm_usr_rol(props) {
    const classes=useStyles();
    const param=props.param
    const Gfunc=require('../../../Gfunc')
    const [data,setData]=useState([]);
    const [option,setOption]=useState([]);
    const [open, setOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    var role=[];
    const service='_usr.php'


   //handle alerts properties
    const handleAlert=(open,severity,message)=>{
        setOpenA(open)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
    }

          //get les roles d'un utilisateur
    const fetch=useCallback(async() => {
      const response=await Gfunc.axiosPostAction(service ,"geturols",{idu:props.row.DT_RowId.substr(1),unsets:false})
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.jData)  
      })
    },[Gfunc,props.row.DT_RowId])

 //get les roles non associe a l'utilisateur 
    const fetchRoles= useCallback( async() => {
    const response=await Gfunc.axiosPostAction(service ,"geturols",{idu:props.row.DT_RowId.substr(1),unsets:true})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        setOption(response.data.jData)  
      })       
  },[Gfunc,props.row.DT_RowId])


  //suppression d'un role
    const handleDeletRole=useCallback(async (row) => {
            const response= await Gfunc.axiosPostAction(service ,"delurole",{idu:props.row.DT_RowId.substr(1), idr:row.id})
            if(response.data.err&&response.data.err!=='-1'){
              handleAlert(true,'error',response.data.err)
            }else
            Gfunc.axiosResponse(response,()=>{
              if(response.data.jData){
                const objIndex = data.findIndex((obj => obj.id ===row.id )); 
                data.splice(objIndex,1)
                handleAlert(true,"success",'Le rôle a été supprimé avec succès.')
              }
            })
    },[Gfunc,props,data])


    //ajouter des role a un utilisateur
   const handleAddRole=useCallback(async() => {
    var idrss=[]
    selectedValues.map((opt)=>{
        idrss.push(opt.id)
        return null
      }
      )
     const response=await Gfunc.axiosPostAction(service ,"seturoles",{ idu:props.row.DT_RowId.substr(1), idrs:idrss})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
          handleAlert(true,'success',"Les rôles sont attribués à l'utilisateur sélectionné.")
          selectedValues.map((item)=>{
            Gfunc.removeItemsByAtt(option,item.id,"id")
            data.push(item)
            return true;
          })
        }) 
        setSelectedValues([])

  },[selectedValues,data,option,Gfunc,service,props])


    const columns =useMemo(
      () => [  
        {
          name: "Role",
          selector:row=>row.name,
          sortable: true,
          minWidth:"350px"
        },
        {
          sortable: true,
          right:true,
          cell: (row) => {         
            return (
              <div title="Supprimer  le role" onClick={()=>handleDeletRole(row)} >
                <IndeterminateCheckBox className={classes.redIcon} fontSize="small"/>
              </div>        
             );
           },
      },
    ],[handleDeletRole,classes]);


      //ouvrir la fenetre de dialog
    const handleClickOpen = () => { 
      if(data.length===0)
      fetch();
      if(option.length===0)
      fetchRoles();
      setOpen(true); 
    };

    //fermer la fenetre de dialog
    const handleClose = () => { setOpen(false); };
    //selection des options 
    function onSelect(selectedList, selectedItem) {
    role=selectedList;
    setSelectedValues(role)
  }
   //suppression des options
    function onRemove(selectedList, removedItem) {
     setSelectedValues(selectedList)
  }
  

  return (
    <div>
      <Tooltip title="Ajouter / Supprimer les roles">
         <SupervisorAccount className={classes.blackIcon} onClick={handleClickOpen}/>
      </Tooltip>
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={paperPropsBigH}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
          <div className='DialogTitle'>
            <Icon path={mdiFormatListGroup} size={1} />
            <label> Les roles de <b>{props.row.user}</b></label>
          </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
        <DialogContent >
        <fieldset id="fieldSet" >
          {(option.length!==0)?
            <div className='flex-row'>
              <Multiselect
                style={multiSelect}
                options={option} 
                onSelect={onSelect} 
                onRemove={onRemove} 
                displayValue="name" 
                emptyRecordMsg="Il n'y a plus d'options"
                selectedValues={selectedValues}
                placeholder="Selectioner les roles"
              />
            <div>
            <Button className={classes.PrintBtn} variant="contained" onClick={handleAddRole} color="primary" startIcon={<Add/>}>AJOUTER</Button></div>
            </div>
          :<></>
          }
        </fieldset>
            <DataTable 
                  key={Math.floor(Math.random() * (1000 - 1 + 1)) + 1}
                  className="table"
                  columns={columns}
                  data={data}
                  noHeader={true}
                  defaultSortAsc={true}
                  customStyles={customStyles}
                  noDataComponent={param.ErreurData}
                  highlightOnHover
                  pagination
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
               />
        </DialogContent>
      </Dialog>
      </div>
  );
}
