import React ,{useRef,useCallback,useState} from "react";
import {Button,Paper,Typography,CircularProgress,Backdrop } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import {DoneAll} from '@material-ui/icons';
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { useStyles } from '../../css/muiStyle';
import  '../../css/reporting.css'
import {NoDataComponent,AlertCompo} from '../../js/components'
import { useMediaQuery } from 'react-responsive'
import PieCharts from '../../compo/Piechart'
import { Autocomplete } from '@material-ui/lab';


export default function Rep_vnt (props){
    const Gfunc=require('../../Gfunc')
    const param=props.param;
    const classes = useStyles();
    const [loading,SetLoading]=useState(false)
    const [comm,SetComm]=useState([])
    const [cat,SetCat]=useState([])
    const [nature,SetNature]=useState([])
    const [byNatureC,SetbyNatureC]=useState([])
    const [byNatureB,SetbyNatureB]=useState([])
    const [byCommercialC,SetbyCommercialC]=useState([])
    const [byCommercialB,SetbyCommercialB]=useState([])
    const [byCommuneC,SetbyCommuneC]=useState([])
    const [byCommuneB,SetbyCommuneB]=useState([])
    const [byCategorieC,SetbyCategorieC]=useState([])
    const [byCategorieB,SetbyCategorieB]=useState([])
    const [byAppC,SetbyAppC]=useState([])
    const [byAppB,SetbyAppB]=useState([])
    const [NatureTaille,SetNatureTaille]=useState(0)
    const [commercielTaille,SetCommercielTaille]=useState(0)
    const [communeTaille,SetCommuneTaille]=useState(0)
    const [categorieTaille,SetCategorieTaille]=useState(0)
    const [appTaille,SetAppTaille]=useState(0)
    const [selectComm,SetSelectComm]=useState('')
    const [selectnature,SetSelectNature]=useState('')
    const [selectcat,SetSelectCat]=useState('')
    const [selectDateD,SetSelectDateD]=useState(Gfunc.DateDeb(new Date()))
    const [selectDateF,SetSelectDateF]=useState(new Date())
    var [cln,setcln]=useState(0)
    var [clct,setclct]=useState(0)
    var [clcm,setclcm]=useState(0)
    var [clcn,setclcn]=useState(0)
    var [cla,setcla]=useState(0)
    var [prcnc,setprcnc]=useState(0)
    var [prcnb,setprcnb]=useState(0)
    var [prccc,setprccc]=useState(0)
    var [prccb,setprccb]=useState(0)
    var [prccac,setprccac]=useState(0)
    var [prccab,setprccab]=useState(0)
    var [prcapc,setprcapc]=useState(0)
    var [prcapb,setprcapb]=useState(0)
    var [prccmc,setprccmc]=useState(0)
    var [prccmb,setprccmb]=useState(0)
    var [colorn,setcolorn]=useState([])
    var [colorcm,setcolorcm]=useState([])
    var [colorcn,setcolorcn]=useState([])
    var [colorct,setcolorct]=useState([])
    var [colorap,setcolorap]=useState([])
    const [err,SetErr]=useState(false)
    const [severity, setSeverity] =useState("info");
    const [open, setOpen] =useState(false);
    const [messageA, setMessageA] =useState('');
    const message='Aucune donnée trouvée'
    const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 850px)'})

   //const COLORS = ["#0088FE", "#00C49F", "#FFBB28","#ffc400","#B9121B","#ffea00","#B0CC99","#ff9100","#FF8042","#f50057","#f73378","#ab003c","#ab003c","#00a0b2","#1769a","#4dabf5","#6573c3"];
   
   //handle alerts properties
  const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }
//recuperer les utilisateurs
const fetchUsers= useCallback(async() => {
    const response = await Gfunc.axiosGetAction('_usr.php',"gets")
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({id:'empty',name:'Tous'})
        SetComm(response.data.jData);   
      })
    }
},[Gfunc,param.urlService]);

//recuperer les communes
const fetchNature= useCallback(async() => {
    const dat=[]
    const response = await Gfunc.axiosGetAction('_cli.php',"getnats")
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        Object.keys(response.data.jData).map((key, index)=> {
          dat.push({id:key,nom:response.data.jData[key]})
          return true
        })
      dat.unshift({id:'empty',nom:'Toutes'})
      SetNature(dat);   
      })
    }
},[Gfunc,param.urlService]);

//recuper les categories 
const fetchCat= useCallback(async() => {
    const response = await Gfunc.axiosGetAction('_cat.php',"gets")
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({id:'empty',nom:'Toutes'})
          SetCat(response.data.jData);   
      })
    }
  },[Gfunc,param.urlService])

//valider le formulaire
 const handleSubmit= async() =>{
   var categorie='';var commercial='';var natur=''
   SetErr(false)
   SetbyCategorieB([]);SetbyCategorieC([]);SetbyNatureB([]);SetbyNatureC([]);SetbyAppB([]);SetbyAppC([]);SetbyCategorieB([]);SetbyCategorieC([])
   SetbyCommuneB([]);SetbyCommuneC([])
   SetNatureTaille('');SetCommercielTaille('');SetCommuneTaille('');SetCategorieTaille('');SetAppTaille('') 
   setcla(0);setclcm(0);setclcn(0);setclct(0);setcln(0) 
   SetLoading(true)
    var nat=[];var nat1=[];var nat2=[];var nat3=[];var nat4
    var naturec=[]; var natureb=[]; var categoriec=[]; var categorieb=[]; var commercialc=[]; var commercialb=[];
    var  communeb=[]; var communec=[];var appb=[]; var appc=[]
    var somnca=0;var somnben=0;var somcaca=0;var somcaben=0;var somcca=0;var somcben=0;var somcmca=0;var somcmben=0;var somapca=0;var somapben=0
    if(selectComm!==''){
      commercial=comm[Gfunc.getIndex(comm,selectComm,"name")].id
    }
    if(selectcat!==''){
      categorie=cat[Gfunc.getIndex(cat,selectcat,"nom")].id
    }
    if(selectnature!==''){
      natur=nature[Gfunc.getIndex(nature,selectnature,"nom")].id
    }
    const response=await Gfunc.axiosPostAction("_rep.php","getvnt",{  date1:Gfunc.formatDate(new Date(selectDateD)), date2:Gfunc.formatDate(new Date(selectDateF)),
                                                commercial:(commercial!=='empty')?commercial:'',commune:(natur!=='empty')?parseInt(natur):'',categorie:(categorie!=='empty')?categorie:''})
    SetLoading(false)
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,async()=>{
      if(response.data.jData!==undefined){
       if(response.data.jData.byNature.length===0&&response.data.jData.byCommercial.length===0&&response.data.jData.byCategorie.length===0&&response.data.jData.byCommune.length===0&&response.data.jData.byApp.length===0)
        { SetErr(true)}       

        nat=response.data.jData.byNature
        nat.map((elem)=>{
          naturec.push({name:elem.nature,value:elem.ca})
          natureb.push({name:elem.nature,value:elem.mben})
          somnca=somnca+elem.ca
          somnben=somnben+elem.mben
          if(elem.hasOwnProperty('mben')){
            cln++
           }
           setcln(cln)
           return null
        })
        setprcnc(somnca)
        setprcnb(somnben)
        SetNatureTaille(response.data.jData.byNature.length)
        SetbyNatureC(naturec)
        SetbyNatureB(natureb)
       /* var colorn=(response.data.jData.byNature.length!==1)? await Gfunc.generateColors(response.data.jData.byNature.length,
        ["#2196f3", '#ffee33']):["#2196f3"]*/
        var colorn=Gfunc.couleurs(response.data.jData.byNature.length)
        setcolorn(colorn)
        
        nat1=response.data.jData.byCategorie
        nat1.map((elem)=>{
          categoriec.push({name:elem.categorie,value:elem.ca})
          categorieb.push({name:elem.categorie,value:elem.mben})
          somcaca=somcaca+elem.ca
          somcaben=somcaben+elem.mben
          if(elem.hasOwnProperty('mben')){
            clct++
           }
           setclct(clct)
           return null
        })
        setprccac(somcaca)
        setprccab(somcaben)
        SetCategorieTaille(response.data.jData.byCategorie.length)  
        SetbyCategorieC(categoriec)
        SetbyCategorieB(categorieb)
        /*var colorct=(response.data.jData.byCategorie.length!==1)?
        await Gfunc.generateColors(response.data.jData.byCategorie.length,["#1769aa", '#00DFFC',"#F6FF00","#B9121B"]):["#1769aa"]*/
        var colorct=Gfunc.couleurs(response.data.jData.byCategorie.length)
        setcolorct(colorct)

        nat2=response. data.jData.byCommune
        nat2.map((elem)=>{
          communec.push({name:elem.commune,value:elem.ca})
          communeb.push({name:elem.commune,value:elem.mben})
          somcmca=somcmca+elem.ca
          somcmben=somcmben+elem.mben
          if(elem.hasOwnProperty('mben')){
            clcn++
           }
           setclcn(clcn)
           return null
        })
        setprccmb(somcmben)
        setprccmc(somcmca)
        SetCommuneTaille(response.data.jData.byCommune.length)  
        SetbyCommuneC(communec)
        SetbyCommuneB(communeb)
       /* var colorcn= (response.data.jData.byCommune.length!==1)?
                    Gfunc.couleurs(response.data.jData.byCommune.length)
                     // await Gfunc.generateColors(response.data.jData.byCommune.length,couleurs(response.data.jData.byCommune.length))
                     :["#6573c3"]*/
        var colorcn= Gfunc.couleurs(response.data.jData.byCommune.length)        
        setcolorcn(colorcn)

        nat3=response.data.jData.byCommercial
        nat3.map((elem)=>{
          commercialc.push({name:elem.commercial,value:elem.ca})
          commercialb.push({name:elem.commercial,value:elem.mben})
          somcca=somcca+elem.ca
          somcben=somcben+elem.mben
          if(elem.hasOwnProperty('mben')){
            clcm++
           }
           setclcm(clcm)
           return null
        })
        setprccb(somcben)
        setprccc(somcca)
        SetCommercielTaille(response.data.jData.byCommercial.length) 
        SetbyCommercialC(commercialc)
        SetbyCommercialB(commercialb)
        /*var colorcm= (response.data.jData.byCommercial.length!==1)?await Gfunc.generateColors(response.data.jData.byCommercial.length,
          ["#1769aa",'#ff9800']):["#1769aa"]*/
        var colorcm=Gfunc.couleurs(response.data.jData.byCommercial.length)  
        setcolorcm(colorcm)

        nat4=response.data.jData.byApp
        nat4.map((elem)=>{
          appc.push({name:elem.app,value:elem.ca})
          appb.push({name:elem.app,value:elem.mben})
          somapca=somapca+elem.ca
          somapben=somapben+elem.mben
          if(elem.hasOwnProperty('mben')){
            cla++
           }
           setcla(cla)
           return null
        })
        setprcapc(somapca)
        setprcapb(somapben)
        SetAppTaille(response.data.jData.byApp.length)  
        SetbyAppC(appc)
        SetbyAppB(appb)
       /* var colorap=(response.data.jData.byApp.length!==1)?await Gfunc.generateColors(response.data.jData.byApp.length,
        (["#343838", '#f44336'])):["#f44336"]*/
        var colorap=Gfunc.couleurs(response.data.jData.byApp.length) 
        setcolorap(colorap)
        return null
      }
    })   
  }
}


//valider formulaire avec touche d'entrer
const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="addBtn")) {
        if(selectComm===''||selectnature===''||selectcat===''){
          alert("Vous devez remplir tous les champs!")
        }
      }
    }

 //remplir Select commercial
 const handleOpenComm= useCallback(async () => {
   if(comm.length===0)
      fetchUsers()
    },[comm,fetchUsers])

//remplir Select nature
const handleOpenNature= useCallback(async () => {
    if(nature.length===0) fetchNature()
    },[nature,fetchNature])

//remplir Select Categorie 
const handleOpenCat= useCallback(async () => {
        if(cat.length===0) fetchCat()
},[cat,fetchCat])

        
return (
  <div className='content'>
    <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
    <fieldset className="fieldsetTop" id="vnt">
        <ValidatorForm noValidate id="formAdd" ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)}  >
            <div className="rowGrid" id="row-1">
              <div>
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                   <KeyboardDatePicker disableToolbar value={selectDateD} variant="inline" onChange={date => SetSelectDateD(date)}
                               className='smallText' margin="dense" inputVariant="outlined"
                               minDate='10/10/2018' format="dd/MM/yyyy" 
                               invalidDateMessage="Le format de la date est incorrect" 
                               />
                               
              </MuiPickersUtilsProvider>
              </div>
              <div>
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectDateF} variant="inline" placeholder="10/10/2018" onChange={date => SetSelectDateF(date)}
                                minDate="01/01/2020" format="dd/MM/yyyy"  className='smallText' margin="dense" inputVariant="outlined"
                                invalidDateMessage="Le format de la date est incorrect" 
                               />
             </MuiPickersUtilsProvider>
            </div>
            <Autocomplete
                   disableClearable
                   onChange={(e,newValue) =>{SetSelectComm(newValue)}}
                   value={selectComm}
                   options={comm.map((option) => option.name)}
                   onFocus={handleOpenComm}
                   className='smallAuto' 
                   renderInput={(params) =>  
                        <TextField margin='dense' 
                              inputlabelprops={{className:(comm)?undefined:classes.dataLabel}}
                              // error={errorP}
                              size='small'{...params} variant="outlined"  label="Commercial..." 
                              SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                          />}
              />      
              <Autocomplete
                disableClearable
                onChange={(e,newValue) =>{SetSelectCat(newValue)}}
                value={selectcat}
                options={cat.map((option) => option.nom)}
                onFocus={handleOpenCat}
                className='smallAuto' 
                renderInput={(params) =>  
                    <TextField margin='dense' 
                          inputlabelprops={{className:(cat)?undefined:classes.dataLabel}}
                          size='small'{...params} variant="outlined"  label="Catégorie..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                      />}
              />   
            
            <Autocomplete
                disableClearable
                onChange={(e,newValue) =>{SetSelectNature(newValue)}}
                value={selectnature}
                options={nature.map((option) => option.nom)}
                onFocus={handleOpenNature}
                className='smallAuto' 
                renderInput={(params) =>  
                    <TextField margin='dense' 
                          inputlabelprops={{className:(nature)?undefined:classes.dataLabel}}
                          size='small'{...params} variant="outlined"  label="Nature..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                      />}
              />
            <Button className={classes.PrintBtn} margin='dense' variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" 
                    color="primary" startIcon={<DoneAll/>}>VALIDER</Button>
            </div >        
    </ValidatorForm>
 </fieldset>
 <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
 </Backdrop>
 <div id='pie'>
          { (NatureTaille>0)?
            <Paper className={classes.paperBody} id='charts1' elevation={5}>
             <div id='SubChart'>
             <PieCharts data={byNatureC} color={colorn} percent={prcnc} dimension={(isDesktopOrLaptop===false)?'50%':'100%'}/>
              { 
              (cln>0)?
              <PieCharts data={byNatureB} color={colorn} percent={prcnb} dimension={(isDesktopOrLaptop===false)?'50%':'100%'}/>:<></>
            }
           </div >
            <Typography className='titleChart' >Ventes par nature client</Typography>
          </Paper>:<></>
          }
          { (commercielTaille>0)?
          <Paper className={classes.paperBody} id='charts1' elevation={5}>
           <div id='SubChart'>
            <PieCharts data={byCommercialC} color={colorcm} percent={prccc} dimension={(isDesktopOrLaptop===false)?'50%':'100%'}/>
            { (clcm>0)?
              <PieCharts data={byCommercialB} color={colorcm} percent={prccb} dimension={(isDesktopOrLaptop===false)?'50%':'100%'}/>:<></>
            }
            </div >
            <Typography className='titleChart' >Ventes par commercial</Typography>
         </Paper>:<></>
        }
</div>
<div id='pie'>
  {
        (categorieTaille>0)?
          <Paper className={classes.paperBody} id='charts1' elevation={5}>
           <div id='SubChart'>
           <PieCharts data={byCategorieC} typ='cat' color={colorct} percent={prccac} dimension={(isDesktopOrLaptop===false)?'50%':'100%'}/>
           { 
            (clct>0)?
              <PieCharts data={byCategorieB} color={colorct} percent={prccab} dimension={(isDesktopOrLaptop===false)?'50%':'100%'}/>:<></>
          }
            </div >
            <Typography className='titleChart' >Ventes par catégorie</Typography>
         </Paper>:<></>
        }

        {
         (appTaille>0)?
          <Paper className={classes.paperBody} id='charts1' elevation={5}>
           <div id='SubChart'>
           <PieCharts data={byAppC}  color={colorap} percent={prcapc} dimension={(isDesktopOrLaptop===false)?'50%':'100%'}/>
            {(cla>0)?
           <PieCharts data={byAppB} color={colorap} percent={prcapb} dimension={(isDesktopOrLaptop===false)?'50%':'100%'}/>:<></>
           }
            </div >
            <Typography className='titleChart' >Ventes par application</Typography>
          </Paper>:<></>
        }
       
</div>
<div id='pie1'>
         {
          (communeTaille>0)?
          <Paper className={classes.paperBody} id='charts1-1' elevation={5}>
            <div id='SubChart1'>
            <PieCharts data={byCommuneC} color={colorcn} percent={prccmc} dimension={(isDesktopOrLaptop===false)?'50%':'100%'}/>
             { 
              (clcn>0)?
            <PieCharts data={byCommuneB} color={colorcn} percent={prccmb} dimension={(isDesktopOrLaptop===false)?'50%':'100%'}/>:<></>
             }
          </div >
              <Typography className='titleChart' >Ventes par commune</Typography>
         </Paper>:<></>
        } 
</div>
        {(err===true)?<div><NoDataComponent message={message}/></div>:<></>}
</div>  
  );
}