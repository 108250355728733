import React,{useState,useCallback,useEffect,useMemo} from "react";
import DataTable from "react-data-table-component";
import {Card,CardContent} from '@mui/material';
import {mdiQrcode,mdiCalendarRange} from '@mdi/js';
import '../../css/irh.css'
import { useStyles } from '../../css/muiStyle';
import {customStyles}from '../../css/datatable-custom'
import {NoDataComponent,NotAllowedComponent,BackdropCompo} from "../../js/components"
import Iconn from "@mdi/react"
import {Face} from '@material-ui/icons';
import LogoIraha from '../../img/logos/logo_iraha.png'
import Prod from '../../img/product.png'

export default function Irh_art (props){
 const classes=useStyles()
 const Gfunc=require('../../Gfunc')
 const librery=require('../../js/data_table_librery')
 const param=props.param;
 const [data, setData] = useState([]);
 const [loading, setLoading] = useState(false);
 const[noData,setNoData]=useState(false);
 const[notAllowed,setNotAllowed]=useState(false);
 const[focus,setFocus]=useState(false);
 const[errMessage,setErrMessage]=useState('');
 const service='_irh.php'


 const fetch= useCallback(async() => {
        setFocus(true)
        setLoading(true);
        const response = await Gfunc.axiosGetAction(service,"getphtik")  
        if(response.data.err&&response.data.err!=="-1"){
            setErrMessage(response.data.err)
            setNotAllowed(true)
          }else{
            Gfunc.axiosResponse(response,()=>{
                if(response.data.jData!==undefined){
                  setData(response.data.jData);
                  setNoData(true)
                }
            })
          } 
          setLoading(false)
  },[Gfunc,librery]);
  

  useEffect(() => {
    if(!focus){fetch(1); }
  }, [fetch,Gfunc]);

  const findIndex=(produit,marque)=>{
    const indexOfFirst = produit.toLowerCase().indexOf(marque.toLowerCase());
    return(
     <div id='full-prod'>
     {
              (indexOfFirst!==-1)?
              <div id='prd-img' title={marque}>
                <div id='prd'>
                 <img src={param.imgMrq+'mrq_'+marque.toLowerCase().replace(/\s/g, '')+'.png'} style={{height:'20px'}}
                    onError={(e) => {
                      e.target.onerror = null
                      e.target.src = Prod
                    }}/> 
                  </div>
                   {produit.substr(indexOfFirst+marque.length)}
              </div>
              :<div id='prd-img' title={'Autres'}><div id='prd'><img src={Prod} style={{height:'20px'}}/> </div>{produit}</div>
 
     }
     </div>
    );
   }

  const columns =useMemo(
    () => [ 
      {
        name: "Produit",
        selector: row=>row.produit,
        sortable: true,
        width:"450px !important",
        start:true,
        cell:(row)=>{
          return(
         <div  id='full-prod'>{findIndex(row.produit,row.marque)}</div> 
         );
        }
      },   
      {
        name: "QTE.",
        selector:row=>row.qte,
        sortable: true,
        width:"90px !important",
        cell:(row)=>{
          return(
          <div > {new Intl.NumberFormat().format(row.qte)}</div>
         );
        }
      }, 
      {
        name: "Prix",
        selector: row=>row.agent,
        sortable: true,
        width:"120px !important",
        cell:(row)=>{
          return(
          <div > {new Intl.NumberFormat().format(row.mtn/row.qte)}</div>
         )
          }
      },  
      {
        name: "Montant",
        selector: row=>row.mtn,
        sortable: true,
        minWidth:"100px !important",
        cell:(row)=>{
          return(
          <div > {new Intl.NumberFormat().format(row.mtn)}</div>
         );
        }
      },  
        
  ],[]);

  return (
    <div className="content">
       <BackdropCompo className={classes.backdrop} open={loading}/>
        <br/>
        <br/>
        <div id='acc-div'>
        { 
        (data.length!==0)?
        data.map((el,index)=>(
          <Card sx={{background:'#f5f5f5'}} key={index} id='card-acc' >
           <div id='dig-exx'> 
            <div id='num-acc' title="Numéro du ticket"><Iconn path={mdiQrcode} color="black" size="16px"/>{el.numero}</div>
            <div id='date-acc'title="Date du ticket"><Iconn path={mdiCalendarRange} color="black" size="16px"/>{el.date.replace(' ', ' à ' )}</div>         
            </div>
            <div >
            </div> 
            <CardContent>
              <div id='div-entete'>
                <div id='agent-face'>
                  <div id='agent-face-div'>
                    <Face className={classes.blackIconAcc}/><br/>
                    {el.agent}
                  </div>
                </div>
                <div id='mtn-face'> 
                 <div id='mtn-face-1'>
                   <p>Ancien:</p>
                   <p>Versement:</p>
                   <p>Restant:</p>
                 </div>
                 <div id='mtn-face-2'>
                   <p><b id='b'>{new Intl.NumberFormat().format(el.preSolde)} </b></p>
                   <p><b  id='b'>{new Intl.NumberFormat().format(el.encaissement)}</b></p>
                   <p><b  id='b'>{new Intl.NumberFormat().format(el.preSolde-el.encaissement)}</b></p>
                 </div> 
                </div>
                
              </div>
             <br/>
             <hr/><br/>
              {
                (el.hasOwnProperty('ventes'))?
                <div id='full-ventes'>
                  <DataTable 
                    className="table"
                    persistTableHead
                    columns={columns}
                    data={el.ventes}
                    noHeader={true}
                    customStyles={customStyles}
                    defaultSortAsc={true}
                    noDataComponent={param.ErreurData}    
                    highlightOnHover
                    paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
                  />
                   <div id='tick-div'><p id="tick-tot">Total du ticket:</p><b  id='total'>{new Intl.NumberFormat().format(el.mtn)}</b></div>
               </div>
                :
                <div id='empty-ventes'>Ticket de versement.</div>
              }
            <br/><br/>
            <hr/>
            <div id='foot'>
              <div > 
                <img src={LogoIraha} id='logo_ticket' alt='logo' style={{width:'85px',height:'auto'}}/>
              </div>
              <div>
                <p id="tick-mtn">Nouv. solde:<b  id='montant'>{new Intl.NumberFormat().format(el.postSolde)}</b> </p>
              </div>
            </div>
            </CardContent>
            
          </Card> 
      ))
        :(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null
        }
    </div>  
    </div>    

  );
}