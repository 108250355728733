import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import TextField from '@material-ui/core/TextField';
import {CircularProgress,FormControlLabel,Checkbox,Tab} from '@material-ui/core';
import {TabContext,TabList,TabPanel} from '@material-ui/lab';
import {CalendarToday, Category, Facebook, Label, LocationOn, Phone, Search, Store} from '@material-ui/icons';
import ContentEditable from 'react-contenteditable';
import {conditionalRowStyles,customStyles}from '../../css/datatable-custom'
import { useStyles } from '../../css/muiStyle';
import {AlertCompo,NoDataComponent,NotAllowedComponent} from "../../js/components"
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import { Box } from "@mui/system";
import Autocomplete from '@mui/material/Autocomplete';
import './../../css/admin.css'
import DialogLogo from '../content/modals/adm_prm_logo'
import {ReactComponent as Header} from '../../img/icons/header.svg'


export default function Adm_prm(props){
  const classes=useStyles()
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [records, setRecords] =useState('');
  const [severity, setSeverity] =useState("info");
  const [selectedDate, setSelectedDate] =useState('');
  const[noData,setNoData]=useState(false); 
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[doSearch,setDoSearch]=useState(false);
  const[controle,setControl]=useState(false);
  const[key,setKey]=useState(false);
  const [value, setValue] =useState('1');
  const service='_prm.php'

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }
 
  const handleChange =async (event, newValue) => {
    var dat=[];
    setValue(newValue);
    if(newValue==='2'){
      dat=await Gfunc.getMag();
      setInfo(dat)
    }
   };
  
  const fetch= useCallback(async(page,servic = service, size = perPage) => {
    setLoading(true);
    const response=await librery.axiosGet(page+'&search='+search,servic,size)
      if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else
      Gfunc.axiosResponse(response,()=>{
        setRecords(response.data.recordsFiltered)
        if(response.data.data!==undefined){
            setData(response.data.data);
            setTotalRows(response.data.recordsFiltered);
            setDoSearch(true)
            setNoData(true)
        }else {setDoSearch(false);setData([])}
      })
    setLoading(false)
  },[Gfunc,param.urlService,perPage])


  useEffect(() => {
    fetch(1);
  }, [fetch]);
  
  const handleVider=(val)=>{
    setControl(false)
  }

  const  HandleUpdate=useCallback(async(e,row,val)=>{
  if(row.typ!==4){
    if(e.target.value!==row.valeur){
      const vl=row.valeur
      var valeur=''
    if(row.typ===1){ valeur=e.target.value}
    if(row.typ===2){ valeur=(e.target.checked===true)?1:0}
    if(row.typ===3){ valeur=Gfunc.formatDate(val)}
    const response= await Gfunc.axiosPostAction(service,'upd',{param:row.param,value:valeur})
    if(response.data.err&&response.data.err!=="-1"){
        row.valeur=vl;
        handleAlert('true','error',response.data.err)
        if(row.typ===3){
          setSelectedDate('')
        }
        setControl(true)
        setKey(true)
        handleVider(false)
    }else{
     Gfunc.axiosResponse(response,()=>{
        if(response.data.jData===true){
          if(row.typ===1){
            row.valeur=e.target.value;
          }
          if(row.typ===2){
            row.valeur=(e.target.checked===true)?1:0;
            if(row.nom==='Afficher le prix sur les etiquettes'){
              localStorage.setItem('showPrice',e.target.checked)
            }
          }
           handleAlert('true','success',"Le paramètre est modifié avec succès")
        }else{
            row.valeur=vl;
            setControl(true)
            handleVider(false)
        }
                })
        }
    }
  }else{
    const response= await Gfunc.axiosPostAction(service,'upd',{param:row.param,value:val.toString()})
    const valll=row.valeur
    if(response.data.err&&response.data.err!=="-1"){
        row.valeur=valll
        handleAlert('true','error',response.data.err)
        setControl(true)
        setKey(true)
        handleVider(false)
    }else{
     Gfunc.axiosResponse(response,()=>{
        if(response.data.jData===true){
          if(row.param==='tiketWidth'){
              localStorage.setItem('tiketWidth',val)
          }
        }
                })
    }
  }
   
  },[Gfunc,handleVider,handleAlert,controle])

  const columns =useMemo(
    () => [ 
      {
        name: "Paramètre",
        selector: row=>row.nom,
        sortable: true,
        minWidth:'800px !important',
        sortField:"nom",
      },  
      {
        name: "Valeur",
        selector:row=>row.typ,
        sortable: true,
        minWidth:'250px !important',
        sortField:"typ",

        cell: (row) => {
            return (
                <div key={(key)?Math.floor(Math.random() * 100):''}>
             {
                 (row.typ===2)?
                 <div className="checkbox">
                        <FormControlLabel control={<Checkbox defaultChecked={(row.valeur==='1')?true:false} className={classes.checkBox}
                            onChange={e=>HandleUpdate(e,row,'')} inputProps={{ 'aria-label': 'controlled' }}/>}
                        /> 
                 </div>
                 :(row.typ===1)?
                 <div className="smallElem"> 
                    <TextField type="number" className='smallTextTable' margin='dense' variant='outlined' size="small"
                               defaultValue={row.valeur} onBlur={e=>HandleUpdate(e,row,'')} /> 
                 </div>
                 :(row.typ===3)?<div className="smallElem"> 
                  <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker disableToolbar value={selectedDate} defaultValue={row.valeur}  variant="inline"
                                     onChange={date => {setSelectedDate(date);HandleUpdate('',row,date)}}
                                     minDate='10-10-2018' format="dd-MM-yyyy" className='smallTextTable' margin="dense" inputVariant="outlined"
                                     invalidDateMessage="Le format de la date est incorrect" 
                               />
                   </MuiPickersUtilsProvider>
                  </div>:
                  <div className="smallSelectDTT">
                   <Autocomplete
                      disableClearable
                      onChange={(e,newValue) =>{HandleUpdate('',row,row.valeurs[Gfunc.getIndex(row.valeurs,newValue,"nom")].id)}}
                      options={row.valeurs.map((option) => option.nom)}
                      //defaultValue={console.log(row.valeurs[Gfunc.getIndex(row.valeurs,parseInt(row.valeur),"id")]!==-1)}
                      defaultValue={(Gfunc.getIndex(row.valeurs,parseInt(row.valeur),"id")!==-1)?
                      row.valeurs[Gfunc.getIndex(row.valeurs,parseInt(row.valeur),"id")].nom:''}
                      inputprops={{className:classes.diagFormInput}}
                      renderInput={(params) =>  
                              <TextField margin='dense' 
                                 // error={errorP}
                                 size='small'{...params} variant="outlined" 
                                 SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                             />}
             />
             </div>
             }
              </div>
             );
           },
      },  
  ],[HandleUpdate,selectedDate]);
 

  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,'get')
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      })  
  }

  const handleSearch=async( size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,'get')
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
    return recherche
  }

  function handlePageChange (page) {
    fetch(page,service,perPage,search);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,service, newPerPage,search);
    setPerPage(newPerPage);
  };
  
  //Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }

  function liveSearch2() {
    handleSearch();
  }
  
  const handleOnEdit=useCallback(async(e,att,val)=>{
    const mag=localStorage.getItem('mag')
    const mags=JSON.parse(localStorage.getItem('mags'))
    const objIndex = mags.findIndex((obj => obj.name ===mag ));
    const idd=mags[objIndex].id;
    const value=e.target.innerHTML
    const edt1=Gfunc.DeleteSpace(value)
    if(val!==edt1){
    const response= await Gfunc.axiosPostAction('_mag.php','upd',{att:att,id:idd,val:edt1})
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert('true','error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        localStorage.setItem(att,edt1)
    })
  }
  }  
  },[Gfunc])

  return (
    <div className="content" id='params'>
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
      <Box sx={{ width: '95%',margin:'auto' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label='Général' value="1" />
            <Tab label='Magasin' value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" >
        <div className='table-header'>
        <div className='button-flex-wrapper' >
         </div>
          {(doSearch)?
            <div className='search' >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined" label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:'pointer' }} />),}}
                               size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
              </Box>
            </div>:null}
        </div>
        {(data.length!==0||doSearch)?<div className="param">
        <DataTable className="table"
              persistTableHead
              columns={columns}
              data={data}
              noHeader={true}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              paginationPerPage={perPage}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
        /></div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
        </TabPanel>
        <TabPanel value="2">
        <br/>
          <div id='param-log'><DialogLogo handleAlert={handleAlert} logo={localStorage.getItem('logo')} link={param.logo}/></div> 
          <br/>
          <div id='param-mag'>
            <div id='param-noedit'>
                <div id='elem'>
                  <Store style={{height:"20px",width:'20px'}}/>
                  <b>Nom:</b>
                  <p> {(info.name)?info.name:'---'}</p>
                </div>
                <div id='elem'>
                  <LocationOn style={{height:"20px",width:'20px'}}/>
                  <b>Adresse:</b>
                  <p>{(info.adresse)?info.adresse:'---'}</p>
                </div>
                <div id='elem'>
                  <Phone style={{height:"20px",width:'20px'}}/>
                  <b>Tel:</b>
                  <p>{(info.tel)?info.tel.replace(/[Tel: Fax:]/g,''):'---'}</p>
                </div>
                <div id='elem'>
                  <Category style={{height:"20px",width:'20px'}}/>
                  <b>Type:</b>
                  <p>{(info.type)?info.type:'---'}</p>
                </div>
                <div id='elem'>
                  <CalendarToday style={{height:"20px",width:'20px'}}/>
                  <b>Date d'expiration de l'abonnement:</b>
                  <p>{(info.expireDate)?info.expireDate:'---'}</p>
                </div>
            </div>
            <div id='param-edit'>
              <div id='elem'>
                  <Label style={{height:"20px",width:'20px'}}/>
                  <b>Étiquette:</b>
                  <ContentEditable
                      html={(info.etiquette!==null)?info.etiquette:'---'}
                      name="value"
                      data-column="item"
                      className='content-editable-param'
                      disabled={false} 
                      onBlur={e => handleOnEdit(e,'etiquette',info.etiquette)}
                  />
              </div>
              <div id='elem'>
                  <Header style={{height:"20px",width:'20px'}}/>
                  <b>Entête ticket 1:</b>
                  <ContentEditable
                      html={(info.entet_tiket_1!==null)?info.entet_tiket_1:'---'}
                      name="value"
                      data-column="item"
                      className='content-editable-param'
                      disabled={false} 
                      onBlur={e => handleOnEdit(e,'entet_tiket_1',info.entet_tiket_1)}
                  />
              </div>
              <div id='elem'>
                  <Header style={{height:"20px",width:'20px'}}/>
                  <b>Entête ticket 2:</b>
                  <ContentEditable
                      html={(info.entet_tiket_2!==null)?info.entet_tiket_2:'---'}
                      name="value"
                      data-column="item"
                      className='content-editable-param'
                      disabled={false} 
                      onBlur={e => handleOnEdit(e,'entet_tiket_2',info.entet_tiket_2)}
                  />
              </div>
              <div id='elem'>
                  <Facebook style={{height:"20px",width:'20px'}}/>
                  <b>Facebook:</b>
                  <ContentEditable
                      html={(info.facebook!==null)?info.facebook:'---'}
                      name="value"
                      data-column="item"
                      className='content-editable-param'
                      disabled={false} 
                      onBlur={e => handleOnEdit(e,'facebook',info.facebook)}
                  />
              </div>
            </div>
          </div>
       </TabPanel>
      </TabContext>
    </Box>
       
        
    </div>    
  );
}
