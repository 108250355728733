import React, { useState, useEffect, useCallback, useMemo } from "react";
import DataTable from "react-data-table-component";
import TextField from '@material-ui/core/TextField';
import { CircularProgress, Checkbox, Button } from '@material-ui/core';
import { Today, Person, Delete } from '@material-ui/icons';
import { AlertCompo, BackdropCompo, NoTableDataComponent } from "../../js/components"
import { conditionalRowStyles, customStyles, numberColumn } from '../../css/datatable-custom.js'
import { useStyles } from '../../css/muiStyle';
import { Autocomplete } from "@material-ui/lab";
import RefZktAdd from './modals/ref_zkt_add.js';
import { Typography } from "material-ui";
import '../../css/referentiel.css'
import { mdiCashMultiple } from '@mdi/js';
import Icon from '@mdi/react';

export default function Ref_zkt(props) {

    const param = props.param;
    const Gfunc = require('../../Gfunc')
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [severity, setSeverity] = useState("info");
    const [open, setOpen] = useState(false);
    const [called1, setCalled1] = useState(false);
    const [called2, setCalled2] = useState(false);
    const [messageA, setMessageA] = useState('');
    const service = '_zkt.php'
    const classes = useStyles();
    const [noData, setNoData] = useState(false);
    const [catL, setCatL] = useState([]);
    const [yearL, setYearL] = useState([]);
    const [balance, setBalance] = useState(0);
    const [zakat, setZakat] = useState(0);
    const percent = 0.025;

    const [selectedHijriDate, setSelectedHijriDate] = useState();




    //handle alerts properties
    const handleAlert = (open, severity, message) => {
        setOpen(open)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function () {
            setOpen(false)
        }, 5000);
    }


    //fetch categorie
    const fetchCat = useCallback(async (serv = service, act = "getcats") => {
        var dat = []
        const response = await Gfunc.axiosGetAction(serv, act);
        if (response.data.err && response.data.err !== "-1") {
            handleAlert(true, 'error', response.data.err)
        } else {
            Gfunc.axiosResponse(response, () => {
                dat = response.data.jData
                setCatL(response.data.jData);
            })
        }
        return dat

    }, [Gfunc])

    //fetch categorie handler
    const handleOpenCat = useCallback(async () => {
        if (catL.length === 0) fetchCat()
    }, [catL, fetchCat])


    //fetch categorie
    const fetchYear = useCallback(async (serv = "_zkt.php", act = "gety") => {
        const response = await Gfunc.axiosGetAction(serv, act);
        if (response.data.err && response.data.err !== "-1") {
            handleAlert(true, 'error', response.data.err)
        } else {
            Gfunc.axiosResponse(response, () => {
                setYearL(response.data.jData);
            })
        }

    }, [Gfunc])

    //fetch categorie handler
    const handleOpenYear = useCallback(async () => {
        fetchYear()
    }, [fetchYear])

    const fetch = useCallback(async (year) => {
        setNoData(false)
        setLoading(true);
        const response = await Gfunc.axiosPostAction("_zkt.php", 'getzakat', { year: year })
        if (response.data.err && response.data.err !== '-1') {
            handleAlert(true, "error", response.data.err)
        } else
            Gfunc.axiosResponse(response, () => {
                if (response.data.jData) {
                    var dat = response.data.jData
                    dat.dets = dat.dets.filter(item => item.idc !== null && item.mtn !== null)
                    if (dat.dets.length === 0) setNoData(true)
                    setData(dat)
                    if (!dat.done) {
                        const bl = (dat.clients + dat.stocks + dat.caisse - dat.frns)
                        setBalance(bl)
                        setZakat(parseInt(bl * percent))
                    }
                    else {
                        const bl = (dat.clients + dat.stocks + dat.caisse - dat.frns)
                        setBalance(bl)
                        setZakat(parseInt(dat.mtn))
                    }
                    //setDetail(response.data.jData)
                }
                else setData([])
            })
        setLoading(false)
    }, [Gfunc, param.urlService, balance])


    useEffect(() => {
        if (!called2)
        handleOpenCat()
        setCalled2(true)
    }, [called2, handleOpenCat]);

    useEffect(() => {
        if (!called1)
        handleOpenYear()
        setCalled1(true)
    }, [called1, handleOpenYear]);

    useEffect(() => {
        if (selectedHijriDate)
        fetch(selectedHijriDate)
    }, [selectedHijriDate]);


    const columns = useMemo(
        () => [
            {
                name: "Categorie",
                selector: row => row.categorie,
                sortable: false,
                minWidth: "160px !important",
            },
            {
                name: "Montant",
                selector: row => row.mtn,
                sortable: false,
                style: numberColumn,
                minWidth: "150px !important",
                cell: (row) => {
                    return (
                        <div id={row.DT_RowId}> {new Intl.NumberFormat().format(row.mtn)} </div>
                    );
                },
            },
            {
                name: 'Achat',
                selector: row => row.isAchat,
                sortable: false,
                width: "80px !important",
                cell: (row) => {
                    return <Checkbox
                        checked={row.isAchat}
                        color="primary"
                        size="small"
                        style={{ padding: 0, cursor: "default" }}
                    />
                }
            },
            {
                name: "Vente",
                selector: row => row.isAchat,
                sortable: false,
                width: "80px !important",
                cell: (row) => {
                    return <Checkbox
                        checked={!row.isAchat}
                        color="primary"
                        size="small"
                        style={{ padding: 0, cursor: "default" }}
                    />
                }
            }

        ], []);


    const handleDateChange = (year) => {
        setSelectedHijriDate(year);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const dat = { cats: data.dets, zakatId: data.id, frns: data.frns, caisse: data.caisse, clients: data.clients, stocks: data.stocks, mtn: zakat, result: balance }
        const respon = await Gfunc.axiosPostAction(service, "set", dat)
        if (respon.data.err && respon.data.err !== '-1') {
            handleAlert(true, "error", respon.data.err)
        } else
            Gfunc.axiosResponse(respon, () => {
                setOpen(false);
                if (respon.data.jData) handleAlert(true, 'success', "Le montant de la Zakat a été prélevé de votre capital.");
                fetch(selectedHijriDate)
                // props.callback();
            })
    }

    const handleDeleteZakat = async (e) => {
        e.preventDefault();
        const dat = { zakatId: data.id }
        const respon = await Gfunc.axiosPostAction(service, "del", dat)
        if (respon.data.err && respon.data.err !== '-1') {
            handleAlert(true, "error", respon.data.err)
        } else
            Gfunc.axiosResponse(respon, () => {
                setOpen(false);
                if (respon.data.jData) handleAlert(true, 'success', "La Zakat a été supprimée.");
                fetch(selectedHijriDate)
                handleOpenYear()
            })
    }

    const callback = useCallback(
        (elem) => { setSelectedHijriDate(elem); if (elem === selectedHijriDate) fetch(elem); handleOpenYear() }, [selectedHijriDate, fetch]
    )
    return (
        <div className="content zakatContent" >
            <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
            <BackdropCompo className={classes.backdrop} open={loading} />
            <div className='table-header'>
                <div className='button-flex-wrapper'>
                    <RefZktAdd handleOpenCat={handleOpenCat} cats={catL} handleAlert={handleAlert} callback={(elem) => callback(elem)} />
                    <Button className={classes.topTabBtn} disabled={!selectedHijriDate || !data.id} variant="contained" color="secondary" onClick={handleDeleteZakat} startIcon={<Delete />}>Supprimer Zakat</Button>
                    <div style={{ width: "120px" }}>
                        <Autocomplete
                            fullWidth
                            disableClearable
                            className='smallAuto'
                            options={yearL}
                            value={selectedHijriDate ? selectedHijriDate.toString() : ""}
                            onChange={(e, newValue) => handleDateChange(newValue)}
                            getOptionLabel={(option) => option.toString()}
                            //getOptionSelected={(option, value) => option.id === value.id || value.id === ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"Année Hijri"}
                                    size="small"
                                    margin="dense"
                                    value={selectedHijriDate ? selectedHijriDate.toString() : ""}
                                    // value={selectedHijriDate.toString()}
                                    variant='outlined'
                                    autoFocus />
                            )} />
                    </div>
                </div>
                <div className='search' >

                </div>
            </div>
            {
                (selectedHijriDate) && <div className="zakatContainer">
                {(data.dets && data.dets.length !== 0) ? <div className="zakatCats">
                    <div id="DT">
                        <DataTable className="table"
                            persistTableHead
                            columns={columns}
                            data={data.dets}
                            noHeader={true}
                            defaultSortAsc={true}
                            noDataComponent={param.ErreurData}
                            // paginationRowsPerPageOptions={[10, 30, 50, 100]}
                            progressPending={loading}
                            progressComponent={<CircularProgress className={classes.circularProgress} />}
                            //paginationTotalRows={totalRows}
                            highlightOnHover
                            customStyles={customStyles}
                            // paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true, selectAllRowsItemText: 'Tous' }}
                            conditionalRowStyles={conditionalRowStyles}
                        />
                    </div>
                    {
                    /*(!loading) ? <Card style={{ margin: "10px", background: "#eee" }}>
                        <CardContent style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
                            <Typography style={{ fontWeight: "600", fontSize: "16px" }} >Total stock</Typography>
                            <Typography style={{ textAlign: "end", fontWeight: "500", fontSize: "16px" }}> {new Intl.NumberFormat().format(data.stocks)}</Typography>
                        </CardContent>
                    </Card> : null*/}
                </div>

                        : (noData) ? <div style={{ maxHeight: "600px", display: "grid" }}> <NoTableDataComponent message="Aucun calcul de Zakat n'a été effectué pour l'année sélectionnée." /> </div> : null}

                    <div className="detailsContainer">
                    {(!loading) ? <>
                            <div className="detailsGrid">
                                {(data.date) ?
                                    <div id="daezakat" style={{ display: "grid", gridTemplateColumns: "100px auto" }}>
                                        <div style={{ fontWeight: "600", fontSize: "16px", display: "flex", gap: "10px" }} > <Today fontSize="small" /> <p>Date</p></div>
                                        <div style={{ textAlign: "end", fontWeight: "500", fontSize: "16px" }}> {(data.date) && data.date.slice(0, 16)}</div>
                                    </div>
                                    : <div id="error1">
                                        <div style={{ fontWeight: "600", fontSize: "12px", color: "red" }} > <p>Veuillez ajouter un calcul de Zakat pour l'année sélectionnée afin de pouvoir calculer la Zakat.</p></div>
                                    </div>}
                                <div id="userin" style={{ display: "grid", gridTemplateColumns: "100px auto" }}>
                                    {(data.userIn) && <>
                                        <div style={{ fontWeight: "600", fontSize: "16px", display: "flex", gap: "10px" }} > <Person fontSize="small" /> <p>Agent</p></div>
                                        <div style={{ textAlign: "end", fontWeight: "500", fontSize: "16px" }}> {(data.userIn) && data.userIn}</div>
                                    </>}
                            </div>
                            <table className="zakatDetail">
                                <tbody>
                                    <tr>
                                        <td className="label">Stocks</td>
                                        <td className="value" style={{ color: "green" }}>{new Intl.NumberFormat().format(data.stocks)}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Caisse</td>
                                        <td className="value" style={{ color: "green" }}>{new Intl.NumberFormat().format(data.caisse)}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Clients</td>
                                        <td className="value" style={{ color: "green" }}>{new Intl.NumberFormat().format(data.clients)}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Fourniseurs</td>
                                        <td className="value" style={{ color: "red" }}>{new Intl.NumberFormat().format(data.frns)}</td>
                                    </tr>
                                    <tr>
                                        <td className="label" style={{ background: "black" }}>Balance</td>
                                        <td className="value" style={{ background: "#e0e0e0" }} >{new Intl.NumberFormat().format(balance)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                            {(data.date) && <div style={{ padding: "24px", display: "grid", margin: "auto", maxWidth: "450px" }}>
                            <form id="zakatForm" onSubmit={handleSubmit}>
                                <TextField disabled={data.done} className='smallText' variant='outlined' size="small" label='Zakat' value={zakat} margin='dense'
                                    fullWidth onChange={e => {
                                        setZakat(e.target.value)
                                    }} required autoFocus />
                            </form>
                                {(!data.done) && <Button form="zakatForm" className={classes.PrintBtn} variant="contained" color="primary" type='submit' startIcon={<Icon path={mdiCashMultiple} size={"20"} />}>Prélever</Button>}
                            </div>}
                    </> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}><CircularProgress /></div>}
                </div>
            </div>

            }
        </div>

    );
}