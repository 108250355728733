import React, { useState,useCallback,useEffect } from 'react'
import {Input,Button,TextField, Tooltip} from '@material-ui/core';
import {Build,Store,TrendingUp,MonetizationOn,PersonAdd,LockOpen, KeyboardBackspace} from "@material-ui/icons"
import $ from "jquery"
import {AlertCompo, AutocompleteS} from "../../../js/components"
import {NoDataComponent,NotAllowedComponent} from '../../../js/components'
import { useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import {ReactComponent as Inv} from '../../../img/icons/inventaire.svg'
import { useStyles } from '../../../css/muiStyle';
import '../../../css/global.css';
import '../../../css/datatable-custom'
import '../../../css/inventaire.css';


export default function Digitalisation(props) {
    const classes = useStyles();
    const Gfunc=require('../../../Gfunc')
    const [data,setData]=useState([]);
    const [noAjust,setNoAjust]=useState(false);
    const [select2,setSelect2]= useState([]);
    const [stock,setStock]= useState([]);
    const [valuesToAjust,setValuesToAjust]= useState([]);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const [notAllowed,setNotAllowed]=useState(false);
    const [errMessage,setErrMessage]=useState('');
    const message="Aucun ajustement à faire"
    const history = useHistory();
    const service='_inv.php'


    var row1=''
    if(props.location.row1){
      row1=props.location.row1;
      localStorage.setItem('row1',JSON.stringify(row1))    
    } else {
      row1=JSON.parse(localStorage.getItem('row1'))
    }

//recuperer les produits à ajuster
const fetch= useCallback(async(servic = service) => {
        const response = await Gfunc.axiosPostAction(service ,'getlocked',{idi:row1.DT_RowId.substr(1)})
        if(response.data.err&&response.data.err!=="-1"){
          handleAlert(true,'error',response.data.err)
          setErrMessage(response.data.err)
          setNotAllowed(true)
        }else
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData!==undefined){
          setNoAjust(true);
          if(response.data.jData.length!==0){
          setData(response.data.jData.detail)
          }else  setData(response.data.jData)
        }
        })
      },[Gfunc,row1.DT_RowId]);
      
//recuperer les  stocks
const fetchStock= useCallback(async() => {
  const response = await Gfunc.axiosGetAction('_stk.php' ,'gets')
  Gfunc.axiosResponse(response,()=>{
      setStock(response.data.jData)
  })
},[Gfunc]);

const routeChange = useCallback((newPath) =>{ 
  let path = newPath; 
  history.push(path);
},[history])

useEffect(() => {
 fetch();
 fetchStock()
}, [fetch,fetchStock]); 

//afficher les valeur d'ajustement 
const handleAJustChange = useCallback((e,v) => {
  valuesToAjust[v]=e.target.value;
  setValuesToAjust(valuesToAjust)
},[valuesToAjust])

 //handle alerts properties
 const handleAlert=(open,severity,message)=>{
  setOpenA(true)
  setSeverity(severity)
  setMessageA(message)
  setTimeout(function(){
    setOpenA(false)
  }, 5000);
}
//cloturer un inventaire
const  handleClose=useCallback(async()=>{
  const dat=[];var fix=[];var success = true;var select=''
  const objIndex = stock.findIndex((obj => obj.nom=== select2)); 
  if(objIndex!==-1){
    select=stock[objIndex].id
  }
  data.map((elem)=>{
      var currentQte = 0;
      elem.stock.map(item=>{
        currentQte += parseInt($("#s"+item.id).val());
        dat.push({iddp:item.id,qte:parseInt($("#s"+item.id).val()),ids:parseInt(select)})
        return true
      })
      if(currentQte!==elem.qte_ajust){
        fix.push(elem.idi)
      }
      success=success&&(currentQte===elem.qte_ajust)
      return true
  })
  if (success) {
    const response = await Gfunc.axiosPostAction(service,'close',{idi:row1.DT_RowId.substr(1),ajusts:dat})
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
    }else Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==null) {
          handleAlert(true,'success',"L'inventaire est clôturé")
          routeChange('/r/ri')
          //window.location=('/r/ri')
        }
     })
  } else {
    handleAlert(true,'Error',"Quantités ajustées invalides !")
    fix.map((item)=>{
      $("[id='i"+item+"']").addClass("comptageError")
      return true
    })
  }
  
},[Gfunc,data,row1.DT_RowId,select2,routeChange])

//retour en arrière
function backHome(){ routeChange('/r/ri') }  

//deverrouiller un inventaire
const Deverrouiller=useCallback(async()=>{
  const response = await Gfunc.axiosPostAction("_inv.php" ,'unlock',{idi:row1.DT_RowId.substr(1)})
  if(response.data.err&&response.data.err!=="-1"){
    handleAlert(true,'error',response.data.err)
  }else
  Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
        routeChange('/r/ri/dr')
        row1.locked=false;
        localStorage.setItem('locked',JSON.stringify(row1.locked)) 
        }
   })
 },[Gfunc,row1,routeChange])

 
    var rows = [];
    return (
        <div className='content'>
          <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
          <div id='inv-clo-ent'>
            <Tooltip title="Retour à l'inventaire du stock">
              <KeyboardBackspace style={{height:'20px',width:'24px',cursor:'pointer'}} onClick={() => { backHome() }}/>
            </Tooltip>
            <div id='inv-det-infos-1'>
              <div id='item'>
                <Inv style={{height:'20px',width:'20px',cursor:'pointer'}}/>
                <b>Nom:</b>
                <p>{row1.inventaire}</p>
              </div>
              <div id='item1' >
                <b>Choisir un stock</b>
                <Autocomplete
                    disableClearable
                    freeSolo
                    className='smallAuto'
                    onChange={(e,newValue) =>{setSelect2(newValue)}} 
                    options={stock.map((option) => option.nom)}
                    renderInput={(params) => (
                               <TextField {...params} label='Boutique' variant="outlined" margin="dense" size="small" autoFocus required/>
                             )} 
                />
              </div>
            </div>
            <div id='btn-clo-head'>
              <Button variant="contained"  color="secondery" type='submit' onClick={()=>{handleClose()}} className={classes.topTabBtn} 
                      startIcon={<Build/>} >Clôturer</Button>
              <Button variant="contained"  color="secondery" id="bt-clo" type='submit' onClick={Deverrouiller} className={classes.topTabBtn}  
                      startIcon={< LockOpen />}> Déverrouiller</Button>
            </div>
            </div>
            <div id="cloDiv">
            { (data.length!==0)?
              <table className="data"> 
                <tbody>
                {
                  data.forEach((e) => {
                    rows.push(<tr key={'s'+e.idi} className="prodRow"><td>ID: {('000000'+e.idp).substring(Math.min((""+e.idp).length,6))}</td><td>{e.produit}</td>
                    <td title="Comptage"><PersonAdd /> <span className="qteStock">{e.cpt}</span></td></tr>);
                    var stockSize = e.stock.length;
                    var restToAjust = e.qte_ajust;
                    var stockCount = 1;
                    var ajustValue=''
                    e.stock.forEach((s) => {
                      if (stockSize===stockCount) {
                         ajustValue = restToAjust;
                      } else {
                        if (restToAjust<=s.qte) {
                           ajustValue = restToAjust;
                           restToAjust = 0; 
                        } else {
                          ajustValue = s.qte;
                          restToAjust -= s.qte; 
                        }
                      }
                      stockCount++;
                      valuesToAjust[s.id] = ajustValue
                      rows.push(<tr  key={'s'+s.id}  id={"i"+e.idi} className="stockRow hoverstyle">
                                  <td><div className="stockRow" title="Ajuster le stock"><MonetizationOn /> <span className="qteStock">{s.pin}</span><TrendingUp /></div></td>
                                  <td><Input type='number' id={'s'+s.id} inputProps={{ min:parseInt(-restToAjust) }} className="dtInput" defaultValue={valuesToAjust[s.id]} 
                                             onChange={(e)=>handleAJustChange(e,s.id)} /></td>
                                  <td title="Quantité en stock"><Store /> <span className="qteStock">{s.qte}</span></td></tr>);
                    })
                })
                }
                {rows}
              </tbody>
              </table>
              :(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noAjust)?<div><NoDataComponent ajust={true} message={message}/></div>:null}

            </div>

        </div>
    )
}
