import React,{useEffect, useState} from 'react';
import {DialogContent,Paper,Button,Dialog,Fab,TextField,Tooltip,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import {useStyles,paperPropsthinTall } from '../../../css/muiStyle';
import {AlertCompo, BackdropCompo} from '../../../js/components';
import {ValidatorForm} from 'react-material-ui-form-validator';
import {Save,Close} from '@material-ui/icons';
import Iconn from "@mdi/react"
import {mdiDelete,mdiPen,mdiSd } from '@mdi/js';
import $ from "jquery";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';


const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//add new bon
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Vnt_cpt_addAir(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const [options, setOptions] =useState([]);
    const [open, setOpen] =useState(false);
    const [selected, setSelected] =useState(true);
    const [qte, setQte] =useState('');
    const [openA, setOpenA] =useState(false);
    const [opt, setOpt] =useState(false);
    const [message, setMessage] =useState('');
    const [severity, setSeverity] =useState('info');
    const [action, setAction] =useState(false);
    const numbers=[1,2,3,4,5,6,7,8,9]
    const labelText="Quantité"
    const addTitle=props.produit;
    const service="_vnt.php"

    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    //get suggestions
    const getSuggestions = async() => { 
          if(props.sugg.length===0){const response = await Gfunc.axiosGetAction(service,"getqteat")
          if(response.data.err){
            props.handleAlert(true,'error',response.data.err)
            setQte(100);
          }else
          Gfunc.axiosResponse(response,()=>{
            if(response.data.jData){
              setQte(response.data.jData[0])
              setOptions(response.data.jData)
              props.setSugg(response.data.jData)
            }
          })
        }else {setQte(props.sugg[0]); setOptions(props.sugg);setOpt(!opt)}
        $("#iQteFlx").focus();
        $("#iQteFlx").select();
      };

    const handleClickOpen = () => { 
      getSuggestions();
      setOpen(true); 
    };

    useEffect(() => {
      if(opt){handleSelect();setOpt(false);}
        $("#iQteFlx").focus();
        $("#iQteFlx").select();
      }, [opt]);
    

    const handleClose = () => { setOpen(false);setOptions([])};

     // ajouter un nouveau bon
     const handleSubmit= async() =>{
        if(!action){
            setAction(true)
            const dat={
                tiketId:props.ticketId,
                produitId:parseInt(props.prodId),
                qte:parseInt(qte),
                retour:props.retour,
                defStock:parseInt(localStorage.getItem('stockId'))
              }
              const response=await Gfunc.axiosPostAction(service,'addvshort',dat)
              if(response.data.err&&response.data.err!=='-1'){
                 handleAlert(true,'error',response.data.err)
              }else
              Gfunc.axiosResponse(response,()=>{
                if(response.data.jData!==undefined){
                  props.handleAddVnt(response,parseInt(localStorage.getItem('stockId')),localStorage.getItem('stock'),response.data.jData.mtn,(props.retour)?-qte:qte,response.data.jData.pdetId,props.handleAlert,()=>{},()=>{})  
                  props.handleAlert(true,'success',"La vente est ajoutée");
                  props.callBack()
                  setOpen(false)
                }     
              })
            setAction(false)
        }
      }
      
      const handleSelect=()=>{
        const elem =document.getElementById("iQteFlx"); if (elem){elem.focus();elem.select();}
        setSelected(true)
        setOpt(true)
       }  
 
  return (
    <>
    <div hidden id="addAir" onClick={handleClickOpen} ></div>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsthinTall}
      >
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
        <BackdropCompo className={classes.backdropDiag} open={action}/>
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
            <div className='DialogTitle'>
              <Iconn path={mdiSd} size={1} />
              <label>{addTitle}</label>
            </div>
        </DialogTitle>
        <DialogContent >
            <ValidatorForm noValidate id="addAirForm" onSubmit={handleSubmit} onKeyDown={e=>{ if(e.keyCode === 13){ e.preventDefault();handleSubmit()}}} onError={errors => console.log(errors)} > 
                <div className='suggestedPrices'>
                    {options.map((elem,index)=>{
                             return(<Button key={index} variant="contained" color="secondary" type='submit' className={classes.squarBtn} onClick={e=>{setQte(elem.toString());setOpt(!opt)}}>{new Intl.NumberFormat().format(elem)}</Button>)
                    })}
                </div>
                <div className='qte'>
                     <TextField  variant='outlined' size="small" label={labelText} id="iQteFlx" name="iQteFlx" value={qte} type='number'
                                      onChange={e =>{setQte(e.target.value)}} className='largeText' required autoFocus={open} />
                     <Tooltip title="Valider la vente"><Button variant="contained" type='submit' color="primary"><Save fontSize="large"/></Button></Tooltip>
                </div>
                <div className="numberKeyboard">
                   {numbers.map((elem,index)=>{
                       return(
                        <Fab key={index} tabIndex="-1" className={classes.sideBtnCalc} variant="extended" color="secondary" onClick={e=>{(selected)?setQte(elem):setQte(qte.toString()+elem);setSelected(false)}} > {elem} </Fab>
                       )
                   })}
                </div>
                <div className="zeroKeyboard">
                        <Tooltip title="Vider la quantité">
                            <Fab className={classes.sideBtnCalcSide} tabIndex="-1" variant="extended" color="secondary" onClick={e=>{setQte("")}} > <Iconn path={mdiDelete} color="black" size="20px"/> </Fab>
                        </Tooltip>
                        <Fab className={classes.sideBtnCalc} tabIndex="-1" variant="extended" color="secondary" onClick={e=>{(selected)?setQte("0"):setQte(qte.toString()+"0");setSelected(false)}} > {0} </Fab>
                        <Tooltip title="Sélectionner la quantité">
                            <Fab className={classes.sideBtnCalcSide} tabIndex="-1" variant="extended" color="secondary" onClick={handleSelect} > {<Iconn path={mdiPen} color="black" size="20px"/>} </Fab>
                        </Tooltip>
                </div>
                </ValidatorForm>
            </DialogContent>
      </Dialog>
      </>
  );
}
