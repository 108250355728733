import axios from 'axios'
import gradstop from 'gradstop';
import $ from 'jquery'
import HijriDate from 'hijri-date';


export const generateColors=(nbr,arr)=>{
  const gradient =  gradstop({
    stops: nbr,
    inputFormat: 'hex',
    colorArray: arr
  });
return gradient;
}

const generateLigthColors=()=>{
  let col = "#";
  for (let i = 0; i < 3; i++){
    col += ("0" + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
  }
  return col
}

export const couleurs=(val)=>{
  var tab=[];
  for(let pas = 0; pas <val; pas++){
    var color=generateLigthColors()
    tab.push(color)
  }
  return tab
}

//Global functions that can be used in diffrent projects
    //treat the response from api axios
     export function axiosResponse(response,f=()=>{}){
            if (response.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              //console.log(response.response.data);
              //console.log(response.response.status);
              //console.log(response.response.headers);
              /*if(response.response.status>400){
                localStorage.clear();
              }*/
              //window.location=("/"); 
            } else if (response.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
             // console.log(response);
             // console.log(response.request);
             // console.log(response.message);
              //changer le lien 
              if(response.request.status<100)
             { 
             //if(localStorage.getItem("netError")!==response.message||!window.location.pathname.split("/")[1].includes("error")){window.location=("/error?message="+response.message); }
               alert("Une erreur s'est produite");
             }
            // else {if(localStorage.getItem("netError")!=="undefined") window.location=("/") }
             // localStorage.setItem("netError",response.message);
            } else {
              // Something happened in setting up the request that triggered an Error
              //console.log('Error', response.message);

            }
            if(response.data) {if(response.data.err==null){
                f();
            }else{  if(localStorage.length!==0)
                      { if(response.data.err==="-1"){
                            alert("Votre session est expiré");
                            localStorage.clear();
                            window.location=("/"); 
                        }else{
                            alert(response.data.err);
                        }}
             }}
       }
      
    //get headers 
    export function getHeader(){
      
        const param=JSON.parse(localStorage.getItem("param"))
        const headers={
          'content-type': 'application/json',
          'session':localStorage.getItem('token'),
          'appID':param.appID,
          "mgID":(localStorage.getItem('magid')!=null&&localStorage.getItem('magid')!=='')?JSON.parse(localStorage.getItem('magid')):""
          }
          return headers;
   }
    
    // manage the ajax's errors in datatables 
    export function errorMan(data){
                if (data.err!=null && data.err!==undefined){
                    if(data.err==='-1') {
                        alert('votre session est expiré ');
                        localStorage.setItem('isLoged', false);
                        window.location=("/");  
                    } else alert(data.err);
                }
    }
    //test if an object is empty 
    export function isEmpty(obj) {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return false;
      }
      return true;
    }
    //get response from add service
    export function axiosAdd(service, data){
        const param=JSON.parse(localStorage.getItem("param"));
        var response= axios({
            method: 'POST',
            url:param.urlService+service+'?do=add',
            headers: getHeader(),
            data:JSON.stringify(data)     
        }).catch(function(error){
          error["data"]=""
          return error
       })
        return response
    }

 //get service
 export async function axiosGetAction(service ,action){
    const param=JSON.parse(localStorage.getItem("param"));
    var doo=action 
    if(doo!=='')
    doo='?do='+doo
    var response=await axios({
        method: 'GET',
        url:param.urlService+service+doo,
        headers: getHeader(),    
    }).catch(function(error){
      error["data"]=""
      return error
   })
    return response
  }
 //POST axios
 export const axiosPostAction=async(service ,action,data)=>{
    const param=JSON.parse(localStorage.getItem("param"));
    var doo=action 
    if(doo!=='')
    doo='?do='+doo
    var response= await axios({
        method: 'POST',
        url:param.urlService+service+doo,
        headers: getHeader(), 
        data:JSON.stringify(data)    
    }).catch(function(error){
      error["data"]=""
       return error
    })
    return response
  }

  export async function axiosPostAction1(service,action,data){
    const param=JSON.parse(localStorage.getItem("param"));
    var response= await axios({
        method: 'POST',
        url:param.urlService+service+'?do='+action,
        headers: getHeader(), 
        data:data   
    }).catch(function(error){
      error["data"]=""
      return error
   })
    return response
  }
 //POST axios
 export async function axiosFlagman(srv,id,att,val){
    const param=JSON.parse(localStorage.getItem("param"));
    var response=await axios({
        method: 'POST',
        url:param.urlService+'flagman.php',
        headers: getHeader(),
        data:JSON.stringify({ srv:srv, id:id,att:att, val: val })    
    }).catch(function(error){
      error["data"]=""
      return error
   })
    return response
  }
    
// group by key function
export const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  // join two arrays of objects in a prop
  export const merge=(a, b, prop)=> {
    let merged = [];
      for(let i=0; i<a.length; i++) {
        merged.push({
        ...a[i], 
        ...(b.find((itmInner) => itmInner[prop] === a[i][prop]))}
        );
      }
    return merged
  }

  export const getDate=()=>{ 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 
    var yyyy = today.getFullYear();
    today = dd + '-' + mm + '-' + yyyy;
    return today
  }

  export function dernierJourDuMois(year,month) {
    var last=formatDate(new Date(year, month , 0))
    return last
  }  
 
  //delete an item from an array of object by id
  export function removeItemsByAtt(arr,value,att) {
    var i = arr.length;
    while(i--){
       if( arr[i] 
           && arr[i].hasOwnProperty(att) 
           && (arr[i][att] === value ) ){ 

           arr.splice(i,1);
       }
    }
    return arr
  }

  //delete all items from an array except this id
  export function removeItemsExcept(arr,value,att) {
    var i = arr.length;
    while(i--){
       if( arr[i] 
           && arr[i].hasOwnProperty(att) 
           && (arr[i][att] !== value ) ){ 

           arr.splice(i,1);
       }
    }
    return arr;
}
 //eliminer les espaces pour les chaines de caractere
  export const DeleteSpace=(edt)=>{
    do{
      var index = edt.indexOf("&nbsp;");
      if(index!==-1){ 
        edt=edt.replace("&nbsp;","");
        edt=edt.replace(" ","")
      }   
    } while (edt.indexOf("&nbsp;")!==-1);
    var text = edt.replace(/<[^>]*>/g, "")
    /*do{
      var ind = edt.indexOf("<div>");
      if(ind!==-1){ 
        edt=edt.replace("<div>","");
        edt=edt.replace("<br>","");
        edt=edt.replace("</div>","");
      }   
    } while (edt.indexOf("<div>")!==-1);
    do{
      var ind = edt.indexOf("</r>");
      if(ind!==-1){ 
        edt=edt.replace("</r>","");
      }   
    } while (edt.indexOf("/r")!==-1);*/
    return text
  }
  export const DeleteSpace2=(edt)=>{
    edt=edt.replace(/&nbsp;/g,"");
    return edt.replace(/\s\s+/g, ' ').trim();
  }
  
  //return the index of an Object by a property value
  export function getIndex(arr,val,att) {
    var index = arr.map(x => {
      return x[att];
    }).indexOf(val);
   // console.log(index)
    return index;
  }
  
  //get next element
  export const navObj = (obj, currentKey, direction) => {
    return Object.keys(obj).indexOf(currentKey) + direction;
  };
  export const formatDate=(today)=>{
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 
    var yyyy = today.getFullYear();
    
    today = dd + '-' + mm + '-' + yyyy;
    return today
  }
  export const formatDate1=(today)=>{
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 
    var yyyy = today.getFullYear();
    
    today = yyyy + '-' + mm + '-' + dd;
    return today
  }
  //imprimer un composant
  export function print(divName,document) {
    var printContents = document.getElementById(divName).innerHTML;    
    var originalContents = document.body.innerHTML;      
    document.body.innerHTML = printContents;     
    window.print();     
    //document.location.reload();
    document.body.innerHTML = originalContents;
  }
 export  function DateDeb(today){
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth()).padStart(2, '0'); 
    var yyyy = today.getFullYear()
    if(mm==='00'){
      today = (yyyy-1) + '-' + 12 + '-' + dd;
    }
    else{
          today = yyyy + '-' + mm + '-' + dd;

    }
    return today
  }
 export function DayName(day){
  switch (day) {
    case 0: return 'Dim.';
    case 1: return 'Lun.';
    case 2: return 'Mar.';
    case 3: return 'Mer.';
    case 4: return 'Jeu.';
    case 5: return 'Ven.';
    default:return'Sem.';    
  }
 }

 export  function DissectDay(today){
    var array={}
    var number = String(today.getDate()).padStart(2, '0');
    var day = today.getDay()
    array['num']=number
    array['day']=DayName(day)
    array['Format']=formatDate(today)
    return array
  }

export  const rgbToHex = (rgb) => {
  //console.log(rgb)
  var index = rgb.indexOf("rgb"); 
  if(index!==-1)
    {
     const rgbExcludeFirst = rgb.split('rgb(')[1]; 
     const rgbExcludeLast = rgbExcludeFirst.split(')')[0]; 
     const rgbValueArray = rgbExcludeLast.split(',');  
     const first= Number(rgbValueArray[0]).toString(16)
     const second=Number( rgbValueArray[1]).toString(16)
     const third=Number(rgbValueArray[2]).toString(16)
     return '#'+first+second+third
    }else return rgb
  };
  //Check if it is the same day
  export const datesAreOnSameDay = (first, second) =>{
    if (!first||!second) return false
    return(
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate())
  };
  //Check if valide date
  export const dateIsValide = (day) =>{
    if(day===null||undefined) return false 
    return (Object.prototype.toString.call(day) === "[object Date]"&&!isNaN(day)&&(datesAreOnSameDay(new Date(),day)||day.getTime()<new Date().getTime()))
  };

   //get x last days
 export function LastXDay (x) {
  var d = new Date();
  d.setDate(d.getDate() - x);
  return d;
}
//get Time in seconds from hours and minutes
export const getClock = (date) => {
  /*
  var seconds = date.getSeconds();
  var minutes = date.getMinutes();
  var hour = date.getHours();*/

  return(date.toLocaleTimeString('fr-FR'));
};


  //get user infornations
  export const getInfo=()=>{
    const info={
      name:localStorage.getItem('name'),
      session:localStorage.getItem('token'),
      mag:localStorage.getItem('mag'),
      magid:localStorage.getItem('magid'),
      mags:localStorage.getItem('mags'),
    }
 return info;
} 

//check if phone number
export function phonenumber(inputtxt){
  //var phoneno = /^\d{10}$/;
  var phoneno = '[0][5-7][4-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]';
  if(inputtxt.match(phoneno) && inputtxt.length===10){ return {response:true,message:"C'est le bon format."};}
  else return {response:false,message:"Vous devez insérer le bon format!"};
}

//date format mm-dd-yyy
export function englishDate(date,heure){
  var dateParts =date.split("-");
  var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  dateObject.setHours(heure.substring(0,2),heure.substring(3,5))
  return dateObject ;
}
//return the object max date from array 
export function maxDate(ar,att,heure){
  const day=ar.reduce((a, b) => {
    return new Date(englishDate(a[att],a[heure])) > new Date(englishDate(b[att],b[heure])) ? a : b;
  });
  return day[att]+' à '+day[heure];
}
//return the object min date from array 
export function minDate(ar,att,heure){
  const day=ar.reduce((a, b) => {
    return new Date(englishDate(a[att],a[heure])) < new Date(englishDate(b[att],b[heure])) ? a : b;
  });
  return day[att]+' à '+day[heure];
}
//calculate unique items
export function countTableId(a,att){
  // sets are, well, sets of unique items. no duplicates allowed
  let uniqueId = new Set();
  for(let elem of a){
      uniqueId.add(elem[att]);
  }
  // the size of the set is the number of unique items we added
  return uniqueId.size;
}

//if empty string returns null
export function stringToNull(str){
  if(str==='')
  return null;
  return str
}
//if empty string returns 0
export function stringToZero(str){
  if(str==='')
  return 0;
  return str
}
//if 0 returns null
export function zeroToNull(val){
  if(val===0)
  return null;
  return val
}
//lose focus
export  function loseFocus(id){
  $(id).on('keydown', function(event) {
    if(event.keyCode===13){
    $(id).off( "focusout" )
    $(id).blur()
   }
    });
  }

//sum of value in an array of objects 
export  function sumArObj(arr,att){
  const sum = arr.reduce((accumulator, object) => {
    return accumulator + object[att];
  }, 0);
  return sum;
  }
//check if a number is odd
export function isOdd(num) { return num % 2;}

//sum of value in an array of objects 
export  function getAllIds(arr,att){
  let ids=[];
  arr.reduce((previousValue, object) => {
    return ids.unshift(object[att]);
  }, 0);
  return ids;
  }

  
//format to 2 decimals to use it do : formatterTo2.format(Some value)
export const formatterTo2 = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,      
    maximumFractionDigits: 2,
 });
export function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}
 
export function getAllDaysInMonth(year, month) {
  const date = new Date(year, month-1, 1);
  const dates = [];

  while (date.getMonth() === month-1) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return dates;
}
export function AllOccurrences(arr,el,att){
  var indexes=[]
  for (let index = 0; index < arr.length; index++) {
    if (arr[index][att] === el) {
      indexes.push(index);
    }
  }
  return indexes;
}
export function getYear(date){
  var yyyy = date.getFullYear();
  return yyyy.toString();
  
}
export function MonthName(num){
  switch (num) {
    case '01': return 'Janvier';
    case '02': return 'Fevrier';
    case '03': return 'Mars';
    case '04': return 'Avril';
    case '05': return 'Mai';
    case '06': return 'Juin';
    case '07': return 'Juillet';
    case '08': return 'Aout';
    case '09': return 'Septembre';
    case '10': return 'Octobre';
    case '11': return 'Novembre';
    default:return'Decembre';    
  }
 }
export function MonthName1(num){
  switch (num) {
    case 'Janvier': return '01';
    case 'Fevrier': return '02';
    case 'Février': return '02';
    case 'Mars': return '03';
    case 'Avril': return '04';
    case 'Mai': return '05';
    case 'Juin': return '06';
    case 'Juillet': return '07';
    case 'Aout': return '08';
    case 'Septembre': return '09';
    case 'Octobre': return '10';
    case 'Novembre': return '11';
    default:return'12';    
  }
 }
export function getMonth(date){
  var mm = String(date.getMonth()+1).padStart(2, '0');
  var monthName=MonthName(mm)
  return monthName;
}
export function DayLanguage(s){
  switch (s) {
    case 'Sun': return 'Dim.';
    case 'Mon': return 'Lun.';
    case 'Tue': return 'Mar.';
    case 'Wed': return 'Mer.';
    case 'Thu': return 'Jeu.';
    case 'Fri': return 'Ven.';
    default:return'Sam.';    
  }
 }

 export const getParams=async()=>{
  var param= null
  await fetch('/param.json'
  ,{
    headers : { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
     }
  }
  )
    .then(function(response){
      //console.log(response)
      param=response.json();
    })
    .then(function(myJson) {
      //console.log(myJson);
    });
    return param
}

export function isNullUndfEmpStr(obj) {
  for (var key in obj) {
      if (obj[key] != null && obj[key] !== "" && obj[key] !== undefined)
          return false;
  }
  return true;
}

//check if array contains an object with an attribute that equals a given value (or multiple)
export function arrayhasObjWithAtt(arr,att,val){
  var found = false;
  if(arr)
  for(var i = 0; i < arr.length; i++) {
      if (val.includes(arr[i][att])) {
          found = true;
          break;
      }
  }
  return found;
}
//remove item from array once 
export function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}
//remove all item from array once 
export function removeItemAll(arr, value) {
  
  const filtered=arr.filter((el)=>el!==value)
  return filtered;
}

export const getMag=async()=> {    
  const Gfunc=require('./Gfunc')
  const response=await Gfunc.axiosPostAction('_mag.php','getmag',{id:localStorage.getItem('magid')})
  Gfunc.axiosResponse(response,()=>{
    if(response.data.jData){
      localStorage.setItem('adresse',response.data.jData.adresse)
      localStorage.setItem('date',response.data.jData.date)
      localStorage.setItem('stockId',response.data.jData.defStock.stockId)
      localStorage.setItem('stock',response.data.jData.defStock.stock)
      localStorage.setItem('stockactiv',response.data.jData.defStock.activ)
      localStorage.setItem('entet_tiket_1',response.data.jData.entet_tiket_1)
      localStorage.setItem('entet_tiket_2',response.data.jData.entet_tiket_2)
      localStorage.setItem('etiquette',response.data.jData.etiquette)
      localStorage.setItem('facebook',response.data.jData.facebook)
      localStorage.setItem('logo',response.data.jData.logo)
      localStorage.setItem('mail',response.data.jData.mail)
      localStorage.setItem('tel',response.data.jData.tel)
      localStorage.setItem('type',response.data.jData.type)
      localStorage.setItem('etiqDisplayPrice',response.data.jData.etiqDisplayPrice)
      localStorage.setItem('tiketWidth',response.data.jData.tiketWidth)
      localStorage.setItem('expireDate',response.data.jData.expireDate)
      localStorage.setItem('intgIraha',response.data.jData.irahaIntegration)
      //localStorage.setItem('shortcuts',JSON.stringify(response.data.jData.shortcuts))
    }
  })
  return response.data.jData
}


export const getHijriYears = (startYear, endYear) => {
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }
  return years.reverse();
};
export const getCurrentHijriYear = () => {
  const currentHijriDate = new HijriDate();
  return currentHijriDate.getFullYear();
};

//fetch update elem number
export const updateElem = (id, val) => {
  var elem = document.getElementById(id);
  if (elem)
    elem.innerHTML = new Intl.NumberFormat().format(val);
}
//fetch update elem string
export const updateElemS = (id, val) => {
  var elem = document.getElementById(id);
  if (elem != null && elem !== undefined) elem.innerHTML = val;
}