import React,{useState,useMemo,useCallback,useRef,useEffect} from 'react';
import {DialogContent,Paper,Button,Dialog,ClickAwayListener,CircularProgress,Tooltip,Typography,TextField,IconButton} from '@material-ui/core';
import {Close,DoneAll,IndeterminateCheckBox} from '@material-ui/icons';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {customStyles,numberColumn} from '../../../css/datatable-custom'
import { useStyles, paperPropsSmall3} from '../../../css/muiStyle';
import DataTable from "react-data-table-component";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {AlertCompo,InputNbrEditable,AutocompleteS} from "../../../js/components"
import $ from 'jquery'
import ContentEditable from 'react-contenteditable';
import Iconn from '@mdi/react';
import { mdiKeyboardSettings, mdiKeyboardSettingsOutline } from '@mdi/js';
import { useContext } from 'react';
import ParamContext from '../../../Contexte';
import "../../../css/referentiel.css"
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_prd_rac(props) {
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const librery=require('../../../js/data_table_librery')
    const [open, setOpen] =useState(false);
    const [errorP, setErrorP] =useState(false);
    const [errorQ, setErrorQ] =useState(false);
    const [errorS, setErrorS] =useState(false);
    const [edit, setEdit] =useState(false);
    const [editt, setEditt] =useState(false);
    const classes=useStyles();
    const [data, setData] =useState([]);
    const [stks, setStks] =useState([]);
    const [qte, setQte] =useState('');
    const [rac, setRac] =useState('');
    const [id, setId] =useState('');
    const [nom, setNom] =useState('');
    //const [stock, setStock] =useState(localStorage.getItem('stock'));
    const [stock, setStock] =useState('');
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const NO=['Control','Alt','Shift',"0","1","2","3","4","5","6","7","8","9","-","*"]
    const Ye=["F11",'F12']
    const service='_prd.php'

    $(function() {
      $("div").removeAttr("data-tag")
    })  
    
     
      //handle alerts properties
    const handleAlert=(open,severity,message)=>{
        setOpenA(open)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
      }

    const handleClickOpen = () => { 
      setOpen(true); 
      handleOpenStock();
      setData([]);setRac('');setQte('');setErrorP(false);setErrorQ(false);setId('');setEdit(false);setNom('');
      setStock(localStorage.getItem('stock'))
      fetch();setEdit(false);
    };
    
    const  HandleDetect=useCallback(async(event)=>{
           if(event.target.id==='shortcuts') {
                if(Ye.includes(event.key)){
                  event.preventDefault()
                    setRac(event.key)
                }
                if((event.ctrlKey&&!NO.includes(event.key))||(event.altKey&&!NO.includes(event.key))||(event.shiftKey&&!NO.includes(event.key))){ 
                  event.preventDefault()
                    const even=(event.ctrlKey)?'ctrl+':(event.altKey)?'alt+':'shift+'
                    const rac=even+event.key
                    setRac(rac)  
                  
                }
                if((event.ctrlKey&&event.altKey&&!NO.includes(event.key))||(event.altKey&&event.shiftKey&&!NO.includes(event.key))||(event.shiftKey&&event.ctrlKey&&!NO.includes(event.key))){
                    event.preventDefault()
                    const even=(event.ctrlKey&&event.altKey)?'ctrl+alt+':(event.altKey&&event.shiftKey)?'alt+shift+':'shift+'
                    const rac=even+event.key
                    setRac(rac)
                }
            }

     },[])
      
   
  //update field
  const handleOnEdit=useCallback(async(e,row,att,idd)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
        if(row[att]!==edt &&edt!==''){
          const attrib=row[att];
          const response=await librery.axiosEdit2(service,'updshort',row,edt,att,idd,'text') 
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{
            row[att]=edt;e.target.innerHTML=edt
             const objIndex =  data.findIndex((obj => obj.shortcutId ===row.shortcutId ));
              data[objIndex].shortcutName=edt1
          })
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt}
  },[librery,Gfunc]);

//update shortcuts
  const EditRac=useCallback(async(event)=>{
   event.preventDefault()
   var value='';var test=0;
   if(Ye.includes(event.key)){
     value=event.key
   }
   if((event.ctrlKey&&!NO.includes(event.key))||(event.altKey&&!NO.includes(event.key))||(event.shiftKey&&!NO.includes(event.key))){ 
     test=1
     const even=(event.ctrlKey)?'ctrl+':(event.altKey)?'alt+':'shift+'
     value=even+event.key
   }
   if((event.ctrlKey&&event.altKey&&!NO.includes(event.key))||(event.altKey&&event.shiftKey&&!NO.includes(event.key))||
      (event.shiftKey&&event.ctrlKey&&!NO.includes(event.key))){
      const even=(event.ctrlKey&&event.altKey)?'ctrl+alt+':(event.altKey&&event.shiftKey)?'alt+shift+':'shift+'
      value=even+event.key
    }
   if(Ye.includes(event.key)||(event.ctrlKey&&!NO.includes(event.key))||(event.altKey&&!NO.includes(event.key))||(event.shiftKey&&!NO.includes(event.key))|| 
      (event.ctrlKey&&event.altKey&&!NO.includes(event.key))||(event.altKey&&event.shiftKey&&!NO.includes(event.key))|| (event.shiftKey&&event.ctrlKey&&!NO.includes(event.key))){
        const response = await Gfunc.axiosPostAction(service,"updshort",{att:'shortcut',id:'c'+id,val:value})
        if(response.data.err){
          handleAlert(true,'error',response.data.err)
          setEdit(false)
        }else{
          Gfunc.axiosResponse(response,()=>{
            if(response.data.jData===true){
              const objIndex =  data.findIndex((obj => obj.shortcutId ===id ));
              data[objIndex].shortcut=value
              setEdit(false)
              setEditt(false)
            // setId('')
            }
        })
      }
   } 
  },[data,id])

  
  const HandleTest=(row)=>{
    setId(row.shortcutId)
    setEdit(true)
    setEditt(true)
  }

  useEffect(() => {
    if(open===true){
        // attach the event listener   
         if(editt===false) {
          document.addEventListener('keydown', HandleDetect);
         }else{
          document.addEventListener('keydown', EditRac);
         }
         // remove the event listener
         return () => {
         if(editt===false) {
             document.removeEventListener('keydown', HandleDetect);
         }else{
             document.removeEventListener('keydown', EditRac);
          }
         };
    } 
  }, [editt,EditRac,HandleDetect]);  

 

    const fetch=async()=>{
      const response=await Gfunc.axiosPostAction(service,"getshorts",{produitId:parseInt(props.row.DT_RowId.substr(1))})
      if(response.data.err&&response.data.err!=="-1"){
           handleAlert(true,"error",response.data.err)
      }else
          Gfunc.axiosResponse(response,()=>{
             setData(response.data.jData)
       })
    }
    const handleClose = () => { setOpen(false); };

    
  const handleDelete=useCallback(async(row)=>{
    const response = await Gfunc.axiosPostAction(service,"delshort",{shortcutId:"c"+row.shortcutId})
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
      setOpen(true)
    }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData===true){
          const objIndex =  data.findIndex((obj => obj.shortcutId ===row.shortcutId )); 
          data.splice(objIndex,1)
          handleAlert(true,"success","La suppression du raccourci a été supprimeé avec succès")
        }
    })
  }
  },[data])
 
  const columns =useMemo(
        () => [ 
          {
            name: "Raccourci",
            selector: row=>row.shortcut,
            sortable: true, 
            minWidth:'150px !important',
            cell: (row) => {
              return (
                <div  id={'rac'+row.shortcutId} className='shortcut'>
                    { 
                    (row.shortcutId===id&&edit)?
                    <ClickAwayListener onClickAway={()=>{setEdit(false);setEditt(false)}}>
                        <TextField  id='rac' value={row.shortcut} color="primary" variant="outlined"/>
                    </ClickAwayListener> :<div  className="content-editable" onClick={()=>HandleTest(row)}>{row.shortcut}</div>
                    }
                </div>
               );
             }
           },
          {
            name: "Nom",
            selector: row=>row.shortcutName,
            sortable: true, 
            minWidth:"150px",
            cell: (row) => {
              return (
              <ContentEditable
                  html={(row.shortcutName)?row.shortcutName:''}
                  name="nom"
                  data-column="item"
                  className="content-editable"
                  disabled={false} 
                  onBlur={e => handleOnEdit(e,row,"shortcutName",'shortcutId')}
               />
               );
             },
           },
          {
            name: "QTE.",
            selector: row=>row.qte,
            style:numberColumn,
            sortable: true,
            minWidth:"100px !important",
            cell: (row) => {
              return (
                <InputNbrEditable  service={service} action='updshort' min={1} row={row} att="qte" value={row.qte} id='shortcutId' 
                                   idi={row.shortId} handleAlert={handleAlert}/>
               );
             }
          },
          {
            name: "Stock",
            selector: row=>row.stock,
            sortable: true, 
            minWidth:"150px !important",
            cell:(row)=>{
              return(
                  <div>
                      <AutocompleteS service={service} action='updshort' defaultValue={row.stock} row={row} list={stks} rowId='shortcutId'
                                     id='stockId' name='stock' attr='nom'  onOpen={handleOpenStock} handleAlert={handleAlert} />  
                  </div>
              );
          }
          },
          {
            minWidth:"50px !important",
            right:true,
            cell: (row) => {
              return (
                <Tooltip title="Supprimer produit">
                   <IndeterminateCheckBox className={classes.redIcon} fontSize="small" onClick={()=>handleDelete(row)}/>
                 </Tooltip>
                );
             },
          } 
        ],[handleDelete,HandleTest,EditRac,HandleDetect,id]);

    //fetch all users that doesn't have the selected role
     const fetchStock=useCallback(async() => {
        const response=await Gfunc.axiosGetAction("_stk.php","gets")
              if(response.data.err&&response.data.err!=='-1'){
                handleAlert(true,"error",response.data.err)
                setOpen(false)
                return false
              }else
                if(response.data.jData!==undefined){
                  Gfunc.axiosResponse(response,()=>{
                  setStks(response.data.jData);
                  return true
                  })
                }
      },[Gfunc,props])  

   //handle the call for fetch commune
   const handleOpenStock=(event)=>{
    if(stks.length===0)
    {
       fetchStock();
    }else{
      //  setError(true)
    }
   }

    //handle submit form
  const handleSubmit= async() =>{
    if(qte===''){setErrorQ(true)}
    if(nom===''){setErrorP(true)}
    if(qte!==''&&nom!==''){
      const stk=Gfunc.getIndex(stks,stock,"nom")?stks[Gfunc.getIndex(stks,stock,"nom")].id:''
      const response = await Gfunc.axiosPostAction(service,"addshort",{shortcut:rac,produitId:parseInt(props.row.DT_RowId.substr(1)),
                                                                       qte:parseInt(qte),stockId:parseInt(stk),shortcutName:nom})
          if(response.data.err){
              handleAlert(true,'error',response.data.err)
              setOpen(true)
          }else{
              Gfunc.axiosResponse(response,()=>{
                if(response.data.jData){
                  data.unshift({
                    shortcutName:nom,
                    qte: 7,
                    shortcut: rac,
                    shortcutId: response.data.jData.shortcutId,
                    stock:stock
                  })
                  setRac('');setQte('');setStock('');setNom('')
                //  handleAlert(true,"success","Un nouveau raccourci a été ajouté avec succès")
                  setOpen(false)
                }
             })
          }
    }
  }

  //code jQuery pour boucler le focus des elements
  $('form').each(function(){
    var list  = $(this).find('input*[tabindex],#last*[tabindex]').sort(function(a,b){ 
      return a.tabIndex > b.tabIndex ? 1 : -1; 
    }),
    first = list.first();
    list.last().on('keydown', function(e){
        if( e.keyCode === 9 ) {
            first.focus();
            return false;
        }
    });
  });
 
  return (
    <div>
       <Tooltip title="Raccourcis du produit" className='Tooltip'> 
          <Iconn path={ mdiKeyboardSettings } size={0.9} color='red' onClick={handleClickOpen}/> 
       </Tooltip>
       <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmall3}
        >
        <DialogTitle className={classes.topDivTitle}  onClose={handleClose} >
           <div className='DialogTitle'>
              <Iconn path={mdiKeyboardSettingsOutline} size={1} />
              <label>Les raccourcis de produit {props.row.produit}</label>
            </div> 
        </DialogTitle>
        <DialogContent >
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <div id='fieldSet'>
            <ValidatorForm    id="form" ref={useRef(null)}  onSubmit={handleSubmit} >
            <div className="rowGrid" id="rac-row-1">
              <TextField  id='shortcuts'  color="primary" variant="outlined" className='smallText' placeholder="Raccourci" 
                          label="Raccourci" size="small"  inputProps={{ tabIndex: "1",className:classes.input }}  autoFocus
                          value={rac} onFocus={HandleDetect}/>
              <TextField required id='iNom' error={errorP} color="primary" variant="outlined" className='smallText' 
                         label="Nom" size="small"  inputProps={{ maxLength: 10,tabIndex: "2",className:classes.input }}  
                         value={nom}  onChange={e =>{setNom(e.target.value)}} placeholder="Nom" 
                         helperText={(errorP)?"Veuillez sélectionner un nom ":null}/>
              <TextField required id='iQte' type='number' error={errorQ} color="primary" variant="outlined" className='smallText' 
                         label="Quantité" size="small"  inputProps={{min: 0, tabIndex: "3",className:classes.input }} 
                         value={qte}  onChange={e =>{setQte(e.target.value);setErrorQ(false)}} placeholder="Quantité"
                         helperText={(errorQ)?"Veuillez sélectionner une quantité ":null}/>
             <Autocomplete
                disableClearable
                id='iStk'
                onChange={(e,newValue) =>{setStock(newValue);setErrorS(false)}}
                value={stock}
                options={stks.map((option) => option.nom)}
                className='smallAuto'
                renderInput={(params) => 
                  <TextField {...params} margin='dense' error={errorS} size='small' variant="outlined"  label="Stock..."  
                             inputProps={{...params.inputProps,tabIndex:'4'}} SelectProps={{ MenuProps: {closeAfterTransition:true } }}/>}
             />          
             <Button className={classes.PrintBtn} variant="contained" type='submit' id='last' tabIndex='5' color="primary"  
                     startIcon={<DoneAll/>} title='Valider' >Valider</Button>
           </div>
           </ValidatorForm >
          </div>

              <DataTable
                  key={Math.floor(Math.random() * 100)}
                  columns={columns}
                  data={data}
                  noDataComponent={param.ErreurData}
                  defaultSortAsc={true}
                  paginationRowsPerPageOptions={[5, 10, 20, 30]}
                  highlightOnHover
                  customStyles={customStyles} 
                  selectableRowsComponentProps={{ className: classes.checkStelect }}
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
                  contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
                  progressComponent={<CircularProgress className="circularProgress" />}
             />
      
            </DialogContent>
      </Dialog>
      </div>
  );
}