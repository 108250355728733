import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import {CircularProgress,ClickAwayListener,TextareaAutosize,Tooltip,FormControlLabel,Checkbox} from '@material-ui/core';
import {Search,Lock,LockOpen,Person} from '@material-ui/icons';
import {conditionalRowStyles,customStyles}from '../../css/datatable-custom'
import { useStyles ,textAreaEdit} from '../../css/muiStyle';
import {AlertCompo,AutocompleteS,InputNbrEditable,NoDataComponent,NotAllowedComponent,TelUpd} from "../../js/components"
import useMediaQuery from '@mui/material/useMediaQuery';
import {ReactComponent as Limit} from '../../img/icons/seuil.svg'
import {ReactComponent as Commercial} from '../../img/icons/Commercial.svg'
import Dialog from './modals/ach_frs_add'
import { Box } from "@mui/system";
import './../../css/vente.css'
import $ from 'jquery';

export default function Vnt_cli(props){
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [wilaya,setWilaya]=useState('');
  const [wilayas,setWilayas]=useState([]);
  const [communes,setCommunes]=useState([]);
  const [Icon,setIcon]=useState('');
  const [open, setOpen] =useState(false);
  const [,setUpd] =useState('');
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const[RespWils,setRespWils]=useState(false); 
  const[noData,setNoData]=useState(false); 
  const[notAllowed,setNotAllowed]=useState(false);
  const[,setError]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[doSearch,setDoSearch]=useState(false);
  const [selecto,setSelecto]=useState('')
  const [Item,setItem]=useState('')
  const [ItemN,setItemN]=useState('')
  const [note,setNote]=useState('')
  const [classe,setClasse]=useState([]);
  const matches = useMediaQuery('(max-width:600px)');
  const matche = useMediaQuery('(max-width:1150px)');
  const service='_cli.php'
  const srv='client'
  const classes=useStyles()
  const[called,setCalled]=useState(false);
  
   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  const fetch=useCallback(async(page,servic = service, size = perPage) => {
    setCalled(true)
    setLoading(true);
    const response=await librery.axiosGet(page+'&search='+search,servic,size)
      if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setDoSearch(true)
        setNoData(true)
        }else {setDoSearch(false);setData([])}
      })
    setLoading(false)
  },[Gfunc,librery,perPage,search])

     //fetch wilaya
  const fetchWilaya= useCallback(async() => {
       setRespWils(true)
      const response = await Gfunc.axiosPostAction("_ref.php","getswils")
      if(response.data.err&&response.data.err!=="-1"){
        //document.getElementById(id).blur();
        setOpen(false)
        handleAlert(true,'error',response.data.err)
      }else 
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==undefined)
          setWilayas(response.data.jData);   
      })
    },[Gfunc]);

  //fetch class
  const fetchClass= useCallback(async() => {
      const dat=[]
      const response = await Gfunc.axiosPostAction(service,"getclass")
      if(response.data.err&&response.data.err!=="-1"){
        //document.getElementById(id).blur();
        setOpen(false)
        handleAlert(true,'error',response.data.err)
      }else 
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==undefined)
        Object.keys(response.data.jData).map((key)=> {
          dat.push({id:parseInt(key),name:response.data.jData[key]})
          return true
        })  
          setClasse(dat);   
      })
    },[Gfunc]);

  //handle the call for fetch classe
    const handleOpenClasse=()=>{
      if(classe.length===0) fetchClass();
  }
  useEffect(() => {
    if(!called) fetch(1);
    if(!RespWils)fetchWilaya();
    Gfunc.loseFocus('.content-editable')
  }, [fetch,fetchWilaya,called,wilayas.length]);
  
  const HandleUpd=(val)=>{
      setUpd(val)
  }

  const flagMan = useCallback(async( e,row,att)=>{
        var value=''
        value=(row[att])?1:0
        const response= await Gfunc.axiosPostAction('flagman.php','',({ srv:srv, id: row.DT_RowId.substr(1),att:att, val:value }))
        if(response.data.err&&response.data.err!=="-1"){
          handleAlert(true,'error',response.data.err)
        }else{   
          Gfunc.axiosResponse(response,()=>{
           if(response.data.jData){
            const objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId)); 
            if(row[att]){
                data[objIndex][att]=false
            }else {data[objIndex][att]=true}
            setUpd(row.DT_RowId)
            HandleUpd('')
          }
                })
        }
  },[Gfunc,data,srv,HandleUpd]);

  //update field
  const handleOnEdit=useCallback(async(e,row,att)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
        if(row[att]!==edt &&edt!==''){
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt,att) 
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt}
  },[librery,Gfunc]);

  const handleLockIcon=useCallback((val)=>{
      setIcon(val);
  },[]);

 
  useEffect((val) => {
    handleLockIcon(val);
  }, [handleLockIcon]);
  

 //fetch commune 
 const fetchCommune= useCallback(async(val) => {
   var wil=''
  setCommunes([])
  if(wilaya!==''){
    const objIndex = wilayas.findIndex((obj => obj.id ===wilaya )); 
    wil=wilayas[objIndex].id
  }else{
    const objIndex = wilayas.findIndex((obj => obj.name ===val )); 
    wil=wilayas[objIndex].id
  }
  const response = await Gfunc.axiosPostAction("_ref.php","getswilcoms",{wil:wil})
  if(response.data.err&&response.data.err!=="-1"){
    setOpen(false)
    handleAlert(true,'error',response.data.err)
  }else
  Gfunc.axiosResponse(response,()=>{
      setWilaya('')
      setCommunes(response.data.jData);   
  })
  },[Gfunc,wilayas,wilaya]);

   //handle the call for fetch produit
   const handleOpenWilaya=useCallback((event)=>{
    setError(false)
    if(wilayas.length===0)
    fetchWilaya();
   },[wilayas.length,fetchWilaya])

 //handle the call for fetch commune
const handleOpenCommune=useCallback((row)=>{
        fetchCommune(row.wilaya);
},[fetchCommune])

const handleSelect=useCallback((row,att)=>{
    setSelecto(row[att])
    setItem(row.DT_RowId)
    setItemN(att)
 },[])

$('.numberonly').on( "keypress",function (e) {    

  var charCode = (e.which) ? e.which : e.keyCode    

  if (String.fromCharCode(charCode).match(/[^0-9]/g))    

      return true;                        

});    

$('#tel-edit').on( "keydown",function(e){ check_charcount('tel-edit', 9, e); });

function check_charcount(content_id, max, e)
{   
    if(e.which !== 8 && $('#'+content_id).text().length > max)
    {
       // $('#'+content_id).text($('#'+content_id).text().substring(0, max));
       e.preventDefault();
    }
}


  //handle update note
  const handleOnEditNote=useCallback(async(note,row,att,action)=>{
    const edt1=note
    var edt=Gfunc.DeleteSpace(edt1);
    if(row[att]!==edt){
      edt=edt.replaceAll("\n", "\r")
      const attrib=row[att];
      const response=await librery.axiosEdit2(service,action,row,edt,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{row[att]=edt})
      if(response.data.err&&response.data.err!=="-1") {row[att]=attrib}
    }else {row[att]=edt}
    setNote('')
    setSelecto('')
  },[librery,Gfunc]);

  const columns =useMemo(
    () => [   
      {
          name: "Nom",
          selector: row=>row.client,
          sortable: true,
          minWidth:'190px !important',
          sortField:'client',
          cell: (row) => {
            return (
            <ContentEditable
                html={(row.client)?row.client:''}
                name="clt"
                data-column="item"
                className="content-editable"
                disabled={false} 
                onBlur={e => handleOnEdit(e,row,"client")}
            />
             );
           },
      },
      {
          name: "Adresse",
          selector: row=>row.adresse,
          sortable: true,
          minWidth:'150px !important',
          sortField:'adresse',
          cell: (row) => {

            return (
            <ContentEditable
                html={(row.adresse)?row.adresse:''}
                name="adresse"
                data-column="item"
                className="content-editable"
                disabled={false} 
                onBlur={e => handleOnEdit(e,row,"adresse")}
          />
             );
           },
      },
      {
        name: "Commune",
        selector: row=>row.commune,
        sortable: true,
        minWidth:'140px !important',
        sortField:'commune',
        cell: (row) => { 
            return (
            <div>  
                 <AutocompleteS
                  service={service} action={'upd'} 
                  defaultValue={row.commune} row={row} list={communes} 
                  id={'communeId'} name='commune' attr='name'
                  onOpen={()=>handleOpenCommune(row)} handleAlert={handleAlert}/>      
            </div> 
            );
        }
      },
      {
        name: "Wilaya",
        selector: row=>row.wilaya,
        sortable: true,
        minWidth:'120px !important',
        sortField:'wilaya',
        cell:(row)=>{
            return(
                <div>
                  <AutocompleteS
                      service={service} action={'upd'} 
                      defaultValue={row.wilaya} row={row} list={wilayas} 
                      id={'wilayaId'} name='wilaya' attr='name'
                      onOpen={handleOpenWilaya} handleAlert={handleAlert}/>  
                </div>
            );
        }
      },
      {
        name: "Tel.",
        selector: row=>row.tel,
        sortable: true,
        minWidth:'110px !important',
        sortField:'tel',
        cell: (row) => {
            return ( 
              <div key={Math.random()}>
                 <TelUpd datein={row.date} agentin={row.agent} dateupd={row.dateUpd} agentupd={row.agentUpd}
                         service={service} action='upd' row={row} att="tel" value={row.tel} id='DT_RowId'handleAlert={handleAlert}
                 />
              </div>
             );
           },
      },
      {
        name: "Classe",
        selector: row=>row.classe,
        sortable: true,
        minWidth:'120px !important',
        sortField:'classe',
        cell:(row)=>{
          return(
              <div>
                <AutocompleteS
                    service={service} action={'upd'} 
                    defaultValue={row.classe} row={row} list={classe} 
                    id='classeId' name='classe' attr='name'
                    onOpen={handleOpenClasse} handleAlert={handleAlert}/>  
              </div>
          );
      }
      },
      {
        name: "Date",
        selector:row=>row.date,
        sortable: true,
        minWidth:'90px !important',
        sortField:'date',
      },
      {
        name: "Surveillé",
        selector:row=>row.onTop,
        sortable: true,
        minWidth:'110px !important',
        sortField:'onTop',
        cell: (row) => {
            return (
              <div className="checkbox">
                <FormControlLabel control={<Checkbox checked={row.onTop} className={classes.checkBox}
                                           onChange={e=>flagMan(e,row,'onTop','principale')} inputProps={{ 'aria-label': 'controlled' }}  />}
                                            /> 
              </div>
             );
           },
      },
      {
        name: "Note",
        selector:row=>row.note,
        sortable: true,
        minWidth:'180px !important',
        sortField:'note',
        cell: (row) => {
          return (
            <div className="note-container">
            { (row.note===selecto && ItemN==='note' && row.DT_RowId===Item)?
            <ClickAwayListener onClickAway={()=>{(note)?handleOnEditNote(note,row,"note",'upd'):setSelecto('')}}>
             <TextareaAutosize style={textAreaEdit} value={note}  onChange={(e)=>{setNote(e.target.value);}}
             /></ClickAwayListener>:
             ((row.note&&row.note.match(/\r/g) || []).length>=1)?
             <Tooltip title={row.note.replaceAll("\r", "/ ")}>
                 <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}}>{row.note.substring(0, row.note.indexOf("\r"))+"..."}</div>
             </Tooltip>:
               <Tooltip title={(row.note)?row.note:''}>
             <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}} >{row.note}</div></Tooltip>}
             </div>
           );
         },
      },
      {
        width:"60px !important",
        cell: (row) => {
          return (
           <div id='iconsCli' style={{ textAlign: 'right !important' }}>
            <div id='row-1'>
            <Tooltip title={(row.noReport)?"Non-associé à un rapport":"Associé à un rapport"}>
              <div className="checkbox" >
                <FormControlLabel control={<Checkbox checked={!row.noReport} className={classes.checkBox} style={{transform: "scale(0.9)"}}
                                  onChange={e=>flagMan(e,row,'noReport')} inputProps={{ 'aria-label': 'controlled', }}  />}
                               /> 
              </div>
            </Tooltip>
            <Tooltip title="Activer / Désactiver">
                <div onClick={e=>flagMan(e,row,'activ')} >
                  {
                   (row.activ)?
                    <LockOpen className={classes.redIcon} fontSize="small"/>
                   :<Lock className={classes.greenIcon} fontSize="small"/> 
                   }
                </div>        
            </Tooltip> 
            </div>      
           </div>
          );
         },
      }  
  ],[flagMan,handleOnEdit,handleLockIcon,handleOnEditNote,handleOpenCommune,handleOpenWilaya,handleOpenClasse,handleSelect,note,Icon,classes,Item,ItemN,selecto,wilayas,communes,classe]);
  
  const columns1 =useMemo(
    () => [   
      {
          name: "Nom",
          selector: row=>row.client,
          sortable: true,
          width:'190px !important',
          sortField:'client',
          cell: (row) => {
            return (
            <ContentEditable
                html={(row.client)?row.client:''}
                name="clt"
                data-column="item"
                className="content-editable"
                disabled={false} 
                onBlur={e => handleOnEdit(e,row,"client")}
            />
             );
           },
      },
      {
          name: "Adresse",
          selector: row=>row.adresse,
          sortable: true,
          minWidth:'150px !important',
          sortField:'adresse',
          cell: (row) => {

            return (
            <ContentEditable
                html={(row.adresse)?row.adresse:''}
                name="adresse"
                data-column="item"
                className="content-editable"
                disabled={false} 
                onBlur={e => handleOnEdit(e,row,"adresse")}
          />
             );
           },
      },
      {
        name: "Commune",
        selector: row=>row.commune,
        sortable: true,
        minWidth:'160px !important',
        sortField:'commune',
        cell: (row) => { 
            return (
            <div>  
                 <AutocompleteS
                  service={service} action={'upd'} 
                  defaultValue={row.commune} row={row} list={communes} 
                  id={'communeId'} name='commune' attr='name'
                  onOpen={()=>handleOpenCommune(row)} handleAlert={handleAlert}/>      
            </div> 
            );
        }
      },
      {
        name: "Wilaya",
        selector: row=>row.wilaya,
        sortable: true,
        minWidth:'130px !important',
        sortField:'wilaya',
        cell:(row)=>{
            return(
                <div>
                  <AutocompleteS
                      service={service} action={'upd'} 
                      defaultValue={row.wilaya} row={row} list={wilayas} 
                      id={'wilayaId'} name='wilaya' attr='name'
                      onOpen={handleOpenWilaya} handleAlert={handleAlert}/>  
                </div>
            );
        }
      },
      {
        name: "Tel",
        selector: row=>row.tel,
        sortable: true,
        width:'130px !important',
        sortField:'tel',
        cell: (row) => {
            return (
              <div key={Math.random()}>
                <TelUpd datein={row.date} agentin={row.agent} dateupd={row.dateUpd} agentupd={row.agentUpd}
                        service={service} action='upd' row={row} att="tel" value={row.tel} id='DT_RowId'handleAlert={handleAlert}
                />
              </div>
             );
           },
      },
      {
        name: "Classe",
        selector: row=>row.classe,
        sortable: true,
        maxWidth:'130px !important',
        sortField:'classe',
        cell:(row)=>{
          return(
              <div>
                <AutocompleteS
                    service={service} action={'upd'} 
                    defaultValue={row.classe} row={row} list={classe} 
                    id='classeId' name='classe' attr='name'
                    onOpen={handleOpenClasse} handleAlert={handleAlert}/>  
              </div>
          );
      }
      },
      {
        name: "Date",
        selector: row=>row.date,
        sortable: true,
        sortField:'date',
        minWidth:'100px  !important'
      },
      {
        name: "Note",
        selector:row=>row.note,
        sortable: true,
        minWidth:'220px !important',
        sortField:'note',
        cell: (row) => {
          return (
            <div className="note-container">
            { (row.note===selecto && ItemN==='note' && row.DT_RowId===Item)?
            <ClickAwayListener onClickAway={()=>{(note)?handleOnEditNote(note,row,"note",'upd'):setSelecto('')}}>
             <TextareaAutosize style={textAreaEdit} value={note}  onChange={(e)=>{setNote(e.target.value);}}
             /></ClickAwayListener>:
             ((row.note&&row.note.match(/\r/g) || []).length>=1)?
             <Tooltip title={row.note.replaceAll("\r", "/ ")}>
                 <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}}>{row.note.substring(0, row.note.indexOf("\r"))+"..."}</div>
             </Tooltip>:
               <Tooltip title={(row.note)?row.note:''}>
             <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}} >{row.note}</div></Tooltip>}
             </div>
           );
         },
      },
      {
        maxWidth:"45px !important",
        cell: (row) => {
          return (
            <div id='row-1'>
                <div onClick={e=>flagMan(e,row,'activ')} title="Activer/Desactiver">{(row.activ)?<LockOpen className={classes.redIcon} fontSize="small"/>:<Lock className={classes.greenIcon} fontSize="small"/> }</div>        
             </div>
            );
         },
      }  
  ],[flagMan,handleOnEdit,handleLockIcon,handleOnEditNote,handleOpenCommune,handleOpenWilaya,handleOpenClasse,handleSelect,note,Icon,classes,Item,ItemN,selecto,wilayas,communes,classe]);
  
 

  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,'get')
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      })  
    }   
  }

  const handleSearch=async( size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,'get')
    if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
  }
    return recherche
  }

  function handlePageChange (page) {
    fetch(page,service,perPage);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,service, newPerPage);
    setPerPage(newPerPage);
  };
  
  //Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }

  function liveSearch2() {
    handleSearch();
  }
  const ExpanableComponent =({data}) => {
    const [comm,SetComm]=useState([])
  
    //recuperer les utilisateurs
 const fetchCom= useCallback(async() => {
      const response= await Gfunc.axiosGetAction('_usr.php',"gets");
      Gfunc.axiosResponse(response,()=>{
          response.data.jData.unshift({id:'empty',name:'Aucun'})
          SetComm(response.data.jData);   
      })
  },[Gfunc,param.urlService]);

     //remplir Select commercial
    const handleOpenComm= useCallback(async () => {
      if(comm.length===0)
       fetchCom()
    },[comm,fetchCom])

    return (
      <div className="row-expenderr">
        <div id='info-expend'>
           <div id='item'>
              <Limit style={{height:'20px',width:'20px'}}/>
              <p>Seuil</p>
              <InputNbrEditable service={service} action='upd' min={1} className="content-editable-exp"
                row={data} att="seuil" value={data.seuil} id='DT_RowId' idi={data.DT_RowId} handleAlert={handleAlert}
              />
           </div>
           <div id='item'>
              <Commercial  style={{height:'20px',width:'20px'}}/>
              <p>Commercial</p>
              <AutocompleteS service={service} action={'upd'} className="content-editable-exp"
                  defaultValue={data.mesure} row={data} list={comm} id={'commercialId'}  name='commercial' attr='name' 
                  onOpen={handleOpenComm} setLoading={setLoading} setItem={setItem} setSelecto={setSelecto}  handleAlert={handleAlert}/>
           </div>
           <div id='item'>
              <Person style={{height:'20px',width:'20px'}}/>
              <p>{data.agent}</p>
           </div>
        </div>
      </div>
    )
  }

  
  return (
    <div className="content">
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <div className='table-header'>
          <div className='button-flex-wrapper' ><Dialog handleAlert={handleAlert} callback={()=>fetch(1)} media={matches} val='clt'/></div>
          {(doSearch)?
            <div className='search' >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer"}} />),}}
                               size="small" margin='none'  onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} /> 
              </Box>
            </div>:null}
        </div>
        <div id={(matche)?"":"cli"} >
        {(data.length!==0||doSearch)?
        <div id='exp-DT'>
          <DataTable 
              persistTableHead
              columns={(JSON.parse(param.gros)===false)?columns1:columns}
              data={data}
              noHeader={true}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              expandOnRowClicked
              expandableRows 
              expandableRowsComponent={ExpanableComponent}
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
        /></div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </div>
    </div> 

  );
}
