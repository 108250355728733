import React,{useState,useMemo,useCallback} from 'react';
import {DialogContent,Paper,Button,Dialog,FormControlLabel,Checkbox,IconButton,Typography, Tooltip} from '@material-ui/core';
import DataTable from "react-data-table-component";
import Draggable from 'react-draggable';
import {IndeterminateCheckBox,Add,Close} from '@material-ui/icons';
import {axiosResponse,axiosPostAction,removeItemsByAtt} from'../../../Gfunc'
import Multiselect from 'multiselect-react-dropdown';
import { useStyles,paperPropss, multiSelect, paperPropsAd, paperProps, paperPropsBigH } from '../../../css/muiStyle';
import {customStyles}from '../../../css/datatable-custom'
import {AlertCompo} from "../../../js/components"
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import {AddBusiness} from '@mui/icons-material';
import Icon from '@mdi/react';
import { mdiFormatListGroup } from '@mdi/js';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//the dialog that adds users to a role**********
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Adm_usr_mags(props) {
    const classes=useStyles();
    const param=useContext(ParamContext);
    const [open, setOpen] = useState(false);
    const [option, setOption] = useState([]);
    const [data, setData] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    var users =[]
    const service="_usr.php"

    //fetch the mag that have the selected user
    const fetch= useCallback(async() => {
      const data={idu:props.row.DT_RowId.substr(1),unsets:false}
      const response=await axiosPostAction(service,"getumags",data)
      if(response.data.err&&response.data.err!=='-1'){
        props.handleAlert(true,"error",response.data.err)
        setOpen(false)
        return false
      }else
         if(response.data.jData!==undefined)
          axiosResponse(response,()=>{
            if(response.data.jData){
                setData(response.data.jData);
            }
          return true
          })
    },[props])

    //fetch all magasins that doesn't have the selected role
    const fetchMags=useCallback(async() => {
      const data={idu:props.row.DT_RowId.substr(1),unsets:true}
      const response=await axiosPostAction(service,"getumags",data)
            if(response.data.err&&response.data.err!=='-1'){
              props.handleAlert(true,"error",response.data.err)
              //setOpen(false)
            }else
              if(response.data.jData!==undefined){
                axiosResponse(response,()=>{
                setOption(response.data.jData);
                })
              }
    },[props])

    const handleClickOpen = () => { 
      fetchMags();
      fetch(); 
      setOpen(true)
    };

    const handleClose = () => { setOpen(false); };

   const handleAlert=(open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

    //Multiselect functions********************
    function onSelect(selectedList, selectedItem) {
      users=selectedList;
      setSelectedValues(users)
    }
     //suppression des options
      function onRemove(selectedList, removedItem) {
       setSelectedValues(selectedList)
    }
  
  //Add users to role
  const handleAddMag=useCallback(async() => {
     if(selectedValues.length>0){
      var idrss=[]
      selectedValues.map((opt)=>{
        idrss.push(opt.id) 
        return true 
      })
      const response= await axiosPostAction(service ,"setumags",{idu:props.row.DT_RowId.substr(1),idrs:idrss})
        axiosResponse(response,()=>{
          if(response.data.jData){
            handleAlert(true,"success","L'utilisateur a été attribués avec succès aux magasins sélectionnés.")
            selectedValues.map((item)=>{
              removeItemsByAtt(option,item.id,"id")
              data.push(item)
              return true;
            })
            props.callback();
          }
        }) 
        setSelectedValues([])
        
      }else {handleAlert(true,"warning","Vous devez sélectionner au moins un élement!")}
   },[data,option,selectedValues,service,props.row.DT_RowId])
   
  
  //Delete magasin from users
  const handleDeleteMag=useCallback(async(row)=>{
    const response =await axiosPostAction(service,"delumag",{idu:props.row.DT_RowId.substr(1), idr:row.id});
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
        axiosResponse(response,()=>{
          if(response.data.jData){
            handleAlert(true,"success","Le magasin a été supprimé avec succès .")
            fetch();
            props.callback();
          }
          
        })
  },[props])
  
  const handleChange=async(val,row)=>{
    const mag=localStorage.getItem('magid')
    const response =await axiosPostAction(service,"setacc",{userId:parseInt(props.row.DT_RowId.substr(1)),magId:row.id,itIs:val});
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
        axiosResponse(response,()=>{
          if(response.data.jData===true){
            row.actionnaire=val
          }else{
            row.actionnaire=!val
          }
          
        })
  }

  const columns =useMemo(
    () => [   
      {
          name: "Magasin",
          selector:row=> row.name,
          sortable: true,
          minWidth:'300px !important'
      },
      {
          name: "Actionnaire",
          minWidth:'150px !important',
          cell: (row) => {
            return (
              <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={row.actionnaire}
                  onChange={e=>handleChange(e.target.checked,row)}
                  name="checked"
                  color="primary"
              />
                    }
            />
          );
        },
      },
      {
          right:true,
          cell: (row) => {
            return (
              <div onClick={()=>handleDeleteMag(row)} title="Supprimer magasin">
                 <IndeterminateCheckBox className={classes.redIcon} fontSize="small"/>
              </div>   
            );
          },
      }  
    ],[handleDeleteMag,classes]);
 
  return (
    <div>
         <Tooltip title="Affecter des magasins">
            <div >
               <AddBusiness className={classes.blueIcon} onClick={handleClickOpen} style={{heigth:'23px',width:'23px'}}/>
            </div>
         </Tooltip>
            <Dialog
                open={open}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                PaperProps={paperPropsBigH}
            >
                <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
                  <div className='DialogTitle'>
                    <Icon path={mdiFormatListGroup} size={1} />
                    <label> Les magasins du <b>{props.row.user}</b></label>
                  </div>
                </DialogTitle>
                <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
                <DialogContent>
                <fieldset id="fieldSet">
                {(option.length!==0)?
                   <div className='flex-row'>
                       <Multiselect
                            style={multiSelect}
                            options={option} 
                            selectedValues={selectedValues}
                            onSelect={onSelect} 
                            onRemove={onRemove} 
                            emptyRecordMsg="Il n'y a plus d'options"
                            displayValue="name" 
                            placeholder="Selectioner les magasins"
                            closeOnSelect={false}
                            hidePlaceholder={true}
                      />
                    <div>
                    <Button className={classes.PrintBtn} variant="contained" onClick={handleAddMag} color="primary" startIcon={<Add/>}>AJOUTER</Button></div>
                    </div>:<></>
              }
              </fieldset>
            <div id='stk-usr'>
            <DataTable 
                  className="table"
                  persistTableHead
                  columns={columns}
                  data={data}
                  noHeader={true}
                  customStyles={customStyles}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
               />
           </div>
        </DialogContent>
      </Dialog>
      </div>
  );
}
