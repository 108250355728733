import React from "react";
import ReactToPrint from "react-to-print";
import LogoBq from '../img/logos/logo_bq.png';
import {useStyles} from '../css/muiStyle';
import {Button,Tooltip, Typography} from '@material-ui/core';
import {Print} from '@material-ui/icons';
import  '../css/retour.css';
import '../css/global.css'
import '../css/ticket-bon.css'
//import QRCode from "react-qr-code";
import { BackdropCompo} from "./components";
import '../css/irh.css'
import ParamContext from "../Contexte";
import { useContext } from "react";

export default function  PrintDig (props) {
  const componentRef = React.useRef(null);

  const onBeforeGetContentResolve = React.useRef(null);

  const [loadingDig, setloadingDigDig] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const [detail, setDetail] = React.useState({});
  const Gfunc=require('../Gfunc')
  const mdi=require('@mdi/js');
  const classes=useStyles();
  const [error, setError] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const param=useContext(ParamContext);
  const [date,setDate]=React.useState('')



  const fetchNow= React.useCallback(async() => {
    const response=await Gfunc.axiosGetAction('getNow.php',"")
    if(response.data.err&&response.data.err!=="-1"){
      //handleAlert(true,"error",response.data.err)
    }else{
        Gfunc.axiosResponse(response,()=>{
            if(response.data.jData){
              setDate(response.data.jData);
            }
        })
      } 
},[Gfunc]);

  const handleError=()=>{
    setError(true)
  }

  const handleLaoad=()=>{
    setLoad(true)
  }

  const handleAfterPrint = React.useCallback(() => {
    //console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    setloadingDigDig(false);
    //console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(async() => {
    //console.log("`onBeforeGetContent` called");
    setloadingDigDig(true);
    await fetchNow()
    setText("loading new text...");
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setloadingDigDig, setText, setDetail,fetchNow]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    return (
        (props.type==="button")?<Button variant="contained" color="primary" startIcon={<Print fontSize="small"/>}>Imprimer</Button>:<div id="imprDig" hidden={props.hidden}><Print className={classes.blueIcon} /></div>
    );
  }, []);

  function TicketIdoomAT(props){

    return(
      <div ref={componentRef} style={{fontFamily:"Arial"}} >
                    <div className="bon_digit" style={{display:'flex',gridGap:"5px",width:"5.2cm"}}>
                      <img src={(props.row.topLogo)?param.imgApp+props.row.topLogo:""} style={{height:"50px"}} onError={errors => {/*console.log(errors)*/}}/>
                     <div style={{justifyContent: "end", display:"flex", width:"90px"}}> <img id='logo_ticket' src={(error===false&&load===true)?param.logo+localStorage.getItem('logo'):LogoBq} onLoad={handleLaoad}  onError={handleError}/></div>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'55% 40%',fontSize:"12px",width:'5.2cm'}}>
                        <div style={{textAlign:'left'}}>
                            {date.day}
                        </div>
                        <div style={{textAlign:'right'}}>
                            {date.time}
                        </div>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'55% 40%',fontSize:"12px",width:"5.2cm"}}>
                        <div style={{textAlign:'left'}}>
                          {localStorage.getItem("mag")}
                        </div>
                        <div style={{textAlign:'right'}}>
                        </div>
                    </div>
                    <div id='p1'>
                      {props.row.digit}
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'40% 60%',fontSize:"11px",width:'5cm',marginBottom:"3px"}}>
                        <div style={{textAlign:'left'}}>
                           TTC:{props.row.prix} DA
                        </div>
                        <div style={{textAlign:'right'}}>
                           Expire: {props.row.expire}
                        </div>
                    </div>
                    <div style={{fontSize:'12px',marginBottom:'5px'}}>N° Série :{props.row.serial}</div>
                    <div style={{textAlign:'center',width:'5cm',marginBottom:"3px" ,height:"40px"}}>
                      {(props.row.digit&&(props.row.digit.toLowerCase().includes("4g") || props.row.digit.toLowerCase().includes("idoom")))?
                    <img src={param.imgApp+props.row.centerLogo} style={{height:props.row.digit.toLowerCase().includes("4g")?"40px":"30px"}}/>:null}</div>
                    <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}>CODE</div>
                    <div style={{textAlign:'center',fontSize:'18px',width:'5cm',fontWeight:'bold'}}>{props.row.code}</div>
                    <div id='p1'></div>
                    <div style={{fontSize:"12px"}}>لتعبئة حسابكم للانترنيت ،اتصل بالرقم : 1500</div>
                    <div style={{textAlign:'center',fontSize:'13px',width:'5.2cm'}}>اتصالات الجزائر غير مسؤولة عن التذكرة</div>
                    <div style={{textAlign:'center',fontSize:'13px',width:'5.2cm',marginBottom:"30px"}}>الغير مطبوعة امامكم</div>
      </div>
    )

  }
  

  //ticketg de idoom et idoom 4g 
  function TicketStandard(props){
    return (
      <div ref={componentRef}  id={(localStorage.getItem('tiketWidth')==="1")?"digCont1":"digCont"}>
        <div id={(localStorage.getItem('tiketWidth')==="1")?'ticket-dig1':'ticket-dig'}>
          <div>
              <p id='entet-tick-dig'>{localStorage.getItem('entet_tiket_1')}</p>
              <p id='entet-tick-dig'>{localStorage.getItem('entet_tiket_2')}</p>
          </div>
          <img src={(error===false&&load===true)?param.logo+localStorage.getItem('logo'):LogoBq} id='logo_ticket' alt='logo'  onLoad={handleLaoad}  onError={handleError} />
        </div>
        <hr size="1" color='black'/>
         <div id={(localStorage.getItem('tiketWidth')==="1")?'digit-inf1':"digit-inf"}>
          <div id='row1'>{'le '+date.day+' à '+date.time}</div>
           <Typography variant="h5" component="div" id='digit-name'>{props.row.digit} </Typography>
           <div id='code'>{props.row.code}</div>
           <div id='row'><b>N° serie : </b>{props.row.serial}</div>
           <div style={{textAlign:'left'}}>
                           TTC:{props.prx} DA
                        </div>
  
         </div>
        <hr size="1" color='black'/>
        <p id='footer-digit'><b>Merci pour votre fidélité</b></p>
        <p id={(localStorage.getItem('tiketWidth')==="1")?"footer-digit2":"footer-digit1"}>{localStorage.getItem('entet_tiket_2')}</p>
        </div>
    )
  }

  //Ticket Ooredoo
  function TicketOoredoo(props){
    return (
      <div ref={componentRef} style={{fontFamily:"Arial"}} >
          <div style={{width:'5.2cm',textAlign:'center',marginBlock:'6px'}}>{<img src={(error===false&&load===true)?param.logo+localStorage.getItem('logo'):LogoBq} style={{height:"40px"}} onLoad={handleLaoad}  onError={handleError}/>}</div>
          <div style={{display:'grid',gridTemplateColumns:'55% 40%',fontSize:"12px",width:'5.2cm'}}>
              <div style={{textAlign:'left'}}>{date.day}</div>
              <div style={{textAlign:'right'}}>{date.time} </div>
          </div>
          <div style={{display:'grid',gridTemplateColumns:'55% 40%',fontSize:"12px",width:"5.2cm"}}>
              <div style={{textAlign:'start'}}>{localStorage.getItem("etiquette")} </div>
          </div>
          <div id='p1'>{props.row.digit}</div>
          <div style={{display:'grid',gridTemplateColumns:'48% 48%',gridGap:'5px',fontSize:"11px",width:'5cm'}}>
              <div style={{textAlign:"start"}}>
                  <p>N° Série :</p>
                  <p>Expire: </p>
                  <p>Montant TTC:</p>
              </div>
              <div style={{textAlign:"end"}}>
                  <p>{(props.row.serial)?props.row.serial:"--"}</p>
                  <p> {props.row.expire}</p>
                  <p>{props.row.prix} DA </p>
              </div>
          </div>
          <div style={{textAlign:'center',width:'5cm',marginBottom:"3px" ,height:"40px"}}>
              <img src={param.imgApp+"logo_ooredoo.png"} style={{height:'100%'}} /></div>
          <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}>CODE</div>
          <div style={{textAlign:'center',fontSize:'18px',width:'5cm',fontWeight:'bold'}}>{props.row.code}</div>
          <div id='p1'></div>
          <div style={{textAlign:'center',fontSize:'11px',width:'5.2cm'}}>Composer *222* Code ci-dessus # OK</div>
          <div style={{fontSize:"11px",textAlign:"center",fontWeight:'normal',marginTop:'5px',width:"5.2cm"}}>Ooredoo n'est pas responsable des  </div>
          <div style={{fontSize:"11px",textAlign:"center",marginBottom:"50px",width:"5.2cm"}}>tickets non imprimes devant vous</div>
     </div>
  )
  }

function TicketDjezzy(props){
  return (
    <div ref={componentRef} style={{width:'5.2cm',fontFamily:'Arial'}} >
    <div style={{width:'100%',marginTop:'10px',display:'grid',gridTemplateColumns:'50% 50%'}}>
      <div style={{textAlign:'start'}}><img src={param.imgApp+"logo_djezzy.png"} style={{height:"40px"}}/></div>
      <div style={{textAlign:'end'}}>{<img src={(error===false&&load===true)?param.logo+localStorage.getItem('logo'):LogoBq} style={{height:"40px"}} onLoad={handleLaoad}  onError={handleError}/>}</div>    </div>
        <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}></div>
        <div style={{display:'block',textAlign:'start',fontSize:'11px'}}>
            <p style={{marginBottom:'3px'}}>Magasin : <b>{localStorage.getItem("etiquette")}</b></p>
            <p style={{marginBottom:'3px'}}>Date : <b>{date.day+' '+date.time}</b></p>
        </div>
        <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}></div>
        <div style={{display:'grid',gridTemplateColumns:'48% 48%',fontSize:'11px'}}>
            <div >
                <p>N° Série :</p>
                <p>Expire le : </p>
                <p>Montant TTC :</p>
            </div>
            <div >
                <p><b>{(props.row.serial)?props.row.serial:'--'}</b></p>
                <p><b>{(props.row.expire)?props.row.expire:'--'}</b> </p>
                <p><b>{(props.row.prix)?props.row.prix:0} DA</b></p>
            </div>
        </div> 
        <p style={{fontSize:'13px',margin:'5px 0',textAlign:'center'}}><b>Recharge {props.row.digit} DA</b></p>
        <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}>
          <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}>CODE RECHARGE</div>
              <div style={{textAlign:'center',fontSize:'18px',width:'5cm',fontWeight:'bold'}}>{props.row.code}</div>
        <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}></div>
        <div style={{fontSize:"12px",textAlign:"center",fontWeight:'bold'}}>Composez</div>
        <div style={{fontSize:"12px",textAlign:"center",fontWeight:'bold'}}>*700* les 14 chiffres #</div>
        <div style={{fontSize:"11px",textAlign:"center",marginTop:'5px'}}>Djezzy n'est pas responsable des  </div>
        <div style={{fontSize:"11px",textAlign:"center",marginBottom:"50px"}}>tickets non imprimes devant vous</div>
   </div>
   </div>
  );
}

function TicketMobilis(props){
  return (
    <div ref={componentRef} style={{fontFamily:"Arial"}} >
        <div style={{width:'5.2cm',textAlign:'center',marginBottom:'6px'}}>
            <img src={(error===false&&load===true)?param.logo+localStorage.getItem('logo'):LogoBq} style={{height:"40px"}} onLoad={handleLaoad}  onError={handleError}/></div>
        <div style={{display:'grid',gridTemplateColumns:'55% 40%',fontSize:"12px",width:'5.2cm'}}>
            <div style={{textAlign:'left'}}>{date.day}</div>
            <div style={{textAlign:'right'}}>{date.time} </div>
        </div>
        <div style={{display:'grid',gridTemplateColumns:'55% 40%',fontSize:"12px",width:"5.2cm"}}>
            <div style={{textAlign:'start'}}>{localStorage.getItem("etiquette")}</div>
        </div>
        <div id='p1'>{props.row.digit}</div>
        <div style={{display:'grid',gridTemplateColumns:'48% 48%',gridGap:'5px',fontSize:"11px",width:'5cm',marginBottom:"3px"}}>
            <div style={{textAlign:"start"}}>
                <p>N° Série :</p>
                <p>Expire: </p>
                <p>Montant TTC:</p>
            </div>
            <div style={{textAlign:"end"}}>
                <p>{(props.row.serial)?props.row.serial:"--"}</p>
                <p> {props.row.expire}</p>
                <p>{props.row.prix} DA </p>
            </div>
        </div>
        <div style={{textAlign:'center',width:'5cm',marginBottom:"3px"}}>
            <img src={param.imgApp+"logo_mobilis.png"} style={{height:'40px'}}/></div>
        <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}>CODE</div>
        <div style={{textAlign:'center',fontSize:'18px',width:'5cm',fontWeight:'bold'}}>{props.row.code}</div>
        <div id='p1'></div>
        <div style={{textAlign:'center',fontSize:'11px',width:'5.2cm',marginBottom:"50px"}}>Composer *111* Code ci-dessus # OK</div>
   </div>
);
}



  return (
    <div style={{display: "grid",gridTemplateRows: "25px"}}>
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle="AwesomeFileName"
        onAfterPrint={handleAfterPrint}
        onBeforeGetContent={handleOnBeforeGetContent}
        onBeforePrint={handleBeforePrint}
        removeAfterPrint= {false}
        trigger={reactToPrintTrigger}
      />
      <BackdropCompo className={classes.backdropDiag} open={loadingDig}/>
      <div hidden className="bon_digit">
      <div>
      {((props.row.digit&&(props.row.digit.toLowerCase().includes("4g") ||props.row.digit.toLowerCase().includes("idoom"))))?
        <TicketIdoomAT row={props.row} prx={props.prx}/>
      :(props.row.digit&&props.row.digit.toLowerCase().includes("mobilis"))?
        <TicketMobilis row={props.row} prx={props.prx}/>
      :(props.row.digit&&props.row.digit.toLowerCase().includes("ooredoo"))?
          <TicketOoredoo  row={props.row} prx={props.prx}/>
      :(props.row.digit&&props.row.digit.toLowerCase().includes("djezzy"))?
          <TicketDjezzy  row={props.row} prx={props.prx}/>
      : <TicketStandard logo={props.logo} row={props.row} prx={props.prx}/>        
      }
      </div>
      </div>
      </div>
  );
};
