import React,{useState, useMemo, useCallback} from 'react';
import {DialogContent,Paper,Dialog,Fab,Box,TextField,CircularProgress,FormControlLabel,Checkbox,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import { useStyles,paperProps } from '../../../css/muiStyle';
import { AlertCompo} from '../../../js/components';
import DataTable from "react-data-table-component";
import { customStyles, numberColumn } from '../../../css/datatable-custom';
import { Search,Close } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiCash } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


//add new bon
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-SldClt" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Vnt_cpt_sldClt(props) {
    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const [loading, setLoading] =useState(false);
    const [search,setSearch] =useState('');
    const [total,setTotal] =useState(0);
    const [totalPage,setTotalPage] =useState(0);
    const [open, setOpen] =useState(false);
    const [data, setData] =useState([]);
    const [onTop, setOnTop] =useState(false);
    const [all, setAll] = useState([]);
    const [userL, setUserL] = useState([]);
    const addTitle="Soldes Clients"
    const classes=useStyles();
    const [client,setClient]=useState('');
    const service="_cli.php"
    const [openA, setOpenA] =useState(false);
    const [message, setMessage] =useState('');
    const [severity, setSeverity] =useState('info');

    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    const fetch = async() => { 
      setLoading(true)
        const response = await Gfunc.axiosGetAction(service,"getsolds")
        if(response.data.err){
          props.handleAlert(true,'error',response.data.err)
          setOpen(false)
        }else
        Gfunc.axiosResponse(response,()=>{
            setAll(response.data.jData.soldes)
            setData(response.data.jData.soldes)
            setTotal(response.data.jData.total) 
            setTotalPage(Gfunc.sumArObj(response.data.jData.soldes,'solde')); 
        })
        setLoading(false)
    };

    const handleClickOpen = () => { 
      fetch();
      handleOpenUser()
      setSearch('')
      setOnTop(false)
      setOpen(true); 
    };
    

    const handleClose = () => { setOpen(false); setData([]); setAll([]); setTotal(0);setTotalPage(0)};
    

    const handleSearchAll=(from,val)=>{
        var d=all
         if(search!==''){
          const filters=[]
          var keys=[] 
          keys=Object.keys(all[0])
            keys.map( key =>{ 
              const f={}  
              f["att"]=key
              filters.push(f)
              return true
            }) 
          d=all.filter((d)=>filters.some(filterEl =>{if(d[filterEl.att]!=null) return((d[filterEl.att].toString().toLowerCase()).includes(search.toLowerCase()))}))
         }
         if(from==='onTop') {if(!val && d.length>0){d=d }else{d=d.filter(elem=>{return(elem.onTop===true)})}; setOnTop(val)}
         else
         if(!onTop && d.length>0){ d=d }else{d=d.filter(elem=>{return(elem.onTop===true)})}
         if(from==='fComm') if(!val && d.length>0){ d=d} else {d=d.filter(elem=>{return(elem.ownerId===val)})}
         else
         if(!client && d.length>0){ d=d} else {d=d.filter(elem=>{return(elem.ownerId===client)})}
         setData(d)
         setTotalPage(Gfunc.sumArObj(d,'solde'));
    }

    function liveSearch(e){
        if(search.length>-1){
          e.preventDefault();
          handleSearchAll();
        }
    }

     //fetch client 
   const fetchUser= useCallback(async() => {
    const response = await Gfunc.axiosGetAction("_usr.php","gets")
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      const dat=response.data.jData
      dat.unshift({id:null,name:"Tous"})
      setUserL(dat);   
    })
  },[Gfunc]);

   //handle the call for fetch client
   const handleOpenUser=()=>{
    if(userL.length===0)
    fetchUser();
   }

    const columns =useMemo(
        () => [
          {
              name: "nom",
              selector: row=>row.nom,
              sortable: true,
              minWidth:'150px !important',
              style:{
                justifyContent:"flex-start !important",
                width:"100%"
              }
          },
          {
              name: "Solde",
              selector: row=>row.solde,
              sortable: true,
              style:numberColumn,
              minWidth:'110px !important',
              cell:(row)=>{
                  return(
                      <div>
                          {new Intl.NumberFormat().format(row.solde)} 
                      </div>
                  )
              }
          },
          {
              name: "Commercial",
              selector: row=>row.owner,
              sortable: true,
              minWidth:'150px !important',
          },
          {
              name: "dernier",
              selector: row=>(row.lastTiket)? Gfunc.englishDate(row.lastTiket,"00:00"):null,
              sortable: true,
              minWidth:'82px !important',
              cell:(row)=>{
                return(
                    <div>
                        {row.lastTiket}
                    </div>
                )
            }
              
          },
          {
              name: "note",
              minWidth:'220px !important',
              selector: row=>row.note,
              sortable: true,
          }
        ],[Gfunc]); 
 
  return (
    <>
    <Fab className={classes.sideBtn} variant="extended" size="small" color="secondary" onClick={handleClickOpen} > Soldes clients</Fab>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}
      >
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
           <div className='DialogTitle'>
              <Icon path={mdiCash} size={1} />
              <label>{addTitle}</label>
            </div>
        </DialogTitle>
            <DialogContent >
       { /*<p className="paperTitle">Liste des bons d'achat</p>*/}
        <div className='table-header'>
            <div className="button-flex-wrapper"> 
               <FormControlLabel label="Surveillé" control={<Checkbox checked={onTop} color="primary" className={classes.checkBox} 
                                         onChange={()=>handleSearchAll('onTop',!onTop)} inputProps={{ 'aria-label': 'controlled' }}  />}
                                          /> 
               <Autocomplete
                                disableClearable
                                id="iClient"
                                className="smallAutoFiltre"
                                onChange={(e,newValue) =>{
                                  //const idf=props.userL[Gfunc.getIndex(props.userL,newValue,"name")].id
                                    setClient(newValue.id)
                                    handleSearchAll("fComm",newValue.id)
                                  }}
                                options={userL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => (option.id=== value.id)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"COMMERCIAL"}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"/>)}/>
            </div>  
            <div className='search'>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField className={classes.search} variant="outlined" id="input-with-sx" label="Recherche" value={search} 
                             size="small" margin='none' inputProps={{className:classes.diagFormInput}}
                             onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} 
                             InputProps={{startAdornment: (<Search style={{ marginRight: '5px',zIndex:'1000' }} />),}}
                   />
            </Box>  
             </div> 
            </div>  
              <div className='paperTotal'>
                  <div className='totalPage'>
                    Total de la page: {new Intl.NumberFormat().format(totalPage)} 
                  </div>
                  <div className='total'>
                    Total: {new Intl.NumberFormat().format(total)} 
                  </div>
              </div> 
              <div id="vntDT">
                <DataTable 
                  persistTableHead
                  columns={columns}
                  data={data}
                  noDataComponent={param.ErreurData}
                  paginationRowsPerPageOptions={[5,10,20,30,40,50,100]}
                  pagination
                  progressPending={loading}
                  progressComponent={<CircularProgress className={classes.circularProgress} />}
                  noHeader={true}
                  highlightOnHover
                  customStyles={customStyles}
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}            
                />
              </div>
                
            </DialogContent>
      </Dialog>
      </>
  );
}
