import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField,Checkbox,FormControlLabel,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {PostAdd,Add,Close} from '@material-ui/icons';
import  {useStyles,paperPropsSmallTall} from '../../../css/muiStyle';
import {AlertCompo} from "../../../js/components"
import Autocomplete from '@material-ui/lab/Autocomplete';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import axios from "axios"
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}
 
export default function Ref_inv_add1(props) {
    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const [name,setName]=useState('');
    const [open, setOpen] = useState(false);
    const [select,setSelect]=useState('');
    const [data,setData]=useState([]);
    const [checked,setcheckedB]=useState(true);
    const service="_inv.php"
    const classes = useStyles();
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');

    const handleClickOpen = () => { 
      setName('');
      setOpen(true); 
      setSelect('')
      fetchCat();
    };
    const handleClose = () => { setOpen(false); };
  
    const fetchCat= useCallback(async() => {
      const response = await axios.get( param.urlService+"_cat.php?do=gets" ,{ headers: Gfunc.getHeader()});
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          setData(response.data.jData);
        } 
      })
    },[Gfunc,param.urlService]);
   
    const handleSubmit= async(e) =>{
      if(select!==''&&name!==''){
      const objIndex =  data.findIndex((obj => obj.nom===select )); 
      const sel=data[objIndex].id
      const respon=await Gfunc.axiosPostAction(service,"add",{name:name,cat:sel,activonly:!checked})
        if(respon.data.err&&respon.data.err!=='-1'){
          handleAlert(true,"error",respon.data.err)
        }else Gfunc.axiosResponse(respon,()=>{
          if(respon.data.jData){
               setOpen(false);
                props.handleAlert(true,"success","L'inventaire est ajouté")
                props.callback();}
        })
      }
    }
  
  const handleChange=(e)=>{
    setcheckedB(!checked)
  }
   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<PostAdd/>} className={classes.topTabBtn}>Inventaire de catégorie</Button>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
      >
      <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
            <div className='DialogTitle'>
              <Icon path={mdiPlusBox} size={1} />
              <label> Générer un inventaire de catégorie</label>
            </div>
      </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
        <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} >
            <DialogContent>
                  <TextField label='Nom' id="iName" name="iName" value={name}  className='smallText' margin='dense' variant='outlined' 
                             onChange={e =>setName(e.target.value)} 
                             errorMessages={["Veuillez renseigner le nom de l'inventaire"]} autoFocus required
                  />
                  <Autocomplete
                    disableClearable
                    className='smallAuto'
                    onChange={(e,newValue) =>{setSelect(newValue)}}
                    options={data.map((option) => option.nom)}
                    renderInput={(params) => <TextField  {...params} margin='dense'  size='small' variant="outlined"  label="Catégorie" 
                                         required SelectProps={{ MenuProps: {closeAfterTransition:true } }}/>}
                    />
                  <FormControlLabel control={ <Checkbox checked={checked} onChange={e=>handleChange(e)} name="checked" color="primary"/>}
                                    label="Inclue les produits innactifs"/>
           </DialogContent>
            <DialogActions>
                <Button variant="contained" type='submit' color="primary" startIcon={<Add />}>Ajouter</Button>
           </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
