//import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import {theme} from "./theme"

export const useStyles = makeStyles((theme) => ({
  checkboxColor: {
    color: theme.palette.secondary.dark
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: '100%',
  },
  greenText:{
    width: "fit-content",
    height:"fit-content",
    color:"green",
 },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    color: "black",
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  switch_track: {
    backgroundColor: theme.palette.secondary.dark,
  }, 
  switch_base: {
    color: "white",
    "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.light,
    }
},
switch_primary: {
    "&.Mui-checked": {
        color: theme.palette.primary.light,
    },
},
search:{
  background:"white",
  color:"black",
  marginLeft:'5px',
  minWidth:"100px",
 },
searchInput:{
    background:"white",
    color:"black",
   },
   //accordion style
accordionRoot:{
    width:"98%",
    marginLeft:"1% !important",
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded':{
      margin:0,
      marginLeft:"1% !important",
    }
  },
accordionSummary:{
    height:"30px !important",
    minHeight:"30px !important",
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)': 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
    "&.MuiAccordionSummary-root":{
      '&.Mui-expanded':{
        height:"30px !important",
        minHeight:"30px !important",
      }
      
    }
  },
  accordionDetails:{
    padding: "5px 10px",
    background:'#C7C7C7',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  },
  accordionDetailss:{
    padding: "5px 10px",
    background:theme.palette.secondary.light,
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  circularProgress: {
    color: theme.palette.primary.main,
  },
  checkStelectableRow: {
    transform:"scale(0.8)",
    "&.MuiCheckbox-root": {
      '&.Mui-checked':{
        color:theme.palette.primary.main,
      }
    }, 
  },

  //mydesign
  topTabBtn: {
    textDecoration: 'none',
    padding: '2px 10px',
    margin: '2px 3px',
    border: "solid 1px #f1f1f1",
    borderRadius:'10px',
    boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
    '&:hover': {
      backgroundColor: 'gray',
      border: "solid 1px gray"
    },
  },
  
  topTabButton: {
    textDecoration: 'none',
    padding: '2px 10px',
    margin: '2px 0px',
    border: "solid 1px #f1f1f1",
    borderRadius:'10px',
  },
  BtnPay: {
    textDecoration: 'none',
    padding: '0px 10px',
    margin: '2px 3px',
    border: "solid 1px gray",
    borderRadius:"10px"
  },
  //mydesign
  TabBtn: {
    //textDecoration: 'none',
    padding: '2px 10px',
    //margin: '2px 3px',
   // border: "solid 1px gray",
  },
  racBtnGrey: {
    textDecoration: 'none',
    padding: '3px 0px',
    margin: '3px 2px',
    border: "solid 1px gray",
    width:'75px', 
    fontSize:"9px",
    display: 'unset',
    background:theme.palette.secondary.main,
    color:"black",
    "&:hover":{
      background:theme.palette.secondary.dark,
    },
    //textTransform: "capitalize"
  },
  racBtnRed: {
    textDecoration: 'none',
    padding: '3px 0px',
    margin: '3px 1px',
    border: "solid 1px gray",
    width:'59px', 
    minWidth:'50px', 
    fontSize:"9px",
    display: 'unset',
    background:theme.palette.primary.main,
    color:"white",
    "&:hover":{
      background:'#791915',
    },
    //textTransform: "capitalize"
  },
  racBtnRedBig: {
    textDecoration: 'none',
    padding: '3px 0px',
    margin: '3px 1px',
    border: "solid 1px gray",
    width:'75px', 
    minWidth:'50px', 
    fontSize:"9px",
    display: 'unset',
    background:theme.palette.primary.main,
    color:"white",
    "&:hover":{
      background:'#791915',
    },
    //textTransform: "capitalize"
  },
  racClickedRed: {
    background:theme.palette.secondary.dark,
  },
  racClickedGrey: {
    background:theme.palette.primary.light,
  },
  sideBtn: {
    textDecoration: 'none !important',
    padding: '2px auto !important',
    margin: '2px 3px !important',
    width:'100% !important',
    minWidth:"fit-content !important",
    height:"25px !important",
    fontSize:"11px !important",
    display: 'unset !important',
    background:theme.palette.secondary.dark+'!important',
    color:"white !important",
    "&:hover":{
      background:theme.palette.secondary.main+'!important',
    },
    "&:disabled":{
      opacity:0.5,
      color:"black !important"
    }
    //textTransform: "capitalize"
  },
  squarBtn: {
    textDecoration: 'none',
    padding: '10px 0px',
    margin: '2px 3px',
    fontSize:'16px',
    border: "solid 1px silver",
  },
  sideBtnCalc: {
    transform: 'scale(1.4)',
    background:theme.palette.secondary.dark+'!important',
    color:"white !important",
    fontSize:'18px',
    "&:hover":{
      background:theme.palette.secondary.main+'!important',
    },
    //textTransform: "capitalize"
  },
  sideBtnCalcSide: {
    transform: 'scale(1.4)',
    fontSize:'18px'
  },
  racProd: {
    textDecoration: 'none',
    padding: '1px 0px',
    margin: '3px 3px',
    border: "solid 1px gray",
    width:'95px', 
    fontSize:"9px",
    display: 'unset',
    background:theme.palette.secondary.main,
    color:"black",
    "&:hover":{
      background:theme.palette.secondary.dark,
    },
    textTransform: "none !important",
  },
  racProdtouch: {
    textDecoration: 'none',
    padding: '1px 0px',
    margin: '3px 3px',
    border: "solid 1px gray",
    width:'72px', 
    fontSize:"9px",
    display: 'unset',
    background:theme.palette.secondary.main,
    color:"black",
    "&:hover":{
      background:theme.palette.secondary.dark,
    },
    textTransform: "none !important",
  },
  racAir: {
    textDecoration: 'none',
    padding: '1px 0px',
    margin: '3px 3px',
    border: "solid 1px gray",
    width:'95px', 
    fontSize:"9px",
    display: 'unset',
    background:theme.palette.secondary.dark,
    color:"white",
    "&:hover":{
      background:theme.palette.secondary.main,
    },
    textTransform: "none !important",
  },
  racPack: {
    textDecoration: 'none',
    padding: '1px 3px',
    margin: '3px 3px',
    border: "solid 1px gray",
    width:'fit-content', 
    maxWidth:'200px', 
    minWidth:'120px', 
    minHeight:'46px', 
    fontSize:"9px",
    display: 'unset',
    background:theme.palette.secondary.main,
    color:"black",
    "&:hover":{
      background:theme.palette.secondary.dark,
    },
    textTransform: "none !important",
  },
  racAirtouch: {
    textDecoration: 'none',
    padding: '1px 0px',
    margin: '3px 3px',
    border: "solid 1px gray",
    width:'72px', 
    fontSize:"9px",
    display: 'unset',
    background:theme.palette.secondary.dark,
    color:"white",
    "&:hover":{
      background:theme.palette.secondary.main,
    },
    textTransform: "none !important",
  },
  smallBtn: {
    textDecoration: 'none',
    padding: '0px 15px !important',
    height:'25px',
    margin: '5px 3px 2px 3px !important',
    border: "solid 1px gray !important",
    backgroundColor:'#C7C7C7',
    borderRadius:"10px"
  },
  PrintBtn: {
    textDecoration: 'none',
    padding: '0px 15px !important',
    height:'25px',
    margin: '5px 3px 2px 3px !important',
    border: "solid 1px gray !important",
    borderRadius:"10px",
    cursor:"pointer"
  },
  iconBtn: {
    textDecoration: 'none',
    padding: '2px !important',
    height:'fit-content !important',
    margin: '5px 3px 2px 3px !important',
    border: "solid 1px gray !important",
    width: "fit-content !important",
    minWidth:"fit-content !important",
  },
  iconBtnBig: {
    textDecoration: 'none',
    padding: '2px !important',
    height:'27px !important',
    margin: '5px 1px!important',
    border: "none",
    width: "27px!important",
    minWidth:"0 !important",
    background:theme.palette.secondary.main,
  },
  input: {
    padding: "8px 14px",
    background:theme.palette.secondary.light,
    "&:focus":{
      background: "white"
    },
  },
  detBtn:{
   padding:'4px 10px !important',
   textAlign:'center'
  },
  detBtn1:{
   padding:'4px 10px !important',
   left:'130%'
  },
  fieldBtn: {
    textDecoration: 'none',
    padding: '5px 8px 5px 8px',
    margin: '2px 3px',
    border: "solid 1px gray",
    minHeight:"30px",
    height:"32px", 
  },
  formInput1:{
    margin:"10px",
    paddingRight:"10px",
    marginBottom:"50px",
    background:theme.palette.secondary.light,
    width:"95%",
    minHeight:"30px",
    height:"30px",
    minWidth:"100px",
    textAlign:'center'
   
  },
  topTabLink: {
    textDecoration: 'none',
    padding: '0px 5px',
    margin: '2px 3px',
    border: "solid 1px gray",
    backgroundColor: theme.palette.secondary.main,
    color:'black',
    borderRadius:'10%',
    fontWeight:'normal'
  },
  topDivTitle: {
    width: '100% important',
    color:"white",
    cursor: 'move',
    background:theme.palette.secondary.dark,
    textDecoration: 'none',
    padding: '10px !important',
    margin: '0px',
    textAlign: "start",
    alignItems:"center",
    fontSize:"16px",
    fontWeight:"bold !important"
  },
  topDivTitleLogo: {
    width: '100% important',
    color:"white",
    cursor: 'move',
    background:theme.palette.secondary.dark,
    textDecoration: 'none',
    padding: '10px !important',
    margin: '0px',
    textAlign: "start",
    alignItems:"center",
    fontSize:"16px"
  },
  textInput: {
    width: '90%',
    minWidth:"300px",
    background:theme.palette.secondary.light,
    textDecoration: 'none',
    padding: '5px 10px',
   // margin: '0px',
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  textInput2: {
    width: '93%',
    margin:"5px auto",
    background:theme.palette.secondary.light,
    textDecoration: 'none',
    padding: '5px 10px',
   // margin: '0px',
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  textInput3: {
    width: '100%',
    margin:"5px auto",
    padding: '5px 5px',
    background:theme.palette.secondary.light,
    textDecoration: 'none',
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  textInput4: {
    width: '100%',
    margin:"5px auto",
    padding: '5px 10px',
    background:theme.palette.secondary.light,
    textDecoration: 'none',
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    boxSizing: 'border-box !important'
  },
  inputAuto: {
    width: '95%',
    background:theme.palette.secondary.light,
    padding:'0px',
    textDecoration: 'none',
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  inputAutoP: {
    width: '100%',
    background:theme.palette.secondary.light,
    padding:'0px',
    textDecoration: 'none',
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  inputSearch: {
    padding: "0px 0px",
    background:theme.palette.primary.light,
    "&:focus":{
     // background: 'white',
    },
  },
  textInputAuto: {
    padding: '0px',
    background:theme.palette.secondary.light,
    textDecoration: 'none',
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  headFilter:{
  //  paddingRight:"10px",
    width:'250px !important',
    marginLeft:'5px',
    minWidth:"100px",
  },
  headInput:{
  //  paddingRight:"10px",
    width:"95%",
    minHeight:"30px",
    height:"30px",
    minWidth:"100px",
  },
  headInput1:{
  //  paddingRight:"10px",
    width:"95%",
   // minHeight:"30px",
   // height:"30px",
    minWidth:"200px",
  },
  headInputBon:{
    paddingRight:"10px",
    width:"35%",
    minHeight:"30px",
    height:"30px",
    marginLeft:'15px',
    minWidth:"100px",
  },
  selectOpt: {
    width: '90%',
    background:theme.palette.secondary.light,
    textDecoration: 'none',
    padding: '5px 10px',
    margin: '0px',
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  tdIcon:{
    cursor:'pointer'
  },
  tdIconButton:{
    cursor:'pointer',
    background: "white",
    border: "0",
    height: "27px",
    margin: "auto",
    maxWidth: "27px",
    minWidth: "27px",
    boxShadow: "0 0",
  },
  tdIconButton1:{
    cursor:'pointer',
    background: "white",
    border: "0",
    height: "20px",
    margin: "auto",
    maxWidth: "27px",
    minWidth: "27px",
    boxShadow: "0 0",
  },
  tabAppBar:{
    height:"27px  !important",
    minHeight:"27px  !important"
  },
  tabs:{
    height:"27px !important", 
    minHeight:"27px  !important", 
    fontSize:"10px  !important"
  },
  tab:{
    height:"27px  !important",
    minHeight:"27px  !important", 
    fontSize:"9px  !important", 
    width:'60px  !important',
    minWidth:"20px  !important", 
    padding:"2px 0px  !important"
  },
  tabPanel:{
    "&.MuiBox-root":{
      padding:"0px !important"
    }
  },
  cardContent:{
    height:"100%  !important",
    maxHeight:"125px",
    width:"100%  !important",
    padding:"0px !important",
    textAlign:'center  !important',
    display:"grid  !important",
    gridTemplateRows:"127 !important",
    gridTemplateColumns:"30px 2px 85px !important",
    justifyItems: "center  !important",
  },
  backdrop: {
    top:'0px !important',
    zIndex: theme.zIndex.drawer + 1 +"!important",
    color: '#fff',
  },
  backdropDiag: {
    zIndex: theme.zIndex.drawer + 1 +"!important",
    color: '#fff',
    position:"absolute !important",
    top:0,
    left:0
  },
  styleBtn:{
    padding:'8px 14px',
  },
  TableForm:{
    width:'100px'
  },
  paperBody:{
    background:theme.palette.secondary.light,  //"#fff176",
    width:"100%",
    height:"auto",
    paddingBottom:"3px",
    margin:"10px",
    marginTop:"10px",
  },
  Dialog:{
    cursor: 'move', width: '400px' ,
    color:'red'
    },
    fieldSet:{
      margin: 10,
      paddingHorizontal: 10,
      paddingBottom: 10,
      borderRadius: 5,
      borderWidth: 1,
      alignItems: 'center',
      borderColor: '#000'
    },
    legend:{
        position: 'absolute',
        top: -10,
        left: 10,
        fontWeight: 'bold',
        backgroundColor: 'white'
    },
    adornedSatrt:{
        background:theme.palette.secondary.light+ '!important',
        paddingLeft: "5px !important"
    },
    //Icons ...
    redIcon:{
      color:"red",
      cursor:"pointer"
    },
    greenIcon:{
      color:"green",
      cursor:"pointer"
    },
    blueIcon:{
      color:"#0d47a1",
      cursor:"pointer"
    },
    yellowIcon:{
      color:"#ffa000",
      cursor:"pointer"
    },
    grayIcon:{
      color:"gray",
      cursor:"pointer"
    },
    blackIcon:{
      color:"black",
      cursor:"pointer",
      width:'22px',
      height:'22px'
    },
    WhiteIcon:{
      color:"white",
      cursor:"pointer !important",
      width:'22px',
      height:'22px'
    },
    blackIconn:{
      color:"black",
      cursor:"pointer",
      width:'16px',
      height:'16px'
    },
    blackIconAcc:{
      color:"black",
      cursor:"pointer",
      width:'32px',
      height:'32px'
    },
    //edited select textfield...
  selectEdit:{
    minHeight:"10px",
    minWidth:"50px", 
    width:"auto",
    height:"15px",
  },
  multiSelect:{
   width:'95% !important',
  },
  inputEdit: {
    fontSize:"13px",
    margin:"0px",
    padding: "0px 0px",
    paddingLeft:'10px',
    color:"white",
    height:"17px",
    background:theme.palette.primary.dark, 
    "&:focus":{
      background:theme.palette.primary.dark, 
      borderRadius:"5px"
    },
  },
  inputCB: {
    paddingLeft:'10px',
    "&:hover":{
      background:theme.palette.primary.dark, 
      opacity:0.7,
      color:'white', 
    },
    "&:focus":{
      background:theme.palette.primary.dark, 
      opacity:0.7,
      color:'white', 
    },
  },
  selectEdit2:{
    minHeight:"10px",
    minWidth:"50px", 
    width:"fit-content",
    height:"17px !important",
    "&.MuiTextField-root":{
      background:theme.palette.primary.main, 
      borderRadius:"5px"
    },
    "&.MuiInputBase-root":{
      height:"17px !important"
    }
  },
  inputEdit2: {
    fontSize:"13px",
    width:"80px",
    margin:"0px",
    padding: "0px 0px",
    paddingLeft:'10px',
    color:"white",
    //background:theme.palette.primary.dark, 
    "&:focus":{
      background:theme.palette.primary.dark, 
      borderRadius:"5px"
    },
  },
   //form textfield
   formInput:{
    width: '98%',
    marginBottom:"10px",
    background:theme.palette.secondary.light,
    textDecoration: 'none',
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
   },
   //datatable text field 
 dataheadInput:{
  paddingRight:"10px",
  marginBottom:"10px",
  marginLeft:"20px",
  minWidth:"120px",
  width:"auto",
  minHeight:"15px",
  height:"15px",
 },
   //cards
   cards:{
    minWidth:0,
    margin:"5px",
    width:'95%',
    
    //background:theme.palette.secondary.light,
    justifyContent:"center",
    //alignItems:"center",
    },

  whiteSmallIcon:{color:"white",fontSize:"15px"},
  blackSmallIcon:{color:"black",fontSize:"15px",cursor:'pointer'},
  redSmallIcon:{color:"red",fontSize:"15px"},
  greenSmallIcon:{color:"green",fontSize:"15px"},

  dialogueContent:{
    padding: "0 !important",
  },
  //for small text field label and input
  dataInput: {
    fontSize: 13,
    fontWeight: "bold",
   // padding: "5px 0px",
    color:"black",
    height:'fit-content',
    background:theme.palette.secondary.main,
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
   },
  dataInput1: {
    fontSize: 13,
    fontWeight: "bold",
    padding: "0px",
    color:"black",
    height:'fit-content',
    background:theme.palette.secondary.main,
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
   },
   dataLabel:{
    fontSize: 13,
    fontWeight: "bold",
    color:"black",
    backgroundColor:'red',
    padding:"0px",
    margin:"0px",
    transform: "translate(14px, 8px) scale(1) !important"
  },
   //for the check box
   checkStelect: {
    transform:"scale(0.8)",//to control the size
    "&.MuiCheckbox-root": {
      color:'red',
      '&.Mui-checked':{
        color:'green',
      }
    }, 
  }, 
  } 
  ));

export const paperProps={
   style:{
     width:"800px",
     maxWidth:"2000px",
     maxHeight:"95%",
    // height:"1000px",
     overflow:"auto"
   }
}

export const paperPropsA4={
   style:{
     width:"2480px",
     maxWidth:"2000px",
     maxHeight:"95%",
     overflow:"auto"
   }
}

export const paperPropsTicket={
   style:{
     width:"8cm",
     maxWidth:"8.5cm",
     maxHeight:"95%",
    // height:"1000px",
     overflow:"auto",
     overflowX:"hidden"
   }
}
export const paperPropsTicketSmall={
   style:{
     width:"300px",
     maxWidth:"600px",
     maxHeight:"95%",
    // height:"1000px",
     overflow:"auto",
     overflowX:"hidden"
   }
}
export const paperPropsTicketDig2={
   style:{
     width:"260px",
     maxWidth:"600px",
     maxHeight:"95%",
    // height:"1000px",
     overflow:"auto",
     overflowX:"hidden"
   }
}
export const paperPropsTicketDig={
  style:{
    width:"250px",
    maxWidth:"600px",
    maxHeight:"95%",
    padding:"0px !important",
   // height:"1000px",
    overflow:"auto",
    overflowX:"hidden",
    background:"red !important",
  }
}
export const paperPropsTicketDigTall={
  style:{
    width:"305px",
    maxWidth:"600px",
    maxHeight:"95%",
    padding:"0px !important",
   // height:"1000px",
    overflow:"auto",
    overflowX:"hidden",
    background:"red !important",
  }
}
export const paperProps2={
  style:{
    width:"900px",
    maxWidth:"2000px",
    maxHeight:"95%",
    minHeight:"400px",
    height:"fit-content",
    overflow:"auto"
  }
}
export const paperProps3={
  style:{
    width:"900px",
    maxWidth:"2000px",
    maxHeight:"95%",
    minHeight:"400px",
    height:"fit-content",
    overflow:"hidden"
  }
}
export const paperPropss={
  style:{
    width:"900px",
    maxWidth:"2000px",
    maxHeight:"95%",
    minHeight:"200px",
    height:"fit-content",
    overflow:"hidden"
  }
}
export const paperPropsBig={
  style:{
    width:"900px",
    maxWidth:"2000px",
    maxHeight:"96%",
    height:"auto",
   overflow:"auto"
  }
}

export const paperPropsSmall={
  style:{
    maxWidth:"2000px",
    maxHeight:"10000px",
    //height:"1000px",
    width:"fit-content",
    height:"fit-content", 
  }
}
export const paperPropsSmall2={
  style:{
    maxWidth:"2000px",
    maxHeight:"10000px",
   // height:"1000px",
    width:"750px",
    height:"fit-content",
    overflow:'hidden'
 
  }
}
export const paperPropsPrint={
  style:{
    maxWidth:"2000px",
    maxHeight:"10000px",
   // height:"1000px",
    width:"1100px",
    height:"fit-content",
    overflow:'hidden'
 
  }
}
export const paperPropsSmall3={
  style:{
    maxWidth:"2000px",
    maxHeight:"10000px",
   // height:"1000px",
    width:"1100px",
    height:"fit-content",
    overflow:'hidden'
 
  }
}
export const paperPropsSmallF={
  style:{
    width:"500px",
    height:"fit-content",
    maxHeight:"5000px",
    overflow:'hidden'
 
  }
}
export const paperPropsSmallForg={
  style:{
    width:"580px",
    height:"fit-content",
    maxHeight:"5000px",
    overflow:'hidden'
 
  }
}
export const paperPropsDet={
  style:{
    maxWidth:"500px",
    height:"fit-content",
    maxHeight:"5000px",
    overflow:'hidden'
 
  }
}


export const paperPropsSmallTall={
  style:{
    maxWidth:"500px",
    maxHeight:"10000px",
    width:"500px",
    height:"fit-content",
    overflow:'hidden'
  }
}
export const paperPropsthinTall={
  style:{
    maxWidth:"420px",
    maxHeight:"100%",
    width:"420px",
    height:"fit-content",
    overflow:'hidden'
  }
}

export const paperPropsBigFixed={
  style:{
    maxWidth:"1000px",
    maxHeight:"694px",
    //height:"1000px",
    width:"90%",
    height:"95%", 
    overflow:"hidden"
  }
}
export const paperPropsAd={
  style:{   
    width:"90%",
    minWidth:'900px',
    height:"fit-content", 
    minHeight:'450px ',
    overflow:"hidden"
  }
}
export const paperPropsBigH={
  style:{
    maxWidth:"1000px",
    width:"90%",
    height:"fit-content", 
    minHeight:"450px",
    overflow:"auto",
  }
}
export const paperPropsSelect={
  style:{
    maxWidth:"2000px",
    maxHeight:"10000px",
    minHeight:"500px",
    width:"fit-content",
    height:"10000px", 
  }
}
export const textAreaEdit={
  background:theme.palette.primary.dark,
  color:"white",
  borderColor:theme.palette.primary.dark,
  width:'100%',
  borderRadius:'5px',
  opacity:"1",
  outline:"0",
  fontFamily:"inherit"
}
export const textAreaEditGray={
  background:theme.palette.secondary.light,
  color:"black",
  borderColor:theme.palette.secondary.main,
  width:'100%',
  borderRadius:'5px',
  opacity:"1",
  outline:"0",
  fontFamily:"inherit"
}
export const textAreaEdit2={
  background:theme.palette.secondary.light,
  color:"black",
  borderColor:theme.palette.secondary.dark,
  borderRadius:'5px',
  opacity:"1",
  outline:"0",
  margin:'5px',
  padding:'5px',
  width:"400px",
  height:'fit-content',
  minHeight:"25px",
  textAlign:'start'
}
export const textAreaNote={
  color:"black",
  borderRadius:'5px',
  opacity:"1",
  height:'70px',
  width:'380px',
  outline:"0"
}
export const textAreaNote1={
  color:"black",
  borderRadius:'5px',
  opacity:"1",
  height:'70px',
  width:'auto',
  outline:"0"
}

export const multiSelect={
  multiselectContainer: { // To change css for multiselect (Width,height,etc..)
    background:theme.palette.secondary.light,
    width:'100%',
    borderRadius:"10px"
  },
  searchBox: { // To change search box element look
    border: "1px solid #a9a6a6",
    fontSize: "10px",
    minHeight: "40px",
  },
  inputField: { // To change input field position or margin
    margin: "5px",
  },
  chips: { // To change css chips(Selected options)
    background: theme.palette.primary.main,
  },
  optionContainer: { // To change css for option container 
    border: "1px solid #a9a6a6",
    boxShadow: "-3px 6px 9px -4px rgba(0,0,0,0.93) ",
    borderTop:'none',
    fontSize:"13px",
  },
  option: { // To change css for dropdown options 
      fontColor:"black !important",
      paddingLeft:"20px"
  },
  groupHeading: { // To chanage group heading style
  } 
}

