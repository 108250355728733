import React,{useState,useRef, useEffect, useContext, useMemo} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField,FormControl,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import { AlertCompo, RaccBtn } from '../../../js/components';
import { mdiReceiptTextClock } from '@mdi/js';
import { DoneAll, Search,Close } from '@material-ui/icons';
import { customStyles, numberColumnTicket } from '../../../css/datatable-custom';
import ParamContext from '../../../Contexte';
import DataTable from 'react-data-table-component';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
//add new bon
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Vnt_cpt_pay(props) {
    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const [numTick,setNumTick]=useState(null);
    const [detail,setDetail]=useState([]);
    const [open, setOpen] =useState(false);
    const [called, setCalled] =useState(false);
    const [action, setAction] =useState(false);

    const addTitle="Ajout d'un retour depuis un ticket en utilisant son numero"
    const labelText1="Numero de ticket"

    const service="_vnt.php"

    const classes=useStyles();
    const [openA, setOpenA] =useState(false);
    const [message, setMessage] =useState('');
    const [severity, setSeverity] =useState('info');
    
    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
 
    useEffect(()=>{
        const elem=document.getElementById('iEnc')
      if(elem){
      elem.focus();
      elem.select();}
      setCalled(true)
    },[called])


    const handleClickOpen = () => { 
      //(props.clientId)?setNote(props.note):setNote('');
      setOpen(true); 
      setDetail({})
      setNumTick("")
    };

    const handleClose = () => { setOpen(false); };

    const getTicketNyNum= async() =>{
        //if(!props.action){
         // props.setAction(true)
          const respon=await axiosPostAction(service,"gettiketnum",{numero:numTick})
          if(respon.data.err && respon.data.err!=='-1'){
            handleAlert(true,'error',respon.data.err);
            props.handleAlert(true,'error',respon.data.err);
          }else
          axiosResponse(respon,()=>{
            if(respon.data.jData){
              //setOpen(false)
              setDetail(respon.data.jData)
              //props.handleAlert(true,'success',"Le ticket est encaissé.");
            }
          })
        //  props.setAction(false)}
      
    }
    
    // ajouter un nouveau bon
    const handleSubmit= async(e) =>{
      if(!action&&detail.ventes&&detail.ventes[0].id){
        const ticketId= await props.addTicket(true)
        setAction(true)
         if(detail&&detail.ventes&&detail.ventes.length>0) await Promise.all(detail.ventes.map(async(elem)=>{if(elem.qte>0) {
          const data={
            pdetId:elem.prod_det_id,
            tiketId:ticketId,
            qte:parseInt(elem.qte),
            stockId:parseInt(elem.stock_id),
            mtn:parseInt(elem.mtn),
            retour:true,
          }
          
          const respon=await Gfunc.axiosPostAction(service,"addvman",data)
          
          if(respon.data.err && respon.data.err!=='-1'){
            handleAlert(true,'error',respon.data.err);
          }else
          Gfunc.axiosResponse(respon,async()=>{
            props.handleChangeStk(elem.prod_det_id,"La modification du stock de "+elem.produit+" est annulée!")
          }) 
          return true
        }}))
        setOpen(false)
        props.fetchTicket()
        props.handleAlert(true,'success',"Le ticket de retour est créé.");
      }
        setAction(false)
        
      }

    const columns = useMemo(()=>[   
      {
          name: "Produit",
          selector: row=>row.produit,
          sortable: false,
          minWidth:'200px !important'
      },
      {
          name: "QTE.",
          selector: row=>row.qte,
          sortable: false,
          minWidth:'60px !important',
          style:numberColumnTicket,
          cell:(row)=>{
            return (
            <>
              {new Intl.NumberFormat().format(row.qte)}
            </>
            )
          }
      },
      {
          name: "Prix",
          sortable: false,
          style:numberColumnTicket,
          minWidth:'80px !important',
          cell:(row)=>{
            return (
            <>
              {new Intl.NumberFormat().format(row.prix)}
            </>
            )
          }
      },
      {
          name: "Total",
          selector: row=>row.mtn,
          minWidth:'100px !important',
          style:numberColumnTicket,
          sortable: false,
          cell:(row)=>{
            return (
            <>
              {new Intl.NumberFormat().format(row.mtn)}
            </>
            )
          }
      },
    ],[])

  return (
    <>
      <RaccBtn
          id='returnTicketPlus'
          tip={"Effectuer un ticket de retour depuis un numéro de ticket"}
          className={classes.racBtnGrey}
          text={'Tick. rt+'}
          path={mdiReceiptTextClock}
          onClick={()=>{handleClickOpen()}}
          iconColor={"black"}
          racc={'alt+F1'}
      />
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
           <div className='DialogTitle'>
              <Icon path={mdiPlusBox} size={1} />
              <label>Ajout d'un retour depuis un ticket en utilisant <br/>
                     son numero</label>
            </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
            <DialogContent >
            <ValidatorForm id="payT"  ref={useRef(null)} onSubmit={()=>handleSubmit()} onError={errors => console.log(errors)} > 
                    <div id='fieldSet' style={{display:'flex',alignItems:"center"}}>
                        <FormControl className='smallText'>
                            <TextField size="small" label={labelText1} onChange={(e)=>setNumTick(e.target.value)} id="iNum" name="iNum"  value={numTick} 
                                margin="dense" variant='outlined' InputProps={{ inputProps: { min: 0 } }} required
                                />
                        </FormControl>
                        <Button title='Rechercher' className={classes.PrintBtn} variant="contained" color="primary" onClick={getTicketNyNum}><Search /></Button>
                    </div>
                    <DataTable 
                      persistTableHead
                      columns={columns}
                      data={detail.ventes}
                      noHeader={true}
                      customStyles={customStyles}
                      defaultSortAsc={true}
                      noDataComponent={param.ErreurData}    
                      highlightOnHover
                      paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
                    />
             </ValidatorForm>
            </DialogContent>
            <DialogActions>
                <Button disabled={!detail.ventes||!detail.ventes[0].id} variant="contained" type='submit' color="primary" form="payT" 
                        startIcon={<DoneAll />}>VALIDER</Button>
            </DialogActions>
      </Dialog>
      </>
  );
}
