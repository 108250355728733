import React,{useState, useMemo, useCallback, useEffect} from 'react';
import {DialogContent,Paper,Dialog,Fab,Box,TextField,CircularProgress,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import { useStyles,paperPropsBig} from '../../../css/muiStyle';
import DataTable from "react-data-table-component";
import { customStyles, negativeRed, numberColumn } from '../../../css/datatable-custom';
import {LocationOn,Phone,Search,Close} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import '../../../css/monitoring.css';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import { AlertCompo } from '../../../js/components';
import Icon from '@mdi/react';
import {mdiPodium,mdiClipboardClockOutline} from '@mdi/js';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  }, 
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//add new bon
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Vnt_cpt_histCli(props) {
    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const librery=require('../../../js/data_table_librery')
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] =useState(false);
    const [search,setSearch] =useState('');
    const [open, setOpen] =useState(false);
    const [data, setData] =useState([]);
    const [client,setClient]=useState('');
    const [clientL,setClientL]=useState([]);
    const [detail,setDetail]=useState({});
    const [clientN,setClientN]=useState('');
    const [errMessage,setErrMessage]=useState('');
    const [doSearch,setDoSearch]=useState(false);
    const [notAllowed,setNotAllowed]=useState(false);
    const classes=useStyles();
    const service="_cli.php"
    const [openA, setOpenA] =useState(false);
    const [message, setMessage] =useState('');
    const [severity, setSeverity] =useState('info');
    
    const conditionalRowStyles=[
      { when: row => row.type==='encaissement',
         style: {
               color: "green",
         }
       },
      { when: row => row.type==='vente',
         style: {
               color: 'red',
         }
       }
   ]
    
    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    //fetch client 
   const fetchClient= useCallback(async() => {
    const response = await Gfunc.axiosGetAction("_cli.php","gets")
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData){
      const dat=response.data.jData
      dat.unshift({id:null,name:"Anonyme"})
        setClientL(dat);
      }   
    })
  },[Gfunc]);

    //fetch client 
   const fetchDetClient= useCallback(async(client) => {
    const response = await Gfunc.axiosPostAction("_cli.php","getdet",{clientId:client})
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData){
        setDetail(response.data.jData);
      }   
    })
  },[Gfunc]);

   //handle the call for fetch client
   const handleOpenClient=(event)=>{
    if(clientL.length===0)
    fetchClient();
   }

    const fetch= async(page,servic = service,size = perPage,client) => {
        setLoading(true);
        const response=await librery.axiosGet2(page,servic,'getclihist&search='+search+"&f="+((client===undefined)?props.clientId:client),size)
        
        if(response.data.err&&response.data.err!=="-1"){
            //setErrMessage(response.data.err)
            //setNotAllowed(true)
            props.handleAlert(true,"error",response.data.err)
            setOpen(false)
          }else
          Gfunc.axiosResponse(response,()=>{
            if(response.data.data!==undefined){
              setData(response.data.data);
              setTotalRows(response.data.recordsFiltered);
              setDoSearch(true)    
            }else {setDoSearch(false);setData([])}
          })  
          setLoading(false)
      }

    const handleClickOpen = () => {
      setOpen(true); 
      handleOpenClient();
      setDetail({})
      if(props.clientId) {fetch(1);}
    };

    const handleClose = () => { setOpen(false); setData([])
        setPerPage(10);
        setCurrentPage(1)
        setSearch('')
    };

    function handlePageChange (page) {
        fetch(page,service,perPage,client);
        setCurrentPage(page);
      };
       const handlePerRowsChange = async (newPerPage, page) => {
        fetch(page,service, newPerPage,client);
        setPerPage(newPerPage);
      };

    
 //Sort serverside
 const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
    setLoading(true);
    const response=await librery.axiosSort(column,sortdirection,page,size,service,"getclihist"+"&f="+props.clientId)
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }   
    })  
    setLoading(false);   
  }

  //recherche serverside
const handleSearch=async(size = perPage)=>{
    setLoading(true);
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,"getclihist"+"&f="+((client===undefined)?props.clientId:client))
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })   
    setCurrentPage(1)
    setLoading(false);
    return recherche
  }

  //handle live search
const liveSearch=(e,search)=>{
    if(param.liveSearch&&search.length>3){
    e.preventDefault();
    handleSearch();
    }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
    }
  }

    const columns =useMemo(
        () => [
          {
              name: "date",
              selector: row=>row.date,
              sortable: true,
              minWidth:'150px !important',
              sortField:'date',
              cell:(row)=>{
                  return(
                   <div>
                       {row.date+' > '+row.heure}
                   </div>
                  );
              }
          },
          {
              name: "Agent",
              selector: row=>row.agent,
              sortable: true,
              minWidth:'130px !important',
              sortField:'agent',
          },
          {
              name: "montant",
              selector: row=>row.mtn,
              sortable: true,
              style:numberColumn,
              conditionalCellStyles:negativeRed,
              width:'120px !important',
              sortField:'mtn',
              cell:(row)=>{
                  return(
                      <div>
                          {new Intl.NumberFormat().format(row.mtn)} 
                      </div>
                  )
              }
          },
          {
              name: "solde",
              selector: row=>row.solde,
              sortable: true,
              style:numberColumn,
              minWidth:'110px !important',
              sortField:'solde',
              cell:(row)=>{
                  return(
                      <div>
                          {new Intl.NumberFormat().format(row.solde)} 
                      </div>
                  )
              }
          },
          {
            name: "note",
            minWidth:'300px !important',
            selector: row=>row.note,
            sortable: true,
        }
        ],[Gfunc]);
        
        const ExpanableComponent = ({ data }) => {
          const [detail,setDetail]=useState([]);
          const [called,setCalled]=useState(false);
          const classes=useStyles();
  
          // fetch detail de tiket 
          const fetchDetail= async() => { 
            setCalled(true)
            const response = await Gfunc.axiosPostAction("_vnt.php","gettiket",{idt:data.tiketId})
            Gfunc.axiosResponse(response,()=>{
              if(response.data.jData)
              setDetail(response.data.jData);
            })
          };

          useEffect(() => {
            if(!called &&data.type==="vente")
            fetchDetail()
          }, [fetchDetail,called]);


            return (
              <div className="row-expenderr" id="detVBon">
                <div className='flex-wrapper' style={{padding:'5px',justifyContent:'center',width:'100%'}}>
                    { (detail&&detail.ventes)?
                     <table id="pdetTable">
                      <thead>
                      <tr>
                        <th>Produit</th>
                        <th>Qte.</th>
                        <th>Prix</th>
                        <th>Montant</th>
                        <th>Stock</th>
                      </tr>
                      </thead>
                      <tbody>
                        {
                          detail.ventes.map((el,index)=>{
                            return(
                              <tr key={index}>
                                <td id="prod">{el.produit}</td>
                                <td className='numbers'>{new Intl.NumberFormat().format(el.qte)}</td>
                                <td className='numbers'>{new Intl.NumberFormat().format(el.prix)}</td>
                                <td className='numbers'>{new Intl.NumberFormat().format(el.mtn)}</td>
                                <td>{el.stock}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>:null}
                </div>
           </div>
            )
        }
 
  return (
    <>
      <Fab className={classes.sideBtn} variant="extended" size="small" color="secondary" onClick={handleClickOpen} > Histo client</Fab>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsBig}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
            <div className='DialogTitle'>
              <Icon path={mdiClipboardClockOutline} size={1} />
              <label>{"Historique client "+((data.length>0)?(" de "+data[0].client):'')}</label>
            </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
            <DialogContent >
           <div id="fieldSet">
               <div id='row-hstCl' >
                     <Autocomplete
                                disableClearable 
                                id="iClient"
                                className="smallAuto"
                                onChange={(e,newValue) =>{
                                    setClient(newValue.id)
                                    setClientN(newValue.name)
                                    fetch(1,service,perPage,newValue.id)
                                    fetchDetClient(newValue.id)
                                  }}
                                options={clientL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => (option.id=== value.id)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"Client"}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"/>)}/>
                      {(detail.tel)?
                        <div id="item" >
                          <Phone style={{height:'18px',width:'18px'}}/>
                          <b> Tel:</b>
                          <p> {detail.tel}</p>
                        </div>
                      :null}
                      {(detail.commune)?
                        <div id="item" >
                          <LocationOn style={{height:'18px',width:'18px'}}/>
                          <b> Commune:</b>
                          <p> {detail.commune}</p>
                        </div>
                      :null}
                      {(detail.classe)?
                        <div id="item" >
                          <Icon path={mdiPodium} size={0.8} />
                          <b> Classe:</b>
                          <p> {detail.classe}</p>
                        </div>
                      :null}
                    </div>
                    <div className='rowGrid' id="row-2">
                      {(JSON.parse(param.gros)&&detail.soldeAT)?<fieldset className="fieldInf" ><legend> Solde AirTime:</legend><p> {detail.soldeAT}</p></fieldset>:null}
                      {(JSON.parse(param.gros)&&detail.soldePhone)?<fieldset className="fieldInf" ><legend> Solde Phone:</legend><p> {detail.soldePhone}</p></fieldset>:null}
                      {(JSON.parse(param.gros)&&detail.soldeAcc)?<fieldset className="fieldInf" ><legend> Solde Accessoires:</legend><p> {detail.soldeAcc}</p></fieldset>:null}
                      </div>
            </div>
        <div className='table-header'>
            <div className="button-flex-wrapper">
            </div>  
            <div className='search'>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField variant="outlined" id="input-with-sx" label="Recherche" value={search} size="small" margin='none'
                             onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} 
                             InputProps={{startAdornment: (<Search style={{ marginRight: '5px',zIndex:'1000' }} />),}}/>
              </Box>  
            </div>
            </div>   
            <div  id='exp-DT'>
                <DataTable
                  expandOnRowClicked
                  expandableRows 
                  expandableRowsComponent={ExpanableComponent}  
                  expandableRowDisabled={(row)=>row.type!=="vente"}
                  persistTableHead
                  columns={columns}
                  data={data}
                  noDataComponent={param.ErreurData}
                  paginationRowsPerPageOptions={[10,20,30,40,50]}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  sortServer={true}
                  onSort={handleOnSort}
                  progressPending={loading}
                  conditionalRowStyles = { conditionalRowStyles }
                  progressComponent={<CircularProgress className={classes.circularProgress} />}
                  noHeader={true}
                  highlightOnHover
                  customStyles={customStyles}
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}            
                /></div>
            </DialogContent>
      </Dialog>
      </>
  );
}
