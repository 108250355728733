import React,{ useState, useEffect ,useMemo, useCallback} from "react";
import DataTable from "react-data-table-component";
import {CircularProgress} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import '../../css/global.css'
import {customStyles, numberColumn} from '../../css/datatable-custom'
import '../../css/datatable.css';
import { useStyles} from '../../css/muiStyle';
import {Search,ZoomIn} from '@material-ui/icons';
import {AlertCompo,NoDataComponent,NotAllowedComponent} from "../../js/components"
import { Box } from "@mui/system";


export default function Ret_ins(props){
  const classes = useStyles();
  const librery=require('../../js/data_table_librery')
  const param=props.param;
  const Gfunc=require('../../Gfunc')
 // const [agent, setAgent] = useState('');
  const [data, setData] = useState([]);
  //const [,setDel] = useState('');
  const [,setDel] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const[noData,setNoData]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[doSearch,setDoSearch]=useState(false);
  const[called,setCalled]=useState(false);
  const service='_ret.php'
  
  //handle alerts properties
  const handleAlert=useCallback((open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  },[])

  const fetch=async(page,servic = service, size = perPage) => {
    setCalled(true)
    setLoading(true)
        const response=await librery.axiosGet2(page+'&search='+search,service,'getinst', size)
        if(response.data.err&&response.data.err!=="-1"){
          setErrMessage(response.data.err)
          setNotAllowed(true)
        }else
        Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
          setData(response.data.data);
          setTotalRows(response.data.recordsFiltered);
          setDoSearch(true)
        }else {setDoSearch(false);setData([])}
        setNoData(true)
        })
        
        setLoading(false)
  }

  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);
  

  const columns =useMemo(
    () => [ 
      {
        name: "PRODUIT",
        minWidth:"250px !important",
        selector:row=>row.produit,
        sortable: true, 
        sortField:'produit' 
      },
      {
        name: "Categorie",
        minWidth:"200px !important",
        selector:row=>row.categorie,
        sortable: true,
        sortField:'categorie' 
      },
      {
        name: "Prix d'achat",
        minWidth:"150px !important",
       // width:"180px",
        style:numberColumn,
        selector:row=>row.achat,
        sortable: true,
        sortField:'achat' ,
        cell:(row)=>{
            return(
            <div>{new Intl.NumberFormat().format(row.achat)}</div>
            )
        }
      },
      {
        name: "Qte.",
        minWidth:"100px !important",
        style:numberColumn,
        selector:row=>row.stock,
        sortable: true,
        sortField:'stock' 
      },
      { width:'85px !important',
        right:true,
        cell: (row) => {
          return (
            <div>    
                <ZoomIn className={classes.blackIcon} idr={parseInt(row.DT_RowId.substr(1))}/>
            </div>
          )
        }
      },
    ],[classes]);
   
    //Sort serverside
    const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,"getinst")
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);}
      }) 
      setLoading(false)    
    }
  
  //recherche serverside
  const handleSearch=async(size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,"getinst")
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
    }else setData([])
    })   
    setLoading(false);
    return recherche
  }

function handlePageChange (page) {
  fetch(page,service,perPage);
  setCurrentPage(page);
}

 const handlePerRowsChange = async (newPerPage, page) => {
  fetch(page,service, newPerPage);
  setPerPage(newPerPage);
}

//Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
}
function liveSearch2() {
  handleSearch();
}
return (
  <div className="content">
   <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} autoFocus/>
     <div className='table-header' >
       <div className="button-flex-wrapper"></div>
     { (doSearch)?
      <div className='search' >
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField className={classes.search} variant="outlined" label="Recherche" value={search} 
                             InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                             style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:'pointer' }} />),}}
                             size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
            </Box>
      </div>:null}
     </div>
     { (data.length!==0||doSearch)?<div>
        <DataTable className="table"
              persistTableHead
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              defaultSortAsc={true}
              paginationRowsPerPageOptions={[10,20,30,100]}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles}   
              selectableRowsComponentProps={{ className: classes.checkStelect }} 
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
              progressComponent={<CircularProgress className={classes.circularProgress} />}/>
              </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
    </div>    

  );
}