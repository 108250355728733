import React,{useState,useMemo} from 'react';
import {DialogContent,Dialog,Paper,Button,CircularProgress,Tooltip,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import "../../../css/referentiel.css"
import DataTable from "react-data-table-component";
import Iconn from "@mdi/react"
import{ mdiAccountGroup} from "@mdi/js"
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import {customStyles,numberColumn} from '../../../css/datatable-custom'
import { useStyles, paperPropsSmallF} from '../../../css/muiStyle';
import {ReactComponent as Totale} from '../../../img/icons/totale.svg'
import $ from 'jquery'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { Groups,Close} from '@mui/icons-material';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable> 
  )
}

export default function Ref_cap_actionnaire(props) {
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const [open, setOpen] =useState(false);
    const classes=useStyles();
    const [data, setData] =useState([]);
    const [somme, setSomme] =useState(0);
    const service='_cai.php'

    $(function() {
      $("div").removeAttr("data-tag")
    })  

    const handleClickOpen = () => { 
      setOpen(true); 
      setData([]);
      fetch();
    };

    const fetch=async()=>{
      var som=0;
      const response=await Gfunc.axiosGetAction(service,"getactcap")
      if(response.data.err&&response.data.err!=="-1"){
          props.handleAlert(true,"error",response.data.err)
      }else
          Gfunc.axiosResponse(response,()=>{
             setData(response.data.jData)
             response.data.jData.map((item)=>{
              som=som+item.capital
             })
             setSomme(som)
       })
    }
    const handleClose = () => { setOpen(false); };

    const columns =useMemo(
        () => [ 
          {
            name: "Actionnaire",
            selector: row=>row.actionnaire,
            minWidth:"200px !important",
            sortable: true, 
          },
          {
            name: "Capital",
            selector: row=>row.capital,
            width:"150px !important",
            style:numberColumn,
            sortable: true,
            cell: (row) => {
                return (
                  <div>{new Intl.NumberFormat().format(row.capital)}</div>
                )
              }
          },
    ],[]);

  return (
    <div>
       <Tooltip title="Les actionnaires"> 
         <Button variant="contained" color="secondary" onClick={handleClickOpen} className={classes.topTabBtn} 
                 startIcon={<Iconn path={mdiAccountGroup} color="black" size="20px"/>}>Actionnaires</Button>
       </Tooltip>
       <Dialog
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={paperPropsSmallF}
        >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
            <div className='DialogTitle'>
              <Groups style={{height:'20px',width:'20px'}}/>
              <label>Actionnaires</label>
            </div>
        </DialogTitle>
        <DialogContent >
            <div id='mtn-total'>
              <Totale style={{width:'18px',height:'18px'}}/>
              <b>Total:</b> 
              <p>{new Intl.NumberFormat().format(somme)}</p>
              <b>DA</b>
            </div>
            <div id='tab-crnc'>
              <DataTable
                columns={columns}
                data={data}
                noDataComponent={param.ErreurData}
                defaultSortAsc={true}
                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                highlightOnHover
                customStyles={customStyles}
                selectableRowsComponentProps={{ className: classes.checkStelect }}
                paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
                contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
                progressComponent={<CircularProgress className="circularProgress" />}
              />
            </div>
          </DialogContent>
      </Dialog>
      </div>
  );
}