import React,{useState,useRef,useCallback,useMemo,useEffect} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,Tooltip,ClickAwayListener,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Add,IndeterminateCheckBox,SwapHoriz,ArrowForward,Close} from '@material-ui/icons';
import {customStyles} from '../../../css/datatable-custom'
import {TextField,CircularProgress} from '@material-ui/core';
import { ValidatorForm} from 'react-material-ui-form-validator';
import  {useStyles,paperPropsSmall3,paperPropsSmallF} from '../../../css/muiStyle';
import DataTable from "react-data-table-component";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {ReactComponent as DA} from '../../../img/icons/dzd.svg'
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import { useMediaQuery } from 'react-responsive'
import  '../../../css/retour.css';
import $ from "jquery"
import axios from 'axios';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import Icon from '@mdi/react';
import { mdiPlusBox, mdiTransfer } from '@mdi/js';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ach_trt_add(props) {
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [stkSrc, setStckSrc] = useState(localStorage.getItem('stock'));
    const [stkDes, setStckDes] = useState('');
    const [stks, setStcks] = useState([]);
    const [prds, setPrds] = useState([]);
    const [trans, setTrans] = useState([]);
    const [datt,setDatt] = useState([]);
    const [prd, setPrd] = useState('');
    const [qte, setQte] = useState('');
    const [, setIcon] = useState('');
    const [desactiver, setDesactiver] = useState(true);
    const [disble, setDisble] = useState(false);
    const [errorP, setErrorP] = useState(false);
    const [errorQ, setErrorQ] = useState(false);
    const [errorS, setErrorS] = useState(false);
    const [control, setControl] = useState(false);
    const [selecto,setSelecto]=useState('')
    const [Item,setItem]=useState('')
    const [ItemN,setItemN]=useState('')
    const service='_prd.php'
    const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 550px)'})

    
    const handleClickOpen = () => {
      handleOpenStock()
      if(props.val!=='prod'){fetchProd()}
      setDatt([]);setTrans([])
      setStckSrc(localStorage.getItem('stock'))
      setStckDes('');setPrd('');setQte('');setDesactiver(true);setDisble(false);setErrorS(false)
      setErrorQ(false);setErrorP(false);
      setOpen(true); 
    };

    const handleClose = () => { setOpen(false); setTrans([])};

    useEffect(() => {
     if(control===true){
      document.getElementById('iProd').focus();
      setControl(false)
     }
    },[control]);
    
     //fetch all users that doesn't have the selected role
     const fetchStock=useCallback(async() => {
       const response=await Gfunc.axiosGetAction("_stk.php","gets&mod=ALL")
       if(response.data.err&&response.data.err!=='-1'){
            props.handleAlert(true,"error",response.data.err)
            setOpen(false)
            return false
       }else
            if(response.data.jData!==undefined){
                Gfunc.axiosResponse(response,()=>{
                  setStcks(response.data.jData);
                  return true
            })
       }
    },[Gfunc,props])

    const fetchProd=useCallback(async() => {
      const response=await axios.get( param.urlService+service+'?do=getsdet&mod=ACTIV',{ headers: Gfunc.getHeader()})
      if(response.data.err&&response.data.err!=='-1'){
          props.handleAlert(true,"error",response.data.err)
          setOpen(false)
          return false
      }else
        if(response.data.jData!==undefined){
          Gfunc.axiosResponse(response,()=>{
            setPrds(response.data.jData);
            return true
          })
        }
    },[Gfunc,props,param.urlService])

    
  //handle the call for fetch commune
  const handleOpenStock=(event)=>{
      if(stks.length===0)
      {
         fetchStock();
      }else{
        //  setError(true)
      }
  }

  //handle the call for fetch commune
 /* const handleOpenProd=(event)=>{
    //  if(stkSrc!==''&& stkDes!='')
      {
        fetchProd();
      }
  }*/
  
 //handle submit form
  const handleSubmit= async() =>{
   var dat=[]
   if(props.val!=='prod'){
     trans.map((el)=>{
       dat.push({'idpd':el.idpd,'qte':parseInt(el.quantite)})
       return true
     })
   }else{
    if(qte ===''){setErrorQ(true)}
    if(stkDes===''){setErrorS(true)}
    if(stkSrc===''){setErrorP(true)}
     dat.push({'idpd':props.row.id,'qte':parseInt(qte)})
   }
   if((qte!==''||props.val!=='prod')&&stkDes!==''&&stkSrc!==''){
    const stockS=stks[Gfunc.getIndex(stks,stkSrc,"nom")].id
    const stockD=stks[Gfunc.getIndex(stks,stkDes,"nom")].id
    const response = await Gfunc.axiosPostAction(service,"trans",{idsrc:stockS,iddest:stockD,trans:dat})
    if(response.data.err){
      props.handleAlert(true,'error',response.data.err)
      setOpen(true)
    }else{
        Gfunc.axiosResponse(response,()=>{
            if(response.data.jData===true){
               props.handleAlert(true,"success","Un nouveau transfert a été effectué")
               props.callback()
               setDatt([])
               setTrans([])
               setOpen(false)
            }
     })
    }
   }
  }
    
  const handleVide= async(val) =>{
    setTrans(val)
  }

  const handleValdier=() =>{
    if(prd!==''&&qte!==''){
      const objIndex =  prds.findIndex((obj => obj.nom===prd )); 
      const prod=prds[objIndex].id
      datt.push({idpd:prod,produit:prd,quantite:qte})
      handleVide(datt)
      setPrd('')
      setQte('')
      setControl(true)
    }else{
      if(prd===''){setErrorP(true)}
      if(qte===''){setErrorQ(true)}
    }
   
  }

 //valider formulaire avec la touche clavier 
/*const detectTouche= (e)=>{
    setError(false)
    if (e.keyCode === 13) {
      //  if(nom===''|| pselected.length===0){
          setError(true)
          handleAlert(true,"warning","Vous devez renseigner tous les champs")
        }else{handleSubmit()}
    }
  }*/

 const handleTest=()=>{
   if(stkDes!==''&& stkSrc!==''){
     if(stkDes!==stkSrc){
      setDesactiver(false)
      setDisble(true)
      setControl(true)
     }
    else{
      setErrorS(true)
    }
   }
 }
 
 const handleOnEditProd=useCallback((e,row)=>{
   row.quantite=e.target.value
   setOpen(true)
},[])

 const handleDeleteProd=useCallback((row)=>{
   const objIndex =  trans.findIndex((obj => obj.idpd ===row.idpd )); 
   trans.splice(objIndex,1)
   setIcon(row.idpd) 
   //handleVide('')
  },[trans])

  const handleSelect=useCallback((row,att)=>{
    setSelecto(row[att])
    setItem(row.idpd)
    setItemN(att)
 },[])

 const columns =useMemo(
  () => [ 
    {
      name: "Produit",
      selector: row=>row.produit,
      minWidth:"650px !important",
      sortable: true, 
    },
    {
      name: "QTE.",
      selector: row=>row.quantite,
      minWidth:"180px !important",
      sortable: true,
      cell: (row) => {
        return (
        <div>
          {
            (row.idpd===Item && row.quantite===selecto && ItemN==="quantite")?
              <ClickAwayListener onClickAway={()=>{setItemN('');}}>
               <input
                  name="qte"
                  type='number'
                  defaultValue={row.quantite}
                  data-column="item"
                  className="content-editable"
                  autoFocus
                  onBlur={e => {handleOnEditProd(e,row)}}
               />
              </ClickAwayListener>
            :<div className="content-editable"  onClick={()=>{handleSelect(row,'quantite')}}>{row.quantite}</div>
              }
        </div>
         );
       },
    },
    {
      cell: (row) => {
        return (
          <div onClick={()=>handleDeleteProd(row)} title="Supprimer produit">
            <IndeterminateCheckBox className={classes.redIcon} fontSize="small"/>
          </div>   
          );
       },
    } 
  ],[classes,Item,selecto,ItemN,handleOnEditProd,handleSelect,handleDeleteProd]);

  //code jQuery pour boucler le focus des elements
$('form').each(function(){
  var list  = $(this).find('input*[tabindex],#last*[tabindex]').sort(function(a,b){ 
    return a.tabIndex > b.tabIndex ? 1 : -1; 
  }),
  first = list.first();
  list.last().on('keydown', function(e){
      if( e.keyCode === 9 ) {
          first.focus();
          return false;
      }
  });
});

  return ( 
    <div>
    {
      (props.val==='prod')?<Tooltip title="Transférer la quantité"><SwapHoriz fontSize="small" className={classes.blueIcon}  onClick={handleClickOpen}/></Tooltip>
      :<Button variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Add/>} className={classes.topTabBtn}>Nouveau Transfert</Button>
    }
      <Dialog
            //id={(props.val==='prod')?'':'trans'}
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={(props.val==='prod')?paperPropsSmallF:paperPropsSmall3}
      > 
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
            <div className='DialogTitle'>
              {
               (props.val==='prod')?
                <Icon path={mdiTransfer} size={1} />
               :<Icon path={mdiPlusBox} size={1} />
              }
              <label>{(props.val==='prod')?"Transférer la quantité de "+props.nom:'Ajouter un nouveau transfert'}</label>
            </div> 
        </DialogTitle>
        <DialogContent >  
        <ValidatorForm  noValidate  id="form" ref={useRef(null)}  onSubmit={handleValdier}/* onKeyDown={e=>detectTouche(e)}*/ >
       {
         (props.val==='prod')?
         <div>
           <div id='fieldSet'>
             <div style={{display:'flex',gridGap:'10px',justifyContent:"center",alignItems:'center'}}>
                <DA style={{height:'20px',width:'20px'}}/>
                <b>Prix d'achat :</b>
                <p> {props.row.prixAchat+' DA'}</p>
             </div>
           </div>
           <Autocomplete
               disabled={disble}
               disableClearable
               className='smallAuto'
               onChange={(e,newValue) =>{setStckSrc(newValue)}}
               value={stkSrc}
               options={stks.map((option) => option.nom)}
               renderInput={(params) => <TextField  error={errorP} 
                helperText={(errorP)?"Veuillez sélectionner un stock Source":null}
                margin='dense'  size='small'{...params} variant="outlined"  label="Stock source" 
                inputProps={{...params.inputProps,tabIndex:'1'}}
                SelectProps={{ MenuProps: {closeAfterTransition:true } }}
             />
            }
            /> 
             <Autocomplete
               disabled={disble}
               disableClearable
               className='smallAuto'
               onChange={(e,newValue) =>{setStckDes(newValue)}}
               value={stkDes}
               options={stks.map((option) => option.nom)}
               renderInput={(params) => <TextField  error={errorS} 
               helperText={(errorS)?"Veuillez sélectionner un stock Destination":null}  
               margin='dense'  size='small'{...params} variant="outlined"  label="Stock Destination" 
                inputProps={{...params.inputProps,tabIndex:'1'}}
                SelectProps={{ MenuProps: {closeAfterTransition:true } }}
             />
            }
            /> 
            <TextField type='number' error={errorQ} className='smallText' margin='dense' variant='outlined' size="small" label='Quantité'   value={qte} 
                        onChange={e =>{setQte(e.target.value);setErrorQ(false)}} title='Insérer la quantité ' fullWidth  required 
                        helperText={(errorQ)?"Veuillez sélectionner une Quantité":null}/>

         </div>
         :
         <div>
          <div id='fieldSet'>
            <div id='trt-row-1'>
              <Autocomplete
                  disabled={disble}
                  disableClearable
                  className='smallAuto'
                  onChange={(e,newValue) =>{setStckSrc(newValue)}}
                  value={stkSrc}
                  options={stks.map((option) => option.nom)}
                  renderInput={(params) =>
                     <TextField margin='dense'  size='small'{...params} variant="outlined"  label="Stock source" 
                                inputProps={{...params.inputProps,tabIndex:'1'}}
                                SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                />
                }
                />              
              <Autocomplete
                  disabled={disble}
                  disableClearable
                  onChange={(e,newValue) =>{setStckDes(newValue);setErrorS(false)}}
                  value={stkDes}
                  options={stks.map((option) => option.nom)}
                  className='smallAuto'
                  renderInput={(params) => 
                     <TextField margin='dense' error={errorS} size='small'{...params} variant="outlined"  label="Stock Destination" 
                                inputProps={{...params.inputProps,tabIndex:'2'}} 
                                SelectProps={{ MenuProps: {closeAfterTransition:true } }}/>}
                />          
                <Button className={classes.smallBtn} variant="contained" onClick={(disble===false)?()=>handleTest():null} 
                      tabIndex='3' color="secondary" /*disabled={disble}*/ title='Valider' > {<ArrowForward/>}</Button>
            </div>
            <div id='trt-row-2'>
            <div  id='auto1'>
                  <Autocomplete
                    disabled={desactiver}
                    disableClearable
                    value={prd}
                    onChange={(e,newValue) =>{setPrd(newValue);setErrorP(false)}}
                    id='iProd'
                    options={prds.map((option) => option.nom)}
                    className='smallAuto'
                    renderInput={(params) => <TextField
                    margin='dense' error={errorP} size='small'{...params} variant="outlined"  label="Produit" 
                    inputProps={{...params.inputProps,tabIndex:'4'}}
                    SelectProps={{ MenuProps: {closeAfterTransition:true } }}/>}
                  />
                </div>
                <div id="container">
                    <TextField required type='number' error={errorQ} color="primary" id='iQte' variant="outlined" className='smallText'  
                           label="Quantité" size="small"  inputProps={{ min: 0,tabIndex: "5",className:classes.input }} 
                           value={qte}  onChange={e =>{setQte(e.target.value);setErrorQ(false)}} placeholder="Quantité" disabled={desactiver}/>
                </div>
                <Button  className={classes.PrintBtn} form='form' type='submit' variant="contained" 
                          tabIndex= "6"  name="addBtn" color="primary" id='last'  disabled={desactiver}>OK</Button>

            </div>
          </div>
          <div id='ach_trt'>
            <DataTable
              key={Math.floor(Math.random() * 100)}
              columns={columns}
              data={trans}
              noDataComponent={param.ErreurData}
              defaultSortAsc={true}
              paginationRowsPerPageOptions={[5, 10, 20, 30]}
              pagination
              highlightOnHover
              customStyles={customStyles}
              selectableRowsComponentProps={{ className: classes.checkStelect }}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
              progressComponent={<CircularProgress className="circularProgress" />}
            />
          </div>
          </div>
         }      
          </ValidatorForm>
          </DialogContent>
          <DialogActions>
              <Button variant="contained" name="iValide" onClick={()=>handleSubmit()} type='submit' color="primary" startIcon={<Add />}>AJOUTER</Button>
          </DialogActions>
      </Dialog>
      </div>
  );
}
