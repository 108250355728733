import React,{useState} from 'react';
import {DialogContent,Paper,Button,Dialog,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import DataTable from "react-data-table-component";
import { useStyles,paperProps } from '../../../css/muiStyle';
import {customStyles, numberColumn}from '../../../css/datatable-custom'
import {ReactComponent as Creance} from '../../../img/icons/creance.svg'
import {ReactComponent as CreanceW} from '../../../img/icons/creanceW.svg'
import ParamContext from '../../../Contexte';
import {ReactComponent as Totale} from '../../../img/icons/totale.svg'
import { useContext } from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ach_ent_crnc(props) {
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] =useState(false);
    const [loading, setLoading] =useState(false);
    const [data, setData] =useState([]);
    const addTitle="Créances"
    const service="_frs.php"
    const classes=useStyles();
    const param=useContext(ParamContext);

     
    const fetch= async() => {
      setLoading(true)
      const response=await Gfunc.axiosGetAction(service,'getsolds')
      if(response.data.err&&response.data.err!=='-1'){
        alert(response.data.err)
        setOpen(false)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==undefined){
          setData(response.data.jData);
        }
      })
      setLoading(false)
    }

    const handleClickOpen = () => { 
      fetch()
      setOpen(true); 
    };

    const handleClose = () => { setOpen(false); };
 

    const columns = [
        {
          name: "NOM",
          selector: row=>row.nom,
          sortable: true,
        },
        {
          name: "Note",
          selector: row=>row.note,
          sortable: true,
        },
        {
          name: "Solde",
          style:numberColumn,
          selector: row=>row.solde,
          sortable: true,
          cell: (row) => {
            return (
              <div>
                {new Intl.NumberFormat().format(row.solde)} 
              </div>
             );
           },
        },
      ];
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Creance fill='black' style={{height:'20px',width:'20px',padding:"1px"}}/>}>Créances fournisseurs</Button>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
            <div className='DialogTitle'>
              <CreanceW style={{height:'20px',width:'20px'}} />
              <label>{addTitle}</label>
            </div>
        </DialogTitle>
        <DialogContent>
              <div id='mtn-total'>
                <Totale style={{width:'18px',height:'18px'}}/>
                <b>Total:</b> 
                <p>{Intl.NumberFormat().format((data.length!==0)?data[data.length-1]:0)}</p>
                <b>DA</b>
              </div>
              <div id='tab-crnc'>
                <DataTable  
                  columns={columns}
                  data={data.slice(0, -1)}
                  noDataComponent={param.ErreurData}
                  progressPending={loading}
                  highlightOnHover
                  noHeader={true}
                  customStyles={customStyles}
                  pagination
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}            
                />
              </div>
        </DialogContent>
      </Dialog>
      </div>
  );
}