import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField,FormControlLabel,Checkbox,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles, paperPropsSmallF } from '../../../css/muiStyle';
import { Autocomplete } from '@material-ui/lab';
import {AlertCompo} from '../../../js/components'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import $ from 'jquery'
import { Add ,Close} from '@material-ui/icons';
import { mdiAccountPlusOutline } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//add new user
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Adm_usr_add(props) {
    const Gfunc=require('../../../Gfunc')
    const classes=useStyles();
    const [name,setName]=useState('');
    const [pseudo,setPseudo]=useState('');
    const [tel,setTel]=useState('');
    const [selectRole,setSelectRole]=useState('');
    const [selectStock,setSelectStock]=useState(localStorage.getItem('stock'));
    const [open, setOpen] = useState(false);
    const [act, setAct] = useState(false);
    const [roles, setRoles] = useState([]);
    const [stk, setStk] = useState([]);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const service="_usr.php"

          $(document).ready(function(){
            $('#tel-edit').on('input', function(ev) {
              var $this = $(this);
              var value = $this.val();
              if (value && value.length >10) {
                $this.val(value.substr(0,10));
              }
            })
            });

    //handle alerts properties
    const handleAlert=(open,severity,message)=>{
      setOpenA(open)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
     
   const handleClickOpen = () => { 
      handleOpenStock()
      setName('');setPseudo('');setSelectRole('');setTel('');setSelectStock(localStorage.getItem('stock'));setAct(false)
      setOpen(true); 
    };
   const handleClose = () => { setOpen(false); };

    //fetch role
   const fetchRole= useCallback(async() => {
      const response = await Gfunc.axiosGetAction('_rol.php',"gets")
      if(response.data.err&&response.data.err!=="-1"){
         handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
          setRoles(response.data.jData);   
      })
    },[Gfunc]);
    
     //fetch role handler
   const handleOpenRole = useCallback(async () => {
     if(roles.length===0) {fetchRole()}
    },[roles,fetchRole])

    //fetch stock
   const fetchStock= useCallback(async() => {
      const response = await Gfunc.axiosGetAction('_stk.php',"gets&opt=ALL")
      if(response.data.err&&response.data.err!=="-1"){
         handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
          setStk(response.data.jData);   
      })
    },[Gfunc]);
    
     //fetch role handler
  const handleOpenStock = useCallback(async () => {
     if(stk.length===0) {fetchStock()}
  },[stk,fetchStock])

  const handleSubmit= async(e) =>{
        e.preventDefault();
        const role=roles[Gfunc.getIndex(roles,selectRole,"name")].id
        const stock=stk[Gfunc.getIndex(stk,selectStock,"nom")].id
        const response=await axiosPostAction(service,"add",{name:pseudo,note:name,tel:tel,roleId:role,actionnaire:act,stockId:stock})
        if(response.data.err&&response.data.err!=="-1"){
            handleAlert(true,'error',response.data.err)
        }else{
        axiosResponse(response,()=>{
            if(response.data.jData){
                props.handleAlert(true,'success',"L'utilisateur est ajouté avec succès, le mot de passe va être généré et envoyé à son numéro")
                props.callback();
                props.ResetPass(response.data.jData.frnsId);
                setOpen(false);
            }
        })
      }
    }

  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Add/>}>Nouvel utilisateur </Button>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallF}
      >
        <DialogTitle className={classes.topDivTitle}  onClose={handleClose}>
         <div style={{display:"flex",gridGap:"10px",alignItems:"center"}}>
           <Icon path={mdiAccountPlusOutline} size={1} />
           <label>Ajouter un nouvel utilisateur </label> 
         </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true} />
        <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
            <DialogContent id='cat-dialog'>
             <div id='usr-add'>
              <TextField className='smallText' variant='outlined' size="small" label='Pseudo'  value={pseudo} margin='dense'
                        onChange={e =>setPseudo(e.target.value)} fullWidth  required autoFocus />
              <TextField className='smallText' variant='outlined' size="small" label='Nom complet'  value={name} margin='dense'
                        onChange={e =>setName(e.target.value)} fullWidth required />
             </div>
             <div id='usr-add'>
                  <Autocomplete
                      disableClearable
                      className='smallAuto' 
                      id="iClass"
                      onChange={(e,newValue) =>{setSelectRole(newValue);}} 
                      onFocus={handleOpenRole}
                      value={selectRole}
                      options={roles.map((option) => option.name)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Role"
                          size="small" 
                          margin='dense' variant='outlined'
                          required/>
                                )} /> 
                  <Autocomplete
                      disableClearable
                      className='smallAuto' 
                      id="iO"
                      onChange={(e,newValue) =>{setSelectStock(newValue);}} 
                      //onFocus={handleOpenStock}
                      value={selectStock}
                      options={stk.map((option) => option.nom)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Stock"
                          size="small" 
                          margin='dense' variant='outlined'
                          required/>
                                )} /> 
                    
              </div>
              <div id='usr-add'>
                <TextField  type="tel" label='Tel' id="tel-edit" name="iTel" value={tel}  className='smallText' margin='dense' variant='outlined'
                           onChange={e =>setTel(e.target.value)}
                           inputProps={{ pattern: "[0][5-7][4-7][0-9][0-9][0-9][0-9][0-9][0-9][0-9]",maxLength:10} } 
                          /> 
                <FormControlLabel
                      control={
                        <Checkbox
                          checked={act}
                          onChange={e=>setAct(e.target.checked)}
                          name="checked"
                          color="primary"
                       />
                             }
                    label="Actionnaire"
                 />
              </div>
            </DialogContent>
            <DialogActions >
              <Button variant="contained" type='submit' color="primary" startIcon={<Add />}>AJOUTER</Button>
            </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
