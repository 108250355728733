import React, { useState,useEffect,useCallback ,useRef} from 'react'
import {Print, KeyboardBackspace} from '@material-ui/icons';
import {Button} from '@material-ui/core';
import { useStyles } from '../../../css/muiStyle';
import ReactToPrint from 'react-to-print';
import { useHistory } from 'react-router-dom';
import "../../../css/global.css"


export default function Digitalisation(props) {
  const classes = useStyles();
  const history = useHistory();
  const Gfunc=require('../../../Gfunc')
  const[data,setData]=useState([])
  var row=''
  const routeChange = (newPath) =>{ 
    let path = newPath; 
    history.push(path);
  }
  const componentRef = useRef();
  if(props.location.row){
    row=props.location.row;
    localStorage.setItem('row',JSON.stringify(row))    
  } else {
   row=JSON.parse(localStorage.getItem('row1'))
  }
  

  const fetch= useCallback(async() => {
    const response = await Gfunc.axiosPostAction('_inv.php' ,'getdet',{idi:row.DT_RowId.substr(1)})
    Gfunc.axiosResponse(response, () => {
      if(response.data.jData.detail!==undefined){
          setData(response.data.jData.detail)
        }
    })
  },[Gfunc,row.DT_RowId]);

  useEffect(() => {
    fetch();
  }, [fetch]);


    function backHome(){ 
      routeChange('/r/ri/dr') 
    }

    return (
      <div className='content'>
        <div id="titleImpDiv">
          <Button variant="contained" type="submit" onClick={backHome} className={classes.topTabBtn} color="secondery" 
                  startIcon={< KeyboardBackspace />}>RETOUR</Button>
          <ReactToPrint trigger={() => <Button  variant="contained" className={classes.topTabBtn} color="secondery" 
                        startIcon={<Print/>}>Imprimer</Button>} content={() => componentRef.current} />
        </div>
        <div id='sectionAimprimer' ref={componentRef}>
          <div style={{backgroundColor:'black',color:'white', width:'fit-content',textAlign:'center',margin:'0 auto',padding:'5px',fontSize:'20px'}}>
            Nom de l'inventaire: <span id="titleSpan">{row.inventaire}</span>
          </div>
          <div id="impDiv">
            <table className="data">
              <thead><tr><th>ID</th><th>PRODUIT</th><th>COMPTAGE</th></tr></thead>
              <tbody>
              {
                data.map((element, i) => {
                  return ((i % 2 === 0) ? <tr key={i}><td>{('000000' + element.idp).substring(Math.min(("" + element.idp).length, 6))}</td><td>{element.produit}</td><td><input type="text" size="4" /></td></tr> : null)
                })
              }
              </tbody>
            </table>
            {
              (data.length>1)?
              <table className="data">
              <thead><tr><th>ID</th><th>PRODUIT</th><th>COMPTAGE</th></tr></thead>
              <tbody>
              {
                data.map((element, i) => {
                  return ((i % 2 === 1) ? <tr key={i}><td>{('000000' + element.idp).substring(Math.min(("" + element.idp).length, 6))}</td><td>{element.produit}</td><td><input type="text" size="4" /></td></tr> : null)
                })
              }
              </tbody>
            </table>:<></>
            }
          </div>
        </div>
      </div>
    )
}