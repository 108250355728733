import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import {CircularProgress,ClickAwayListener,TextareaAutosize,Tooltip,TextField} from '@material-ui/core';
import {Search,IndeterminateCheckBox,ZoomIn} from '@material-ui/icons';
import { textAreaEdit, useStyles } from '../../css/muiStyle';
import {conditionalRowStyles,customStyles, numberColumn}from '../../css/datatable-custom'
import  {Detail_Bon,AlertCompo,NoDataComponent,NotAllowedComponent, AutocompleteS, DatePickerUpd, InputNbrEditable,ImprimerEtiq, ImprimerEtiqPlus, ImprimerEtiqAll} from '../../js/components';
import { useMediaQuery } from 'react-responsive'
import { Box } from "@mui/system";
import AddNvBn from './modals/ach_ent_nvbn'
import AddVers from './modals/ach_ent_vers'
import AddCrnc from './modals/ach_ent_crnc'
import AddAch from './modals/ach_ent_ach' 
 
 
export default function Ach_add (props){
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [num, setNum] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [mag,setMag]=useState([]);
  const classes=useStyles();
  const [selecto, setSelecto] = useState('');
  const [ItemN, setItemN] = useState('');
  const [Item, setItem] = useState('');
  const [, setDel] = useState('');
  const [clickedRow, setClickedRow] = useState('');
  const [note, setNote] = useState('');
  const [frnsL, setFrnsL] = useState([]);
  const [userL, setUserL] = useState([]);  
  const[noData,setNoData]=useState(false);
  const[doSearch,setDoSearch]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const[errMessage,setErrMessage]=useState('');
  const[called,setCalled]=useState(false);
  const[achatNum,setAchatNum]=useState(0);
  const [control, setControl] = useState(false);
  const service='_ach.php'
  const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 550px)'})

  
   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }
  
  const fetchDetails= useCallback(async(bon) => {
    setLoading2(true);
    const response=await Gfunc.axiosPostAction(service,'getbon',{idb:bon})
    if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
    }else
      Gfunc.axiosResponse(response,()=>{
          if(response.data.jData['numero']) setNum(response.data.jData['numero']); else setNum(null)
          if(response.data.jData['achats']!==undefined){
            setData2(response.data.jData['achats'])
            setAchatNum(Gfunc.countTableId(response.data.jData['achats'],'produitId'))
          }else{
          setData2([])
          setAchatNum(0)
        }
      })
    setLoading2(false)
  },[Gfunc]);

  const handleRowClick=useCallback((row,dat)=>{
    const updatedData = dat.map(item => {
        if (row !== item.DT_RowId) {
          return {
            ...item,
            toggleSelected: false
          };
        }
        return {
          ...item,
          toggleSelected: true
        };
      });
  
    setData(updatedData);
    fetchDetails(row.substr(1));
    setClickedRow(row)
  },[fetchDetails])

  const fetch= useCallback(async(page,servic = service,size = perPage) => {
    setCalled(true)
    setLoading(true);
    const response=await librery.axiosGet2(page,servic,'getbons&search='+search,size)
    if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
          setData(response.data.data);
          var test=Gfunc.getIndex(response.data.data,clickedRow,"DT_RowId")
          if(test!==-1){
            handleRowClick(clickedRow,response.data.data)}
          else{
            handleRowClick(response.data.data[0].DT_RowId,response.data.data)
          }          
          setTotalRows(response.data.recordsFiltered);
          setDoSearch(true)
          if(document.getElementById('iCode')) document.getElementById('iCode').focus()
        }else {setDoSearch(false);setData([])}
        setNoData(true)
      })  
      setLoading(false)
  },[Gfunc, clickedRow, handleRowClick, librery,perPage,search])

  const fetch2= async(page,servic = service,size = perPage) => {
    setCalled(true)
    setLoading(true);
    const response=await librery.axiosGet2(page,servic,'getbons&search='+search,size)
    if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
          setData(response.data.data);
          handleRowClick(response.data.data[0].DT_RowId,response.data.data)         
          setTotalRows(response.data.recordsFiltered);
          setDoSearch(true)
          document.getElementById('iCode').focus()
        }else {setDoSearch(false);setData([])}
        setNoData(true)
      })  
      setLoading(false)
  }

    //fetch fournisseurs
    const fetchFrns= useCallback(async(serv="_frs.php", act = "gets") => {
        const response = await Gfunc.axiosGetAction(serv,act);
        Gfunc.axiosResponse(response,()=>{
            setFrnsL(response.data.jData);    
        })
      },[Gfunc])

    //fetch users
    const fetchUser= useCallback(async(serv="_usr.php", act = "gets") => {
        const response = await Gfunc.axiosGetAction(serv,act);
        Gfunc.axiosResponse(response,()=>{
            setUserL(response.data.jData);    
        })
      },[Gfunc])
     // fetch detail de magasin
    const fetchDetMag= useCallback(async() => {  
        const response = await Gfunc.axiosPostAction("_mag.php","getmag",{id:localStorage.getItem('magid')})
        Gfunc.axiosResponse(response,()=>{
        setMag(response.data.jData);
        })
    },[Gfunc])
    
    //handle fetch magasin
    const handleFetchMag=useCallback(()=>{
      if(mag.length===0)
      fetchDetMag();
    },[fetchDetMag,mag])

  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);

   
  //modifier un champs avec un select
const handleSelect=useCallback((row,att)=>{
        setSelecto(row[att])
        setItem(row.DT_RowId) 
        setItemN(att)
},[])

//delete bon
const handleSetData=useCallback((row,att)=>{
    Gfunc.removeItemsByAtt(data,row.DT_RowId,att)
    setDel('')
},[data,Gfunc])

//delete achat
const handleSetData2=useCallback((row,att)=>{
    Gfunc.removeItemsByAtt(data2,row.id,att)
    setDel('')
},[data2,Gfunc])
/*
//handle update note
const handleOnEditNote=useCallback(async(id,note,row,att,action)=>{
  setNote('')
  setSelecto('')
  const edt1=note
  const edt=Gfunc.DeleteSpace(edt1);  
      if(row[att].toString()!==edt){
        const attrib=row[att];handleRowClick
        const response=await librery.axiosEdit2(service,action,row,edt,att,id) 
        Gfunc.axiosResponse(response,()=>{
          row[att]=edt;
        })
        if(response.data.err) {Gfunc.updateElem(id,attrib)}
        return response.data;
      }

      
},[librery,Gfunc,service]);
*/

 //handle update note
 const handleOnEditNote=useCallback(async(note,row,att,action)=>{
  const edt1=note
  var edt=Gfunc.DeleteSpace(edt1);
  if(row[att]!==edt){
    const attrib=row[att];
    const response=await librery.axiosEdit2(service,action,row,edt,att) 
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{row[att]=edt})
    if(response.data.err) {row[att]=attrib}
  }else {row[att]=edt}
  setNote('')
  setSelecto('')
},[librery,Gfunc]);

//handle delete bon
const handleDeleteBon=useCallback(async(row)=>{
  const x=window.confirm("Êtes-vous sûrs de supprimer cet élément?");
  if(x){
    const response = await Gfunc.axiosPostAction(service,"delbon",{idb:JSON.parse(row.DT_RowId.substr(1))})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
            handleAlert(true,'success',"L'élément est supprimé avec succès.")
            handleSetData(row,"DT_RowId")
            setDel(row.DT_RowId)
            setTotalRows(totalRows-1)
        })
        if(data.length===0){
          fetch(currentPage)
        }else
        if(row.DT_RowId===clickedRow&&data.length!==0) handleRowClick(data[0].DT_RowId,data)
  }
},[Gfunc,handleSetData,service,data,fetch,totalRows,clickedRow,handleRowClick,currentPage])

//handle delete achat
const handleDeleteAchat=useCallback(async(row)=>{
  const x=window.confirm("Êtes-vous sure de supprimer cet élément?");
  if(x){
    const response = await Gfunc.axiosPostAction(service,"delach",{ida:row.id})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        Gfunc.axiosResponse(response,()=>{
            handleAlert(true,'success',"L'élément est supprimé avec succès.")
            handleSetData2(row,"id")
            setDel(row.id)
            setAchatNum(Gfunc.countTableId(data2,'produitId'))
            Gfunc.updateElem(clickedRow,response.data.jData['mtnBon'])
        })
    })  
  }
},[Gfunc,handleSetData2,service,clickedRow,data2])

 //Sort serverside
 const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
  setLoading(true);
  setData2([])
  setAchatNum(0)
  setClickedRow('')
  setNum('')
  setAchatNum(0)
  const response=await librery.axiosSort(column,sortdirection,page,size,service,"getbons")
  Gfunc.axiosResponse(response,()=>{
    if(response.data.data!==undefined){
    setData(response.data.data);
    setTotalRows(response.data.recordsFiltered);
    handleRowClick(response.data.data[0].DT_RowId,response.data.data)
    }   
  })  
  setLoading(false);   
}

//recherche serverside
const handleSearch=async(size = perPage)=>{
  setLoading(true);
  const recherche=search;
  const response=await librery.axiosSearch(service,recherche,1,size,"getbons")
  Gfunc.axiosResponse(response,()=>{
    if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
    }else setData([])
  })   
  setCurrentPage(1)
  setLoading(false);
  return recherche
}

function handlePageChange (page) {
  fetch(page,service,perPage);
  setCurrentPage(page);
};
 const handlePerRowsChange = async (newPerPage, page) => {
  fetch(page,service, newPerPage);
  setPerPage(newPerPage);
};

//fetch fournisseur handler
const handleOpenFrns = useCallback(async () => {
if(frnsL.length===0)
fetchFrns()
},[frnsL,fetchFrns])

//fetch utilisateurs handler
const handleOpenUsers = useCallback(async () => {
if(userL.length===0)
fetchUser()
},[userL,fetchUser])
/*
//fetch update elem
const updateElem = useCallback(async (id,val) => {
  var elem = document.getElementById(id);
  elem.innerHTML= new Intl.NumberFormat().format(val);
},[userL,fetchUser])
*/

//Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
const liveSearch=(e,search)=>{
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
          e.preventDefault();
          handleSearch();
        }
  }
}
function liveSearch2() {
  handleSearch();
}

const columns =useMemo(
    () => [
      {   
        width:"45px",
        cell: (row) => {
        return (
           <Tooltip  title="Détail">
             <ZoomIn className={classes.blackIcon} id={"i"+row.DT_RowId} fontSize="small" onClick={()=>handleRowClick(row.DT_RowId,data)} />
           </Tooltip>
         );
       },
    }  , 
      {
          name: "DATE",
          selector: row=>row.date,
          sortable: true,
          minWidth:'150px',
          sortField:'date',
          cell: (row) => {
            return (
              <DatePickerUpd  datein={row.date_add} agentin={row.user_add} dateupd={row.date_upd} agentupd={row.user_upd}
                              selecto={selecto} Item={Item} ItemN={ItemN} row={row} setSelecto={setSelecto} rowId='DT_RowId'
                              name="date" minDate={Gfunc.LastXDay(300)} service={service} action='updbon' extra=''
                              handleSelect={handleSelect} setItem={setItem} handleAlert={handleAlert} 
                              className={(isDesktopOrLaptop)?'smallSelectDate':'smallSelectDT'}/>
             );
           },
      },
      {
        name: "Bon",
        minWidth:'160px !important',
        selector: row=>row.numero,
        sortable: true,
        sortField:'numero',
      },
      {
        name: "FOURNISSEUR",
        minWidth:'160px !important',
        selector: row=>row.frns,
        sortable: true,
        sortField:'frns',
        cell: (row) => { 
          return (
                <AutocompleteS datein={row.date_add} agentin={row.user_add} dateupd={row.date_upd} agentupd={row.user_upd} 
                               service={service} action={'updbon'}  defaultValue={row.frns} row={row} list={frnsL} 
                               id={'idfrns'} name='frns' attr='name' onOpen={handleOpenFrns} handleAlert={handleAlert}/>
          );
      },
    },
    {
        name: "ACHETEUR",
        minWidth:'160px !important',
        selector: row=>row.saller,
        sortable: true,
        sortField:'saller',
        cell: (row) => { 
          return (
          <div >  
               <AutocompleteS datein={row.date_add} agentin={row.user_add} dateupd={row.date_upd} agentupd={row.user_upd}
                              service={service} action={'updbon'}  defaultValue={row.saller} row={row} list={userL} 
                              id={'saller_id'} name='saller' attr='name' onOpen={handleOpenUsers} handleAlert={handleAlert}/>
          </div> 
          );
       },
    },
    {
        name: "MONTANT",
        minWidth:'150px !important',
        style:numberColumn,
        selector: row=>row.mtn,
        sortable: true,
        sortField:'mtn',
        cell: (row) => {
          return (
            <div id={row.DT_RowId}> {new Intl.NumberFormat().format(row.mtn)} </div>
           );
         },
    },
    {
        name: "NOTE",
        selector: row=>row.note,
        minWidth:'200px !important',
        sortable: true,
        sortField:'note',
        cell: (row) => {
          return (
            <div className="note-container">
            { (row.note===selecto && row.DT_RowId===Item)?
            <ClickAwayListener onClickAway={()=>{(note)?handleOnEditNote(note,row,"note",'updbon'):setSelecto('')}}>
             <TextareaAutosize style={textAreaEdit} autoFocus onFocus={(e)=>{ e.target.value=note}} onChange={(e)=>{setNote(e.target.value);}}
             /></ClickAwayListener>:
             (((row.note&&row.note.match(/\n/g)) || []).length>=1)?
             <Tooltip title={row.note.replaceAll("\n", "/ ")}>
                 <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}}>{row.note.substring(0, row.note.indexOf("\n"))+"..."}</div>
             </Tooltip>:
             <Tooltip title={(row.note)?row.note:''}>
             <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}} >{row.note}</div></Tooltip>}
             </div>
           );
         },
    },
    {
      right:true,
      cell: (row) => {
        return (
          <div id="iconsAch" >
            <div className='rowGrid' id="row-1" >
              <Detail_Bon icon={true} num="numero" row={row} handleFetchMag={handleFetchMag} mag={mag}/>
              <Tooltip title="Supprimer">
                <div onClick={()=>handleDeleteBon(row)} >
                  <IndeterminateCheckBox  fontSize="small" className={classes.redIcon}/> 
                </div> 
              </Tooltip >
            </div>      
          </div>
         );
       },
    }
    ],[Gfunc,frnsL,Item,selecto,ItemN,classes,userL, mag,data,note,service,setItem,setSelecto,handleSelect,handleDeleteBon,handleRowClick,
       handleOpenFrns,handleOpenUsers,handleFetchMag,handleOnEditNote,]);

    const columnsD =useMemo(
      () => [   
        {
          name: "PAR",
          minWidth:'130px !important',
          selector: row=>row.userCreate,
          sortable: true,
          sortField:'userCreate',
      },
      {
          name: "PRODUIT",
          minWidth:'270px !important',
          selector: row=>row.produit,
          sortable: true,
          sortField:'produit',
      },
      {
          name: "QTE.",
          minWidth:'60px !important',
          style:numberColumn,
          selector: row=>row.qte,
          sortField:'qte',
          sortable: true,
          cell: (row) => {
            return (
                <InputNbrEditable datein={row.dateCreate} agentin={row.userCreate} dateupd={row.dateUpdate} agentupd={row.userUpdate}
                                  service={service} action='updach' min={0} row={row} att="qte" value={row.qte} id='id' handleAlert={handleAlert}
                                  traetResp={(resp)=>{
                                    if(resp!==undefined&&resp['jData']){ 
                                      const index=Gfunc.getIndex(data,clickedRow,'DT_RowId')
                                      data[index]['mtn']=resp.jData['mtnBon']
                                      const index2=Gfunc.getIndex(data2,row.id,'id')
                                      data2[index2]['qte']=row.qte
                                      setData2(data2)
                                      setControl(!control)
                                      Gfunc.updateElem(clickedRow,resp.jData['mtnBon'])
                                      Gfunc.updateElem(row.id,resp.jData['mtnAchat'])
                                      }
                                  }}
                />
             );
           },
      },
      {
          name: "PRIX",
          minWidth:'110px !important',
          style:numberColumn,
          selector: row=>row.achat,
          sortable: true,
          sortField:'prix',
          cell:(row)=>{
            return ( <div>{new Intl.NumberFormat().format(row.achat)}</div>)
          }
      },
      {
          name: "TOTAL",
          minWidth:'130px !important',
          style:numberColumn,
          selector: row=>row.mtn,
          sortable: true,
          sortField:'mtn',
          cell:(row)=>{
            return (<div id={row.id}>{new Intl.NumberFormat().format(row.mtn)}</div>)
          }
      },  
      {   
          right:true,
          minWidth:'70px !important',
          cell: (row) => {
            return (
              <div id="iconsAch" >
               <div className='rowGrid' id="row-2" >
                  <ImprimerEtiqPlus produit={row.produit} pdetId={row.produitId} prix={row.vente} qte={data2[Gfunc.getIndex(data2,row.id,'id')].qte}/>
                  <ImprimerEtiq produit={row.produit} pdetId={row.produitId} prix={row.vente}/>
                  <Tooltip title="Supprimer">
                    <IndeterminateCheckBox fontSize="small"  onClick={()=>handleDeleteAchat(row)} className={classes.redIcon}/>
                  </Tooltip >
               </div> 
               </div>
             );
           },
      }  
      ],[Gfunc,classes,clickedRow,data,data2,control,handleDeleteAchat]);
 
  
  return (
    <div className="content" > 
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} autoFocus/>
        <div className='table-header'>
             <div className="button-flex-wrapper">
                <AddNvBn  frnsL={frnsL} userL={userL} callback={()=>fetch2(1)}/>
                <AddVers  param={param} frnsL={frnsL} handleOpenFrns={handleOpenFrns} userL={userL} handleOpenUsers={handleOpenUsers} />
                <AddCrnc param={param}  />
            </div>  
            <div className='search'>
            {(doSearch)?
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField className={classes.search} id="input-with-sx" label="Recherche" value={search} variant="outlined"
                           InputProps={{startAdornment: (<Search onClick={() => handleSearch()} 
                           style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:'pointer' }} />),}}
                           size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
              </Box>
            :null} 
          </div>
          </div>
            { (data.length!==0||doSearch)?<div id={(data.length<=5)?"bonDT":"bonDT2"}>
              <DataTable 
                  key="tab1"
                  columns={columns}
                  data={data}
                  title="Liste des bons d'achat"
                  noDataComponent={param.ErreurData}
                  paginationRowsPerPageOptions={[5,10,20,50]}
                  progressPending={loading}
                  progressComponent={<CircularProgress className={classes.circularProgress} />}
                  pagination
                  paginationServer
                  fixedHeader={true}
                  paginationTotalRows={totalRows}
                  paginationPerPage={5}
                  responsive={true}     
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  sortServer={true}
                  onSort={handleOnSort}
                  highlightOnHover
                  customStyles={customStyles}
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}            
                  conditionalRowStyles = { conditionalRowStyles }
                />
             </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}

             {clickedRow!==''?
            <div>
                  <div className="table-header">
                  <div className="button-flex-wrapper" >
                   < ImprimerEtiqAll data={data2}/>
                  </div> 
                  <div className="search">
                     <AddAch idb={clickedRow.substr(1)} data={data2} bon={clickedRow} callback={fetchDetails} handleAlert={handleAlert}/>
                  </div>
                  </div>
                  <div id={(data2.length<=5)?"achatDT":"achatDT2"}>
                    <DataTable 
                        key="tab2"
                        columns={columnsD}
                        data={data2}
                        title={"Détail du bon "+(num??'')+" ["+achatNum+"]"}
                        progressPending={loading2}
                        noDataComponent={param.ErreurData}
                        fixedHeader={true}
                        progressComponent={<CircularProgress className={classes.circularProgress} />}
                        highlightOnHover
                        customStyles={customStyles}
                      />
                  </div>
              </div>:<></>}

    </div> 
  );
}