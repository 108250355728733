import React from "react";
import "../../css/about.css"
const version=require('../../version.json')




export default function About(){
    return(
        <div className="content">
        <table id="about">
        <caption >Historique des versions</caption>
            <thead id='theadA'>
                <tr>
                <th>VERSION</th>
                <th>DATE</th>
                <th>DESCRIPTION</th>
                </tr>
            </thead> 
            <tbody>
            {version.map((item,index)=>{
                return(
                    <tr key={index}>
                       <td id="datvers">{item.build}</td>
                       <td id="datvers">{item.date}</td>
                       <td><ul>{item.features.map((elem,index)=>{
                           return(
                               <li key={index}>
                                {elem}
                               </li>
                           )
                       })}</ul></td>
                    </tr>
                )
            })}  
            </tbody>
</table>
        </div>
    )
}