import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import {CircularProgress, Tooltip} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Link} from "react-router-dom";
import {customStyles,mtnControl,numberColumn} from '../../css/datatable-custom'
import { useStyles } from '../../css/muiStyle';
import Draggable from '../content/modals/ref_inv_add1'
import Draggable1 from '../content/modals/ref_inv_add2'
import {Delete,Search,Build,Lock,PlaylistAddCheck} from '@material-ui/icons';
import {AlertCompo,NoDataComponent,NotAllowedComponent} from "../../js/components"
import { Box } from "@mui/system";
import {theme} from "../../css/theme"
import '../../css/datatable.css';
import '../../css/global.css'


export default function Ref_inv (props){
  const classes = useStyles();
  const librery=require('../../js/data_table_librery')
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const [data, setData] = useState([]);
  const [,setDel] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const service='_inv.php'
  const[noData,setNoData]=useState(false); 
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[doSearch,setDoSearch]=useState(false);
  const[called,setCalled]=useState(false);

  const fetch=useCallback(async(page,servic = service, size = perPage,val) => {
    setCalled(true)
    setLoading(true);
    const response=await librery.axiosGet(page+'&search='+((val!==undefined)?val:''),servic,size)
    if(response.data.err&&response.data.err!=="-1"){
      setErrMessage(response.data.err)
      setNotAllowed(true)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      setDoSearch(true)
      setNoData(true)
      }else {setDoSearch(false);setData([])}
    })
   setLoading(false)
  },[Gfunc,param.urlService,perPage])

  useEffect(() => {
    if(!called) { fetch(1);}
    Gfunc.loseFocus(".content-editable")
  }, [fetch,called,Gfunc]);

  //delete itam
const handleSetData=useCallback((row,att)=>{
  Gfunc.removeItemsByAtt(data,row.DT_RowId,att)
  setDel('')
},[data,Gfunc])
  
 const handleDeleteItem=useCallback(async(row)=>{
    const x=window.confirm("Êtes-vous sûrs de supprimer cet élément?");
    if(x){
    if(row.clotured===false && row.locked===false){
      const response=await Gfunc.axiosPostAction(service ,"del",{idi:row.DT_RowId.substr(1)})
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==0){
          handleAlert(true,"success","L'inventaire est supprimé")
          handleSetData(row,"DT_RowId")
          setDel(row.DT_RowId)
        }
        })
    } 
   }
  },[Gfunc,handleSetData,service])

  const handleOnEdit=useCallback(async(e,row,att)=>{
    e.preventDefault();
    const edt=e.target.innerHTML
    const edt1=Gfunc.DeleteSpace(edt)
    if(att==='nom' ){
      if(row.inventaire!==edt1){
        const attrib=row.categorie;
        const response=await librery.axiosEdit(service,row,edt1,att) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{e.target.innerHTML=edt1;})
        if(response.data.err) {setLoading(true); row.categorie=attrib; setLoading(false)}
      }else{e.target.innerHTML=edt1}
    }
  },[librery,Gfunc]);

  const columns =useMemo(
    () => [ 
      {
          name: "NOM",
          minWidth:'200px !important',
          selector: row=>row.inventaire,
          sortable: true,
          sortField:'inventaire',
          cell: (row) => {
            return (
             <ContentEditable
                html={row.inventaire}
                name="inventaire"
                data-column="item"
                className="content-editable"
                disabled={false}
                onBlur={e => handleOnEdit(e,row,"nom")}
             />
             );
           },
      },
      {
        name: "Catégorie",
        minWidth:'150px !important',
        selector:row=>row.categorie,
        sortable: true,  
        sortField:'categorie',
      },
      {
        name: "Date",
        minWidth:'100px !important',
        selector:row=>row.datin,
        sortable: true,
        sortField:'datin',
      },
      {
        name: "Utilisateur",
        width:'160px !important',
        selector:row=>row.user,
        sortable: true,
        sortField:'user',
      },
      {
        name: "Produits",
        width:'130px !important',
        selector:row=>row.qte,
        style:numberColumn,
        sortable: true,
        sortField:'qte',
      },
      {
        name: "Montant",
        selector:row=>row.mtn,
        width:'130px !important',
        conditionalCellStyles:mtnControl,
        sortField:'mtn',
        sortable: true,
        cell: (row) => {
          return (
            <div>{new Intl.NumberFormat().format(row.mtn)} </div>
          )
        }
      },
      {
        right:true,
        cell: (row) => {
          return (
            <div style={{display:'grid',gridTemplateColumns:'50% 50%'}}>
              {
               (row.clotured===false && row.locked===false)? 
                <Tooltip title="Supprimer l'inventaire">
                  <Delete fontSize="small" style={{color:theme.palette.error.main}} className={classes.tdIcon} onClick={()=>{handleDeleteItem(row)}} />
                </Tooltip>:<></>
              } 
              <Tooltip title={(row.locked===false)?"Verrouiller l'inventaire":(row.clotured===false)?"Clôturer l'inventaire":"Résultat l'inventaire"}>
                {
                (row.locked===false)
                ?<Link to={{pathname: "/r/ri/dr" ,row:row}} ><Lock style={{color:theme.palette.secondary.dark}} fontSize="small" className={classes.tdIcon} /></Link >
                :(row.clotured===false)
                ?<Link to={{pathname: "/r/ri/cr" ,row1:row}} ><Build  style={{color:theme.palette.secondary.dark}} fontSize="small" className={classes.tdIcon} /></Link>
                :<Link to={{pathname: "/r/ri/fi" ,row1:row}} ><PlaylistAddCheck  fontSize="small" style={{color:theme.palette.success.main}} /></Link>}
             </Tooltip >
           </div>
          );
        },   
       }
  
    ],[handleDeleteItem,handleOnEdit,classes]);
   
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,'get')
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      })     
  }

  const handleSearch=async(size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,'get')
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
    return recherche
  }

  //handle alerts properties
  const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

function handlePageChange (page) {
  fetch(page,service,perPage,search);
  setCurrentPage(page);
}

 const handlePerRowsChange = async (newPerPage, page) => {
  fetch(page,service, newPerPage,search);
  setPerPage(newPerPage);
}

//Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
}

function liveSearch2(e, search) {
  handleSearch();
}

return (
  <div className="content">
      <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
  <div  className="table-header">
      <div className='button-flex-wrapper'>
          <Draggable param={param} callback={()=>fetch(1)} handleAlert={handleAlert} />
          <Draggable1 param={param} callback={()=>fetch(1)} handleAlert={handleAlert} />
      </div>
      {(doSearch)?
        <div className='search' >
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField className={classes.search} variant="outlined" label="Recherche" value={search} 
                             InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                             style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer" }} />),}}
                             size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
            </Box> 
        </div>:null}
     </div>
     {(data.length!==0||doSearch)?<div>
           <DataTable 
              persistTableHead
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              defaultSortAsc={true}
              paginationRowsPerPageOptions={[15,20,30]}
              progressPending={loading}
              pagination
              paginationServer
              perPage={perPage}
              paginationPerPage={15}
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles}   
              selectableRowsComponentProps={{ className: classes.checkStelect }} 
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
            /></div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
    </div> 

  );
}