import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import {CircularProgress, Box, ClickAwayListener, TextareaAutosize, Tooltip} from '@material-ui/core';
import {Search,Lock,LockOpen, Star, StarOutline} from '@material-ui/icons';
import AddPck from './modals/ref_pck_add'
import AddToPck from './modals/ref_pck_addToPack'
import { textAreaEdit, useStyles } from '../../css/muiStyle';
import {conditionalRowStyles,customStyles, numberColumn}from '../../css/datatable-custom'
import { InputNbrEditable, NoDataComponent,NotAllowedComponent} from "../../js/components"
import {AlertCompo} from "../../js/components"


export default function Ref_pck (props){
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [detail,setDetail]=useState([]);
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState(false);
  const [lastClicked, setLastClicked] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [Icon,setIcon]=useState('');
  const service='_pck.php'
  const srv="pack"
  const classes=useStyles();
  const[noData,]=useState(false);
  const[doSearch,setDoSearch]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,]=useState('');
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const [openA, setOpenA] =useState(false);
  const [prodL,setProdL]=useState([]);
  const [selecto,setSelecto]=useState('')
  const [Item,setItem]=useState('')
  const [ItemN,setItemN]=useState('')
  const [note,setNote]=useState('')
  const [currentRow, setCurrentRow] = useState(null);

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

 //fetch marque
  const fetch= useCallback(async(page,servic = service, size = perPage) => {
    setFocus(true)
    setLoading(true);
    const response=await librery.axiosGet(page,servic,size)
    if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,"error",response.data.err)
       setNotAllowed(true)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered); 
        setDoSearch(true) 
      }     
    })  
  setLoading(false);  
  },[Gfunc,librery,perPage]);

  useEffect(() => {
    if(!focus){fetch(1);}
    Gfunc.loseFocus('.content-editable')
  }, [fetch,Gfunc,focus]);
  
  const flagMan =useCallback(async(row,att)=>{
    var datt=[...data]
    const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: row.DT_RowId.substr(1),att:att, val: (row[att])?1:0 }))
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{ 
      if (response.data.jData) {
        const objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId));
        //setIcon(row.DT_RowId)
        if(row[att]){
          datt[objIndex][att]=(row[att]===1)?0:false
        }else datt[objIndex][att]=(row[att]===0)?1:true
        //setIcon('')  
        setData(datt)
      }else handleAlert(true,'error',response.data.err);   
   })
   
},[Gfunc,data])

  //update field
  const handleOnEdit=useCallback(async(e,row,att)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
        if(row[att]!==edt){
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt,att) 
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,"error",response.data.err)
         }else
          Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
          if(response.data.err) {setLoading(true);e.target.innerHTML=attrib;setLoading(false)}
        }else {e.target.innerHTML=edt}
  },[librery,Gfunc]);

//icon change handler
const handleLockIcon=(val)=>{
  setIcon(val);
}
useEffect((val) => {
handleLockIcon(val);
}, []);


const handleSelect=useCallback((row,att)=>{
  setSelecto(row[att])
  setItem(row.DT_RowId)
  setItemN(att)
},[])


 //handle update note
 const handleOnEditNote=useCallback(async(note,row,att,action)=>{
  const edt1=note
  var edt=Gfunc.DeleteSpace(edt1);
  if(row[att]!==edt){
    edt=edt.replaceAll("\n", "\r")
    const attrib=row[att];
    const response=await librery.axiosEdit2(service,action,row,edt,att) 
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{row[att]=edt})
    if(response.data.err) {row[att]=attrib}
  }else {row[att]=edt}
  setNote('')
  setSelecto('')
},[librery,Gfunc]);

  // fetch detail de pack 
  const fetchDetail= useCallback(async(id) => { 
    var dat=[]
    if(lastClicked!==id){
      setDetail([])
      setLastClicked(id)
    }
    
    const response = await Gfunc.axiosPostAction("_pck.php","getpack",{packId:id})
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,"error",response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData)
      dat=response.data.jData;
    })
    setDetail(dat)
  },[Gfunc]);

const columns =useMemo(
    () => [   
      {
          name: "pack",
          minWidth:"280px !important",
          selector:row=>row.pack,
          sortable: true,
          sortField:"pack",
          cell: (row) => {
            return (
          <div>
            <ContentEditable
              html={(row.marque!==null)?row.pack:''}
              name="pack"
              data-column="item"
              className="content-editable"
              disabled={false} 
              onBlur={e => handleOnEdit(e,row,'pack')}
            />
          </div >
             );
           },
      },
      {
        name: "Montant",
        minWidth:'130px !important',
        style:numberColumn,
        selector: row=>row.mtn,
        sortable: true,
        sortField:'mtn',
        cell:(row)=>{
          return ( <div id={"m"+(row.DT_RowId.substr(1))}>{new Intl.NumberFormat().format(row.mtn)}</div>)
        }
    },
    {
      name: "Note",
      selector:row=>row.note,
      sortable: true,
      minWidth:'160px !important',
      sortField:'note',
      cell: (row) => {
        return (
          <div className="note-container">
          { (row.note===selecto && ItemN==='note' && row.DT_RowId===Item)?
          <ClickAwayListener onClickAway={()=>{(note)?handleOnEditNote(note,row,"note",'upd'):setSelecto('')}}>
           <TextareaAutosize style={textAreaEdit} autoFocus onFocus={(e)=>{ e.target.value=note}}  onChange={(e)=>{setNote(e.target.value);}}
           /></ClickAwayListener>:
           ((row.note&&row.note.match(/\r/g) || []).length>=1)?
           <Tooltip title={row.note.replaceAll("\r", "/ ")}>
               <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}}>{row.note.substring(0, row.note.indexOf("\r"))+"..."}</div>
           </Tooltip>:
             <Tooltip title={row.note?row.note:''}>
           <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}} >{row.note}</div></Tooltip>}
           </div>
         );
       },
    },
      {
        name: "DATE",
        minWidth:"100px !important",
        selector:row=>row.date,
        sortable: true,
        sortField:"date"
      },
      {
        name: "Agent",
        minWidth:"160px !important",
        selector: row=>row.agent,
        sortable: true,
        sortField:"agent"
      },
      { 
        right:true,
        cell: (row) => {
          if(Icon===row.DT_RowId){
            if(row.activ===1)
            row.activ=0
            else
            row.activ=1   
            setTimeout(() => { handleLockIcon('')},1) ;         
          }
          return (
            <div className="rowGrid threeIcons">
              <div onClick={()=>flagMan(row,"favorite")} data-text="Favoriser/Défavoriser" >
                <Tooltip title="Favoriser / Défavoriser">
                 {(row.favorite)?
                   <Star className={classes.yellowIcon} fontSize="small"/>
                   :<StarOutline className={classes.yellowIcon} fontSize="small"/> }
                </Tooltip>
              </div> 
              <AddToPck handleAlert={handleAlert} id={parseInt(row.DT_RowId.substr(1))} pack={row.pack} callback={fetchDetail} />
              <div onClick={()=>flagMan(row,"activ")}>
               <Tooltip title="Activer / Désactiver">
                {(row.activ)?<LockOpen className={classes.redIcon} fontSize="small"/>:<Lock className={classes.greenIcon} fontSize="small"/> }
               </Tooltip>
              </div>        
            </div>      
           );
         },
      }  
  ],[flagMan,handleOnEdit,fetchDetail,handleAlert,handleOnEditNote,handleSelect,textAreaEdit,selecto,Item,ItemN,note,Icon,classes]);
  

  // a style applied when the condition is verified 
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
    setLoading(true);
    const response=await librery.axiosSort(column,sortdirection,page,size,service,'get')
    if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      })  
   }   
  }

     //recherche serverside
  const handleSearch=async(size = perPage)=>{
    setLoading(true);
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,"get")
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
   }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        }else setData([])
      })  
      setLoading(false); 
      return recherche
  }
  
  function handlePageChange (page) {
    fetch(page,service,perPage);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,service, newPerPage);
    setPerPage(newPerPage);
  };
  
  //Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }
  function liveSearch2() {
    handleSearch();
  }

        //fetch Products
    const fetchProd= useCallback(async(serv="_prd.php", act = "gets") => {
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          setProdL(response.data.jData); 
        }   
      })
    }
    },[Gfunc])

     //handle fetch magasin
     const handleFetchProd=useCallback(()=>{
      if(prodL.length===0)
      fetchProd();
    },[fetchProd,prodL])

  const ExpanableComponent = ({ data ,datta,setDatta,handleFetchProd,detail,lastClicked,fetchDetail,handleAlert}) => {
    const [called,setCalled]=useState(false);
    const [control,setControl]=useState(false);
    const [loadingPacks,setLoadingPacks]=useState(false)

    // fetch detail de pack 
    const fetchDet= useCallback(async() => { 
      setLoadingPacks(true)
      await fetchDetail(parseInt(data.DT_RowId.substr(1)))
      handleFetchProd()
      setCalled(true)
      setLoadingPacks(false)
    },[Gfunc,fetchDetail,handleFetchProd,data]);

    useEffect(() => {
      if(!called&&lastClicked!==parseInt(data.DT_RowId.substr(1)))
      fetchDet();
      return ()=>{
        setCalled(true)
      }
    }, [fetchDet,lastClicked,data,called]);


      return (
        <div className="row-expenderr" id="detVBon">
          <div className='flex-wrapper' style={{padding:'5px',justifyContent:'center',width:'100%'}}>
              { (loadingPacks)?<div id="empty-expender"><CircularProgress /></div>:(detail&&detail.details)?
               <table className="row-expenderr" key={control}>
                <thead>
                <tr>
                  <th>Produit</th>
                  <th>Qte.</th>
                  <th>Montant</th>
                  <th>Date</th>
                  <th>Agent</th>
                </tr>
                </thead>
                <tbody>
                  {
                    detail.details.map((el,index)=>{
                      return(
                        <tr key={index}>
                          <td >{el.produit}</td>
                          <td className='updQte'><InputNbrEditable
                                                      supp={parseInt(data.DT_RowId.substr(1))} suppAtt="packId"
                                                     // positif={true}
                                                      service={service} action='upddet'
                                                      row={el} att="qte" value={el.qte} id='id'
                                                      handleAlert={handleAlert}
                                                  /></td>
                          <td className='updMtn'><InputNbrEditable
                                                      supp={parseInt(data.DT_RowId.substr(1))} suppAtt="packId"
                                                      //min={0} positif={true}
                                                      service={service} action='upddet'
                                                      row={el} att="mtn" value={el.mtn} id='id'
                                                      handleAlert={handleAlert}
                                                      traetResp={(resp)=>{
                                                       if(resp!==undefined&&resp['jData']){ 
                                                          const index=Gfunc.getIndex(datta,data.DT_RowId,'DT_RowId')
                                                          datta[index]['mtn']=resp.jData['mtnPack']
                                                          setDatta(datta)
                                                          setControl(!control)
                                                          Gfunc.updateElem("m"+data.DT_RowId.substr(1),resp.jData['mtnPack'])
                                                          }
                                                      }}
                                                  />
                         </td>
                          <td>{el.date}</td>
                          <td>{el.agent}</td>
                          <td >{el.stock}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>:null}
          </div>
     </div>
      )
  }

  return (
    <div className="content">
       <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
        <div className='table-header'>
          <div className='button-flex-wrapper'><AddPck callback={()=>fetch(currentPage)} handleAlert={handleAlert}/></div>
          {(doSearch)?
          <div className='search'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:'pointer' }} />),}}
                               size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
              </Box>
            </div>:null}
        </div>
        {(data.length!==0||doSearch)?
            <div id='exp-DT'>
            <DataTable 
            persistTableHead
              expandOnRowClicked
              expandableRows 
              expandableRowsComponent={(props)=><ExpanableComponent {...props} datta={data} setDatta={setData} handleFetchProd={handleFetchProd} prodL={prodL} fetchDetail={fetchDetail} detail={detail} lastClicked={lastClicked} handleAlert={handleAlert}/>} 
              onRowClicked={(row) => setCurrentRow(row)}
              onRowExpandToggled={(bool, row) => setCurrentRow(row)}
              expandableRowExpanded={(row) => (row === currentRow)}
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              noHeader={true}
              highlightOnHover
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
            /></div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      
    </div>    

  );
}