import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import TextField from '@material-ui/core/TextField';
import ContentEditable from 'react-contenteditable';
import {CircularProgress,FormControlLabel,Checkbox,Tooltip} from '@material-ui/core';
import Smile_Off from '../../img/smile_off.png'
import Smile_On from '../../img/smile.png'
import {Search,LockOpen,Lock,Straighten} from '@material-ui/icons';
import {conditionalRowStyles,customStyles}from '../../css/datatable-custom'
import { useStyles } from '../../css/muiStyle';
import {AlertCompo,NoDataComponent,NotAllowedComponent,AutocompleteS,InputNbrEditable,DatePickerUpd, ImprimerEtiq} from "../../js/components"
import { Box } from "@mui/system";
import {numberColumn} from '../../css/datatable-custom'
import Autocomplete from '@mui/material/Autocomplete';
import DraggableDialog from './modals/ref_prd_add'
import DraggableDialogCB from './modals/ref_prd_codeBar'
import DraggableD from './modals/mon_lst_prd_add'
import  DialogAjsut from "../content/modals/ref_prd_details_ajust";
import  Dialogtrt from "../content/modals/ach_trt_add";
import  DialogCB from "../content/modals/ref_prd_cb";
import  DialogStk from "./modals/ref_prd_detail_stk";
import  DialogHst from "./modals/ref_prd_hst"; 
import  DialogRac from "./modals/ref_prd_rac";
import  DialogAllRac from "./modals/ref_prd_allRac";
import {ReactComponent as Limit} from '../../img/icons/seuil.svg'
import {ReactComponent as Brand} from '../../img/icons/brand.svg'
import './../../css/monitoring.css'
import './../../css/referentiel.css'
import './../../css/achat.css'


export default function Ref_prd(props){
  const lodashClonedeep = require('lodash.clonedeep');
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [catL, setCatL] = useState([]);
  const [catTL, setCatTL] = useState([]);
  const [,setSelecto]=useState('')
  const [,setItem]=useState('')
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setTest] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [cat, setCat] =useState('');
  const [stk, setStk] =useState('');
  const [records, setRecords] =useState('');
  const [,setIcon]=useState('');
  const [prodL, setProdL] = useState([]);
  const [stkL, setStkL] = useState([]);
  const [stkLT, setStkLT] = useState([]);
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const[noData,setNoData]=useState(false); 
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[doSearch,setDoSearch]=useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const service='_prd.php'
  const srv='produit'
  const srv1='prod_det'
  const classes=useStyles()
 
   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  const fetch= async(page,cat,stok,servic = service, size = perPage,value) => {
    setLoading(true);
    setCalled(true);
    const response= await librery.axiosGet2(page,servic,'get&search='+((value!==undefined)?value:'')+'&cat='+((cat!==undefined&&cat!=='empty')?cat:'')+'&stk='+((stok!==undefined&&stok!=='empty')?stok:''),size);
      if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else{
       Gfunc.axiosResponse(response,()=>{
        setRecords(response.data.recordsFiltered)
        if(response.data.data!==undefined){
         /* let dat=[...response.data.data]
          if(stk!==""){
            dat=Gfunc.removeItemAll(dat,0,"stock")
          }*/
          setData(response.data.data);
          setTotalRows(response.data.recordsFiltered);
          setDoSearch(true)
          setNoData(true)
        } else { setData([]) }
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    if(!called){ fetch(1); }
   Gfunc.loseFocus('.content-editable')
  }, [fetch,called]);
    
  //fetch Products
  const fetchProd= useCallback(async(serv="_prd.php", act = "gets") => {
        const response = await Gfunc.axiosGetAction(serv,act);
        if(response.data.err&&response.data.err!=='-1'){
           handleAlert(true,'error',response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{
            setProdL(response.data.jData);    
        })
      }
      },[Gfunc])

  //fetch categorie
  const fetchCat= useCallback(async(serv="_cat.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
     if(response.data.err&&response.data.err!=="-1"){
       handleAlert(true,'error',response.data.err)
     }else{
      Gfunc.axiosResponse(response,()=>{
        setCatL(lodashClonedeep(response.data.jData));    
        response.data.jData.unshift({id:'empty',nom:'Tous'})
        setCatTL(response.data.jData)
     })
     }
    
  },[Gfunc,lodashClonedeep])

  //fetch stocks
  const fetchStk= useCallback(async(id) => {
    const response = await Gfunc.axiosGetAction('_stk.php',"gets")
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({id:'empty',nom:'Tous'})
        setStkL(response.data.jData);   
    })
  },[Gfunc]);  

  //fetch stocks
  const fetchStkT= useCallback(async(id) => {
    const response = await Gfunc.axiosGetAction('_stk.php',"gets")
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        setStkLT(response.data.jData);   
    })
  },[Gfunc]);  

  //fetch categorie handler
  const handleOpenCat = useCallback(async () => {
      if(catL.length===0) fetchCat()
      },[catL,fetchCat])

  //fetch stock handler
  const handleOpenStk = useCallback(async () => {
      if(stkL.length===0)fetchStk()
  },[stkL,fetchStk])

  const handleOnEdit=useCallback(async(e,row,att)=>{
    e.preventDefault();
    const edt=e.target.innerHTML
    const edt1=Gfunc.DeleteSpace(edt)
    if(att==='produit' ){
      if(row[att]!==edt1 && edt1.length>3){
        const attrib=row[att];
        const response=await librery.axiosEdit(service,row,edt1,att) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{e.target.innerHTML=edt1;})
        if(response.data.err) {setLoading(true); row[att]=attrib; setLoading(false)}
      }else{
         if(edt1.length<=3){handleAlert(true,'error',"Vous devez saisir plus de caractères")}
         e.target.innerHTML=row[att]
       // handleAlert(true,'error','Veuillez  saisir plus de caractères')
      }
    }
  },[librery,Gfunc]);


/*const handleSelect=useCallback((row,att)=>{
    setSelecto(row[att])
    setItem(row.DT_RowId)
    setItemN(att)
 },[])*/

const handleVider=(val)=>{
  setIcon(val)
}
const flagMan = useCallback(async(e,row,att,vall)=>{
  var objIndex=""
  var val=''
  if(att!=='activ'){
    val=(row[att])===true?1:0
  }else{
    val=row[att]
  }
    if(vall==='principale'){
       objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId));
    } 
    const response= await Gfunc.axiosPostAction('flagman.php','',({ srv:(vall==='principale')?srv:srv1, id:(vall==='principale')?row.DT_RowId.substr(1):row.id,att:att, val:val }))
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        if(att!=='activ'){
            if(row[att]){
              data[objIndex][att]=false
            }else data[objIndex][att]=true
        }else{
        if(vall==='principale'){
          if(row[att]){
            data[objIndex][att]=0
          }else data[objIndex][att]=1
        }else{
          if(row[att]){
            row[att]=0
          }else row[att]=1
        }
        } 
            setIcon(row.DT_RowId) 
            handleVider('')
              })
          }
  },[Gfunc,data]);
  
  const handleTest=(val)=>{
    setTest(val)
  }
  const DelPrdCatalogue=useCallback(async(row)=>{
    const response=await Gfunc.axiosPostAction(service,"delcat",{produitId:parseInt(row.DT_RowId.substr(1))})
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,"error",response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData===true){
        row.catalog=false
        setTest(true)
        handleTest(false)
      }
   })
  },[Gfunc])

  const AddPrdCatalogue=useCallback(async(row)=>{
    const response=await Gfunc.axiosPostAction(service,"addcat",{produitId:parseInt(row.DT_RowId.substr(1))})
        if(response.data.err&&response.data.err!=="-1"){
           handleAlert(true,"error",response.data.err)
       }else
       Gfunc.axiosResponse(response,()=>{
          if(response.data.jData===true){
            row.catalog=true
            setTest(true)
            handleTest(false)
          }
       })
  },[Gfunc])
  
  const columns =useMemo(
    () => [ 
      {
         width:'55px !important',
         cell: (row) => {
             return (
                <div style={{display:"grid",gridTemplateColumns:'49% 49%',gridGap:'3px',alignItems:'center'}}>
                    <DialogHst param={param} row={row} val='prod' callback={()=>fetch(1)} setOpen={setOpen} handleAlert={handleAlert}/>
                    <DialogRac param={param} row={row} val='prod' callback={()=>fetch(1)} handleAlert={handleAlert}/>
                </div>
                   
             );
            },   
      },
      {
        name: "Produit",
        selector: row=>row.produit,
        sortable: true,
        minWidth:'400px !important',
        sortField:"produit",
        cell: (row) => {
            return (
              <div title={'ID: '+('00000000' + row.DT_RowId.substr(1)).substring(Math.min(("" + row.DT_RowId.substr(1)).length,8))} className='tooltip' >
                  <ContentEditable
                    html={(row.produit!==null)?row.produit:''}
                    name="prdd"
                    data-column="item"
                    className="content-editable"
                    disabled={false} 
                    onBlur={e => handleOnEdit(e,row,"produit")}
                  />
              </div>
             );
           },
      },  
      {
        name: "Categorie",
        selector:row=>row.categorie,
        minWidth:'140px !important',
        sortField:"categorie",
        sortable: true,
        cell: (row) => { 
            return (
            <div >  
            {
            <AutocompleteS
                  service={service} action={'upd'} 
                  defaultValue={row.categorie} row={row} list={catL} 
                  id={'categorieId'} name='categorie' attr='nom' 
                  onOpen={handleOpenCat} setLoading={setLoading} setItem={setItem} setSelecto={setSelecto}  handleAlert={handleAlert}/>
            }          
            </div> 
            );
        }
      },
      {
        name: "Depuis",
        selector:row=>row.dateIn,
        width:'110px !important',
        sortable:true,
        sortField:"dateIn",
      },
      {
        name: "Stock",
        selector:row=>row.stock,
        width:'100px !important',
        style:numberColumn,
        cell:(row)=>{
          return(
            <div id={'stck'+row.DT_RowId}>{new Intl.NumberFormat().format(row.stock)}</div>
          )
        }
      },
      {
        name: "Comptoire",
        selector:row=>row.onTop,
        sortable: true,
        width:'135px !important',
        sortField:"onTop",
        cell: (row) => {
            return (
              <div className="checkbox">
                <FormControlLabel control={<Checkbox checked={row.onTop} className={classes.checkBox}
                                           onChange={e=>flagMan(e,row,'onTop','principale')} inputProps={{ 'aria-label': 'controlled' }}  />}
                                            /> 
              </div>
             );
           },
      },
     
      {
        name: "Appro.",
        selector:row=>row.autoList,
        sortable: true,
        width:'120px !important',
        sortField:"autoList",
        cell: (row) => {
            return (
              <div className="checkbox">
                <FormControlLabel control={<Checkbox checked={row.autoList} className={classes.checkBox}
                                           onChange={e=>flagMan(e,row,'autoList','principale')} inputProps={{ 'aria-label': 'controlled' }}  />}
                                            /> 
              </div>
             );
           },
      },
      {
        right: 'true',
        cell: (row) => {
            return (
             <div id="iconsProd">
                <div className='rowGrid fiveIcons'  >
                        <DraggableD row={row} val='prod' callback={()=>fetch(1)} setOpen={setOpen} handleAlert={handleAlert}/>
                        <DialogCB row={row} val='prod' callback={()=>fetch(1)} setOpen={setOpen} handleAlert={handleAlert}/>
                        <DialogStk param={param} stk={stk} row={row} val='prod' callback={()=>fetch(1)} setOpen={setOpen} handleAlert={handleAlert}/>    
                      <Tooltip title="Activer/Désactiver">
                       <div onClick={e=>flagMan(e,row,'activ','principale')} >
                        {(row.activ)?<LockOpen className={classes.redIcon} fontSize="small"/>:<Lock className={classes.greenIcon} fontSize="small"/> }
                       </div>
                      </Tooltip>  
                      {
                    (JSON.parse(param.gros) === true) ?
                        <Tooltip title={(row.catalog)?'Supprimer le produit du catalogue':' Ajouter le produit au catalogue'}>
                       <div>
                          {
                          (row.catalog!=null)?
                          <img src={(row.catalog===true)?Smile_On:Smile_Off} alt='catalog' onClick={(row.catalog===true)?()=>DelPrdCatalogue(row):()=>AddPrdCatalogue(row)} style={{height:'22px',width:'22px',padding:"1px"}}/>
                          :<></>
                          }
                       </div> 
                       </Tooltip> :<></>
                      }       
                </div>
             </div>
            );
           },   
     },    
  ],[classes.redIcon,AddPrdCatalogue,DelPrdCatalogue,catL,classes.checkBox,classes.greenIcon,fetch,handleOnEdit,handleOpenCat,flagMan]);
  
 

  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,'get')
      if(response.data.err&&response.data.err!=='-1'){
         handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      })  
    }
  }

  const handleSearch=async( size = perPage)=>{
    var categorie=(cat!==undefined&&cat!=='empty')?cat:''
    var stock=(stk!==undefined&&stk!=='empty')?stk:''
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,'get&cat='+categorie+'&stk='+stock)
    if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,'error',response.data.data)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
  }
    return recherche
  }

  function handlePageChange (page) {
    fetch(page,cat,stk,service,perPage,search);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,cat,stk,service,newPerPage,search);
    setPerPage(newPerPage);
  };
  
  //Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }

  function liveSearch2() {
    handleSearch();
  }

  const ExpanableComponent =({data}) => {
    const [prod,setProd]=useState([]);
    const [msrL, setMsrL] = useState([]);
    const [mthL, setMthL] = useState([]);
    const [mrqL, setMrqL] = useState([]);
    const [selecto,setSelecto]=useState('')
    const [Item,setItem]=useState('')
    const [ItemN,setItemN]=useState('')
    const [control, setControl] = useState(false);
    const [loadingExp,setLoadingExp]=useState(false)
    const [called,setCalled]=useState(false)
    const [severityE, setSeverityE] =useState("info");
    const [openE, setOpenE] =useState(false);
    const [messageAE, setMessageAE] =useState('');
    
    //handle alerts properties
    const handleAlertE=(open,severity,message)=>{
      setOpenE(open)
      setSeverityE(severity)
      setMessageAE(message)
      setTimeout(function(){
        setOpenE(false)
      }, 5000);
    }
     //fetch details 
     const fetchP= useCallback(async() => {
      setCalled(true)
      setLoadingExp(true)
      var stock=(stk!=='empty'&&stk!==undefined)?stk:''
      const response=await Gfunc.axiosPostAction(service,"getdet&stk="+stock,{idp:parseInt(data.DT_RowId.substr(1))})
      setLoadingExp(false)
      if(response.data.err&&response.data.err!=='-1'){
         handleAlertE(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          setProd(response.data.jData);
        }
      })
    }
    },[data.DT_RowId,Gfunc,handleAlert])

    useEffect(() => {
      if(!called){fetchP();}
    }, [fetchP,called]);

    //fetch mesure
    const fetchMsr= useCallback(async(serv="_prd.php", act = "getmsr") => {
      const data=[]
      const response = await Gfunc.axiosGetAction(serv,act);
        if(response.data.err&&response.data.err!=='-1'){
          handleAlertE(true,'error',response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{
        Object.keys(response.data.jData).map((key, index)=> {
          data.push({id:key,name:response.data.jData[key]})
          return true
        })  
        setMsrL(data);    
      })
      }
     },[])

    //fetch methode
    const fetchMth= useCallback(async(serv="_prd.php", act = "getmth") => {
      var data=[];
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlertE(true,'error',response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          Object.keys(response.data.jData).map((key)=> {
              data.push({id:key,name:response.data.jData[key]})
            return true
          }) 
          data.unshift({id:null,name:'Aucune méthode de calcul'}) 
          setMthL(data);    
        })
     }
     },[Gfunc])

     //fetch meseur handler
     const handleOpenMsr = useCallback(async () => {
       if(msrL.length===0) fetchMsr()
      },[msrL,fetchMsr])

     //fetch methode handler
     const handleOpenMth = useCallback(async () => {
       if(mthL.length===0) fetchMth()
      },[mthL,fetchMth])
    
    const handleQte=(val,id,Fid)=>{
      const objIndex = prod.findIndex((obj => obj.id=== id)); 
      prod[objIndex]['stock']=val
      Gfunc.updateElem('stk'+id,val)
      Gfunc.updateElem('stck'+Fid,val)
    }

    const handleSelectE=useCallback((row,att)=>{
      setSelecto(row[att])
      if(att==='datePeremption'){
        setItem(row.id)
      }else{
        setItem(row.DT_RowId) 
      }
      setItemN(att)
    },[])

    //fetch marque
    const fetchMrq= useCallback(async(serv="_mrq.php", act = "gets") => {
      const response = await Gfunc.axiosGetAction(serv,act);
      Gfunc.axiosResponse(response,()=>{
        setMrqL(response.data.jData);    
    })
    },[Gfunc])

    //fetch marque handler
    const handleOpenMrq = useCallback(async () => {
      if(mrqL.length===0) fetchMrq()
    },[mrqL,fetchMrq])


  return (
      <div className="row-expenderr">
        <AlertCompo severity={severityE} open={openE} setOpen={setOpenE} message={messageAE} />
        <div id='info-expend'>
          <div id='item'>
              <Brand  style={{height:'25px',width:'25px'}}/>
              <p>Marque</p>
              <AutocompleteS service={service} action={'upd'}  defaultValue={data.marque} className="content-editable-exp"
                             row={data} list={mrqL} id={'marqueId'} name='marque' attr='name' onOpen={handleOpenMrq} 
                             setLoading={setLoading} setItem={setItem} setSelecto={setSelecto}  handleAlert={handleAlert}/>
          </div>
          <div id='item'>
              <Straighten  style={{height:'20px',width:'20px'}}/>
              <p>Mesure</p>
              <AutocompleteS service={service} action={'upd'}  defaultValue={data.mesure} row={data} list={msrL} className="content-editable-exp"
                             id={'mesureId'} name='mesure' attr='name' onOpen={handleOpenMsr} setLoading={setLoading} 
                             setItem={setItem} setSelecto={setSelecto} handleAlert={handleAlert}/>
          </div>
          <div id='item'>
              <Limit  style={{height:'20px',width:'20px'}}/>
              <p>Seuil</p>
              <InputNbrEditable service={service} action='upd' min={1} className="content-editable-exp"
                                row={data} att="limite" value={data.limite} id='DT_RowId' idi={data.DT_RowId} handleAlert={handleAlert}/>
          </div>

        </div><br/>
        <div className='table-scroll'>{
          (loadingExp)?<div id="empty-expender"><CircularProgress /></div>:
          (prod.length!==0)?
          <table style={{minWidth:'100%',maxWidth:'100%'}} >
            <thead>
            <tr>
              <th style={{minWidth:'100px',maxWidth:'100px'}}>Depuis</th>
              <th style={{minWidth:'100px',maxWidth:'100px'}}>Par</th>
              <th  style={{minWidth:'150px',minWidth:'150px'}}>Achat</th>
              <th  style={{minWidth:'150px',maxWidth:'150px'}}>Vente</th>
              <th  style={{width:'100px'}}>Péremption</th>
              <th  style={{minWidth:'250px',maxWidth:"250px"}}>Calcul</th>
              <th  style={{minWidth:'100px',maxWidth:"100px"}}>Modifié</th>
              <th  style={{minWidth:'100px',maxWidth:'100px'}}>Par</th>
              <th  style={{minWidth:'100px',maxWidth:'100px'}}>Quantité</th>
              <th  style={{minWidth:'120px',maxWidth:'120px'}}></th>
            </tr>
            </thead>
             <tbody>
              {
                prod.map((el,index)=>{
                  return(
                    <tr /*key={index+Math.floor(Math.random() * (1000 - 1 + 1)) + 1} */ style={{color:(el.activ===0)?'#bbb5b5':'black'}}>
                      <td style={{minWidth:'100px',maxWidth:'100px'}}>{el.dateIn}</td>
                      <td style={{minWidth:'100px',maxWidth:'100px'}}>{el.agentIn}</td>
                      <td style={{minWidth:'130px',maxWidth:'130px',textAlign:'right',paddingRight:'10px'}}>{new Intl.NumberFormat().format(el.prixAchat)}</td>
                      <td style={{minWidth:'150px',maxWidth:'150px'}}>
                      <InputNbrEditable service={service} action='upddet' min={0} row={el} att="prixVente" value={el.prixVente} id='id'
                                        handleAlert={handleAlert}
                                        traetResp={(resp)=>{
                                          if(resp!==undefined&&resp['jData']){ 
                                            prod[index]['prixVente']=el.prixVente
                                            setProd(prod)
                                            setControl(!control)
                                            }
                                        }}
                      />
                       </td>
                      <td style={{width:'100px'}}>
                        <DatePickerUpd selecto={selecto} Item={Item} ItemN={ItemN} row={el} setSelecto={setSelecto}  name="datePeremption" 
                                       minDate={new Date()} maxDate={Gfunc.LastXDay(-3000)} service={service} action='upddet'  extra='' 
                                       setItemN={setItemN}  handleSelect={handleSelectE} setItem={setItem} handleAlert={handleAlert} rowId='id'/>
                      </td>
                      <td style={{minWidth:'250px',maxWidth:'250px'}}>
                        <AutocompleteS service={service} action={'upddet'} rowId='id' defaultValue={el.methode} row={el} list={mthL} 
                                       id={'methodeId'} name='methode' attr='name' onOpen={handleOpenMth} setLoading={setLoading} 
                                       setItem={setItem} setSelecto={setSelecto} handleAlert={handleAlert}/>
                      </td>
                      <td style={{minWidth:'100px',maxWidth:'100px'}}>{el.dateUpd}</td>
                      <td style={{minWidth:'100px',maxWidth:'100px'}}>{el.agentUpd}</td>
                      <td id={'stk'+el.id} style={{minWidth:'100px',maxWidth:'100px',textAlign:'right',paddingRight:'10px'}}>{el.stock}</td>
                      <td style={{minWidth:'120px',maxWidth:"120px"}}> 
                       <div style={{display:'flex',alignItems:"center",gridGap:'5px'}}>
                       <Dialogtrt param={param} row={el} val='prod' callback={()=>fetch(1)} setOpen={setOpen} handleAlert={handleAlert} nom={data.produit}/>
                       <Tooltip title="Imprimer étiquette"><div> 
                        <ImprimerEtiq produit={data.produit} pdetId={parseInt(data.DT_RowId.substr(1))} prix={el.prixVente}/>
                        </div> 
                        </Tooltip>
                        <DialogAjsut handleAlert={handleAlert} row={el} handleQte={handleQte} Frow={data} />
                        <Tooltip title="Activer/Desactiver">
                        <div onClick={e=>flagMan(e,el,'activ','detail')} >
                        {(el.activ)?<LockOpen className={classes.redIcon} fontSize="small"/>:<Lock className={classes.greenIcon} fontSize="small"/> }
                       </div> 
                      </Tooltip>
                        </div>
                      </td>
                    </tr>
                    
                  )
                })
              }
             </tbody>
           </table>:<div id='empty-expender'>Aucune donnée .</div>
  }
           </div>
       </div>
     )
    }

  const handleChange=(val)=>{
      fetch(1,val,stk,service,perPage,search)
   }

  const handleChangeS=(val)=>{
      fetch(1,cat,val,service,perPage,search)
   }

  return (
    <div className="content">
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <div className='table-header'>
        <div className='button-flex-wrapper' id='mtn-lst-exp'>
         <DraggableDialog handleAlert={handleAlert} callback={()=>fetch(1)} open='prod' fetchProd={fetchProd} prodL={prodL} 
                          fetchStk={fetchStkT} stkL={stkLT}/>
         <DraggableDialogCB handleAlert={handleAlert} callback={()=>fetch(1)} setOpen={setOpen} />
         <DialogAllRac param={param} handleAlert={handleAlert} callback={()=>fetch(1)} setOpen={setOpen} />
          <Autocomplete
                disableClearable
                onChange={(e,newValue) =>{setCat(catTL[Gfunc.getIndex(catTL,newValue,"nom")].id);handleChange(catTL[Gfunc.getIndex(catTL,newValue,"nom")].id)}}
                // value={cat}
                onFocus={handleOpenCat}
                options={catTL.map((option) => option.nom)}
                className="smallAutoFiltre"
                renderInput={(params) =>  
                        <TextField margin='dense' size='small'{...params} variant="outlined"  label="CATÉGORIE" />}
          />
          <Autocomplete
                disableClearable
                onChange={(e,newValue) =>{setStk(stkL[Gfunc.getIndex(stkL,newValue,"nom")].id);
                                          handleChangeS(stkL[Gfunc.getIndex(stkL,newValue,"nom")].id)}}
                onFocus={handleOpenStk}
                options={stkL.map((option) => option.nom)}
                className="smallAutoFiltre"
                renderInput={(params) =>  
                        <TextField margin='dense' size='small' {...params} variant="outlined"  label="Stock"  />}
          />
         </div>
          {(doSearch)?
            <div className='search' >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField className={classes.search} variant="outlined" label="Recherche" value={search} 
                             InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                             style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:'pointer'}} />),}}
                             size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
              </Box>
            </div>:null}  
        </div>
        {(data.length!==0||doSearch)?
        <div id='exp-DT'>
          <DataTable 
              //key={Math.floor(Math.random() * (1000 - 1 + 1)) + 1}
              persistTableHead
              columns={columns}
              data={data}
              noHeader={true}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[15,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationPerPage={perPage}
              pagination
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles}
              expandOnRowClicked
              expandableRows 
              expandableRowsComponent={(props)=><ExpanableComponent {...props}/>}
              onRowClicked={(row) => setCurrentRow(row)}
              onRowExpandToggled={(bool, row) => setCurrentRow(row)}
              expandableRowExpanded={(row) => (row === currentRow)}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
        /></div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
        
    </div>    
  );
}
