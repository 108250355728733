import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField ,FormControl,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider,KeyboardDatePicker, KeyboardTimePicker} from "@material-ui/pickers";
import { AccessTimeOutlined, Today,Close, Add } from '@material-ui/icons';
import { AlertCompo } from '../../../js/components';
import { Autocomplete } from '@material-ui/lab';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


//add new bon
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ach_add_nvbn(props) {
    const Gfunc=require('../../../Gfunc')
    const [frns,setFrns]=useState('');
    const [user,setUser]=useState('');
    const [userN,setUserN]=useState('');
    const [note,setNote]=useState('');
    const [frnsL, setFrnsL] = useState([]);
    const [userL, setUserL] = useState([]);
    const [open, setOpen] =useState(false);

    const nom="bon"
    const addTitle="Ajouter un nouveau bon"
    const alertError="le "+nom+" est ajouté"
    const labelText1="Date"
    const labelText2="Fournisseur"
    const labelText3="Utilisateur"
    const labelText4="Note"
    const labelText5="Heure"
    const service="_ach.php"

    const classes=useStyles();
    const [openA, setOpenA] =useState(false);
    const [message, setMessage] =useState('');
    const [severity, setSeverity] =useState('info');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState(new Date());



    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
    //time handler
    const handleTimeChange = (date) => {
      setSelectedTime(date);
    };  
    //date handler
    const handleDateChange = (date) => {
      setSelectedDate(date);
    }; 

    //fetch fournisseurs
    const fetchFrns= useCallback(async(serv="_frs.php", act = "gets") => {
        const response = await Gfunc.axiosGetAction(serv,act);
        Gfunc.axiosResponse(response,()=>{
            setFrnsL(response.data.jData);    
        })
      },[Gfunc])

    //fetch fournisseurs
    const fetchUsers= useCallback(async(serv="_usr.php", act = "gets") => {
        const response = await Gfunc.axiosGetAction(serv,act);
        Gfunc.axiosResponse(response,()=>{
            setUserL(response.data.jData);    
        })
      },[Gfunc])

    //fetch fournisseur handler
    const handleOpenFrns = useCallback(async () => {
      if(frnsL.length===0)
      fetchFrns()
      },[frnsL,fetchFrns])
  
    //fetch utilisateurs handler
    const handleOpenUsers = useCallback(async () => {
    if(userL.length===0)
    fetchUsers()
    },[userL,fetchUsers])

    const handleClickOpen = () => { 
      if(props.frnsL.length) setFrnsL(props.frnsL)
      //else if(frnsL.length===0) fetchFrns();
      if(props.userL.length) setUserL(props.userL)
      //else if(userL.length===0) fetchUsers();
      setFrns('');
      setUser((localStorage.getItem('uid'))?localStorage.getItem('uid'):'');
      setUserN((localStorage.getItem('name'))?localStorage.getItem('name'):'');
      setNote('');
      setOpen(true); 
      setSelectedDate(new Date())
      setSelectedTime(new Date())
    };

    const handleClose = () => { setOpen(false); };

      // ajouter un nouveau bon
    const handleSubmit= async(e) =>{
      const data={
        date:Gfunc.formatDate(new Date(selectedDate)),
        time:Gfunc.getClock(selectedTime),
        idfrns:parseInt(frns),
        agent:parseInt(user),
        note:e.target.iNote.value,
      }
        const respon=await axiosPostAction(service,"addbon",data)
        if(respon.data.err && respon.data.err!=='-1'){
          handleAlert(true,'error',respon.data.err);
        }else
        axiosResponse(respon,()=>{
                if (respon.data.jData) {
                handleAlert(true,'success',alertError);
                props.callback();
                setOpen(false);
              }
        })
    }
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Add/>}>Nouveau bon</Button>
      <Dialog
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={paperPropsSmallTall}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
            <div className='DialogTitle'>
              <Icon path={mdiPlusBox} size={1} />
              <label> {addTitle}</label>
            </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
            <DialogContent >
            <ValidatorForm id="nvbn"  ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} > 
            <div className="rowGrid" id='row-1'>
            <FormControl className='smallText' margin="dense">
            <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                              <KeyboardDatePicker
                              disableToolbar
                              size="small"
                              //inputProps={{className:classes.diagFormInput}}
                              KeyboardButtonProps={{style:{padding:"0px"}}}
                              variant="inline"
                              inputVariant="outlined"
                              maxDate={new Date()}  
                              invalidDateMessage="Le format de la date est incorrect"   
                              maxDateMessage="La date ne doit pas être supérieure à la date maximale"
                              format="dd/MM/yyyy"
                              name="iDate"
                              id="date-picker-inline"
                              label={labelText1}
                              keyboardIcon={<Today className={classes.blackSmallIcon}/>}
                              value={selectedDate}
                              onChange={handleDateChange}
                              required />
                  </MuiPickersUtilsProvider>  
                  </FormControl>
                  <FormControl className='smallText' margin="dense">
                  <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    showTodayButton={true}
                                    //inputProps={{tabIndex: "2",className:classes.FormInput}}
                                    inputVariant='outlined'
                                    KeyboardButtonProps={{style:{padding:"0px",height:'30px'}}}
                                    okLabel="Confirmer"
                                    cancelLabel='Annuler'
                                    todayLabel="Maintenant"
                                    invalidDateMessage="Format est incorrect"   
                                    size="small"
                                    autoOk={true}
                                    orientation='landscape'
                                    ampm={false}
                                    maxDate={new Date()} 
                                    format="HH:mm"
                                    id="iTime"
                                    label={labelText5}
                                    value={selectedTime}
                                    keyboardIcon={<AccessTimeOutlined  className={classes.blackSmallIcon}/>}
                                    onChange={handleTimeChange} />            
                    </MuiPickersUtilsProvider> 
                    </FormControl> 
                    </div>
                    <Autocomplete
                        disableClearable
                        id='iUser'
                        className='smallAuto'
                        onChange={(e,newValue) =>{setUser(userL[Gfunc.getIndex(userL,newValue,"name")].id);setUserN(newValue) }} 
                        value={userN}
                        onFocus={handleOpenUsers}
                        options={userL.map((option) => option.name)}
                        renderInput={(params) => (
                          <TextField {...params} label={labelText3} size="small" margin='dense' variant="outlined" required />
                            )} 
                    />
                    <Autocomplete
                        disableClearable
                        id='iFrns'
                        className='smallAuto'
                        onChange={(e,newValue) =>{setFrns(frnsL[Gfunc.getIndex(frnsL,newValue,"name")].id) }} 
                        onFocus={handleOpenFrns}
                        options={frnsL.map((option) => option.name)}
                        renderInput={(params) => (
                              <TextField {...params} label={labelText2} size="small" margin='dense' variant="outlined" autoFocus required />   
                            )}
                    />
                    <TextField size="small" variant="outlined" label={labelText4} className='smallNote' margin="dense" id="iNote" name="iNote" 
                               value={note} onChange={e =>setNote(e.target.value)} placeholder="Insérer une note" multiline rows={4}
                         />
               </ValidatorForm>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type='submit' color="primary" form="nvbn" startIcon={<Add />}>AJOUTER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
