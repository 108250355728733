import React ,{useCallback,useRef,useState,useMemo}from "react";
import TextField from '@material-ui/core/TextField';
import DataTable from "react-data-table-component";
import {Button,Paper,Typography,CircularProgress,Backdrop, Tooltip } from '@material-ui/core';
import { GetApp,CancelOutlined, DoneAll} from '@material-ui/icons';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { customStyles} from '../../css/datatable-custom'
import  '../../css/reporting.css'
import { useStyles } from '../../css/muiStyle';
import {theme} from "../../css/theme"
import {iconCell} from '../../css/datatable-custom'
import {NoDataComponent,AlertCompo} from '../../js/components'
import { useMediaQuery } from 'react-responsive'
import { Autocomplete } from '@material-ui/lab';
import BarCharts from '../../compo/BarChart'
import ReactExport from "react-export-excel";
//import { el } from "date-fns/locale";

export default function Rep_nov(props){
  const classes = useStyles();
  const Gfunc=require('../../Gfunc')
  const param=props.param;
  const [comm,SetComm]=useState([])
  const [cat,SetCat]=useState([])
  const [selectComm,SetSelectComm]=useState('')
  const [selectNumber,SetSelectNumber]=useState('15')
  const [data,setData]=useState([])
  const [,setDataExcel]=useState([])
  const [selectcat,SetSelectCat]=useState('')
  const [icon,setIcon] = useState('');
  const [loading,SetLoading] = useState(false);
  const [err,SetErr] = useState(false);
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const message='Aucune donnée trouvée'
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const isDesktopOrLaptop = useMediaQuery({query: '(max-width: 850px)'})

    //handle alerts properties
  const handleAlert=(open,severity,message)=>{
      setOpen(open)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpen(false)
      }, 5000);
  }

//recuperer les utilisateurs
const fetchUsers= useCallback(async() => {
    const response = await Gfunc.axiosGetAction('_usr.php','gets');
    if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({id:'empty',name:'Tous'})
        SetComm(response.data.jData);   
     })
    }
},[Gfunc]);

//recuper les categories 
const fetchCat= useCallback(async() => {
    const response = await Gfunc.axiosGetAction('_cat.php','gets');
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        response.data.jData.unshift({id:'empty',nom:'Toutes'})
        SetCat(response.data.jData);   
      })
    }
  },[Gfunc]);

//Valider le formulaire
const handleSubmit= async() =>{ 
  var commercial='';var categorie='';
  SetLoading(true)
  SetErr(false)
  setData([])
  if(selectComm!==''){
    commercial=comm[Gfunc.getIndex(comm,selectComm,"name")].id
  }
  if(selectcat!==''){
    categorie=cat[Gfunc.getIndex(cat,selectcat,"nom")].id
  }
  const response=await Gfunc.axiosPostAction("_rep.php","getnonach",{jours:selectNumber, commercial:(commercial!=='empty')?commercial:'',categorie:(categorie!=='empty')?categorie:''})
  SetLoading(false)
  if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
 }else{
      Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        if(response.data.jData.length!==0){
        var dat=response.data.jData
        var file=[]
        dat.map((item)=>{
          item["exclude"]=1
          file.push({'Client':item.client,'Commune':item.commune,'Commercial':item.commercial,'Dernier achat':item.old,
          'Montant dernier achat':item.last_tik_mtn,'Moyenne de consomation':item.monthly_avg,'Solde':item.solde})
          return null
        })
        setData(dat)
        setDataExcel(file)
        }else{
          SetErr(true)
        }
      }
      })
 }
}
    
//supprimer un element
const flagMan=useCallback(async(row)=>{
  if (window.confirm("Êtes-vous sûrs d'exclure cet élément?")) {   
    const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:"client", id: row.id_client,att:'no_report_warn', val: 0}))
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{ 
        if(response.data.jData===true)
        { 
          setIcon(row.id_client)
        }
      })
    }
  }
},[Gfunc])

//les colonnes de datatable
const columns =useMemo(
    () => [   
      {
          name: "Client",
          selector:row=> row.client,
          sortable: true,
          minWidth:'150px !important'
      },
      {
        name: "Commune",
        selector:row=> row.commune,
        sortable: true,
        minWidth:'180px !important'
    },
    {
        name: "Commercial",
        selector:row=> row.commercial,
        sortable: true,
        minWidth:'180px !important'
    },
    {
        name: "Dern. achat (J)",
        selector:row=> row.old,
        sortable: true,
        minWidth:'200px !important',
        cell: (row) => {
            return (
              <div >
                {new Intl.NumberFormat().format(row.old)} 
               </div>
            )
          }
    },
    {
        name: "Dern. achat (DA)",
        selector:row=> row.last_tik_mtn,
        sortable: true,
        minWidth:'200px !important',
        cell: (row) => {
          return (
            <div >
              {new Intl.NumberFormat().format(row.last_tik_mtn)} 
             </div>
          )
        }
    },
    {
        name: "Moy. conso",
        selector:row=> row.monthly_avg,
        sortable: true,
        minWidth:'150px !important',
        cell: (row) => {
          return (
            <div >
              {new Intl.NumberFormat().format(row.monthly_avg)} 
             </div>
          )
        }
    },
    {
        name: "Solde",
        selector:row=> row.solde,
        sortable: true,
        minWidth:'120px !important',
        cell: (row) => {
          return (
            <div >
              {new Intl.NumberFormat().format(row.solde)} 
             </div>
          )
        }
   },
   {
      minWidth:"35px",
      right:true,
      cell: (row) => {
         if(row.id_client===icon){
           row.exclude=0
           setTimeout(() => { setIcon('')},1)
         }
        return (
          <div>
            { 
              (row.exclude)?
                <Tooltip title="Exclusion">
                    <CancelOutlined style={{color:'red'}} onClick={()=>{flagMan(row)}} />
                </Tooltip>
              :null
            }
          </div> )
      }
  }
    ],[classes,icon,flagMan]);

  //remplir Select commercial
  const handleOpenComm= useCallback(async () => {
      if(comm.length===0)
        fetchUsers()
  },[comm,fetchUsers])

  //remplir Select Categorie 
  const handleOpenCat= useCallback(async () => {
      if(cat.length===0) fetchCat()
      },[cat,fetchCat])

  //valider formulaire avec touche d'entrer
  const detectTouche= (e)=>{
      if (e.keyCode === 13 && (e.target.name==="addBtn")) {
          if(selectComm===''||selectNumber===''||selectcat===''){
            alert("Vous devez remplir tous les champs!")
          }
        }
      }
    
return (
  <div className="content" >
   <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
   <fieldset className="fieldsetTop" id="Nov">
        <ValidatorForm noValidate id="formAdd" ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)}  >
            <div className="rowGrid" id="row-1">
            <Autocomplete
                   disableClearable
                   onChange={(e,newValue) =>{SetSelectComm(newValue)}}
                   value={selectComm}
                   options={comm.map((option) => option.name)}
                   onFocus={handleOpenComm}
                   className='smallAuto' 
                   renderInput={(params) =>  
                        <TextField margin='dense' 
                              inputlabelprops={{className:(comm)?undefined:classes.dataLabel}}
                              // error={errorP}
                              size='small'{...params} variant="outlined"  label="Commercial..." 
                              SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                          />}
              />
              <Autocomplete
                disableClearable
                onChange={(e,newValue) =>{SetSelectCat(newValue)}}
                value={selectcat}
                options={cat.map((option) => option.nom)}
                onFocus={handleOpenCat}
                className='smallAuto' 
                renderInput={(params) =>  
                    <TextField margin='dense' 
                          inputlabelprops={{className:(cat)?undefined:classes.dataLabel}}
                          // error={errorP}
                          size='small'{...params} variant="outlined"  label="Catégorie..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                      />}
              />
              <TextField required  color="primary" label="Nombre de jours" size="small" inputProps={{ tabIndex: "4"}}
                     className='smallText' margin="dense" variant="outlined"
                     value={selectNumber}  onChange={e =>SetSelectNumber(e.target.value)} placeholder="Dernier jours.">
              </TextField>
              <Button className={classes.PrintBtn} variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" color="primary" 
                      startIcon={<DoneAll/>}>VALIDER</Button>
              {
             (data.length>0)?
             <ExcelFile element={ <Button  id='Row-Button1' className={classes.PrintBtn} variant="contained"  name="addBtn1" color="primary" title='Exporter les Données'><GetApp fontSize='small'/></Button> }>
              <ExcelSheet data={data} name="Clients sans achat" >
                 <ExcelColumn label="Client" value="client"/>
                 <ExcelColumn label="Commune" value="commune"/>
                 <ExcelColumn label="Commercial" value="commercial"/>
                 <ExcelColumn label="Dernier achat" value="old"/>
                 <ExcelColumn label="Montant dernier achat" value="last_vers"/>
                 <ExcelColumn label="Moyenne de consomation" value="monthly_avg"/>
                 <ExcelColumn label="Solde" value="solde"/>
             </ExcelSheet>
         </ExcelFile>             :<></>
              }
            </div>
        </ValidatorForm>
        </fieldset>
        <Backdrop className={classes.backdrop} open={loading} >
            <CircularProgress color="inherit" />
       </Backdrop>
        {
        (data.length>0)?
        <div id='charts' >
          <Paper className={classes.paperBody} id='charts-1' elevation={5}>
            <BarCharts width={(isDesktopOrLaptop===false)?600:380} height={300} data={data}
             margin={{ top: 5, right: 30, left: (isDesktopOrLaptop===false)?20:-10, bottom: 5 }} barSize={20}
             dataKeyX="client" padding={{ left: 10, right: 10 }} dataKeyY="old" fill="#8884d8" name="Age (jours)"/> 
            <Typography className='titleCharts'> Nombre de jours sans achat </Typography>
          </Paper>
          <Paper className={classes.paperBody} id='charts-2' elevation={5}>
            <BarCharts width={(isDesktopOrLaptop===false)?600:380} height={300} data={data}
             margin={{ top: 5, right: 30, left: (isDesktopOrLaptop===false)?20:-10, bottom: 5 }} barSize={20}
             dataKeyX="client" padding={{ left: 10, right: 10 }} dataKeyY="solde"  fill="#01579b" name="Solde (DA)"/>
          <Typography className='titleCharts'> Solde </Typography>
          </Paper>
    </div>:<></>
    }
    {
        (data.length>0)?
        <div>
          <DataTable 
              persistTableHead 
              title="Les chiffres d'affaires des clients"
              columns={columns}
              data={data}
              defaultSortAsc={true}
              noHeader={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              highlightOnHover
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
          />
        </div>:<></>
    }
    {(err===true)?<div><NoDataComponent message={message}/></div>:<></>}
  </div>    

  );
}