import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo} from "../../../js/components"
import { Add,Close } from '@material-ui/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Adm_rol_add(props) {
    const classes=useStyles();
    const [name,setName]=useState('');
    const [note,setNote]=useState('');
    const [open, setOpen] =useState(false);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const nom="role"
    const addMessage="Ajouter un nouveau "+nom
    const alertError="le "+nom+" été ajouté"
    const labelText="Nom"
    const labelTextN='Note'
    const service="_rol.php"
   

    //handle alerts properties
    const handleAlert=(open,severity,message)=>{
      setOpenA(open)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    
    const handleClickOpen = () => { 
      setName('');
      setNote('');
      setOpen(true); 
    };
    const handleClose = () => { setOpen(false); };
    
    const handleSubmit= async(e) =>{
        e.preventDefault();
        const data={name:name,note:note}
        const respon=await axiosPostAction(service,"add",data)
        if(respon.data.err&&respon.data.err!=='-1'){
          handleAlert(true,"error",respon.data.err)
        }else
        axiosResponse(respon,()=>{
                setOpen(false);
                if (respon.data.jData) props.handleAlert(true,'success',alertError);
                props.callback();
        })
    }
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Add/>}>NOUVEAU ROLE</Button>

      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
       >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
            <div className='DialogTitle'>
              <Icon path={mdiPlusBox} size={1} />
              <label>{addMessage}</label>
            </div>
        </DialogTitle>
        <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true} />
        <DialogContent>
                  <div>
                    <TextField  className='smallText' margin="dense" variant="outlined" size="small" label={labelText} value={name} 
                                onChange={e =>setName(e.target.value)} fullWidth autoFocus required />
                  </div>
                  <div>
                    <TextField className='smallNote' size="small" label={labelTextN} id="note" name="note"  value={note} 
                               onChange={e =>setNote(e.target.value)}  margin="dense" variant="outlined" multiline rows={3} fullWidth/>
                  </div>
        </DialogContent>
        <DialogActions>
              <Button variant="contained" type='submit' color="primary" startIcon={<Add />}>AJOUTER</Button>
        </DialogActions>
        </ValidatorForm> 

      </Dialog>

      </div>
  );
}
