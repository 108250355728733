import React,{useState,useCallback} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,TextField,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {Add,Close} from '@material-ui/icons';
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import { AlertCompo, BackdropCompo, RaccBtn } from '../../../js/components';
import { Autocomplete } from '@material-ui/lab';
import { mdiBasketPlus ,mdiPlusBox} from '@mdi/js';
import Icon from '@mdi/react';
import { mdiMargin } from '@mdi/js';
import '../../../css/vente.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


//add new vente
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Vnt_cpt_add(props) {

    const Gfunc=require('../../../Gfunc') 
    const [stkL,setStkL]=useState([]);
    const [prodL,setProdL]=useState([]);
    const [cat,setCat]=useState('');
    const [qte,setQte]=useState('');
    const [prod,setProd]=useState('');
    const [total,setTotal]=useState('');
    const [lastTotal,setLastTotal]=useState('');
    const [marge,setMarge]=useState(0);
    const [lastMarge,setLastMarge]=useState(0);
    const [stk,setStk]=useState(localStorage.getItem('stockId'));
    const [stkN,setStkN]=useState(localStorage.getItem('stock'));
    const [catN,setCatN]=useState('');
    const service='_vnt.php'
    const classes=useStyles();
    const [action, setAction] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [open, setOpen] =useState(false);
  
    const labelText1='Catégorie'
    const labelText2='Produit'
    const labelText3='Qte.'
    const labelText4='Total'
    const labelText5='Stock'
   
    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
    
   //handle marge
   const handleMarge=async(pdetId,qte)=>{
    setQte(qte);
    setMarge('');setTotal('')
    if(qte===''){qte=0; }
    else
      if(pdetId){ const data={
            pdetId:pdetId,
            qte:parseInt(qte)
          }
          const response = await Gfunc.axiosPostAction("_vnt.php","getpdinfvt",data)
          if(response.data.err&&response.data.err!=="-1"){
            handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{
            if(response.data.jData){
              setMarge(parseFloat(response.data.jData.marge))
              setLastMarge(parseFloat(response.data.jData.marge))
              if(!qte) setQte(parseInt(response.data.jData.qte))
              setTotal(parseFloat(response.data.jData.montant))
              setLastTotal(parseFloat(response.data.jData.montant))
            }
          })}
  }
   //handle marge
   const handleMarge2=async(total)=>{
    setTotal(total)
     if(total==='')total=0
     total=parseFloat(total)
    setMarge(marge-(total-lastTotal));
    setLastTotal(total)
  }
   

   //fetch prod
   const fetchProd= useCallback(async() => {
    if(cat!==''){const response = await Gfunc.axiosPostAction("_prd.php","getspcat",{catId:cat,retour:props.retour})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData){
        setProdL(response.data.jData);  
      } 
    })}else {
        handleAlert(true,'error',"Veulliez d'abord choisir une catégorie!")
        document.getElementById('iCat').focus()
    }
  },[Gfunc,cat]);

   //handle the call for fetch client
   const handleOpenProd=(event)=>{
    if(prodL.length===0)
    fetchProd();
   }

   //fetch Stock
   const fetchStk= useCallback(async(id) => {
    const response = await Gfunc.axiosGetAction("_stk.php","gets")
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData){
        setStkL(response.data.jData);  
      } 
    })
  },[Gfunc]);

   //handle the call for fetch client
   const handleOpenStk=(event)=>{
    if(stkL.length===0)
    fetchStk(event.target.id);
   }
   
   //reste all fiels
   const reset=()=>{
    setProd('')
    setProdL([])
    setCat('')
    setCatN('')
    setTotal('')
    setLastTotal('')
    setQte('')
    setMarge('')
   }

    // ajouter un nouveau bon
    const handleSubmit= async(e) =>{
      if(!action){
       if(qte>0) {setAction(true)
        const data={
          pdetId:prod,
          tiketId:props.ticketId,
          qte:parseInt(qte),
          stockId:parseInt(stk),
          mtn:parseInt(total),
          retour:props.retour,
        }
          
          const respon=await Gfunc.axiosPostAction(service,"addvman",data)
          
          if(respon.data.err && respon.data.err!=='-1'){
            handleAlert(true,'error',respon.data.err);
          }else
          Gfunc.axiosResponse(respon,async()=>{
            if(respon.data.jData){
              let t=parseFloat(total)
              let q=parseInt(qte)
              if(props.retour){ t=-t
                q=-q}
              props.handleAddVnt(respon,stk,stkN,t,q,prod,props.handleAlert,()=>{},()=>{})  
              props.handleAlert(true,'success',"La vente est ajouté");
              reset()
              //document.getElementById('iCat').focus()
              setOpen(false)
             }
          })
          setAction(false)
        }}
      }

    const handleClickOpen = () => { 
        setOpen(true)
        reset()
        props.handleOpenCat()
        if(JSON.parse(localStorage.getItem('stockactiv'))){
            setStk(localStorage.getItem('stockId'))
            setStkN(localStorage.getItem('stock'))
        }else{
            setStk('')
            setStkN('')
        }  
    };
  
    const handleClose = () => { setOpen(false); };
   
  return (
    <div>
       <RaccBtn
          id="addvMan"
          tip={'Ajouter une vente'}
          className={classes.racBtnRedBig}
          onClick={handleClickOpen} 
          text={'Ajout. vt.'}
          path={mdiBasketPlus}
          iconColor={"white"}
          racc={'F5'}
          disabled={props.disabled}
      /> 
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
           <div className='DialogTitle'>
              <Icon path={mdiPlusBox} size={1} />
              <label>Ajouter une nouvelle vente</label>
            </div>
        </DialogTitle>
        <BackdropCompo className={classes.backdropDiag} open={action}/>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent >
            <ValidatorForm id="addV"  onSubmit={handleSubmit} onError={errors => console.log(errors)} > 
                            <Autocomplete
                                disableClearable
                                id="iCat"
                                onChange={(e,newValue) =>{
                                    setCat(props.catL[Gfunc.getIndex(props.catL,newValue,"nom")].id)
                                    setCatN(newValue)
                                    setProdL([])} 
                                  } 
                                value={(catN!=='')?(catN):null}
                                options={props.catL.map((option) => option.nom)}
                                className='smallAuto'
                                renderInput={(params) => (
                                <TextField {...params} className={classes.diagFormText} variant='outlined' label={labelText1} 
                                           size="small" autoFocus={open} required/>
                            )}/>
                            <Autocomplete
                                disableClearable
                                id="iProd"
                                onChange={(e,newValue) =>{
                                    setTotal('')
                                    setMarge('')
                                    setProd(newValue.pdetId)
                                    handleMarge(newValue.pdetId)
                                  }
                                  } 
                                onFocus={handleOpenProd}
                                className='smallAuto'
                                options={prodL}
                                getOptionLabel={(option) => option.pdet}
                                getOptionSelected={(option, value) => option.pdetId === value.pdetId}
                                renderInput={(params) => (
                                <TextField {...params} variant='outlined' label={labelText2} size="small" required/>
                            )}/>

                            <div className='rowGrid' id="addVM">
                              <TextField  variant='outlined' size="small" label={labelText3} id="iQte" name="iQte"   value={qte} type='number'
                                      onChange={e =>{handleMarge(prod,e.target.value)}} className='smallText' InputProps={{ inputProps: { min: 1 } }} required/>
                              <TextField  variant='outlined' size="small" label={labelText4} id="iTotal" name="iTotal"   value={total} type='number'  InputProps={{ inputProps: { min: 0 ,step:"0.01"} }}
                                    onChange={e =>{handleMarge2(e.target.value)}} /*onBlur={()=>handleMarge()}*/  className='smallText' required/>
                              <div id="fieldSet" style={{display:'flex',gridGap:"5px",fontSize:'12px',alignItems:'center',padding:'4px 8px'}}>
                                  <Icon path={mdiMargin} size={0.8} />
                                  <b> Marge:</b>
                                  <p> {Intl.NumberFormat().format(marge)}</p>
                              </div> 
                            </div>
                            <Autocomplete
                                disableClearable
                                id="iStk"
                                className='smallAuto'
                                onChange={(e,newValue) =>{
                                    setStk(stkL[Gfunc.getIndex(stkL,newValue,"nom")].id)
                                    setStkN(newValue)}
                                  } 
                                onFocus={handleOpenStk}
                                value={(stkN!=='')?(stkN):null}
                                options={stkL.map((option) => option.nom)}
                                renderInput={(params) => (
                                  <TextField {...params} variant='outlined' label={labelText5} size="small" required/>
                                )}/>
                 </ValidatorForm>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" type='submit' color="primary" form="addV" startIcon={<Add />}>Ajouter</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
