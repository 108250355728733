import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,MenuItem,ListItemText,Checkbox,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Add, Close} from '@material-ui/icons';
import {TextField} from '@material-ui/core';
import { ValidatorForm} from 'react-material-ui-form-validator';
import  {useStyles,paperPropsSmall2} from '../../../css/muiStyle';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {AlertCompo} from "../../../js/components"
import  '../../../css/retour.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import Icon from '@mdi/react';
import { mdiPlusBox } from '@mdi/js';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ret_rec_add(props) {
    const Gfunc=require('../../../Gfunc')
    const [code,setCode]=useState('');
    const [prod,setProd]=useState('');
    const [frns,setFrns]=useState('');
    const [prodN,setProdN]=useState('');
    const [nserie,setNserie]=useState('');
    const [client,setClient]=useState('');
    const [type,setType]=useState('');
    const [agent,setAgent]=useState('');
    const [autre,setAutre]=useState('');
    const [note,setNote]=useState([]);
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate2, setSelectedDate2] = useState(new Date());
    const [prodL,setProdL]=useState([]);
    const [frnsL,setFrnsL]=useState([]);
    const [userL,setUserL]=useState([]);
    const [clientL,setClientL]=useState([]);
    const [typeL,setTypeL]=useState({});
    const [noteL,setNoteL]=useState([]);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const classes = useStyles();
    const [EProd, setEprod] = useState(false);
    const [EDate, setEdate] = useState(false);
    const [EDate2, setEdate2] = useState(false);
    const [Eserie, setEserie] = useState(false);
    const [Eclient, setEclient] = useState(false);
    const [Etype, setEtype] = useState(false);
    const [Eagent, setEagent] = useState(false);
    const [Epanne, setEpanne] = useState(false);
    const [Efrns, setEfrns] = useState(false);

    const service="_ret.php"

    
    const handleClickOpen = () => { 
      handleOpenProd()
      handleOpenClient()
      handleOpenType()
      handleOpenUser()
      handleOpenNote()
      setAgent(localStorage.getItem("uid"))
      setAutre('');
      setCode('');
      setFrns('');
      setProd('');
      setNserie('')
      setType('');
      setClient('');
      setNote([]);
      setEprod(false)
      setEserie(false)
      setEtype(false)
      setEclient(false)
      setEagent(false)
      setEpanne(false)
      setEfrns(false)
      setSelectedDate(false)
      setSelectedDate2(false)
      fetchProd()
      setOpenA(false)
      setSelectedDate(new Date())
      setSelectedDate2(new Date())
      setOpen(true); 
    };
    const handleClose = () => { setOpen(false); };
  
    const handleDateChange = (date) => {
        setSelectedDate(date);}

    const handleDateChange2 = (date) => {
        setSelectedDate2(date);
      };   

    //fetch produit
    const fetchProd= useCallback(async() => {
      const response = await Gfunc.axiosPostAction("_prd.php","gets")
      if(response.data.err){
        setOpen(false)
        props.handleAlert(true,'error',response.data.err)
      }else 
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==undefined)
          setProdL(response.data.jData);   
      })
    },[Gfunc]);

   //fetch fournisseur 
   const fetchFrns= useCallback(async() => {
    const response = await Gfunc.axiosGetAction("_frs.php","gets")
    if(response.data.err){
      setOpen(false)
      props.handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        setFrnsL(response.data.jData);   
    })
  },[Gfunc]);

    //fetch users 
    const fetchUser= useCallback(async() => {
      const response = await Gfunc.axiosGetAction("_usr.php","gets")
      if(response.data.err){
        setOpen(false)
        props.handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
          setUserL(response.data.jData);   
      })
    },[Gfunc]);
    //fetch client 
    const fetchClient= useCallback(async() => {
      const response = await Gfunc.axiosGetAction("_cli.php","gets")
      if(response.data.err){
        setOpen(false)
        props.handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
          setClientL(response.data.jData);   
      })
    },[Gfunc]);

     //handle the call for fetch client
     const handleOpenClient=()=>{
      if(clientL.length===0)
      fetchClient();
     }
  

    //fetch types
    const fetchType= useCallback(async() => {
      const response = await Gfunc.axiosGetAction(service,"gettypes")
      if(response.data.err){
        setOpen(false)
        props.handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
          setTypeL(response.data.jData);   
      })
    },[Gfunc]);
    
    //fetch notes
    const fetchNote= useCallback(async() => {
      const response = await Gfunc.axiosGetAction(service,"getnotes")
      if(response.data.err){
        setOpen(false)
        props.handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
          setNoteL(response.data.jData);   
      })
    },[Gfunc]);
    
    //fecth products by code barre
    const fetchProdbyCb= useCallback(async(e) => {
      setCode(e.target.value.replace(/(\r\n|\n|\r)/g, ""))
      if(e.target.value.length===8){
      const response = await Gfunc.axiosPostAction("_prd.php","getpbycb",{code:e.target.value})
      Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            setProd(response.data.jData.id); 
            const index=Gfunc.getIndex(prodL,response.data.jData.id,"id")
            if (index>-1){
              setProdN(prodL[index].nom)
              document.getElementById('iSerie').focus()
            }
            else {
              handleAlert(true,'warning',"Ce code-barres n'est pas enregistré !")
              setProd('')
            }
            setCode('')
          }
      })
      setCode('')
    }
    },[Gfunc,prodL]);

   //handle the call for fetch user
    const handleOpenFrns=()=>{
        if(frnsL.length===0)
        fetchFrns();
    }
    //handle the call for fetch produit
    const handleOpenProd=()=>{
        if(prodL.length===0)
        fetchProd();
    }

     //handle the call for fetch user
    const handleOpenUser=()=>{
        if(userL.length===0)
        fetchUser();
    }
    
    //handle the call for fetch type
    const handleOpenType=()=>{
        if(Gfunc.isEmpty(typeL))
        fetchType();
    }

     //handle the call for fetch notes
    const handleOpenNote=()=>{
        if(noteL.length===0)
        fetchNote();
    }
     //handle the call for fetch notes
    const handleSelectMulti=(selected)=>{
        return selected.join(", ")
    }
    
    //handle a note added by the user
    const handleAutre=(event)=>{
        setAutre(event.target.value);
    }
    
    //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
  //valider formulaire avec touche clavier 
  const detectTouche= (e)=>{
     //setEprod(false); setEdate(false); setEserie(false); setEclient(false); setEtype(false); setEagent(false); setEpanne(false); setEfrns(false)
     if(!Gfunc.dateIsValide(selectedDate)) setEdate(true)
     if(!Gfunc.dateIsValide(selectedDate2)) setEdate2(true)
     if (e.keyCode === 13) {
        if(e.target.name==="iCode") e.preventDefault()
        if(e.target.name==="iValide"){
          if(prod==="") setEprod(true)
          if(nserie==="") setEserie(true)
          if(client==="") setEclient(true)
          if(type==="") setEtype(true)
          if(agent==="") setEagent(true)
          if(note.length===0) setEpanne(true)
          if(type==='2'&&frns==="") setEfrns(true)
        }
    }
    }
    //handle subit form
    const handleSubmit= async(e) =>{
          if(prod==="") setEprod(true)
          if(!(Object.prototype.toString.call(selectedDate) === "[object Date]"&&!isNaN(selectedDate))||(!Gfunc.datesAreOnSameDay(new Date(),selectedDate)&&selectedDate.getTime()>new Date().getTime())) setEdate(true)
          if(type==='1'&&!Gfunc.dateIsValide(selectedDate2)) setEdate2(true)
          if(nserie==="") setEserie(true)
          if(client==="") setEclient(true)
          if(type==="") setEtype(true)
          if(agent==="") setEagent(true)
          if(note.length===0) setEpanne(true)
          if(type==='2'&&frns==="") setEfrns(true)
      if(prod!==''&&nserie!==""&&client!==""&&agent!==''&&note.length!==0&&Gfunc.dateIsValide(selectedDate)&&((type==="1"&&Gfunc.dateIsValide(selectedDate2))||(type!==""&&type!=="1"))){
        setEprod(false); setEdate(false);setEdate2(false); setEserie(false); setEclient(false); setEtype(false); setEagent(false); setEpanne(false); 
          var nots=[]
          if(autre){
          nots=note.filter(e => e !== 'Autre');
          nots.push(autre)
          }else{
            nots=note
          }
          var notes=''
          nots.map((note)=>{
            notes=notes+note+"\r"
            return(true)
          })
          var dat=false
          if(type==='1'){
            if(Gfunc.datesAreOnSameDay(selectedDate,selectedDate2)||selectedDate.getTime()<selectedDate2.getTime()){
              dat={produit:prod,date:Gfunc.formatDate(new Date(selectedDate)),serie:nserie,client:client,type:parseInt(type),agent:agent,note:notes,date2:Gfunc.formatDate(selectedDate2)}
            }else{ handleAlert(true,"error","La date de swap doit être supérieur à la date de retour"); dat=false}
          } else if(type==='2') {
                  if(frns!==''){ setEfrns(false)
                    dat={produit:prod,date:Gfunc.formatDate(new Date(selectedDate)),serie:nserie,client:client,type:parseInt(type),agent:agent,note:notes,frns:frns}}
                  else handleAlert(true,"error","Vous devez remplir tous les champs!")
                  } else dat={produit:prod,date:Gfunc.formatDate(new Date(selectedDate)),serie:nserie,client:client,type:parseInt(type),agent:agent,note:notes}
           
           var respon={data:{}}
            if(dat) respon=await Gfunc.axiosPostAction(service,"add",dat)
            if(respon.data.err){
              handleAlert(true,'error',respon.data.err)
            }else {Gfunc.axiosResponse(respon,()=>{
                    if(respon.data.jData){
                          props.handleAlert(true,'success','Le retour est ajouté')
                          setOpen(false);
                          props.callback();}
                  })}
     }else handleAlert(true,"error","Vous devez remplir tous les champs!")
    }
  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Add/>} className={classes.topTabBtn}>Nouveau retour</Button>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmall2}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
          <div style={{display:"flex",gridGap:"10px",alignItems:"center"}}>
            <Icon path={mdiPlusBox} size={1} />
            <label>Ajouter un retour client</label> 
          </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
        <ValidatorForm  noValidate  id="form" ref={useRef(null)} onSubmit={handleSubmit} onKeyDown={e=>detectTouche(e)} >
            <DialogContent >
                <div className="form-two-grid-container">
                        <TextField label='Code barre' id="iCode" name="iCode" value={code}  className='smallText' margin='dense' variant='outlined' autoFocus
                                    onChange={(e)=>fetchProdbyCb(e)} />
                        <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iProduit"
                                onChange={(e,newValue) =>{
                                    setProd(prodL[Gfunc.getIndex(prodL,newValue,"nom")].id)
                                    setProdN(newValue)}
                                  } 
                                value={(prod!=='')?(prodN):null}
                                options={prodL.map((option) => option.nom)}
                                renderInput={(params) => (
                                <TextField
                                    error={EProd&&prod===''}
                                    {...params}
                                    margin='dense'
                                    variant='outlined'
                                    label="Produit retourné"
                                    size="small" 
                                    helperText={(EProd&&prod==='')?"Veuillez sélectionner un produit":null}
                                    required/>
                                )} />
                         <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                error={EDate&&(!(Object.prototype.toString.call(selectedDate) === "[object Date]"&&!isNaN(selectedDate))||(!Gfunc.datesAreOnSameDay(new Date(),selectedDate)&&selectedDate.getTime()>new Date().getTime()))}
                                disableToolbar
                                size="small"
                                className='smallText'
                                margin='dense'
                                inputVariant='outlined' 
                                maxDate={new Date()} 
                                invalidDateMessage="Le format de la date est incorrect"   
                                maxDateMessage="La date ne doit pas être supérieure à la date maximale"                           
                                variant="inline"
                                format="dd/MM/yyyy"
                                name="iDate"
                                id="date-picker-inline"
                                label="Date de retour"
                                value={selectedDate}
                                onChange={handleDateChange}
                                required/>
                         </MuiPickersUtilsProvider>    
                        <TextField size="small"  label='N Serie' id="iSerie" name="iSerie" value={nserie}  className='smallText' margin='dense' variant='outlined'
                            onChange={e =>setNserie(e.target.value)} 
                            required
                            error={Eserie&&nserie===''}
                            helperText={(Eserie&&nserie==='')?"Veuillez renseigner le numero de serie":null}/>
                        <Autocomplete
                                disableClearable
                                className='smallAuto'
                                onChange={(e,newValue) =>{
                                    setClient(clientL[Gfunc.getIndex(clientL,newValue,"name")].id)
                                  }
                                  } 
                                id='iClient'
                                options={clientL.map((option) => option.name)}
                                renderInput={(params) => (
                                <TextField
                                    error={Eclient&&client===''}
                                    helperText={(Eclient&&client==='')?"Veuillez sélectionner un client":null}
                                    {...params}
                                    margin="dense"
                                    variant='outlined'
                                    label="Client"
                                    size="small"
                                    required />
                                )} />
                        <TextField size="small" select label='Type' id="iType" name="iType" value={type} className='smallText' margin='dense' variant='outlined' 
                            onChange={e =>{setType(e.target.value);if(e.target.value==='2')handleOpenFrns()}} 
                            helperText={(Etype&&type==='')?"Veuillez sélectionner un type":null}
                            required
                            error={Etype&&type===''}
                            >
                                {Object.keys(typeL).map((key,index)=>{return (<MenuItem key={index} value={key}>{typeL[key]}</MenuItem>)})}
                        </TextField>
                        <TextField size="small"  select label='Récupéré par' id="iAgent" name="iAgent" value={agent} className='smallText' margin='dense' variant='outlined'
                            onChange={e =>setAgent(e.target.value)} 
                            helperText={(Eagent&&agent!=='')?"Veuillez sélectionner un agent":null}
                            required
                            error={Eagent&&agent===''}
                            >
                                <MenuItem value={agent}>{localStorage.getItem('name')}</MenuItem>
                                {userL.map((user,index)=>{return (<MenuItem key={index} value={user.id}>{user.name}</MenuItem>)})}
                         </TextField>
                            <TextField
                            error={Epanne&&note.length===0}
                            margin="dense"
                            className='smallText'
                            required
                            select
                            size="small" 
                            label="Panne"
                            color="primary"
                            variant='outlined'
                            //helperText={(Epanne&&note.length===0)?"Veuillez sélectionner une/des pannes":null}
                            id="iNote" name="iNote"
                            SelectProps={{
                              multiple: true,
                              value:note,
                              onChange: (e) =>setNote(e.target.value),
                              renderValue:(selected) => handleSelectMulti(selected)
                            }}
                           // inputProps={{color:"red"}}
                            >
                                <MenuItem value="Autre">
                                <Checkbox color="primary" checked={note.indexOf("Autre") > -1} />
                                    Autre</MenuItem>
                            {noteL.map((name,index) => (
                                <MenuItem key={index} value={name}>
                                <Checkbox key={"c"+index} color="primary" checked={note.indexOf(name) > -1} />
                                <ListItemText key={index} primary={name} />
                                </MenuItem>
                            ))}
                            </TextField>
                        <div>
                        {(type==='2')?<TextField size="small"  select label='Réparation chez' value={frns} className='smallText' margin='dense' variant='outlined' 
                            onChange={e =>setFrns(e.target.value)} 
                            id="iFrns"
                            required
                            error={Efrns&&frns===''}
                            helperText={(Efrns&&frns==='')?"Veuillez sélectionner un fournisseur":null}

                            >
                                {frnsL.map((user,index)=>{return (<MenuItem key={index} value={user.id}>{user.name}</MenuItem>)})}
                         </TextField>:null}
                        {(type==='1')? <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                error={EDate2&&(!(Object.prototype.toString.call(selectedDate2) === "[object Date]"&&!isNaN(selectedDate2))||(!Gfunc.datesAreOnSameDay(new Date(),selectedDate2)&&selectedDate2.getTime()>new Date().getTime()))}
                                disableToolbar
                                size="small"
                                className='smallText'
                                margin='dense' 
                                inputVariant='outlined'
                                maxDate={new Date()}                                  
                                variant="inline"
                                format="dd/MM/yyyy"
                                invalidDateMessage="Le format de la date est incorrect"   
                                maxDateMessage="La date ne doit pas être supérieure à la date maximale"
                                name="iDate"
                                id="date-picker-inline"
                                label="Date du swap"
                                value={selectedDate2}
                                onChange={handleDateChange2}
                                required/>
                         </MuiPickersUtilsProvider> :null}
                         </div>
                         <div>
                         {(note.includes("Autre"))?<TextField size="small" value={autre} onChange={handleAutre} label='Autre panne' className='smallText' margin='dense' variant='outlined'> </TextField>:<></>}
                         </div>
                  </div>
           </DialogContent>
            <DialogActions>
                <Button variant="contained" name="iValide" form="form" type='submit' color="primary" startIcon={<Add/>}>AJOUTER</Button>
            </DialogActions>
            </ValidatorForm>

      </Dialog>
      </div>
  );
}
