import React,{ useState, useEffect ,useCallback,useMemo, Fragment} from "react";
import DataTable from "react-data-table-component";
import TextField from '@material-ui/core/TextField';
import {CircularProgress, AccordionSummary, Accordion, Typography, AccordionDetails, Tooltip, Card, CardContent, FormControl, Button, TextareaAutosize} from '@material-ui/core';
import { ExpandMore, Person, Face, AccessTimeOutlined, Event, Comment, Delete, ArrowRight} from '@material-ui/icons';
import { textAreaEdit, textAreaEditGray, useStyles } from '../../css/muiStyle';
import { Autocomplete } from '@material-ui/lab';
import {ReactComponent as Profit} from '../../img/icons/profit.svg'
import './../../css/monitoring.css'
import { customStylesSmall, numberColumn}from '../../css/datatable-custom'
import {AutocompleteS, BackdropCompo, NoDataComponent,NoTableDataComponent, OutsideClickHandler, AlertCompo} from "../../js/components"
import $ from 'jquery'
import { mdiCashRegister, mdiFileTable, mdiScaleUnbalanced } from "@mdi/js";
import Iconn from "@mdi/react";
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardTimePicker} from "@material-ui/pickers";
import { ValidatorForm } from "react-material-ui-form-validator";
import {ReactComponent as Dzd} from '../../img/icons/dzd.svg'
import '../../css/irh.css'
import PrintTicket from "../../js/printTicket"

export default function Mon_jrn (props){
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const mdi=require('@mdi/js');
  const [agent, setAgent] = useState('');
  const [caisses, setCaisses] = useState([]);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState('');
  const [months, setMonths] = useState([]);
  const [month, setMonth] = useState('');
  const [monthN, setMonthN] = useState('');
  const [days, setDays] = useState([]);
  const [sumDay, setSumDay] = useState([]);
  const [detDay, setDetDay] = useState([]);
  const [ids, setIds] = useState([]);
  const [idss, setIdss] = useState([]);
  const [recoltInf, setRecoltInf] = useState({});
  const [loading1, setLoading1] = useState(false);
  const [loadingSum, setLoadingSum] = useState(false);
  const [loadingDet, setLoadingDet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingRec, setLoadingRec] = useState(false);
  const [reset, setReset] = useState(false);
  const [action, setAction] = useState(false);
  const [Item, setItem] = useState('');
  const [selecto, setSelecto] = useState('');
  const [jour,setJour]=useState('');
  const [jourN,setJourN]=useState('');
  const [lastJr,setLastJr]=useState('');
  const [lastJn,setLastJn]=useState('');
  const [clot,setClot]=useState('');
  const [ecart,setEcart]=useState(0);
  const [selectedTime, setSelectedTime] = useState("");
  const service='_jrn.php'
  const classes=useStyles();
  const[noData,setNoData]=useState(false);
  const[empty,setEmpty]=useState(false);
  const Apis =require("../../js/Apis.js");
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const [openA, setOpenA] =useState(false);
  const [expanded, setExpanded] = useState(false);
  const [clientL,setClientL]=useState([]);
  const [note,setNote] = useState('');
  const labelText1="Année"
  const labelText2="Mois"
  const labelText3="Jour"
  const labelText4="Horraires"


   //fetch client 
   const fetchClient= useCallback(async(id) => {
    const response = await Gfunc.axiosGetAction("_cli.php","gets")
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData){
       const dat=response.data.jData
       dat.unshift({id:null,name:"Anonyme"})
       setClientL(dat);
      }   
    })
  },[Gfunc]);

   //handle the call for fetch client
   const handleOpenClient=(event)=>{
    if(clientL.length===0)
    fetchClient(event.target.id);
   }
   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000
    );
  }
   //handle get Year
   const getYears=useCallback(async()=>{
     const response= await Gfunc.axiosGetAction(service,"getsyear");
     if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err);
     }else
     Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
            setYears(response.data.jData)
            if(response.data.jData.length!==0){ 
            getMonths(response.data.jData[0].year);
            setYear(response.data.jData[0].year)}
            else
            setNoData(true)
        }
     })
  },[])

   //handle get months
   const getMonths=useCallback(async(year)=>{
    setLoading(true)
     const response= await Gfunc.axiosPostAction(service,"getsmonth",{year:year});
     if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err);
     }else
     Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          if(response.data.jData.length!==0){
            setMonths(response.data.jData)
            getDays(year,response.data.jData[0].month,0);
            setMonth(response.data.jData[0].month)
            setMonthN(response.data.jData[0].monthName)
          }
            else
            setNoData(true)
        }
     })
  },[])

   //handle get days
   const getDays=useCallback(async(year, month,day,jn)=>{
     const response= await Gfunc.axiosPostAction(service,"getmjour",{year:year,month:month});
     setLastJr('');
     setExpanded(false)
     if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err);
     }else
     Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          if(response.data.jData.length!==0){
            setDays(response.data.jData)
            setTimeout(()=>{const elem=document.getElementById("day"+day)
            if(elem) {elem.click()
              if(jn){
                setTimeout(()=>{const elem2=document.getElementById("i"+jn)
                if(elem2) {elem2.click()
                }},0)
              }
            }},0)
          }
            else
            setNoData(true)
        }
     })
     setLoading(false)
  },[Gfunc])

   //handle get somaire d'une journee
   const getSumDay=useCallback(async(id)=>{
    setLoadingSum(true);
    const data=(id!==undefined)?{idjs:id}:{}
     const response= await Gfunc.axiosPostAction(service,"getjsum",data);
     if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err);
     }else
     Gfunc.axiosResponse(response,()=>{
       let cats={}
        if(response.data.jData){
         
            if(response.data.jData.length>1){
              response.data.jData.map((el)=>{
                if(el.categories) el.categories.map((i)=>{
                  if(cats.hasOwnProperty(i.catId)){
                    cats[i.catId].produits=cats[i.catId].produits.concat(i.produits)
                  }
                  else{
                    cats[i.catId]=i
                  }
                })
              })
              response.data.jData[0].categories= Object.values(cats)
            }
              setSumDay(response.data.jData)
        }
     })
     setLoadingSum(false);
  },[])

    //handle get sdet d'une journee
   const getDetDay=useCallback(async(id)=>{
    setLoadingDet(true);
    const data=(id!==undefined)?{idjs:id}:{}
     const response= await Gfunc.axiosPostAction(service,"getjdet",data);
     if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err);
     }else
     Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          if(response.data.jData)
            setDetDay(response.data.jData)
            if(response.data.jData.length>0){
              let empt=false;
              response.data.jData.map((elem)=>{
                if(elem){
                  elem.tikets.map((el)=>{
                     if(!el.versement&&(!el.ventes||el.ventes[0].venteId===null)) {empt=true};
                     return true
                  })
                }
                return true;
              })
              setEmpty(empt)
            }
        }
     })
     setLoadingDet(false);
   },[])


   //handle get all caisses
   const getCaisses=useCallback(async()=>{
     setLoading1(true)
     const response= await Gfunc.axiosGetAction("_cai.php","getcaisseall",);
     if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err);
     }else
     Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
            setCaisses(response.data.jData)
        }
     })
     setLoading1(false)
  },[])

   //handle get all caisses
   const getInfRecolt=useCallback(async(id)=>{
    setEcart(0);
    setLoadingRec(true)
     const response= await Gfunc.axiosPostAction("_jrn.php","getrecoltinf",{jourId:id});
     if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err);
     }else
     Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
            setRecoltInf(response.data.jData)
            setClot(response.data.jData.caisse)
          setSelectedTime(response.data.jData.sysTime)
            const elem=document.getElementById("iClot"); if(elem){ setTimeout(() => elem.select(), 0);}
        }
     })
     setLoadingRec(false)
  },[])

   //handle get all caisses
   const DeleteEmpt=useCallback(async(id)=>{
     const response= await Gfunc.axiosPostAction("_jrn.php","delemptiks",{jourId:id});
     if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err);
     }else
     Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
            getDetDay([id]);
            const rec = recoltInf;
            rec.unpaidTikets = rec.unpaidTikets-1;
            setRecoltInf(rec)
        }
     })
  },[getDetDay,recoltInf])

   //handle recolter un jour
   const Recolt=useCallback(async(id)=>{
    setAction(true);
     const response= await Gfunc.axiosPostAction("_jrn.php","recolt",{jourId:id,caisse:clot,ecart:ecart,horraires:selectedTime});
     if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err);
     }else
     Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
            handleAlert(true,"success","La journee est recoltée")
            //getInfRecolt(id);
            getDays(year,month,lastJr,lastJn);
            /*const ind=Gfunc.getIndex(days[lastJr].journee,id,"journeeId")
            const el=days.map((e,index)=>{
                 if(index===lastJr){
                     e.journee.map((l,indd)=>{
                      if(indd===ind){
                        l.dateRecolte=Gfunc.formatDate(selectedTime)
                      }
                    } )
                 }
                 return(e)
            })*/
            Apis.Caisse();
            //setDays(el)
        }
     })
     setAction(false)
    // getDays(year,month,lastJr)
    
  },[days,lastJr,year,month,clot,ecart,selectedTime,getInfRecolt,handleAlert,getDays,lastJn])

   //handle pay unpaid tiket
   const payTik=useCallback(async(id,jourId)=>{
     const response= await Gfunc.axiosPostAction("_jrn.php","payunpaidtik",{tiketId:id});
     if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err);
     }else
     Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
            handleAlert(true,"success","Le ticket est payé");
            getDetDay([jourId]);
            if(lastJn===jourId) getInfRecolt(jourId)
            //getDays(year,month,lastJr,lastJn)
            Apis.Caisse();
        }
     })
  },[ids,year,month,lastJr,lastJn,getInfRecolt,handleAlert])

   //time handler
   const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };  

  useEffect(() => {
    getCaisses()
    getYears()
  }, [getYears,getCaisses]);

  
//handle accordionchange
const handleChangeAccordion = (panel)=>(event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const columns =useMemo(
    () => [
      {
          name: "Agent",
          selector: row=>row.agent,
          sortable: true,
          minWidth:'130px !important',
      },
      {
          name: "Caisse",
          selector: row=>row.caisse,
          sortable: true,
          style:numberColumn,
          minWidth:'115px !important',
          cell:(row)=>{
            return(
                <div>
                    {new Intl.NumberFormat().format(row.caisse)} 
                </div>
            )
        }
      },
    
    ],[Gfunc]); 

    //handle update note
const handleOnEditNote=useCallback(async(note,row,att,action)=>{
  const edt1=note
  var edt=Gfunc.DeleteSpace(edt1);
  if(row[att]!==edt){
    const attrib=row[att];
    const response=await Gfunc.axiosPostAction("_jrn.php",action,{tiketId:row.tiketId,note:note})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{row[att]=edt})
    if(response.data.err) {row[att]=attrib}
  }else {row[att]=edt}
  setSelecto("")
},[Gfunc]);


    //code jQuery pour boucler le focus des elements
$('form').each(function(){
  var list  = $(this).find('input*[tabindex],#last*[tabindex]').sort(function(a,b){ return a.tabIndex < b.tabIndex ? -1 : 1; }),
      first = list.first();
  list.last().on('keydown', function(e){
      if( e.keyCode === 9 ) {
          first.focus();
          return false;
      }
  });
});
 
  
  return (
    <div className="content">
      <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
      <BackdropCompo className={classes.backdrop} open={action}/>
         <div className="jrnGrid">
            <div className="jrnList">
              <div id="table">
            <DataTable 
                  persistTableHead
                  columns={columns}
                  data={caisses}
                  noDataComponent={param.ErreurData}
                  progressPending={loading1}
                  progressComponent={<CircularProgress className={classes.circularProgress} />}
                  noHeader={true}
                  highlightOnHover
                  customStyles={customStylesSmall}
                />
                <span id="caisTot"><p>Total</p><ArrowRight/><p>{Intl.NumberFormat().format(Gfunc.sumArObj(caisses,"caisse"))}</p></span>
                </div>
                <div id="selectYM">
                         <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iYear"
                                value={{year:year}}
                                onChange={(e,newValue) =>{
                                    setReset(!reset)
                                    setDays([])
                                    setYear(newValue.year);
                                    getMonths(newValue.year)
                                }} 
                                options={years}
                                getOptionLabel={(option) => option.year}
                                getOptionSelected={(option, value) => option.year === value.year||value.year===''}
                                renderInput={(params) => (
                                <TextField
                                    {...params} label={labelText1} variant='outlined' margin='dense' size="small"/>
                                )}/>
                         <Autocomplete
                                key={reset}
                                disableClearable
                                className='smallAuto'
                                id="iMonth"
                                value={{monthName:monthN,month:month}}
                                onChange={(e,newValue) =>{
                                    setMonth(newValue.month);
                                    setMonthN(newValue.monthName);
                                    getDays(year,newValue.month,0);
                                }} 
                                disabled={year===''||loading}
                                options={months}
                                getOptionLabel={(option) => option.monthName}
                                getOptionSelected={(option, value) => option.month === value.month || value.month===''}
                                renderInput={(params) => (
                                <TextField
                                    {...params} label={labelText2} variant='outlined' margin='dense' size="small" autoFocus/>
                                )}/>
                    </div>
                    <div id="jrList">
                        {(!loading)?(!noData)?days.map((elem,index)=>{
                            return(<Accordion className={classes.accordionRoot} key={"Acc"+index} expanded={expanded === index} onChange={handleChangeAccordion(index)}>
                                <AccordionSummary
                                    key={"AccSum"+index}
                                    className={classes.accordionSummary+" accSum"}
                                    onClick={()=>{if(lastJr!==index){setJour(elem.jour);setJourN(elem.jourName);setLastJr(index); setLastJn('');const ids=Gfunc.getAllIds(elem.journee,"journeeId"); setIds(ids); setIdss(ids); setRecoltInf({});getSumDay(ids);getDetDay(ids)}}}
                                    expandIcon={<ExpandMore />}
                                    aria-controls={index+"-content"}
                                    id ={"day"+index}>
                                <div className="accordTitle"><Typography className="accJour">{elem.jourName+" "+elem.jour}</Typography> <div className="accMoney"><Tooltip title="Recette" ><div className="accMoneyElem"><Dzd  style={{height:'18px',width:'18px',padding:"1px"}}/><p>{Intl.NumberFormat().format(parseFloat((elem.recette!=null&&elem.recette!=='')?elem.recette:0))}</p></div></Tooltip><Tooltip title="Marge" ><div className="accMoneyElemMarge" ><Profit fill='black' style={{height:'20px',width:'20px',padding:"1px"}}/><p>{Intl.NumberFormat().format(parseFloat((elem.marge!=null&&elem.marge!=='')?elem.marge:0))}</p></div></Tooltip></div></div>
                                </AccordionSummary>    
                                <AccordionDetails id ={"detail"+index}  className={classes.accordionDetails+" accDetails"} >
                                      <div className="accDet" id ={"detDiv"+index}>
                                          <div className="accDetInf" onClick={()=>{getSumDay(idss);getDetDay(idss);setLastJn('');setRecoltInf({})}}>
                                          <div className="detHeader"><Iconn path={mdiCashRegister} color="black" size="24px"/><div className="staticsHead"><h2>{Intl.NumberFormat().format(parseFloat((elem.caisse!=null&&elem.caisse!=='')?elem.caisse:0))}</h2><p>Caisse</p></div></div>
                                          <div className="detHeader"><Iconn path={mdiScaleUnbalanced} color="black" size="21px"/><div className="staticsHead"><h2>{Intl.NumberFormat().format(parseFloat((elem.ecart!=null&&elem.ecart!=='')?elem.ecart:0))}</h2><p>Ecart</p></div></div>
                                          </div>
                                          <table id="journalierID" key={"tab"+index} >
                                           <tbody>
                                            {elem.journee.map((el,index)=>{
                                              if(el.caisse||el.ecart||el.recette||el.marge) return(
                                                <tr key={"jr"+index} style={{background:(el.dateRecolte)?"rgb(217, 255, 176)":"#fdf0d0",filter:(lastJn===el.journeeId)?"brightness(65%)":"none"}}>
                                                  <td className="icon"><Tooltip title="Rapport" ><div id={"i"+el.journeeId} onClick={()=>{if(lastJn!==el.journeeId){setAgent(el.owner);setLastJn(el.journeeId);getInfRecolt(el.journeeId);getSumDay([el.journeeId]);getDetDay([el.journeeId]);setIds([el.journeeId]); const elem=document.getElementById("iClot"); if(elem){ setTimeout(() => elem.select(), 0);}}}}><Iconn  path={mdiFileTable} color={(el.dateRecolte)?"#00b300":"#ff4d2e"}/></div></Tooltip></td>
                                                  <td className="date"><AccessTimeOutlined className={classes.greyIcon} fontSize="small"/> {el.dateDebut}</td>
                                                  <td className="owner"><Person className={classes.greyIcon} fontSize="small"/> {el.owner}</td>
                                                  <td className="recette"><Iconn path={mdiScaleUnbalanced} color="black"/>{Intl.NumberFormat().format(el.ecart)}</td>
                                                </tr>)
                                                return null
                                              })}
                                            </tbody>
                                           </table>
                                      </div>
                                </AccordionDetails>
                        </Accordion>)}):<NoDataComponent message="Aucun résultat trouvé"/>:<div id="loading"><CircularProgress className={classes.circularProgress} /></div>}
                   </div>
            </div>
            <div className={(!Gfunc.isEmpty(recoltInf))?"middleSection":"middleSection2"}>
            {(!Gfunc.isEmpty(recoltInf))?
              <div id="dayInfo">
                <div ><Event className={classes.blackIcon}/><p>{jourN} {jour} {monthN} {year}</p></div>
                <div ><Person className={classes.blackIcon}/><p>{agent}</p></div>
                {(empty&&recoltInf.unpaidTikets>0)?<div><p className="emptyOrUnpaid">Payer les tickets impayés et supprimer les tickets vides avant de recolter!!</p><Tooltip title="Supprimer les tickets vides"><Delete fontSize="small" className={classes.redIcon} onClick={()=>DeleteEmpt(recoltInf.jourId)}/></Tooltip></div>:null}
                {(empty&&recoltInf.unpaidTikets<1)?<div><p className="emptyOrUnpaid">Supprimer les tickets vides avant de recolter!</p><Tooltip title="Suprimmer les tickets vides"><Delete fontSize="small" className={classes.redIcon} onClick={()=>DeleteEmpt(recoltInf.jourId)}/></Tooltip></div>:null}
                {(recoltInf.unpaidTikets>0&&!empty)?<p className="emptyOrUnpaid">Payer les tickets impayés avant de recolter!</p>:null}
              </div>:null}
            {(!Gfunc.isEmpty(recoltInf))?
            (!loadingRec)?<ValidatorForm id="recolt"  onSubmit={()=>Recolt(recoltInf.jourId)} onError={errors => console.log(errors)} >
                <fieldset className="fieldsetTop3" id="recolInf">
                  <legend>RECOLTER LA JOURNEE<p style={{color:(recoltInf.status==="Ouverte")?"#ff5000":"lime", marginLeft:"5px"}}>[{recoltInf.status}]</p></legend>
                    <div id="row-1">
                      <fieldset className="fieldInf" >
                                <legend> Début:</legend>
                                <p>{recoltInf.debut}</p>
                      </fieldset>
                      <fieldset className="fieldInf" >
                                <legend> Fin:</legend>
                                <p>{(recoltInf.recolteDate!=null)?recoltInf.recolteDate+" "+recoltInf.realTime:"-"}</p>
                      </fieldset>
                      {(recoltInf.status!=="Ouverte")?<fieldset className="fieldInf" >
                                <legend>Durée: </legend>
                                <p>{(recoltInf.realTime!=="00:00")?recoltInf.realTime:"--"}</p>
                      </fieldset>:
                      <fieldset className="fieldInf" >
                                <legend> Temps travaillé:</legend>
                                <p>{recoltInf.sysTime}</p>
                      </fieldset>}
                    </div>
                    <div id="row-2">
                      <fieldset className="fieldInf" >
                                <legend> Recette:</legend>
                                <p>{Intl.NumberFormat().format(Math.round(recoltInf.recette))}</p>
                      </fieldset>
                      <fieldset className="fieldInf" >
                                <legend> Caisse:</legend>
                                <p>{Intl.NumberFormat().format(Math.round(recoltInf.caisse))}</p>
                      </fieldset>
                      <fieldset className="fieldInf" >
                                <legend> Marge:</legend>
                                <p>{Intl.NumberFormat().format(Math.round(recoltInf.mben))}</p>
                      </fieldset>
                      <fieldset className="fieldInf" >
                                <legend> Encaissement:</legend>
                                <p>{Intl.NumberFormat().format(Math.round(recoltInf.encaissement))}</p>
                      </fieldset>
                      <fieldset className="fieldInf" >
                                <legend> Charges:</legend>
                                <p>{(recoltInf.charges&&recoltInf.charges!=="")?Intl.NumberFormat().format(Math.round(JSON.parse(recoltInf.charges))):"0"}</p>
                      </fieldset>
                    </div>
                    {
                    (recoltInf.status!=="Récoltée")?
                    <div id="row-3">
                    <TextField required autoFocus variant='outlined' size="small" label="Récolter à" id="iClot" name="iClot"   value={clot} type='number'  InputProps={{ inputProps: { min: 0 ,tabIndex:1} }}
                                    onChange={e =>{setClot(e.target.value);setEcart(parseInt(e.target.value)-recoltInf.caisse)}} /*onBlur={()=>handleMarge()}*/  className='smallText' disabled={recoltInf.status!=="Ouverte"}/>
                    <TextField required  disabled={true} variant='outlined' size="small" label="Ecart" id="iEcart" name="iEcart"   value={ecart} type='number'  InputProps={{ inputProps: { tabIndex:2} }}
                                    onChange={e =>{setEcart(e.target.value)}} /*onBlur={()=>handleMarge()}*/  className='smallText'/>
                    <TextField required disabled={false} variant='outlined' size="small" label="Durée" id="iDurée" name="iDurée"  value={selectedTime} type='text'  InputProps={{ inputProps: { tabIndex:2, pattern: '^([0-1][0-9]|2[0-3]):[0-5][0-9]$'} }}
                                    onChange={handleTimeChange} /*onBlur={()=>handleMarge()}*/  className='smallText'/>
                <Button disabled={(empty||(recoltInf.status==="Récoltée"||recoltInf.unpaidTikets>0))} className={classes.PrintBtn} tabIndex="4" id="last" variant="contained" type='submit' color="primary" form="recolt" startIcon={<Iconn path={mdi.mdiCashMultiple} color="white" size="16px" disabled={recoltInf.status!=="Ouverte"}/>}>RECOLTER</Button>
                </div>:
                  <div id="row-3">
                     <fieldset className="fieldInf" >
                                <legend> Ecart:</legend>
                                <p>{Intl.NumberFormat().format(Math.round(recoltInf.ecart))}</p>
                      </fieldset>
                  </div>}
                </fieldset>
              </ValidatorForm>:<div id="loading"><CircularProgress className={classes.circularProgress} /></div>:null}
              <div id="sommaireDiv">
                <div className="heading"><p>SOMMAIRE DES VENTES</p></div>
                {(!loadingSum)?(sumDay.length>0)?
                <table id='jrnSumTable'>
                  <thead>
                    <tr>
                      <th>PRODUIT</th>
                      <th>QTE.</th>
                      <th>MTN.</th>
                      <th>MARGE</th>
                    </tr>
                  </thead>
                  <tbody>
                   
                        {(sumDay[0].categories.length>0)?sumDay[0].categories.map((elem,index)=>{
                          return(
                          <Fragment key={"sum"+index}>
                              <tr id="infH" key={"tr1"+index}><td id="infHead" ><span id ="categorie" className="flex-wrapper">{(elem.icon!=null)?<Iconn path={mdi[elem.icon]}  size="22px"  style={{marginRight:"5px"}}/>:null}{elem.nom }</span></td><td colSpan={3} id="mt"><span id='mtt'><Tooltip title="Montant"><p id='mtn' className="flex-wrapper"><Dzd fill='black' style={{height:'18px',width:'18px',padding:"1px"}}/><span>{new Intl.NumberFormat().format(Gfunc.sumArObj(elem.produits,"mtn"))}</span></p></Tooltip><Tooltip title="Marge"><p id='marge'><Profit style={{height:'20px',width:'20px',padding:"1px"}}/><span>{new Intl.NumberFormat().format(Gfunc.sumArObj(elem.produits,"marge"))}</span></p></Tooltip></span></td></tr>
                              { (elem.produits!==undefined&&elem.produits.length>0)? elem.produits.map((el,index)=>{
                                  return(
                                    <tr id="inf" key={"tr2"+index}>
                                      <td id="prod">{el.nom}</td>
                                      <td id="qte">{new Intl.NumberFormat().format(el.qte)}</td>
                                      <td id="mtn">{new Intl.NumberFormat().format(Math.round(el.mtn))}</td>
                                      <td id="marge">{new Intl.NumberFormat().format(Math.round(el.marge))}</td>
                                    </tr>
                                  )})
                                  :null
                              }
                         </Fragment>)
                          }
                      )
                    :null}
                  </tbody>
                </table>:<NoTableDataComponent message="Il n'y a aucune vente."/>:<div id="loading"><CircularProgress className={classes.circularProgress} /></div>
}
              </div>
            </div>
            <div className="endSection">
            <div className="heading"><p>DETAIL DES VENTES</p></div>
              {(!loadingDet)?( detDay.length>0)?<>{ detDay.map((elo,index)=>{
                     return(
                     <div key={"detail"+index}>
                     {
                      elo.tikets.map((el,index)=>{
                      return(
                      <Card sx={{background:'#f5f5f5'}} key={"card"+index} id='card-acc' >
                        <div id='dig-exx' className="tikHead" style={{background:(!el.retour)?(el.reste>0)?"blue":'green':"#e50000",color:"white"}}> 
                            <div id='num-acc' ><Iconn path={mdi.mdiQrcode} color="white" size="16px"/>{(el.numero)}</div>
                            <div id="tikActions"></div>
                            <div id='date-acc'><Iconn path={mdi.mdiCalendarRange} color="white" size="16px"/>{el.date.replace(' ', ' à ' )}</div>         
                        </div>
                        <CardContent>
                          <div id='div-entete' className="tikBody">
                            <div id='agent-face'>
                              <Face className={classes.blackIconAcc}/>
                              <AutocompleteS
                                from="monJrn"
                                service={service} action={'updtikcli'} 
                                defaultValue={el.client} row={el} list={clientL} 
                                id={'clientId'} name='client' attr='name'
                                onOpen={handleOpenClient} handleAlert={handleAlert}/>
                            </div>
                            <div className="flex-wrapper centerContent">
                            {(el.caisseId==null&&el.total!==0)?<Tooltip title="Payer"><Button className={classes.iconBtn}  id="last" variant="contained" type='submit' color="primary" onClick={()=>payTik(el.tiketId,elo.jourId)}><Iconn path={mdi.mdiCashPlus} size="20px" color="white"/></Button></Tooltip>:null}
                             <PrintTicket idt={el.tiketId} from='jrn' disabled={(el.ventes&&el.ventes.length>0)?false:true}/>
                          </div>
                            <div id='mtn-face'>
                              <div id='mtn-face-1'>
                                <p>Versement:</p>
                                <p>Restant:</p>
                              </div>
                              <div id='mtn-face-2'>
                                <p><b  id='b'>{new Intl.NumberFormat().format(Math.round(el.versement))}</b></p>
                                <p><b  id='b'>{new Intl.NumberFormat().format(Math.round(el.reste))}</b></p>
                              </div> 
                            </div>
                          </div>
                          {
                            ((el.hasOwnProperty('ventes'))&&(!(el.ventes&&el.ventes.length===1&&el.ventes[0].venteId==null)))?
                            <>
                            <table id='jrnSumTable'>
                  <thead>
                    <tr>
                      <th>HEURE</th>
                      <th>PRODUIT</th>
                      <th>QTE.</th>
                      <th>MTN.</th>
                      <th>MARGE</th>
                    </tr>
                  </thead>
                  <tbody>
                        {(el.ventes.length>0)?el.ventes.map((elem,index)=>{
                          return(
                                 <Tooltip title={"Prix: "+elem.prix}>
                                    <tr id="inf" key={"detTr"+index} 
                                    style={{background:(elem.harm)?"rgb(180, 50, 50)":(!el.retour)?"#fff":"#fdf0d0",
                                                 color:(elem.harm)?"#fff":"black"}} >
                                      <td id="time">{elem.heure}</td>
                                      <td id="prod">{elem.produit}</td>
                                      <td id="qte">{new Intl.NumberFormat().format(elem.qte)}</td>
                                      <td id="mtn">{new Intl.NumberFormat().format(Math.round(elem.mtn))}</td>
                                      <td id="marge">{new Intl.NumberFormat().format(Math.round(elem.marge))}</td>
                                    </tr></Tooltip>
                                  )})
                                  :null}
                  </tbody>
                </table>
                </>
                            :(el.versement!=0)?
                            <div id='empty-ventes'>Ticket de versement.</div>:<div id='empty-ventes'>Ticket vide.</div>
                          }
                <div id="payTik">
                  <div className="flex-wrapper">
                     {//(el.note==null)?
                    <div className="noteDiv">
                    <Comment/>
                   { (!el.noteVersement)?<div className="monNote">
                      { (el.note===selecto && el.tiketId===Item )?
                        <OutsideClickHandler
                        onOutsideClick={e => {(note)?handleOnEditNote(note,el,"note","updtiknot"):setSelecto('');}}>
                        <TextareaAutosize style={textAreaEdit} onChange={(e)=>setNote(e.target.value)}  autoFocus onFocus={(e)=>{ e.target.value=note} } /> </OutsideClickHandler>:
                        ((el.note!=null&&el.note.match(/\r/g) || []).length>1)?
                        <Tooltip title={(el.note!=null)?el.note.replaceAll("\r", "/ "):""}>
                            <div onClick={()=>{setSelecto(el.note);setItem(el.tiketId);setNote(el.note.replace(/^\r|\r$/g, '\n'))}}>{el.note.substring(0, el.note.indexOf("\r"))+"..."}</div>
                        </Tooltip>:
                        <TextareaAutosize style={textAreaEditGray} onClick={()=>{setSelecto(el.note);setItem(el.tiketId);setNote((el.note!=null)?el.note.replace(/^\r|\r$/g, '\n'):"")}} value={(el.note)?el.note:""}/>}
                  </div> 
                    :<div className="monNote">
                      { (el.noteVersement===selecto && el.tiketId===Item )?
                        <OutsideClickHandler
                        onOutsideClick={e => {(note)?handleOnEditNote(note,el,"note","updtiknot"):setSelecto('');}}>
                        <TextareaAutosize style={textAreaEdit} onChange={(e)=>setNote(e.target.value)}  autoFocus onFocus={(e)=>{ e.target.value=note}} /> </OutsideClickHandler>:
                        ((el.noteVersement!=null&&el.noteVersement.match(/\r/g) || []).length>1)?
                        <Tooltip title={(el.noteVersement!=null)?el.noteVersement.replaceAll("\r", "/ "):""}>
                            <div onClick={()=>{setSelecto(el.noteVersement);setItem(el.tiketId);setNote(el.noteVersement.replace(/^\r|\r$/g, '\n'))}}>{el.noteVersement.substring(0, el.noteVersement.indexOf("\r"))+"..."}</div>
                        </Tooltip>:
                        <TextareaAutosize style={textAreaEditGray} onClick={()=>{setSelecto(el.noteVersement);setItem(el.tiketId);setNote((el.noteVersement!=null)?el.noteVersement.replace(/^\r|\r$/g, '\n'):"")}} value={el.noteVersement}/>}
                  </div> }
                  </div>//:null
                  }
                 
                  </div>
                  <div id='tick-div'><p id="tick-tot">Total du ticket:</p><b  id='total'>{new Intl.NumberFormat().format(Math.round(el.total))}</b></div>
                </div>

               
                
                       </CardContent>
                     </Card>)
                    })
                   }
                     </div>
                     )
                  })
                }
                </>:<NoTableDataComponent message="Il n'y a aucune vente."/>:<div id="loading"><CircularProgress className={classes.circularProgress} /></div>}
            </div>
         </div>
    </div>    

  );
}