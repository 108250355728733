import React,{useState,useCallback, useMemo} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField, CircularProgress} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {Add} from '@material-ui/icons';
import { useStyles,paperProps } from '../../../css/muiStyle';
import { AlertCompo, BackdropCompo } from '../../../js/components';
import DataTable from "react-data-table-component";
import '../../../css/vente.css';
import { customStyles, numberColumn } from '../../../css/datatable-custom';
import ParamContext from '../../../Contexte';
import { useContext } from 'react';


//add new vente
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Vnt_cpt_addAuto(props) {

    const param=useContext(ParamContext);
    const Gfunc=require('../../../Gfunc')
    const [qte,setQte]=useState(1);
    const [check,setCheck]=useState('');
    const service='_vnt.php'
    const classes=useStyles();
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [open, setOpen] =useState(false);
    const [action, setAction] =useState(false);
    const [able, setAble] =useState('');
    const [first, setFirst] =useState('');
  
   
    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

   //reste all fiels
   const reset=()=>{
    setQte(1)
    //setFirst(props.data.detail[0].pdetId)
   }
   const handleMtn=async(pdetId,qte)=>{
     var mtn=0;
      if(pdetId){ const data={
            pdetId:pdetId,
            qte:parseInt(qte)
          }
          const response = await Gfunc.axiosPostAction("_vnt.php","getpdinfvt",data)
          if(response.data.err&&response.data.err!=="-1"){
            handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{
            if(response.data.jData)
              mtn=response.data.jData.montant
          })}
          return mtn
  }
    // ajouter un nouveau bon
    const handleSubmit= async(e) =>{
        if(!action){
          if(qte!==''){
            var m= await handleMtn(able.pdetId,parseInt(qte))
            setAction(true)
          const dat={
            pdetId:able.pdetId,
            tiketId:props.ticketId,
            qte:parseInt(qte),
            stockId:parseInt(able.stockId),
            mtn:m,
            retour:props.retour,
          }
            const respon=await Gfunc.axiosPostAction(service,"addvman",dat)   
            if(respon.data.err && respon.data.err!=='-1'){
              handleAlert(true,'error',respon.data.err);
            }else
            Gfunc.axiosResponse(respon,async()=>{
              if(respon.data.jData){
                let t=parseFloat(m)
                let q=parseInt(qte)
                if(props.retour){ t=-t
                  q=-q}
                props.handleAlert(true,'success',"La vente est ajouté");
                props.handleAddVnt(respon,able.stockId,able.stock,t,q,able.pdetId,props.handleAlert,()=>{},()=>{},"iCode")  
                //const index=Gfunc.getIndex(props.data.detail,able.pdetId,'pdetId')
                //props.data.detail[index]=props.data.detail[index].qte-parseInt(qte)
                setOpen(false)
                props.Focus("iCode")
                reset()
              }
            })
            setAction(false)
        }else{
          handleAlert(true,'error',"Veuillez saisir la quantité!")
        }
      }
      }
      
    const handleClickOpen = () => { 
      setOpen(true)
      if(!Gfunc.isEmpty(props.data)&&props.data.detail.length>0) setFirst(props.data.detail[0].pdetId)
      setAble('')
      //document.getElementById(props.data.detail[0].pdetId).focus()
    };
  
    const handleClose = () => { setOpen(false); props.Focus("iCode") };

    const HandleUpdateDefault=useCallback(async(row)=>{
      setCheck(row)      
   },[])

      const columns =useMemo(
        () => [    
          {
              name: "Vente",
              minWidth:'100px !important',
              style:numberColumn,
              selector: row=>row.prixVente,
              sortable: true,
          },
          {
              name: "stock",
              minWidth:'100px !important',
              selector: row=>row.stock,
              sortable: true,
          },
          {
              name: "Qte.",
              minWidth:'100px !important',
              style:numberColumn,
              selector: row=>row.qte,
              sortable: true,
          },
          {
            name: "Choisir un stock",
            minWidth:'180px !important',
            cell: (row) => {
              return (
                <div className="smallElem"> 
                   <TextField id={"pp"+row.pdetId} autoFocus={(open&&able!=='')?able.pdetId===row.pdetId:first===row.pdetId} className='qteT' margin='dense' variant='outlined' size="small" value={(able.pdetId===row.pdetId)?qte:''} onFocus={()=>{setAble(row)}} onChange={(e)=>{setQte(e.target.value)}} type='number' /> 
                </div>
               );
             },
          },
        ],[classes,HandleUpdateDefault,check,qte,able,first]);
   
  return (
    <div>
      <div hidden id="addAutoBtn" className={classes.smallBtn} variant="contained" color="secondary" onClick={handleClickOpen} disabled={props.disabled}></div>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}>
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Sortie de stock</DialogTitle>
        <BackdropCompo className={classes.backdropDiag} open={action}/>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent >
            <ValidatorForm id="addVAuto"  onSubmit={handleSubmit} onError={errors => console.log(errors)} > 
            <fieldset className="fieldsetTop2" id="vntAddAuto">
                   <legend>Information de produit:</legend>
                   <fieldset className="fieldInf" >
                                  <legend> Nom:</legend>
                                  <p>{props.data.produit}</p>
                    </fieldset> 
                   <fieldset className="fieldInf" >
                                  <legend> Catégorie:</legend>
                                  <p>{props.data.categorie}</p>
                    </fieldset>                    
                   <fieldset className="fieldInf" >
                                  <legend>Stock:</legend>
                                  <p>{props.data.qte}</p>
                    </fieldset>                    
            </fieldset>
            <DataTable 
                  persistTableHead
                  columns={columns}
                  title="Veuillez choisir le stock de sortie."
                  data={props.data.detail}
                  noDataComponent={param.ErreurData}
                  progressComponent={<CircularProgress className={classes.circularProgress} />}
                  sortServer={true}
                  //noHeader={true}
                  highlightOnHover
                  customStyles={customStyles}
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}            
                />
              </ValidatorForm>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type='submit' color="primary" form="addVAuto" startIcon={<Add />}>Ajouter</Button>
              <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
