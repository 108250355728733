import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import {CircularProgress,Tooltip,Button,FormControlLabel,Checkbox,MenuItem,ListSubheader,TextField} from '@material-ui/core';
import Retmanfuncs from '../content/modals/ret_frs_act'
import RetrecSuiv from '../content/modals/ret_rec_suiv'
import {MonetizationOn, Search, Undo} from '@material-ui/icons';
import {AlertCompo,NoDataComponent,NotAllowedComponent} from "../../js/components"
import { Box } from "@mui/system";
import Icon from '@mdi/react';
import { mdiFilterMenu } from '@mdi/js';
import '../../css/global.css'
import {customStyles} from '../../css/datatable-custom'
import '../../css/datatable.css';
import { useStyles } from '../../css/muiStyle';

export default function Ref_frs (props){
  const cli=[{id:7,name:"Non traité"},{id:8,name:"swapé"},{id:9,name:"Rembourssé"}]
  const classes = useStyles();
  const librery=require('../../js/data_table_librery')
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const [data, setData] = useState([]);
  const [userL,setUserL]=useState([]);
  const [stockL,setStockL]=useState([]);
  const [filterE, setFilterE] = useState('');
  const [client, setClient] = useState(cli);
  const [mag,setMag]=useState([]);
  const [,setDel] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const[cpt, setCpt] = useState(0);
  const [pselected, setPselected] = useState([]);
  const [jselected, setJselected] = useState([]);
  const [clearRows, setClearRows] = useState(false);
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const[noData,setNoData]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[doSearch,setDoSearch]=useState(false);
  const [frnsL,setFrnsL]=useState([]);
  const[called,setCalled]=useState(false);
  const [check,setCheck]=useState([])

  const service='_ret.php'

   //handle alerts properties
   const handleAlert=useCallback((open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  },[])

  const fetch= async(page,servic = service, size = perPage) => {
    setCalled(true)
    setLoading(true)
        const response=await librery.axiosGet2(page+'&search='+search+"&filter="+filterE,service,'getrepa', size)
        if(response.data.err&&response.data.err!=="-1"){
          setErrMessage(response.data.err)
          setNotAllowed(true)
        }else
        Gfunc.axiosResponse(response,()=>{
          if(response.data.data!==undefined){
          setData(response.data.data);
          setTotalRows(response.data.recordsFiltered);
          setDoSearch(true);
        }else {setDoSearch(false);setData([])}
        setNoData(true)
    })
    setLoading(false)
  }

  const fetchUser= useCallback(async() => {
    const response = await Gfunc.axiosGetAction("_usr.php","gets")
    if(response.data.err&&response.data.err!=='-1'){handleAlert(true,'error',response.data.err);}
    else
    Gfunc.axiosResponse(response,()=>{
        setUserL(response.data.jData);   
    })
    return response;
  },[Gfunc,handleAlert]);

  //fetch fournisseur 
  const fetchFrns=useCallback(async() => {
    const response = await Gfunc.axiosGetAction("_frs.php","gets")
    if(response.data.err&&response.data.err!=='-1'){handleAlert(true,'error',response.data.err) ;}
    else
    Gfunc.axiosResponse(response,()=>{
        setFrnsL(response.data.jData);   
    })
    return response
  },[Gfunc,handleAlert])

  //fetch stocks 
  const fetchStock= useCallback(async() => {
    const response = await Gfunc.axiosGetAction("_stk.php","gets")
    if(response.data.err&&response.data.err!=='-1'){handleAlert(true,'error',response.data.err); }
    else
    Gfunc.axiosResponse(response,()=>{
        setStockL(response.data.jData);  
    })
    return response
  },[Gfunc,handleAlert])

    //handle the call for fetch user
    const handleOpenUser=useCallback(async()=>{
      if(userL.length===0)
      return await fetchUser();
      else return userL
  },[fetchUser,userL])
  
  
   //handle the call for fetch user
  const handleOpenStock=useCallback(async()=>{
      if(stockL.length===0)
      return await fetchStock();
      return stockL
  },[stockL,fetchStock])

  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);

  // fetch detail de magasin
 const fetchDetMag= useCallback(async() => {  
  const response = await Gfunc.axiosPostAction("_mag.php","getmag",{id:localStorage.getItem('magid')})
  Gfunc.axiosResponse(response,()=>{
     setMag(response.data.jData);
  })
},[Gfunc])
  
  //handle fetch magasin
  const handleFetchMag=useCallback(()=>{
    if(mag.length===0)
    fetchDetMag();
  },[fetchDetMag,mag])

  //handle the call for fetch fournisseur
  const handleOpenFrns=useCallback(async()=>{
    if(frnsL.length===0)
    return await fetchFrns();
    return frnsL
},[frnsL,fetchFrns])


  
  const handleSelectedRows=(selectedRows, selectedCount)=>{  
    if(cpt<selectedCount ){
      var dat=pselected.concat(selectedRows)
      const unique = [];
      dat.map(x => unique.filter(a => a.DT_RowId === x.DT_RowId).length > 0 ? null : unique.push(x));   
      setPselected(unique)
      setCpt(selectedCount)
      setJselected(selectedRows)
    }else
    if(cpt>selectedCount && selectedCount>0 ){
     var elem=jselected.filter(({ DT_RowId: id1 }) => !selectedRows.some(({ DT_RowId: id2 }) => id2 === id1));  
      var index = pselected.map(x => {
        return x["DT_RowId"];
      }).indexOf(elem[0]["DT_RowId"]);
      pselected.splice(index, 1);
      setJselected(selectedRows)
      setCpt(selectedCount)
    }else{
      setCpt(selectedCount)
      setJselected(selectedRows)
    }
}
  //vider les elemets selectionnes
const handleClearRows =useCallback(() => {
    setClearRows(!clearRows)
    setPselected([])
    setCpt(0)
  },[clearRows])

  //delete itam
  const handleSetData=useCallback((row,att)=>{
    Gfunc.removeItemsByAtt(data,row.DT_RowId,att)
    setDel('')
  },[data,Gfunc])

    //handle cancel a return
const handleCancel=useCallback(async(row)=>{
  const response=await Gfunc.axiosPostAction(service,"unrepas",{ids:[parseInt(row.DT_RowId.substr(1))]})
  if(response.data.err&&response.data.err!=='-1'){
    handleAlert(true,'error',response.data.err)
  }else
  Gfunc.axiosResponse(response,()=>{
    handleAlert(true,'success','Le retour est annulé')
    handleSetData(row,"DT_RowId")
    setDel(row.DT_RowId)
  })    
},[Gfunc,service,handleSetData,handleAlert])
const handleRemb=useCallback(async(row)=>{
  const response=await Gfunc.axiosPostAction(service,"mback",{retour:parseInt(row.DT_RowId.substr(1))})
  if(response.data.err){
    handleAlert(true,"error",response.data.err)
  }else  Gfunc.axiosResponse(response,()=>{  
            handleAlert(true,"success","Le remboursement est effectué avec succès")
            handleSetData(row,"DT_RowId")
            setDel(row.DT_RowId)
          })
},[Gfunc,service,handleSetData,handleAlert])

const HandleChange=useCallback((e,row)=>{
  if(e.target.checked===true){
    const ind=check.findIndex((obj => obj.DT_RowId ===row.DT_RowId));
    if(ind===-1){check.push(row)}
 }
 else{
     const ind=check.findIndex((obj => obj.DT_RowId ===row.DT_RowId))
     check.splice(ind,1)
 }
},[check])

  const columns =useMemo(
    () => [
      {
        width:"55px",
        cell: (row) => {
          return (
            <div >
               <FormControlLabel control={<Checkbox defaultChecked={Gfunc.getIndex(check,row.DT_RowId,"DT_RowId")>-1} className={classes.checkStelectableRow}
                  onChange={e=>HandleChange(e,row)} inputProps={{ 'aria-label': 'controlled' }}/>}
               />
            </div>
           );
         },
      }, 
      {   
          name: "DATE",
          width:"100px !important",
          selector: row=>row.date,
          sortable: true,   
          sortField:"date"        
      },
      { 
          name: "PRODUIT",
          minWidth:"200px !important",
          selector:row=>row.produit,
          sortable: true, 
          sortField:"produit"         
      },
      { 
        name: "SERIE",
        selector:row=>row.serie,
        width:"160px !important",
        sortable: true,
        sortField:"serie"        

      },
      { 
        name: "Fournisseur",
        width:"160px !important",
        selector:row=>row.frns,
        sortable: true,
        sortField:"frns"        
      },
      {
         name: "panne",
        width:"180px !important",
        selector:row=>row.note_panne,
        sortable: true,
        sortField:"panne" ,       
        cell:(row)=>{
            return (
              (row.note_panne!==null)?
              <div>
                { ((row.note_panne.match(/\r/g) || []).length>1)?
             <Tooltip title={row.note_panne.replaceAll("\r", "/ ")}>
                 <div >{row.note_panne.substring(0, row.note_panne.indexOf("\r"))+"..."}</div>
             </Tooltip>:
             <Tooltip title={row.note_panne}>
             <div>{row.note_panne}</div>
             </Tooltip>}
              </div>:null
            )
        }
      },
      { 
        name: "réparation",
        width:"180px !important",
        selector:row=>row.note_reparation,
        sortable: true,
        sortField:"note_reparation",    
        cell:(row)=>{
          return (
            (row.note_reparation!==null)?
            <div>
              { ((row.note_reparation.match(/\r/g) || []).length>1)?
           <Tooltip title={row.note_reparation.replaceAll("\r", "/ ")}>
               <div >{row.note_reparation.substring(0, row.note_reparation.indexOf("\r"))+"..."}</div>
           </Tooltip>:
           <Tooltip title={row.note_reparation}>
           <div>{row.note_reparation}</div>
           </Tooltip>}
            </div>:null
          )
      }
      },
      { 
        right:true,
        cell: (row) => {
          return (
            <div id="icons">
                <div className='rowGrid' id="row-3" >
                    <div >
                    {(row.reponseId===0)?<Retmanfuncs type="swap" service="_ret.php" action="swap" title="Swap client" rows={row} callback={()=>fetch(currentPage)} userL={userL} stockL={stockL} handleClearRows={handleClearRows} handleAlert={handleAlert} handleOpenUser={handleOpenUser} handleOpenStock={handleOpenStock}/>:null}
                    </div>
                    <div >
                    {(row.reponseId===0)?<MonetizationOn onClick={()=>handleRemb(row)} className={classes.yellowIcon} fontSize="small"/>:null }
                    </div>
                    <div  >
                    <Retmanfuncs type="destroy" service="_ret.php" action="destroy" title="Détruire un retour" rows={row}  callback={()=>fetch(currentPage)} handleClearRows={handleClearRows} handleAlert={handleAlert} stockL={stockL} userL={userL} handleOpenStock={handleOpenStock} handleOpenUser={handleOpenUser}/>
                    </div>
                    <div  >
                    <Retmanfuncs type="repa" service="_ret.php" action="makerepa" title="Réparation" rows={row} callback={()=>fetch(currentPage)} handleClearRows={handleClearRows} handleAlert={handleAlert} stockL={stockL} userL={userL} handleOpenStock={handleOpenStock} handleOpenUser={handleOpenUser}/>
                    </div>
                    <Tooltip title="Annuler" >
                    <Undo fontSize="small" onClick={()=>handleCancel(row)} className={classes.blueIcon}/> 
                    </Tooltip>
                    <div>
                      <RetrecSuiv mag={mag} handleFetchMag={handleFetchMag} from="frs" idr={parseInt(row.DT_RowId.substr(1))} handleOpenFrns={handleOpenFrns} frnsL={frnsL} handleOpenUser={handleOpenUser} userL={userL}/>
                    </div>
                </div>
            </div>
          )
        }
      },
    ],[classes,handleOpenUser,handleOpenStock,userL,stockL,handleCancel, handleClearRows,handleRemb,handleOpenFrns,frnsL,handleFetchMag,mag,fetch,currentPage,handleAlert]);
   
  //handle saerch
  const handleSearch=async(size = perPage)=>{
    //setFilterE('')
    setLoading(true);
    setCurrentPage(1)
    const recherche = search + "&filter=" + filterE;
    const response=await librery.axiosSearch(service,recherche,1,size,"getrepa")
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered)
    }else setData([])
    }) 
    setLoading(false);  
    return recherche
  }
  //handle filter
  const handleFilter=async(filter)=>{
    //setSearch('')
    setLoading(true);
    const response = await librery.axiosFilter(service, (filter + "&search=" + search), 1, perPage, "getrepa")
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setCurrentPage(1)
      setTotalRows(response.data.recordsFiltered);
      }else setData([])
      setLoading(false);
    })   
    return filter
  }

  //Sort serverside
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
    setLoading(true);
    const response=await librery.axiosSort(column,sortdirection,page,size,service,"getrepa")
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered)}
    })     
    setLoading(false)
}

//handle cancel a return multi
const handleCancels=async()=>{
  if(pselected.length>0){
    var ids=[]
    pselected.map(item=>{
        ids.push(parseInt(item.DT_RowId.substr(1)))
        return true
    })
    const response=await Gfunc.axiosPostAction(service,"unrepas",{ids:ids})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
      handleClearRows()
    }else
    Gfunc.axiosResponse(response,()=>{
        pselected.map(y=>{
          Gfunc.removeItemsByAtt(data,y.DT_RowId,"DT_RowId")
          return true
        })
        handleClearRows()
        handleAlert(true,'success','Les retours sélectionnés sont annulés')
        topFunction()
    })  
  }else handleAlert(true,"warning","Vous devez sélectionner au moins un élément!")
}

//handle the changes of the page
function handlePageChange (page) {
  fetch(page,service,perPage);
  setCurrentPage(page);
}

//handle the changes of the number of rows
const handlePerRowsChange = async (newPerPage, page) => {
  fetch(page,service, newPerPage);
  setPerPage(newPerPage);
}

//Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     handleSearch();
  }else{ if (e.keyCode === 13) {
            handleSearch();
          }
  }
}
function liveSearch2(e, search) {
  handleSearch();
}
function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  const handleVider=(val)=>{
    setCheck(val)
  }
return (
  <div className="content">
   <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA}/>
  <div className='table-header' >
  {(data.length>0)?
      <div className='button-flex-wrapper'>
      <Retmanfuncs type="swaps" service="_ret.php" action="swaps" title="Swap fournisseur" message='Swapper' rows={check}  callback={()=>fetch(1)} handleVider={handleVider} handleClearRows={handleClearRows} handleAlert={handleAlert} stockL={stockL} userL={userL} handleOpenStock={handleOpenStock} handleOpenUser={handleOpenUser}/>
      <Button variant="contained" color="secondary"  onClick={handleCancels} className={classes.topTabBtn} startIcon={<Undo/>}>Annuler</Button>
      <Retmanfuncs type="remb" service="_ret.php" action="mbacks" title="Remboursement fournisseur" message='Rembourser' rows={check}  callback={()=>fetch(1)} handleVider={handleVider} handleClearRows={handleClearRows} handleAlert={handleAlert} stockL={stockL} userL={userL} handleOpenStock={handleOpenStock} handleOpenUser={handleOpenUser}/>
      </div>
      :<div className='button-flex-wrapper'></div>}
      { (doSearch)?
      <div className='search' >
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField className={classes.search} variant="outlined" label="Recherche" value={search}
                             InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                             style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:'pointer' }} />),}} 
                             size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
                  <TextField
                  select
                  className={classes.headFilter}
                  label="Filtrer"
                  value={filterE}
                  size="small"
                  variant="outlined"
                  InputProps={{startAdornment: (<Icon path={mdiFilterMenu} size={0.8} style={{zIndex:"1000"}} />),}}
                  onChange={(e)=>{setFilterE(e.target.value);handleFilter(e.target.value)}}>
              <MenuItem value={""}>Aucun</MenuItem>
                         <ListSubheader>Client</ListSubheader>
                        {client.map((cat,index)=>{
                         return <MenuItem key={index} value={cat.id}>{cat.name}</MenuItem>
                         })}    
                </TextField>
      </Box>
    </div>:null}
     </div>
     { (data.length!==0 ||doSearch)?<div>
        <DataTable 
              persistTableHead
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              defaultSortAsc={true}
              paginationRowsPerPageOptions={[10,20,30,100]}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              onSort={handleOnSort}
              Clicked
              highlightOnHover
              customStyles={customStyles}   
              contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
              progressComponent={<CircularProgress className={classes.circularProgress} />} />
              </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
    </div>    
  );
}