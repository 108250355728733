import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,MenuItem,Checkbox,FormControlLabel,FormControl,Radio,RadioGroup,IconButton,Typography, Tooltip} from '@material-ui/core';
import Draggable from 'react-draggable';
import {SwapHoriz,SwapHorizontalCircle, Settings, AttachMoney, Cancel, DoneAll,Close} from '@material-ui/icons';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import  {useStyles,paperPropsSmallTall} from '../../../css/muiStyle';
import  '../../../css/retour.css';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import {AlertCompo} from "../../../js/components"
import { mdiCashRefund, mdiCloseOctagon, mdiSwapVerticalCircle } from '@mdi/js';
import Icon from '@mdi/react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { Construction } from '@mui/icons-material';


const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ret_man_act(props) {
    const Gfunc=require('../../../Gfunc')
    const [agent,setAgent]=useState('');
    const [stk,setStk]=useState('');
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [ids,setIds]=useState([]);
    const [id,setId]=useState('');
    const [note,setNote]=useState('');
    const [mtn,setMtn]=useState(0);
    const classes = useStyles();
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    const [checked,setChecked]=useState(true);
    const [radio, setRadio] = useState('0');


    
    const handleClickOpen =async() => {
      if(props.rows.length>0||(props.type==="repa"||props.type==="swap"||props.type==='destroy')){ 
        var ops=true
        var opu=true
      if(props.type==="swaps"||props.type==="repa"||props.type==="swap") {
        const resps=await props.handleOpenStock();
        if(resps.data!==undefined&&resps.data.err!==undefined) ops=false
        if(props.stockL.length>0) setStk(props.stockL[0].id); else setStk('') 
      }
      
      const respu=await props.handleOpenUser();
      if(respu.data!==undefined&&respu.data.err!==undefined) opu=false
      if(!(ops&&opu)){ props.handleClearRows(); return false}
      setOpen(ops&&opu)
      setAgent(parseInt(localStorage.getItem("uid")))
      setIds([])
      setId('')
      handleRows();
      setOpenA(false);
      setMessageA('');
      setMtn(0)
      setRadio('0')
      setNote('')
      setSeverity('info');
      setChecked(true)
      setSelectedDate(new Date());
    }
    else props.handleAlert(true,"warning","Vous devez sélectionner au moins un élément!")
    };
    const handleClose = () => { setOpen(false); };
  
    const handleDateChange = (date) => {
        setSelectedDate(date);
      };    

    const handleChange=(e)=>{
        setChecked(!checked)
      }

    const handleRows=()=>{
        var dat=[]      
        if(props.rows.length){
        if(props)
          props.rows.map(row=>{   
                dat.push(parseInt(row.DT_RowId.substr(1)))
          })
        setIds(dat)
        }else{
            setId(props.rows.DT_RowId)
        }
    }
    //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
    
    //handle subit form
    const handleSubmit= async(e) =>{
        var dat={}
        if(props.type==="swaps")
        dat={ids:ids,date:Gfunc.formatDate(new Date(selectedDate)),agent:agent,stock:stk}
        if(props.type==="remb")
        dat={ids:ids,date:Gfunc.formatDate(new Date(selectedDate)),agent:agent}
        if(props.type==="repa")
          if(parseInt(radio)&&checked) dat={retour:parseInt(id.substr(1)),date:Gfunc.formatDate(new Date(selectedDate)),agent:agent,stock:stk,note:note,isrep:checked,mtn:parseInt(mtn),inject:parseInt(radio)}
          else dat={retour:parseInt(id.substr(1)),date:Gfunc.formatDate(new Date(selectedDate)),agent:agent,stock:stk,note:note,isrep:checked,mtn:parseInt(mtn)}
        if(props.type==="destroy")
        dat={retour:parseInt(id.substr(1)),agent:agent,note:note}
        if(props.type==="swap")
        dat={retour:parseInt(id.substr(1)),date:Gfunc.formatDate(new Date(selectedDate)),agent:agent,stock:stk}
        const respon=await Gfunc.axiosPostAction(props.service,props.action,dat)
        if(respon.data.err){
            props.handleAlert(true,'error',respon.data.err);setOpen(false)
        }else Gfunc.axiosResponse(respon,()=>{
                if(respon.data.jData)
                var mess=''
                   if(props.type==="swaps"||props.type==="remb")
                      mess="Les produits sélectionnés ont été "+((props.type==="swaps")?'swapé':(props.type==="remb")?'rembourssé':null)+" avec succés"
                   else mess="Le produit sélectionné a été "+((props.type==="repa")?'réparé':(props.type==="destroy")?'détruit':(props.type==="swap")?'swapé':null)+" avec succés"
                     props.callback();
                     props.handleAlert(true,'success',mess)
                     setOpen(false);
                })
          props.handleClearRows()
    }
  return (
    <div>
      <Tooltip title={props.title}>
      <div>
      {
        (props.type==="swaps"||props.type==="remb")?
        <Button variant="contained" color="secondary" onClick={handleClickOpen} startIcon={(props.type==="swaps")?<SwapHoriz/>:(props.type==="remb")?<AttachMoney/>:null} className={classes.topTabBtn}>{props.message}</Button>
        :(props.type==="repa")?<Settings onClick={handleClickOpen} className={classes.grayIcon} fontSize="small"/>:(props.type==="destroy")?<Cancel className={classes.redIcon} onClick={handleClickOpen}  fontSize="small"/>:<SwapHorizontalCircle onClick={handleClickOpen} className={classes.blueIcon} fontSize="small"/>
      }
     </div>
     </Tooltip>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose} >
          <div style={{display:"flex",gridGap:"10px",alignItems:"center"}}>
           {
           (props.title==='Swap fournisseur')?
              <Icon path={mdiSwapVerticalCircle} size={1} />
            :(props.title==='Détruire un retour')?
              <Icon path={mdiCloseOctagon} size={1}/>
            :(props.title==='Réparation')?
              <Construction style={{height:'20px',width:'20px'}} />
            : <Icon path={mdiCashRefund} size={1} />
            }
            <label>{props.title} </label> 
          </div>

        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent >
            <ValidatorForm id="form" ref={useRef(null)} onSubmit={handleSubmit} >
                         <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                disableToolbar
                                size="small"
                                className='smallText' margin="dense" inputVariant='outlined'
                                maxDate={new Date()}  
                                invalidDateMessage="Le format de la date est incorrect"   
                                maxDateMessage="La date ne doit pas être supérieure à la date maximale"                               
                                variant="inline"
                                format="dd/MM/yyyy"
                                name="iDate"
                                id="date-picker-inline"
                                label={(props.type==="swaps")?"Date de réception":"Date"}
                                value={selectedDate}
                                onChange={handleDateChange}
                                required/>
                         </MuiPickersUtilsProvider>  
                        <TextValidator size="small"  onClick={(e) => e.stopPropagation()} select value={agent} className='smallText' margin="dense" variant='outlined'
                            label={(props.type==="swaps")?"Réceptionné par":(props.type==="repa")?"Réparateur/ Agent":"Agent"}
                            onChange={e =>setAgent(e.target.value)} 
                            validators={['required']} 
                            errorMessages={["Veuillez sélectionner un agent"]}>  
                            <MenuItem value={agent}>{localStorage.getItem('name')}</MenuItem>
                                {props.userL.map((user,index)=>{return (<MenuItem key={index} value={user.id}>{user.name}</MenuItem>)})}
                         </TextValidator>
                         { (props.type==="swaps"||props.type==="repa"||props.type==='swap')?
                        <TextValidator size="small"  select value={stk}  className='smallText' margin="dense" variant='outlined'
                            label={(props.type==="swaps"||props.type==="repa")?"Vers le stock":"Stock"}
                            onClick={(e) => e.stopPropagation()} 
                            onChange={e =>setStk(e.target.value)} 
                            validators={['required']} 
                            errorMessages={["Veuillez sélectionner un stock"]}
                            autoFocus>
                              {props.stockL.map((user,index)=>{return (<MenuItem key={index} value={user.id}>{user.nom}</MenuItem>)})}
                         </TextValidator>:null}
                         { (props.type==="destroy")?
                        <TextValidator size="small" label='Note' value={note.replaceAll("\r", ", ")} className='smallText' margin="dense" variant='outlined'
                            onClick={(e) => e.stopPropagation()} 
                            onChange={e =>setNote(e.target.value)} 
                            validators={['required']} 
                            errorMessages={["Veuillez sélectionner un stock"]}
                            autoFocus/>:null}
                        { (props.type==="repa")?
                        <div>
                            <TextValidator size="small" multiline rows={2} label='Note' value={note}  className='smallNote' margin="dense" variant='outlined'
                                onChange={e =>setNote(e.target.value)} 
                                validators={['required']} 
                                errorMessages={["Veuillez saisie une note"]}/>
                            <FormControlLabel control={
                                                    <Checkbox
                                                    checked={checked}
                                                    onChange={e=>handleChange(e)}
                                                    name="checked"
                                                    color="primary"/> }
                                                    label="Réparé"/>
                            <TextValidator size="small" label='Montant' value={mtn}  className='smallText' margin="dense" variant='outlined'
                                onChange={e =>setMtn(e.target.value)} 
                                disabled={!checked}
                                validators={['required']} 
                                errorMessages={["Veuillez saisie une note"]}/>
                             <FormControl disabled={!checked} component="fieldset">
                              <RadioGroup aria-label="" name="gender1" value={radio} onChange={e=>setRadio(e.target.value)}>
                                <FormControlLabel value={"1"} control={<Radio color="primary" />} label="Saisir autant que charge." />
                                <FormControlLabel value={"2"} control={<Radio color="primary"/>} label="Faire payer le client."/>
                                <FormControlLabel value={"3"} control={<Radio color="primary"/>} label="Faire payer le fournisseur." />
                                <FormControlLabel value={"0"} control={<Radio color="primary"/>} label="Ne rien faire." />
                              </RadioGroup>
                            </FormControl>
                        </div>:null}
                </ValidatorForm> 
           </DialogContent>
            <DialogActions>
              <Button variant="contained" form="form" type='submit' color="primary" startIcon={<DoneAll />}>VALIDER</Button>
            </DialogActions>
      </Dialog>
    </div>
  );
}