import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,Paper,Button,TextField,Dialog,IconButton,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Add,Close} from '@material-ui/icons';
import { ValidatorForm} from 'react-material-ui-form-validator';
import  {useStyles,paperPropsSmallF} from '../../../css/muiStyle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {AlertCompo} from "../../../js/components"
import $ from 'jquery'
import  '../../../css/stock.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ach_frs_add(props) {
    const Gfunc=require('../../../Gfunc')
    const [nom,setNom]=useState('');
    const [adresse,setAdresse]=useState('');
    const [tel,setTel]=useState('');
    const [seuil,setSeuil]=useState('');
    const [mail,setMail]=useState('');
    const [wilaya,setWilaya]=useState('');
    const [commune,setCommune]=useState('');
    const [user,setUser]=useState('');
    const [note,setNote]=useState([]);
    const [open, setOpen] = useState(false);
    const [wilayas,setWilayas]=useState([]);
    const [communes,setCommunes]=useState([]);
    const [classe,setClasse]=useState([]);
    const [selectClass,setSelectClass]=useState('');
    const [users,setUsers]=useState([]);
    const [error, setError] =useState(false);
    const [errorN, setErrorN] =useState(false);
    const [errorA, setErrorA] =useState(false);
    const [errorC, setErrorC] =useState(false);
    const [errorW, setErrorW] =useState(false);
    const [errorT, setErrorT] =useState(false);
    const [errorCl, setErrorCl] =useState(false);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const classes = useStyles();
    const service=(props.val!=='clt')?"_frs.php":"_cli.php"

    $(document).ready(function(){
      $('#tel-edit').on('input', function(ev) {
        var $this = $(this);
        var value = $this.val();
        if (value && value.length >10) {
          $this.val(value.substr(0,10));
        }
      })
      });

    const handleClickOpen = () => { 
      setNom('');setAdresse('');setTel('');setSeuil('');setMail('');setWilaya('');setCommune('');setUser('');setNote('');setCommunes([]);
      setWilayas([]);setUsers([]);setError(false);setSelectClass('')
      setErrorN(false);setErrorA(false);setErrorW(false);setErrorC(false);setErrorT(false);
      setOpen(true); 
    };
    const handleClose = () => { setOpen(false); };
   
      //handle alerts properties
      const handleAlert=(open,severity,message)=>{
        setOpenA(open)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
      }

    //fetch wilaya
    const fetchWilaya= useCallback(async() => {
      const response = await Gfunc.axiosPostAction("_ref.php","getswils")
      if(response.data.err){
        //document.getElementById(id).blur();
        setOpen(false)
        props.handleAlert(true,'error',response.data.err)
      }else 
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==undefined)
          setWilayas(response.data.jData);   
      })
    },[Gfunc,props]);

    //fetch class
    const fetchClass= useCallback(async() => {
      const dat=[]
      const response = await Gfunc.axiosPostAction(service,"getclass")
      if(response.data.err){
        //document.getElementById(id).blur();
        setOpen(false)
        props.handleAlert(true,'error',response.data.err)
      }else 
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==undefined)
        Object.keys(response.data.jData).map((key)=> {
          dat.push({id:key,name:response.data.jData[key]})
          return true
        })  
          setClasse(dat);   
      })
    },[Gfunc,props]);

   //fetch commune 
   const fetchCommune= useCallback(async() => {
    setCommunes([])
    const objIndex = wilayas.findIndex((obj => obj.name ===wilaya )); 
    const wil=wilayas[objIndex].id
    const response = await Gfunc.axiosPostAction("_ref.php","getswilcoms",{wil:wil})
    if(response.data.err){
      setOpen(false)
      props.handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        setCommunes(response.data.jData);   
    })
    },[Gfunc,wilayas,wilaya,props]);

    //fetch users 
    const fetchUser= useCallback(async(id) => {
      const response = await Gfunc.axiosGetAction("_usr.php","gets")
      if(response.data.err){
        setOpen(false)
       props.handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
          setUsers(response.data.jData);   
      })
    },[Gfunc,props]);
    
    //handle the call for fetch produit
    const handleOpenWilaya=()=>{
        setError(false)
        if(wilayas.length===0) fetchWilaya();
    }
    //handle the call for fetch classe
    const handleOpenClasse=()=>{
        if(classe.length===0) fetchClass();
    }

     //handle the call for fetch commune
    const handleOpenCommune=()=>{
        if(wilaya!=='')
        {
            fetchCommune();
        }else{
            setError(true)
        }
    }
     //handle the call for fetch compte
    const handleOpenUser=(event)=>{
        if(users.length===0)
        fetchUser(event.target.id);
    }
    
  //valider formulaire avec touche clavier 
  const detectTouche= (e)=>{
    }
 //handle submit form
  const handleSubmit= async() =>{
    var cl='';var usr=''
    if(nom===''){setErrorN(true)}
    if(tel===''){setErrorT(true)}
    if(wilaya===''){setErrorW(true)}
    if(commune===''){setErrorC(true)}
    if(props.val==='clt'){
       if(selectClass===''){setErrorCl(true)}
    }
    if(nom!==""&&tel!==''&&wilaya!==''&&commune!==''){
        const objIndex = wilayas.findIndex((obj => obj.name ===wilaya )); 
        const objIndex1 = communes.findIndex((obj => obj.name ===commune )); 
        const wil=wilayas[objIndex].id
        const com=communes[objIndex1].id
        if(user!==''){
          const objIndex2 = users.findIndex((obj => obj.name ===user )); 
          usr =users[objIndex2].id
        }
        if(props.val==='clt'){
          const objIndex3 = classe.findIndex((obj => obj.name ===selectClass )); 
          cl=classe[objIndex3].id
        }
        const response = await Gfunc.axiosPostAction(service,"add",(props.val!=='clt')?
               {name:nom,tel:tel,user:usr,note:note,adresse:adresse,wilaya:wil,seuil:seuil, commune:com,mail:mail}:
               {name:nom,tel:tel,commercialId:usr,note:note,adresse:adresse,wilaya:wil,seuil:seuil, commune:com,mail:mail,classe:parseInt(cl)})
        if(response.data.err){
          handleAlert(true,'error',response.data.err)
        }else{
            Gfunc.axiosResponse(response,()=>{
                if(response.data.jData){
                  const message=(props.val==='clt')?"Un nouveau client a été ajouté":"Un nouveau fournisseur a été ajouté"
                   props.handleAlert(true,"success",message)
                   props.callback()
                   setOpen(false)
                }
         })
        }
      }
    }

    const testPhone=(e)=>{
      const re = /^[0-9\b]+$/;
      if (e.target.value==='' || re.test(e.target.value)) {
        setTel(e.target.value)
      }
    }

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Add/>} className={classes.topTabBtn}>{(props.val!=='clt')?"Nouveau Fournisseur":"Nouveau Client"}</Button>
      <Dialog
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallF}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
            <div className='DialogTitle'>
              <Icon path={mdiPlusBox} size={1} />
              <label> {(props.val!=='clt')?'Ajouter un nouveau fournisseur':'Ajouter un nouveau client'}</label>
            </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
        <ValidatorForm  id="formFrns" ref={useRef(null)} onSubmit={handleSubmit} onKeyDown={e=>detectTouche(e)} >
            <DialogContent >
                <div className="form-two-grid-container">
                        <TextField   error={errorN} helperText={(errorN)?"Veuillez sélectionner un nom":null}
                          label='Nom' id="iNom" name="iNom" value={nom} className='smallText' margin='dense' variant='outlined' autoFocus
                                    onChange={(e)=>{setNom(e.target.value);setErrorN(false)}} required />
                        <Autocomplete
                                disableClearable
                                className='smallAuto' 
                                id="iWialya"
                                onChange={(e,newValue) =>{setWilaya(newValue);setErrorW(false)}} 
                                onFocus={handleOpenWilaya}
                                value={(wilaya!=='')?(wilaya):null}
                                options={wilayas.map((option) => option.name)}
                                renderInput={(params) => (
                                <TextField
                                    error={errorW}
                                    {...params}
                                    label="Wilaya"
                                    size="small" 
                                    margin='dense' variant='outlined'
                                    helperText={(errorW)?"Veuillez sélectionner une wilaya":null}
                                    required/>
                                )} />
                        <TextField   error={errorA} 
                                     label='Adresse' id="iAdresse" name="iAdresse" value={adresse} 
                                     className='smallText' margin='dense' variant='outlined'
                                     onChange={(e)=>{setAdresse(e.target.value);}} />    
                       <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iCommune"
                                onChange={(e,newValue) =>{setCommune(newValue);setErrorC(false)}} 
                                onFocus={handleOpenCommune}
                                value={(commune!=='')?(commune):null}
                                options={communes.map((option) => option.name)}
                                renderInput={(params) => (
                                <TextField
                                    error={error||errorC}
                                    {...params}
                                    label="Commune"
                                    size="small" 
                                    margin='dense' variant='outlined'
                                    helperText={(error)?"Veuillez sélectionner une wilaya en amont":(errorC)?"Veuillez sélectionner une commune":null}
                                    required/>
                                )} />
                        <TextField  type="tel" error={errorT} helperText={(errorT)?"Veuillez sélectionner un num. Tel":null} 
                                    label='Tel' id="tel-edit" name="iTel" value={tel}  className='smallText' margin='dense' variant='outlined'
                                    onChange={(e)=>{testPhone(e)}} required
                                    inputProps={{ pattern: "[0][1-7][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]"}}
                                    />   
                        <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id={(props.val==='clt')?"iCommercial":"iCompte"}
                                onChange={(e,newValue) =>{setUser(newValue)}} 
                                onFocus={handleOpenUser}
                                value={(user!=='')?(user):null}
                                options={users.map((option) => option.name)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={(props.val==='clt')?"Commercial":"Compte"}
                                    size="small" 
                                    margin='dense' variant='outlined'
                                     />
                                )} />
                       <TextField  
                                    label='Seuil' id="iSeuil" name="iSeuil" value={seuil}  className='smallText' margin='dense' variant='outlined'
                                    onChange={(e)=>{setSeuil(e.target.value)}}                                 
                                    />  
                        <TextField    
                                    label='Mail' id="iMail" name="iMail" value={mail}  className='smallText' margin='dense' variant='outlined'
                                    onChange={(e)=>{setMail(e.target.value)}}
                                    />  
                  
                  </div>
                  
                  {(props.val==='clt')?
                   <div id='row'>
                    <Autocomplete
                        disableClearable
                        className='smallAuto' 
                        id="iClass"
                        onChange={(e,newValue) =>{setSelectClass(newValue);setErrorCl(false)}} 
                        onFocus={handleOpenClasse}
                        value={(selectClass!=='')?(selectClass):null}
                        options={classe.map((option) => option.name)}
                        renderInput={(params) => (
                            <TextField {...params}
                                       error={errorCl} label="Classe" size="small" margin='dense' variant='outlined' required
                                       helperText={(errorCl)?"Veuillez sélectionner une classe":null}/>
                                )} />                  
                              </div>:<></>}
                  <div className='note-add'>
                    <TextField label='Note' id="iNote" name="iNote" value={note}  className='smallNote' margin='dense' variant='outlined'
                             multiline minRows={3} onChange={(e)=>{setNote(e.target.value);}} /> 
                  </div>
           </DialogContent>
            <DialogActions>
                <Button variant="contained" name="iValide" form="formFrns" type='submit' color="primary" startIcon={<Add />}>AJOUTER</Button>
            </DialogActions>
            </ValidatorForm>

      </Dialog>
      </div>
  );
}
