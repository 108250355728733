import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import {CircularProgress,ClickAwayListener,TextareaAutosize,Tooltip} from '@material-ui/core';
import {Search,Lock,LockOpen, Person} from '@material-ui/icons';
import {conditionalRowStyles,customStyles}from '../../css/datatable-custom'
import { useStyles ,textAreaEdit} from '../../css/muiStyle';
import {AlertCompo,AutocompleteS,InputNbrEditable,NoDataComponent,NotAllowedComponent,TelUpd} from "../../js/components"
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from "@mui/system";
import $ from 'jquery';
import {ReactComponent as Limit} from '../../img/icons/seuil.svg'
import Dialog from './modals/ach_frs_add'
import './../../css/stock.css'
import './../../css/referentiel.css'


export default function Ach_frs(props){
  const classes=useStyles()
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [wilaya,setWilaya]=useState('');
  const [wilayas,setWilayas]=useState([]);
  const [communes,setCommunes]=useState([]);
  const [,setCheck1]=useState('');
  const [,setvendable]=useState(false);
  const [Icon,setIcon]=useState('');
  const [RespWils, setRespWils] =useState(false);
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const[noData,setNoData]=useState(false); 
  const[notAllowed,setNotAllowed]=useState(false);
  const[,setError]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[doSearch,setDoSearch]=useState(false);
  const[called,setCalled]=useState(false);
  const [selecto,setSelecto]=useState('')
  const [Item,setItem]=useState('')
  const [ItemN,setItemN]=useState('')
  const [note,setNote]=useState('')
  const service='_frs.php'
  const srv='frns'
  const matches = useMediaQuery('(max-width:600px)');

  
   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  const fetch=useCallback(async(page,servic = service, size = perPage) => {
    setCalled(true)
    setLoading(true);
    const response=await librery.axiosGet(page+'&search='+search,servic,size)
      if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setDoSearch(true)
        setNoData(true)
        }else {setDoSearch(false);setData([])}
      })
    setLoading(false)
  },[Gfunc,librery,perPage,search])

     //fetch wilaya
     const fetchWilaya= useCallback(async() => {
      setRespWils(true)
      const response = await Gfunc.axiosPostAction("_ref.php","getswils")
      if(response.data.err){
        //document.getElementById(id).blur();
        setOpen(false)
        handleAlert(true,'error',response.data.err)
      }else 
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==undefined)
          setWilayas(response.data.jData);   
      })
    },[Gfunc]);
  

  useEffect(() => {
    if(!called) fetch(1);
    if(!RespWils)fetchWilaya();
    Gfunc.loseFocus('.content-editable')
  }, [fetch,fetchWilaya,called,wilayas,Gfunc]);
  

  //flagman function 
  const flagMan = useCallback(async( e,row,att)=>{
    var val=''
    if(att==='vendable'){
         val=(row[att]===true)?1:0
    }
    const objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId)); 
    const response= await Gfunc.axiosPostAction('flagman.php','',({ srv:srv, id: row.DT_RowId.substr(1),att:att, val:(val==='')? row[att]:val }))
      setCheck1(row.DT_RowId)
    if(att==='vendable'){
      if(response.data.err){setvendable(row[att])}
      else{
           setvendable(!row[att])
           Gfunc.axiosResponse(response,()=>{
              setvendable(!row[att])
            //setIcon(row.DT_RowId) 
            })
      }
    }
    else{
      Gfunc.axiosResponse(response,()=>{
        if(row.activ){
            data[objIndex].activ=0
          }else data[objIndex].activ=1
        setIcon(row.DT_RowId) 

            })
    
    }
   
  },[Gfunc,data,srv]);

  //update field
  const handleOnEdit=useCallback(async(e,row,att)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
        if(row[att]!==edt &&edt!==''){
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt,att) 
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt}
  },[librery,Gfunc]);

  const handleLockIcon=useCallback((val)=>{
      setIcon(val);
  },[]);
 
  useEffect((val) => {
    handleLockIcon(val);
  }, [handleLockIcon]);

 //fetch commune 
 const fetchCommune= useCallback(async(val) => {
   var wil=''
  setCommunes([])
  if(wilaya!==''){
    const objIndex = wilayas.findIndex((obj => obj.id ===wilaya )); 
    wil=wilayas[objIndex].id
  }else{
    const objIndex = wilayas.findIndex((obj => obj.name ===val )); 
    wil=wilayas[objIndex].id
  }
  const response = await Gfunc.axiosPostAction("_ref.php","getswilcoms",{wil:wil})
  if(response.data.err){
    setOpen(false)
    handleAlert(true,'error',response.data.err)
  }else
  Gfunc.axiosResponse(response,()=>{
      setWilaya('')
      setCommunes(response.data.jData);   
  })
  },[Gfunc,wilayas,wilaya]);

   //handle the call for fetch produit
   const handleOpenWilaya=useCallback((event)=>{
    setError(false)
    if(wilayas.length===0)
    fetchWilaya();
   },[wilayas.length,fetchWilaya])

 //handle the call for fetch commune
const handleOpenCommune=useCallback((row)=>{
        fetchCommune(row.wilaya);
},[fetchCommune])

const handleSelect=useCallback((row,att)=>{
    setSelecto(row[att])
    setItem(row.DT_RowId)
    setItemN(att)
 },[])




$('.numberonly').on( "keypress",function (e) {    

  var charCode = (e.which) ? e.which : e.keyCode    

  if (String.fromCharCode(charCode).match(/[^0-9]/g))    

      return true;                        

});    

$('#tel-edit').on( "keydown",function(e){ check_charcount('tel-edit', 9, e); });

function check_charcount(content_id, max, e)
{   
    if(e.which !== 8 && $('#'+content_id).text().length > max)
    {
       // $('#'+content_id).text($('#'+content_id).text().substring(0, max));
       e.preventDefault();
    }
}

  //handle update note
  const handleOnEditNote=useCallback(async(note,row,att,action)=>{
    const edt1=note
    var edt=Gfunc.DeleteSpace(edt1);
    if(row[att]!==edt){
      edt=edt.replaceAll("\n", "\r")
      const attrib=row[att];
      const response=await librery.axiosEdit2(service,action,row,edt,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{row[att]=edt})
      if(response.data.err) {row[att]=attrib}
    }else {row[att]=edt}
    setNote('')
    setSelecto('')
  },[librery,Gfunc]);


  const columns =useMemo(
    () => [   
      {
          name: "Nom",
          selector: row=>row.frns,
          sortable: true,
          width:'240px !important',
          sortField:'frns',
          cell: (row) => {
            return (
            <ContentEditable
                html={(row.frns)?row.frns:''}
                name="frns"
                data-column="item"
                className="content-editable"
                disabled={false} 
                onBlur={e => handleOnEdit(e,row,"frns")}
          />
             );
           },
      },
      {
          name: "Adresse",
          selector: row=>row.adresse,
          sortable: true,
          minWidth:'170px !important',
          sortField:'adresse',
          cell: (row) => {
            return (
            <ContentEditable
              html={(row.adresse!=='')?row.adresse:''}
              name="adresse"
              data-column="item"
              className="content-editable"
              disabled={false} 
              onBlur={e => handleOnEdit(e,row,"adresse")}
            />
             );
           },
      },
      {
        name: "Commune",
        selector: row=>row.commune,
        sortable: true,
        minWidth:'150px !important',
        sortField:'commune',
        cell: (row) => { 
            return (
                 <AutocompleteS service={service} action={'upd'} defaultValue={row.commune} row={row} list={communes} 
                                id={'communeId'} name='commune' attr='name' onOpen={()=>handleOpenCommune(row)} handleAlert={handleAlert}/>      
            );
        }
      },
      {
        name: "Wilaya",
        selector: row=>row.wilaya,
        sortable: true,
        width:'140px !important',
        sortField:'wilaya',
        cell:(row)=>{
            return(
                  <AutocompleteS service={service} action={'upd'} defaultValue={row.wilaya} row={row} list={wilayas} 
                                 id={'wilayaId'} name='wilaya' attr='name' onOpen={handleOpenWilaya} handleAlert={handleAlert}/>  
            );
        }
      },
      {
        name: "Tel",
        selector: row=>row.tel,
        sortable: true,
        width:'120px !important',
        sortField:'tel',
        cell: (row) => {
            return (
             <div key={Math.random()}>
                <TelUpd datein={row.datin} agentin={row.user} dateupd={row.dateUpd} agentupd={row.agentUpd} service={service} action='upd'
                        row={row} att="tel" value={row.tel} id='DT_RowId' handleAlert={handleAlert}/>
             </div>
             );
           },
      },
      {
        name: "Note",
        selector:row=>row.note,
        sortable: true,
        minWidth:'240px !important',
        sortField:'note',
        cell: (row) => {
          return (
           <div className="note-container">
              { 
                (row.note===selecto && ItemN==='note' && row.DT_RowId===Item)?
                <ClickAwayListener onClickAway={()=>{(note)?handleOnEditNote(note,row,"note",'upd'):setSelecto('')}}>
                <TextareaAutosize style={textAreaEdit} autoFocus onFocus={(e)=>{ e.target.value=note}}  onChange={(e)=>{setNote(e.target.value);}}
                /></ClickAwayListener>:
                (((row.note&&row.note.match(/\r/g)) || []).length>=1)?
                <Tooltip title={row.note.replaceAll("\r", "/ ")}>
                    <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}}>{row.note.substring(0, row.note.indexOf("\r"))+"..."}</div>
                </Tooltip>:
                  <Tooltip title={row.note?row.note:''}>
                <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}} >{row.note}</div></Tooltip>
              }
           </div>
           );
           },
      },
      {
        right:true,
        cell: (row) => {
          if(Icon===row.DT_RowId){  
            if(row.activ===1)
            row.activ=0
            else
            row.activ=1   
            setTimeout(() => { handleLockIcon('')},100)         
          }
          return (
            <Tooltip title="Activer/Désactiver">
              <div onClick={e=>flagMan(e,row,'activ')} >
                {(row.activ)?<LockOpen className={classes.redIcon} fontSize="small"/>:<Lock className={classes.greenIcon} fontSize="small"/> }
              </div> 
            </Tooltip>       
          );
         },
      }  
  ],[flagMan,handleOnEdit,handleLockIcon,handleOnEditNote,handleOpenCommune,handleOpenWilaya,handleSelect,note,Icon,classes,Item,
     ItemN,selecto,wilayas,communes]);
   

  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service,'get')
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      })     
  }

  const handleSearch=async( size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,'get')
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
    return recherche
  }

  function handlePageChange (page) {
    fetch(page,service,perPage);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,service, newPerPage);
    setPerPage(newPerPage);
  };
  
  //Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }

  function liveSearch2() {
    handleSearch();
  }

  const ExpanableComponent =({data}) => {

    return (
       <div className="row-expenderr">
         <div id='frns-expend'>
            <div id="item"> 
              <Limit style={{width:'20px',height:'20px'}}/>
              <p>Seuil</p>
              <InputNbrEditable service={service} action='upd' min={1} row={data} att="seuil" value={data.seuil} id='DT_RowId'
                                className="content-editable-exp" idi={data.DT_RowId} handleAlert={handleAlert}/>
            </div>
            <div id="item">
              <Person className={classes.blackIconn}/>
              <p>{data.user}</p> 
            </div>
          </div>
       </div>
    )
  }


  return (
    <div className="content">
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <div className='table-header'>
          <div className='button-flex-wrapper' ><Dialog handleAlert={handleAlert} callback={()=>fetch(1)} media={matches}/></div>
          {(doSearch)?
            <div className='search' >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} id="input-with-sx" label="Recherche" value={search} variant="outlined"
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:'pointer' }} />),}}
                               size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
              </Box>
            </div>:null}
        </div>
        {(data.length!==0||doSearch)?<div>
        <div id='exp-DT'>
          <DataTable 
              persistTableHead
              columns={columns}
              data={data}
              noHeader={true}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              customStyles={customStyles}
              expandOnRowClicked
              expandableRows 
              expandableRowsComponent={ExpanableComponent}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
         />
        </div>
        </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </div> 
  );
}
