import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import {CircularProgress, Box, Tooltip} from '@material-ui/core';
import {Search,Lock,LockOpen} from '@material-ui/icons';
import DraggableDialog from './modals/ref_mrq_add'
import { useStyles } from '../../css/muiStyle';
import {conditionalRowStyles,customStyles, iconCell}from '../../css/datatable-custom'
import {NoDataComponent,NotAllowedComponent} from "../../js/components"
import {AlertCompo} from "../../js/components"


export default function Ref_mrq (props){
  const classes=useStyles();
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [Icon,setIcon]=useState('');
  const[noData,]=useState(false);
  const[doSearch,setDoSearch]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,]=useState('');
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const [openA, setOpenA] =useState(false);
  const service='_mrq.php'
  const srv="marque"

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(true)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

 //fetch marque
  const fetch= useCallback(async(page,servic = service, size = perPage) => {
    setFocus(true)
    setLoading(true);
    const response=await librery.axiosGet(page,servic,size)
    if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,"error",response.data.err)
       setNotAllowed(true)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered); 
        setDoSearch(true) 
      }     
    })  
  setLoading(false);  
  },[Gfunc,librery,perPage]);

  useEffect(() => {
    if(!focus){fetch(1);}
    Gfunc.loseFocus('.content-editable')
  }, [fetch,Gfunc,focus]);
  
  const flagMan = useCallback(async( row)=>{
    const objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId)); 
    const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: row.DT_RowId.substr(1),att:'activ', val: row.activ }))
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
   }else
    Gfunc.axiosResponse(response,()=>{ 
        setIcon(row.DT_RowId)      
            })
            if(row.activ){
              data[objIndex].activ=0
            }else data[objIndex].activ=1
  },[Gfunc,data])

  //update field
  const handleOnEdit=useCallback(async(e,row,att)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
        if(row[att]!==edt){
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt,att) 
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,"error",response.data.err)
         }else
          Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
          if(response.data.err) {setLoading(true);e.target.innerHTML=attrib;setLoading(false)}
        }else {e.target.innerHTML=edt}
  },[librery,Gfunc]);

//icon change handler
const handleLockIcon=(val)=>{
  setIcon(val);
}
useEffect((val) => {
handleLockIcon(val);
}, []);

const columns =useMemo(
    () => [   
      {
          name: "NOM",
          minWidth:"180px !important",
          selector:row=>row.marque,
          sortable: true,
          sortField:"marque",
          cell: (row) => {
            return (
          <div>
            <ContentEditable
              html={(row.marque!==null)?row.marque:''}
              name="mrq"
              data-column="item"
              className="content-editable"
              disabled={false} 
              onBlur={e => handleOnEdit(e,row,'marque')}
            />
          </div >
             );
           },
      },
      {
        name: "DATE",
        minWidth:"100px !important",
        selector:row=>row.datin,
        sortable: true,
        sortField:"datin"
      },
      {
        name: "UTILISATEUR",
        minWidth:"160px !important",
        selector: row=>row.user,
        sortable: true,
        sortField:"user"
      },
      { 
        width:"10px",
        style:iconCell,
        cell: (row) => {
          if(Icon===row.DT_RowId){
            if(row.activ===1)
            row.activ=0
            else
            row.activ=1   
            setTimeout(() => { handleLockIcon('')},1) ;         
          }
          return (
            <Tooltip title="Activer/Desactiver">
            <div onClick={()=>flagMan(row)} >
               {(row.activ)?<LockOpen className={classes.redIcon} fontSize="small"/>:<Lock className={classes.greenIcon} fontSize="small"/> }
            </div> 
            </Tooltip>       
           );
         },
      }  
  ],[flagMan,handleOnEdit,Icon,classes]);
  
  // a style applied when the condition is verified 
  
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
    setLoading(true);
    const response=await librery.axiosSort(column,sortdirection,page,size,service,'get')
    if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      setLoading(false);
    })  
  }   
}

     //recherche serverside
   const handleSearch=async(size = perPage)=>{
    setLoading(true);
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,"get")
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
   }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
    return recherche
  }
  
   function handlePageChange (page) {
    fetch(page,service,perPage);
    setCurrentPage(page);
  };
   const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,service, newPerPage);
    setPerPage(newPerPage);
  };
  
  //Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }
  function liveSearch2() {
    handleSearch();
  }
  return (
    <div className="content">
       <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA}/>
        <div className='table-header'>
          <div className='button-flex-wrapper'><DraggableDialog callback={()=>fetch(1)} handleAlert={handleAlert}/></div>
          {(doSearch)?
          <div className='search'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField className={classes.search} variant="outlined"  label="Recherche" value={search} size="small" 
                               InputProps={{startAdornment: (<Search onClick={() => handleSearch()}
                               style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer" }} />),}}
                               margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
              </Box>
            </div>:null}
        </div>
        {(data.length!==0||doSearch)?
            <DataTable className="table"
          persistTableHead
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              noHeader={true}
              highlightOnHover
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
            />:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      
    </div>    

  );
}