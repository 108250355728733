import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,Paper,Button,Dialog,FormControl,TextField,MenuItem,Typography,IconButton} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles, paperPropsSmall2 } from '../../../css/muiStyle';
import { Box } from "@mui/system";
import {ReactComponent as BarCode} from '../../../img/icons/barcodeSearch.svg'
import { Add ,Close} from '@material-ui/icons';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import AddProd from "./ref_prd_add"
import { Autocomplete } from '@material-ui/lab';
import { AlertCompo } from '../../../js/components';
import { Divider } from '@mui/material';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import { mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ach_ent_ach(props) {
    const Gfunc=require('../../../Gfunc')
    const [prod,setProd]=useState('');
    const [prodN,setProdN]=useState('');
    const [stk,setStk]=useState('');
    const [stkN,setStkN]=useState('');
    const [mth,setMth]=useState('null');
    const [prodd,setProdd]=useState({});
    const [found,setFound]=useState(false);
    const [code,setCode]=useState('');
    const [qte,setQte]=useState(1);
    const [pin,setPin]=useState('');
    const [pout,setPout]=useState('');
    const [poutL,setPoutL]=useState([{pout:0}]);
    const [pinL,setPinL]=useState([{pin:0}]);
    const [prodL, setProdL] = useState([]);
    const [stkL, setStkL] = useState([]);
    const [calML, setCalML] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [open, setOpen] =useState(false);
    const nom="achat"
    const addTitle="Ajouter un nouvel achat"
    const labelText1="Produit"
    const labelText2="Stock"
    const labelText3="prix d'achat"
    const labelText4="Prix de vente"
    const labelText5="Quantité"
    const labelText6="Péremption"
    const labelText7="Calcul"
    const service="_ach.php"

    const classes=useStyles();
    const [openA, setOpenA] =useState(false);
    const [message, setMessage] =useState('');
    const [severity, setSeverity] =useState('info');

    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    //fetch Products
    const fetchProd= useCallback(async(serv="_prd.php", act = "gets") => {
        const response = await Gfunc.axiosGetAction(serv,act);
        Gfunc.axiosResponse(response,()=>{
            setProdL(response.data.jData);    
        })
      },[Gfunc])

      //fecth products by code barre
  const fetchProdbyCb=async(e) => {
    setCode(e.target.value.replace(/(\r\n|\n|\r)/g, ""))
    if(e.target.value===''){
      handleClickOpen();
    }else{
    const response = await Gfunc.axiosPostAction("_prd.php","getpbycb",{code:e.target.value})
    Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
        //const index=Gfunc.getIndex(prodL,response.data.jData.id,"id")
        handleClickOpen();
          if (response.data.jData.id!=null){
            setProdd(response.data.jData)
            handleOpenPout(response.data.jData.id)
            document.getElementById('iPin').focus()
            setFound(true)
          }
          else {
            props.handleAlert(true,'warning',"Ce code à barre n'est pas enregistré.")
            //setProd('')
          }
          setCode('')
        }
    })
    setCode('')}
  };

  //fetch stocks
  const fetchStk= useCallback(async() => {
    const response = await Gfunc.axiosGetAction('_stk.php',"gets")
    if(response.data.err){
      setOpen(false)
      props.handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        setStkL(response.data.jData);   
    })
  },[Gfunc,props]);

   //handle the call for fetch type
   const handleOpenStk=()=>{
    if(Gfunc.isEmpty(stkL))
    fetchStk();
   }
  //handle date change 
   const handleDateChange = (date) => {
    setSelectedDate(date);}

  //get calculation methods
  const getCalMethods= useCallback(async(id) => {
    if(Gfunc.isEmpty(calML)){
    const response = await Gfunc.axiosGetAction('_prd.php',"getmth")
    if(response.data.err){
      props.handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!=null)
        setCalML(response.data.jData);   
    })}
  },[Gfunc,calML,props]);

  //get calculation methods
  const handleOpenPout= useCallback(async(id) => {
    if(true){
      const response = await Gfunc.axiosPostAction('_prd.php',"getprxsach",{idp:id})
      if(response.data.err){
        props.handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!=null){
          setPoutL(response.data.jData.vente);
          setPinL(response.data.jData.achat);
          if(response.data.jData.vente.length!==0)
            setPout(response.data.jData.vente[0].prxVente.toString())
          else
            setPout('0')
          if(response.data.jData.achat.length!==0)
            setPin(response.data.jData.achat[0].prxAchat.toString())
          else
            setPin('0')
        }  
      })
    }
  },[Gfunc,props]);
  
    const handleClickOpen = () => { 
      getCalMethods()
      setPin('');
      setPout('');
      setPinL([]);
      setPoutL([]);
      setProdd({});
      setProd('');
      setQte(1);
      setMth("null")
      setProdN('')
      setOpen(true);
      if(JSON.parse(localStorage.getItem('stockactiv'))){
        setStk(localStorage.getItem('stockId'))
        setStkN(localStorage.getItem('stock'))
      }else{
        setStk('')
        setStkN('')
      }  
    };

    const reset = () => { 
      setPin('');
      setPout('');
      //setProd('');
      //setProdd({});
      setQte('');
      setMth("null")
      setProdN('')
    };

    const handleClose = () => { setOpen(false); setFound(false) };

      //submit un achat 
    const handleSubmit= async(e) =>{
      if(parseInt(pin)<=parseInt(pout)){const data={
        idb:parseInt(props.bon.substr(1)),
        idp:(found)?prodd.id:parseInt(prod),
        idstock:parseInt(stk),
        qte:parseInt(qte),
        achat:parseFloat(pin.replace(/,/g, '.')),
        vente:parseFloat(pout.replace(/,/g, '.')),
        dat_per:Gfunc.formatDate(new Date(selectedDate)),
        id_methode:JSON.parse(mth)
      }
        e.preventDefault();
        const respon=await axiosPostAction(service,"addach",data)
        if(respon.data.err&&respon.data.err!=='-1'){
          handleAlert(true,'error',respon.data.err)
        }else
        axiosResponse(respon,()=>{
                //setOpen(false);
                //alert(alertError);
                reset()
                props.handleAlert(true,'success',"L'achat est ajouté.")
                props.callback(props.bon.substr(1));
                Gfunc.updateElem(props.bon,respon.data.jData['mtnBon'])
                setOpen(false)        
        })}else{
          handleAlert(true,'error',"Le prix de vente doit être supérieur à celui d'achat!")
        }
    }

   const handleAddProdSuccess=()=>{
        props.callback(props.bon.substr(1))
        setOpen(false)
        props.handleAlert(true,'success',"L'achat est ajouté.")
   }
  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}> 
            <TextField  id="iCode" value={code} className={classes.search} variant="outlined" label='Code à barres'
                        InputProps={{startAdornment: (<BarCode style={{ marginRight: '5px',zIndex:'1000',width:'20px',height:'20px',cursor:"pointer" }} onClick={()=>setOpen(true)}/>),}}
                        onChange={e=>{setCode(e.target.value)}} onKeyPress={(e)=>{if(e.key ==='Enter' )fetchProdbyCb(e)}} />
      </Box>
      <Dialog
            open={open}
            id='nv-ach'
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmall2}
      >
        <DialogTitle className={classes.topDivTitle} onClose={handleClose}>
            <div className='DialogTitle'>
              <Icon path={mdiPlusBox} size={1} />
              <label>{addTitle} {(found)?' de '+prodd['produit']:null}</label>
            </div>
        </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
            <DialogContent >
            {(!found)?
            <>
            <Divider textAlign='center' >Si le produit n'existe pas vous pouvez toujours ajouter un  nouveau produit</Divider>
            <AddProd idb={props.bon.substr(1)} callback={handleAddProdSuccess} handleAlert={props.handleAlert} prodL={prodL} stkL={stkL} fetchProd={fetchProd} handleOpenStk={handleOpenStk}/>
            </>:null}
           { (!found)?<Divider textAlign='center' > Ou vous pouvez sélectionner un des produits existants </Divider>:null}
            <ValidatorForm className="block-wrapper" id="validateAchat" ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
              {    
                (found)?
                     <>
                    <Autocomplete
                        disableClearable
                        className='smallAuto'
                        id="iStk"
                        onChange={(e,newValue) =>{
                            setStk(stkL[Gfunc.getIndex(stkL,newValue,"nom")].id)
                            setStkN(newValue)}
                        } 
                        onOpen={handleOpenStk}
                        value={stkN}
                        options={stkL.map((option) => option.nom)}
                        renderInput={(params) => (
                            <TextField {...params} label={labelText2} margin='dense' variant='outlined'size="small" required/>
                                )} />
                    </>
                    :<div className="grid2">
                        <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iProd"
                                onChange={(e,newValue) =>{
                                    setProd(newValue.id)
                                    handleOpenPout(newValue.id);
                                    setProdN(newValue.nom)}
                                  } 
                                onOpen={()=>fetchProd()}
                                value={{id:prod,nom:prodN}}
                                options={prodL}
                                getOptionLabel={(option) => option.nom}
                                getOptionSelected={(option, value) => option.id === value.id || value.id===''}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText1}
                                    size="small" 
                                    margin="dense"
                                    variant='outlined'
                                    autoFocus
                                    required/>
                                )} />
                        <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iStk"
                                onChange={(e,newValue) =>{
                                    setStk(stkL[Gfunc.getIndex(stkL,newValue,"nom")].id)
                                    setStkN(newValue)}
                                  } 
                                onOpen={handleOpenStk}
                                value={stkN}
                                options={stkL.map((option) => option.nom)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText2}
                                    variant='outlined'
                                    margin='dense'
                                    size="small" 
                                    required/>
                                )} />
    </div>
              }
            <div className='grid3'>
            <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iPin"
                                freeSolo
                                onChange={(e,newValue) =>{setPin(newValue.substring(0,newValue.indexOf(' [')))}} 
                                value={(pin!=='')?(pin):''}
                                options={pinL.map((option) => option.display)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText3}
                                    size="small" 
                                    margin='dense'
                                    variant='outlined'
                                    value={pin}
                                    onChange={e=>{setPin(e.target.value);}}
                                    autoFocus={(found)?true:false}
                                    required/>
                                )} />
                        <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iPout"
                                freeSolo
                                onChange={(e,newValue) =>{setPout(newValue.substring(0,newValue.indexOf('[')))}} 
                                value={(pout!=='')?(pout):''}
                                options={poutL.map((option) => option.display)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText4}
                                    size="small" 
                                    margin='dense'
                                    variant='outlined'
                                    value={pout}
                                    onChange={e=>{setPout(e.target.value);}}
                                    required/>
                                )} />
                    <TextField label={labelText5} size="small" type='number' id="iQte" name="iQte"  InputProps={{ inputProps: { min: 1 } }} value={qte} className='smallText'
                        onChange={e =>setQte(e.target.value)} required  variant="outlined" margin='dense' //helperText="Insérer un chiffre."
                     />
           </div>
           <div className="grid2">
                     <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                          <KeyboardDatePicker
                                disableToolbar
                                size="small"
                                className='smallText'
                                margin='dense'
                                inputVariant='outlined'
                                minDate={new Date()} 
                                invalidDateMessage="Le format de la date est incorrect"   
                                maxDateMessage="La date ne doit pas être inferieur à la date minimale"                           
                                variant="inline"
                                format="dd/MM/yyyy"
                                name="iDate"
                                id="date-picker-inline"
                                label={labelText6}
                                value={selectedDate}
                                onChange={handleDateChange}
                                />
                         </MuiPickersUtilsProvider>    
                         <FormControl  onClick={(e) => e.stopPropagation()} >
                              <TextField select  id="iMth" name="iMth" className='smallText' variant='outlined' margin="dense" size="small" 
                                         label={labelText7}  value={mth} onChange={(e)=>setMth(e.target.value)}
                              >
                                  <MenuItem value={"null"}>Aucune méthode de calcul</MenuItem>
                                          {
                                            Object.keys(calML).map((key, index)=>{
                                              return <MenuItem key={index}  value={key}>{calML[key]
                                          }
                                  </MenuItem>
                                          })}
                               </TextField>
                           </FormControl>
                </div>
                 </ValidatorForm>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type='submit' color="primary" form="validateAchat" startIcon={<Add />}>AJOUTER</Button>
            </DialogActions>
      
      </Dialog>
      </div>
  );
}